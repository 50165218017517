import styled from "styled-components";

export const DividerBase = styled.div `
    display: table;
    white-space: nowrap;
    text-align: center;
    height: 1px;
    width: 100%;
    color: rgba(0,0,0, .15);
    margin: 16px 0;
    clear: both;
    &:before,
    &:after {
      content: '';
      display: table-cell;
      position: relative;
      top: 50%;
      width: 50%;
      border-top: 1px solid ${props => props.theme.borderMain};
      transform: translateY(50%);
    }
`;
