import * as React from "react";
import * as gs from "./Button.Styles";

export type ButtonType = "default" | "primary" | "danger";
export type ButtonHTMLType = "submit" | "button" | "reset";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    type?: ButtonHTMLType;  // This is the actual HTML type
    buttonType?: ButtonType; // This is a domain-specific concept and will be used within the switch state of
    iconOnly?: boolean;
}

export class Button extends React.Component<ButtonProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {type, buttonType, iconOnly, ...rest} = this.props;

        switch (buttonType) {
            case "primary":
                return (
                    <gs.ButtonPrimary
                        iconOnly={iconOnly}
                        type={type || "button"}
                        {...rest}
                    />);

            case "danger":
                return (
                    <gs.ButtonDanger
                        iconOnly={iconOnly}
                        type={type || "button"}
                        {...rest}
                    />);

            case "default":
            default:
                return (
                    <gs.ButtonDefault
                        iconOnly={iconOnly}
                        type={type || "button"}
                        {...rest}
                    />);
        }
    }
}
