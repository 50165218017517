"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var MediaHubActions_1 = require("../../actions/MediaHubActions");

var INITIAL_STATE = Immutable({
  progressValue: 0
});
exports.uploadReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(MediaHubActions_1.MediaHubActions.uploadMediaInProgress, function (state, action) {
  return state.merge({
    progressValue: action.payload
  });
}).on(MediaHubActions_1.MediaHubActions.uploadMediaCompleted, function (state, action) {
  return state.merge({
    uploadData: action.payload
  }, {
    deep: true
  });
}).build();