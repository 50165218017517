"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubAnalyticsRedux = exports.getAnalyticsSummary = void 0;

var AnalyticsActions_1 = require("../../actions/AnalyticsActions");

var AnalyticsReducer_1 = require("./AnalyticsReducer"); // selectors


function getAnalyticsSummary(state) {
  if (state.clubs.clubAnalytics.feedEngagementData) {
    return {
      currentUser: state.authentication.currentUser,
      feedData: state.clubs.clubAnalytics.feedEngagementData,
      feedDataFetchCompleted: !state.clubs.clubAnalytics.fetchFeedAnalyticsInProgress,
      adminDataFetchCompleted: !state.clubs.clubAnalytics.fetchAdminAnalyticsInProgress
    };
  } else {
    return {
      currentUser: state.authentication.currentUser,
      feedDataFetchCompleted: !state.clubs.clubAnalytics.fetchFeedAnalyticsInProgress,
      adminDataFetchCompleted: !state.clubs.clubAnalytics.fetchAdminAnalyticsInProgress
    };
  }
}

exports.getAnalyticsSummary = getAnalyticsSummary; // redux selector

exports.clubAnalyticsRedux = {
  actions: __assign({}, AnalyticsActions_1.AnalyticsActions),
  reducer: AnalyticsReducer_1.clubAnalyticsReducer,
  selectors: {
    getAnalyticsSummary: getAnalyticsSummary
  }
};