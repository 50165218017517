"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrivacySettingId = exports.PrivacyValue = exports.FeedSettingName = exports.BallotResultAvailability = exports.Chat = exports.ActivityFeed = exports.ClubHub = exports.CookieNames = exports.ReportClubItemType = exports.ImageSources = exports.UpdateImageTypes = exports.ContentLocatorType = exports.ReportType = exports.FeedbackType = void 0;
var FeedbackType;

(function (FeedbackType) {
  FeedbackType["UserContentActivityFeed"] = "UserContentActivityFeed";
  FeedbackType["CommsTextMessage"] = "CommsTextMessage";
  FeedbackType["UserContentComment"] = "UserContentComment";
})(FeedbackType = exports.FeedbackType || (exports.FeedbackType = {}));

var ReportType;

(function (ReportType) {
  ReportType["UserContentActivityFeed"] = "UserContentActivityFeed";
  ReportType["UserContentComment"] = "UserContentComment";
  ReportType["UserContentGameDVR"] = "UserContentGameDVR";
  ReportType["CommsTextMessage"] = "CommsTextMessage";
  ReportType["UserContentGamertag"] = "UserContentGamertag";
  ReportType["UserContentRealName"] = "UserContentRealName";
  ReportType["UserContentGamerpic"] = "UserContentGamerpic";
  ReportType["UserContentPersonalInfo"] = "UserContentPersonalInfo";
  ReportType["UserContentScreenshot"] = "UserContentScreenshot";
  ReportType["CommsSpam"] = "CommsSpam";
  ReportType["UserContentInappropriateClub"] = "UserContentInappropriateClub";
  ReportType["UserContentClubLogo"] = "UserContentClubLogo";
  ReportType["UserContentClubBackground"] = "UserContentClubBackground";
  ReportType["UserContentClubDescription"] = "UserContentClubDescription";
  ReportType["UserContentClubName"] = "UserContentClubName";
})(ReportType = exports.ReportType || (exports.ReportType = {}));

var ContentLocatorType;

(function (ContentLocatorType) {
  ContentLocatorType["Download"] = "Download";
  ContentLocatorType["ThumbSmall"] = "Thumbnail_Small";
  ContentLocatorType["ThumbLarge"] = "Thumbnail_Large";
})(ContentLocatorType = exports.ContentLocatorType || (exports.ContentLocatorType = {}));

var UpdateImageTypes;

(function (UpdateImageTypes) {
  UpdateImageTypes["profile"] = "ClubLogo";
  UpdateImageTypes["background"] = "ClubBackground";
})(UpdateImageTypes = exports.UpdateImageTypes || (exports.UpdateImageTypes = {}));

var ImageSources;

(function (ImageSources) {
  ImageSources["GenericClubs"] = "genericClubs";
  ImageSources["GenericProfiles"] = "genericProfiles";
  ImageSources["Captures"] = "captures";
  ImageSources["Custom"] = "custom";
  ImageSources["All"] = "all";
})(ImageSources = exports.ImageSources || (exports.ImageSources = {}));

var ReportClubItemType;

(function (ReportClubItemType) {
  ReportClubItemType["ActivityFeedItem"] = "ActivityFeedItem";
  ReportClubItemType["Chat"] = "Chat";
  ReportClubItemType["Comment"] = "Comment";
})(ReportClubItemType = exports.ReportClubItemType || (exports.ReportClubItemType = {}));

var CookieNames;

(function (CookieNames) {
  CookieNames["SignedInCookieName"] = "SignedIn";
})(CookieNames = exports.CookieNames || (exports.CookieNames = {}));

var ClubHub;

(function (ClubHub) {
  var ClubRoles;

  (function (ClubRoles) {
    ClubRoles["None"] = "None";
    ClubRoles["Moderator"] = "Moderator";
    ClubRoles["Banned"] = "Banned";
    ClubRoles["Member"] = "Member";
    ClubRoles["Owner"] = "Owner";
    ClubRoles["Nonmember"] = "Nonmember";
    ClubRoles["NonDefaultFeedChannelFollower"] = "NonDefaultFeedChannelFollower";
    ClubRoles["Invited"] = "Invited";
  })(ClubRoles = ClubHub.ClubRoles || (ClubHub.ClubRoles = {}));

  var ClubTypeTypes;

  (function (ClubTypeTypes) {
    ClubTypeTypes["Closed"] = "closed";
    ClubTypeTypes["Open"] = "open";
    ClubTypeTypes["Secret"] = "secret";
  })(ClubTypeTypes = ClubHub.ClubTypeTypes || (ClubHub.ClubTypeTypes = {}));
})(ClubHub = exports.ClubHub || (exports.ClubHub = {}));

var ActivityFeed;

(function (ActivityFeed) {
  var FeedType;

  (function (FeedType) {
    FeedType["Clubs"] = "Clubs";
    FeedType["Trending"] = "Trending";
    FeedType["People"] = "People";
    FeedType["All"] = "All";
    FeedType["Ballots"] = "Ballots";
    FeedType["Scheduled"] = "Scheduled";
  })(FeedType = ActivityFeed.FeedType || (ActivityFeed.FeedType = {}));

  var AdminActionTypes;

  (function (AdminActionTypes) {
    AdminActionTypes["Delete"] = "delete";
    AdminActionTypes["ScheduledPostDelete"] = "scheduledPostDelete";
    AdminActionTypes["BallotPostDelete"] = "ballotPostDelete";
    AdminActionTypes["DeleteAndBan"] = "deleteAndBan";
    AdminActionTypes["AddToModQ"] = "addToModQ";
    AdminActionTypes["Report"] = "report";
    AdminActionTypes["Pin"] = "pin";
    AdminActionTypes["UnPin"] = "unpin";
    AdminActionTypes["DisableComment"] = "disableComment";
    AdminActionTypes["EnableComment"] = "enableComment";
    AdminActionTypes["ExportComments"] = "exportComments";
  })(AdminActionTypes = ActivityFeed.AdminActionTypes || (ActivityFeed.AdminActionTypes = {}));

  var SocialActionTypes;

  (function (SocialActionTypes) {
    SocialActionTypes["Like"] = "like";
    SocialActionTypes["Vote"] = "vote";
  })(SocialActionTypes = ActivityFeed.SocialActionTypes || (ActivityFeed.SocialActionTypes = {}));

  var CustomAdminActionMenu;

  (function (CustomAdminActionMenu) {
    CustomAdminActionMenu[CustomAdminActionMenu["DeleteFeedItemOnly"] = 0] = "DeleteFeedItemOnly";
  })(CustomAdminActionMenu = ActivityFeed.CustomAdminActionMenu || (ActivityFeed.CustomAdminActionMenu = {}));

  var ActivityFeedTypes;

  (function (ActivityFeedTypes) {
    ActivityFeedTypes["all"] = "all";
    ActivityFeedTypes["friends"] = "friends";
    ActivityFeedTypes["clubs"] = "clubs";
    ActivityFeedTypes["trending"] = "trending";
  })(ActivityFeedTypes = ActivityFeed.ActivityFeedTypes || (ActivityFeed.ActivityFeedTypes = {}));

  var ActivityItemTypes;

  (function (ActivityItemTypes) {
    ActivityItemTypes["Achievement"] = "Achievement";
    ActivityItemTypes["Achievements"] = "Achievements";
    ActivityItemTypes["Ballot"] = "Ballot";
    ActivityItemTypes["BroadcastStart"] = "BroadcastStart";
    ActivityItemTypes["BroadcastEnd"] = "BroadcastEnd";
    ActivityItemTypes["GameDVR"] = "GameDVR";
    ActivityItemTypes["Leaderboard"] = "Leaderboard";
    ActivityItemTypes["Lfg"] = "Lfg";
    ActivityItemTypes["Screenshot"] = "Screenshot";
    ActivityItemTypes["TextPost"] = "TextPost";
    ActivityItemTypes["Tournament"] = "Tournament";
    ActivityItemTypes["UserPost"] = "UserPost";
    ActivityItemTypes["SocialRecommendation"] = "SocialRecommendation";
    ActivityItemTypes["Container"] = "Container";
  })(ActivityItemTypes = ActivityFeed.ActivityItemTypes || (ActivityFeed.ActivityItemTypes = {}));

  var LinkDeviceType;

  (function (LinkDeviceType) {
    LinkDeviceType["Web"] = "Web";
    LinkDeviceType["Xbox"] = "XboxOne";
    LinkDeviceType["Windows"] = "Windows";
    LinkDeviceType["Apple"] = "Apple";
    LinkDeviceType["Android"] = "Android";
  })(LinkDeviceType = ActivityFeed.LinkDeviceType || (ActivityFeed.LinkDeviceType = {}));

  var TimelineType;

  (function (TimelineType) {
    TimelineType["User"] = "User";
    TimelineType["Club"] = "Club";
  })(TimelineType = ActivityFeed.TimelineType || (ActivityFeed.TimelineType = {}));

  var PostType;

  (function (PostType) {
    PostType["Text"] = "Text";
    PostType["WebLink"] = "Link";
    PostType["XblLink"] = "XboxLink";
    PostType["StoreLink"] = "PostV2";
    PostType["Ballot"] = "Ballot";
  })(PostType = ActivityFeed.PostType || (ActivityFeed.PostType = {}));
})(ActivityFeed = exports.ActivityFeed || (exports.ActivityFeed = {}));

var Chat;

(function (Chat) {
  var ChatMessageType;

  (function (ChatMessageType) {
    ChatMessageType["JoinChannel"] = "JoinChannel";
    ChatMessageType["LeaveChannel"] = "LeaveChannel";
    ChatMessageType["TicketRequest"] = "TicketRequest";
    ChatMessageType["TicketRefresh"] = "TicketRefresh";
    ChatMessageType["BasicText"] = "BasicText";
    ChatMessageType["RichText"] = "RichText";
    ChatMessageType["KickUser"] = "KickUser";
    ChatMessageType["ModifyUser"] = "ModifyUser";
    ChatMessageType["DirectMention"] = "DirectMention";
    ChatMessageType["IsTyping"] = "IsTyping";
    ChatMessageType["MessageOfTheDay"] = "MessageOfTheDay";
    ChatMessageType["UserInfo"] = "UserInfo";
    ChatMessageType["WhoAmI"] = "WhoAmI";
    ChatMessageType["NoOp"] = "NoOp";
  })(ChatMessageType = Chat.ChatMessageType || (Chat.ChatMessageType = {}));

  var ChannelType;

  (function (ChannelType) {
    ChannelType["Club"] = "Club";
    ChannelType["Custom"] = "Custom";
  })(ChannelType = Chat.ChannelType || (Chat.ChannelType = {}));

  var FlagType;

  (function (FlagType) {
    FlagType[FlagType["IsDeleted"] = 0] = "IsDeleted";
    FlagType[FlagType["ServerOriginated"] = 1] = "ServerOriginated";
    FlagType[FlagType["ContainsLinks"] = 2] = "ContainsLinks";
    FlagType[FlagType["TextContainsMarkup"] = 3] = "TextContainsMarkup";
    FlagType[FlagType["Lurk"] = 4] = "Lurk";
  })(FlagType = Chat.FlagType || (Chat.FlagType = {}));
})(Chat = exports.Chat || (exports.Chat = {}));

var BallotResultAvailability;

(function (BallotResultAvailability) {
  BallotResultAvailability[BallotResultAvailability["BeforeVoting"] = 0] = "BeforeVoting";
  BallotResultAvailability[BallotResultAvailability["AfterVoting"] = 1] = "AfterVoting";
  BallotResultAvailability[BallotResultAvailability["AfterClose"] = 2] = "AfterClose";
})(BallotResultAvailability = exports.BallotResultAvailability || (exports.BallotResultAvailability = {}));

var FeedSettingName;

(function (FeedSettingName) {
  FeedSettingName["AutopostAchievements"] = "AutopostAchievements";
  FeedSettingName["AutopostGameClips"] = "AutopostGameClips";
  FeedSettingName["AutopostScreenShots"] = "AutopostScreenShots";
  FeedSettingName["RelevancySortFeed"] = "RelevancySortFeed";
})(FeedSettingName = exports.FeedSettingName || (exports.FeedSettingName = {}));

var PrivacyValue;

(function (PrivacyValue) {
  PrivacyValue["Everyone"] = "Everyone";
  PrivacyValue["PeopleOnMyList"] = "PeopleOnMyList";
  PrivacyValue["FriendCategoryShareIdentity"] = "FriendCategoryShareIdentity";
  PrivacyValue["Blocked"] = "Blocked";
  PrivacyValue["NotSet"] = "NotSet";
})(PrivacyValue = exports.PrivacyValue || (exports.PrivacyValue = {}));

var PrivacySettingId;

(function (PrivacySettingId) {
  PrivacySettingId["ShareFriendList"] = "ShareFriendList";
  PrivacySettingId["ShareGameHistory"] = "ShareGameHistory";
  PrivacySettingId["CommunicateUsingTextAndVoice"] = "CommunicateUsingTextAndVoice";
  PrivacySettingId["SharePresence"] = "SharePresence";
  PrivacySettingId["ShareProfile"] = "ShareProfile";
  PrivacySettingId["ShareVideoAndMusicStatus"] = "ShareVideoAndMusicStatus";
  PrivacySettingId["CommunicateUsingVideo"] = "CommunicateUsingVideo";
  PrivacySettingId["CollectVoiceData"] = "CollectVoiceData";
  PrivacySettingId["ShareXboxMusicActivity"] = "ShareXboxMusicActivity";
  PrivacySettingId["ShareExerciseInfo"] = "ShareExerciseInfo";
  PrivacySettingId["ShareIdentity"] = "ShareIdentity";
  PrivacySettingId["ShareRecordedGameSessions"] = "ShareRecordedGameSessions";
  PrivacySettingId["CollectLiveTvData"] = "CollectLiveTvData";
  PrivacySettingId["CollectXboxVideoData"] = "CollectXboxVideoData";
  PrivacySettingId["ShareIdentityTransitively"] = "ShareIdentityTransitively";
  PrivacySettingId["ShareVideoHistory"] = "ShareVideoHistory";
  PrivacySettingId["ShareMusicHistory"] = "ShareMusicHistory";
  PrivacySettingId["AllowUserCreatedContentViewing"] = "AllowUserCreatedContentViewing";
  PrivacySettingId["AllowProfileViewing"] = "AllowProfileViewing";
  PrivacySettingId["ShowRealTimeActivity"] = "ShowRealTimeActivity";
  PrivacySettingId["CollectVoiceDataXboxOneFull"] = "CollectVoiceDataXboxOneFull";
  PrivacySettingId["ShareContentToExternalNetworks"] = "ShareContentToExternalNetworks";
  PrivacySettingId["CanShareIdentity"] = "CanShareIdentity";
  PrivacySettingId["CollectVoiceSearchData"] = "CollectVoiceSearchData";
  PrivacySettingId["ShareClubMembership"] = "ShareClubMembership";
  PrivacySettingId["CollectVoiceGameChatData"] = "CollectVoiceGameChatData";
  PrivacySettingId["ShareActivityFeed"] = "ShareActivityFeed";
})(PrivacySettingId = exports.PrivacySettingId || (exports.PrivacySettingId = {}));