var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ActivityFeed } from "@xbox/social-core";
import { PromiseCompletionSource } from "@xbox/framework";
import { feedViewerRedux, ClubActions, CancellationTokenSource } from "@xbox/social-redux";
export var SCHEDULED_POST_FUTURE_DATE_THRESHOLD = 32;

function coreScheduledFeedContainer(resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.refreshFeed = function () {
          _this.props.clearActivityFeed();

          if (_this.props.clubId) {
            _this.updateFeedItems(_this.props.clubId);
          }
        };

        _this.doDelete = function (item) {
          var request = {
            action: ActivityFeed.AdminActionTypes.ScheduledPostDelete,
            item: item,
            clubId: item.timeline && item.timeline.timelineId ? item.timeline.timelineId : "",
            xuid: item.authorInfo.xuid
          };

          _this.props.deleteScheduledPost(request);

          _this.feedItemsUpdated = new PromiseCompletionSource();
          return _this.feedItemsUpdated.promise;
        };

        _this.handleDelete = function (item) {
          _this.doDelete(item).then(function () {
            _this.refreshFeed();
          });
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.refreshFeed();
      };

      class_1.prototype.componentWillUnmount = function () {
        // On Unmount, cancel any outstanding request
        if (this.cancellationSource) {
          this.cancellationSource.cancel();
        }
      };

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.clubId && this.props.selectedChannel && this.props.selectedChannel.id !== prevProps.selectedChannel.id) {
          this.updateFeedItems(this.props.clubId);
        }

        if (this.props.postStatus && this.props.postStatus !== prevProps.postStatus) {
          this.refreshFeed();
        }
      };

      class_1.prototype.componentWillReceiveProps = function (newProps) {
        if (this.feedItemsUpdated && (this.props.results === undefined || this.props.results !== newProps.results)) {
          this.feedItemsUpdated.resolve(true);
        }
      };

      class_1.prototype.render = function () {
        var adminActions = {
          delete: this.handleDelete,
          pin: function pin() {},
          unpin: function unpin() {},
          report: function report() {},
          addToModQ: function addToModQ() {},
          disableComment: function disableComment() {},
          enableComment: function enableComment() {}
        };
        var feedStatus = {
          results: this.props.results,
          isValid: this.props.isValid,
          isErrored: this.props.isCompleted && !this.props.results
        };
        return resolver.getScheduledPostsPanel(feedStatus, adminActions, this.props.selectChannel, this.refreshFeed, this.props.postStatus.resultData, this.props.selectedChannel, this.props.channelList);
      };

      class_1.prototype.updateFeedItems = function (clubId) {
        var today = new Date();
        var futureDate = new Date();

        if (this.cancellationSource) {
          this.cancellationSource.cancel();
        }

        this.cancellationSource = new CancellationTokenSource();
        futureDate.setDate(today.getDate() + SCHEDULED_POST_FUTURE_DATE_THRESHOLD);

        if (this.props.fetchScheduledActivityFeed) {
          var request = {
            clubId: clubId,
            cancellationToken: this.cancellationSource.getToken(),
            numItems: 100,
            cursor: "",
            startDate: today.toISOString(),
            endDate: futureDate.toISOString(),
            pinCount: 0,
            channelId: this.props.selectedChannel && !this.props.selectedChannel.isDefault ? this.props.selectedChannel.id : undefined
          };
          this.props.fetchScheduledActivityFeed(request);
        }
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var activityFeed = feedViewerRedux.selectors.getScheduledActivityFeed(state);
  return {
    results: activityFeed.results || [],
    completed: activityFeed.completed,
    selectedChannel: state.channels.selectedChannel,
    channelList: state.channels.feedChannels,
    postStatus: state.feedViewer.postStatus,
    isValid: state.feedViewer.activityFeed.activities ? state.feedViewer.activityFeed.activities.feedId === state.feedViewer.activityFeed.selectedFeedId : false,
    isCompleted: activityFeed.completed
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearActivityFeed: function clearActivityFeed() {
      return dispatch(feedViewerRedux.actions.clearFeedState());
    },
    selectChannel: function selectChannel(channel) {
      return dispatch(ClubActions.clubChannelSelected(channel));
    },
    fetchScheduledActivityFeed: function fetchScheduledActivityFeed(action) {
      return dispatch(feedViewerRedux.actions.fetchScheduledFeedItems(action));
    },
    deleteScheduledPost: function deleteScheduledPost(action) {
      return dispatch(feedViewerRedux.actions.adminActOnItem(action));
    }
  };
};

export function CoreScheduledFeedContainer(resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreScheduledFeedContainer(resolver));
}