"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityFeedReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var _ = require("lodash");

var ImmutableObj = require("seamless-immutable");

var social_core_1 = require("@xbox/social-core");

var ActivityFeedActions_1 = require("../../actions/ActivityFeedActions");

var ClubListActions_1 = require("../../actions/ClubListActions");

var CommentsFeedNormalizer_1 = require("./CommentsFeedNormalizer");

var INITIAL_STATE = ImmutableObj({
  fetchInProgress: false,
  settings: []
});
var innerActivityFeedReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE);
/****************************************************************************************************
FETCH PROGRESS ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress, function (state, action) {
  return state.merge({
    fetchInProgress: action.payload
  });
});
/****************************************************************************************************
FETCH COMPLETION ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.feedFetchCompleted, function (state, action) {
  if (state.fetchInProgress) {
    return state;
  } // Check that the current feed data returned is from the most recently requested by user


  if (state.selectedFeedId !== action.payload.feedId) {
    return state;
  }

  if (state.activities) {
    if (action.payload.feedId === state.activities.feedId) {
      var newResults = state.activities.result.concat(action.payload.result);
      var newState = state.merge({
        activities: action.payload
      }, {
        deep: true
      });
      newState = newState.setIn(["activities", "result"], newResults);
      return newState;
    } else {
      var newState = state.setIn(["activities"], action.payload);
      return newState;
    }
  } else {
    var newState = state.setIn(["activities"], action.payload);
    return newState;
  }
});
innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.feedFetchError, function (state, action) {
  if (state.activities) {
    // If the latest request is new
    if (state.selectedFeedId !== state.activities.feedId) {
      var newState = state.setIn(["activities"], undefined);
      return newState;
    } else {
      return state;
    }
  }

  return state;
});
/****************************************************************************************************
CURRENT FEED ID STATUS ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.setCurrentFeedId, function (state, action) {
  if (state.activities && state.selectedFeedId !== action.payload.toString()) {
    state = state.setIn(["activities", "cursor"], undefined);
  }

  return state.merge({
    selectedFeedId: action.payload.toString()
  });
});
innerActivityFeedReducer.on(ClubListActions_1.ClubActions.clubChannelSelected, function (state, action) {
  if (state.activities) {
    state = state.setIn(["activities", "cursor"], undefined);
  }

  return state;
});
/****************************************************************************************************
FEED ITEM DELETION ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.feedItemDeleted, function (state, action) {
  if (!state || !state.activities) {
    return state;
  }

  var feed = ImmutableObj(state.activities.entities.feed);
  feed = feed.without(feed[action.payload]);
  var results = state.activities.result.filter(function (item) {
    return item !== action.payload;
  });
  var newState = state.setIn(["activities", "result"], results);
  return newState.setIn(["activities", "entities", "feed"], feed);
});
/****************************************************************************************************
COMMENT ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.feedItemSelected, function (state, action) {
  return state.setIn(["selectedFeedItem"], action.payload);
}).on(ActivityFeedActions_1.ActivityFeedActions.clearComments, function (state, action) {
  return state.setIn(["activities", "entities", "feed", action.payload, "comments"], undefined);
}).on(ActivityFeedActions_1.ActivityFeedActions.fetchFeedCommentsCompleted, function (state, action) {
  var feedItemId = action.payload.feedItemId;

  if (feedItemId !== "") {
    var normalizedComments = action.payload.comments;
    var newComments = CommentsFeedNormalizer_1.commentsDenormalizer(normalizedComments.result, normalizedComments.entities);
    var comments = void 0; // Handle the case where we are coming in from analytics, and we haven't loaded the particular feedItemId on the activity feed

    if (state.activities && state.activities.entities.feed[feedItemId]) {
      comments = _.cloneDeep(state.activities.entities.feed[feedItemId].comments);
    } else {
      comments = _.cloneDeep(state.selectedFeedItem.comments);
    }

    if (!comments) {
      comments = {
        cursor: action.payload.cursor,
        items: newComments
      };
    } else if (comments.cursor === action.payload.cursor) {
      return state;
    } else {
      comments.items = comments.items.concat(newComments);
      comments.cursor = action.payload.cursor;
    }

    var feed = _.cloneDeep(state);

    var newState = state;

    if (feed.activities) {
      // Update the feed
      // Handle the case where we are coming in from analytics, and we haven't loaded the particular feedItemId on the activity feed
      if (feed.activities.entities.feed[feedItemId]) {
        feed.activities.entities.feed[feedItemId].comments = comments;
      }
    } // Update the selected feed item comments


    if (feed.selectedFeedItem) {
      newState = state.setIn(["selectedFeedItem", "comments"], comments);
    }

    return newState.merge({
      activities: feed.activities
    });
  }

  return state;
}).on(ActivityFeedActions_1.ActivityFeedActions.postFeedCommentCompleted, function (state, action) {
  var feedItemId = action.payload.feedItemId;
  var normalizedComments = action.payload.comment;
  var newComment = CommentsFeedNormalizer_1.commentsDenormalizer(normalizedComments.result, normalizedComments.entities)[0];

  var feed = _.cloneDeep(state);

  var newState = state;

  if (feed.selectedFeedItem && feed.selectedFeedItem.itemRoot === feedItemId) {
    if (feed.selectedFeedItem.comments) {
      feed.selectedFeedItem.comments.items.unshift(newComment);
    } // Update Comment count


    if (feed.selectedFeedItem.stat) {
      feed.selectedFeedItem.stat.comments = feed.selectedFeedItem.stat.comments + 1;
    }

    newState = newState.setIn(["selectedFeedItem"], feed.selectedFeedItem);
  }

  return newState;
}).on(ActivityFeedActions_1.ActivityFeedActions.commentItemDeleted, function (state, action) {
  var feed = _.cloneDeep(state);

  var feedItemRoot = action.payload.feedItemRoot;
  var newState = state;

  if (feed.selectedFeedItem && feed.selectedFeedItem.itemRoot === feedItemRoot) {
    // If selectedFeedItem is the one that comments were deleted, set to new comment items
    if (feed.selectedFeedItem.comments) {
      feed.selectedFeedItem.comments.items = feed.selectedFeedItem.comments.items.filter(function (x) {
        return x.id !== action.payload.itemId;
      }); // Update Comment count

      if (feed.selectedFeedItem.stat) {
        feed.selectedFeedItem.stat.comments = Math.max(feed.selectedFeedItem.stat.comments - 1, 0);
      }

      newState = newState.setIn(["selectedFeedItem"], feed.selectedFeedItem);
    }
  }

  return newState;
}).on(ActivityFeedActions_1.ActivityFeedActions.loadCommentLockCompleted, function (state, action) {
  return state.setIn(["isCommentLocked"], action.payload);
});
/****************************************************************************************************
LIVE FEED MANIPULATOR ACTORS
*****************************************************************************************************/

innerActivityFeedReducer.on(ActivityFeedActions_1.ActivityFeedActions.voteFeedItemSuccess, function (state, action) {
  var id = action.payload.feedItemId;
  var itemRoot = action.payload.itemRoot;

  if (state.activities && id !== "") {
    var feedItem = _.cloneDeep(state.activities.entities.feed[id]);

    feedItem.pollData.myChoice = action.payload.ballotChoice;
    feedItem.pollData.choices[action.payload.ballotChoice].numVotes++;
    var newState = state.setIn(["activities", "entities", "feed", id], feedItem); // if selected item is voted on, update that as well

    if (state.selectedFeedItem && state.selectedFeedItem.itemRoot === itemRoot) {
      newState = newState.setIn(["selectedFeedItem"], feedItem);
    }

    return newState;
  }

  return state;
}).on(ActivityFeedActions_1.ActivityFeedActions.hasPinnedFeed, function (state, action) {
  var id = action.payload.item.feedItemId;

  if (state.activities && id !== "") {
    var item = state.activities.entities.feed[id];
    var newState = state;
    var pinAction = action.payload.action === social_core_1.ActivityFeed.AdminActionTypes.Pin;

    if (pinAction) {
      // We must remove previous pinned item's flag from the redux
      var result = _.find(state.activities.entities.feed, function (o) {
        return o.pinned;
      });

      if (result) {
        var oldItem = _.cloneDeep(state.activities.entities.feed[result.feedItemId]);

        oldItem.pinned = false;
        newState = newState.setIn(["activities", "entities", "feed", result.feedItemId], oldItem);
      }
    }

    var feedItem = _.cloneDeep(state.activities.entities.feed[id]);

    feedItem.pinned = pinAction; // if selected item is pinned, update that as well

    if (state.selectedFeedItem && state.selectedFeedItem.itemRoot === id) {
      newState = newState.setIn(["selectedFeedItem"], feedItem);
    }

    return newState = newState.setIn(["activities", "entities", "feed", id], feedItem);
  }

  return state;
}).on(ActivityFeedActions_1.ActivityFeedActions.shareFeedItemCompleted, function (state, action) {
  var id = action.payload["postTypeData"].locator;

  if (state.activities) {
    var filter = _.filter(state.activities.entities.feed, function (item) {
      return id === item.itemRoot;
    });

    var item = filter.length ? filter[0] : undefined;

    if (item) {
      var feedItem = _.cloneDeep(item);

      feedItem.stat.shares = item.stat.shares + 1;
      var newState = state.setIn(["activities", "entities", "feed", feedItem.feedItemId], feedItem);

      if (state.selectedFeedItem && state.selectedFeedItem.itemRoot === feedItem.feedItemId) {
        newState = newState.setIn(["selectedFeedItem"], feedItem);
      }

      return newState;
    }
  }

  return state;
}).on(ActivityFeedActions_1.ActivityFeedActions.hasLikedFeed, function (state, action) {
  var item;

  if (state.activities) {
    var filter = _.filter(state.activities.entities.feed, function (item) {
      return action.payload.itemRoot === item.itemRoot;
    });

    item = filter.length ? filter[0] : undefined;

    if (item) {
      var newLikes = item.stat ? item.stat.likes : 0;

      if (action.payload.like) {
        newLikes++;
      } else {
        newLikes--;
      }

      newLikes = Math.max(newLikes, 0);

      var feedItem = _.cloneDeep(item);

      feedItem.hasLiked = action.payload.like;
      feedItem.stat.likes = newLikes;
      var newState = state.setIn(["activities", "entities", "feed", feedItem.feedItemId], feedItem);

      if (state.selectedFeedItem && state.selectedFeedItem.itemRoot === feedItem.feedItemId) {
        newState = newState.setIn(["selectedFeedItem"], feedItem);
      }

      return newState;
    }
  }

  return state;
}).on(ActivityFeedActions_1.ActivityFeedActions.clearFeedState, function (state, action) {
  return state.setIn(["activities"], undefined);
}).on(ClubListActions_1.ClubActions.clearState, function (state, action) {
  return state.setIn(["activities"], undefined);
}).on(ActivityFeedActions_1.ActivityFeedActions.getFeedSettingsCompleted, function (state, action) {
  return state.merge({
    settings: action.payload
  });
}).on(ActivityFeedActions_1.ActivityFeedActions.updateFeedSettingCompleted, function (state, action) {
  var settings = _.cloneDeep(state.settings);

  action.payload.forEach(function (setting) {
    var index = settings.findIndex(function (f) {
      return f.name === setting.name;
    });
    settings[index] = setting;
  });
  return state.merge({
    settings: settings
  });
});
exports.activityFeedReducer = innerActivityFeedReducer.build();