"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RtaConnectionFactory = void 0;

var framework_1 = require("@xbox/framework");

var http_1 = require("@xbox/http");

var inversify_1 = require("inversify");

var XSocialSymbols_1 = require("../../typings/XSocialSymbols");

var rtaNonceUrl = "https://rta.xboxlive.com/nonce";
var rtaUrl = "wss://rta.xboxlive.com/connect?nonce=";
var rtaSupportedProtocols = "rta.xboxlive.com.V2";

var RtaConnection =
/** @class */
function () {
  function RtaConnection(xblService, messageReceived) {
    this.xblService = xblService;
    this.messageReceived = messageReceived;
    this.sequenceNumber = 0;
    this.promiseCompletionMap = new Map();
  }

  RtaConnection.createMessage = function (messageType, sequenceNumber, message) {
    return "[" + messageType + ", " + sequenceNumber + ", '" + message + "']";
  };

  RtaConnection.prototype.connect = function () {
    var _this = this;

    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Get,
      url: rtaNonceUrl
    }).then(function (response) {
      _this.socket = new WebSocket("" + rtaUrl + response.data.nonce, rtaSupportedProtocols);

      _this.socket.onmessage = function (ev) {
        _this.messageReceived(ev.data);

        var messageParts = JSON.parse(ev.data);

        var pcs = _this.promiseCompletionMap.get(+messageParts[1]);

        if (pcs) {
          pcs.resolve(ev.data);
        }
      };

      _this.socket.onerror = function (error) {
        console.log(error);
      };

      _this.socket.onclose = function () {
        _this.socket = undefined;
        Array.from(_this.promiseCompletionMap.values()).forEach(function (promise) {
          return promise.reject("Socket closed");
        });
      };

      return new Promise(function (resolve, reject) {
        if (_this.socket) {
          _this.socket.onopen = function () {
            return resolve();
          };
        } else {
          reject("Socket was closed or undefined");
        }
      });
    });
  };

  RtaConnection.prototype.sendMessage = function (messageType, message) {
    if (this.socket && this.socket.readyState === 1) {
      var formattedMessage = RtaConnection.createMessage(messageType, this.sequenceNumber, message);
      this.sequenceNumber++;
      this.socket.send(formattedMessage);
    } else {
      throw new Error("Socket hasn't been opened");
    }
  };

  RtaConnection.prototype.sendMessageAndAwait = function (messageType, message) {
    if (this.socket && this.socket.readyState === 1) {
      var currentMessageIndex = this.sequenceNumber;
      var pcs = new framework_1.PromiseCompletionSource();
      this.promiseCompletionMap.set(currentMessageIndex, pcs);
      var formattedMessage = RtaConnection.createMessage(messageType, currentMessageIndex, message);
      this.sequenceNumber++;
      this.socket.send(formattedMessage);
      return pcs.promise;
    } else {
      throw new Error("Socket hasn't been opened");
    }
  };

  RtaConnection.prototype.close = function () {
    if (this.socket && this.socket.readyState <= WebSocket.CLOSING) {
      this.socket.close();
      this.socket = undefined;
    } else {
      throw new Error("Socket hasn't been opened");
    }
  };

  return RtaConnection;
}();

var RtaConnectionFactory =
/** @class */
function () {
  function RtaConnectionFactory(xblService) {
    this.xblService = xblService;
  }

  RtaConnectionFactory.prototype.createRtaConnection = function (messageReceived) {
    return new RtaConnection(this.xblService, messageReceived);
  };

  RtaConnectionFactory = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IXboxLiveRequestService)), __metadata("design:paramtypes", [Object])], RtaConnectionFactory);
  return RtaConnectionFactory;
}();

exports.RtaConnectionFactory = RtaConnectionFactory;