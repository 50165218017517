import * as React from "react";
import { Input } from "antd";
import * as ptas from "./PostTextArea.Styles";

const DEFAULT_MIN_ROWS = 4;
const DEFAULT_MAX_ROWS = 6;

interface PostTextAreaProps {
    onChange: any;
    value: any;
    placeholder?: string;
    maxLength?: number;
    Rows?: number;
    style?: React.CSSProperties;
}

export const PostTextArea: React.SFC<PostTextAreaProps> = props => {
    const minRows = props.Rows ? props.Rows : DEFAULT_MIN_ROWS;
    const maxRows = props.Rows ? props.Rows : DEFAULT_MAX_ROWS;

    return (
        <ptas.PostInputWrapper style={props.style} className="textPostArea">
            <Input.TextArea
                onChange={props.onChange}
                value={props.value}
                autosize={{minRows: minRows, maxRows}}
                aria-label="textArea"
                placeholder={props.placeholder}
                maxLength={props.maxLength || 350}
                autoFocus={true}
            />
            {props.value.length > 0 && <ptas.PostCharacterCount> {props.value.length} / {props.maxLength || 350}</ptas.PostCharacterCount>}
        </ptas.PostInputWrapper>
    );
};

export const SingleLinePostTextArea: React.SFC<PostTextAreaProps> = props => {
    return (
        <ptas.PostInputWrapper style={props.style} className="textPostArea">
            <Input
                onChange={props.onChange}
                value={props.value}
                aria-label="textArea"
                placeholder={props.placeholder}
                maxLength={props.maxLength || 350}
            />
            {props.value.length > 0 && <ptas.PostCharacterCount> {props.value.length} / {props.maxLength || 350}</ptas.PostCharacterCount>}
        </ptas.PostInputWrapper>
    );
};
