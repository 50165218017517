import styled from "styled-components";
import { media } from "../../common/Generic.SnapPoints";

export const ClubHeader = styled.div`
    display: flex;
    flex: none;
    margin-bottom: 10px;
`;

export const ClubViewerDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const ClubPicMainContainer = styled.div`
    width: 100px;
    height: 100px;
    margin-right: 24px;

    //mobile
    ${media.small`
        width: 75px;
        height: 75px;
        margin-right: 12px;
    `}
`;

export const InfoContainer = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const ClubName = styled.h1`
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 0;

    //mobile
    ${media.small`
        font-size: 20px;
    `}
`;

export const DataContainerDiv = styled.div`
    margin-right: 20px;

    //mobile
    ${media.small`
        font-size: 12px;
    `}
`;

export const DataTitle = styled.p`
    font-size: 18px;
    font-weight: 500;
    margin: 0;

    //mobile
    ${media.small`
        font-size: 14px;
    `}
`;

/* Club Menu */
export const ClubLevelMenu = styled.ul`
    display: flex;
    flex: none;
    flex-wrap: wrap;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    padding-inline-start: 0;
`;

export const ClubLevelItem = styled.li`
    margin: 8px 20px 6px 0;

    a {
        font-size: 15px;
        text-decoration: none;
        color: ${props => props.theme.foregroundBrigth};
    }

    .active {
        border-bottom: 4px solid ${props => props.theme.blueRest};
        text-decoration: none;
        padding-bottom: 5px;
    }
`;
