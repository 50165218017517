import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { PostOptionsContainer } from "./PostOptionsContainer";
import { CommonPostDetailsProps } from "./FeedToolsTypes";
import { PreviewTextPostFeedItem } from "../feeditems/TextPostFeedItem";
import { GenericErrorMessage, GenericLoading } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import * as FTStyles from "./FeedTools.Styles";
import { PostTextArea } from "../PostTextArea";

interface TextPostDataState {
    text: string;
}

export class CreateTextFeedItem extends React.Component<CommonPostDetailsProps, TextPostDataState> {
    constructor(props: any) {
        super(props);

        this.state = {
            text: "",
        };
    }

    resetState = () => {
        this.setState({ text: "" });
    }

    onChangeText = (e: any) => {
        this.setState({ text: e.target.value });
    }

    onPreviewItem = (isPinned: boolean, scheduledDateTime?: string) => {
        let gamerPic = "", gamerTag = "";
        if (this.props.currentUser && this.props.currentUser.profile) {
            gamerPic = this.props.currentUser.profile.gamerpic;
            gamerTag = this.props.currentUser.profile.gamertag;
        }
        return (
            <PreviewTextPostFeedItem
                pinned={isPinned}
                itemText={this.state.text}
                userGamerPicUrl={gamerPic}
                userGamerTag={gamerTag}
                selectedChannel={this.props.selectedChannel}
                scheduledDateTime={scheduledDateTime}
            />
        );
    }

    onPostFeedItem = (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, scheduledDateTime?: string) => {
        if (!this.props.clubId) {
            return;
        }

        let timeline: ActivityFeed.UserPostTimeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: this.props.clubId,
        };

        if (this.props.selectedChannel && !this.props.selectedChannel.isDefault) {
            timeline.channelId = `${this.props.selectedChannel.id}`;
        }

        let tls = [timeline];

        let payload: ActivityFeed.UserPost = {
            postText: this.state.text,
            postType: ActivityFeed.PostType.Text,
            postDate: scheduledDateTime,
            timelines: tls,
        };

        this.props.handlePostClick(payload, isPinned, isDisabled, isScheduled, scheduledDateTime);
    }

    onCancelPost = () => {
        this.resetState();
    }

    onValidateString = () => {
        if (!this.state.text) {
            return;
        }

        this.props.handleStringValidation([this.state.text]);
    }

    componentWillUpdate(nextProps: any) {
        // Only reset post after post is successful
        // check this by seeing if the result data of the redux for the post status is new,
        // this only updates on successful post
        let status = nextProps.hydrationStates.postStatus.resultData;
        if (status && status !== this.props.hydrationStates.postStatus.resultData) {
            this.resetState();
        }
    }

    render() {
        if (!this.props.permissions) {
            return <GenericLoading />;
        }

        if (!this.props.permissions.post) {
            return (
                <GenericErrorMessage
                    errorMessage={Localize("feedtools.warning", { context: "text" })}
                    showError={true}
                />
            );
        }

        return (
            <div>
                <FTStyles.Header>
                    {Localize("postFeedItem.postDetails")}
                </FTStyles.Header>
                {Localize("postFeedItem.postText")}
                <br/>

                <PostTextArea
                    onChange={this.onChangeText}
                    value={this.state.text}
                    placeholder={Localize("postFeedItem.textPostPlaceholder")}
                />

                <PostOptionsContainer
                    onPostFeedItem={this.onPostFeedItem}
                    onCancelPost={this.onCancelPost}
                    getPreviewItem={this.onPreviewItem}
                    canPin={this.props.permissions.pinPost}
                    canSchedule={this.props.permissions.schedulePost}
                    postAbilityBlocked={this.state.text ? false : true}
                    forceSchedule={this.props.forceSchedule}
                />
            </div>
        );
    }
}
