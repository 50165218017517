"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExperimentationService = void 0;

var inversify_1 = require("inversify");

var ExperimentationService =
/** @class */
function () {
  function ExperimentationService() {}

  ExperimentationService.prototype.nullCache = function () {
    return {
      get: function get() {
        return null;
      },
      set: function set() {
        return null;
      }
    };
  }; // Read from cache


  ExperimentationService.prototype.getVariantFromCache = function (key) {
    return window.localStorage.getItem(key);
  }; // Store to cache


  ExperimentationService.prototype.setVariantFromCache = function (key, value) {
    return window.localStorage.setItem(key, value);
  }; // Gets experimentation information from service


  ExperimentationService.prototype.loadExperimentationData = function () {
    var fakeExperiment = {
      experiments: [{
        id: "experiment1",
        group: "variant"
      }, {
        id: "experiment2",
        group: "variant"
      }, {
        id: "experiment3",
        group: "control"
      }]
    };
    return Promise.resolve(fakeExperiment);
  };

  ExperimentationService = __decorate([inversify_1.injectable()], ExperimentationService);
  return ExperimentationService;
}();

exports.ExperimentationService = ExperimentationService;