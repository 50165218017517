import styled from "styled-components";
import { Button } from "../../../common/generic/Button";

export const MiniGameDiv = styled.div`
    width: 110px;
    height: 200px;
`;

export const MiniGameImage = styled.img`
    width: 100px;
    height: 150px;
`;

export const MiniGameTitle = styled.div`
    height: 45px;
    overflow: auto;
`;

export const PreviewGameContainer = styled.div`
    width:100%;
    height: auto;
    padding: 1%;
    border: 1px solid ${props => props.theme.blueRest};
    overflow: auto;
`;

export const PreviewGameImage = styled.img`
   float: left;
   height: 150px;
   width: 100px;
   margin-right: 2%;
`;

export const RightUpButton = styled(Button)`
    position: absolute !important;
    float: right;
    top: 5%;
    right: 2%;
`;

export const GameTitle = styled.h3`
    color: ${props => props.theme.foregroundMain};
    margin-top: 5%;
`;

export const GamePublisher = styled.h4`
    color: ${props => props.theme.lightHeader};
`;
