"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubAnalyticsReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ImmutableObj = require("seamless-immutable");

var AnalyticsActions_1 = require("../../actions/AnalyticsActions"); // reducer


var INITIAL_STATE = ImmutableObj({
  fetchFeedAnalyticsInProgress: false,
  fetchAdminAnalyticsInProgress: false,
  fetchCSVAnalyticsInProgress: false
});
exports.clubAnalyticsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(AnalyticsActions_1.AnalyticsActions.fetchAdminAnalyticsInProgress, function (state, action) {
  return state.merge({
    fetchAdminAnalyticsInProgress: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.fetchFeedAnalyticsInProgress, function (state, action) {
  return state.merge({
    fetchFeedAnalyticsInProgress: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.fetchAdminAnalyticsCompleted, function (state, action) {
  return state.merge({
    adminActionsData: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.fetchFeedAnalyticsCompleted, function (state, action) {
  return state.merge({
    feedEngagementData: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.fetchCSVAnalyticsInProgress, function (state, action) {
  return state.merge({
    fetchCSVAnalyticsInProgress: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.fetchCSVAnalyticsCompleted, function (state, action) {
  return state.merge({
    csvAnalyticsData: action.payload
  });
}).on(AnalyticsActions_1.AnalyticsActions.resetAnalytics, function (state, action) {
  state = state.setIn(["feedEngagementData"], undefined);
  return state.setIn(["adminActionsData"], undefined);
}).build();