import * as React from "react";
import { ActivityFeed, FeedCommentItem } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers, IFeedItemAdminActionHanders } from "@xbox/social-views";
import { CommentStatusState } from "@xbox/social-redux";

import { FeedViewType } from "../../infiniteScrollers/base/InfiniteScrollTypes";
import * as Style from "./FeedItemViewer.Styles";
import CommentItem from "../../activityItems/CommentItem";
import { InfiniteScrollerSelector } from "../../infiniteScrollers/InfiniteScrollerSelector";
import { FlexCenterContainer } from "../../../containers/activityfeed/FeedModal.Styles";
import { GenericLoading, GenericDisplayMode } from "../../../common/GenericElements";
import { FeedItemSelector } from "../FeedItemSelector";
import { Localize } from "../../../common/translation/Localize";
import { Divider } from "../../../common/generic/Divider";
import { Icon } from "../../../common/GenericElements";
import { Glyphs } from "../../../common/Generic.Icons";
import { Button } from "../../../common/generic/Button";
import { ScrollType } from "../../../components/infiniteScrollers/base/InfiniteScrollTypes";
import ShareModalContainer from "../../../containers/clubadmin/ShareModalContainer";
import TextToHTMLInput from "../../../common/input/TextToHTMLInput";

interface FeedItemViewerProps {
    item?: ActivityFeed.FeedItem;
    commentStatus?: CommentStatusState;
    socialActions: IFeedItemSocialActionHandlers;
    adminActions: IFeedItemAdminActionHanders;
    submitComment: (commentText: string) => void;
    isCommentLocked: boolean;
    isModerator: boolean;
    handleBackButton: () => void;
    fetchCommentLock: (item: ActivityFeed.FeedItem) => void;
}

interface FeedItemViewerState {
    feedItemView: FeedViewType;
    showShareItemModal: boolean;
}

const EmptyState = () => {
    return (<FlexCenterContainer>{Localize("activityFeedContainer.noComments")}</FlexCenterContainer>);
};

export class FeedItemViewer extends React.Component<FeedItemViewerProps, FeedItemViewerState> {
    private readonly listWrapperRef?: React.RefObject<any>;
    private passedClearMethod?: () => void;
    private scrollMethod?: (e: any) => void;
    constructor(props: any) {
        super(props);
        this.listWrapperRef = React.createRef();
        this.state = {
            feedItemView: FeedViewType.CompactView,
            showShareItemModal: false,
        };
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        if (document.documentElement) {
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.commentStatus && this.props.commentStatus.resultData !== prevProps.commentStatus.resultData) {
            if (this.passedClearMethod) {
                this.passedClearMethod();
            }
        }
    }

    showShareItemModal = () => {
        this.setState({ showShareItemModal: true });
    }

    hideShareItemModal = () => {
        this.setState({ showShareItemModal: false });
    }

    getFeedItemSelector = () => {
        if (!this.props.item) {
            return <GenericLoading />;
        }

        let socialActions: IFeedItemSocialActionHandlers = this.props.socialActions;
        socialActions.showShareModal = this.showShareItemModal;

        return (
            <FeedItemSelector
                key={this.props.item.feedItemId}
                item={this.props.item}
                adminActions={this.props.adminActions}
                socialActions={socialActions}
                showAdminAction={true}
                hideBorders={true}
                showFullDescription={true}
                isCommentLocked={this.props.isCommentLocked}
                fetchCommentLock={this.props.fetchCommentLock}
                viewType={FeedViewType.FullView}
            />
        );
    }

    getUpdateMethod = () => {
        return this.props.socialActions.loadMoreComments ? this.props.socialActions.loadMoreComments() : Promise.resolve(false);
    }

    getComments = (item: ActivityFeed.FeedItem) => {
        if (!item.comments) {
            return <GenericLoading />;
        }
        let commentItems: any[] = item.comments.items
            ? Array.from(item.comments.items).reverse().map((comment: FeedCommentItem) =>
                <CommentItem item={comment} feedItem={item} key={comment.id} showAdminAction={true} />)
            : [];

        return (
            <InfiniteScrollerSelector
                scrollerType={ScrollType.ListStrict}
                updateMethod={this.getUpdateMethod}
                listData={commentItems}
                overscanRowCount={10}
                emptyState={EmptyState}
                appliedTo="comment"
            />
        );
    }

    submitComment = (text: string) => {
       this.props.submitComment(text);
    }

    getClearButton = (props: any) => {
        this.passedClearMethod = props.onClick;
        return (
            <Button iconOnly={true} onClick={props.onClick}>
                <Icon type={Glyphs.Close} />
            </Button>
        );
    }

    getSubmitButton = (props: any) => {
        return (
            <Button style={{ margin: "0 6px" }} iconOnly={true} onClick={props.onClick} buttonType="primary">
                <Icon type={Glyphs.Send} />
            </Button>
        );
    }

    getAddCommentsSection = () => {
        return (
            <TextToHTMLInput
                onConfirm={this.submitComment}
                placeholder={Localize("activityFeedContainer.addComment")}
                submitButton={this.getSubmitButton}
                clearButton={this.getClearButton}
            />
        );
    }

    scrollCheckElement = (e: any) => {
        // Check to see if there is a scroll event fired by resizing
        // this will cause only have scrolling by manual means
        if (Math.abs(e.target.clientHeight - e.target.scrollTop) > 5) {
            let view = e.target.scrollTop > 10 ? FeedViewType.CompactView : FeedViewType.FullView;
            this.setState({ feedItemView: view });
        }
    }

    backButtonPressed = () => {
        this.props.handleBackButton();
    }

    render() {
        if (!this.props.item) {
            return <GenericLoading />;
        }

        if (this.props.item.comments && this.props.item.comments.items.length > 0) {
            this.scrollMethod = this.scrollCheckElement;
        }

        return (
            <Style.DetailedViewContainer className="PostDetailedView" ref={this.listWrapperRef} onScroll={this.scrollMethod}>
                <Style.CombinedDiv>
                    <div>
                        {this.getFeedItemSelector()}
                        <Divider style={{ margin: "-10px 0 2px 0" }} />
                    </div>
                    <Style.CommentsHolder className="CommentsHolder">
                        {this.getComments(this.props.item)}
                    </Style.CommentsHolder>
                    <div style={{ display: GenericDisplayMode(!this.props.isCommentLocked) }}>
                        <Divider style={{ marginTop: "0px" }} />
                        {this.getAddCommentsSection()}
                    </div>
                </Style.CombinedDiv>

                <Style.BackButton onClick={this.backButtonPressed}>
                    <Icon type={Glyphs.ArrowLeft} />
                </Style.BackButton>
                <ShareModalContainer showShareModal={this.state.showShareItemModal} onCancelShare={this.hideShareItemModal} />
            </Style.DetailedViewContainer>
        );
    }
}
