"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CorrelationVectorInterceptor = void 0;

var CorrelationVector_1 = require("../utils/CorrelationVector");

var CorrelationVectorInterceptor =
/** @class */
function () {
  function CorrelationVectorInterceptor() {}

  CorrelationVectorInterceptor.prototype.intercept = function (request) {
    var originalHeaders = request.headers ? request.headers : [];
    var cv = CorrelationVector_1.generateCV();
    var cvHeader = {
      Name: "MS-CV",
      Value: cv
    };

    var headers = __spreadArrays(originalHeaders, [cvHeader]);

    return Promise.resolve(__assign(__assign({}, request), {
      headers: headers
    }));
  };

  return CorrelationVectorInterceptor;
}();

exports.CorrelationVectorInterceptor = CorrelationVectorInterceptor;