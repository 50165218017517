"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Url = void 0;

var UrlSearchParams_1 = require("./UrlSearchParams");

var isReactNative = false;

var Url =
/** @class */
function () {
  function Url(url) {
    if (typeof navigator !== "undefined") {
      isReactNative = navigator.product === "ReactNative";
    }

    if (!isReactNative) {
      this.domUrl = new URL(url);
    } else {
      // URL constructor is unsupported. RN throws.
      this.regexUrl = new RegexUrl(url);
    }
  }

  Url.prototype.toJSON = function () {
    throw new Error("Method not implemented.");
  };

  Object.defineProperty(Url.prototype, "hash", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.hash;
      }

      return this.regexUrl.hash;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "host", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.host;
      }

      return this.regexUrl.host;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "hostname", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.hostname;
      }

      return this.regexUrl.hostname;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "href", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.href;
      }

      return "" + this.origin + this.pathname + this.search + this.hash;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "origin", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.origin;
      }

      return this.regexUrl.origin;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "password", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.password;
      }

      return this.regexUrl.password;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "pathname", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.pathname;
      }

      return this.regexUrl.pathname;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "port", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.port;
      }

      return this.regexUrl.port;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "protocol", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.protocol;
      }

      return this.regexUrl.protocol;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "search", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.search;
      }

      return this.regexUrl.search;
    },
    set: function set(query) {
      if (this.domUrl) {
        this.domUrl.search = query;
      } else {
        this.regexUrl.search = query;
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "searchParams", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.searchParams;
      }

      var search = this.regexUrl.search;
      return search ? new UrlSearchParams_1.UrlSearchParams(search) : new UrlSearchParams_1.UrlSearchParams();
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Url.prototype, "username", {
    get: function get() {
      if (this.domUrl) {
        return this.domUrl.username;
      }

      return this.regexUrl.username;
    },
    enumerable: false,
    configurable: true
  });

  Url.prototype.toString = function () {
    return this.href;
  };

  return Url;
}();

exports.Url = Url;

var RegexUrl =
/** @class */
function () {
  function RegexUrl(url) {
    // This regex matches the components of a URL and behaves the same way as the browser whatwg URL API
    // The only known issue is username:password@ in the URL causes origin to incorrectly include it username:password@
    // It was tested with this URL: https://profile.xboxlive.com:8080/test-32?query=3&second=as3#some=43
    // ^(?<origin>(?<protocol>[A-Z]*:)\/\/(?:(?<username>[^:@]+):(?<password>[^:@]*)@)?(?<host>(?<hostname>[^/:]*)(?::(?<port>[0-9]+))?))(?<pathname>\/?[^:?#]*)(?<search>\?+[^#]*)?(?<hash>#+.*)?$
    // JavaScript doesn't support named groups, so references are by index.
    var urlRegex = /^(([A-Z]*:)\/\/(?:([^:@]+)(?::([^:@]*))?@)?(([^/:]*)(?::([0-9]+))?))(\/?[^:?#]*)(\?+[^#]*)?(#+.*)?$/gi;
    var match = urlRegex.exec(url);

    if (!match) {
      throw new Error("Unable to parse URL with regex");
    }

    this.href = match[0];
    this.origin = match[1];
    this.protocol = match[2];
    this.username = match[3];
    this.password = match[4];
    this.host = match[5];
    this.hostname = match[6];
    this.port = match[7];
    this.pathname = match[8];
    this.searchInternal = match[9];
    this.hash = match[10];

    if (!this.username) {
      this.username = "";
    }

    if (!this.password) {
      this.password = "";
    }

    if (!this.port) {
      this.port = "";
    }

    if (!this.searchInternal) {
      this.searchInternal = "";
    }

    if (!this.hash) {
      this.hash = "";
    }
  }

  Object.defineProperty(RegexUrl.prototype, "search", {
    get: function get() {
      return this.searchInternal;
    },
    set: function set(value) {
      if (!value || value.length <= 0) {
        this.searchInternal = "";
      } else {
        this.searchInternal = value.startsWith("?") ? value : "?" + value;
      }
    },
    enumerable: false,
    configurable: true
  });
  return RegexUrl;
}();