"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringValidateReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ActivityFeedActions_1 = require("../../actions/ActivityFeedActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.stringValidateReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ActivityFeedActions_1.ActivityFeedActions.validateStringInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(ActivityFeedActions_1.ActivityFeedActions.validateStringCompleted, function (state, action) {
  return state.merge({
    resultData: action.payload
  }, {
    deep: true
  });
}).build();