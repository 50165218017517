import * as React from "react";
import { MediaOffStyle, ShowManageCookiesStyle } from "./CookieConsent.Styles";

enum Text { "mediaOff" = "Cookies Blocked", "manange" = "Manage Cookies" }
let siteConsent: WcpConsent.SiteConsent;
const onConsentChanged = (categoryPreferences: Record<WcpConsent.consentCategories, boolean> ): void => {
    // update application functionality per users' cookie choices
};
const onWCPConsentInit = (err: Error | undefined, _siteConsent: WcpConsent.SiteConsent | undefined): void => {
    if (_siteConsent === undefined) {
        const error = err ? err : "NO ERROR INFORMATION AVAILABLE";
        console.warn("CookieConsent : onWCPConsentInit : Error" , error);
    } else {
        siteConsent = _siteConsent;
    }
};

const CookieConsent = {
    init: () => {
        WcpConsent.init(
            "en-US",
            "cookie-banner",
            onWCPConsentInit,
            onConsentChanged
        );
    },
    manageConsent: (): void => {
        siteConsent.manageConsent();
    },
    getSocialMediaConsent: (): boolean => {
        return siteConsent.getConsentFor(WcpConsent.consentCategories.SocialMedia);
    },
    isXboxUrl: (imageUrl: string): boolean => {
        return /http.*\.xboxlive.com/.test(imageUrl);
    },
    isConsentRequired: (): boolean => {
        return siteConsent.isConsentRequired;
    }
};
const ShowManageCookies = () => (
    <ShowManageCookiesStyle
        onClick={() => {
            CookieConsent.manageConsent();
        } }
    >
        {Text.manange}
    </ShowManageCookiesStyle>
);
const MediaOff = () => (
    <MediaOffStyle>
        {Text.mediaOff}
    </MediaOffStyle>
    );

export { CookieConsent, ShowManageCookies, MediaOff };
