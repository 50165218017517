"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagPickerActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var TagPickerActions;

(function (TagPickerActions) {
  TagPickerActions.getTags = redux_ts_simple_1.createAction("TAG_PICKLER:GET_TAGS");
  TagPickerActions.getTagsCompleted = redux_ts_simple_1.createAction("TAG_PICKLER:GET_TAGS_COMPLETED");
})(TagPickerActions = exports.TagPickerActions || (exports.TagPickerActions = {}));