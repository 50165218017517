import * as React from "react";
import { connect } from "react-redux";
import { Person, edsImageResizer, EdsImageSize, ReportType } from "@xbox/social-core";
import { ReduxState, BlockUserPayload, PrivacyActions, ClubActions, ClubRosterReportMemberPayload, SocialActions, FollowUserPayload } from "@xbox/social-redux";
import { Dropdown, Menu, Modal, Popover } from "antd";
import * as Styles from "./ProfileCard.Styles";
import { Localize, LocalizeHTML } from "../../common/translation/Localize";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import { GetReportOffenseModal } from "../../components/ReportOffenseMenu/ReportOffenseMenu";
import Util from "../../common/Util";

const confirm = Modal.confirm;

interface ProfileCardProps {
    person: Person;
    element: JSX.Element;
}

interface IProfileCardStateProps {
    currentUserXuid?: string;
}

interface IProfileCardrDispatchProps {
    reportUser(payload: ClubRosterReportMemberPayload): void;
    blockUser(payload: BlockUserPayload): void;
    unblockUser(payload: BlockUserPayload): void;
    followUser(payload: FollowUserPayload): void;
}

type IProfileCardProps = IProfileCardrDispatchProps & IProfileCardStateProps & ProfileCardProps;

interface IProfileCardState {
    blockActionVisible: boolean;
    addFriendActionVisible: boolean;
}

class ProfileCard extends React.Component<IProfileCardProps, IProfileCardState> {

    constructor(props: IProfileCardProps) {
        super(props);

        this.state = {
            blockActionVisible: false,
            addFriendActionVisible: false
        };
    }

    componentDidMount() {
        if (this.props.person.detail) {
            this.setState({ blockActionVisible: !(this.props.person.detail.blocked) });
            this.setState({ addFriendActionVisible: !(this.props.person.isFriend)});
        }
    }

    getUserActions(
        currentXuid: string,
        targetPerson: Person,
    ): JSX.Element {
        return (
            <Menu onClick={e => e.domEvent.stopPropagation()}>
                {this.getAddFriendActionVisiblity(currentXuid, targetPerson.xuid)}
                {this.getBlockUnblockUserActions(currentXuid, targetPerson)}
                <Menu.Item key="3" onClick={() => GetReportOffenseModal(currentXuid, targetPerson.gamertag, targetPerson.xuid, this.reportUserAction)}>{Localize("userDetails.report")}</Menu.Item>
            </Menu>
        );
    }

    getAddFriendActionVisiblity(
        currentXuid: string,
        targetXuid: string
    ): JSX.Element | null {
        if (this.state.addFriendActionVisible) {
            return <Menu.Item key="0" onClick={() => this.followUserAction(currentXuid, targetXuid)}>{Localize("userDetails.followUser")}</Menu.Item>;
        } else {
            return null;
        }
    }

    getBlockUnblockUserActions(
        currentXuid: string,
        targetPerson: Person
    ): JSX.Element | null {
        if (this.state.blockActionVisible) {
            return <Menu.Item key="1" onClick={() => this.blockUserAction(currentXuid, targetPerson)}>{Localize("userDetails.block")}</Menu.Item>;
        } else {
            return <Menu.Item key="2" onClick={() => this.unblockUserAction(currentXuid, targetPerson)}>{Localize("userDetails.unblock")}</Menu.Item>;

        }
    }

    setBlockActionVisiblity = (blockActionVisiblity: boolean) => {
        this.setState({ blockActionVisible: blockActionVisiblity });
    }

    getBlockUserActionHandlers(currentXuid: string, blockedPerson: Person, blockActionVisiblity: boolean, userAction: (payload: BlockUserPayload) => void): () => void {
        let payload: BlockUserPayload = {
            currentUserXuid: currentXuid,
            blockedXuid: blockedPerson.xuid
        };

        let blockUserHandler = userAction;
        let setBlockActionVisiblity = this.setBlockActionVisiblity;

        return () => {
            blockUserHandler(payload);
            setBlockActionVisiblity(blockActionVisiblity);
        };
    }

    followUserAction = (currentXuid: string, targetXuid: string) => {
        this.setState({ addFriendActionVisible: false });

        let payload: FollowUserPayload = {
            requesterXuid: currentXuid,
            targetXuid: targetXuid
        };

        this.props.followUser(payload);
    }

    blockUserAction = (currentXuid: string, blockedPerson: Person) => {
        let blockUserHandler = this.getBlockUserActionHandlers(currentXuid, blockedPerson, false, this.props.blockUser);

        confirm({
            title: <LocalizeHTML id="userDetails.blockConfirmTitle" options={{ gamertag: blockedPerson.gamertag }} />,
            content: <LocalizeHTML id="userDetails.blockConfirmText" options={{ gamertag: blockedPerson.gamertag }} />,
            okText: Localize("generic.okay"),
            okType: "primary",
            cancelText: Localize("generic.cancel"),
            onOk() {
                blockUserHandler();
            }
        });

    }

    unblockUserAction = (currentXuid: string, blockedPerson: Person) => {
        let unblockUserHandler = this.getBlockUserActionHandlers(currentXuid, blockedPerson, true, this.props.unblockUser);

        confirm({
            title: <LocalizeHTML id="userDetails.unblockConfirmTitle" options={{ gamertag: blockedPerson.gamertag }} />,
            content: <LocalizeHTML id="userDetails.unblockConfirmText" options={{ gamertag: blockedPerson.gamertag }} />,
            okText: Localize("generic.okay"),
            okType: "primary",
            cancelText: Localize("generic.cancel"),
            onOk() {
                unblockUserHandler();
            }
        });
    }

    reportUserAction = (xuid: string, offense: ReportType, reporter: string) => {
        let payload: ClubRosterReportMemberPayload = {
            evidenceId: xuid,
            feedbackContext: "User",
            feedbackType: offense,
            reporter
        };
        this.props.reportUser(payload);
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement.classList.contains("ant-dropdown-trigger")) {
            if (event.key === "Escape") {
                setTimeout(() => {
                    const dropdownElement = document.querySelector(".ant-popover") as HTMLElement;
                    const paragraphElement = document.querySelector("p.Gamerpic-Tag") as any;
                    if (paragraphElement) {
                        const gamerpicInfoDiv = paragraphElement.closest("div.Gamerpic-Info");
                        dropdownElement.classList.add("ant-popover-hidden");
                        gamerpicInfoDiv?.focus();
                    }
                }, 500);
            }
        }
    }

    getUserActionsMenu(
        currentUserXuid: string,
        person: Person
    ): JSX.Element | null {
        if (currentUserXuid === person.xuid) {
            return null;
        }

        return (
            <div>
                <Dropdown
                    overlay={this.getUserActions(
                        currentUserXuid,
                        person)}
                >
                    <Styles.MenuButton  onKeyDown={this.handleKeyDown} onClick={e => e.stopPropagation()}>
                        <Icon type={Glyphs.MoreActions} />
                    </Styles.MenuButton>
                </Dropdown>
            </div>
        );
    }

    render() {

        if (!this.props.currentUserXuid) {
            return null;
        }

        let friendship: any = <Styles.HorizontalSeparator />;

        if (this.props.person.isFavorite) {
            friendship = <Styles.FavoriteLabel>{Localize("userDetails.favorite")}</Styles.FavoriteLabel>;
        } else if (this.props.person.isFollowingCaller) {
            friendship = <Styles.FriendLabel>{Localize("userDetails.friend")}</Styles.FriendLabel>;
        } else if (this.props.person.isFollowedByCaller) {
            friendship = <Styles.FollowsYouLabel>{Localize("userDetails.followsYou")}</Styles.FollowsYouLabel>;
        }

        let content: JSX.Element = (
            <Styles.ProfileCardPopup>
                <Styles.ProfileCardContent>
                    <div >
                        <Styles.Gamerpic src={edsImageResizer(Util.ensureSSLImage(this.props.person.displayPicRaw), EdsImageSize._100x100)} />
                    </div>
                    <div style={{marginBottom: 10}}>
                        {this.props.person.realName ? <Styles.UserName>{this.props.person.realName}</Styles.UserName> : null}
                        {friendship}
                        {this.props.person.detail && this.props.person.detail.bio ? <Styles.Subtext>{this.props.person.detail.bio.substring(0, 20) || ""}</Styles.Subtext> : null}
                        {this.props.person.detail && this.props.person.detail.location ? <Styles.LightSubtext>{this.props.person.detail.location}</Styles.LightSubtext> : null}
                    </div>
                </Styles.ProfileCardContent>
                <Styles.ViewProfileLink onClick={e => e.stopPropagation()} target="_blank" href={`https://account.xbox.com/${navigator.language}/Profile?GamerTag=${this.props.person.gamertag}`}>
                    {Localize("generic.viewFullProfile")}
                </Styles.ViewProfileLink>
            </Styles.ProfileCardPopup>
        );

        let title: JSX.Element = (
            <Styles.ProfileCardTitle>
                <div style={{ paddingTop: "5px" }}>{this.props.person.gamertag}</div>
                <div style={{ float: "right", textAlign: "center" }}>{this.getUserActionsMenu(this.props.currentUserXuid, this.props.person)}</div>
            </Styles.ProfileCardTitle>
        );

        return (
            <Popover content={content} title={title} trigger="hover" overlayStyle={{ width: 300, maxWidth: "100%", zIndex: 11}}>
                {this.props.element}
            </Popover>
        );
    }
}

const mapStateToProps = (state: ReduxState): IProfileCardStateProps => {
    return {
        currentUserXuid: state.authentication && state.authentication.currentUser && state.authentication.currentUser.xuid
    };
};

const mapDispatchToProps = (dispatch: any): IProfileCardrDispatchProps => {
    return {
        reportUser: (payload: ClubRosterReportMemberPayload) => dispatch(ClubActions.clubRosterReportMember(payload)),
        blockUser: (payload: BlockUserPayload) => dispatch(PrivacyActions.blockUser(payload)),
        unblockUser: (payload: BlockUserPayload) => dispatch(PrivacyActions.unblockUser(payload)),
        followUser: (payload: FollowUserPayload) => dispatch(SocialActions.followUser(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileCard);
