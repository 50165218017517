// file inversify.config.ts
import { AuthStorageAdapter, engineContainer, XSocialSymbols, IAuthComponents, XalAuthProvider } from "@xbox/social-core";
import { Container } from "inversify";
import { WebCryptographyFactory } from "xaljs-web";
import { IDeviceInfo } from "xaljs/dist/declarations";
import { DevelopmentAppConfig, ProductionAppConfig } from "./config";
import { WebDeviceInfo } from "./config/WebDeviceInfo";
import { AppInsightsTelemetryService } from "./services/AppInsightsTelemetryService";
import { AsyncLocalStorageService } from "./services/AsyncLocalStorageService";
import { NotifcationService } from "./services/NotificationsService";
import { WebFileSaver } from "./services/WebFileSaver";

const xPartialSocialContainer = new Container();

if (process.env.NODE_ENV === "development") {
    xPartialSocialContainer.bind(XSocialSymbols.IAppConfig).to(DevelopmentAppConfig).inSingletonScope();
} else {
    xPartialSocialContainer.bind(XSocialSymbols.IAppConfig).to(ProductionAppConfig).inSingletonScope();
}

xPartialSocialContainer.bind(XSocialSymbols.ILocalStorageService).to(AsyncLocalStorageService).inSingletonScope();
xPartialSocialContainer.bind(XSocialSymbols.ITelemetryService).to(AppInsightsTelemetryService).inSingletonScope();
xPartialSocialContainer.bind(XSocialSymbols.INotificationsService).to(NotifcationService).inSingletonScope();
xPartialSocialContainer.bind(XSocialSymbols.IFileSaver).to(WebFileSaver).inSingletonScope();

xPartialSocialContainer.bind(XSocialSymbols.IDeviceInfo).to(WebDeviceInfo).inSingletonScope();

// this conditional import will not pull in the mrscrypto libraries if we are running as a server
var mrsCryptoFactory: any;
if (typeof window !== "undefined") {
    import("xaljs-msrcrypto").then((msrcrypto) => {
        mrsCryptoFactory = new msrcrypto.MsrCryptographyFactory();
    });
}

xPartialSocialContainer.bind(XSocialSymbols.IAuthComponents).toDynamicValue(context => {
    const cryptoFactory = (typeof window !== "undefined" && (!navigator || navigator.userAgent.indexOf("Edge/") !== -1)) ? mrsCryptoFactory : new WebCryptographyFactory();
    const deviceInfo = context.container.get<IDeviceInfo>(XSocialSymbols.IDeviceInfo);
    const storage = context.container.resolve<AuthStorageAdapter>(AuthStorageAdapter);
    const authComponents: IAuthComponents = {
        cryptoFactory,
        deviceInfo,
        storage
    };
    return authComponents;
}).inSingletonScope();
xPartialSocialContainer.bind(XSocialSymbols.IXalAuthProvider).toConstantValue(new XalAuthProvider());

// merge the engine with the application
let XSocialContainer = Container.merge(xPartialSocialContainer, engineContainer);
export { XSocialContainer };
