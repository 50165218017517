import * as React from "react";
import { SystemTag, SystemTagGroup } from "@xbox/social-core";
import { CoreTagPickerContainer, TagPickerHandlers, TagPickerContainerProps, ITagPickerResolver } from "@xbox/social-views";
import { withClubSelectionContext } from "../ClubSelectionContext";
import * as Styles from "./TagPicker.Styles";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon } from "../../common/GenericElements";
import { ThemedModal } from "../Styles";

function getTagPickerPanel(isEditible: boolean, isVisible: boolean, selectedTags: SystemTag[], handlers: TagPickerHandlers, tagPickerProps: TagPickerContainerProps): JSX.Element | null {
    let addTags = null;
    if (isEditible) {
        addTags = (
            <Styles.AddTag
                onClick={handlers.showTagPicker}
            >
                <Icon type={Glyphs.Add} />
            </Styles.AddTag>
        );
    }

    let tags = tagPickerProps.club && tagPickerProps.club.details.profile.tags.value.map(
        t => {
            let tag = tagPickerProps.combinedTagsList && tagPickerProps.combinedTagsList.find(s => s.tagId === t);
            if (tag) {
                return <Styles.SystemTag key={t}><Styles.TagIcon value={tag.emoji} />{tag.tagName}</Styles.SystemTag>;
            }
            return null;
        }
    );

    function renderGroup(group: SystemTagGroup) {
        if (!group) {
            return null;
        }

        function isChecked(tag: SystemTag) {
            return selectedTags.findIndex(t => t.tagId === tag.tagId) >= 0;
        }

        return (
            <div key={group.tagGroupName}>
                <h3>{group.tagGroupName}</h3>
                {group.tags.map((tag: SystemTag) =>
                    (<Styles.ToggleTag
                        key={tag.tagName}
                        checked={isChecked(tag)}
                        onChange={e => handlers.tagSelected(tag)}
                    >
                        <Styles.TagIcon value={tag.emoji} />
                        {tag.tagName}
                    </Styles.ToggleTag>))}
                <br />
                <br />
            </div>
        );
    }

    return (
        <div>
            {tags}{addTags}
            <ThemedModal visible={isVisible} onOk={handlers.submitTags} onCancel={handlers.hideTagPicker}>
                {tagPickerProps.systemTags.map((group: SystemTagGroup) => renderGroup(group))}
            </ThemedModal>
        </div>
    );
}

const FeedContainerResolver: ITagPickerResolver = {
    getTagPickerPanel: getTagPickerPanel
};

export default withClubSelectionContext(CoreTagPickerContainer(FeedContainerResolver));
