"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CookieUtils = void 0;

var XSocialTypes_1 = require("../../typings/XSocialTypes");

var CookieUtils =
/** @class */
function () {
  function CookieUtils() {}

  CookieUtils.prototype.setSignedInCookie = function (cookies, appConfig) {
    if (appConfig.isServerSideRendered) {
      cookies.set(XSocialTypes_1.CookieNames.SignedInCookieName, this.generateRandomString(), {
        path: "/",
        expires: this.getSigninCookieExpiration()
      });
    }
  };

  CookieUtils.prototype.deleteSignedInCookie = function (cookies, appConfig) {
    // this line does not delete cookies
    cookies.remove(XSocialTypes_1.CookieNames.SignedInCookieName); // explicitly remove cookies

    ["ai_session=; expires=Thu, 01-Jan-1970 00:00:01 GMT;", "ai_user=; expires=Thu, 01-Jan-1970 00:00:01 GMT;"].forEach(function (s) {
      return document.cookie = s;
    });
  };

  CookieUtils.prototype.getSigninCookieExpiration = function () {
    var date = new Date(); // Set it expire in 7 days

    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    return date;
  };

  CookieUtils.prototype.generateRandomString = function () {
    return Math.random().toString(36).substring(7);
  };

  return CookieUtils;
}();

exports.CookieUtils = CookieUtils;