"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetMsaTicket = void 0;

var http_1 = require("@xbox/http");

var AuthConfig_1 = require("../../../AuthConfig");

var Constants_1 = require("../../Constants");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var MsaTicketSet_1 = require("../../MsaTicketSet");

var Utils_1 = require("../../Utils");

var XalHttpRequest_1 = require("../../XalHttpRequest");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var GetDtoken_1 = require("./GetDtoken");

var InitializeTokenStack_1 = require("./InitializeTokenStack");

var framework_1 = require("@xbox/framework");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["Init"] = "Init";
  Step["RefreshTokenSilently"] = "RefreshTokenSilently";
  Step["GetDtoken"] = "GetDtoken";
  Step["CallSisuSignIn"] = "CallSisuSignIn";
  Step["ShowMsaUi"] = "ShowMsaUi";
  Step["ExchangeAuthCode"] = "ExchangeAuthCode";
  Step["UpdateCache"] = "UpdateCache";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var GetMsaTicket =
/** @class */
function (_super) {
  __extends(GetMsaTicket, _super);

  function GetMsaTicket(telemetryClient, scopes, forceRefresh, skipSplash, tokenStackComponents, httpClient, webView, callingOperation, msaUserId, responseUrl, rehydrationParams) {
    if (rehydrationParams === void 0) {
      rehydrationParams = undefined;
    }

    var _this = _super.call(this, telemetryClient) || this;

    _this.scopes = scopes;
    _this.forceRefresh = forceRefresh;
    _this.skipSplash = skipSplash;
    _this.tokenStackComponents = tokenStackComponents;
    _this.httpClient = httpClient;
    _this.webView = webView;
    _this.callingOperation = callingOperation;
    _this.msaUserId = msaUserId;
    _this.responseUrl = responseUrl;
    _this.rehydrationParams = rehydrationParams;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.GetMsaTicket, Step.Start); // Indicates we've restarted the operation due to an invalid Dtoken

    _this.refreshingDtoken = false;
    _this.forceRefreshDtoken = false;
    _this.scopesToRequest = scopes.slice();
    Utils_1.assert(_this.scopes.length > 0 || !Utils_1.isNullOrWhiteSpace(responseUrl)); // Access tokens themselves cannot have this scope

    Utils_1.assert(!_this.scopes.find(function (scope) {
      return scope.toLowerCase() === Constants_1.OfflineAccessScope;
    }));
    _this.endUrl = _this.tokenStackComponents.config.msaRedirectUri;

    if (_this.tokenStackComponents.config.titleType === AuthConfig_1.TitleType.ThirdParty) {
      // First party ticket requests automatically receive a refresh token.
      // For third party requests we need to add it when hitting the wire,
      // but we don't want it present in the list of scopes we look for in the cache.
      // Always place this at the end to make it appear at the end of the consent screen
      // for a better user experience.
      _this.scopesToRequest.push(Constants_1.OfflineAccessScope);
    }

    return _this;
  }

  GetMsaTicket.createFromTargetAndPolicy = function (telemetryClient, forceRefresh, skipSplash, tokenStackComponents, httpClient, webView, target, policy, callingOperation, msaUserId) {
    return new GetMsaTicket(telemetryClient, ["service::" + target + "::" + policy], forceRefresh, skipSplash, tokenStackComponents, httpClient, webView, callingOperation, msaUserId);
  };

  GetMsaTicket.createWithResponseUrl = function (telemetryClient, forceRefresh, tokenStackComponents, httpClient, webView, responseUrl, rehydrationParams, msaUserId) {
    var getMsaTicket = new GetMsaTicket(telemetryClient, [], forceRefresh, false, // skipSplash
    tokenStackComponents, httpClient, webView, undefined, msaUserId, responseUrl, rehydrationParams);
    return getMsaTicket;
  };

  GetMsaTicket.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            return [4
            /*yield*/
            , this.init()];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === Step.Init);
            if (!!Utils_1.isNullOrWhiteSpace(this.responseUrl)) return [3
            /*break*/
            , 2];
            this.restoreRehydrationParams();
            return [3
            /*break*/
            , 7];

          case 2:
            if (!!Utils_1.isNullOrWhiteSpace(this.msaUserId)) return [3
            /*break*/
            , 4];
            return [4
            /*yield*/
            , this.checkCache()];

          case 3:
            _a.sent();

            return [3
            /*break*/
            , 7];

          case 4:
            if (!!this.webView) return [3
            /*break*/
            , 5];
            Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA ticket operation requires UI for new users but no webview was received.");
            this.step.advance(Step.Done);
            this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "MSA ticket operation requires UI for new users but no webview was received."));
            return [2
            /*return*/
            ];

          case 5:
            return [4
            /*yield*/
            , this.getDtoken()];

          case 6:
            _a.sent();

            _a.label = 7;

          case 7:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.init = function () {
    this.step.advance(Step.Init);
    var operation = new InitializeTokenStack_1.InitializeTokenStack(this.telemetryClient, this.tokenStackComponents, this.httpClient, false // offlineMode
    );
    operation.execute();
    return operation.completionPromise;
  };

  GetMsaTicket.prototype.checkCache = function () {
    return __awaiter(this, void 0, void 0, function () {
      var ticket, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.msaTicketSet = this.tokenStackComponents.msaCache.getUser(this.msaUserId);

            if (!this.msaTicketSet) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA ticket operation received user ID that does not exist in cache.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "MSA ticket operation received user ID that does not exist in cache."));
              return [2
              /*return*/
              ];
            }

            if (!this.forceRefresh) {
              ticket = this.msaTicketSet.getTicket(this.scopes);

              if (ticket) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA ticket operation successfully served from cache.");
                result = {
                  msaUser: this.msaTicketSet,
                  ticket: ticket
                };
                this.step.advance(Step.Done);
                this.succeed(result);
                return [2
                /*return*/
                ];
              }
            } else {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA ticket operation ignoring cached tokens.");
            }

            if (!this.tokenStackComponents.config.deviceInfo.isWeb) return [3
            /*break*/
            , 2]; // NOXAL:WEB: Web can't be refreshed via refresh token
            // instead a redirect must be done to MSA and back

            return [4
            /*yield*/
            , this.refreshTicketSilentlyForWeb()];

          case 1:
            // NOXAL:WEB: Web can't be refreshed via refresh token
            // instead a redirect must be done to MSA and back
            _a.sent();

            return [3
            /*break*/
            , 4];

          case 2:
            this.refreshToken = this.msaTicketSet.refreshToken;

            if (Utils_1.isNullOrWhiteSpace(this.refreshToken)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA ticket operation received user ID that has no refresh token.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "MSA ticket operation received user ID that has no refresh token."));
              return [2
              /*return*/
              ];
            }

            this.msaRequestParams = this.msaTicketSet.requestParams;
            return [4
            /*yield*/
            , this.refreshTicketSilently()];

          case 3:
            _a.sent();

            _a.label = 4;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.refreshTicketSilently = function () {
    return __awaiter(this, void 0, void 0, function () {
      var response, request, params, reqParms, error_1, error_2, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!Utils_1.isNullOrWhiteSpace(this.msaUserId) && !Utils_1.isNullOrWhiteSpace(this.refreshToken), "MSA user ID and refresh token should not be null");
            Utils_1.assert(!!this.scopes && this.scopes.length > 0);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            request = new XalHttpRequest_1.XalHttpRequest(this.httpClient, http_1.Http.HttpRequestMethod.Post, this.tokenStackComponents.config.msaLoginEndpoint + Constants_1.OauthTokenPath);
            request.setHeader(Constants_1.HeaderKeys.ContentType, Constants_1.HeaderValues.ApplicationFormUrlEncoded);
            request.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            params = "client_id=" + this.tokenStackComponents.config.clientId + "&refresh_token=" + this.refreshToken + "&grant_type=refresh_token&scope=" + encodeURIComponent(this.scopesToRequest.join(" "));

            if (this.msaRequestParams && this.msaRequestParams.size > 0) {
              reqParms = Utils_1.createQuery(this.msaRequestParams);
              params = params.concat("&", reqParms);
            }

            request.setBody(params);
            this.step.advance(Step.RefreshTokenSilently);
            return [4
            /*yield*/
            , request.execute()];

          case 2:
            response = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.RefreshTokenSilently);
            return [3
            /*break*/
            , 4];

          case 3:
            error_1 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA silent refresh call failed: " + error_1);
            this.step.advance(Step.Done);
            this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.SilentRefreshFailed, "MSA silent refresh call failed: " + error_1));
            return [2
            /*return*/
            ];

          case 4:
            Utils_1.assert(!!response);

            if (!Utils_1.isHttpSuccesStatusCode(response.status) && response.status !== 400) {
              // 400 is returned when UI is needed
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA silent refresh call received unexpected HTTP status " + response.status);
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "MSA silent refresh call received unexpected HTTP status " + response.status));
              return [2
              /*return*/
              ];
            }

            _a.label = 5;

          case 5:
            _a.trys.push([5, 7,, 8]);

            return [4
            /*yield*/
            , this.processTokenResponse(response.data)];

          case 6:
            _a.sent();

            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA silent refresh call succeded"); // We were able to refresh silently the operation has already moved on to the next step
            // processTokenResponse eventually calls this.succeed, so don't call it here.

            return [2
            /*return*/
            ];

          case 7:
            error_2 = _a.sent();
            xalError = error_2;

            if (!xalError || !xalError.errorType || xalError.errorType !== XalInternalErrorType_1.XalInternalErrorType.UiRequired) {
              if (this.webView) {
                // This means an unexpected error occurred. We should log it but it's possible we can recover by going through the UI route so don't fail
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "MSA silent refresh call received an unexpected response " + error_2 + ". Attempting to continue.");
              } else {
                // We got an unexpected error and we cannot recover because we cannot show UI.
                Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA silent refresh call received an unexpected response " + error_2 + ".");
                this.step.advance(Step.Done);
                this.fail(error_2);
                return [2
                /*return*/
                ];
              }
            }

            return [3
            /*break*/
            , 8];

          case 8:
            if (!this.webView) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA ticket operation requires UI but no webview was received.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "MSA ticket operation requires UI but no webview was received."));
              return [2
              /*return*/
              ];
            } // Start on the process of refreshing the MSA ticket with UI


            return [4
            /*yield*/
            , this.getDtoken()];

          case 9:
            // Start on the process of refreshing the MSA ticket with UI
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.refreshTicketSilentlyForWeb = function () {
    return __awaiter(this, void 0, void 0, function () {
      var queryMap, queryString, url, webViewResult, error_3, xalError, resultUrl, error_4, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            // NOXAL:WEB: Web can't be refreshed via refresh token
            // instead a redirect must be done to MSA and back
            Utils_1.assert(!Utils_1.isNullOrWhiteSpace(this.msaUserId), "MSA user ID should not be null");
            Utils_1.assert(!!this.scopes && this.scopes.length > 0);

            if (!this.webView) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA ticket operation requires UI but no webview was received.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "MSA ticket operation requires UI but no webview was received."));
              return [2
              /*return*/
              ];
            }

            queryMap = new Map();
            queryMap.set("response_type", "token");
            queryMap.set("client_id", this.tokenStackComponents.config.clientId);
            queryMap.set("response_mode", "fragment");
            queryMap.set("scope", this.tokenStackComponents.config.userSignInScopes.join(" "));
            queryMap.set("redirect_uri", this.endUrl);
            queryMap.set("prompt", "none");
            queryString = Utils_1.createQuery(queryMap);
            url = new http_1.Url(this.tokenStackComponents.config.msaLoginEndpoint + Constants_1.OauthAuthorizePath);
            url.search = queryString;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , this.webView.showUrl(url.toString(), this.endUrl, true, // suppressUi. Clients can do this in an iframe without user interaction.
            framework_1.guid(), undefined)];

          case 2:
            webViewResult = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            xalError = error_3;

            if (xalError && xalError.errorType && xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.Aborted) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA refresh web view was manually closed by either user or client.");
              this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.GetMsaTicket, ITelemetryClient_1.EventAction.MsaSignInCanceled);
            }

            this.step.advance(Step.Done);
            this.fail(error_3);
            return [2
            /*return*/
            ];

          case 4:
            _a.trys.push([4, 6,, 7]);

            if (Utils_1.isNullOrWhiteSpace(webViewResult) || !Utils_1.isValidUri(webViewResult)) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Invalid repsonse URL received for silent MSA refresh for web");
            }

            resultUrl = new http_1.Url(webViewResult);
            return [4
            /*yield*/
            , this.parseFinalUrlForWebSilentRefresh(resultUrl.hash)];

          case 5:
            _a.sent();

            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA silent refresh call succeded"); // We were able to refresh silently the operation has already moved on to the next step
            // parseFinalUrl eventually calls this.succeed, so don't call it here.

            return [2
            /*return*/
            ];

          case 6:
            error_4 = _a.sent();
            xalError = error_4;

            if (!xalError || !xalError.errorType || xalError.errorType !== XalInternalErrorType_1.XalInternalErrorType.UiRequired) {
              if (this.webView) {
                // This means an unexpected error occurred. We should log it but it's possible we can recover by going through the UI route so don't fail
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "MSA silent refresh call received an unexpected response " + error_4 + ". Attempting to continue.");
              } else {
                // We got an unexpected error and we cannot recover because we cannot show UI.
                Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA silent refresh call received an unexpected response " + error_4 + ".");
                this.step.advance(Step.Done);
                this.fail(error_4);
                return [2
                /*return*/
                ];
              }
            }

            return [3
            /*break*/
            , 7];

          case 7:
            if (!this.webView) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA ticket operation requires UI but no webview was received.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "MSA ticket operation requires UI but no webview was received."));
              return [2
              /*return*/
              ];
            } // Start on the process of refreshing the MSA ticket with UI


            return [4
            /*yield*/
            , this.getDtoken()];

          case 8:
            // Start on the process of refreshing the MSA ticket with UI
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.processTokenResponse = function (responseRaw) {
    return __awaiter(this, void 0, void 0, function () {
      var responseData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            responseData = MsaTicketSet_1.MsaTicketSet.deserializeResponse(responseRaw);
            Utils_1.assert(Utils_1.isNullOrWhiteSpace(responseData.code)); // This should never be able to be returned from a token request

            if (!Utils_1.isNullOrWhiteSpace(responseData.error)) {
              // We've hit an OAuth level error
              if (responseData.error === "invalid_grant") {
                // This is the only error we recognize as expected
                Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA token response received an error " + responseData.error + ": " + responseData.errorDescription);
                throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "User interaction required");
              }

              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA token response received an error " + responseData.error + ": " + responseData.errorDescription);
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "MSA token response received an error: " + responseData.error + " " + responseData.errorDescription);
            }

            return [4
            /*yield*/
            , this.updateCacheAndSucceed(responseData)];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.updateCacheAndSucceed = function (tokenResponseData) {
    return __awaiter(this, void 0, void 0, function () {
      var prefix, ticketData, error_5, operationResult;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            prefix = "";

            switch (this.tokenStackComponents.config.titleType) {
              case AuthConfig_1.TitleType.ThirdParty:
                prefix = "d=";
                break;

              case AuthConfig_1.TitleType.FirstParty:
                prefix = "t=";
                break;

              default:
                Utils_1.assert(false);
                break;
            }

            if (this.tokenStackComponents.config.deviceInfo.isWeb) {
              // NOXAL:WEB: Web uses d= regardless of being first party
              prefix = "d=";
            }

            tokenResponseData.accessToken = prefix.concat(tokenResponseData.accessToken);
            this.step.advance(Step.UpdateCache);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , this.tokenStackComponents.msaCache.writeToken(this.msaTicketSet, this.msaRequestParams, tokenResponseData, this.tokenStackComponents.config.deviceInfo.isWeb)];

          case 2:
            ticketData = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            error_5 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA token cache update failed:  " + error_5);
            this.step.advance(Step.Done);
            this.fail(error_5);
            return [2
            /*return*/
            ];

          case 4:
            operationResult = {
              msaUser: ticketData.ticketSet,
              ticket: ticketData.scopedTicket,
              sisuClusterAffinity: this.sisuClusterAffinity,
              sisuSessionId: this.sisuSessionId
            };
            this.step.advance(Step.Done);
            this.succeed(operationResult);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.getDtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, dtoken;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.GetDtoken);
            operation = new GetDtoken_1.GetDtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, this.forceRefreshDtoken);
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            dtoken = _a.sent();
            this.step.advance(Step.GetDtoken);
            this.dtoken = dtoken;
            this.forceRefreshDtoken = false;
            Utils_1.assert(!!this.dtoken);
            return [4
            /*yield*/
            , this.callSisu()];

          case 2:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.callSisu = function () {
    return __awaiter(this, void 0, void 0, function () {
      var body, sisuUri, request, sisuNsalEntry, response, wasRequestSuccessful, xerr, affinity, reqParams, responseRequestParams, key, value, webViewResult, error_6, xalError;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!!this.dtoken && this.dtoken.isValid);
            this.step.advance(Step.CallSisuSignIn);
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "SISU signin call started");
            body = {
              AppId: this.tokenStackComponents.config.clientId,
              RedirectUri: this.endUrl,
              DeviceToken: this.dtoken.tokenValue,
              Sandbox: this.tokenStackComponents.config.sandbox,
              TokenType: this.tokenStackComponents.config.titleType === AuthConfig_1.TitleType.FirstParty ? "token" : "code",
              Offers: this.scopesToRequest
            };

            if (!Utils_1.isNullOrWhiteSpace(this.refreshToken) || this.skipSplash) {
              body.OmitSplashPage = true;
            }

            if (!Utils_1.isNullOrWhiteSpace(this.refreshToken)) {
              body.RefreshToken = this.refreshToken;
            }

            if (!Utils_1.isNullOrWhiteSpace(this.tokenStackComponents.config.deviceInfo.displayType)) {
              body.Query = {
                display: this.tokenStackComponents.config.deviceInfo.displayType
              };
            } // TODO(alkhayat): include Query fields, pkce code challenge
            // if (this.tokenStackComponents.config.titleType === TitleType.ThirdParty) {
            // }
            // NOXAL: XAL uses 64 random bytes base64url encoded. For JavaScript a GUID is easier


            this.webViewFlowId = framework_1.guid();

            if (body.Query) {
              body.Query.state = this.webViewFlowId;
            } else {
              body.Query = {
                state: this.webViewFlowId
              };
            }

            sisuUri = this.tokenStackComponents.config.sisuEndpoint + "/signin";
            request = new XalHttpRequest_1.XalHttpRequest(this.httpClient, http_1.Http.HttpRequestMethod.Post, sisuUri);
            request.setHeader(Constants_1.HeaderKeys.ContentType, Constants_1.HeaderValues.ApplicationJsonUtf8);
            request.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            request.setHeader(Constants_1.HeaderKeys.XblContractVersion, "1");
            request.setBody(JSON.stringify(body));
            sisuNsalEntry = this.tokenStackComponents.nsal.lookup(sisuUri);
            if (!sisuNsalEntry.Policy) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , request.signRequest(this.tokenStackComponents.xboxCache.deviceIdentity.key, sisuNsalEntry.Policy)];

          case 1:
            _a.sent();

            return [3
            /*break*/
            , 3];

          case 2:
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "NSAL indicates not signing SISU request.");
            _a.label = 3;

          case 3:
            return [4
            /*yield*/
            , request.execute()];

          case 4:
            response = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.CallSisuSignIn);
            wasRequestSuccessful = Utils_1.isHttpSuccesStatusCode(response.status);
            if (!(response.status === 403)) return [3
            /*break*/
            , 6];
            xerr = response.headers["x-err"];
            return [4
            /*yield*/
            , this.refreshDtokenAndRestart(xerr)];

          case 5:
            _a.sent();

            return [2
            /*return*/
            ];

          case 6:
            if (!wasRequestSuccessful) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU signin call received unexpected HTTP status " + response.status + ".");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "SISU signin call received unexpected HTTP status " + response.status));
              return [2
              /*return*/
              ];
            }

            _a.label = 7;

          case 7:
            this.sisuSessionId = response.headers["x-sessionid"];

            if (Utils_1.isNullOrWhiteSpace(this.sisuSessionId)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Session ID header missing from SISU signin response.");
            }

            this.sisuClusterAffinity = response.headers["x-cluster-affinity"];

            if (Utils_1.isNullOrWhiteSpace(this.sisuClusterAffinity)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Cluster Affinity header missing from SISU signin response.");
            } else {
              affinity = Utils_1.tryParseUrl(this.sisuClusterAffinity);

              if (!affinity) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Cluster Affinity header is invalid from SISU signin response.");
                this.sisuClusterAffinity = undefined;
              } else if (affinity.protocol !== "https:") {
                // We're guaranteed Url has canonicalized this to lowercase, and includes a trailing ':'
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Cluster Affinity header is not https from SISU signin response.");
                this.sisuClusterAffinity = undefined;
              }
            }

            if (response.data.MsaRequestParameters) {
              reqParams = new Map();
              responseRequestParams = response.data.MsaRequestParameters;

              for (key in responseRequestParams) {
                if (responseRequestParams.hasOwnProperty(key)) {
                  value = responseRequestParams[key];
                  reqParams.set(key, value);
                }
              }

              this.msaRequestParams = reqParams;
            }

            if (!response.data.MsaOauthRedirect || response.data.MsaOauthRedirect.length < 0) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU response did not contain a redirect URL.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "SISU response did not contain a redirect URL."));
              return [2
              /*return*/
              ];
            }

            this.rehydrationParams = new Map();

            if (this.callingOperation) {
              this.rehydrationParams.set("operation", this.callingOperation);
            }

            if (!Utils_1.isNullOrWhiteSpace(this.msaUserId)) {
              this.rehydrationParams.set("msaUserId", this.msaUserId);
            }

            if (this.msaRequestParams) {
              Array.from(this.msaRequestParams.entries()).forEach(function (pair) {
                return _this.rehydrationParams.set("MsaRequestParameters-" + pair["0"], pair["1"]);
              });
            }

            this.scopesToRequest.forEach(function (scope) {
              return _this.rehydrationParams.set("MsaScopes-" + scope, scope);
            });
            this.step.advance(Step.ShowMsaUi);
            _a.label = 8;

          case 8:
            _a.trys.push([8, 10,, 11]);

            return [4
            /*yield*/
            , this.webView.showUrl(response.data.MsaOauthRedirect, this.endUrl, false, // suppressUi
            this.webViewFlowId, this.rehydrationParams)];

          case 9:
            webViewResult = _a.sent();
            return [3
            /*break*/
            , 11];

          case 10:
            error_6 = _a.sent();
            xalError = error_6;

            if (xalError && xalError.errorType && xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.Aborted) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA sign in web view was manually closed by either user or client.");
              this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.GetMsaTicket, ITelemetryClient_1.EventAction.MsaSignInCanceled);
            }

            this.step.advance(Step.Done);
            this.fail(error_6);
            return [2
            /*return*/
            ];

          case 11:
            return [4
            /*yield*/
            , this.msaUiCallback(webViewResult)];

          case 12:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.refreshDtokenAndRestart = function (xerr) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.GetMsaTicket, "Invalid Dtoken error received", this.refreshingDtoken ? ITelemetryClient_1.ErrorLevel.Error : ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.BadDeviceIdentity);

            if (this.refreshingDtoken) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA ticket operation received bad token errors twice in a row. Failing out.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "MSA ticket operation received bad token errors twice in a row. Failing out."));
              return [2
              /*return*/
              ];
            } else {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "MSA ticket operation received bad token error. Retrying with fresh tokens.");
            } // Set a flag so we only restart like this once


            this.refreshingDtoken = true;
            this.forceRefreshDtoken = true; // Restart at step 1

            return [4
            /*yield*/
            , this.getDtoken()];

          case 1:
            // Restart at step 1
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.msaUiCallback = function (result) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        Utils_1.assert(this.step.currentStep === Step.ShowMsaUi);
        this.handleMsaFinalUrl(result);
        return [2
        /*return*/
        ];
      });
    });
  };

  GetMsaTicket.prototype.restoreRehydrationParams = function () {
    var _this = this;

    Utils_1.assert(!!this.rehydrationParams);
    this.msaRequestParams = new Map();
    Array.from(this.rehydrationParams.entries()).filter(function (pair) {
      return pair["0"].startsWith("MsaRequestParameters-");
    }).forEach(function (pair) {
      return _this.msaRequestParams.set(pair["0"], pair["1"]);
    });
    this.scopesToRequest = Array.from(this.rehydrationParams.entries()).filter(function (pair) {
      return pair["0"].startsWith("MsaScopes-");
    }).map(function (pair) {
      return pair["1"];
    });
    this.handleMsaFinalUrl(this.responseUrl);
  };

  GetMsaTicket.prototype.handleMsaFinalUrl = function (result) {
    var finalUrl;

    try {
      if (Utils_1.isNullOrWhiteSpace(result)) {
        throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "MSA sign in end URI is invalid.");
      }

      finalUrl = new http_1.Url(result);
    } catch (error) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA sign in end URI is invalid");
      this.step.advance(Step.Done);
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "MSA sign in end URI is invalid."));
      return;
    }

    switch (this.tokenStackComponents.config.titleType) {
      case AuthConfig_1.TitleType.ThirdParty:
        this.parseFinalUrl(finalUrl.search);
        break;

      case AuthConfig_1.TitleType.FirstParty:
        this.parseFinalUrl(finalUrl.hash);
        break;

      default:
        Utils_1.assert(false);
        this.step.advance(Step.Done);
        this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Unknown title type"));
        return;
    }
  };

  GetMsaTicket.prototype.parseFinalUrl = function (form) {
    return __awaiter(this, void 0, void 0, function () {
      var params, responseData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            params = Utils_1.parseQuery(form);
            responseData = MsaTicketSet_1.MsaTicketSet.deserializeFormResponse(params);
            if (!!Utils_1.isNullOrWhiteSpace(responseData.error)) return [3
            /*break*/
            , 1];
            this.step.advance(Step.Done); // MSA returns access_denied when the user presses the back
            // button, so we report it as a user abort

            if (responseData.error === "access_denied") {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA URL response indicated user abort.");
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Aborted, "MSA URL response indicated user abort."));
              return [2
              /*return*/
              ];
            } else {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA URL response returned an error: " + responseData.error + " - " + responseData.errorDescription);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "MSA URL response returned an error"));
              return [2
              /*return*/
              ];
            }

            return [3
            /*break*/
            , 5];

          case 1:
            if (!!Utils_1.isNullOrWhiteSpace(responseData.code)) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.exchangeCode(responseData.code)];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 5];

          case 3:
            return [4
            /*yield*/
            , this.updateCacheAndSucceed(responseData)];

          case 4:
            _a.sent();

            _a.label = 5;

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  }; // NOXAL:WEB: Web silent refresh returns an MSA url instead of and http response
  // so we have to parse out the refreshed data, however, parseFinalUrl fails this
  // operation if the url is invalid, whereas for silent refresh we only want to
  // throw to allow the caller an opportunity to recover by performing a full refresh


  GetMsaTicket.prototype.parseFinalUrlForWebSilentRefresh = function (form) {
    return __awaiter(this, void 0, void 0, function () {
      var params, responseData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            params = Utils_1.parseQuery(form);
            responseData = MsaTicketSet_1.MsaTicketSet.deserializeFormResponse(params);
            if (!!Utils_1.isNullOrWhiteSpace(responseData.error)) return [3
            /*break*/
            , 1];
            this.step.advance(Step.Done); // MSA returns access_denied when the user presses the back
            // button, so we report it as a user abort

            if (responseData.error === "access_denied") {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA URL response indicated user abort.");
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Aborted, "MSA URL response indicated user abort.");
            } else {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA URL response returned an error: " + responseData.error + " - " + responseData.errorDescription);
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "MSA URL response returned an error");
            }

            return [3
            /*break*/
            , 5];

          case 1:
            if (!!Utils_1.isNullOrWhiteSpace(responseData.code)) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.exchangeCode(responseData.code)];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 5];

          case 3:
            return [4
            /*yield*/
            , this.updateCacheAndSucceed(responseData)];

          case 4:
            _a.sent();

            _a.label = 5;

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetMsaTicket.prototype.exchangeCode = function (authorizationCode) {
    return __awaiter(this, void 0, void 0, function () {
      var tokenRequest, params, reqParms, response, error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "MSA code exchange call started");
            this.step.advance(Step.ExchangeAuthCode);
            tokenRequest = new XalHttpRequest_1.XalHttpRequest(this.httpClient, http_1.Http.HttpRequestMethod.Post, this.tokenStackComponents.config.msaLoginEndpoint + Constants_1.OauthTokenPath);
            tokenRequest.setHeader(Constants_1.HeaderKeys.ContentType, Constants_1.HeaderValues.ApplicationFormUrlEncoded);
            tokenRequest.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            params = "grant_type=authorization_code&code=" + authorizationCode + "&client_id=" + this.tokenStackComponents.config.clientId + "&redirect_uri=" + this.endUrl + "&scope=" + encodeURIComponent(this.scopesToRequest.join(" ")); // TODO(alkhayat): include pkce code verifier
            // if (this.tokenStackComponents.config.titleType === TitleType.ThirdParty) {
            //     params = params.concat("&", `code_verifier=${this.pkceCodeVerifier}`);
            // }

            Utils_1.assert(!!this.msaRequestParams);

            if (this.msaRequestParams && this.msaRequestParams.size > 0) {
              reqParms = Utils_1.createQuery(this.msaRequestParams);
              params = params.concat("&", reqParms);
            }

            tokenRequest.setBody(params);
            return [4
            /*yield*/
            , tokenRequest.execute()];

          case 1:
            response = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.ExchangeAuthCode);

            if (!Utils_1.isHttpSuccesStatusCode(response.status)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA code exchange call received unexpected HTTP status " + response.status);
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "MSA code exchange call received unexpected HTTP status " + response.status));
              return [2
              /*return*/
              ];
            }

            _a.label = 2;

          case 2:
            _a.trys.push([2, 4,, 5]);

            return [4
            /*yield*/
            , this.processTokenResponse(response.data)];

          case 3:
            _a.sent();

            return [3
            /*break*/
            , 5];

          case 4:
            error_7 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "MSA code exchange call failed with an error from MSA: " + error_7);
            this.step.advance(Step.Done);
            this.fail(error_7);
            return [2
            /*return*/
            ];

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return GetMsaTicket;
}(OperationBase_1.OperationBase);

exports.GetMsaTicket = GetMsaTicket;