import * as React from "react";
import * as Loadable from "react-loadable";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route, Switch } from "react-router";
import { Layout } from "antd";
import { ClubHub, User, Optional } from "@xbox/social-core";
import { clubsRedux, ReduxState } from "@xbox/social-redux";
import { telemetryService } from "../index";
import { FixedWidthContent, ClubMainContent } from "./Styles";
import Login from "./Login";

// pages imports
import { ClubSelectionContextProvider } from "./ClubSelectionContext";
import { GenericLoading } from "../common/GenericElements";
import { CurrentUserContextProvider } from "./CurrentUserContext";

const AsyncClubViewerContainer = Loadable({
    loader: () => import("./clubviewer/ClubViewer"),
    loading: GenericLoading,
});

export enum ClubPages {
    analytics = "analytics",
    feed = "feed",
    profile = "profile"
}

interface ClubPresenterContainerDispatchProps {
    handleItemFetch: (request: ClubHub.ClubRecommendationsRequest) => void;
    navigateHome: () => void;
    getClubDetails: (clubId: string) => void;
}

interface ClubPresenterContainerProps extends ClubPresenterContainerDispatchProps {
    currentUser: User;
    item: ClubHub.Club;
    match: any;
    currentChannel: ClubHub.Channel;
}

interface ClubPresenterContainerState {
}

class ClubPresenterContainer extends React.Component<ClubPresenterContainerProps, ClubPresenterContainerState> {
    constructor(props: ClubPresenterContainerProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.currentUser) {
            this.updateClubDetails(this.props.match.params.id);
        } else {
            this.props.navigateHome();
        }
    }

    componentDidUpdate(prevProps: ClubPresenterContainerProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.updateClubDetails(this.props.match.params.id);
        }
    }

    render() {
        telemetryService.trackPageView("ClubPresenter");
        let item = <GenericLoading />;
        let clubChanged = this.props.item && this.props.item.details.id !== this.props.match.params.id;

        let clubSelectionContext: Optional<any>;
        let currentUserContext: Optional<any>;
        if (this.props.item && !clubChanged) {
            let moderators = this.props.item.roster && this.props.item.roster.moderator && this.props.item.roster.moderator.filter(x => x.profile).map(x => x.profile.xuid);
            clubSelectionContext = { clubItem: this.props.item, clubId: this.props.item.details.id, clubModerators: moderators };

            let isModerator: boolean = false;
            let isOwner: boolean = false;
            let isMember: boolean = false;

            if (this.props.currentUser && this.props.item.settings) {
                isMember = this.props.item.settings.viewerRoles.roles.some(x => x === ClubHub.ClubRoles.Member);
                isModerator = this.props.item.settings.viewerRoles.roles.some(x => x === ClubHub.ClubRoles.Moderator);
                isOwner = this.props.item.details.owner && this.props.item.details.owner.xuid === this.props.currentUser.xuid;
                currentUserContext = { user: this.props.currentUser, isModerator: isModerator, isOwner: isOwner };
            }

            item = (
                <Switch>
                    <Route path="/app/:id" render={() => <AsyncClubViewerContainer isMember={isMember} isModerator={isModerator || isOwner} currentChannel={this.props.currentChannel} club={this.props.item}/>} />
                    <Route exact={true} path="/" component={Login} />
                </Switch>
            );
        }

        let background = this.props.item ? this.props.item.details.profile.backgroundImageUrl.value : "";
        return (
            <ClubSelectionContextProvider.Provider value={clubSelectionContext}>
                <CurrentUserContextProvider.Provider value={currentUserContext}>
                    <Layout style={{ flexDirection: "row", overflow: "hidden" }} >
                        <ClubMainContent
                            id="MainContentWindow" // used for element reference in InfiniteScroller
                            className="ClubPresenterContainer"
                            backgroundUrl={background}
                        >
                            <FixedWidthContent>
                                {item}
                            </FixedWidthContent>
                        </ClubMainContent>
                    </Layout>
                </CurrentUserContextProvider.Provider>
            </ClubSelectionContextProvider.Provider>
        );
    }
    private updateClubDetails(clubId: string) {
        if (this.props.getClubDetails) {
            this.props.getClubDetails(clubId);
        }
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleItemFetch: (request: ClubHub.ClubRecommendationsRequest) => dispatch(clubsRedux.actions.fetchClubItems()),
        navigateHome: () => dispatch(push("/")),
        getClubDetails: (clubId: string) => {
            dispatch(clubsRedux.actions.fetchClubDetails(clubId));
            dispatch(clubsRedux.actions.fetchSafetyThreshold(clubId));
        }
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return clubsRedux.selectors.getClubDetails(state);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClubPresenterContainer);
