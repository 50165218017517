import styled from "styled-components";
import { ContentContainer } from "../../../containers/Styles";
import { Button } from "../../../common/generic/Button";
import { media } from "../../../common/Generic.SnapPoints";

export const DetailedViewContainer = styled(ContentContainer)`
    display: block;
    position: relative;
    height: 100%;
    margin-top: 1rem;
    min-height: 10px;
    padding-bottom: 16px;
    word-break: break-word;

    .InlineFeedItemContainer {
        box-shadow: none;
        padding: 0 0 0 36px;
    }
    .GridFeedItemContainer {
        padding: 0 0 0 36px;
    }

    .FullFeedItemContainer {
        .UserPostPreviewImage {
            height: 200px;
            width: auto;
        }
    }

    ${media.medium`padding-bottom: 12px;`}
    ${media.small`
        padding: 12px 12px 8px 12px;
        margin: 0 8px;
    `}
`;

export const BackButton = styled(Button)`
    position: absolute;
    top: 5px;
    left: 0;
    border: 0px;
    font-size: 20px;
    color: ${props => props.theme.blueRest};
    background: transparent;

    span {
        color: ${props => props.theme.blueRest};
    }
`;

export const CombinedDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
`;

export const CommentsHolder = styled.div`
    overflow: hidden;
    height: 100%;
    height: stretch;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    .ContentBox {
        height: inherit;
    }
`;
