import * as React from "react";
import { ActivityFeedHeaderButtons } from "../feeditems/base/ActivityFeedHeaderButtons";
import { FeedViewType, ScrollType } from "../infiniteScrollers/base/InfiniteScrollTypes";
import { BaseActivityFeed, BaseActivityFeedContainerProps } from "./BaseActivityFeed";

export default class ClublessActivityFeed extends BaseActivityFeed {
    constructor(props: BaseActivityFeedContainerProps) {
        super(props);
        this.storageKey = "clublessFeedType";
        this.state = {
            showShareItemModal: false,
            feedViewType: this.props.forcedFeedType || this.loadStoredViewType()
        };
    }

    getScrollerTypeByViewType = () => {
        return this.state.feedViewType === FeedViewType.GridView ? ScrollType.MansoryWindow : ScrollType.ListStrict;
    }

    getHeaderButtons = () => {
        return (
            <ActivityFeedHeaderButtons
                isModerator={false}
                switchToFeedTools={() => { return; }}
                onRefreshClick={this.refreshFeed}
                showRefresh={true}
                feedViewType={this.state.feedViewType}
                setFeedViewType={(type: any) => this.setFeedViewType(type)}
            />
        );
    }
}
