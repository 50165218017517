import * as React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";

import { telemetryService } from "../../index";
import { Localize } from "../../common/translation/Localize";
import { TranslationBaseProps } from "../../common/translation/TranslationTypes";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import { MenuItemIcon, MenuItemLabel } from "../layout/ClubMenuTabs.Styles";

interface DeepLinkMenuStateProps {}

export interface MenuItem {
    titleKey: string;
    linkKey: string;
    link: string;
    icon?: Glyphs;
}

interface DeepLinkMenuPassedProps {
    items: MenuItem[];
    mode: any;
}

interface DeepLinkMenuDispatchProps {
    handleNavigation: (route: string) => void;
}

type DeepLinkMenuProps = DeepLinkMenuStateProps & DeepLinkMenuDispatchProps & TranslationBaseProps & DeepLinkMenuPassedProps;

class DeepLinkMenu extends React.Component<DeepLinkMenuProps> {

    constructor(props: DeepLinkMenuProps) {
        super(props);
    }

    getDefaultIndex = () => {
        let path = window.location.pathname;
        let idxMap = _.map(this.props.items, (item: MenuItem) => path.indexOf(item.link) > -1 );
        return Math.max(idxMap.indexOf(true), 0);
    }

    onMenuClick = (param: ClickParam) => {
        telemetryService.trackEvent("NavigationMenuItemClick", { "PageName: ": param.key });
        this.props.handleNavigation(param.key);
    }

    generateItems = (items: MenuItem[]): JSX.Element[] => {
        let results = [];
        for (var i = 0; i < items.length; i++) {
            let menuItem = items[i].icon ? (
                 <Menu.Item key={items[i].link}>
                    <MenuItemIcon><Icon type={items[i].icon!} /></MenuItemIcon>
                    <MenuItemLabel>{Localize(items[i].titleKey)}</MenuItemLabel>
                </Menu.Item>
            ) : (
                <Menu.Item key={items[i].link}>
                    {Localize(items[i].titleKey)}
                </Menu.Item>
            );
            results.push(menuItem);
        }
        return results;
    }

    public render() {
        return (
            <Menu defaultSelectedKeys={[this.props.items[this.getDefaultIndex()].link]} onClick={this.onMenuClick} mode={this.props.mode}>
                {this.generateItems(this.props.items)}
            </Menu>
        );
    }
}

const mapStateToProps = (state: any): DeepLinkMenuStateProps => {
    return {};
};

const mapDispatchToProps = (dispatch: any): DeepLinkMenuDispatchProps => {
    return {
        handleNavigation: (route: string) => dispatch(push(`${route}`))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeepLinkMenu);
