"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var fragmentTypes = {
  "__schema": {
    "types": [{
      "kind": "SCALAR",
      "name": "String",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Boolean",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Float",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Int",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "ID",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Date",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Decimal",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__Schema",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__Type",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "__TypeKind",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__Field",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__InputValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__EnumValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "__Directive",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "__DirectiveLocation",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubType",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ClubMemberRole",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "PreferredColorInput",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "ClubProfileUpdateInput",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "ClubProfileUpdateRequestContractInput",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubPresenceItem",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubRosterItem",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubRoster",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubSetting",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ChatSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "FeedSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "LfgSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfileSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "RosterSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubSettings",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfileBooleanValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfileStringValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfileStringArrayValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfileTitleArrayValue",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubProfile",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ClubDetails",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Club",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ReportClubItemType",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "ReportClubItemInput",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ReportClubItemResult",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Report",
      "possibleTypes": null
    }, {
      "kind": "INTERFACE",
      "name": "ReportedItem",
      "possibleTypes": [{
        "name": "ReportedActivityFeedItem"
      }, {
        "name": "ReportedChatItem"
      }, {
        "name": "ReportedCommentItem"
      }]
    }, {
      "kind": "OBJECT",
      "name": "ReportedActivityFeedItem",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ReportedChatItem",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ReportedCommentItem",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "DeleteReportedItemReason",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "ReportedItemsDeleteRequestInput",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "DeleteReportedItemsInput",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ChannelMessageType",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ChannelType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Channel",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "FlagType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ChannelMessage",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ChannelMessages",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ChannelMessagesBatch",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "DirectionType",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "ChannelInput",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "PreferredColor",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "UserPresence",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "UserProfile",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "User",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Me",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ProfileSettingId",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ProfileSetting",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ProfileSettings",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "ActivityItemType",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "PostType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Stat",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "LocatorType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "GameMediaContentLocator",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "PostDetails",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Recommendations",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ItemInfo",
      "possibleTypes": null
    }, {
      "kind": "INTERFACE",
      "name": "ActivityItem",
      "possibleTypes": [{
        "name": "TextPost"
      }, {
        "name": "GameDVR"
      }, {
        "name": "Screenshot"
      }, {
        "name": "BroadcastStart"
      }, {
        "name": "BroadcastEnd"
      }, {
        "name": "Achievement"
      }, {
        "name": "UserPost"
      }, {
        "name": "Container"
      }, {
        "name": "SocialRecommendation"
      }]
    }, {
      "kind": "OBJECT",
      "name": "TextPost",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "GameDVR",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Screenshot",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "BroadcastStart",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "BroadcastEnd",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Achievement",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "UserPost",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Container",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "SocialRecommendation",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Feed",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "TimelineType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Timeline",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "TimelineInput",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "PostTypeData",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "PostTypeDataInput",
      "possibleTypes": null
    }, {
      "kind": "INPUT_OBJECT",
      "name": "CreatePostInput",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "PostResult",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Comment",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Share",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Like",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Comments",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Shares",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Likes",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "Category",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Topic",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Broadcast",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "GlobalTrendingResult",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "ContentCollectionResult",
      "possibleTypes": null
    }, {
      "kind": "ENUM",
      "name": "TitleType",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Title",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Query",
      "possibleTypes": null
    }, {
      "kind": "OBJECT",
      "name": "Mutation",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "ClubId",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "UserId",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "ChannelMessageId",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "TitleId",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "Uri",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "PostUri",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "CommentUri",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "CommentId",
      "possibleTypes": null
    }, {
      "kind": "SCALAR",
      "name": "CollectionId",
      "possibleTypes": null
    }]
  }
};
exports.default = fragmentTypes;