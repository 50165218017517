import * as React from "react";
import * as _ from "lodash";
import Moment from "react-moment";
import { Select, Button } from "antd";
import { MediaHydrationDataStates } from "@xbox/social-views";
import { ContentLocatorType, Mediahub, edsImageResizer, EdsImageSize, TitleHub } from "@xbox/social-core";
import { FetchMediaItemsPayload, FetchMediaTitlesState } from "@xbox/social-redux";
import { PromiseCompletionSource } from "@xbox/framework";

import * as fts from "./../FeedTools.Styles";
import { getThumbnailLocator } from "./../Utils";
import { GenericVideoPlayer, GenericLoading, Icon } from "../../../common/GenericElements";
import { Localize } from "../../../common/translation/Localize";
import { Glyphs } from "../../../common/Generic.Icons";
import { ThemedSelect } from "../../../common/Generic.Styles";
import { ThemedModal } from "../../../containers/Styles";
import { IsDevMode } from "../../../common/GenericChecks";
import { fileSizeFormatter } from "../Utils";

const Option = Select.Option;

enum FilterOrder {
    Increasing = "Increasing",
    Decreasing = "Decreasing"
}

export interface MediaManagerProps {
    fetchMediaItems: (payload: FetchMediaItemsPayload, isVideo: boolean) => void;
    deleteMediaItem:  (contentId: string, isVideo: boolean) => void;
    getUserMediaTitles: () => void;
    hydrationStates: MediaHydrationDataStates;
}

export interface MediaManagerState {
    selectedItem: Mediahub.MediahubItem | null;
    isVideo: boolean;
    dataSource: any[];
    titleId: string;
    sortingList: any[];
    filterOrder?: FilterOrder;
}

// This Class is supposed to be treated as an abstract class
export abstract class ManageMedia extends React.Component<MediaManagerProps, MediaManagerState> {
    protected payload: FetchMediaItemsPayload = {
        maxPageSize: 20,
        continuationToken: "",
        sort: []
    };
    protected listData: any[] = [];
    private optionsListScreenShots: any = [];
    private optionsListGameClips: any = [];
    private showLoader = true;
    private listItemsUpdated?: PromiseCompletionSource<boolean>;

    constructor(props: any) {
        super(props);
        this.state = {
            selectedItem: null,
            isVideo: false,
            dataSource: [],
            titleId: "",
            sortingList: [],
        };
    }

    componentWillMount() {
        this.props.getUserMediaTitles();
    }

    componentWillUpdate(nextProps: any) {
        let options: FetchMediaTitlesState = nextProps.hydrationStates.titleOptions;
        if (options && options.data && options.data !== this.props.hydrationStates.titleOptions.data) {
            this.optionsListScreenShots = _.map(options.data.screenshotTitles, (item: TitleHub.Title) => this.getGameOption(item.titleId, item.name, item.displayImage));
            this.optionsListGameClips = _.map(options.data.gameclipTitles, (item: TitleHub.Title) => this.getGameOption(item.titleId, item.name, item.displayImage));

            let isVideo = this.state.isVideo;
            this.setState({sortingList: isVideo ? this.optionsListGameClips : this.optionsListScreenShots});
        }
    }

    resetSelectedState = () => {
        this.setState({selectedItem: null});
    }

    updateList = (list: Mediahub.MediahubItem[], isVideo: boolean) => {
        let sortedList = this.filterMediaByFileSize(list, this.state.filterOrder);
        this.setState({isVideo: isVideo});
        this.listData = _.map(sortedList, (item) => this.getMediaElement(isVideo, item));
        this.showLoader = false;
        if (this.listItemsUpdated) {
            this.listItemsUpdated.resolve(true);
        }
    }

    // Generic Delete
    confirmDelete = (item: Mediahub.MediahubItem, isVideo: boolean) => {
        this.setState({selectedItem: item, isVideo: isVideo});
    }

    handleDelete = () => {
        if (!this.state.selectedItem) {
            return;
        }

        this.props.deleteMediaItem(this.state.selectedItem.contentId, this.state.isVideo);
        this.resetSelectedState();
    }

    loadMoreMedia = () => {
        console.log("Load More Media");
    }

    filterTitleBy = (value: string) => {
        // prevent repeat filtering
        if (this.payload.filterKey === value) {
            return;
        }

        this.resetSelectedState();
        this.showLoader = true;
        this.payload.continuationToken = "";
        this.payload.filterKey = value;
        this.loadMoreMedia();
    }

    filterMediaByFileSize = (list: Mediahub.MediahubItem[], order?: FilterOrder) => {
        let result = _.cloneDeep(list);
        if (order === FilterOrder.Decreasing) {
            result.sort((a: Mediahub.MediahubItem, b: Mediahub.MediahubItem) => b.contentLocators[0].fileSize - a.contentLocators[0].fileSize);
        } else if (order === FilterOrder.Increasing) {
            result.sort((a: Mediahub.MediahubItem, b: Mediahub.MediahubItem) => a.contentLocators[0].fileSize - b.contentLocators[0].fileSize);
        }
        return result;
    }

    requestUpdate = (): Promise<boolean> => {
        if (!this.payload.continuationToken) {
            return Promise.resolve(false);
        }

        this.loadMoreMedia();
        this.listItemsUpdated = new PromiseCompletionSource<boolean>();
        return this.listItemsUpdated.promise;
    }

    getAllTitleItem() {
        return (
            <Option key={""} value={""}>
                {Localize("feedtools.allGames")}
            </Option>
        );
    }

    getGameOption(gameId: string, gameName: string, gameImage?: string) {
        let image = gameImage ? gameImage : "";
        return (
            <Option key={gameId} value={gameId}>
                <fts.PlayedTitleImage src={edsImageResizer(image, EdsImageSize._100x100)} />
                {gameName}
            </Option>
        );
    }

    getMediaElement(isVideo: boolean, item: Mediahub.MediahubItem) {
        let preview = <div/>;

        if ( isVideo ) {
            preview = <GenericVideoPlayer posterUrl={getThumbnailLocator(item.contentLocators, ContentLocatorType.ThumbSmall)} downloadUri={getThumbnailLocator(item.contentLocators, ContentLocatorType.Download)} />;
        } else {
            preview = <fts.FitImage src={getThumbnailLocator(item.contentLocators, ContentLocatorType.ThumbSmall)} alt={item.contentId}/>;
        }

        return (
            <fts.MediaManagerContainer key={item.contentId}>
                <fts.PreviewWrapper>
                    {preview}
                </fts.PreviewWrapper>
                <fts.MediaManagerButtons>
                    <fts.XblItemTitle>{item.titleName}</fts.XblItemTitle>
                    <div style={{margin: "15px 0"}}>
                        <div>{item.resolutionWidth && item.resolutionHeight && `${item.resolutionWidth}x${item.resolutionHeight}`}</div>
                        <div>{item.contentLocators[0].fileSize ? `${Localize("feedtools.fileSize")} ${fileSizeFormatter(item.contentLocators[0].fileSize)}` : null}</div>
                        <div>{Localize("generic.uploaded")}: <Moment fromNow={true}>{item.dateUploaded || item.uploadDate}</Moment></div>
                    </div>
                    {IsDevMode() && <Button onClick={() => this.confirmDelete(item, isVideo)}  type="danger"><Icon type={Glyphs.Delete}/> {Localize("generic.delete")}</Button>}
                </fts.MediaManagerButtons>
            </fts.MediaManagerContainer>
        );
    }

    getList() {
        if (this.showLoader) {
            return (<GenericLoading/>);
        } else {
            return (
            <section>
                {this.listData.map((element, i) => (<article key={i}>{element}</article>))}
            </section>
            );
        }
    }

    handleSearch = (value: string) => {
        let userHasList = [];
        if (!this.state.isVideo) {
            userHasList = this.optionsListScreenShots;
        } else {
            userHasList = this.optionsListGameClips;
        }
        this.setState({dataSource: _.filter(userHasList, (item) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1 )});
    }

    onGameIdChanged = (e: any) => {
        let titleId = e.toString();
        this.setState({titleId});
        this.filterTitleBy(titleId);
    }

    onOrderChanged = (e: any) => {
        this.setState({filterOrder: e});
    }

    render() {
        return (
            <div>
                <ThemedModal
                    visible={this.state.selectedItem !== null}
                    title={Localize("uploadMedia.modal.deletePrompt")}
                    onCancel={this.resetSelectedState}
                    footer={(
                        <div>
                            <Button onClick={this.resetSelectedState}>
                                {Localize("generic.cancel")}
                            </Button>
                            <Button onClick={this.handleDelete}  type="danger">
                                <Icon type={Glyphs.Delete}/> {Localize("generic.delete")}
                            </Button>
                        </div>
                    )}
                >
                    <p>{Localize("uploadMedia.modal.deleteConfirmText")}</p>
                </ThemedModal>
                <fts.FlexDiv>
                    <fts.FilterMenuDiv>
                        <fts.FilterTextHeader>
                            {Localize("feedtools.filterMediaByGame")}
                        </fts.FilterTextHeader>
                        <ThemedSelect
                            aria-label={Localize("feedtools.filterMediaByGame")}
                            showSearch={true}
                            style={{width: "100%", maxWidth: "250px"}}
                            placeholder={Localize("uploadMedia.associatedGamePlaceholder")}
                            value={this.state.titleId ? this.state.titleId : undefined}
                            onChange={this.onGameIdChanged}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {this.getAllTitleItem()}
                            {this.state.sortingList}
                        </ThemedSelect>
                    </fts.FilterMenuDiv>
                    <fts.FilterMenuDiv>
                        <fts.FilterTextHeader>
                            {Localize("feedtools.filterMediaByFileSize")}
                        </fts.FilterTextHeader>
                        <ThemedSelect
                            aria-label={Localize("feedtools.filterMediaByFileSize")}
                            allowClear={true}
                            style={{width: "100%", maxWidth: "200px"}}
                            placeholder={Localize("uploadMedia.associatedOrderPlaceholder")}
                            value={this.state.filterOrder}
                            onChange={this.onOrderChanged}
                        >
                            <Option key={FilterOrder.Decreasing} value={FilterOrder.Decreasing}>{Localize("feedtools.fromBigToSmall")}</Option>
                            <Option key={FilterOrder.Increasing} value={FilterOrder.Increasing}>{Localize("feedtools.fromSmallToBig")}</Option>
                        </ThemedSelect>
                    </fts.FilterMenuDiv>
                </fts.FlexDiv>
                {this.getList()}
            </div>
        );
    }
}
