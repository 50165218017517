"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityFeedDenormalizer = exports.activityFeedNormalizer = void 0;

var normalizr_1 = require("normalizr");

var feed = new normalizr_1.schema.Entity("feed", undefined, {
  idAttribute: "feedItemId"
});
var feedList = [feed];

var activityFeedNormalizer = function activityFeedNormalizer(activities) {
  return normalizr_1.normalize(activities, feedList);
};

exports.activityFeedNormalizer = activityFeedNormalizer;

var activityFeedDenormalizer = function activityFeedDenormalizer(activityIds, entities) {
  return normalizr_1.denormalize(activityIds, feedList, entities);
};

exports.activityFeedDenormalizer = activityFeedDenormalizer;