"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PostCommentsActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var PostCommentsActions;

(function (PostCommentsActions) {
  // action creators
  PostCommentsActions.fetchFeedComments = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS");
  PostCommentsActions.fetchFeedCommentsCompleted = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS_COMPLETED");
  PostCommentsActions.fetchFeedCommentsInProgress = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS_INPROGRESS");
})(PostCommentsActions = exports.PostCommentsActions || (exports.PostCommentsActions = {}));