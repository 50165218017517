"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClubActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ClubActions;

(function (ClubActions) {
  // ACTION CREATORS
  // Club List Actions /// Club Info Actions
  ClubActions.fetchClubItems = redux_ts_simple_1.createAction("CLUB::CLUB_FETCH");
  ClubActions.clubFetchCompleted = redux_ts_simple_1.createAction("CLUB::CLUB_FETCH_COMPLETED");
  ClubActions.clubFetchInProgress = redux_ts_simple_1.createAction("CLUB::CLUB_FETCH_INPROGRESS");
  ClubActions.requestToJoinClub = redux_ts_simple_1.createAction("CLUB::REQUEST_TO_JOIN_CLUB");
  ClubActions.fetchMyClubs = redux_ts_simple_1.createAction("CLUB::FETCH_MY_CLUBS"); // Clear State Action

  ClubActions.clearState = redux_ts_simple_1.createAction("CLUB::CLEAR_STATE"); // Club Details

  ClubActions.fetchClubFeedItems = redux_ts_simple_1.createAction("CLUB::FEED_FETCH");
  ClubActions.fetchClubFeedItemsPreview = redux_ts_simple_1.createAction("CLUB::FEED_FETCH_PREVIEW");
  ClubActions.clubFeedFetchCompleted = redux_ts_simple_1.createAction("CLUB::FEED_FETCH_COMPLETED");
  ClubActions.clubFeedFetchInProgress = redux_ts_simple_1.createAction("CLUB::FEED_FETCH_INPROGRESS");
  ClubActions.clearCurrentClubDetails = redux_ts_simple_1.createAction("CLUB::CLEAR_CURRENTCLUB");
  ClubActions.fetchSafetyThreshold = redux_ts_simple_1.createAction("CLUB::FETCH_SAFETY_THRESHOLD");
  ClubActions.fetchSafetyInProgress = redux_ts_simple_1.createAction("CLUB::FETCH_SAFETY_THRESHOLD_INPROGRESS");
  ClubActions.fetchSafetyCompleted = redux_ts_simple_1.createAction("CLUB::FETCH_SAFETY_THRESHOLD_COMPLETED");
  ClubActions.fetchClubSafetyThreshold = redux_ts_simple_1.createAction("CLUB::FETCH_SAFETY_THRESHOLD");
  ClubActions.updateSafetyThreshold = redux_ts_simple_1.createAction("CLUB::UPDATE_SAFETY_THRESHOLD"); // Club Feed

  ClubActions.fetchClubDetails = redux_ts_simple_1.createAction("CLUB:DETAILS_FETCH");
  ClubActions.clubDetailsFetchCompleted = redux_ts_simple_1.createAction("CLUB::DETAILS_FETCH_COMPLETED");
  ClubActions.clubDetailsFetchInProgress = redux_ts_simple_1.createAction("CLUB::DETAILS_FETCH_INPROGRESS");
  ClubActions.clubUpdateProperty = redux_ts_simple_1.createAction("CLUB::UPDATE_CLUB_PROPERTY");
  ClubActions.createClubChannel = redux_ts_simple_1.createAction("CLUB::CREATE_CHANNEL");
  ClubActions.moveChannel = redux_ts_simple_1.createAction("CLUB::MOVE_CHANNEL");
  ClubActions.moveChannelComplete = redux_ts_simple_1.createAction("CLUB::MOVE_CHANNEL_COMPLETE");
  ClubActions.reorderChannel = redux_ts_simple_1.createAction("CLUB::REORDER_CHANNEL");
  ClubActions.reorderChannelGroup = redux_ts_simple_1.createAction("CLUB::REORDER_CHANNEL_GROUP");
  ClubActions.deleteClubChannel = redux_ts_simple_1.createAction("CLUB::DELETE_CHANNEL");
  ClubActions.deleteClubChannelComplete = redux_ts_simple_1.createAction("CLUB::DELETE_CHANNEL_COMPLETE");
  ClubActions.likedClubFeedItem = redux_ts_simple_1.createAction("CLUB::SEND_LIKE_FEED");
  ClubActions.hasLikedClubFeed = redux_ts_simple_1.createAction("CLUB::HAS_LIKED_FEED"); // Club Feed Item

  ClubActions.postTextClubFeedItem = redux_ts_simple_1.createAction("CLUB::SEND_FEED_ITEM");
  ClubActions.hydrateWebLink = redux_ts_simple_1.createAction("CLUB::HYDRATE_WEB_LINK");
  ClubActions.hydrateInProgress = redux_ts_simple_1.createAction("CLUB::HYDRATE_INPROGRESS");
  ClubActions.hydrateCompleted = redux_ts_simple_1.createAction("CLUB::HYDRATE_COMPLETED"); // Club Roster

  ClubActions.clubRosterFetchCompleted = redux_ts_simple_1.createAction("CLUB::ROSTER_FETCH_COMPLETED");
  ClubActions.clubRosterFetchInProgress = redux_ts_simple_1.createAction("CLUB::ROSTER_FETCH_INPROGRESS"); // Club Settings

  ClubActions.updateClubSettings = redux_ts_simple_1.createAction("CLUB:CLUB_SETTINGS_UPDATE");
  ClubActions.updateClubSettingsCompleted = redux_ts_simple_1.createAction("CLUB::CLUB_SETTINGS_UPDATE_COMPLETED");
  ClubActions.updateClubSettingsInProgress = redux_ts_simple_1.createAction("CLUB::CLUB_SETTINGS_UPDATE_INPROGRESS");
  ClubActions.deleteClub = redux_ts_simple_1.createAction("CLUB:CLUB_DELETE");
  ClubActions.deleteClubInprogress = redux_ts_simple_1.createAction("CLUB::CLUB_DELETE_INPROGRESS");
  ClubActions.cancelDeleteClub = redux_ts_simple_1.createAction("CLUB:CLUB_CANCEL_DELETE");
  ClubActions.cancelDeleteClubInprogress = redux_ts_simple_1.createAction("CLUB::CLUB_DELETE_CANCEL_INPROGRESS"); // Club Moderation

  ClubActions.clubModerationFetchQueue = redux_ts_simple_1.createAction("CLUB::MODERATION_FETCH_QUEUE");
  ClubActions.clubModerationFetchCompleted = redux_ts_simple_1.createAction("CLUB::MODERATION_FETCH_COMPLETED");
  ClubActions.clubModerationFetchInProgress = redux_ts_simple_1.createAction("CLUB::MODERATION_FETCH_INPROGRESS");
  ClubActions.clubModerationActionInProgress = redux_ts_simple_1.createAction("CLUB::MODERATION_ACTION_INPROGRESS");
  ClubActions.clubModerationAction = redux_ts_simple_1.createAction("CLUB::MODERATION_ACTION");
  ClubActions.clubUpdateModerationQueue = redux_ts_simple_1.createAction("CLUB::UPDATE_MODERATION_QUEUE");
  ClubActions.clubModerationQueueSearchFilter = redux_ts_simple_1.createAction("CLUB::QUEUE_SEARCH_FILTER_ACTION");
  ClubActions.clubModerationQueueTypeFilter = redux_ts_simple_1.createAction("CLUB::QUEUE_TYPE_FILTER_ACTION"); // Club Invite Queue

  ClubActions.clubInviteQueueFetchQueue = redux_ts_simple_1.createAction("CLUB::INVITE_FETCH_QUEUE");
  ClubActions.clubInviteQueueFetchCompleted = redux_ts_simple_1.createAction("CLUB::INVITE_FETCH_COMPLETED");
  ClubActions.clubInviteQueueFetchInProgress = redux_ts_simple_1.createAction("CLUB::INVITE_FETCH_INPROGRESS");
  ClubActions.clubInviteQueueRequestAction = redux_ts_simple_1.createAction("CLUB::INVITE_REQUEST_ACTION");
  ClubActions.clubUpdateInviteQueue = redux_ts_simple_1.createAction("CLUB::UPDATE_INVITE_QUEUE");
  ClubActions.clubInviteQueueSearchFilter = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_SEARCH_FILTER_ACTION");
  ClubActions.clubInviteQueueFilter = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_FILTER_ACTION");
  ClubActions.clubInviteQueueActionInProgress = redux_ts_simple_1.createAction("CLUB::INVITE_ACTION_INPROGRESS");
  ClubActions.clubInviteGetFilter = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_GET_FILTER");
  ClubActions.clubInviteSaveFilter = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_SAVE_FILTER");
  ClubActions.clubInviteSaveFilterCompleted = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_SAVE_FILTER_COMPLETED");
  ClubActions.clubInviteGetFilterCompleted = redux_ts_simple_1.createAction("CLUB::INVITE_QUEUE_GET_FILTER_COMPLETED");
  ClubActions.clubUserGetStats = redux_ts_simple_1.createAction("CLUB::USER_GET_STATS");
  ClubActions.clubUserGetStatsInProgress = redux_ts_simple_1.createAction("CLUB::USER_GET_STATS_INPROGRESS");
  ClubActions.clubUserGetStatsCompleted = redux_ts_simple_1.createAction("CLUB::USER_GET_STATS_COMPLETED"); // Club Roster

  ClubActions.clubRosterSearchFilter = redux_ts_simple_1.createAction("CLUB::ROSTER_SEARCH_FILTER_ACTION");
  ClubActions.clubRosterTypeFilter = redux_ts_simple_1.createAction("CLUB::ROSTER_TYPE_FILTER_ACTION");
  ClubActions.clubRosterUpdateRole = redux_ts_simple_1.createAction("CLUB::ROSTER_UPDATE_ROLE_ACTION");
  ClubActions.clubRosterUpdateRoleProperty = redux_ts_simple_1.createAction("CLUB::ROSTER_UPDATE_ROLE_PROPERTY_ACTION");
  ClubActions.clubRosterReportMember = redux_ts_simple_1.createAction("CLUB::ROSTER_REPORT_MEMBER_ACTION");
  ClubActions.clubRosterUpdateSearchValue = redux_ts_simple_1.createAction("CLUB::ROSTER_UPDATE_SEARCH_VALUE");
  ClubActions.clubRosterInviteUser = redux_ts_simple_1.createAction("CLUB::ROSTER_INVITE_USER_ACTION"); // Channels

  ClubActions.clubChannelSelected = redux_ts_simple_1.createAction("CLUB::SELECT_CHANNEL_ACTION");
  ClubActions.clearClubChannel = redux_ts_simple_1.createAction("CLUB::CLEAR_CHANNEL_ACTION");
  ClubActions.fetchClubFeedChannels = redux_ts_simple_1.createAction("CLUB::FETCH_CLUB_FEED_CHANNELS_ACTION");
  ClubActions.fetchClubFeedChannelsCompleted = redux_ts_simple_1.createAction("CLUB::FETCH_CLUB_FEED_CHANNELS_COMPLETED"); // Share

  ClubActions.fetchShareFeedChannels = redux_ts_simple_1.createAction("CLUB::FETCH_SHARE_FEED_CHANNELS_ACTION");
  ClubActions.fetchShareFeedChannelsCompleted = redux_ts_simple_1.createAction("CLUB::FETCH_SHARE_FEED_CHANNELS_COMPLETED");
})(ClubActions = exports.ClubActions || (exports.ClubActions = {}));