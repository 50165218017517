"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserManager = void 0;

var inversify_1 = require("inversify");

var XSocialSymbols_1 = require("../../typings/XSocialSymbols");

var User_1 = require("./User");

var FeedQueries_1 = require("../../queries/FeedQueries");

var CookieUtils_1 = require("./CookieUtils");

var UserManager =
/** @class */
function () {
  function UserManager(authProvider, xblGraphQlService, appConfig) {
    this.authProvider = authProvider;
    this.xblGraphQlService = xblGraphQlService;
    this.appConfig = appConfig;
    this.cookieUtils = new CookieUtils_1.CookieUtils();
  }

  Object.defineProperty(UserManager.prototype, "currentUser", {
    get: function get() {
      return this.user;
    },
    enumerable: false,
    configurable: true
  }); // Signs the user in silently
  // If the user is in memory, returns that without further processing
  // Otherwise, attempts to load the signed in user from storage

  UserManager.prototype.signInSilently = function (cookies) {
    return __awaiter(this, void 0, void 0, function () {
      var xalUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.currentUser) {
              return [2
              /*return*/
              , this.currentUser];
            }

            return [4
            /*yield*/
            , this.authProvider.signInSilently()];

          case 1:
            xalUser = _a.sent();
            this.cookieUtils.setSignedInCookie(cookies, this.appConfig);
            return [4
            /*yield*/
            , this.buildUserWithProfile(xalUser)];

          case 2:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  }; // Sign in a user for the first time


  UserManager.prototype.signIn = function (cookies) {
    return __awaiter(this, void 0, void 0, function () {
      var xalUser, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 8]);

            return [4
            /*yield*/
            , this.authProvider.signInSilently()];

          case 1:
            xalUser = _a.sent();
            this.cookieUtils.setSignedInCookie(cookies, this.appConfig);
            return [4
            /*yield*/
            , this.buildUserWithProfile(xalUser)];

          case 2:
            return [2
            /*return*/
            , _a.sent()];

          case 3:
            error_1 = _a.sent();
            if (!this.appConfig.isServerSideRendered) return [3
            /*break*/
            , 4]; // Anonymous login.  If we are here, instead of showing UI, do the anonymous cookie creation step

            console.log("UserManager: Silent sign in did not find a user. Proceeding with Anonymous signin."); // If SignedIn cookie exists, delete it

            this.cookieUtils.deleteSignedInCookie(cookies, this.appConfig);
            return [3
            /*break*/
            , 7];

          case 4:
            console.log("UserManager: XAL error occured during silent sign in. Proceeding to sign in with UI. Error: %O", error_1);
            return [4
            /*yield*/
            , this.authProvider.signIn()];

          case 5:
            xalUser = _a.sent();
            this.cookieUtils.setSignedInCookie(cookies, this.appConfig);
            return [4
            /*yield*/
            , this.buildUserWithProfile(xalUser)];

          case 6:
            return [2
            /*return*/
            , _a.sent()];

          case 7:
            return [3
            /*break*/
            , 8];

          case 8:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  UserManager.prototype.buildUserWithProfile = function (xalUser) {
    return __awaiter(this, void 0, void 0, function () {
      var queryResponse, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , this.xblGraphQlService.query({
              query: FeedQueries_1.UserProfileQuery
            })];

          case 1:
            queryResponse = _a.sent();
            this.user = new User_1.User(xalUser, queryResponse.data.me.user.profile, queryResponse.data.me.user.clubs);
            return [2
            /*return*/
            , this.user];

          case 2:
            error_2 = _a.sent();
            console.log("UserManager: failed to hydrate user profile", error_2);
            return [2
            /*return*/
            , new User_1.User(xalUser, undefined, undefined)];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  UserManager.prototype.signOut = function (cookies) {
    this.cookieUtils.deleteSignedInCookie(cookies, this.appConfig);
    return this.authProvider.signOut();
  };

  UserManager = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IXalAuthProvider)), __param(1, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IGraphQLClient)), __param(2, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IAppConfig)), __metadata("design:paramtypes", [Object, Object, Object])], UserManager);
  return UserManager;
}();

exports.UserManager = UserManager;