export const MIN_INFINITE_BATCH_SIZE = 20;

export enum ScrollType {
    ListStrict = "ListStrict",
    ListWindow = "ListWindow",
    MansoryWindow = "MansoryWindow"
}

export enum ScrollItemStatus {
    Loading = 0,
    Loaded = 1
}

export enum FeedViewType {
    CompactView = "CompactView",
    FullView = "FullView",
    GridView = "GridView",
}

export function feedViewTypeToScrollType(viewType: FeedViewType): ScrollType {
    switch (viewType) {
        case FeedViewType.GridView:
            return ScrollType.MansoryWindow;

        case FeedViewType.FullView:
        case FeedViewType.CompactView:
            return ScrollType.ListWindow;

        default:
            return ScrollType.ListStrict;
    }
}
