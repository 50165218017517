"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImagePickerService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var typings_1 = require("../../typings");

var utils_1 = require("../../utils");

var gamerpicsUri = "https://dlassets-ssl.xboxlive.com/public/content/ppl/gamerpics/gamerpicsmanifest.json";
var clubpicsUri = "https://dlassets-ssl.xboxlive.com/public/content/ppl/gamerpics/clubpicsmanifest.json";

var ImagePickerService =
/** @class */
function () {
  function ImagePickerService(httpService, xblService) {
    this.httpService = httpService;
    this.xblService = xblService;
  }

  ImagePickerService.prototype.getCaptures = function (xuid) {
    var headers = utils_1.GetGenericHeaders(3);
    var rootUrl = "https://mediahub.xboxlive.com";
    var dataRequest = {
      query: "OwnerXuid eq " + xuid
    };
    return this.xblService.sendRequest({
      url: rootUrl + "/screenshots/search",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(dataRequest),
      headers: headers
    });
  };

  ImagePickerService.prototype.getGamerpics = function () {
    return this.httpService.sendRequest({
      url: gamerpicsUri,
      method: http_1.Http.HttpRequestMethod.Get
    });
  };

  ImagePickerService.prototype.getClubpics = function () {
    return this.httpService.sendRequest({
      url: clubpicsUri,
      method: http_1.Http.HttpRequestMethod.Get
    });
  };

  ImagePickerService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IHttpRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __metadata("design:paramtypes", [Object, Object])], ImagePickerService);
  return ImagePickerService;
}();

exports.ImagePickerService = ImagePickerService;