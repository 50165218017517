import * as React from "react";
import { AdminAnalyticsData } from "@xbox/social-core";
import { UniqueVisitorBars } from "../../components/analytics/charts/UUVisitsBars";
import { FollowersBars } from "../../components/analytics/charts/FollowersBars";
import AnalyticsPieChart from "../../components/analytics/charts/AnalyticsPieChart";
import StatsSection from "../../components/analytics/StatsSection";
import TableWidget, { ColumnDefinition } from "../../components/analytics/TableWidget";

export interface AdminActionsDataViewProps {
    stats?: JSX.Element | null;
    topEngagers?: JSX.Element | null;
    visits?: JSX.Element | null;
    followers?: JSX.Element | null;
    locale?: JSX.Element | null;
    age?: JSX.Element | null;
    gender?: JSX.Element | null;
}

export const getAdminActionsDataView = (month: string | undefined, adminActionsData?: AdminAnalyticsData, engagers?: any) => {
    let stats: JSX.Element | null;
    let topEngagers: JSX.Element | null;
    let followers: JSX.Element | null;
    let visits: JSX.Element | null;
    let locale: JSX.Element | null;
    let age: JSX.Element | null;
    let gender: JSX.Element | null;

    const columnsDef: ColumnDefinition[] = [
        { locId: "analyticsView.gamerTagTitle", dataKey: "gamertag" }
    ];

    stats = (
        adminActionsData &&
        (adminActionsData.uniqueVisitors || adminActionsData.adminActions)
    ) ? (<StatsSection actionActionsData={adminActionsData} />) : null;

    topEngagers = engagers && engagers.length !== 0 ? (<TableWidget columnsDef={columnsDef} tableData={engagers} />) : null;

    visits = (
        adminActionsData &&
        adminActionsData.uniqueVisitors &&
        adminActionsData.uniqueVisitors.timesliceData
    ) ? (<UniqueVisitorBars timesliceData={adminActionsData.uniqueVisitors.timesliceData} month={month} />) : <UniqueVisitorBars />;

    followers = (
        adminActionsData &&
        adminActionsData.adminActions &&
        adminActionsData.adminActions.timesliceData
    ) ? (<FollowersBars timesliceData={adminActionsData.adminActions.timesliceData} month={month} />) : <FollowersBars />;

    locale = (
        adminActionsData &&
        adminActionsData.demographics &&
        adminActionsData.demographics.locale &&
        Object.keys(adminActionsData.demographics.locale).length
    ) ? (<AnalyticsPieChart locale={adminActionsData.demographics.locale} />) : null;

    age = (
        adminActionsData &&
        adminActionsData.demographics &&
        adminActionsData.demographics.age &&
        Object.keys(adminActionsData.demographics.age).length
    ) ? (<AnalyticsPieChart age={adminActionsData.demographics.age} />) : null;

    gender = (
        adminActionsData &&
        adminActionsData.demographics &&
        adminActionsData.demographics.gender &&
        Object.keys(adminActionsData.demographics.gender).length
    ) ? (<AnalyticsPieChart gender={adminActionsData.demographics.gender} />) : null;

    return { stats, topEngagers, visits, followers, locale, age, gender };
};
