import * as React from "react";
import { Dropdown, Modal } from "antd";
import { ActivityFeed, FeedCommentItem } from "@xbox/social-core";
import { ICommentItemAdminActionHanders } from "@xbox/social-views";

import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";
import { BaseAdminActionsProps, GetAllowedAdminActions } from "../feeditems/actions/BaseAdminActions";
import { withClubSelectionContext } from "../../containers/ClubSelectionContext";
import { withCurrentUserContext } from "../../containers/CurrentUserContext";
import { Localize } from "../../common/translation/Localize";
import { Glyphs } from "../../common/Generic.Icons";

const confirm = Modal.confirm;

export interface AdminCommentActionsProps extends BaseAdminActionsProps {
    adminActions?: ICommentItemAdminActionHanders;
    item: FeedCommentItem;
}

class AdminCommentActionsContainer extends React.Component<AdminCommentActionsProps> {
    constructor(props: any) {
        super(props);
    }

    actionSelected = (e: any) => {
        if (e && e.domEvent) {
            e.domEvent.stopPropagation();
        }

        if (!this.props.adminActions) {
            return;
        }

        const shouldDeleteAndBan = (props: AdminCommentActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.deleteAndBan"),
                content: Localize("activityFeedContainer.deleteCommentAndBanConfirmTitle"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.adminActions) {
                        props.adminActions.delete(props.item, true);
                    }
                }
            });
        };

        const shouldReport = (props: AdminCommentActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.report"),
                content: Localize("activityFeedContainer.reportCommentConfirmText"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.adminActions) {
                        props.adminActions.report(props.item);
                    }
                }
            });
        };

        let action = e.key;
        switch (action) {
            case ActivityFeed.AdminActionTypes.Delete:
                this.props.adminActions.delete(this.props.item, false);
                break;
            case ActivityFeed.AdminActionTypes.DeleteAndBan:
                shouldDeleteAndBan(this.props);
                break;
            case ActivityFeed.AdminActionTypes.AddToModQ:
                this.props.adminActions.addToModQ(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.Report:
                shouldReport(this.props);
                break;
            default:
                return;
        }
    }

    render() {
        let isUserTheOwnerOfComment = this.props.user !== undefined && this.props.item.author.xuid === this.props.user.xuid;

        if (!this.props.showAdminAction || (!this.props.isModerator && !isUserTheOwnerOfComment)) {
            return <div />;
        }

        let adminActions = GetAllowedAdminActions(isUserTheOwnerOfComment,
            this.props.isModerator,
            this.props.isOwner,
            this.actionSelected,
            this.props.item.author.xuid,
            () => null,
            () => null,
            () => null,
            this.props.clubModerators
        );

        return (
            <Dropdown placement="bottomCenter" overlay={adminActions} trigger={["click"]}>
                <FeedItemStyles.AdminActionsMenu className="ant-dropdown-link" href="#">
                    <FeedItemStyles.AdminCommentActionMenu type={Glyphs.MoreActions} />
                </FeedItemStyles.AdminActionsMenu>
            </Dropdown>
        );
    }
}

export default withClubSelectionContext(withCurrentUserContext(AdminCommentActionsContainer));
