"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var utils_1 = require("../../utils");

var typings_1 = require("../../typings");

var FeedCommentsQueries_1 = require("../../queries/FeedCommentsQueries");

var contractVersionNumber = 1;

var CommentService =
/** @class */
function () {
  function CommentService(xblService, userManager, xblGraphQlService) {
    this.xblService = xblService;
    this.userManager = userManager;
    this.xblGraphQlService = xblGraphQlService;
    this.CommentsRootUrl = "https://comments.xboxlive.com";
    this.LikeFormatUrl = this.CommentsRootUrl + "/{0}/likes/xuid({1})";
    this.ExportFormatUrl = this.CommentsRootUrl + "/{0}/comments/adminReport"; // needed constructor
  }

  CommentService.prototype.getComments = function (postUri, first, after) {
    return this.xblGraphQlService.query({
      query: FeedCommentsQueries_1.GetCommentsByPostQuery,
      variables: {
        postUri: postUri,
        first: first,
        after: after
      },
      fetchPolicy: "network-only"
    });
  };

  CommentService.prototype.getCommentsByIds = function (postUri, commentId) {
    return this.xblGraphQlService.query({
      query: FeedCommentsQueries_1.GetCommentQuery,
      variables: {
        postUri: postUri,
        commentId: commentId
      }
    });
  };

  CommentService.prototype.getBatchComments = function (commentLocators) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getCommentSummary = function (rootPath, commentId) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getSummary = function (itemRoot) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getBatchSummaries = function (itemRoots) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getLikes = function (itemRoot, maxItems, continuationToken) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getShares = function (itemRoot, maxItems, continuationToken) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getBatchLikes = function (itemRoots) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getCommentLikes = function (itemRoot, commentId) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.getHasLiked = function (itemRoot) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.like = function (itemRoot) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, sanitizeRoot, currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = this.GetHeaders();
            sanitizeRoot = utils_1.SanitizeItemRoot(itemRoot);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [2
            /*return*/
            , this.xblService.sendRequest({
              url: this.LikeFormatUrl.replace("{0}", sanitizeRoot).replace("{1}", currentUser.xuid),
              method: http_1.Http.HttpRequestMethod.Put,
              headers: headers
            })];
        }
      });
    });
  };

  CommentService.prototype.unlike = function (itemRoot) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, sanitizeRoot, currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = this.GetHeaders();
            sanitizeRoot = utils_1.SanitizeItemRoot(itemRoot);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [2
            /*return*/
            , this.xblService.sendRequest({
              url: this.LikeFormatUrl.replace("{0}", sanitizeRoot).replace("{1}", currentUser.xuid),
              method: http_1.Http.HttpRequestMethod.Delete,
              headers: headers
            })];
        }
      });
    });
  };

  CommentService.prototype.addCaption = function (itemRoot, caption) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.postComment = function (itemRoot, commentText, userId) {
    return this.xblGraphQlService.mutate({
      mutation: FeedCommentsQueries_1.AddComment,
      variables: {
        postUri: itemRoot,
        text: commentText,
        userId: userId
      }
    });
  };

  CommentService.prototype.likeComment = function (itemRoot, commentId) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.unlikeComment = function (itemRoot, commentId) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.deleteComment = function (itemRoot, commentId) {
    return this.xblGraphQlService.mutate({
      mutation: FeedCommentsQueries_1.DeleteComment,
      variables: {
        postUri: itemRoot,
        commentId: commentId
      }
    });
  };

  CommentService.prototype.deleteCommentByLocator = function (locator) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.shareToFeed = function (shareRoot, caption) {
    throw new Error("Not implemented");
  };

  CommentService.prototype.lockComments = function (itemRoot, clubId) {
    var lockCommentsHeaders = utils_1.GetGenericHeaders(1);
    var lockCommentsData = {
      "locked": true
    };
    return this.xblService.sendRequest({
      url: this.CommentsRootUrl + ("/" + itemRoot + "/commentthreadlock"),
      method: http_1.Http.HttpRequestMethod.Put,
      data: JSON.stringify(lockCommentsData),
      headers: lockCommentsHeaders
    });
  };

  CommentService.prototype.unlockComments = function (itemRoot, clubId) {
    var lockCommentsHeaders = utils_1.GetGenericHeaders(1);
    var lockCommentsData = {
      "locked": false
    };
    return this.xblService.sendRequest({
      url: this.CommentsRootUrl + ("/" + itemRoot + "/commentthreadlock"),
      method: http_1.Http.HttpRequestMethod.Put,
      data: JSON.stringify(lockCommentsData),
      headers: lockCommentsHeaders
    });
  };

  CommentService.prototype.getIsCommentThreadLocked = function (itemRoot) {
    var lockCommentsHeaders = utils_1.GetGenericHeaders(1);
    return this.xblService.sendRequest({
      url: this.CommentsRootUrl + ("/" + itemRoot + "/commentthreadlock"),
      method: http_1.Http.HttpRequestMethod.Get,
      headers: lockCommentsHeaders
    });
  };

  CommentService.prototype.exportComments = function (postUri) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, sanitizeRoot, currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = utils_1.GetExportCSVHeaders(contractVersionNumber);
            sanitizeRoot = utils_1.SanitizeItemRoot(postUri);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [2
            /*return*/
            , this.xblService.sendRequest({
              url: this.ExportFormatUrl.replace("{0}", sanitizeRoot),
              method: http_1.Http.HttpRequestMethod.Get,
              headers: headers
            })];
        }
      });
    });
  };

  CommentService.prototype.GetHeaders = function () {
    var headers = utils_1.GetGenericHeaders(contractVersionNumber);
    return headers;
  };

  CommentService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IUserManager)), __param(2, inversify_1.inject(typings_1.XSocialSymbols.IGraphQLClient)), __metadata("design:paramtypes", [Object, Object, Object])], CommentService);
  return CommentService;
}();

exports.CommentService = CommentService;