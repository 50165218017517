"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.xboxColors = void 0;
exports.xboxColors = [{
  primaryColor: "107c10",
  secondaryColor: "102b14",
  tertiaryColor: "155715"
}, {
  primaryColor: "1081ca",
  secondaryColor: "10314f",
  tertiaryColor: "105080"
}, {
  primaryColor: "1073d6",
  secondaryColor: "133157",
  tertiaryColor: "134e8a"
}, {
  primaryColor: "193e91",
  secondaryColor: "101836",
  tertiaryColor: "102c69"
}, {
  primaryColor: "744DA9",
  secondaryColor: "24153B",
  tertiaryColor: "4c208a"
}, {
  primaryColor: "b144c0",
  secondaryColor: "38163D",
  tertiaryColor: "671a73"
}, {
  primaryColor: "bf1077",
  secondaryColor: "4A102E",
  tertiaryColor: "731247"
}, {
  primaryColor: "a21025",
  secondaryColor: "450710",
  tertiaryColor: "691117"
}, {
  primaryColor: "e31123",
  secondaryColor: "471010",
  tertiaryColor: "851414"
}, {
  primaryColor: "eb8c10",
  secondaryColor: "4f300e",
  tertiaryColor: "a55f10"
}, {
  primaryColor: "724f2f",
  secondaryColor: "301d01",
  tertiaryColor: "553518"
}, {
  primaryColor: "677488",
  secondaryColor: "222B38",
  tertiaryColor: "3e4b61"
}, {
  primaryColor: "54a81b",
  secondaryColor: "103610",
  tertiaryColor: "107c10"
}, {
  primaryColor: "108272",
  secondaryColor: "102b26",
  tertiaryColor: "115e50"
}, {
  primaryColor: "148282",
  secondaryColor: "103033",
  tertiaryColor: "13585E"
}, {
  primaryColor: "eb4910",
  secondaryColor: "692015",
  tertiaryColor: "ab2d11"
}, {
  primaryColor: "ed5588",
  secondaryColor: "57172d",
  tertiaryColor: "9e2950"
}, {
  primaryColor: "4c4a4b",
  secondaryColor: "191919",
  tertiaryColor: "373737"
}, {
  primaryColor: "7e715c",
  secondaryColor: "362F24",
  tertiaryColor: "574b38"
}, {
  primaryColor: "547a72",
  secondaryColor: "1C2B28",
  tertiaryColor: "294A42"
}, {
  primaryColor: "737373",
  secondaryColor: "262626",
  tertiaryColor: "4f4f4f"
}];