"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XalUser = exports.XalPrivilegeCheckDenyReasons = exports.XalPrivilege = exports.XalAgeGroup = exports.XalUserChangeType = void 0;

var Utils_1 = require("./internal/Utils");

var UserSet_1 = require("./internal/UserSet");

var XalUserChangeType;

(function (XalUserChangeType) {
  XalUserChangeType[XalUserChangeType["SignedOutUserSignedInAgain"] = 0] = "SignedOutUserSignedInAgain";
  XalUserChangeType[XalUserChangeType["SigningOut"] = 1] = "SigningOut";
  XalUserChangeType[XalUserChangeType["SignedOut"] = 2] = "SignedOut";
  XalUserChangeType[XalUserChangeType["Gamertag"] = 3] = "Gamertag";
  XalUserChangeType[XalUserChangeType["Privileges"] = 4] = "Privileges";
})(XalUserChangeType = exports.XalUserChangeType || (exports.XalUserChangeType = {}));

var XalAgeGroup;

(function (XalAgeGroup) {
  XalAgeGroup[XalAgeGroup["Unknown"] = 0] = "Unknown";
  XalAgeGroup[XalAgeGroup["Child"] = 1] = "Child";
  XalAgeGroup[XalAgeGroup["Teen"] = 2] = "Teen";
  XalAgeGroup[XalAgeGroup["Adult"] = 3] = "Adult";
})(XalAgeGroup = exports.XalAgeGroup || (exports.XalAgeGroup = {}));

var XalPrivilege;

(function (XalPrivilege) {
  XalPrivilege[XalPrivilege["Clubs"] = 188] = "Clubs";
  XalPrivilege[XalPrivilege["Sessions"] = 189] = "Sessions";
  XalPrivilege[XalPrivilege["BroadCast"] = 190] = "BroadCast";
  XalPrivilege[XalPrivilege["ManageProfilePrivacy"] = 196] = "ManageProfilePrivacy";
  XalPrivilege[XalPrivilege["GameDvr"] = 198] = "GameDvr";
  XalPrivilege[XalPrivilege["MultiplayerParties"] = 203] = "MultiplayerParties";
  XalPrivilege[XalPrivilege["CommsInGameVoice"] = 205] = "CommsInGameVoice";
  XalPrivilege[XalPrivilege["CloudManageSession"] = 207] = "CloudManageSession";
  XalPrivilege[XalPrivilege["CloudJoinSession"] = 208] = "CloudJoinSession";
  XalPrivilege[XalPrivilege["CloudSavedGames"] = 209] = "CloudSavedGames";
  XalPrivilege[XalPrivilege["SocialNetworkSharing"] = 220] = "SocialNetworkSharing";
  XalPrivilege[XalPrivilege["Ugc"] = 247] = "Ugc";
  XalPrivilege[XalPrivilege["Comms"] = 252] = "Comms";
  XalPrivilege[XalPrivilege["Multiplayer"] = 254] = "Multiplayer";
  XalPrivilege[XalPrivilege["AddFriends"] = 255] = "AddFriends";
})(XalPrivilege = exports.XalPrivilege || (exports.XalPrivilege = {}));

var XalPrivilegeCheckDenyReasons;

(function (XalPrivilegeCheckDenyReasons) {
  XalPrivilegeCheckDenyReasons[XalPrivilegeCheckDenyReasons["None"] = 0] = "None";
  XalPrivilegeCheckDenyReasons[XalPrivilegeCheckDenyReasons["Settings"] = 1] = "Settings";
  XalPrivilegeCheckDenyReasons[XalPrivilegeCheckDenyReasons["Enforcement"] = 2] = "Enforcement";
  XalPrivilegeCheckDenyReasons[XalPrivilegeCheckDenyReasons["Title"] = 4] = "Title";
  XalPrivilegeCheckDenyReasons[XalPrivilegeCheckDenyReasons["Unknown"] = 8] = "Unknown";
})(XalPrivilegeCheckDenyReasons = exports.XalPrivilegeCheckDenyReasons || (exports.XalPrivilegeCheckDenyReasons = {}));

var XalUser =
/** @class */
function () {
  function XalUser(userSet, account) {
    this.userSet = userSet;
    this.xuid = account.xuid;
    this.webAccountId = account.webAccountId;
    this.gamertagInternal = account.gamertag;
    Utils_1.xalTrace(Utils_1.TraceLevel.Important, "XalUser constructed");
    this.ageGroupInternal = parseAgeGroup(account.ageGroup);
    Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "User AgeGroup is " + this.ageGroupInternal);
    this.privilegesInternal = getPrivilegesMap(account.privileges, account.settingsRestrictions, account.enforcementRestrictions, account.titleRestrictions);
  }

  Object.defineProperty(XalUser.prototype, "gamertag", {
    get: function get() {
      return this.gamertagInternal;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XalUser.prototype, "ageGroup", {
    get: function get() {
      return this.ageGroupInternal;
    },
    enumerable: false,
    configurable: true
  });

  XalUser.prototype.checkPrivilege = function (privilege) {
    var foundDenyReasons = this.privilegesInternal.get(privilege);

    if (!foundDenyReasons) {
      return {
        allowed: false,
        denyReasons: [XalPrivilegeCheckDenyReasons.Unknown]
      };
    }

    if (foundDenyReasons.length > 0) {
      return {
        allowed: false,
        denyReasons: foundDenyReasons
      };
    }

    return {
      allowed: true,
      denyReasons: undefined
    };
  };

  XalUser.prototype.updateAccountData = function (account) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Important, "XalUser (" + this.xuid + ") account updated");
    Utils_1.assert(this.xuid === account.xuid);
    Utils_1.assert(this.webAccountId === account.webAccountId);
    var changeTypeBitmask = UserSet_1.UserChangeType.None;

    if (account.gamertag !== this.gamertagInternal) {
      this.gamertagInternal = account.gamertag; // tslint:disable-next-line:no-bitwise

      changeTypeBitmask |= UserSet_1.UserChangeType.Gamertag;
    }

    this.ageGroupInternal = parseAgeGroup(account.ageGroup);
    var newPrivileges = getPrivilegesMap(account.privileges, account.settingsRestrictions, account.enforcementRestrictions, account.titleRestrictions);
    var foundDifference = false;
    Array.from(this.privilegesInternal.entries()).forEach(function (pair) {
      var newValue = newPrivileges.get(pair["0"]);

      if (newValue) {
        if (newValue.some(function (reason) {
          return pair["1"].indexOf(reason) < 0;
        })) {
          foundDifference = true;
        }
      } else {
        foundDifference = true;
      }
    });

    if (foundDifference) {
      // tslint:disable-next-line:no-bitwise
      changeTypeBitmask |= UserSet_1.UserChangeType.Privileges;
      this.privilegesInternal = newPrivileges;
    }

    return changeTypeBitmask;
  };

  XalUser.prototype.release = function () {
    this.userSet.remove(this.xuid);
  };

  return XalUser;
}();

exports.XalUser = XalUser;

function parseAgeGroup(ageGroup) {
  if (ageGroup === "Child") {
    return XalAgeGroup.Child;
  } else if (ageGroup === "Teen") {
    return XalAgeGroup.Teen;
  } else if (ageGroup === "Adult") {
    return XalAgeGroup.Adult;
  }

  Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Failed to parse age group string");
  return XalAgeGroup.Unknown;
}

function getPrivilegesMap(privilegesStr, settingsRestrictions, enforcementRestrictions, titleRestrictions) {
  var privilegeMap = new Map();
  parsePrivileges(privilegeMap, privilegesStr, undefined);
  Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "User Privileges are: " + privilegesStr);
  parsePrivileges(privilegeMap, settingsRestrictions, XalPrivilegeCheckDenyReasons.Settings);
  Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "User Setting Restricted Privileges are: " + settingsRestrictions);
  parsePrivileges(privilegeMap, enforcementRestrictions, XalPrivilegeCheckDenyReasons.Enforcement);
  Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "User Enforcement Restricted Privileges are: " + enforcementRestrictions);
  parsePrivileges(privilegeMap, titleRestrictions, XalPrivilegeCheckDenyReasons.Title);
  Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "User Title Restricted Privileges are: " + titleRestrictions);
  return privilegeMap;
}

function parsePrivileges(privilegeMap, privilegesStr, denyReason) {
  if (!privilegesStr) {
    return;
  }

  var privilegeValues = privilegesStr.split(" ");
  privilegeValues.forEach(function (valueStr) {
    var valueNum = parseInt(valueStr, 10);
    var enumValue = valueNum;
    var denyReasons = privilegeMap.get(enumValue);

    if (denyReasons && denyReason) {
      denyReasons.push(denyReason);
    } else {
      privilegeMap.set(enumValue, denyReason ? [denyReason] : []);
    }
  });
}