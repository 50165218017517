import * as React from "react";
import { ThemeType } from "@xbox/social-views";

export interface LayoutContext {
    isOpen: boolean;
    onToggleSider?: (state: boolean) => void;
    toggleSlider?: () => void;
    onThemeChange?: (theme: ThemeType) => void;
}

export const LayoutContextProvider = React.createContext<LayoutContext>({
    isOpen: false,
    onToggleSider: (state: boolean) => { /* No default behavior */ },
    onThemeChange: (theme: ThemeType) => { /* No default behavior */ }
});
