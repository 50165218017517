import * as React from "react";
import { ActivityFeed, ClubHub, PostStatus } from "@xbox/social-core";
import { IFeedItemAdminActionHanders } from "@xbox/social-views";

import ScheduledClubActivityFeed from "../activityfeed/ScheduledClubActivityFeed";
import { FeedViewType } from "../infiniteScrollers/base/InfiniteScrollTypes";
// import { FeedItemSelector } from "../feeditems/FeedItemSelector";

interface ScheduledPostViewProps {
    isValid: boolean;
    isErrored: boolean;
    scheduledPosts?: ActivityFeed.FeedItem[];
    adminActions: IFeedItemAdminActionHanders;
    refreshFeed: () => void;
    postStatus?: PostStatus.PostStatusResult;
    selectedChannel?: ClubHub.Channel;
    channelList?: ClubHub.Channel[];
    selectChannel: (channel: ClubHub.Channel) => void;
}

export const ScheduledPostView = (props: ScheduledPostViewProps) => {
    return (
        <ScheduledClubActivityFeed
            listData={props.scheduledPosts || []}
            isValid={props.isValid}
            isErrored={props.isErrored}
            feedItemSelected={() => {/**/}}
            clearActivityFeed={() => {/**/}}
            updateMethod={props.refreshFeed}
            adminActions={props.adminActions}
            forcedFeedType={FeedViewType.FullView}
            feedManipulationProps={{
                    postStatus: props.postStatus,
                    isModerator: true,
                    channelList: props.channelList,
                    selectChannel: props.selectChannel,
                    selectedChannel: props.selectedChannel
                }
            }
        />
    );
};
