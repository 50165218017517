"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClubAdminSaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var _ = require("lodash");

var social_core_1 = require("@xbox/social-core");

var ClubsNormalizer_1 = require("../reducers/club/ClubsNormalizer");

var ClubListActions_1 = require("../actions/ClubListActions");

var Utils_1 = require("./Utils");

var ClubAdminSaga =
/** @class */
function () {
  function ClubAdminSaga(clubHubService, peopleHubService, activityFeedService, notificationService, telemetryService, localStorageService) {
    this.clubHubService = clubHubService;
    this.peopleHubService = peopleHubService;
    this.activityFeedService = activityFeedService;
    this.notificationService = notificationService;
    this.telemetryService = telemetryService;
    this.localStorageService = localStorageService;
    this.sagas = [this.watchUpdateClubSettings(), this.watchMoveChannel(), this.watchReorderChannel(), this.watchReorderChannelGroup(), this.watchDeleteClub(), this.watchCancelDeleteClub(), this.watchGetModerationQueue(), this.watchActOnModerationItems(), this.watchInviteQueueItems(), this.watchActOnInviteRequest(), this.watchApplyFilter(), this.watchGetFilter(), this.watchGetUserStats(), this.watchClubRosterUpdateRole(), this.watchClubRosterReportMember(), this.watchInviteUser(), this.watchFetchSafetyThreshold(), this.watchUpdateSafetyThreshold()];
  }

  ClubAdminSaga.prototype._fetchClubSafetyThreshold = function (action) {
    var result, data, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.getClubSafetyThreshold(action.payload)];

        case 2:
          result = _a.sent();
          data = result.data.clubs[0].profile.safetyThreshold;
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyCompleted(data))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_1 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(false))];

        case 6:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._updateClubSettings = function (action) {
    var requestStart, result, err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 14,, 16]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.updateClubSettingsInProgress(true))];

        case 1:
          _a.sent();

          if (!action.payload.update) return [3
          /*break*/
          , 10];
          requestStart = Date.now();
          if (!(action.payload.update.modifiedFields[0] === "name")) return [3
          /*break*/
          , 3];
          return [4
          /*yield*/
          , this.clubHubService.changeName(action.payload.clubId, action.payload.updatedValue)];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 9];

        case 3:
          if (!(action.payload.update.modifiedFields[0] === "channelName")) return [3
          /*break*/
          , 7];
          return [4
          /*yield*/
          , this.clubHubService.changeName(action.payload.clubId, action.payload.updatedValue, action.payload.channelId)];

        case 4:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload.clubId)];

        case 5:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(result.data.club.feedChannels))];

        case 6:
          _a.sent();

          return [3
          /*break*/
          , 9];

        case 7:
          return [4
          /*yield*/
          , this.clubHubService.updateClubSetting(action.payload.clubId, action.payload.update)];

        case 8:
          _a.sent();

          _a.label = 9;

        case 9:
          this.telemetryService.trackEvent("ClubAdminSettingUpdated", {
            "clubId": action.payload.clubId,
            "updatedSetting": action.payload.updatedProperty,
            "updatedValue": action.payload.updatedValue
          });
          this.telemetryService.trackLoadTime("ClubAdminSettingUpdated", requestStart);
          _a.label = 10;

        case 10:
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateProperty(action.payload))];

        case 11:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.updateClubSettingsCompleted())];

        case 12:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.updateClubSettingsInProgress(false))];

        case 13:
          _a.sent();

          this.notificationService.notifySuccess("ClubAdminSettings");
          return [3
          /*break*/
          , 16];

        case 14:
          err_2 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.updateClubSettingsInProgress(true))];

        case 15:
          _a.sent(); // edit would still be in progress on failure to give the user an opportunity to retry saving


          this.notificationService.notifyError("ClubAdminSettings");
          Utils_1.LogHttpsError("Unable to update club settings", err_2);
          this.telemetryService.trackException(err_2, "ClubAdminSettingUpdated", {
            "clubId": action.payload.clubId,
            "updatedSetting": action.payload.updatedProperty,
            "updatedValue": action.payload.updatedValue
          });
          return [3
          /*break*/
          , 16];

        case 16:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._clubMoveChannel = function (action) {
    var requestStart, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          requestStart = Date.now();
          return [4
          /*yield*/
          , this.clubHubService.moveChannel(action.payload.channelId, action.payload.clubId, action.payload.group)];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubChannelMoved", {
            "clubId": action.payload.clubId
          });
          this.telemetryService.trackLoadTime("ClubChannelMoved", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.moveChannelComplete(action.payload))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_3 = _a.sent();
          console.warn("Unable to move channel");
          this.notificationService.notifyError("MoveChannel", "10");
          this.telemetryService.trackException(err_3, "ClubChannelMove", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._reorderChannel = function (action) {
    var res, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 6]);

          if (!action.payload.channelId) return [3
          /*break*/
          , 2];
          return [4
          /*yield*/
          , this.clubHubService.reorderChannel(action.payload.clubId, action.payload.newPosition, action.payload.channelId)];

        case 1:
          _a.sent();

          _a.label = 2;

        case 2:
          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload.clubId)];

        case 3:
          res = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(res.data.club.feedChannels))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 6];

        case 5:
          err_4 = _a.sent();
          Utils_1.LogHttpsError("Unable to reorder channel", err_4);
          this.notificationService.notifyError("ReorderChannel");
          this.telemetryService.trackException(err_4, "ReorderChannel", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 6];

        case 6:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._reorderChannelGroup = function (action) {
    var res, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 4,, 5]);

          return [4
          /*yield*/
          , this.clubHubService.reorderChannelGroup(action.payload.clubId, action.payload.newPosition, action.payload.groupName)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload.clubId)];

        case 2:
          res = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(res.data.club.feedChannels))];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 5];

        case 4:
          err_5 = _a.sent();
          Utils_1.LogHttpsError("Unable to reorder channel group", err_5);
          this.notificationService.notifyError("ReorderChannelGroup");
          this.telemetryService.trackException(err_5, "ReorderChannelGroup", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 5];

        case 5:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._updateSafetyThreshold = function (action) {
    var err_6;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.updateSafetyThreshold(action.payload.clubId, action.payload.updatedValue)];

        case 2:
          _a.sent();

          this.notificationService.notifySuccess("SafetyThreshold");
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyCompleted(action.payload.updatedValue))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_6 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchSafetyInProgress(false))];

        case 6:
          _a.sent();

          Utils_1.LogHttpsError("Unable to update safety threshold", err_6);
          this.notificationService.notifyError("SafetyThreshold");
          this.telemetryService.trackException(err_6, "UpdateClubSafetyThreshold", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._deleteClub = function (action) {
    var requestStart, err_7;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(true))];

        case 1:
          _a.sent();

          requestStart = Date.now();
          this.telemetryService.trackEvent("DeleteClub", {
            "clubId": action.payload.clubId
          });
          return [4
          /*yield*/
          , this.clubHubService.deleteClub(action.payload.clubId)];

        case 2:
          _a.sent();

          this.telemetryService.trackLoadTime("DeleteClub", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateProperty(action.payload))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_7 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(false))];

        case 6:
          _a.sent();

          Utils_1.LogHttpsError("Unable to delete club", err_7);
          this.telemetryService.trackException(err_7, "ClubDeleted", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._cancelDeleteClub = function (action) {
    var requestStart, err_8;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(true))];

        case 1:
          _a.sent();

          requestStart = Date.now();
          this.telemetryService.trackEvent("ClubDeleteCancelled", {
            "clubId": action.payload.clubId
          });
          return [4
          /*yield*/
          , this.clubHubService.cancelDeleteClub(action.payload.clubId)];

        case 2:
          _a.sent();

          this.telemetryService.trackLoadTime("ClubDeleteCancelled", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateProperty(action.payload))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_8 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubInprogress(false))];

        case 6:
          _a.sent();

          Utils_1.LogHttpsError("Unable to cancel delete club", err_8);
          this.telemetryService.trackException(err_8, "ClubDeleteCancelled", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._getModerationQueue = function (action) {
    var requestStart, reportedItems, result, err_9;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationFetchInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubModerationQueueFetched", {
            "clubId": action.payload
          });
          requestStart = Date.now();
          return [4
          /*yield*/
          , this.clubHubService.getModerationQueue(action.payload)];

        case 2:
          reportedItems = _a.sent();
          result = ClubsNormalizer_1.clubModerationNormalizer(reportedItems.data.clubModerationQueue);
          this.telemetryService.trackLoadTime("ClubModerationQueueFetched", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationFetchCompleted(result))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationFetchInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_9 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationFetchInProgress(false))];

        case 6:
          _a.sent();

          console.warn("Unable to fetch club moderation queue: " + err_9);
          this.telemetryService.trackException(err_9, "ClubModerationQueueFetched", {
            "clubId": action.payload
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._actOnModerationItems = function (action) {
    var requestStart, err_10;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubModerationItemActioned", {
            "clubId": action.payload.clubId,
            "action": action.payload.shouldDelete ? "delete" : "ignore"
          });
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateModerationQueue(action.payload))];

        case 2:
          _a.sent();

          requestStart = Date.now();
          return [4
          /*yield*/
          , this.clubHubService.actOnModerationItems(action.payload.clubId, action.payload.reportedItemsDeleteRequest, action.payload.shouldDelete)];

        case 3:
          _a.sent();

          this.telemetryService.trackLoadTime("ClubModerationItemActioned", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_10 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(false))];

        case 6:
          _a.sent();

          console.warn("Unable to " + (action.payload.shouldDelete ? "delete" : "ignore") + " club moderation item: " + err_10);
          this.telemetryService.trackException(err_10, "ClubModerationItemActioned", {
            "clubId": action.payload.clubId,
            "action": action.payload.shouldDelete ? "delete" : "ignore"
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._getInviteQueue = function (action) {
    var requestStart, iq, requests_1, err_11;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueFetchInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubInviteQueueFetched", {
            "clubId": action.payload
          });
          requestStart = Date.now();
          return [4
          /*yield*/
          , this.clubHubService.getInviteQueue(action.payload)];

        case 2:
          iq = _a.sent();
          requests_1 = [];

          if (iq.data.club && iq.data.club.roster && iq.data.club.roster.recommended) {
            iq.data.club.roster.recommended.forEach(function (req) {
              var clubMember = req;
              requests_1.push(clubMember);
            });
          }

          if (iq.data.club && iq.data.club.roster && iq.data.club.roster.requestedToJoin) {
            iq.data.club.roster.requestedToJoin.forEach(function (req) {
              var clubMember = req;
              requests_1.push(clubMember);
            });
          }

          this.telemetryService.trackLoadTime("ClubInviteQueueReque", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueFetchCompleted(requests_1))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueFetchInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_11 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueFetchInProgress(false))];

        case 6:
          _a.sent();

          console.warn("Unable to fetch club invite queue: " + err_11);
          this.telemetryService.trackException(err_11, "ClubInviteQueueFetched", {
            "clubId": action.payload
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._actOnInviteQueueItems = function (action) {
    var requestStart, requests, i, xuids, err_12;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 10,, 12]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueActionInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubInviteQueueRequestActioned", {
            "clubId": action.payload.clubId,
            "action": action.payload.shouldInvite ? "ignored" : "invite"
          });
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateInviteQueue(action.payload))];

        case 2:
          _a.sent();

          requestStart = Date.now();
          if (!(action.payload.xuids.length === 1)) return [3
          /*break*/
          , 4];
          return [4
          /*yield*/
          , this.clubHubService.updateRoster(action.payload.clubId, action.payload.xuids[0], action.payload.shouldInvite)];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 8];

        case 4:
          requests = _.chunk(action.payload.xuids, 20);
          i = 0;
          _a.label = 5;

        case 5:
          if (!(i < requests.length)) return [3
          /*break*/
          , 8];
          xuids = requests[i];
          return [4
          /*yield*/
          , effects_1.fork(this.clubHubService.batchUpdateRoster.bind(this), action.payload.clubId, xuids, action.payload.shouldInvite)];

        case 6:
          _a.sent();

          _a.label = 7;

        case 7:
          i++;
          return [3
          /*break*/
          , 5];

        case 8:
          this.telemetryService.trackLoadTime("ClubInviteQueueRequestActioned", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(false))];

        case 9:
          _a.sent();

          return [3
          /*break*/
          , 12];

        case 10:
          err_12 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(false))];

        case 11:
          _a.sent();

          console.warn("Unable to " + (action.payload.shouldInvite ? "ignored" : "invited") + " club moderation item: " + err_12);
          this.telemetryService.trackException(err_12, "ClubInviteQueueRequestActioned", {
            "clubId": action.payload.clubId,
            "action": action.payload.shouldInvite ? "ignored" : "invited"
          });
          return [3
          /*break*/
          , 12];

        case 12:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._clubRosterUpdateRole = function (action) {
    var requestStart, err_13;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 8,, 10]);

          this.telemetryService.trackEvent("ClubRosterRoleUpdated", {
            "clubId": action.payload.clubId,
            "action": action.payload.add ? "add" : "remove",
            "role": action.payload.role
          });
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubRosterUpdateRoleProperty(action.payload))];

        case 1:
          _a.sent();

          requestStart = Date.now();
          if (!action.payload.add) return [3
          /*break*/
          , 3];
          return [4
          /*yield*/
          , this.clubHubService.addClubMemberRole(action.payload.clubId, action.payload.xuid, [action.payload.role])];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 3:
          if (!(action.payload.role === "Member")) return [3
          /*break*/
          , 5];
          return [4
          /*yield*/
          , this.clubHubService.updateRoster(action.payload.clubId, action.payload.xuid, false)];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          return [4
          /*yield*/
          , this.clubHubService.deleteClubMemberRole(action.payload.clubId, action.payload.xuid, action.payload.role)];

        case 6:
          _a.sent();

          _a.label = 7;

        case 7:
          this.telemetryService.trackLoadTime("ClubRosterRoleUpdated", requestStart);
          return [3
          /*break*/
          , 10];

        case 8:
          err_13 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubModerationActionInProgress(false))];

        case 9:
          _a.sent();

          console.warn("Unable to " + (action.payload.add ? "add" : "remove") + " club role: " + err_13);
          this.telemetryService.trackException(err_13, "ClubRosterRoleUpdated", {
            "clubId": action.payload.clubId,
            "action": action.payload.add ? "add" : "remove",
            "role": action.payload.role
          });
          return [3
          /*break*/
          , 10];

        case 10:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._clubRosterInviteUser = function (action) {
    var person, payload, err_14;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 4,, 5]);

          return [4
          /*yield*/
          , this.peopleHubService.getXuidFromGamertag(action.payload.gamertag)];

        case 1:
          person = _a.sent();
          if (!(person && person.length)) return [3
          /*break*/
          , 3];
          payload = {
            clubId: action.payload.clubId,
            shouldInvite: true,
            xuids: [person[0].id]
          };
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteQueueRequestAction(payload))];

        case 2:
          _a.sent();

          this.notificationService.notifySuccess("InviteUser");
          _a.label = 3;

        case 3:
          return [3
          /*break*/
          , 5];

        case 4:
          err_14 = _a.sent();
          Utils_1.LogHttpsError("Unable to invite user", err_14);
          this.notificationService.notifyError("InviteUser", "10");
          this.telemetryService.trackException(err_14, "ClubRosterInviteUserWithGamertag", {
            "clubId": action.payload
          });
          return [3
          /*break*/
          , 5];

        case 5:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._getFilter = function (action) {
    var json, filter, err_15;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 6,, 7]);

          return [4
          /*yield*/
          , this.localStorageService.getItem(action.payload + "_filter")];

        case 1:
          json = _a.sent();
          if (!json) return [3
          /*break*/
          , 3];
          filter = JSON.parse(json);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteGetFilterCompleted(filter))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 5];

        case 3:
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteGetFilterCompleted())];

        case 4:
          _a.sent();

          _a.label = 5;

        case 5:
          return [3
          /*break*/
          , 7];

        case 6:
          err_15 = _a.sent();
          console.warn("Unable to get filter");
          this.telemetryService.trackException(err_15, "ClubInviteQueueGetFilter", {
            "clubId": action.payload
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._saveFilter = function (action) {
    var newFilter, err_16;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          newFilter = __assign({}, action.payload.filter);
          newFilter.isVisible = false;
          this.localStorageService.setItem(action.payload.clubId + "_filter", JSON.stringify(newFilter));
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubInviteSaveFilterCompleted())];

        case 1:
          _a.sent();

          return [3
          /*break*/
          , 3];

        case 2:
          err_16 = _a.sent();
          console.warn("Unable to save filter");
          this.telemetryService.trackException(err_16, "ClubInviteQueueSaveFilter", {
            "clubId": action.payload.clubId
          });
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._getUserStats = function (action) {
    var requestStart, input, userStatsRequest, iq, userStatsMap_1, err_17;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUserGetStatsInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("ClubGetUserStatsFetched", {
            "action": "club_get_user_stats"
          });
          requestStart = Date.now();
          input = [{
            titleId: action.payload.titleId,
            name: "MinutesPlayed"
          }];
          userStatsRequest = {
            arrangeByField: "xuid",
            xuids: action.payload.xuids,
            stats: input
          };
          return [4
          /*yield*/
          , this.clubHubService.getUserStats(userStatsRequest)];

        case 2:
          iq = _a.sent();
          userStatsMap_1 = {};

          if (iq.data.userStats && iq.data.userStats.statListsCollection) {
            iq.data.userStats.statListsCollection.forEach(function (element, index) {
              userStatsMap_1[action.payload.xuids[index]] = element.stats[0].value;
            });
          }

          this.telemetryService.trackLoadTime("ClubGetUserStats", requestStart);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUserGetStatsCompleted(userStatsMap_1))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUserGetStatsInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_17 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUserGetStatsInProgress(false))];

        case 6:
          _a.sent();

          console.warn("Unable to get user stats: " + err_17);
          this.telemetryService.trackException(err_17, "ClubGetUserStatsFetched");
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype._clubRosterReportMember = function (action) {
    var userId, payload, err_18;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          userId = action.payload.reporter;

          payload = function (_a) {
            var evidenceId = _a.evidenceId,
                feedbackContext = _a.feedbackContext,
                feedbackType = _a.feedbackType;
            return {
              evidenceId: evidenceId,
              feedbackContext: feedbackContext,
              feedbackType: feedbackType
            };
          }(action.payload);

          return [4
          /*yield*/
          , this.clubHubService.reportMemberToEnforcement(userId, payload)];

        case 1:
          _a.sent();

          return [3
          /*break*/
          , 3];

        case 2:
          err_18 = _a.sent();
          console.warn("Unable to report member from roster: " + err_18);
          this.telemetryService.trackException(err_18, "ClubRosterMemberReported", {
            "uxId": action.payload.evidenceId,
            "action": "ClubRosterReportMember"
          });
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchGetModerationQueue = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubModerationFetchQueue.type, this._getModerationQueue.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchUpdateClubSettings = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.updateClubSettings.type, this._updateClubSettings.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchDeleteClub = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.deleteClub.type, this._deleteClub.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchCancelDeleteClub = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.cancelDeleteClub.type, this._cancelDeleteClub.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchActOnModerationItems = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubModerationAction.type, this._actOnModerationItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchInviteQueueItems = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubInviteQueueFetchQueue.type, this._getInviteQueue.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchActOnInviteRequest = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubInviteQueueRequestAction.type, this._actOnInviteQueueItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchApplyFilter = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubInviteSaveFilter.type, this._saveFilter.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchGetFilter = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubInviteGetFilter.type, this._getFilter.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchGetUserStats = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubUserGetStats.type, this._getUserStats.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchClubRosterUpdateRole = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubRosterUpdateRole.type, this._clubRosterUpdateRole.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchClubRosterReportMember = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubRosterReportMember.type, this._clubRosterReportMember.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchInviteUser = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.clubRosterInviteUser.type, this._clubRosterInviteUser.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchMoveChannel = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.moveChannel.type, this._clubMoveChannel.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchReorderChannel = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.reorderChannel.type, this._reorderChannel.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchReorderChannelGroup = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.reorderChannelGroup.type, this._reorderChannelGroup.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchFetchSafetyThreshold = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.fetchSafetyThreshold.type, this._fetchClubSafetyThreshold.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga.prototype.watchUpdateSafetyThreshold = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.updateSafetyThreshold.type, this._updateSafetyThreshold.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubAdminSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IClubHubService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.IPeopleHubService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.IActivityFeedService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(4, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(5, inversify_1.inject(social_core_1.XSocialSymbols.ILocalStorageService)), __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object])], ClubAdminSaga);
  return ClubAdminSaga;
}();

exports.ClubAdminSaga = ClubAdminSaga;