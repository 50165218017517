"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeedViewerSaga = void 0;

var inversify_1 = require("inversify");

var _ = require("lodash");

var social_core_1 = require("@xbox/social-core");

var effects_1 = require("redux-saga/effects");

var ActivityFeedActions_1 = require("../actions/ActivityFeedActions");

var ActivityFeedNormalizer_1 = require("../reducers/feed/ActivityFeedNormalizer");

var __1 = require("..");

var Utils_1 = require("./Utils");

var FeedViewerSaga =
/** @class */
function () {
  function FeedViewerSaga(activityFeedService, commentService, telemetryService, clubHubService, notificationService, ballotBoxService) {
    this.activityFeedService = activityFeedService;
    this.commentService = commentService;
    this.telemetryService = telemetryService;
    this.clubHubService = clubHubService;
    this.notificationService = notificationService;
    this.ballotBoxService = ballotBoxService;
    this.sagas = [this.watchFormatFeedData(), this.watchClubFeedFetch(), this.watchPersonalFeedFetch(), this.watchBallotFeedFetch(), this.watchScheduledFeedFetch(), this.watchLikeFeedItem(), this.watchCreateBallotFeedItem(), this.watchVoteFeedItem(), this.watchPostFeedItem(), this.watchHydrateWebLink(), this.watchValidateString(), this.watchAdminActOnFeedItem(), this.watchHydrateFetchItemsPreview(), this.watchGetFeedSettings(), this.watchUpdateFeedSetting(), this.watchFetchAndSelectFeedItem()];
  }

  FeedViewerSaga.prototype._formatFeedData = function (action) {
    var rawFeed, formattedFeed, normalizedFeed;

    var _this = this;

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          rawFeed = action.payload.rawFeedData.items;
          formattedFeed = rawFeed.map(function (x) {
            return _this.formatFeedItem(x);
          });
          normalizedFeed = ActivityFeedNormalizer_1.activityFeedNormalizer(formattedFeed); // Apply Cursor

          normalizedFeed.cursor = action.payload.rawFeedData.cursor || ""; // Apply Feed Id

          normalizedFeed.feedId = action.payload.feedId;
          this.telemetryService.trackLoadTime(action.payload.feedType, action.payload.requestStart);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(false))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchCompleted(normalizedFeed))];

        case 2:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._fetchClubFeedItems = function (action) {
    var feedType, result, requestStart, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          feedType = "";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 7,, 11]);

          result = void 0;
          requestStart = Date.now();
          if (!action.payload.clubId) return [3
          /*break*/
          , 6];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.setCurrentFeedId(action.payload.channelId || action.payload.clubId))];

        case 2:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(true))];

        case 3:
          _a.sent();

          feedType = "ClubActivityFeedFetched";
          this.telemetryService.trackEvent(feedType, {
            "clubId": action.payload.clubId
          });
          return [4
          /*yield*/
          , this.activityFeedService.getClubActivityFeeds(action.payload.clubId, action.payload.numItems, action.payload.channelId, action.payload.cursor, [], [social_core_1.ActivityFeed.ActivityItemTypes.BroadcastStart, social_core_1.ActivityFeed.ActivityItemTypes.BroadcastEnd])];

        case 4:
          result = _a.sent();
          action.payload.cancellationToken.throwIfCancelled();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.formatFeedData({
            rawFeedData: result.data["club"]["feed"],
            feedId: action.payload.channelId || action.payload.clubId,
            feedType: feedType,
            requestStart: requestStart
          }))];

        case 5:
          _a.sent();

          _a.label = 6;

        case 6:
          return [3
          /*break*/
          , 11];

        case 7:
          err_1 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(false))];

        case 8:
          _a.sent();

          if (!!action.payload.cancellationToken.isCancelled()) return [3
          /*break*/
          , 10];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchError())];

        case 9:
          _a.sent();

          console.warn("Unable to fetch feed " + action.payload.clubId + ", " + err_1);
          this.telemetryService.trackException(err_1, "FeedFetch", {
            "clubId": action.payload.clubId
          }, true);
          _a.label = 10;

        case 10:
          return [3
          /*break*/
          , 11];

        case 11:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._fetchPersonalFeedItems = function (action) {
    var feedType, result, requestStart, clonedData, err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          feedType = "";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 6,, 10]);

          result = void 0;
          requestStart = Date.now();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.setCurrentFeedId(action.payload.type || social_core_1.ActivityFeed.FeedType.All))];

        case 2:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(true))];

        case 3:
          _a.sent();

          feedType = "MyActivityFeedFetched";
          this.telemetryService.trackEvent(feedType);
          return [4
          /*yield*/
          , this.activityFeedService.getMyActivityFeed(action.payload.type || social_core_1.ActivityFeed.FeedType.All, action.payload.numItems, action.payload.cursor, action.payload.selectTypes, action.payload.excludedTypes)];

        case 4:
          result = _a.sent();
          clonedData = _.cloneDeep(result);
          clonedData.data["me"]["feed"]["items"] = result.data["me"]["feed"]["items"].filter(function (item) {
            return item.itemInfo.activityItemType !== "Container" && item.itemInfo.activityItemType !== "SocialRecommendation";
          });
          action.payload.cancellationToken.throwIfCancelled();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.formatFeedData({
            rawFeedData: clonedData.data["me"]["feed"],
            feedId: action.payload.type || social_core_1.ActivityFeed.FeedType.All,
            feedType: feedType,
            requestStart: requestStart
          }))];

        case 5:
          _a.sent();

          return [3
          /*break*/
          , 10];

        case 6:
          err_2 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(false))];

        case 7:
          _a.sent();

          if (!!action.payload.cancellationToken.isCancelled()) return [3
          /*break*/
          , 9];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchError())];

        case 8:
          _a.sent();

          console.warn("Unable to fetch my activity feed, " + err_2);
          this.telemetryService.trackException(err_2, "FeedFetch", {}, true);
          _a.label = 9;

        case 9:
          return [3
          /*break*/
          , 10];

        case 10:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._fetchBallotFeedItems = function (action) {
    var feedType, result, requestStart, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          feedType = "ClubActivityBallotFeedFetched";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 7,, 11]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.setCurrentFeedId(social_core_1.ActivityFeed.FeedType.Ballots))];

        case 2:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(true))];

        case 3:
          _a.sent();

          result = void 0;
          requestStart = Date.now();
          if (!action.payload.clubId) return [3
          /*break*/
          , 6];
          this.telemetryService.trackEvent(feedType, {
            "clubId": action.payload.clubId
          });
          return [4
          /*yield*/
          , this.activityFeedService.getClubActivityFeeds(action.payload.clubId, action.payload.numItems, action.payload.channelId, action.payload.cursor, [social_core_1.ActivityFeed.ActivityItemTypes.Ballot], [social_core_1.ActivityFeed.ActivityItemTypes.BroadcastStart, social_core_1.ActivityFeed.ActivityItemTypes.BroadcastEnd], action.payload.pinCount)];

        case 4:
          result = _a.sent();
          action.payload.cancellationToken.throwIfCancelled();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.formatFeedData({
            rawFeedData: result.data["club"]["feed"],
            feedId: social_core_1.ActivityFeed.FeedType.Ballots,
            feedType: feedType,
            requestStart: requestStart
          }))];

        case 5:
          _a.sent();

          _a.label = 6;

        case 6:
          return [3
          /*break*/
          , 11];

        case 7:
          err_3 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(false))];

        case 8:
          _a.sent();

          if (!!action.payload.cancellationToken.isCancelled()) return [3
          /*break*/
          , 10];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchError())];

        case 9:
          _a.sent();

          console.warn("Unable to fetch ballot feed " + action.payload.clubId);
          this.telemetryService.trackException(err_3, "FeedFetch", {
            "clubId": action.payload.clubId
          }, true);
          _a.label = 10;

        case 10:
          return [3
          /*break*/
          , 11];

        case 11:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._fetchScheduledFeedItems = function (action) {
    var feedType, formatted, result, requestStart, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          feedType = "ClubActivityScheduledFeedFetched";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 7,, 11]);

          formatted = action.payload.channelId || action.payload.clubId || "";
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.setCurrentFeedId(social_core_1.ActivityFeed.FeedType.Scheduled + formatted))];

        case 2:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(true))];

        case 3:
          _a.sent();

          result = void 0;
          requestStart = Date.now();
          if (!action.payload.clubId) return [3
          /*break*/
          , 6];
          this.telemetryService.trackEvent(feedType, {
            "clubId": action.payload.clubId
          });
          return [4
          /*yield*/
          , this.activityFeedService.getClubActivityFeeds(action.payload.clubId, action.payload.numItems, action.payload.channelId, action.payload.cursor, [], [social_core_1.ActivityFeed.ActivityItemTypes.BroadcastStart, social_core_1.ActivityFeed.ActivityItemTypes.BroadcastEnd], action.payload.pinCount, action.payload.startDate, action.payload.endDate)];

        case 4:
          result = _a.sent();
          action.payload.cancellationToken.throwIfCancelled();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.formatFeedData({
            rawFeedData: result.data["club"]["feed"],
            feedId: social_core_1.ActivityFeed.FeedType.Scheduled + formatted,
            feedType: feedType,
            requestStart: requestStart
          }))];

        case 5:
          _a.sent();

          _a.label = 6;

        case 6:
          return [3
          /*break*/
          , 11];

        case 7:
          err_4 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchInProgress(false))];

        case 8:
          _a.sent();

          if (!!action.payload.cancellationToken.isCancelled()) return [3
          /*break*/
          , 10];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedFetchError())];

        case 9:
          _a.sent();

          console.warn("Unable to fetch scheduled feed " + action.payload.clubId);
          this.telemetryService.trackException(err_4, "FeedFetch", {
            "clubId": action.payload.clubId
          }, true);
          _a.label = 10;

        case 10:
          return [3
          /*break*/
          , 11];

        case 11:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._castVote = function (action) {
    var err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.ballotBoxService.castVote(action.payload.ballotChoiceUri)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.voteFeedItemSuccess(action.payload))];

        case 2:
          _a.sent();

          this.notificationService.notifySuccess("BallotCastVote");
          return [3
          /*break*/
          , 4];

        case 3:
          err_5 = _a.sent();
          Utils_1.LogHttpsError("Unable to vote on poll", err_5);
          this.telemetryService.trackException(err_5, "BallotCastVote", {
            "action": action.payload
          }, true);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._createBallotFeedItem = function (action) {
    var combinedText, hasOffensiveStrings, result, payload, postData, ballotAction, err_6;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 4,, 5]);

          combinedText = action.payload.ballotOptions.Choices.concat([action.payload.ballotOptions.PollQuestion]);
          return [4
          /*yield*/
          , this.validateStringAndNotify(combinedText)];

        case 1:
          hasOffensiveStrings = _a.sent(); // we only want to stop posting if offending strings detected, in case if call fails we will just ignore this check.

          if (hasOffensiveStrings) {
            return [2
            /*return*/
            ];
          }

          return [4
          /*yield*/
          , this.ballotBoxService.createBallot(action.payload.ballotOptions)];

        case 2:
          result = _a.sent();
          payload = action.payload.postData;
          postData = {
            "pollId": result.data.PollId,
            "creatorId": result.data.CreatorId
          };
          payload.postTypeData = postData;
          ballotAction = {
            clubId: action.payload.clubId,
            postData: payload,
            isScheduled: action.payload.isScheduled,
            isPinned: action.payload.isPinned,
            isDisabled: action.payload.isDisabled
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedItem(ballotAction))];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 5];

        case 4:
          err_6 = _a.sent();
          Utils_1.LogHttpsError("Unable to create ballot, error", err_6);
          this.telemetryService.trackException(err_6, "BallotCreation", {
            "action": action.payload
          }, true);
          return [3
          /*break*/
          , 5];

        case 5:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._likeFeedItem = function (action) {
    var actionType, itemRoot, err_7, errPayload;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          actionType = "";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 7,, 9]);

          itemRoot = action.payload.itemRoot;
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hasLikedFeed(action.payload))];

        case 2:
          _a.sent();

          if (!(action.payload.like === true)) return [3
          /*break*/
          , 4];
          actionType = "LikeClick";
          this.telemetryService.trackEvent(actionType, {
            "type": action.payload.itemType,
            "hasLiked": "true"
          });
          return [4
          /*yield*/
          , this.commentService.like(itemRoot)];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 6];

        case 4:
          actionType = "UnlikeClick";
          this.telemetryService.trackEvent(actionType, {
            "type": action.payload.itemType,
            "hasLiked": "false"
          });
          return [4
          /*yield*/
          , this.commentService.unlike(itemRoot)];

        case 5:
          _a.sent();

          _a.label = 6;

        case 6:
          this.activityFeedService.clearMostRecentPolicy();
          return [3
          /*break*/
          , 9];

        case 7:
          err_7 = _a.sent();
          errPayload = {
            itemRoot: "",
            like: false,
            itemType: ""
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hasLikedFeed(errPayload))];

        case 8:
          _a.sent();

          Utils_1.LogHttpsError("_likeFeedItem", err_7);
          this.telemetryService.trackException(err_7, actionType, {
            "clubId": action.payload,
            "type": action.payload.itemType,
            "action": "like_unlike_item"
          });
          return [3
          /*break*/
          , 9];

        case 9:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._postFeedItem = function (action) {
    var postData, hasOffensiveStrings, wasPinned, result, locator, err_8;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          postData = action.payload.postData;
          return [4
          /*yield*/
          , this.validateStringAndNotify([postData.postText])];

        case 1:
          hasOffensiveStrings = _a.sent(); // we only want to stop posting if offending strings detected, in case if call fails we will just ignore this check.

          if (hasOffensiveStrings) {
            return [2
            /*return*/
            ];
          }

          wasPinned = false;
          _a.label = 2;

        case 2:
          _a.trys.push([2, 15,, 17]);

          if (!action.payload.clubId) return [3
          /*break*/
          , 14];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedItemInProgress(true))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , this.activityFeedService.createPost(postData)];

        case 4:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedItemInProgress(false))];

        case 5:
          _a.sent();

          if (!action.payload.isShared) return [3
          /*break*/
          , 7];
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.shareFeedItemCompleted(result.data))];

        case 6:
          _a.sent();

          return [3
          /*break*/
          , 9];

        case 7:
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedItemCompleted(result.data))];

        case 8:
          _a.sent();

          _a.label = 9;

        case 9:
          this.telemetryService.trackEvent("FeedItemPosted", {
            "type: ": postData.postType,
            "clubId: ": action.payload.clubId
          });
          this.notificationService.notifySuccess("FeedItemPost");
          locator = result.data.postUri;

          if (result.data.timelines.length > 0) {
            locator = result.data.timelines[0].timelineUri ? result.data.timelines[0].timelineUri : locator;
          }

          if (!(action.payload.isDisabled && locator)) return [3
          /*break*/
          , 11];
          this.telemetryService.trackEvent("FeedItemCommentDisabled", {
            "lockComments, clubId: ": postData.timelines[0].timelineOwner
          });
          return [4
          /*yield*/
          , this.commentService.lockComments(locator, action.payload.clubId)];

        case 10:
          _a.sent();

          _a.label = 11;

        case 11:
          if (!(action.payload.isPinned && locator)) return [3
          /*break*/
          , 13];
          this.telemetryService.trackEvent("FeedItemPinned", {
            "addPin, clubId: ": postData.timelines[0].timelineOwner
          });
          return [4
          /*yield*/
          , this.activityFeedService.addPin(locator, postData.timelines[0].timelineType, postData.timelines[0].timelineOwner, postData.timelines[0].channelId)];

        case 12:
          _a.sent();

          wasPinned = true;
          _a.label = 13;

        case 13:
          this.activityFeedService.clearMostRecentPolicy();
          _a.label = 14;

        case 14:
          return [3
          /*break*/
          , 17];

        case 15:
          err_8 = _a.sent();

          if (action.payload.isPinned && !wasPinned) {
            console.warn("Unable to pin post for club " + action.payload.clubId);
            this.telemetryService.trackException(err_8, "FeedItemPin", {
              "clubId": action.payload.clubId
            });
          } else {
            console.warn("Unable to post feed item type " + action.payload.postData.postType + " for club " + action.payload.clubId);
            this.telemetryService.trackException(err_8, "FeedItemPost", {
              "clubId": action.payload.clubId
            }, true);
          }

          Utils_1.LogHttpsError("_postFeedItem", err_8);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedItemInProgress(false))];

        case 16:
          _a.sent();

          return [3
          /*break*/
          , 17];

        case 17:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._validateString = function (action) {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , this.validateStringAndNotify(action.payload.stringsToVerify)];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._hydrateWebLink = function (action) {
    var result, linkData, temp, err_9;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 8,, 10]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkInProgress(true))];

        case 1:
          _a.sent();

          linkData = action.payload.link;
          this.telemetryService.trackEvent("HydrateLink", {
            "link: ": linkData
          });
          return [4
          /*yield*/
          , this.activityFeedService.hydrateWebLink(linkData)];

        case 2:
          result = _a.sent();
          if (!(result.status === 200 && result.data.Link === null)) return [3
          /*break*/
          , 4];
          temp = {
            Description: linkData,
            ImageUrl: "",
            Link: "",
            LinkData: null,
            LinkType: "ValidWithNulls",
            Title: ""
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkCompleted(temp))];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 6];

        case 4:
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkCompleted(result.data))];

        case 5:
          _a.sent();

          _a.label = 6;

        case 6:
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkInProgress(false))];

        case 7:
          _a.sent();

          return [3
          /*break*/
          , 10];

        case 8:
          err_9 = _a.sent();
          console.warn("Unable to hydrate link " + action.payload.link + ", error: " + err_9);
          Utils_1.LogHttpsError("HydrateLink", err_9);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkInProgress(false))];

        case 9:
          _a.sent();

          this.telemetryService.trackException(err_9, "HydrateLink", {
            "link": action.payload.link
          });
          return [3
          /*break*/
          , 10];

        case 10:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._hydrateBatchFeedItemsPreview = function (action) {
    var result, rawItems, formattedItems, normalizedItems, err_10;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItemsInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("HydrateBatchFeedItemsPreview");
          return [4
          /*yield*/
          , this.activityFeedService.getBatchFeedItemsPreview(action.payload.itemsId)];

        case 2:
          result = _a.sent();
          rawItems = result.data["hydrateItems"];
          formattedItems = rawItems.map(function (x) {
            return Object.assign({
              "gameMediaContentLocators": x.gameMediaContentLocators
            }, {
              "postDetails": x.postDetails
            }, {
              "postType": x.postType
            }, x.itemInfo);
          });
          normalizedItems = ActivityFeedNormalizer_1.activityFeedNormalizer(formattedItems);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItemsCompleted(normalizedItems))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItemsInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_10 = _a.sent();
          Utils_1.LogHttpsError("HydrateBatchFeedItemsPreview", err_10);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItemsInProgress(false))];

        case 6:
          _a.sent();

          this.telemetryService.trackException(err_10, "HydrateBatchFeedItemsPreview", {
            "action": "hydrate_items_preview"
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._adminActOnFeedItem = function (action) {
    var item, _a, rosterUpdate, disableCommentPayload, enableCommentPayload, reportItem, reportItem, err_11;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          _b.trys.push([0, 27,, 28]);

          item = action.payload.item;
          _a = action.payload.action;

          switch (_a) {
            case social_core_1.ActivityFeed.AdminActionTypes.ScheduledPostDelete:
              return [3
              /*break*/
              , 1];

            case social_core_1.ActivityFeed.AdminActionTypes.BallotPostDelete:
              return [3
              /*break*/
              , 1];

            case social_core_1.ActivityFeed.AdminActionTypes.Delete:
              return [3
              /*break*/
              , 1];

            case social_core_1.ActivityFeed.AdminActionTypes.DeleteAndBan:
              return [3
              /*break*/
              , 4];

            case social_core_1.ActivityFeed.AdminActionTypes.DisableComment:
              return [3
              /*break*/
              , 9];

            case social_core_1.ActivityFeed.AdminActionTypes.EnableComment:
              return [3
              /*break*/
              , 12];

            case social_core_1.ActivityFeed.AdminActionTypes.UnPin:
              return [3
              /*break*/
              , 15];

            case social_core_1.ActivityFeed.AdminActionTypes.Pin:
              return [3
              /*break*/
              , 18];

            case social_core_1.ActivityFeed.AdminActionTypes.AddToModQ:
              return [3
              /*break*/
              , 21];

            case social_core_1.ActivityFeed.AdminActionTypes.Report:
              return [3
              /*break*/
              , 23];
          }

          return [3
          /*break*/
          , 25];

        case 1:
          return [4
          /*yield*/
          , this.activityFeedService.deletePost(item.itemRoot)];

        case 2:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedItemDeleted(item.feedItemId))];

        case 3:
          _b.sent();

          this.notificationService.notifySuccess("delete");
          return [3
          /*break*/
          , 26];

        case 4:
          return [4
          /*yield*/
          , this.activityFeedService.deletePost(item.itemRoot)];

        case 5:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedItemDeleted(item.feedItemId))];

        case 6:
          _b.sent();

          rosterUpdate = {
            add: true,
            clubId: action.payload.clubId,
            role: social_core_1.ClubHub.ClubRoles.Banned,
            xuid: action.payload.xuid
          };
          return [4
          /*yield*/
          , this.clubHubService.addClubMemberRole(action.payload.clubId, action.payload.xuid, [social_core_1.ClubHub.ClubRoles.Banned])];

        case 7:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(__1.ClubActions.clubRosterUpdateRoleProperty(rosterUpdate))];

        case 8:
          _b.sent();

          this.notificationService.notifySuccess("deleteAndBan");
          return [3
          /*break*/
          , 26];

        case 9:
          return [4
          /*yield*/
          , this.commentService.lockComments(item.itemRoot, action.payload.clubId)];

        case 10:
          _b.sent();

          disableCommentPayload = {
            cursor: item.comments ? item.comments.cursor : "",
            postId: item.itemRoot,
            numberOfItems: 10
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchFeedComments(disableCommentPayload))];

        case 11:
          _b.sent();

          this.notificationService.notifySuccess("disableComment");
          return [3
          /*break*/
          , 26];

        case 12:
          return [4
          /*yield*/
          , this.commentService.unlockComments(item.itemRoot, action.payload.clubId)];

        case 13:
          _b.sent();

          enableCommentPayload = {
            itemId: "commentthreadlock",
            feedItemRoot: item.itemRoot
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.commentItemDeleted(enableCommentPayload))];

        case 14:
          _b.sent();

          this.notificationService.notifySuccess("enableComment");
          return [3
          /*break*/
          , 26];

        case 15:
          return [4
          /*yield*/
          , this.activityFeedService.removePin(item.itemRoot, "Club", action.payload.clubId, action.payload.channelId)];

        case 16:
          _b.sent();

          this.telemetryService.trackEvent("FeedItemUnPinned", {
            "removePin, clubId: ": action.payload.clubId
          });
          this.notificationService.notifySuccess("unpin");
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hasPinnedFeed(action.payload))];

        case 17:
          _b.sent();

          return [3
          /*break*/
          , 26];

        case 18:
          return [4
          /*yield*/
          , this.activityFeedService.addPin(item.itemRoot, "Club", action.payload.clubId, action.payload.channelId)];

        case 19:
          _b.sent();

          this.telemetryService.trackEvent("FeedItemPinned", {
            "addPin, clubId: ": action.payload.clubId
          });
          this.notificationService.notifySuccess("pin");
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hasPinnedFeed(action.payload))];

        case 20:
          _b.sent();

          return [3
          /*break*/
          , 26];

        case 21:
          reportItem = {
            targetXuid: action.payload.clubId,
            contentId: item.itemRoot,
            contentType: "ActivityFeedItem"
          };
          return [4
          /*yield*/
          , this.activityFeedService.reportToClub(reportItem, action.payload.clubId)];

        case 22:
          _b.sent();

          this.notificationService.notifySuccess("report");
          return [3
          /*break*/
          , 26];

        case 23:
          reportItem = {
            clubId: action.payload.clubId,
            evidenceId: item.itemRoot,
            feedbackContext: "Club",
            feedbackType: social_core_1.FeedbackType.UserContentActivityFeed
          };
          return [4
          /*yield*/
          , this.activityFeedService.reportToEnforcement(reportItem, action.payload.xuid)];

        case 24:
          _b.sent();

          this.notificationService.notifySuccess("report");
          return [3
          /*break*/
          , 26];

        case 25:
          return [2
          /*return*/
          ];

        case 26:
          this.activityFeedService.clearMostRecentPolicy();
          return [3
          /*break*/
          , 28];

        case 27:
          err_11 = _b.sent();
          console.warn("Unable to perform " + action.payload.action + " item, error: " + err_11);
          Utils_1.LogHttpsError("FeedItemAdminAction", err_11);
          this.telemetryService.trackException(err_11, "FeedItemAdminAction", {
            "action": action.payload.action
          }, true);
          return [3
          /*break*/
          , 28];

        case 28:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._getFeedSettings = function (action) {
    var result, err_12;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          this.telemetryService.trackEvent("FetchFeedSettings", {
            "userId": action.payload
          });
          return [4
          /*yield*/
          , this.activityFeedService.getFeedSettings(action.payload)];

        case 1:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.getFeedSettingsCompleted(result.feedSettings))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_12 = _a.sent();
          console.warn("Unable to get feed settings: " + err_12);
          this.telemetryService.trackException(err_12, "FetchFeedSettings", {
            "userId": action.payload
          });
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._updateFeedSetting = function (action) {
    var err_13;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          this.telemetryService.trackEvent("UpdateFeedSetting", {
            "userId": action.payload
          });
          return [4
          /*yield*/
          , this.activityFeedService.updateFeedSettings(action.payload.userId, action.payload.settings)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.updateFeedSettingCompleted(action.payload.settings))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_13 = _a.sent();
          console.warn("Unable to update feed settings: " + err_13);
          this.telemetryService.trackException(err_13, "UpdateFeedSetting", {
            "userId": action.payload
          });
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype._fetchAndSelectFeedItem = function (action) {
    var result, rawItems, formatted, err_14;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItemsInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("FetchAndSelectFeedItem");
          return [4
          /*yield*/
          , this.activityFeedService.getBatchFeedItemsPreview(action.payload.itemsId)];

        case 2:
          result = _a.sent();
          rawItems = result.data["hydrateItems"];
          formatted = this.formatFeedItem(rawItems[0]);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.feedItemSelected(formatted))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchAndSelectFeedItemInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_14 = _a.sent();
          Utils_1.LogHttpsError("FetchAndSelectFeedItem", err_14);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchAndSelectFeedItemInProgress(false))];

        case 6:
          _a.sent();

          this.telemetryService.trackException(err_14, "FetchAndSelectFeedItem", {
            "action": "fetch_and_select_feedItem"
          }, true);
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };
  /*********************************************************************/

  /* Watchers
  /*********************************************************************/


  FeedViewerSaga.prototype.watchFormatFeedData = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.formatFeedData.type, this._formatFeedData.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchHydrateFetchItemsPreview = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.hydrateFeedItems.type, this._hydrateBatchFeedItemsPreview.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchClubFeedFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchClubFeedItems.type, this._fetchClubFeedItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchPersonalFeedFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchPersonalFeedItems.type, this._fetchPersonalFeedItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchBallotFeedFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchBallotFeedItems.type, this._fetchBallotFeedItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchScheduledFeedFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchScheduledFeedItems.type, this._fetchScheduledFeedItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchLikeFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.likeFeedItem.type, this._likeFeedItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchCreateBallotFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.createBallot.type, this._createBallotFeedItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchVoteFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.voteFeedItem.type, this._castVote.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchPostFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.postFeedItem.type, this._postFeedItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchHydrateWebLink = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.hydrateWebLink.type, this._hydrateWebLink.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchValidateString = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.validateString.type, this._validateString.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchAdminActOnFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.adminActOnItem.type, this._adminActOnFeedItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchGetFeedSettings = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.getFeedSettings.type, this._getFeedSettings.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchUpdateFeedSetting = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.updateFeedSetting.type, this._updateFeedSetting.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  FeedViewerSaga.prototype.watchFetchAndSelectFeedItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchAndSelectFeedItem.type, this._fetchAndSelectFeedItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };
  /*********************************************************************/

  /* Private functions
  /*********************************************************************/


  FeedViewerSaga.prototype.validateStringAndNotify = function (stringsToVerify) {
    var hasOffensiveString;
    return __generator(this, function (_a) {
      hasOffensiveString = false;
      /*
      try {
          yield put(Actions.validateStringInProgress(true));
                let result: Http.IHttpResponse<StringValidation.StringValidationResult>;
          result = yield this.activityFeedService.validateStrings(stringsToVerify);
          yield put(Actions.validateStringCompleted(result.data.verifyStringResult));
          yield put(Actions.validateStringInProgress(false));
                hasOffensiveString = result.data.verifyStringResult.some(item => item.offendingString !== null);
          if (result.status === 200 && hasOffensiveString) {
              // Show user which string validation failed
              let offensiveIndexes = result.data.verifyStringResult.filter(item => item.offendingString !== null).map((value: any) => value.offendingString);
              this.notificationService.modalErrorPayload("StringValidation", {textPayload: offensiveIndexes});
          }
      } catch (err) {
          yield put(Actions.validateStringInProgress(false));
          console.warn(`Unable to validate post text data`);
          this.telemetryService.trackException(err, "StringValidation");
      }
      */

      return [2
      /*return*/
      , hasOffensiveString];
    });
  };

  FeedViewerSaga.prototype.formatFeedItem = function (item) {
    var result;

    switch (item.itemInfo.activityItemType) {
      case "Ballot":
        var pollData = {
          "endTime": item.endTime,
          "canVote": item.canVote,
          "choices": item.choices,
          "pollId": item.pollId,
          "myChoice": item.myChoice,
          "visible": item.visible,
          "pollQuestion": item.pollQuestion
        };
        result = Object.assign({
          "pollData": pollData
        }, item.itemInfo);
        break;

      case "TextPost":
        result = item.itemInfo;
        break;

      case "UserPost":
        result = Object.assign({
          "postDetails": item.postDetails
        }, {
          "postType": item.postType
        }, item.itemInfo);
        break;

      case "GameDVR":
      case "Screenshot":
        result = Object.assign({
          "gameMediaContentLocators": item.gameMediaContentLocators
        }, item.itemInfo);
        break;

      default:
        result = item.itemInfo;
        break;
    }

    return result;
  };

  FeedViewerSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IActivityFeedService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ICommentService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.IClubHubService)), __param(4, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(5, inversify_1.inject(social_core_1.XSocialSymbols.IBallotBoxService)), __metadata("design:paramtypes", [social_core_1.ActivityFeedService, Object, Object, Object, Object, Object])], FeedViewerSaga);
  return FeedViewerSaga;
}();

exports.FeedViewerSaga = FeedViewerSaga;