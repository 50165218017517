"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorizeWithSisu = void 0;

var OperationBase_1 = require("../OperationBase");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var StepTracker_1 = require("../StepTracker");

var Utils_1 = require("../../Utils");

var http_1 = require("@xbox/http");

var XalHttpRequest_1 = require("../../XalHttpRequest");

var Constants_1 = require("../../Constants");

var XboxTokenData_1 = require("../../XboxTokenData");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var XalInternalError_1 = require("../../errors/XalInternalError");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["FetchTokens"] = "FetchTokens";
  Step["ParseTokens"] = "ParseTokens";
  Step["WriteTtoken"] = "WriteTtoken";
  Step["WriteUtoken"] = "WriteUtoken";
  Step["WriteXtoken"] = "WriteXtoken";
  Step["Done"] = "Done";
})(Step || (Step = {})); /// <summary>
/// Operation for retrieving U,T, and X tokens from SISU. May return a URL
/// for displaying UI.
/// </summary>


var AuthorizeWithSisu =
/** @class */
function (_super) {
  __extends(AuthorizeWithSisu, _super);

  function AuthorizeWithSisu(telemetryClient, components, httpClient, dToken, tToken, uToken, xToken, msaUserId, msaTicket, sessionId, clusterAffinity) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.components = components;
    _this.httpClient = httpClient;
    _this.dToken = dToken;
    _this.tToken = tToken;
    _this.uToken = uToken;
    _this.xToken = xToken;
    _this.msaUserId = msaUserId;
    _this.msaTicket = msaTicket;
    _this.sessionId = sessionId;
    _this.clusterAffinity = clusterAffinity;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.AuthorizeWithSisu, Step.Start);
    Utils_1.assert(!!dToken);
    Utils_1.assert(!!tToken);
    Utils_1.assert(!!uToken);
    Utils_1.assert(!!xToken);
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(msaTicket));
    return _this;
  }

  AuthorizeWithSisu.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var sisuUri, request, proofKey, body, sisuNsalEntry, response, authenticateHeader, xerrHeader, xerr, ttokenData, operationResult;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            this.step.advance(Step.FetchTokens);
            sisuUri = Utils_1.isNullOrWhiteSpace(this.clusterAffinity) ? this.components.config.sisuEndpoint : this.clusterAffinity;
            sisuUri = sisuUri.concat("/authorize");
            request = new XalHttpRequest_1.XalHttpRequest(this.httpClient, http_1.Http.HttpRequestMethod.Post, sisuUri);
            request.setHeader(Constants_1.HeaderKeys.ContentType, Constants_1.HeaderValues.ApplicationJsonUtf8);
            return [4
            /*yield*/
            , this.components.xboxCache.deviceIdentity.key.proofKey()];

          case 1:
            proofKey = _a.sent();
            body = {
              AccessToken: this.msaTicket,
              DeviceToken: this.dToken.tokenValue,
              Sandbox: this.components.config.sandbox,
              SiteName: this.components.config.userTokenSiteName,
              ProofKey: proofKey
            };

            if (!Utils_1.isNullOrWhiteSpace(this.sessionId)) {
              body.SessionId = this.sessionId;
            }

            request.setBody(JSON.stringify(body));
            sisuNsalEntry = this.components.nsal.lookup(sisuUri);
            if (!sisuNsalEntry.Policy) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , request.signRequest(this.components.xboxCache.deviceIdentity.key, sisuNsalEntry.Policy)];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 4];

          case 3:
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "NSAL indicates not signing SISU request.");
            _a.label = 4;

          case 4:
            return [4
            /*yield*/
            , request.execute()];

          case 5:
            response = _a.sent();
            this.step.advance(Step.ParseTokens);
            authenticateHeader = response.headers["www-authenticate"];

            if (!Utils_1.isNullOrWhiteSpace(authenticateHeader)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU token fetch returned WWW-Authenticate header: " + authenticateHeader);
            }

            xerrHeader = response.headers["x-err"];

            if (!Utils_1.isNullOrWhiteSpace(xerrHeader)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU token fetch returned X-Err header: " + xerrHeader);
              xerr = parseInt(xerrHeader, 10);

              if (xerr !== NaN) {
                this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.AuthorizeWithSisu, "XErr received from SISU authorize " + xerr + ".", ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.NetworkError);

                switch (xerr) {
                  case Constants_1.Xerr.ExpiredDtoken:
                  case Constants_1.Xerr.InvalidDtoken:
                  case Constants_1.Xerr.InvalidMsaTicket:
                    this.step.advance(Step.Done);
                    this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.BadUserToken, "SISU token fetch returned X-Err header: " + xerr));
                    return [2
                    /*return*/
                    ];

                  default:
                    break;
                }
              }
            }

            if (response.status === 401) {
              // Getting a 401 back from the server means we might have a Page URL to
              // show the user, so we don't fail on that code
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "SISU token fetch returned 401");
            } else if (!Utils_1.isHttpSuccesStatusCode(response.status)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU token fetch failed with HTTP status " + response.status);
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "SISU token fetch failed with HTTP status " + response.status));
              return [2
              /*return*/
              ];
            }

            if (!response.data) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "SISU response data root is not an object");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "SISU response data root is not an object"));
              return [2
              /*return*/
              ];
            }

            this.xtokenData = XboxTokenData_1.XboxTokenData.deserializeTokenField(response.data.AuthorizationToken);
            ttokenData = XboxTokenData_1.XboxTokenData.deserializeTokenField(response.data.TitleToken);
            this.utokenData = XboxTokenData_1.XboxTokenData.deserializeTokenField(response.data.UserToken);
            this.pageUrl = response.data.WebPage; // If we got a 401 and didn't get a web page back from SISU we're dead
            // in the water

            if (response.status === 401 && (!this.pageUrl || this.pageUrl.length < 0)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Expected page URL missing from response.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "Expected page URL missing from response."));
              return [2
              /*return*/
              ];
            }

            this.sessionId = response.headers["x-sessionid"];
            this.clusterAffinity = response.headers["x-cluster-affinity"];

            if (!ttokenData || !this.utokenData) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Ttoken or utoken missing: ttoken: " + ttokenData + ", utoken: " + this.utokenData);
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Ttoken or utoken missing: ttoken: " + ttokenData + ", utoken: " + this.utokenData));
              return [2
              /*return*/
              ];
            } // Validate the title ID we received from the ttoken matches what was passed in


            if (ttokenData.titleId !== this.components.config.titleId) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Title ID returned by authorize call '" + ttokenData.titleId + "' does not match title ID in XAL config '" + this.components.config.titleId);
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Title ID returned by authorize call '" + ttokenData.titleId + "' does not match title ID in XAL config '" + this.components.config.titleId));
              return [2
              /*return*/
              ];
            }

            this.step.advance(Step.WriteTtoken);
            return [4
            /*yield*/
            , this.components.xboxCache.writeToken(this.tToken, ttokenData)];

          case 6:
            _a.sent();

            this.step.advance(Step.WriteUtoken);
            return [4
            /*yield*/
            , this.components.xboxCache.writeToken(this.uToken, this.utokenData)];

          case 7:
            _a.sent();

            this.step.advance(Step.WriteXtoken);
            if (!this.xtokenData) return [3
            /*break*/
            , 9];
            return [4
            /*yield*/
            , this.components.xboxCache.writeToken(this.xToken, this.xtokenData)];

          case 8:
            _a.sent();

            _a.label = 9;

          case 9:
            operationResult = {
              xtoken: this.xToken,
              ttoken: this.tToken,
              utoken: this.uToken,
              pageUrl: this.pageUrl,
              sessionId: this.sessionId,
              clusterAffinity: this.clusterAffinity
            };
            this.succeed(operationResult);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return AuthorizeWithSisu;
}(OperationBase_1.OperationBase);

exports.AuthorizeWithSisu = AuthorizeWithSisu;