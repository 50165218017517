import * as React from "react";
import { PromiseCompletionSource } from "@xbox/framework";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers, IFeedItemAdminActionHanders, FeedManipulationProps } from "@xbox/social-views";

import { ActivityFeedHeaderButtons } from "../feeditems/base/ActivityFeedHeaderButtons";
import { ActivityFeedDiv } from "../../containers/Styles";
import { InfiniteScrollerSelector } from "../infiniteScrollers/InfiniteScrollerSelector";
import ShareModalContainer from "../../containers/clubadmin/ShareModalContainer";

import { FeedViewType, feedViewTypeToScrollType } from "../infiniteScrollers/base/InfiniteScrollTypes";
import { FeedItemSelector } from "../../components/feeditems/FeedItemSelector";
import { GenericEmptyState, GenericLoading } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";

export interface BaseActivityFeedContainerProps {
    listData: ActivityFeed.FeedItem[];
    isValid: boolean;
    isErrored: boolean;
    cursor?: string;
    isCommentLocked?: boolean;
    socialActions?: IFeedItemSocialActionHandlers;
    adminActions?: IFeedItemAdminActionHanders;
    updateMethod: (cursor?: string) => void;
    clearActivityFeed: () => void;
    feedItemSelected: (item: ActivityFeed.FeedItem) => void;
    fetchCommentLock?: (item: ActivityFeed.FeedItem) => void;
    forcedFeedType?: FeedViewType;
    feedManipulationProps?: FeedManipulationProps;
}

interface BaseActivityFeedContainerState {
    feedViewType: FeedViewType;
    showShareItemModal: boolean;
}

export abstract class BaseActivityFeed extends React.Component<BaseActivityFeedContainerProps, BaseActivityFeedContainerState> {
    protected feedItemsUpdated?: PromiseCompletionSource<boolean>;
    protected storageKey: "clubFeedType" | "clublessFeedType" = "clublessFeedType";
    constructor(props: BaseActivityFeedContainerProps) {
        super(props);
        this.state = ({
            feedViewType: FeedViewType.CompactView,
            showShareItemModal: false
        });
    }

    componentDidMount() {
        this.props.clearActivityFeed();
        this.updateFeedItems();
    }

    // MODAL MANIPULATION START
    showShareItemModal = (item: ActivityFeed.FeedItem) => {
        this.props.feedItemSelected(item);
        this.setState({showShareItemModal: true});
    }

    hideShareItemModal = () => {
        this.setState({showShareItemModal: false});
    }
    // MODAL MANIPULATION END

    loadStoredViewType = () => {
        let savedClubFeedType = FeedViewType[localStorage.getItem(this.storageKey) || "none"];

        if (savedClubFeedType === undefined) {
            savedClubFeedType = FeedViewType.CompactView;
            localStorage.setItem(this.storageKey, savedClubFeedType);
        }
        return savedClubFeedType;
    }

    setFeedViewType = (newView: FeedViewType) => {
        if (!this.props.forcedFeedType) {
            this.setState({ feedViewType: newView });
            localStorage.setItem(this.storageKey, newView);
        }
    }

    getElementHeight = () => {
        let height: number|undefined = undefined;
        if (this.state.feedViewType !== FeedViewType.FullView) {
            height = this.state.feedViewType === FeedViewType.GridView
                        ? 260
                        : 130;
        }
        return height;
    }

    getScrollerTypeByViewType = () => {
        return feedViewTypeToScrollType(this.state.feedViewType);
    }

    refreshFeed = () => {
        this.props.clearActivityFeed();

        this.updateFeedItems();
        this.feedItemsUpdated = new PromiseCompletionSource<boolean>();

        return this.feedItemsUpdated.promise;
    }

    updateMethod = (): Promise<boolean> => {
        if (this.props.cursor && this.props.cursor !== "0") {
            this.updateFeedItems(this.props.cursor);
        }

        this.feedItemsUpdated = new PromiseCompletionSource<boolean>();

        return this.feedItemsUpdated.promise;
    }

    updateFeedItems = (cursor?: string) => {
        this.props.updateMethod(cursor);
    }

    mapFeedData = (feedItems: ActivityFeed.FeedItem[]) => {
        let socialActions: IFeedItemSocialActionHandlers | undefined = this.props.socialActions;
        if (socialActions) {
            socialActions.showShareModal = this.showShareItemModal;
        }

        let feedView = this.state.feedViewType;
        let adminActions: IFeedItemAdminActionHanders | undefined = this.props.adminActions;
        return this.props.listData.map(function (item: ActivityFeed.FeedItem) {
            return {item: item, properties: {socialActions: socialActions, adminActions: adminActions, feedViewType: feedView  }};
        });
    }

    itemGenerator = (item: any, meassure: () => void) => {
        return this.getFeedItemType(item.item, item.properties.socialActions, item.properties.adminActions, item.properties.feedViewType, meassure);
    }

    getFeedItemType = (
        item: ActivityFeed.FeedItem,
        socialActions?: IFeedItemSocialActionHandlers,
        adminActions?: IFeedItemAdminActionHanders,
        feedViewType?: FeedViewType,
        onload?: () => void
    ): JSX.Element | null => {
        if (!item) {
            return (<div />);
        }
        return (
            <FeedItemSelector
                key={item.feedItemId}
                item={item}
                adminActions={adminActions}
                socialActions={socialActions}
                showAdminAction={true}
                onload={onload}
                viewType={feedViewType}
                isCommentLocked={this.props.isCommentLocked}
                fetchCommentLock={this.props.fetchCommentLock}
                showFullDescription={true}
            />
        );
    }

    loadingState = () => {
        return <GenericLoading/>;
    }

    emptyState = () => {
        return (
            <GenericEmptyState
                message={Localize("activityFeedContainer.emptyState")}
                subTitle={Localize("activityFeedContainer.subTitle")}
            />
        );
    }

    getHeaderButtons = (): JSX.Element | null => {

        if (!this.props.feedManipulationProps) {
            return null;
        }

        return (
            <ActivityFeedHeaderButtons
                postStatus={this.props.feedManipulationProps.postStatus}
                isModerator={false}
                switchToFeedTools={() => { return; }}
                onRefreshClick={this.refreshFeed}
                showRefresh={true}
                feedViewType={this.state.feedViewType}
                setFeedViewType={(type: any) => this.setFeedViewType(type)}
            />
        );
    }

    render() {

        if (this.props.isErrored) {
            return this.emptyState();
        }

        if (!this.props.isValid) {
            return this.loadingState();
        }
        let activityFeedData: any[] = this.mapFeedData(this.props.listData);
        return (
            <ActivityFeedDiv className="ClubFeedContainer" style={{height: "inherit", width: "100%"}}>
                {this.getHeaderButtons()}
                <ShareModalContainer showShareModal={this.state.showShareItemModal} onCancelShare={this.hideShareItemModal}/>
                <InfiniteScrollerSelector
                    scrollerType={this.getScrollerTypeByViewType()}
                    listData={activityFeedData}
                    itemGenerator={this.itemGenerator}
                    updateMethod={this.updateMethod}
                    emptyState={this.emptyState}
                    overscanRowCount={10}
                    defaultHeight={this.getElementHeight()}
                />
            </ActivityFeedDiv>
        );
    }
}
