"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebLocalStorage = void 0;

var WebLocalStorage =
/** @class */
function () {
  function WebLocalStorage() {}

  WebLocalStorage.prototype.read = function (key) {
    return Promise.resolve(localStorage.getItem(key));
  };

  WebLocalStorage.prototype.write = function (key, data) {
    return Promise.resolve(localStorage.setItem(key, data));
  };

  WebLocalStorage.prototype.clear = function (key) {
    return Promise.resolve(localStorage.removeItem(key));
  };

  return WebLocalStorage;
}();

exports.WebLocalStorage = WebLocalStorage;