"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaHubSaga = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var MediaHubActions_1 = require("../actions/MediaHubActions");

var ClubListActions_1 = require("../actions/ClubListActions");

var CancellationToken_1 = require("../typings/CancellationToken");

var Utils_1 = require("./Utils"); // More info: xboxwiki/wiki/GameMedia


var UploadMediaItemType = "UploadMediaItem";
var UploadProgress;

(function (UploadProgress) {
  UploadProgress[UploadProgress["Error"] = -1] = "Error";
  UploadProgress[UploadProgress["Done"] = 0] = "Done";
  UploadProgress[UploadProgress["Init"] = 5] = "Init";
  UploadProgress[UploadProgress["Created"] = 10] = "Created";
  UploadProgress[UploadProgress["ContentDone"] = 80] = "ContentDone";
  UploadProgress[UploadProgress["ThumbnailDone"] = 85] = "ThumbnailDone";
  UploadProgress[UploadProgress["PublishDone"] = 95] = "PublishDone";
})(UploadProgress || (UploadProgress = {}));

var MediaHubSaga =
/** @class */
function () {
  function MediaHubSaga(mediaHubService, titleHubService, telemetryService, notificationService, userManager) {
    var _this = this;

    this.mediaHubService = mediaHubService;
    this.titleHubService = titleHubService;
    this.telemetryService = telemetryService;
    this.notificationService = notificationService;
    this.userManager = userManager;
    this.sagas = [this.watchFetchScreenshots(), this.watchFetchGameclips(), this.watchUploadScreenshot(), this.watchUploadGameclip(), this.watchDeleteScreenshot(), this.watchDeleteGameClip(), this.watchMetadataScreenshots(), this.watchMetadataGameclips(), this.watchUsersMediaTitles(), this.watchUploadCustomPic(), this.watchUsersQuota()];

    this.getMediaItemLocator = function (mediaType, xuid, contentId) {
      switch (mediaType) {
        case social_core_1.MediaServiceType.Screenshots:
          var scrRoot = "screenshotsmetadata.xboxlive.com/users";
          return scrRoot + "/xuid(" + xuid + ")/scids/00000000-0000-0000-0000-000000000000/screenshots/" + contentId;

        case social_core_1.MediaServiceType.Gameclips:
          var clipsRoot = "gameclipsmetadata.xboxlive.com/users";
          return clipsRoot + "/xuid(" + xuid + ")/scids/00000000-0000-0000-0000-000000000000/clips/" + contentId;

        case social_core_1.MediaServiceType.CustomPics:
        default:
          return "";
      }
    };

    this.notifyUserAboutUploadError = function (err, type) {
      if (err.message === CancellationToken_1.CancellationErrorMsg) {
        _this.telemetryService.trackEvent(UploadMediaItemType, {
          "status": "canceled"
        });

        _this.notificationService.notifyInfo(UploadMediaItemType);
      } else if (err.response.status === http_1.Http.HttpStatus.Conflict && err.response.data.errorCode === 10603) {
        _this.telemetryService.trackEvent(UploadMediaItemType, {
          "status": "rejected"
        });

        _this.notificationService.notifyError(UploadMediaItemType, "10603");
      } else {
        _this.telemetryService.trackException(err, UploadMediaItemType, {
          "status": "interrupted",
          "type": type
        }, true);
      }
    }; // empty

  }

  MediaHubSaga_1 = MediaHubSaga;

  MediaHubSaga.prototype._fetchMediaItems = function (mediaType, progressAction, completedAction, action) {
    var currentUser, data, result, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 8,, 10]);

          return [4
          /*yield*/
          , effects_1.put(progressAction(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.userManager.currentUser];

        case 2:
          currentUser = _a.sent();

          if (!currentUser) {
            throw new Error("No user signed in.");
          }

          data = {
            query: "OwnerXuid eq " + currentUser.xuid,
            continuationToken: action.payload.continuationToken,
            maxPageSize: action.payload.maxPageSize,
            sort: action.payload.sort
          };

          if (action.payload.filterKey) {
            data.query = data.query + " AND TitleId eq " + action.payload.filterKey;
          }

          this.telemetryService.trackEvent("FetchMediaItems", {
            "type": mediaType
          });
          result = void 0;
          return [4
          /*yield*/
          , this.mediaHubService.getMediaItems(mediaType, data)];

        case 3:
          result = _a.sent();
          result.data.values = result.data.values.filter(function (item) {
            return item.contentId !== undefined;
          });
          if (!action.payload.filterKey) return [3
          /*break*/
          , 5];
          return [4
          /*yield*/
          , result.data.filterKey = action.payload.filterKey];

        case 4:
          _a.sent();

          _a.label = 5;

        case 5:
          return [4
          /*yield*/
          , effects_1.put(completedAction(result.data))];

        case 6:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(progressAction(false))];

        case 7:
          _a.sent();

          return [3
          /*break*/
          , 10];

        case 8:
          err_1 = _a.sent();
          console.warn("Unable to fetch " + mediaType + " from xbox library");
          Utils_1.LogHttpsError("FetchMediaItems", err_1);
          return [4
          /*yield*/
          , effects_1.put(progressAction(false))];

        case 9:
          _a.sent();

          this.telemetryService.trackException(err_1, "FetchMediaItems", {
            "type": mediaType
          });
          return [3
          /*break*/
          , 10];

        case 10:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._getMediaItemsMetadata = function (mediaType, progressAction, completedAction, action) {
    var result, err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(progressAction(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("GetMediaItemsMetadata", {
            "type": mediaType
          });
          return [4
          /*yield*/
          , this.mediaHubService.getMediaItemMetadata(mediaType, action.payload)];

        case 2:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(completedAction(result.data))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(progressAction(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_2 = _a.sent();
          Utils_1.LogHttpsError("GetMediaItemsMetadata", err_2);
          return [4
          /*yield*/
          , effects_1.put(progressAction(false))];

        case 6:
          _a.sent();

          this.telemetryService.trackException(err_2, "GetMediaItemsMetadata", {
            "type": mediaType
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._uploadMediaItem = function (mediaType, action) {
    var cancelToken, currentUser, maxBlobSize, createRequest, result, progressPercent, respond, hydratedItem, uploadResult, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 15,, 17]);

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Init))];

        case 1:
          _a.sent();

          cancelToken = action.payload.cancellationToken;
          return [4
          /*yield*/
          , this.userManager.currentUser];

        case 2:
          currentUser = _a.sent();

          if (!currentUser) {
            throw new Error("No user signed in.");
          }

          maxBlobSize = this.getMaxBlobSize(mediaType);
          createRequest = this.getMediaRequestData(mediaType, action.payload, maxBlobSize, currentUser.xuid);
          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "initialize",
            "type": mediaType
          });
          cancelToken.throwIfCancelled();
          return [4
          /*yield*/
          , this.mediaHubService.uploadMediaItem(mediaType, createRequest)];

        case 3:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Created))];

        case 4:
          _a.sent(); // 10-80% progress - cancellation covered on each iteration of uploadContent.


          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "start_content",
            "type": mediaType
          });
          progressPercent = Math.floor((UploadProgress.ContentDone - UploadProgress.Created) / createRequest.expectedBlocks);
          return [4
          /*yield*/
          , this.uploadContent(result.data.contentUploadUri, action.payload.file, cancelToken, maxBlobSize, progressPercent)];

        case 5:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.ContentDone))];

        case 6:
          _a.sent(); // thumbnail always send as one chunk (80-85%)


          cancelToken.throwIfCancelled();
          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "start_thumbanail",
            "type": mediaType
          });
          return [4
          /*yield*/
          , this.mediaHubService.sendDataSlice(result.data.thumbnailUploadUri, 1, action.payload.thumbnailBase64)];

        case 7:
          respond = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.ThumbnailDone))];

        case 8:
          _a.sent(); // non-cancelable after this point;


          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "start_publish",
            "type": mediaType
          });
          return [4
          /*yield*/
          , this.mediaHubService.publishMediaItem(result.data.publishUri)];

        case 9:
          respond = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.PublishDone))];

        case 10:
          _a.sent();

          hydratedItem = undefined;
          if (!(action.payload.shouldHydrate === true)) return [3
          /*break*/
          , 12];
          return [4
          /*yield*/
          , this.hydrateSingleMediaItem(mediaType, result.data.contentId)];

        case 11:
          hydratedItem = _a.sent();
          _a.label = 12;

        case 12:
          uploadResult = {
            contentId: result.data.contentId,
            locatorId: this.getMediaItemLocator(mediaType, currentUser.xuid, result.data.contentId),
            mediaType: mediaType,
            mediaItem: hydratedItem
          };
          this.notificationService.modalSuccess(UploadMediaItemType, "0");
          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "finished",
            "type": mediaType
          });
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaCompleted(uploadResult))];

        case 13:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Done))];

        case 14:
          _a.sent();

          return [3
          /*break*/
          , 17];

        case 15:
          err_3 = _a.sent();
          Utils_1.LogHttpsError("Unable to upload screenshot to xbox library", err_3);
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Error))];

        case 16:
          _a.sent();

          this.telemetryService.trackException(err_3, UploadMediaItemType);
          this.notifyUserAboutUploadError(err_3, mediaType);
          return [3
          /*break*/
          , 17];

        case 17:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._uploadCustomPic = function (mediaType, action) {
    var cancelToken, maxBlobSize, createRequest, result, progressPercent, respond, uploadResult, updatePropertyPayload, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 10,, 12]);

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Init))];

        case 1:
          _a.sent();

          cancelToken = action.payload.cancellationToken;
          maxBlobSize = this.getMaxBlobSize(mediaType);
          createRequest = this.getCustomMediaRequestData(action.payload, maxBlobSize);
          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "initialize",
            "type": mediaType
          });
          cancelToken.throwIfCancelled();
          result = void 0;
          return [4
          /*yield*/
          , this.mediaHubService.uploadMediaItem(mediaType, createRequest)];

        case 2:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Created))];

        case 3:
          _a.sent(); // 10-85% progress - cancellation covered on each iteration of uploadContent.


          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "start_content",
            "type": mediaType
          });
          progressPercent = Math.floor((UploadProgress.ThumbnailDone - UploadProgress.Created) / createRequest.expectedBlocks);
          return [4
          /*yield*/
          , this.uploadContent(result.data.contentUploadUri, action.payload.file, cancelToken, maxBlobSize, progressPercent)];

        case 4:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.ThumbnailDone))];

        case 5:
          _a.sent(); // publish media item
          // non-cancelable after this point;


          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "start_publish",
            "type": mediaType
          });
          return [4
          /*yield*/
          , this.mediaHubService.publishMediaItem(result.data.publishUri)];

        case 6:
          respond = _a.sent();

          if (respond.status !== http_1.Http.HttpStatus.Accepted) {
            throw new Error("Publish is failed");
          }

          uploadResult = {
            contentId: result.data.contentId,
            locatorId: "",
            mediaType: "customPics"
          };
          this.notificationService.notifySuccess(UploadMediaItemType);
          this.telemetryService.trackEvent(UploadMediaItemType, {
            "status": "finished",
            "type": mediaType
          });
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaCompleted(uploadResult))];

        case 7:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Done))];

        case 8:
          _a.sent();

          updatePropertyPayload = {
            clubId: action.payload.associationId ? action.payload.associationId : "",
            updatedProperty: action.payload.customPicType === social_core_1.UpdateImageTypes.profile ? ["details", "profile", "displayImageUrl", "value"] : ["details", "profile", "backgroundImageUrl", "value"],
            updatedValue: action.payload.thumbnailBase64
          };
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubUpdateProperty(updatePropertyPayload))];

        case 9:
          _a.sent();

          return [3
          /*break*/
          , 12];

        case 10:
          err_4 = _a.sent();
          Utils_1.LogHttpsError("Unable to upload custom to xbox library", err_4);
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(UploadProgress.Error))];

        case 11:
          _a.sent();

          this.telemetryService.trackException(err_4, UploadMediaItemType, null, true);
          this.notifyUserAboutUploadError(err_4, mediaType);
          return [3
          /*break*/
          , 12];

        case 12:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._deleteMediaItem = function (mediaType, action) {
    var response, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          this.telemetryService.trackEvent("DeleteMediaItem", {
            "type": mediaType
          });
          return [4
          /*yield*/
          , this.mediaHubService.deleteMediaItem(mediaType, action.payload)];

        case 1:
          response = _a.sent();

          if (response.status === http_1.Http.HttpStatus.Accepted) {
            this.notificationService.notifySuccess("DeleteMediaItem");
          }

          return [3
          /*break*/
          , 3];

        case 2:
          err_5 = _a.sent();
          console.warn("Unable to delete 1 of " + mediaType + " from xbox library, due to " + err_5);
          Utils_1.LogHttpsError("DeleteMediaItem", err_5);
          this.telemetryService.trackException(err_5, "DeleteMediaItem", {
            "type": mediaType
          }, true);
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._getUsersMediaTitles = function (action) {
    var currentUser, result, clips, screens, _a, clipTitles, screenTitles, mediaTitles, err_6;

    var _this = this;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          _b.trys.push([0, 9,, 11]);

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesInProgress(true))];

        case 1:
          _b.sent();

          return [4
          /*yield*/
          , this.userManager.currentUser];

        case 2:
          currentUser = _b.sent();

          if (!currentUser) {
            throw new Error("No user signed in.");
          }

          this.telemetryService.trackEvent("getUsersMediaTitles");
          return [4
          /*yield*/
          , this.mediaHubService.getUsersMediaTitles(currentUser.xuid)];

        case 3:
          result = _b.sent();
          return [4
          /*yield*/
          , effects_1.spawn(function (ids) {
            return _this.titleHubService.hydrateTitles(ids);
          }, Object.keys(result.data.gameClipTitleCounts))];

        case 4:
          clips = _b.sent();
          return [4
          /*yield*/
          , effects_1.spawn(function (ids) {
            return _this.titleHubService.hydrateTitles(ids);
          }, Object.keys(result.data.screenshotTitleCounts))];

        case 5:
          screens = _b.sent();
          return [4
          /*yield*/
          , effects_1.join(clips, screens)];

        case 6:
          _a = _b.sent(), clipTitles = _a[0], screenTitles = _a[1];
          mediaTitles = {
            gameclipTitles: clipTitles.data.titles,
            screenshotTitles: screenTitles.data.titles
          };
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesCompleted(mediaTitles))];

        case 7:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesInProgress(false))];

        case 8:
          _b.sent();

          return [3
          /*break*/
          , 11];

        case 9:
          err_6 = _b.sent();
          Utils_1.LogHttpsError("getUsersMediaTitles", err_6);
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesInProgress(false))];

        case 10:
          _b.sent();

          this.telemetryService.trackException(err_6, "getUsersMediaTitles");
          return [3
          /*break*/
          , 11];

        case 11:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype._getUsersQuota = function (action) {
    var currentUser, result, err_7;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 6,, 8]);

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersQuotaInProgress(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.userManager.currentUser];

        case 2:
          currentUser = _a.sent();

          if (!currentUser) {
            throw new Error("No user signed in.");
          }

          this.telemetryService.trackEvent("getUsersMediaQuota");
          result = void 0;
          return [4
          /*yield*/
          , this.mediaHubService.getUsersMediaQuota(currentUser.xuid)];

        case 3:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersQuotaCompleted(result.data.percentageUsed))];

        case 4:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersQuotaInProgress(false))];

        case 5:
          _a.sent();

          return [3
          /*break*/
          , 8];

        case 6:
          err_7 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.getUsersQuotaInProgress(false))];

        case 7:
          _a.sent();

          Utils_1.LogHttpsError("Unable to get user's media quota", err_7);
          this.telemetryService.trackException(err_7, "getUsersMediaQuota");
          return [3
          /*break*/
          , 8];

        case 8:
          return [2
          /*return*/
          ];
      }
    });
  };
  /*********************************************************************/

  /* Watchers
  /*********************************************************************/


  MediaHubSaga.prototype.watchFetchScreenshots = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.fetchScreenshots.type, this._fetchMediaItems.bind(this, social_core_1.MediaServiceType.Screenshots, MediaHubActions_1.MediaHubActions.fetchScreenshotsInProgress, MediaHubActions_1.MediaHubActions.fetchScreenshotsCompleted))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchFetchGameclips = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.fetchGameclips.type, this._fetchMediaItems.bind(this, social_core_1.MediaServiceType.Gameclips, MediaHubActions_1.MediaHubActions.fetchGameclipsInProgress, MediaHubActions_1.MediaHubActions.fetchGameclipsCompleted))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchUploadScreenshot = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.uploadScreenshot.type, this._uploadMediaItem.bind(this, social_core_1.MediaServiceType.Screenshots
          /* +auto payload */
          ))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchUploadGameclip = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.uploadGameclip.type, this._uploadMediaItem.bind(this, social_core_1.MediaServiceType.Gameclips
          /* +auto payload */
          ))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchUploadCustomPic = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.uploadCustomPic.type, this._uploadCustomPic.bind(this, social_core_1.MediaServiceType.CustomPics
          /* +auto payload */
          ))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchDeleteScreenshot = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.deleteScreenshot.type, this._deleteMediaItem.bind(this, social_core_1.MediaServiceType.Screenshots
          /* +auto payload */
          ))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchDeleteGameClip = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.deleteGameClip.type, this._deleteMediaItem.bind(this, social_core_1.MediaServiceType.Gameclips
          /* +auto payload */
          ))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchMetadataScreenshots = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.fetchScreenshotsMetadata.type, this._getMediaItemsMetadata.bind(this, social_core_1.MediaServiceType.Screenshots, MediaHubActions_1.MediaHubActions.fetchScreenshotsMetadataInProgress, MediaHubActions_1.MediaHubActions.fetchScreenshotsMetadataCompleted))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchMetadataGameclips = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.fetchGameclipsMetadata.type, this._getMediaItemsMetadata.bind(this, social_core_1.MediaServiceType.Gameclips, MediaHubActions_1.MediaHubActions.fetchGameclipsMetadataInProgress, MediaHubActions_1.MediaHubActions.fetchGameclipsMetadataCompleted))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchUsersMediaTitles = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.getUsersMediaTitles.type, this._getUsersMediaTitles.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.watchUsersQuota = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(MediaHubActions_1.MediaHubActions.getUsersQuota.type, this._getUsersQuota.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };
  /*********************************************************************/

  /* Helper functions
  /*********************************************************************/


  MediaHubSaga.prototype.uploadContent = function (uploadUri, file, cancelToken, maxBlobSize, progressPercent, startPercentage, blockId, startSlice) {
    var endSlice, blob, blobContent, result, err_8;

    if (startPercentage === void 0) {
      startPercentage = UploadProgress.Created;
    }

    if (blockId === void 0) {
      blockId = 1;
    }

    if (startSlice === void 0) {
      startSlice = 0;
    }

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          cancelToken.throwIfCancelled();
          endSlice = this.getEndSlice(startSlice, file.size, maxBlobSize);
          blob = file.slice(startSlice, endSlice);
          _a.label = 1;

        case 1:
          _a.trys.push([1, 8,, 9]);

          return [4
          /*yield*/
          , this.readFileBlob(blob)];

        case 2:
          blobContent = _a.sent();
          return [4
          /*yield*/
          , this.mediaHubService.sendDataSlice(uploadUri, blockId, blobContent)];

        case 3:
          result = _a.sent();
          if (!(result.status === http_1.Http.HttpStatus.Created)) return [3
          /*break*/
          , 6];

          if (endSlice >= file.size) {
            return [2
            /*return*/
            ];
          }

          startPercentage += progressPercent;
          return [4
          /*yield*/
          , effects_1.put(MediaHubActions_1.MediaHubActions.uploadMediaInProgress(startPercentage))];

        case 4:
          _a.sent();

          return [4
          /*yield*/
          , this.uploadContent(uploadUri, file, cancelToken, maxBlobSize, progressPercent, startPercentage, blockId + 1, endSlice)];

        case 5:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 6:
          throw new Error(status);

        case 7:
          return [3
          /*break*/
          , 9];

        case 8:
          err_8 = _a.sent();
          console.warn("Unable to read file slice: " + err_8 + ", blockId: " + blockId);
          throw err_8;

        case 9:
          return [2
          /*return*/
          ];
      }
    });
  };

  MediaHubSaga.prototype.hydrateSingleMediaItem = function (mediaType, contentId) {
    var hydration, err_9;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          hydration = void 0;
          return [4
          /*yield*/
          , this.mediaHubService.getMediaItemMetadata(mediaType, [contentId])];

        case 1:
          hydration = _a.sent();
          return [2
          /*return*/
          , hydration.data.values.length > 0 ? hydration.data.values[0] : undefined];

        case 2:
          err_9 = _a.sent();
          console.warn("Unable to hydrate media item: " + err_9 + ", contentId: " + contentId);
          Utils_1.LogHttpsError("hydrateMediaItems", err_9);
          this.telemetryService.trackException(err_9, "hydrateMediaItems");
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          , undefined];
      }
    });
  };

  MediaHubSaga.prototype.getMaxBlobSize = function (mediaType) {
    return mediaType === social_core_1.MediaServiceType.Gameclips ? MediaHubSaga_1.MaxBlobSizeGameclip : MediaHubSaga_1.MaxBlobSizeScreenshot;
  };

  MediaHubSaga.prototype.getMediaRequestData = function (mediaType, payload, maxBlobSize, curXuid) {
    var fileBlocks = Math.ceil(payload.file.size / maxBlobSize);
    var initMetadata = mediaType === social_core_1.MediaServiceType.Gameclips ? this.getGameclipsInitialMetadata(payload, curXuid) : this.getScreenshotInitialMetadata(payload);
    var request = {
      expectedBlocks: fileBlocks,
      fileSize: payload.file.size,
      thumbnailNumBlocks: 1,
      thumbnailFileSize: payload.thumbnailOriginSize,
      initialMetadata: initMetadata
    };
    return request;
  };

  MediaHubSaga.prototype.getCustomMediaRequestData = function (payload, maxBlobSize) {
    var fileBlocks = Math.ceil(payload.file.size / maxBlobSize);
    var initMetadata = {
      associationId: payload.associationId,
      customPicType: payload.customPicType
    };
    var request = {
      expectedBlocks: fileBlocks,
      fileSize: payload.file.size,
      initialMetadata: initMetadata,
      makePermanent: true
    };
    return request;
  };

  MediaHubSaga.prototype.getScreenshotInitialMetadata = function (payload) {
    var initMetadata = {
      captureDate: new Date(payload.file.lastModified),
      creationType: "UserGenerated",
      editingApp: null,
      localId: "",
      requiresActiveModeration: false,
      resolutionHeight: payload.originalHeight,
      resolutionWidth: payload.originalWidth,
      titleData: "(null)",
      titleId: payload.gameId,
      userCaption: payload.mediaTitle
    };
    return initMetadata;
  };

  MediaHubSaga.prototype.getGameclipsInitialMetadata = function (payload, curXuid) {
    var contentSegment = {
      creationType: "UserGenerated",
      creatorXuid: Number(curXuid),
      durationInSeconds: payload.duration,
      recordDate: payload.file.lastModifiedDate,
      offset: 0,
      segmentId: 1,
      titleId: payload.gameId
    };
    var initMetadata = {
      contentAttributes: 0,
      contentSegments: [contentSegment],
      durationInSeconds: payload.duration,
      editingApp: null,
      embeddedText: "(null)",
      frameRate: 0,
      greatestMomentId: "(null)",
      localId: "",
      requiresActiveModeration: false,
      resolutionHeight: payload.originalHeight,
      resolutionWidth: payload.originalWidth,
      titleData: "(null)",
      userCaption: payload.mediaTitle
    };
    return initMetadata;
  };

  MediaHubSaga.prototype.getEndSlice = function (startSlice, objectSize, maxBlobSize) {
    var endSlice = startSlice + maxBlobSize + 1;
    return endSlice > objectSize ? objectSize : endSlice;
  };

  MediaHubSaga.prototype.readFileBlob = function (inputBlob) {
    var reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onerror = function () {
        reader.abort();
        reject(reader.error);
      };

      reader.onload = function () {
        resolve(reader.result);
      };

      reader.readAsDataURL(inputBlob);
    });
  };

  var MediaHubSaga_1;
  MediaHubSaga.MaxBlobSizeScreenshot = 256 * 1024; // 256 KiB

  MediaHubSaga.MaxBlobSizeGameclip = 2 * 1024 * 1024; // 2 MiB

  MediaHubSaga = MediaHubSaga_1 = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IMediaHubService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ITitleHubService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(4, inversify_1.inject(social_core_1.XSocialSymbols.IUserManager)), __metadata("design:paramtypes", [social_core_1.MediaHubService, social_core_1.TitleHubService, Object, Object, Object])], MediaHubSaga);
  return MediaHubSaga;
}();

exports.MediaHubSaga = MediaHubSaga;