"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LongView = void 0;

var bigInt = require("big-integer");

var LongView =
/** @class */
function () {
  function LongView(arrayBuffer, byteOffset) {
    this.arrayBuffer = arrayBuffer;
    this.byteOffset = byteOffset;
  }

  LongView.prototype.setLong = function (byteOffset, longString) {
    var tempString = longString.slice(0);
    var dataView = new DataView(this.arrayBuffer, this.byteOffset + byteOffset);

    for (var i = 0; i < 8; i++) {
      var bigNumber = bigInt("" + tempString).remainder(256);
      dataView.setUint8(7 - i, parseInt(bigNumber.toString(), 10));
      tempString = bigInt("" + tempString).divide(256).toString();
    }
  };

  LongView.prototype.getLong = function (byteOffset) {
    var longUint8Array = new Uint8Array(this.arrayBuffer, this.byteOffset + byteOffset, 8);
    var longString = "0";

    for (var i = 0; i < 8; i++) {
      longString = bigInt("" + longString).multiply(256).add("" + longUint8Array[i]).toString();
    }

    return longString.toString();
  };

  return LongView;
}();

exports.LongView = LongView;