"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTagPicker = void 0;

function getTagPicker(state) {
  return {
    systemTags: state.tagsCache.systemTags,
    club: state.clubs.clubDetails.club,
    currentUser: state.authentication.currentUser,
    combinedTagsList: state.tagsCache.combinedTagsList
  };
}

exports.getTagPicker = getTagPicker;