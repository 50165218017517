import * as React from "react";
import { ClubHub, PostStatus } from "@xbox/social-core";
import { FeedStatus } from "@xbox/social-views";

import { IScheduledFeedResolver, CoreScheduledFeedContainer, IFeedItemAdminActionHanders } from "@xbox/social-views";
import { withClubSelectionContext } from "../../containers/ClubSelectionContext";
import { ScheduledPostView } from "../../components/feedtools/ScheduledPostView";
import { telemetryService } from "../../index";

function getScheduledPostsPanel(
    feedStatus: FeedStatus,
    adminActions: IFeedItemAdminActionHanders,
    selectChannel: (channel: ClubHub.Channel) => void,
    refreshFeed: () => void,
    postStatus?: PostStatus.PostStatusResult,
    selectedChannel?: ClubHub.Channel,
    channelList?: ClubHub.Channel[]
): JSX.Element | null {
    telemetryService.trackPageView("ScheduledActivityFeed");
    return (
        <ScheduledPostView
            scheduledPosts={feedStatus.results}
            isValid={feedStatus.isValid}
            isErrored={feedStatus.isErrored}
            adminActions={adminActions}
            postStatus={postStatus}
            selectChannel={selectChannel}
            refreshFeed={refreshFeed}
            selectedChannel={selectedChannel}
            channelList={channelList}
        />
    );
}

const ScheduledFeedResolver: IScheduledFeedResolver = {
    getScheduledPostsPanel: getScheduledPostsPanel
};

export default withClubSelectionContext(CoreScheduledFeedContainer(ScheduledFeedResolver));
