"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddUser = void 0;

var XalUser_1 = require("../../../XalUser");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var Utils_1 = require("../../Utils");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["GetDefaultUser"] = "GetDefaultUser";
  Step["AddWebAccount"] = "AddWebAccount";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var AddUser =
/** @class */
function (_super) {
  __extends(AddUser, _super);

  function AddUser(telemetryClient, userSet, platform) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.userSet = userSet;
    _this.platform = platform;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.AddUser, Step.Start);
    return _this;
  }

  AddUser.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            if (!this.userSet.empty()) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , this.getDefaultUser()];

          case 1:
            _a.sent();

            return [3
            /*break*/
            , 5];

          case 2:
            if (!this.userSet.canAddUser()) return [3
            /*break*/
            , 4];
            return [4
            /*yield*/
            , this.addWebAccount(undefined)];

          case 3:
            _a.sent(); // No preexisting MSA


            return [3
            /*break*/
            , 5];

          case 4:
            this.step.advance(Step.Done);
            this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UserSetFull, "User set is full"));
            _a.label = 5;

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  AddUser.prototype.getDefaultUser = function () {
    return __awaiter(this, void 0, void 0, function () {
      var data, error_1, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.GetDefaultUser);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 6]);

            return [4
            /*yield*/
            , this.platform.tokenStack.getDefaultUser()];

          case 2:
            data = _a.sent();
            return [3
            /*break*/
            , 6];

          case 3:
            error_1 = _a.sent();
            xalError = error_1;
            if (!(xalError && xalError.errorType && (xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.NoUserFound || xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.TooManyCachedUsers))) return [3
            /*break*/
            , 5];
            return [4
            /*yield*/
            , this.addWebAccount(undefined)];

          case 4:
            _a.sent();

            return [2
            /*return*/
            ];

          case 5:
            this.step.advance(Step.Done);
            this.fail(error_1);
            return [2
            /*return*/
            ];

          case 6:
            Utils_1.assert(this.step.currentStep === Step.GetDefaultUser);
            return [4
            /*yield*/
            , this.addWebAccount(data.webAccountId)];

          case 7:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  AddUser.prototype.addWebAccount = function (preexistingMsaUserId) {
    return __awaiter(this, void 0, void 0, function () {
      var accountData, user;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.AddWebAccount);
            return [4
            /*yield*/
            , this.platform.tokenStack.signIn(preexistingMsaUserId, this.platform.webView, this.userSet)];

          case 1:
            accountData = _a.sent();
            user = new XalUser_1.XalUser(this.userSet, accountData);
            this.userSet.addUser(user);
            this.step.advance(Step.Done);
            this.succeed(user);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return AddUser;
}(OperationBase_1.OperationBase);

exports.AddUser = AddUser;