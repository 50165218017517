"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.User = void 0;

var User =
/** @class */
function () {
  function User(xalUser, profile, clubs) {
    this.xalUser = xalUser;
    this.profile = profile;
    this.clubs = clubs;
  }

  Object.defineProperty(User.prototype, "xuid", {
    get: function get() {
      return this.xalUser.xuid;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(User.prototype, "webAccountId", {
    get: function get() {
      return this.xalUser.webAccountId;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(User.prototype, "gamertag", {
    get: function get() {
      return this.xalUser.gamertag;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(User.prototype, "ageGroup", {
    get: function get() {
      return this.xalUser.ageGroup;
    },
    enumerable: false,
    configurable: true
  });

  User.prototype.checkPrivilege = function (privilege) {
    return this.xalUser.checkPrivilege(privilege);
  };

  return User;
}();

exports.User = User;