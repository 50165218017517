"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peopleDenormalizer = exports.peopleNormalizer = void 0;

var normalizr_1 = require("normalizr"); // schema definitions


var people = new normalizr_1.schema.Entity("people", undefined, {
  idAttribute: "xuid"
});
var peopleList = [people];

var peopleNormalizer = function peopleNormalizer(result) {
  return normalizr_1.normalize(result, peopleList);
};

exports.peopleNormalizer = peopleNormalizer;

var peopleDenormalizer = function peopleDenormalizer(xuids, entities) {
  return normalizr_1.denormalize(xuids, peopleList, entities);
};

exports.peopleDenormalizer = peopleDenormalizer;