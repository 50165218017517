export var FeedItemUtils;

(function (FeedItemUtils) {
  // The core assuption of this is based on a feedItemId that looks like this:
  // userposts.xboxlive.com/users/xuid(${xuid})/posts/${shortFeedItemId}/timelines/Club/${clubId}/channels/${channelId}
  function parseFeedItemId(feedItemIdString) {
    var array = feedItemIdString.split("/");
    var channelId = array.indexOf("channels") > -1 ? array[array.indexOf("channels") + 1] : "";
    return channelId !== "" ? btoa(array[array.indexOf("posts") + 1]) + "/" + channelId : btoa(array[array.indexOf("posts") + 1]);
  }

  FeedItemUtils.parseFeedItemId = parseFeedItemId;

  function constuctFeedItemId(shortFeedItemId, clubId, channelId) {
    var decodedId = atob(shortFeedItemId);
    var posterXuid = decodedId.substr(0, decodedId.indexOf("(0)"));
    return channelId ? "userposts.xboxlive.com/users/xuid(" + posterXuid + ")/posts/" + decodedId + "/timelines/Club/" + clubId + "/channels/" + channelId : "userposts.xboxlive.com/users/xuid(" + posterXuid + ")/posts/" + decodedId + "/timelines/Club/" + clubId;
  }

  FeedItemUtils.constuctFeedItemId = constuctFeedItemId; // Aggregate functions

  function parseClubId(feedItemIdString) {
    var array = feedItemIdString.split("/");
    return "Club_" + array[array.indexOf("Club") + 1];
  }

  FeedItemUtils.parseClubId = parseClubId;

  function parseUserId(feedItemIdString) {
    var array = feedItemIdString.split("/");
    return "User_" + array[array.indexOf("User") + 1];
  }

  FeedItemUtils.parseUserId = parseUserId;

  function constuctAggregateFeedItemId(shortFeedItemId, feedItemSuffix) {
    var decodedId = atob(shortFeedItemId);
    var posterXuid = decodedId.substr(0, decodedId.indexOf("(0)"));
    var formattedSuffix = feedItemSuffix.replace("_", "/");
    return "userposts.xboxlive.com/users/xuid(" + posterXuid + ")/posts/" + decodedId + "/timelines/" + formattedSuffix;
  }

  FeedItemUtils.constuctAggregateFeedItemId = constuctAggregateFeedItemId;
})(FeedItemUtils || (FeedItemUtils = {}));