import * as React from "react";
import { Person } from "@xbox/social-core";
import { Table } from "antd";
import { TableRow } from "./Analytics.Styles";
import { Localize } from "../../common/translation/Localize";
import { GamerpicListItem } from "../../common/generic/GamerpicListItem";

export interface ColumnDefinition {
    locId: string;
    dataKey: string;
}

export interface TableWidgetProps {
    columnsDef: ColumnDefinition[];
    tableData: Person[];
}

export default function TableWidget(props: TableWidgetProps) {
    const columns = props.columnsDef.map(i => {
        return {
            title: Localize(i.locId),
            dataIndex: i.dataKey,
            render: (text: any, record: Person, index: number) => {
                return (
                    <GamerpicListItem
                        key={record.xuid}
                        person={record}
                    />
                );
            }
        };
    });

    return (
        <TableRow>
            <Table showHeader={false} columns={columns} dataSource={props.tableData} size="small" pagination={false} />
        </TableRow>
    );
}
