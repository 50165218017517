"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gameclipsReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var MediaHubActions_1 = require("../../actions/MediaHubActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.gameclipsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(MediaHubActions_1.MediaHubActions.fetchGameclipsInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(MediaHubActions_1.MediaHubActions.fetchGameclipsCompleted, function (state, action) {
  if (state.mediaData) {
    var newResults = void 0;

    if (state.mediaData.filterKey !== action.payload.filterKey || !state.mediaData.continuationToken && action.payload.continuationToken) {
      newResults = action.payload.values;
    } else if (state.mediaData.continuationToken === action.payload.continuationToken) {
      newResults = state.mediaData.values.slice();
    } else {
      newResults = state.mediaData.values.concat(action.payload.values);
    }

    var newState = state.merge({
      mediaData: action.payload
    }, {
      deep: true
    }); // Because the continuation token is not overwritten if undefined when it hits the last results,
    // we need to make sure the undefined is sent to the props so the component knows the last
    // results have been returned

    if (!action.payload.continuationToken) {
      newState = newState.setIn(["mediaData", "continuationToken"], undefined);
    }

    if (!action.payload.filterKey) {
      newState = newState.setIn(["mediaData", "filterKey"], undefined);
    }

    return newState.setIn(["mediaData", "values"], newResults);
  } else {
    return state.merge({
      mediaData: action.payload
    });
  }
}).build();