import * as React from "react";
import { connect } from "react-redux";
import { ReduxState } from "@xbox/social-redux";

import { Localize } from "../../common/translation/Localize";
import FriendsContainer from "../FriendsContainer";

interface PeopleContainerProps {
}

interface PeopleContainerDispatchProps {
}

type PeopleProps = PeopleContainerProps & PeopleContainerDispatchProps;

interface PeopleContainerState {
    listDataItems: JSX.Element[];
}

export class PeopleContainer extends React.Component<PeopleProps, PeopleContainerState> {
    private gapSize: string = "10px";

    constructor(props: any) {
        super(props);
        this.state = {
            listDataItems: []
        };
    }

    render() {
        return (
            <div style={{ marginLeft: this.gapSize, marginRight: this.gapSize, height: "100%" }}>
                <h1>{Localize("clubMenu.people")}</h1>
                <FriendsContainer />
            </div>
        );
    }
}

const mapStateToProps = (state: ReduxState): PeopleContainerProps => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any): PeopleContainerDispatchProps => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PeopleContainer);
