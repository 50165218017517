"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateCV = void 0;

var base64 = require("base64-js");

function generateCV() {
  var vector = new Uint8Array(12);

  if (typeof window !== "undefined" && window.crypto && window.crypto.getRandomValues) {
    crypto.getRandomValues(vector);
  } else {
    var dv = new DataView(vector.buffer);

    for (var i = 0; i < 3; i++) {
      var rand = Math.random();
      rand = Math.floor(rand * Math.floor(Number.MAX_SAFE_INTEGER));
      dv.setUint32(i * 4, rand);
    }
  }

  return base64.fromByteArray(vector);
}

exports.generateCV = generateCV;