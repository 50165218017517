import * as React from "react";
import { omit } from "lodash";
import { ThemeType } from "@xbox/social-views";
import { LayoutContext, LayoutContextProvider } from "./LayoutContext";

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    sideMenuIsOpen: boolean;
    onSideMenuToggled?: (state: boolean) => void;
    toggleSideMenu?: () => void;
    onThemeChange?: (theme: ThemeType) => void;
}

export interface LayoutState extends LayoutContext {
}

export default class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);

        this.state = {
            isOpen: false,
            onToggleSider: this.onToggleSidePanel,
            onThemeChange: this.onChangeSiteTheme,
        };
    }

    onToggleSidePanel = (state: boolean) => {
        this.setState({ isOpen: state });
        if (this.props.onSideMenuToggled) {
            this.props.onSideMenuToggled(this.props.sideMenuIsOpen);
        }
    }

    onChangeSiteTheme = (theme: ThemeType) => {
        if (this.props.onThemeChange) {
            this.props.onThemeChange(theme);
        }
    }

    componentDidMount() {
        if (this.props.sideMenuIsOpen !== undefined && this.props.sideMenuIsOpen) {
            this.setState({isOpen: this.props.sideMenuIsOpen});
        }

        if (this.props.toggleSideMenu !== undefined) {
            this.setState({toggleSlider: this.props.toggleSideMenu });
        }
    }

    componentDidUpdate(prevProps: LayoutProps) {
        if (this.props.sideMenuIsOpen !== undefined && prevProps.sideMenuIsOpen !== this.props.sideMenuIsOpen) {
            this.setState({isOpen: this.props.sideMenuIsOpen});
            if (this.props.onSideMenuToggled) {
                this.props.onSideMenuToggled(this.props.sideMenuIsOpen);
            }
        }
    }

    render() {
        const finalProps = omit(this.props, "sideMenuIsOpen", "toggleSideMenu", "onThemeChange");
        return (
            <div {...finalProps}>
                <LayoutContextProvider.Provider value={this.state}>
                    {this.props.children}
                </LayoutContextProvider.Provider>
            </div>
        );
    }
}
