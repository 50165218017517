import * as React from "react";
import { CoreFeedToolsContainer, IPostFeedItemResolver, PostHydrationDataStates } from "@xbox/social-views";
import { FetchMediaItemsPayload, UploadMediaItemsPayload, BallotPayload } from "@xbox/social-redux";
import { ClubHub, User, ActivityFeed } from "@xbox/social-core";

import { withClubSelectionContext } from "../ClubSelectionContext";
import { CreatePostView } from "../../components/feedtools";
import { ClubCanActPermissions } from "../../components/feedtools/FeedToolsTypes";

function getCanActPermission (clubSetting?: ClubHub.ClubSetting): boolean {
    return clubSetting ? clubSetting.canViewerAct === true : false;
}

function getPermissionsFromFeedSettings (feedSettings?: ClubHub.ClubSettingsList): ClubCanActPermissions | undefined {
    if (!feedSettings) {
        return undefined;
    }

    let result: ClubCanActPermissions = {
        post: getCanActPermission(feedSettings.post),
        postMediaFromDevice: getCanActPermission(feedSettings.postMediaFromDevice),
        postMediaFromXblLibrary: getCanActPermission(feedSettings.postMediaFromXblLibrary),
        postStoreLink: getCanActPermission(feedSettings.postStoreLink),
        postWebLink: getCanActPermission(feedSettings.postWebLink),

        pinPost: getCanActPermission(feedSettings.pinPost),
        schedulePost: getCanActPermission(feedSettings.schedulePost),
        delete: getCanActPermission(feedSettings.delete)
    };

    return result;
}

function getPostFeedItemContainer (
    club: ClubHub.Club,
    currentUser: User,
    hydration: PostHydrationDataStates,
    handlePost: (item: ActivityFeed.UserPost, wasPinned: boolean, wasDisabled: boolean, wasScheduled: boolean, scheduledDateTime?: string) => void,
    handleHydration: (webLink: string) => void,
    handleStringValidation: (stringList: string[]) => void,
    searchGameInMsCatalog: (gameName: string) => void,
    fetchMediaItems: (payload: FetchMediaItemsPayload, isVideo: boolean) => void,
    uploadMediaItems: (data: UploadMediaItemsPayload, isVideo: boolean) => void,
    getAllTitles: () => void,
    createBallot: (ballotPayload: BallotPayload) => void,
    forceSchedule: boolean,
    selectedChannel?: ClubHub.Channel
): JSX.Element | null {

    if (!club || !club.details.id) {
        return <div/>;
    }

    let feedPermissions = club.feedChannels && selectedChannel ? getPermissionsFromFeedSettings(selectedChannel.settings) : undefined;
    const createPostRouteResponse = (
            <CreatePostView
                clubId={club.details.id}
                permissions={feedPermissions}
                handlePostClick={handlePost}
                handleLinkHydration={handleHydration}
                handleStringValidation={handleStringValidation}
                searchGameInMsCatalog={searchGameInMsCatalog}
                fetchMediaItems={fetchMediaItems}
                uploadMediaItems={uploadMediaItems}
                getAllTitles={getAllTitles}
                hydrationStates={hydration}
                club={club}
                currentUser={currentUser}
                createBallot={createBallot}
                selectedChannel={selectedChannel}
                forceSchedule={forceSchedule}
            />
        );

    return (
        <div className="FeedToolsContainer">
            {createPostRouteResponse}
        </div>
    );
}

const PostFeedItemResolver: IPostFeedItemResolver = {
    getPostFeedItemContainer: getPostFeedItemContainer
};

export default withClubSelectionContext(CoreFeedToolsContainer(PostFeedItemResolver));
