import styled, { css } from "styled-components";
import { Button } from "../../common/generic/Button";
import { FeedItemModal } from "../../containers/activityfeed/FeedModal.Styles";

export const GreenText = styled.span`
    color: ${props => props.theme.greenRest};
`;

export const BallotGraphBar = styled.div`
    background-color: ${props => props.theme.barBackground};
    border-radius: 25px;
    margin-right: 10px;
`;

export const BallotButtonWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const BallotButton = styled(Button)`
    background-color: ${props => props.theme.backgroundSecondary};
    color: ${props => props.theme.foregroundBrigth};
    border-radius: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 2em;
    width: 100%;
`;

export const FullBallotContent = styled.div`
    margin: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    max-width: 500px;
    > div {
        > div {
            > div:first-of-type {
                background-color: ${props => props.theme.barBackground};
            }
            > div:last-of-type {
                background-color: ${props => props.theme.transparent};
            }
        }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: black-on-white) {
    > div {
        > div:first-of-type {
            background-color: ${props => props.theme.barBackground};
            filter: invert(1);
        }
        > div:last-of-type {
            background-color: ${props => props.theme.transparent};
        }
    }
}
`;

export const GridBallotContent = styled.div`
    max-height: 100px;
    min-width: 200px;
    max-width: 95%;

    margin: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const GridContentDiv  = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    div {
        color: ${props => props.theme.whiteText}
    }
`;

export const GridHeader  = styled.div`
    flex: 0 0 auto;
    margin: 4px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const FullBallotFooter = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
`;

export const GridBallotFooter = styled.div`
    flex: 0 0 auto;
    margin: 4px 10px;
    display: flex;
    justify-content: space-between;
`;

export const FullResultDiv = styled<{selected: boolean}, "div">("div")`
    margin: 5px 0 15px 0;

    ${props => props.selected && css`
        opacity: 0.5;
    `}
`;

export const GridResultDiv = styled<{selected: boolean}, "div">("div")`
    margin: 5px 0;
    display: flex;
    justify-content: space-between;

    ${props => props.selected && css`
        border: 1px solid;
        padding: 0 5px;
    `}
`;

export const PercentageText = styled.div`
    margin-left: 20px;
`;

export const InlineVoteButton = styled(Button)`
    padding: 1px 15px;
    border: none;
    background-color: ${props => props.theme.greenDark};
`;

export const FullItemModal = FeedItemModal.extend`
    .FullFeedItemContainer{
        padding: 10px 10px 0 10px;
        border: none;
    }
`;
