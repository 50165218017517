"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeSingleTokenBlob = exports.parseSingleTokenBlob = exports.serializeMultipleTokenBlob = exports.parseMultipleTokenBlob = exports.XboxTokenCache = void 0;

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var InMemoryXboxTokenCache_1 = require("./InMemoryXboxTokenCache");

var LoadDeviceIdentity_1 = require("./operations/cache/LoadDeviceIdentity");

var LoadTokensForUser_1 = require("./operations/cache/LoadTokensForUser");

var OperationQueue_1 = require("./operations/OperationQueue");

var StorageCacheHelpers_1 = require("./StorageCacheHelpers");

var Utils_1 = require("./Utils");

var XboxToken_1 = require("./XboxToken");

var XboxTokenCache =
/** @class */
function () {
  function XboxTokenCache(authConfig, cryptoFactory, storage, telemetryClient) {
    this.authConfig = authConfig;
    this.cryptoFactory = cryptoFactory;
    this.storage = storage;
    this.telemetryClient = telemetryClient;
    this.memoryCache = new InMemoryXboxTokenCache_1.InMemoryXboxTokenCache(authConfig, cryptoFactory);
    this.queue = new OperationQueue_1.OperationQueue();
  }

  XboxTokenCache.prototype.loadDeviceIdentity = function () {
    var idKey = this.makeDeviceIdentityKey();
    var dtKey = this.makeDtokenKey();
    var ttKey = this.makeTtokenKey();
    var operation = new LoadDeviceIdentity_1.LoadDeviceIdentity(this.telemetryClient, this.memoryCache, this.storage, idKey, dtKey, ttKey);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  Object.defineProperty(XboxTokenCache.prototype, "deviceIdentity", {
    get: function get() {
      return this.memoryCache.deviceIdentity;
    },
    enumerable: false,
    configurable: true
  });

  XboxTokenCache.prototype.getToken = function (identityType, relyingParty, subRelyingParty, tokenType, hasSignInDisplayClaims, msaUserId) {
    if (msaUserId && hasSignInDisplayClaims !== undefined && hasSignInDisplayClaims !== null) {
      Utils_1.assert(identityType === XboxToken_1.IdentityType.Utoken || identityType === XboxToken_1.IdentityType.Xtoken);
      return this.memoryCache.getToken(identityType, relyingParty, subRelyingParty, tokenType, hasSignInDisplayClaims, msaUserId);
    } else {
      Utils_1.assert(identityType === XboxToken_1.IdentityType.Dtoken || identityType === XboxToken_1.IdentityType.Ttoken);
      return this.memoryCache.getToken(identityType, relyingParty, subRelyingParty, tokenType);
    }
  };

  XboxTokenCache.prototype.writeToken = function (token, data) {
    return __awaiter(this, void 0, void 0, function () {
      var key, blob, type, msaUserId, operation;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!!token);
            Utils_1.assert(!!data && data.xerr === 0 && !Utils_1.isNullOrWhiteSpace(data.token));

            if (data.xerr !== 0) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to write token with Xerr value.");
            }

            Utils_1.assert(!!this.memoryCache.deviceIdentity);
            token.tokenData = data;
            type = token.identityType;

            if (type === XboxToken_1.IdentityType.Dtoken) {
              key = this.makeDtokenKey();
              blob = serializeSingleTokenBlob(token, this.memoryCache.deviceIdentity.uniqueId);
            } else if (type === XboxToken_1.IdentityType.Ttoken) {
              key = this.makeTtokenKey();
              blob = serializeSingleTokenBlob(token, this.memoryCache.deviceIdentity.uniqueId);
            } else {
              msaUserId = token.msaUserId;
              Utils_1.assert(!!msaUserId); // user tokens

              key = this.makeUserTokensKey(msaUserId);
              blob = serializeMultipleTokenBlob(this.memoryCache.getTokensForUser(msaUserId), this.memoryCache.deviceIdentity.uniqueId);
            }

            operation = new StorageCacheHelpers_1.WriteCacheData(this.telemetryClient, this.storage, key, blob);
            this.queue.queueOperation(operation);
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  XboxTokenCache.prototype.resetDeviceIdentity = function () {
    return __awaiter(this, void 0, void 0, function () {
      var key, id, serializedId, operation;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            key = this.makeDeviceIdentityKey();
            return [4
            /*yield*/
            , this.memoryCache.resetDeviceIdentity()];

          case 1:
            id = _a.sent();
            return [4
            /*yield*/
            , id.serialize()];

          case 2:
            serializedId = _a.sent();
            operation = new StorageCacheHelpers_1.WriteCacheData(this.telemetryClient, this.storage, key, serializedId);
            this.queue.queueOperation(operation);
            return [4
            /*yield*/
            , operation.completionPromise];

          case 3:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  XboxTokenCache.prototype.loadTokensForUser = function (msaUserId) {
    var key = this.makeUserTokensKey(msaUserId);
    var operation = new LoadTokensForUser_1.LoadTokensForUser(this.telemetryClient, this.memoryCache, this.storage, msaUserId, key);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  XboxTokenCache.prototype.loadAnonymousToken = function (msaUserId) {
    return __awaiter(this, void 0, void 0, function () {
      var key, data, error_1, tokens, parsedTokens;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            key = this.makeUserTokensKey(msaUserId);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , this.storage.read(key)];

          case 2:
            data = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            error_1 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Failed to read anonymous token with error: " + error_1);
            throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NoUserFound, "Failed to read anonymous token");

          case 4:
            if (Utils_1.isNullOrWhiteSpace(data)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "No data was found for the anonymous user");
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NoUserFound, "No data was found for the anonymous user");
            }

            tokens = [];

            try {
              parsedTokens = parseMultipleTokenBlob(data, this.memoryCache.deviceIdentity.uniqueId);
              tokens = parsedTokens ? parsedTokens : [];
              tokens.forEach(function (token) {
                return _this.memoryCache.insertToken(token);
              });
            } catch (error) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Failed to deserialize anonymous tokens: " + error);
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NoUserFound, "Failed to deserialize anonymous tokens");
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  XboxTokenCache.prototype.clearTokensForUser = function (msaUserId) {
    this.memoryCache.clearTokensForUser(msaUserId);
    var key = this.makeUserTokensKey(msaUserId);
    var operation = new StorageCacheHelpers_1.ClearCacheData(this.telemetryClient, this.storage, key);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  XboxTokenCache.prototype.makeDeviceIdentityKey = function () {
    return "Xal." + this.authConfig.environment + "." + this.authConfig.sandbox + ".DeviceIdentity";
  };

  XboxTokenCache.prototype.makeDtokenKey = function () {
    return "Xal." + this.authConfig.environment + "." + this.authConfig.sandbox + ".D";
  };

  XboxTokenCache.prototype.makeTtokenKey = function () {
    return "Xal." + this.authConfig.titleId + "." + this.authConfig.environment + "." + this.authConfig.sandbox + ".T";
  };

  XboxTokenCache.prototype.makeUserTokensKey = function (msaUserId) {
    // TODO(alkhayat): XAL base64url encodes the msauserId. Necessary?
    return "Xal." + this.authConfig.titleId + "." + this.authConfig.environment + "." + this.authConfig.sandbox + ".User." + msaUserId;
  };

  return XboxTokenCache;
}();

exports.XboxTokenCache = XboxTokenCache;

function parseMultipleTokenBlob(data, deviceIdentity) {
  var parsedData = JSON.parse(data);

  if (parsedData && parsedData.deviceId && parsedData.deviceId !== deviceIdentity) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Stored token device identity does not match current device identity, dropping token");
    return undefined;
  }

  if (parsedData && parsedData.tokens) {
    var tokens = [];

    for (var _i = 0, _a = parsedData.tokens; _i < _a.length; _i++) {
      var parsedToken = _a[_i];
      var token = XboxToken_1.XboxToken.deserialize(parsedToken);

      if (token) {
        tokens.push(token);
      }
    }

    return tokens;
  } else {
    return undefined;
  }
}

exports.parseMultipleTokenBlob = parseMultipleTokenBlob;

function serializeMultipleTokenBlob(tokens, deviceIdentity) {
  var blob = {
    deviceId: deviceIdentity,
    tokens: tokens.filter(function (token) {
      return token.isValid || token.hasSignInDisplayClaims && token.hasData;
    }).map(function (token) {
      return token.serialize();
    })
  };
  return JSON.stringify(blob);
}

exports.serializeMultipleTokenBlob = serializeMultipleTokenBlob;

function parseSingleTokenBlob(data, deviceIdentity) {
  var parsedData = JSON.parse(data);

  if (parsedData && parsedData.deviceId && parsedData.deviceId !== deviceIdentity) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Stored token device identity does not match current device identity, dropping token");
    return undefined;
  }

  if (parsedData && parsedData.token) {
    return XboxToken_1.XboxToken.deserialize(parsedData.token);
  } else {
    return undefined;
  }
}

exports.parseSingleTokenBlob = parseSingleTokenBlob;

function serializeSingleTokenBlob(token, deviceIdentity) {
  var blob = {
    deviceId: deviceIdentity,
    token: token.serialize()
  };
  return JSON.stringify(blob);
}

exports.serializeSingleTokenBlob = serializeSingleTokenBlob;