import * as React from "react";
import { Select } from "antd";
import { ClubHub } from "@xbox/social-core";
import * as ClubStyles from "./Club.Styles";
import { Localize } from "../../common/translation/Localize";

const Option = Select.Option;

interface ClubSettingComboBoxProps {
    item?: ClubHub.ClubSetting;
    handler: any;
    label: string;
}

export const ClubSettingsComboBox: React.SFC<ClubSettingComboBoxProps> = props => {
    return (
        <div>
            <p>{props.label}</p>

            <ClubStyles.DropDown
                onChange={props.handler}
                value={props.item ? Localize(`clubSettings.${props.item.value}`) : ""}
                disabled={!props.item || !props.item.canViewerAct || !props.item.canViewerChangeSetting}
            >
                {props.item
                    && props.item.allowedValues
                    && props.item.allowedValues.map(
                        (val: string) => (
                            <Option key={val} value={val}>
                                {Localize(`clubSettings.${val}`)}
                            </Option>
                        )
                    )}
            </ClubStyles.DropDown>
        </div>
    );
};
