import styled, { css } from "styled-components";

const ButtonBase = styled<{iconOnly: boolean|undefined}, "button">("button")`
    font-size: 14px;
    font-weight: 400;
    border: 1px solid;
    border-radius: 4px;
    padding: 5px 15px;

    ${props => props.iconOnly && css`
        padding: 2px 0 0 1px;
    `}

    &:hover:enabled, &:focus:enabled, &:active:enabled {
        outline:0;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        outline: 2px solid ${props => props.theme.signOutBlackBorder} !important;
        border: 2px solid  ${props => props.theme.signOutWhiteBorder} !important;
    }

    &:disabled, &:disabled:hover, &:disabled:focus, &:disabled:active {
        cursor: default;
        color: ${props => props.theme.foregroundDisabled};
        border-color: ${props => props.theme.borderMain};
        background-color: ${props => props.theme.backgroundDisabled};
        border-radius: 5px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
    }
`;

export const ButtonDefault = ButtonBase.extend`
    color: ${props => props.theme.grayShadeA};
    background-color: ${props => props.theme.backgroundMain};
    border-color: ${props => props.theme.borderMain};

    &:hover:enabled, &:focus:enabled {
        color: ${props => props.theme.blueHover};
        border-color: ${props => props.theme.blueHover};
        background-color: ${props => props.theme.backgroundMain};

        span {
            color: ${props => props.theme.blueHover};
        }
    }

    &:active:enabled {
        color: ${props => props.theme.whiteText};
        border-color: ${props => props.theme.blueActive};
        background-color: ${props => props.theme.blueActive};

        span {
            color: ${props => props.theme.whiteText};
        }
    }

    span {
        color: ${props => props.theme.grayShadeA};
    }
`;

export const ButtonPrimary = ButtonBase.extend`
    color: ${props => props.theme.blackText};
    background-color: ${props => props.theme.whiteText};
    border-color: ${props => props.theme.borderMain};

    &:hover:enabled {
        color: ${props => props.theme.whiteText};
        border-color: ${props => props.theme.blueHover};
        background-color: ${props => props.theme.blueHover};
        span {
            color: ${props => props.theme.whiteText};
        }
    }

    &:active:enabled {
        color: ${props => props.theme.whiteText};
        border-color: ${props => props.theme.blueActive};
        background-color: ${props => props.theme.blueActive};
        span {
            color: ${props => props.theme.whiteText};
        }
    }

    span {
        color: ${props => props.theme.whiteText};
    }
`;

export const ButtonDanger = ButtonBase.extend`
    color: ${props => props.theme.btnTextColor};
    background-color: ${props => props.theme.dangerBtnBackground};
    border-color: ${props => props.theme.btnBgColor};

    &:hover:enabled {
        color: ${props => props.theme.btnTextColor};
        background-color: ${props => props.theme.btnBgColor};
        outline:  ${props => props.theme.btnOutlineColor};
        border-color: ${props => props.theme.btnTextColor};
        span {
            color: ${props => props.theme.btnTextColor};
        }
    }

    &:focus:enabled {
        color: ${props => props.theme.btnTextColor};
        background-color: ${props => props.theme.btnBgColor};
        outline:  ${props => props.theme.btnOutlineColor};
        border-color: ${props => props.theme.btnTextColor};
        span {
            color: ${props => props.theme.btnTextColor};
        }
    }

    &:active:enabled {
        color: ${props => props.theme.btnTextColor};
        border-color: ${props => props.theme.redActive};
        background-color: ${props => props.theme.redActive};
        span {
            color: ${props => props.theme.btnTextColor};
        }
    }

    span {
        color: ${props => props.theme.btnTextColor};
    }
`;
