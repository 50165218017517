import * as React from "react";
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import { Person, ActivityFeed } from "@xbox/social-core";
import { AnalyticsState } from "@xbox/social-redux";
import { IFeedItemSocialActionHandlers, BatchFeedItems } from "@xbox/social-views";
import { Localize } from "../../common/translation/Localize";
import * as GlobalStyles from "../../containers/Styles";
import * as avs from "./AnalyticsViewer.Styles";
import { getFeedEngagementDataView, FeedEngagementDataViewProps } from "./FeedEngagementDataContainer";
import { getAdminActionsDataView, AdminActionsDataViewProps } from "./AdminActionsDataContainer";
import { SummaryLayout } from "../../components/analytics/SummaryLayout";
import { DetailsLayout } from "../../components/analytics/DetailsLayout";
import FeedItemViewerContainer from "../feeditemviewer/FeedItemViewerContainer";

interface AnalyticsViewerProps {
    isLoading: boolean;
    clubId: string;
    socialActions: IFeedItemSocialActionHandlers;
    analyticsData: AnalyticsState;
    batchFeedItems: BatchFeedItems;
    month?: string;
    engagers: Person[];
    OnMonthChanged: (date: any) => void;
    ExportAnalyticsReport?: () => void;
    navigateCommentsPage: (item: ActivityFeed.FeedItem, returnPage: string) => void;
    navigateBackToPage: (targetPage: string) => void;
}

interface AnalyticsLayoutState {
    feedEngagementData: FeedEngagementDataViewProps;
    adminActionsDataView: AdminActionsDataViewProps;
}

enum AnalyticsPages {
    Summary = "summary",
    Details = "details"
}

// this class has been created to allow for further sorting logic, so that only the needed props
// And data are being sent down to the layout
// It also allows for a more organized container in regards to deeplinking which was not availible before
// And allows us to use the component did update method to reduce the number of redraws, since updates to the
// chart data are now only fired on certain conditions

export default class AnalyticsViewer extends React.Component<AnalyticsViewerProps, AnalyticsLayoutState> {
    constructor(props: AnalyticsViewerProps) {
        super(props);
        this.state = {
            feedEngagementData: this.getFeedEngagementData(),
            adminActionsDataView: this.getAdminActionsData()
        };
    }

    componentDidUpdate(prevProps: AnalyticsViewerProps) {
        if (this.props.engagers && prevProps.engagers !== this.props.engagers) {
            this.setState({
                feedEngagementData: this.getFeedEngagementData(),
                adminActionsDataView: this.getAdminActionsData()
            });
        }
        if (this.props.batchFeedItems && prevProps.batchFeedItems !== this.props.batchFeedItems) {
            this.setState({
                feedEngagementData: this.getFeedEngagementData(),
                adminActionsDataView: this.getAdminActionsData()
            });
        }
    }

    scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        if (document.documentElement) {
            document.documentElement.scrollTop = 0; // Firefox, IE and Opera
        }
        if (document.getElementById("MainContentWindow") !== null) {
            document.getElementById("MainContentWindow")!.scrollTo(0, 0); // for Chrome
        }
    }

    handleCommmentNagivation = (item: ActivityFeed.FeedItem) => {
        this.scrollToTop();
        let target = window.location.pathname.indexOf(AnalyticsPages.Details) > -1 ? AnalyticsPages.Details : AnalyticsPages.Summary;
        this.props.navigateCommentsPage(item, target);
    }

    handleBackButton = () => {
        this.scrollToTop();
        let target = window.location.pathname.indexOf(AnalyticsPages.Details) > -1 ? AnalyticsPages.Details : AnalyticsPages.Summary;
        this.props.navigateBackToPage(target);
    }

    getFeedEngagementData = () => {
        let updatedSocial = this.props.socialActions;
        updatedSocial.viewComments = this.handleCommmentNagivation;
        return this.props.analyticsData.feedEngagementData
                ? getFeedEngagementDataView(this.props.month, updatedSocial, this.props.analyticsData.feedEngagementData, this.props.batchFeedItems)
                : getFeedEngagementDataView(this.props.month, updatedSocial);
    }

    getAdminActionsData = () => {
        return this.props.analyticsData.adminActionsData
                ? getAdminActionsDataView(this.props.month, this.props.analyticsData.adminActionsData, this.props.engagers)
                : getAdminActionsDataView(this.props.month);
    }

    getDetailsComponent = () => {
        return (
            <DetailsLayout
                isLoading={this.props.isLoading}
                stats={this.state.adminActionsDataView.stats}
                topEngagers={this.state.adminActionsDataView.topEngagers}
                posts={this.state.feedEngagementData.posts}
                feedEngament={this.state.feedEngagementData.engament}
                followers={this.state.adminActionsDataView.followers}
                uniqueVisits={this.state.adminActionsDataView.visits}
                topPosts={this.state.feedEngagementData.topPosts}
                feedEngagementByLocale={this.state.feedEngagementData.feedEngagementByLocale}
                month={this.props.month}
                OnMonthChanged={this.props.OnMonthChanged}
                ExportAnalyticsReport={this.props.ExportAnalyticsReport}
            />
        );
    }

    getSummaryComponent = () => {
        return (
            <SummaryLayout
                isLoading={this.props.isLoading}
                stats={this.state.adminActionsDataView.stats}
                topEngagers={this.state.adminActionsDataView.topEngagers}
                feedEngament={this.state.feedEngagementData.engament}
                topPosts={this.state.feedEngagementData.topPosts}
                gender={this.state.adminActionsDataView.gender}
                age={this.state.adminActionsDataView.age}
                locale={this.state.adminActionsDataView.locale}
                feedEngagementByLocale={this.state.feedEngagementData.feedEngagementByLocale}
                month={this.props.month}
                OnMonthChanged={this.props.OnMonthChanged}
            />
        );
    }

    getFeedViewerComponent = (props: any) => {
        return (
            <FeedItemViewerContainer
                match={props.match}
                backButtonOverride={this.handleBackButton}
            />
        );
    }

    render() {
        return (
            <GlobalStyles.ContentContainer>
                <GlobalStyles.ContentTitle>{Localize("analyticsView.titleAnalytics")}</GlobalStyles.ContentTitle>
                <avs.AnalyticsMenu>
                    <avs.AnalyticsMenuItem>
                        <NavLink activeClassName="active" to={`/app/${this.props.clubId}/manage/analytics/summary`}>
                            {Localize("analyticsView.menuBarSummary")}
                        </NavLink>
                    </avs.AnalyticsMenuItem>
                    <avs.AnalyticsMenuItem>
                        <NavLink activeClassName="active" to={`/app/${this.props.clubId}/manage/analytics/details`}>
                            {Localize("analyticsView.menuBarDetails")}
                        </NavLink>
                    </avs.AnalyticsMenuItem>
                </avs.AnalyticsMenu>
                <Switch>
                    <Route path={`/app/${this.props.clubId}/manage/analytics/(details|summary)/:formattedFeedId`} render={(props: any) => this.getFeedViewerComponent(props)}/>
                    <Route path={`/app/${this.props.clubId}/manage/analytics/details`} render={this.getDetailsComponent} />
                    <Route path={`/app/${this.props.clubId}/manage/analytics/summary`} render={this.getSummaryComponent} />
                </Switch>
            </GlobalStyles.ContentContainer>
        );
    }
}
