"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubModerationReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({
  fetchInProgress: false
});
exports.clubModerationReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubModerationFetchInProgress, function (state, action) {
  return state.merge({
    fetchInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.clubModerationFetchCompleted, function (state, action) {
  return state.merge({
    clubModerationQueue: action.payload
  }, {
    deep: true
  });
}).on(ClubListActions_1.ClubActions.clubUpdateModerationQueue, function (state, action) {
  if (state.clubModerationQueue && action.payload) {
    var modq_1 = Immutable(state.clubModerationQueue.entities.reportedItems);
    var removeIds_1 = action.payload.reportedItemsDeleteRequest.map(function (item) {
      return item.reportId;
    });
    removeIds_1.forEach(function (i) {
      modq_1 = modq_1.without(modq_1[i]);
    });
    var updatedResults = state.clubModerationQueue.result.filter(function (item) {
      return removeIds_1.indexOf(item) === -1;
    });
    var newState = state.setIn(["clubModerationQueue", "result"], updatedResults);
    return newState.setIn(["clubModerationQueue", "entities", "reportedItems"], modq_1);
  }

  return state;
}).on(ClubListActions_1.ClubActions.clubModerationQueueSearchFilter, function (state, action) {
  return state.setIn(["searchFilter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubModerationQueueTypeFilter, function (state, action) {
  return state.setIn(["typeFilter"], action.payload);
}).on(ClubListActions_1.ClubActions.clearState, function (state, action) {
  return state.setIn(["clubModerationQueue"], undefined);
}).build();