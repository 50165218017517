var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { ClubActions, clubsRedux } from "@xbox/social-redux";
export var ActivityType;

(function (ActivityType) {
  ActivityType["Chat"] = "Chat";
  ActivityType["Comment"] = "Comment";
  ActivityType["FeedItem"] = "ActivityFeedItem";
})(ActivityType || (ActivityType = {}));

var FilterTypes = function FilterTypes(reportedItems, filter) {
  if (!reportedItems || !filter) {
    return reportedItems;
  }

  switch (filter) {
    case "All":
      return reportedItems;

    default:
      return reportedItems.filter(function (t) {
        return t.contentType === filter;
      });
  }
};

var FilterGamerTag = function FilterGamerTag(reportedItems, filter) {
  if (!reportedItems || !filter) {
    return reportedItems;
  }

  return reportedItems.filter(function (t) {
    if (!t.item) {
      return false;
    }

    var gamertag;

    switch (t.contentType) {
      case "ActivityFeedItem":
        gamertag = t.item.itemInfo.authorInfo.gamertag;
        break;

      case "Chat":
        gamertag = t.item.sender.gamertag;
        break;

      case "Comment":
        gamertag = t.item.author.gamertag;
        break;

      default:
        return false;
    }

    return gamertag.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
  });
};

function coreModQContainer(ModItemsPanel, ModItemHolder, LoadingState, resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.modActionSelected = function (action, actionItem) {
          var reason = action === "remove" ? "Deleted" : "Ignored";
          var request = [{
            contentId: actionItem.contentId,
            reportId: actionItem.reportId,
            contentType: actionItem.contentType,
            reason: reason
          }];

          switch (action) {
            case "remove":
              _this.removeItem(request);

              break;

            case "ignore":
              // ignore the item
              var ignoreItemPayload = {
                clubId: _this.props.clubId,
                shouldDelete: false,
                reportedItemsDeleteRequest: request
              };

              _this.props.actOnModerationItem(ignoreItemPayload);

              break;

            case "ban":
              _this.banUser(actionItem);

              break;

            case "removeandban":
              _this.removeItem(request);

              _this.banUser(actionItem);

              break;

            default:
              // nothing
              break;
          }
        };

        _this.removeItem = function (request) {
          var removeItemPayload = {
            clubId: _this.props.clubId,
            shouldDelete: true,
            reportedItemsDeleteRequest: request
          };
          console.log(removeItemPayload);

          _this.props.actOnModerationItem(removeItemPayload);
        };

        _this.banUser = function (actionItem) {
          var itemAuthorXuid = "";

          switch (actionItem.contentType) {
            case ActivityType.Chat:
              var chatItem = actionItem.item;
              itemAuthorXuid = chatItem.sender.xuid;
              break;

            case ActivityType.Comment:
              var commentItem = actionItem.item;
              itemAuthorXuid = commentItem.author.xuid;
              break;

            case ActivityType.FeedItem:
              var activityFeedItem = void 0;
              activityFeedItem = __assign({}, actionItem.item.itemInfo);
              itemAuthorXuid = activityFeedItem.authorInfo.xuid;
              break;

            default:
              break;
          } // ban the user


          var banUserPayload = {
            xuid: itemAuthorXuid,
            clubId: _this.props.clubId,
            role: ClubHub.ClubRoles.Banned,
            add: true
          };

          _this.props.updateMemberRole(banUserPayload);
        };

        _this.ignoreAll = function () {
          var request = [];

          _this.props.reportedItems.forEach(function (actionItem) {
            request.push({
              contentId: actionItem.contentId,
              reportId: actionItem.reportId,
              contentType: actionItem.contentType,
              reason: "Ignored"
            });
          });

          var payload = {
            clubId: _this.props.clubId,
            shouldDelete: false,
            reportedItemsDeleteRequest: request
          };

          _this.props.actOnModerationItem(payload);
        };

        _this.search = function (e) {
          // search
          _this.props.applySearchFilter(e);
        };

        _this.filter = function (e) {
          // type filter
          _this.props.applyTypeFilter(e);
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.props.fecthModerationQueue(this.props.clubId);
      };

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.match && this.props.match.path !== prevProps.match.path) {
          this.props.fecthModerationQueue(this.props.clubId);
        }
      };

      class_1.prototype.render = function () {
        var handlers = {
          modActionSelected: this.modActionSelected,
          search: this.search,
          filter: this.filter,
          ignoreAll: this.ignoreAll
        };
        return this.props.completed ? React.createElement(ModItemsPanel, {
          handlers: handlers,
          items: this.props.reportedItems,
          moderators: this.props.moderators
        }) : React.createElement(LoadingState, null);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var moderationResult = clubsRedux.selectors.getClubModerationQueue(state.moderation);
  var clubDetailsResult = clubsRedux.selectors.getClubDetails(state);
  var moderator = clubDetailsResult.item.roster && clubDetailsResult.item.roster.moderator && clubDetailsResult.item.roster.moderator.filter(function (x) {
    return x.profile;
  }).map(function (x) {
    return x.profile.xuid;
  });
  return {
    reportedItems: FilterTypes(FilterGamerTag(moderationResult.reportedItems, state.moderation.searchFilter), state.moderation.typeFilter),
    moderators: moderator,
    completed: moderationResult.completed
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fecthModerationQueue: function fecthModerationQueue(clubId) {
      return dispatch(ClubActions.clubModerationFetchQueue(clubId));
    },
    actOnModerationItem: function actOnModerationItem(clubModerationPayload) {
      return dispatch(ClubActions.clubModerationAction(clubModerationPayload));
    },
    applyTypeFilter: function applyTypeFilter(query) {
      return dispatch(ClubActions.clubModerationQueueTypeFilter(query));
    },
    applySearchFilter: function applySearchFilter(query) {
      return dispatch(ClubActions.clubModerationQueueSearchFilter(query));
    },
    updateMemberRole: function updateMemberRole(update) {
      return dispatch(ClubActions.clubRosterUpdateRole(update));
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreModQContainer(ModItemsPanel, ModItemHolder, LoadingState, resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreModQContainer(ModItemsPanel, ModItemHolder, LoadingState, resolver));
}