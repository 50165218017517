"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrivacyActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var PrivacyActions;

(function (PrivacyActions) {
  PrivacyActions.getPrivacySettings = redux_ts_simple_1.createAction("PRIVACY::FETCH_SETTINGS");
  PrivacyActions.getPrivacySettingsCompleted = redux_ts_simple_1.createAction("PRIVACY::FETCH_SETTINGS_COMPLETED");
  PrivacyActions.updatePrivacySetting = redux_ts_simple_1.createAction("PRIVACY::UPDATE_SETTINGS");
  PrivacyActions.updatePrivacySettingCompleted = redux_ts_simple_1.createAction("PRIVACY::UPDATE_SETTINGS_COMPLETED");
  PrivacyActions.blockUser = redux_ts_simple_1.createAction("PRIVACY::BLOCK_USER");
  PrivacyActions.unblockUser = redux_ts_simple_1.createAction("PRIVACY::UNBLOCK_USER");
})(PrivacyActions = exports.PrivacyActions || (exports.PrivacyActions = {}));