import * as React from "react";
import * as FeedItemStyles from "./base/FeedItem.Styles";
import { ActivityFeedItem, FeedItemProps } from "./base/ActivityFeedItem";
import { PreviewFeedItemProps, PreviewActivityFeedItem } from "./base/PreviewActivityFeedItem";
import { Glyphs } from "../../common/Generic.Icons";

export class TextPostFeedItem extends ActivityFeedItem {
    constructor(props: FeedItemProps) {
        super(props);
    }

    /*********************************************************************/
    /* Full Base Template
    /*********************************************************************/
    useFullOnLoadOnMainDiv = (): boolean => true;

    getFullFeedItemData = (): JSX.Element | null => {
        return this.props.item.hasUgc
                    ?  <FeedItemStyles.TextContainer>{this.props.item.itemText}</FeedItemStyles.TextContainer>
                    : null;
    }

    /*********************************************************************/
    /* Inline Template
    /*********************************************************************/

    getInlinePostGlyph = () => {
        return Glyphs.Chat;
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/

    // Fully covered by default ActivityFeedItem functions.
}

export const PreviewTextPostFeedItem: React.SFC<PreviewFeedItemProps> = props => {
    let previewContent =  (
        <FeedItemStyles.TextContainer>{props.itemText} </FeedItemStyles.TextContainer>
    );

    return <PreviewActivityFeedItem previewContent={previewContent} {...props} />;
};
