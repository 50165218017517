"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActivityFeedActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ActivityFeedActions;

(function (ActivityFeedActions) {
  // Action creators
  ActivityFeedActions.setCurrentFeedId = redux_ts_simple_1.createAction("FEED:FEED_SET_CURRENT_FEED_ID");
  ActivityFeedActions.formatFeedData = redux_ts_simple_1.createAction("FEED:FEED_FORMAT");
  ActivityFeedActions.feedFetchCompleted = redux_ts_simple_1.createAction("FEED::FEED_FETCH_COMPLETED");
  ActivityFeedActions.feedFetchInProgress = redux_ts_simple_1.createAction("FEED::FEED_FETCH_INPROGRESS");
  ActivityFeedActions.feedFetchError = redux_ts_simple_1.createAction("FEED::FEED_FETCH_ERROR");
  ActivityFeedActions.fetchClubFeedItems = redux_ts_simple_1.createAction("FEED::CLUB_FEED_FETCH");
  ActivityFeedActions.fetchPersonalFeedItems = redux_ts_simple_1.createAction("FEED::PERSONAL_FEED_FETCH");
  ActivityFeedActions.fetchScheduledFeedItems = redux_ts_simple_1.createAction("FEED::SCHEDULED_FEED_FETCH");
  ActivityFeedActions.fetchBallotFeedItems = redux_ts_simple_1.createAction("FEED::BALLOT_FEED_FETCH");
  ActivityFeedActions.likeFeedItem = redux_ts_simple_1.createAction("FEED::SEND_LIKE_FEED");
  ActivityFeedActions.hasLikedFeed = redux_ts_simple_1.createAction("FEED::HAS_LIKED_FEED");
  ActivityFeedActions.hasPinnedFeed = redux_ts_simple_1.createAction("FEED::HAS_PINNED_FEED");
  ActivityFeedActions.resetFeedItems = redux_ts_simple_1.createAction("FEED::RESET_FEED_ITEMS"); // Posting to feed

  ActivityFeedActions.postFeedItem = redux_ts_simple_1.createAction("FEED::SEND_FEED_ITEM");
  ActivityFeedActions.postFeedItemInProgress = redux_ts_simple_1.createAction("FEED::SEND_FEED_ITEM_INPROGRESS");
  ActivityFeedActions.postFeedItemCompleted = redux_ts_simple_1.createAction("FEED::SEND_FEED_ITEM_COMPLETED");
  ActivityFeedActions.shareFeedItemCompleted = redux_ts_simple_1.createAction("FEED::SHARE_FEED_ITEM_COMPLETED"); // Web link hydration

  ActivityFeedActions.hydrateWebLink = redux_ts_simple_1.createAction("FEED::HYDRATE_WEB_LINK");
  ActivityFeedActions.HydrateWebLinkInProgress = redux_ts_simple_1.createAction("FEED::HYDRATE_WEB_LINK_INPROGRESS");
  ActivityFeedActions.HydrateWebLinkCompleted = redux_ts_simple_1.createAction("FEED::HYDRATE_WEB_LINK_COMPLETED"); // Feed item selected

  ActivityFeedActions.fetchAndSelectFeedItem = redux_ts_simple_1.createAction("FEED::FEED_ITEM_FETCH_AND_SELECTED");
  ActivityFeedActions.fetchAndSelectFeedItemInProgress = redux_ts_simple_1.createAction("FEED::FEED_ITEM_FETCH_AND_SELECTED_INPROGRESS");
  ActivityFeedActions.feedItemSelected = redux_ts_simple_1.createAction("FEED::FEED_ITEM_SELECTED"); // String Validation Actions

  ActivityFeedActions.validateString = redux_ts_simple_1.createAction("FEED::VALIDATE_STRING");
  ActivityFeedActions.validateStringInProgress = redux_ts_simple_1.createAction("FEED::VALIDATE_STRING_INPROGRESS");
  ActivityFeedActions.validateStringCompleted = redux_ts_simple_1.createAction("FEED::VALIDATE_STRING_COMPLETED"); // BatchFeedItems

  ActivityFeedActions.hydrateFeedItems = redux_ts_simple_1.createAction("FEED::HYDRATE_FEED_ITEMS");
  ActivityFeedActions.hydrateFeedItemsInProgress = redux_ts_simple_1.createAction("FEED::HYDRATE_FEED_ITEMS_INPROGRESS");
  ActivityFeedActions.hydrateFeedItemsCompleted = redux_ts_simple_1.createAction("FEED::HYDRATE_FEED_ITEMS_COMPLETED"); // Admin Actions

  ActivityFeedActions.adminActOnItem = redux_ts_simple_1.createAction("FEED::ADMIN_ACT_ON_ITEM");
  ActivityFeedActions.feedItemDeleted = redux_ts_simple_1.createAction("FEED::ITEM_DELETED");
  ActivityFeedActions.adminActOnCommentItem = redux_ts_simple_1.createAction("COMMENT::ADMIN_ACT_ON_ITEM");
  ActivityFeedActions.commentItemDeleted = redux_ts_simple_1.createAction("COMMENT::ITEM_DELETED"); // Vote Actions

  ActivityFeedActions.createBallot = redux_ts_simple_1.createAction("FEED::CREATE_BALLOT");
  ActivityFeedActions.voteFeedItem = redux_ts_simple_1.createAction("FEED::VOTE_ACT_ON_ITEM");
  ActivityFeedActions.voteFeedItemSuccess = redux_ts_simple_1.createAction("FEED::VOTE_ACT_ON_ITEM_COMPLETED"); // Comments

  ActivityFeedActions.fetchFeedComments = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS");
  ActivityFeedActions.fetchFeedCommentsCompleted = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS_COMPLETED");
  ActivityFeedActions.fetchFeedCommentsInProgress = redux_ts_simple_1.createAction("COMMENTS::FETCH_FEED_COMMENTS_INPROGRESS");
  ActivityFeedActions.exportFeedComments = redux_ts_simple_1.createAction("COMMENTS::EXPORT_FEED_COMMENTS");
  ActivityFeedActions.loadCommentLock = redux_ts_simple_1.createAction("COMMENTS::LOAD_COMMENT_LOCK");
  ActivityFeedActions.loadCommentLockInProgress = redux_ts_simple_1.createAction("COMMENTS::LOAD_COMMENT_LOCK_IN_PROGRESS");
  ActivityFeedActions.loadCommentLockCompleted = redux_ts_simple_1.createAction("COMMENTS::LOAD_COMMENT_LOCK_COMPLETED");
  ActivityFeedActions.clearComments = redux_ts_simple_1.createAction("COMMENTS::CLEAR_COMMENTS"); // Post Comment

  ActivityFeedActions.postFeedComment = redux_ts_simple_1.createAction("COMMENT::POST_COMMENT_ITEM");
  ActivityFeedActions.postFeedCommentInProgress = redux_ts_simple_1.createAction("COMMENT::POST_COMMENT_ITEM_INPROGRESS");
  ActivityFeedActions.postFeedCommentCompleted = redux_ts_simple_1.createAction("COMMENT::POST_COMMENT_ITEM_COMPLETED"); // Clear State Action

  ActivityFeedActions.clearFeedState = redux_ts_simple_1.createAction("FEED::CLEAR_FEED_STATE"); // Feed Settings

  ActivityFeedActions.getFeedSettings = redux_ts_simple_1.createAction("FEED::FETCH_SETTINGS");
  ActivityFeedActions.getFeedSettingsCompleted = redux_ts_simple_1.createAction("FEED::FETCH_SETTINGS_COMPLETED");
  ActivityFeedActions.updateFeedSetting = redux_ts_simple_1.createAction("FEED::UPDATE_SETTINGS");
  ActivityFeedActions.updateFeedSettingCompleted = redux_ts_simple_1.createAction("FEED::UPDATE_SETTINGS_COMPLETED");
})(ActivityFeedActions = exports.ActivityFeedActions || (exports.ActivityFeedActions = {}));