var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { feedViewerRedux, CancellationTokenSource } from "@xbox/social-redux";
import { ActivityFeed, ClubHub } from "@xbox/social-core";
import { FeedItemUtils } from "../../Utils";

function coreClubFeedContainer(ClubFeedResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.cancelRequest = function () {
          if (_this.cancellationSource) {
            _this.cancellationSource.cancel();
          }
        };
        /*******************************************************
            SOCIAL ACTIONS START
        *******************************************************/


        _this.castVote = function (item, choice) {
          var request = {
            ballotChoice: choice,
            ballotChoiceUri: item.pollData.choices[choice].voteUri,
            itemRoot: item.itemRoot,
            feedItemId: item.feedItemId
          };

          _this.props.castVote(request);
        };

        _this.handleLikeClick = function (item, hasLiked) {
          var request = {
            itemRoot: item.itemRoot,
            like: hasLiked,
            itemType: item.activityItemType
          };

          _this.props.updateLike(request);
        };

        _this.fetchCommentLock = function (item) {
          var lockPayload = {
            itemRoot: item.itemRoot
          };

          _this.props.loadCommentLock(lockPayload);

          _this.props.feedItemSelected(item);
        };

        _this.navigateCommentsPage = function (item) {
          _this.fetchCommentLock(item);

          var payload = {
            cursor: item.comments ? item.comments.cursor : "",
            postId: item.itemRoot,
            numberOfItems: 10
          };

          _this.props.loadComments(payload);

          _this.props.navigateViewer(_this.props.currentClub.details.id, FeedItemUtils.parseFeedItemId(item.itemRoot));
        };
        /*******************************************************
            ADMIN ACTIONS START
        *******************************************************/


        _this.actOnItem = function (action, item) {
          var payload = {
            action: action,
            clubId: _this.props.currentClub ? _this.props.currentClub.details.id : "",
            xuid: item.authorInfo.xuid,
            item: item,
            channelId: _this.props.selectedChannel && _this.props.selectedChannel.id
          };

          _this.props.actOnFeedItem(payload);
        };

        _this.addToModQ = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.AddToModQ, item);
        };

        _this.report = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Report, item);
        };

        _this.pin = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Pin, item);
        };

        _this.unpin = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.UnPin, item);
        };

        _this.deleteItem = function (item, shouldBan) {
          if (shouldBan) {
            _this.actOnItem(ActivityFeed.AdminActionTypes.DeleteAndBan, item);
          } else {
            _this.actOnItem(ActivityFeed.AdminActionTypes.Delete, item);
          }
        };

        _this.exportComments = function (itemId) {
          _this.props.exportComments(itemId);
        };
        /*******************************************************
            ADMIN ACTIONS END
        *******************************************************/


        _this.switchToFeedTools = function (subpage) {
          _this.props.navigateToFeedTools(_this.props.currentClub.details.id, subpage);
        };

        _this.fetchFeed = function (cursor) {
          _this.cancelRequest();

          _this.cancellationSource = new CancellationTokenSource();

          if (_this.props.fetchActivityFeed) {
            var request = {
              cancellationToken: _this.cancellationSource.getToken(),
              clubId: _this.props.currentClub.details.id,
              numItems: 20,
              cursor: cursor,
              channelId: _this.props.selectedChannel && !_this.props.selectedChannel.isDefault ? _this.props.selectedChannel.id : undefined
            };

            _this.props.fetchActivityFeed(request);
          }
        };

        return _this;
      }

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.selectedChannel && !prevProps.selectedChannel) {
          this.fetchFeed();
        } else if (this.props.selectedChannel && this.props.selectedChannel.id !== prevProps.selectedChannel.id) {
          this.fetchFeed();
        }

        if (this.props.postStatus && this.props.postStatus !== prevProps.postStatus) {
          this.props.clearActivityFeed();
          this.fetchFeed();
        }
      };

      class_1.prototype.componentWillUnmount = function () {
        // On Unmount, cancel any outstanding request
        this.cancelRequest();
      };

      class_1.prototype.render = function () {
        var adminActions = {
          delete: this.deleteItem,
          pin: this.pin,
          unpin: this.unpin,
          report: this.report,
          addToModQ: this.addToModQ,
          disableComment: function disableComment() {},
          enableComment: function enableComment() {},
          exportComments: this.exportComments
        };
        var socialActions = {
          like: this.handleLikeClick,
          viewComments: this.navigateCommentsPage,
          vote: this.castVote
        };
        var feedStatus = {
          results: this.props.results,
          isValid: this.props.isValid,
          isErrored: this.props.isCompleted && !this.props.results
        };
        var options = {
          isModerator: this.props.isModerator,
          selectedChannel: this.props.selectedChannel,
          postStatus: this.props.postStatus,
          switchToFeedTools: this.switchToFeedTools
        };
        return ClubFeedResolver.getClubFeedContainer(this.fetchFeed, this.props.clearActivityFeed, this.props.feedItemSelected, socialActions, adminActions, feedStatus, options, this.props.isCommentLocked, this.props.cursor);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var activityFeed = feedViewerRedux.selectors.getActivityFeed(state);

  if (activityFeed.currentUser === undefined) {
    throw new Error("undefined User");
  }

  return {
    currentUser: activityFeed.currentUser,
    results: activityFeed.results,
    currentClub: state.clubs.clubDetails.club,
    cursor: activityFeed.cursor,
    selectedChannel: state.channels.selectedChannel,
    isValid: state.feedViewer.activityFeed.activities ? state.feedViewer.activityFeed.activities.feedId === state.feedViewer.activityFeed.selectedFeedId : false,
    postStatus: state.feedViewer.postStatus.resultData,
    isModerator: state.clubs.clubDetails.club.settings.viewerRoles.roles.some(function (x) {
      return x === ClubHub.ClubRoles.Moderator;
    }),
    isCompleted: activityFeed.completed,
    isCommentLocked: state.feedViewer.activityFeed.isCommentLocked
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchActivityFeed: function fetchActivityFeed(request) {
      return dispatch(feedViewerRedux.actions.fetchClubFeedItems(request));
    },
    clearActivityFeed: function clearActivityFeed() {
      return dispatch(feedViewerRedux.actions.clearFeedState());
    },
    updateLike: function updateLike(likePayload) {
      return dispatch(feedViewerRedux.actions.likeFeedItem(likePayload));
    },
    castVote: function castVote(votePayload) {
      return dispatch(feedViewerRedux.actions.voteFeedItem(votePayload));
    },
    actOnFeedItem: function actOnFeedItem(payload) {
      return dispatch(feedViewerRedux.actions.adminActOnItem(payload));
    },
    loadComments: function loadComments(payload) {
      return dispatch(feedViewerRedux.actions.fetchFeedComments(payload));
    },
    exportComments: function exportComments(feedItemId) {
      return dispatch(feedViewerRedux.actions.exportFeedComments(feedItemId));
    },
    loadCommentLock: function loadCommentLock(payload) {
      return dispatch(feedViewerRedux.actions.loadCommentLock(payload));
    },
    feedItemSelected: function feedItemSelected(feedItem) {
      return dispatch(feedViewerRedux.actions.feedItemSelected(feedItem));
    },
    navigateToFeedTools: function navigateToFeedTools(clubId, subpage) {
      return dispatch(push("/app/" + clubId + "/manage/" + subpage));
    },
    navigateViewer: function navigateViewer(clubId, formattedId) {
      return dispatch(push("/app/" + clubId + "/discussion/post/" + formattedId));
    },
    navigateHome: function navigateHome() {
      return dispatch(push("/"));
    }
  };
};

export function CoreClubFeedContainer(ClubFeedResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreClubFeedContainer(ClubFeedResolver));
}