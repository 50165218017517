import * as React from "react";
import { Row, Modal, Col, Select, Form } from "antd";
import { ClubHub, edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { CoreShareModalContainer, IShareModalResolver } from "@xbox/social-views";
import * as Styles from "../../common/Generic.Styles";
import * as ClubAdminStyles from "./ClubAdmin.Styles";
import { Localize } from "../../common/translation/Localize";
import { GenericModalFooter } from "../../common/GenericElements";
import { withCurrentUserContext } from "../CurrentUserContext";
import Util from "../../common/Util";

function getShareModal(
    userClubs: ClubHub.ClubDetails[],
    showShareModal: boolean,
    onCancelShare: () => void,
    onShare: () => void,
    onCaptionsChanged: (e: any) => void,
    onClubSelectChange: (e: any) => void,
    onChannelSelectChange: (e: any) => void,
    shareCaption: string,
    clubChannels?: ClubHub.Channel[],
    selectedClubId?: string,
    selectedChannelId?: string
): JSX.Element {
    return (
        <div key="shareModal" onClick={e => e.stopPropagation()}>
            <Modal
                visible={showShareModal}
                title={Localize("activityFeedContainer.shareFeedItem")}
                onCancel={onCancelShare}
                footer={
                    <GenericModalFooter
                        onOk={onShare}
                        onCancel={onCancelShare}
                        disableOK={selectedClubId === undefined}
                        showError={selectedClubId === undefined}
                        errorMessage={Localize("activityFeedContainer.noClubsToShareError")}
                    />}
            >
                {getClubListAndCaption(
                    userClubs,
                    onCaptionsChanged,
                    onClubSelectChange,
                    onChannelSelectChange,
                    shareCaption,
                    clubChannels,
                    selectedClubId,
                    selectedChannelId
                )}
            </Modal>
        </div>
    );

}

function getClubOption(club: ClubHub.ClubDetails) {
    let image = club.profile.displayImageUrl.value ? club.profile.displayImageUrl.value : "";
    return (
        <Select.Option key={club.id} value={club.id}>
            <ClubAdminStyles.PlayedTitleImage src={edsImageResizer(Util.ensureSSLImage(image), EdsImageSize._100x100)} />
            {club.profile.name.value}
        </Select.Option>
    );
}

function getChannelOption(channel: ClubHub.Channel) {
    return (
        <Select.Option key={channel.id.toString()} value={channel.id.toString()}>
            {channel.isDefault ? Localize("channels.general") : channel.name}
        </Select.Option>
    );
}

function getClubListAndCaption(
    userClubs: ClubHub.ClubDetails[],
    onCaptionsChanged: (e: any) => void,
    onClubSelectChange: (e: any) => void,
    onChannelSelectChange: (e: any) => void,
    shareCaption: string,
    clubChannels?: ClubHub.Channel[],
    selectedClubId?: string,
    selectedChannelId?: string
): JSX.Element {
    return (
        <div>
            <Form layout="horizontal">
                <ClubAdminStyles.FilterList>
                    <ClubAdminStyles.FilterListItem>
                        <Form.Item>
                            <ClubAdminStyles.FilterTitle>{Localize("activityFeedContainer.shareToClubTitle")}</ClubAdminStyles.FilterTitle>
                            <Styles.ThemedSelect
                                style={{ width: 300 }}
                                onChange={onClubSelectChange}
                                value={selectedClubId}
                            >
                                {userClubs.map(x => getClubOption(x))}
                            </Styles.ThemedSelect>
                            <ClubAdminStyles.FilterTitle>{Localize("activityFeedContainer.shareToChannelTitle")}</ClubAdminStyles.FilterTitle>
                            <Styles.ThemedSelect
                                style={{ width: 300 }}
                                onChange={onChannelSelectChange}
                                value={
                                    selectedChannelId
                                        ? (selectedChannelId === selectedClubId ? `${Localize("channels.general")}` : selectedChannelId.toString())
                                        : ""
                                }
                            >
                                {clubChannels && clubChannels.map(x => getChannelOption(x))}
                            </Styles.ThemedSelect>
                        </Form.Item>
                    </ClubAdminStyles.FilterListItem>
                </ClubAdminStyles.FilterList>
            </Form>

            <Row>
                <Col>
                    <Styles.TextAreaInput value={shareCaption} onChange={onCaptionsChanged} placeholder={Localize("activityFeedContainer.addCaption")} />
                </Col>
            </Row>
        </div>
    );
}

const ShareModalResolver: IShareModalResolver = {
    getShareModalContainer: getShareModal
};

export default withCurrentUserContext(CoreShareModalContainer(ShareModalResolver));
