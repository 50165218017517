import { Mediahub, ContentLocatorType, UpdateImageTypes } from "@xbox/social-core";
import { notificationService } from "../../index";
import { MediaType } from "./FeedToolsTypes";

const ERROR_THUMBNAIL_FAILED_007 = "007";
const maxPreviewWidth = 370;

const customLogoDimensions = {
    height: 1080,
    width: 1080
};

const customBackgroundDimensions = {
    height: 1080,
    width: 1920
};

const UploadMediaItemType = "UploadMediaItem";

export enum XBLMediaChoices {
    Screenshots,
    Gameclips,
    Both
}

export interface FileData {
    thumbnailBase64: string;
    thumbnailOriginSize: number;
    originalWidth: number;
    originalHeight: number;
    duration?: number;
}

export const getLocator = (xuid: string, contentId: string, mediaType: MediaType, sharedId: string = "00000000-0000-0000-0000-000000000000") => {
    let locatorRoot;
    let mediaPath;

    switch (mediaType) {
        case MediaType.Gameclip:
            locatorRoot = "gameclipsmetadata.xboxlive.com/users";
            mediaPath = "clips";
            break;
        case MediaType.Screenshot:
        default:
            locatorRoot = "screenshotsmetadata.xboxlive.com/users";
            mediaPath = "screenshots";
    }

    return `${locatorRoot}/xuid(${xuid})/scids/${sharedId}/${mediaPath}/${contentId}`;
};

export const getThumbnailLocator = (locators: Mediahub.MediaContentLocator[], locatorType: ContentLocatorType, defaultValue: string = ""): string => {
    let data = locators.find(dl => dl.locatorType === locatorType);
    return data ? data.uri : defaultValue;
};

export const readFileAsDataUrl = (file: File): Promise<string> => {
    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(reader.error);
            console.warn(`Failed to read file `, file.type);
            notificationService.modalError("UploadMediaItem", "001");
        };
        reader.onload = () => {
            if (reader.result && typeof reader.result === "string") {
                resolve(reader.result);
            }
        };

        reader.readAsDataURL(file);
    });
};

export const createImageThumbnail = (dataUrl: string): Promise<FileData> => {
    let img = new Image;

    return new Promise<FileData>((resolve, reject) => {
        img.onerror = (err: Event | string) => {
            reject(err);
            console.warn(`Failed to create image thumbnail: `, err);
            notificationService.modalError(UploadMediaItemType, ERROR_THUMBNAIL_FAILED_007);
        };

        img.onload = () => {
            let canvas = document.createElement("canvas");
            let thumbBase64 = img.src;

            if (img.width > maxPreviewWidth) {
                canvas.width = img.width;
                canvas.height = img.height;
                let context = canvas.getContext("2d");
                if (context) {
                    canvas.height = (img.height / img.width) * maxPreviewWidth;
                    canvas.width = maxPreviewWidth;
                    context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                    context.drawImage(img, 0, 0, canvas.width, canvas.height);
                }
                thumbBase64 = canvas.toDataURL();
            }

            const thumbTrim = thumbBase64.slice(thumbBase64.indexOf(`,`) + 1, thumbBase64.length);
            const data: FileData = {
                originalHeight: img.height,
                originalWidth: img.width,
                thumbnailBase64: thumbBase64,
                thumbnailOriginSize: atob(thumbTrim).length
            };
            resolve(data);
        };

        img.src = dataUrl;
    });
};

export const createScaledImage = (dataUrl: string, type: string): Promise<FileData> => {
    let img = new Image;

    return new Promise<FileData>((resolve, reject) => {
        img.onerror = (err: Event | string) => {
            reject(err);
            console.warn(`Failed to create scaled image: `, err);
            notificationService.modalError(UploadMediaItemType, ERROR_THUMBNAIL_FAILED_007);
        };

        img.onload = () => {
            let canvas = document.createElement("canvas");
            let thumbBase64 = img.src;

            if (type === UpdateImageTypes.background) {
                if (img.width !== customBackgroundDimensions.width || img.height !== customBackgroundDimensions.height) {
                    reject("size");
                }
            } else if (type === UpdateImageTypes.profile) {
                if (img.width !== customLogoDimensions.width || img.height !== customLogoDimensions.height) {
                    reject("size");
                }
            }

            if (img.width > maxPreviewWidth) {
                canvas.width = img.width;
                canvas.height = img.height;

                let context = canvas.getContext("2d");
                if (context) {
                    canvas.height = type === UpdateImageTypes.profile ? customLogoDimensions.height : customBackgroundDimensions.height;
                    canvas.width = type === UpdateImageTypes.profile ? customLogoDimensions.width : customBackgroundDimensions.width;
                    context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                    context.drawImage(img, 0, 0, canvas.width, canvas.height);
                }
                thumbBase64 = canvas.toDataURL();
            }

            const thumbTrim = thumbBase64.slice(thumbBase64.indexOf(`,`) + 1, thumbBase64.length);
            const data: FileData = {
                originalHeight: img.height,
                originalWidth: img.width,
                thumbnailBase64: thumbBase64,
                thumbnailOriginSize: atob(thumbTrim).length
            };
            resolve(data);
        };

        img.src = dataUrl;
    });
};

export const createVideoThumbnail = (dataUrl: string): Promise<FileData> => {
    let video = document.createElement("video");

    return new Promise<FileData>((resolve, reject) => {
        video.onerror = (err: Event | string) => {
            reject(err);
            console.warn(`Failed to create video thumbnail: `, err);
            notificationService.modalError(UploadMediaItemType, ERROR_THUMBNAIL_FAILED_007);
        };

        video.addEventListener("loadeddata", function() {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            if (video.videoWidth > maxPreviewWidth) {
                canvas.height = (video.videoHeight / video.videoWidth) * maxPreviewWidth;
                canvas.width = maxPreviewWidth;
            }
            if (context) {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
            }

            const thumbBase64 = canvas.toDataURL();
            const thumbTrim = thumbBase64.slice(thumbBase64.indexOf(`,`) + 1, thumbBase64.length);
            const data: FileData = {
                originalHeight: video.videoHeight,
                originalWidth: video.videoWidth,
                thumbnailBase64: thumbBase64,
                thumbnailOriginSize: atob(thumbTrim).length,
                duration: Math.ceil(video.duration)
            };

            resolve(data);
        });

        video.src = dataUrl;
    });
};

export const isEdsResizable = (link: string) => {
    const edsRoots = [`images-eds.xboxlive.com`, `images-eds-ssl.xboxlive.com`];
    for (let root of edsRoots) {
        if (link.indexOf(root) !== -1) {
            return true;
        }
    }
    return false;
};

export const getWebLink = (productId: string, gameTitle: string = "p") => {
    let productTitle = gameTitle.substring(0, 30);
    productTitle = gameTitle.replace(/ /g, "-");
    productTitle = gameTitle.replace(/[.*+?!@#$%^&*~{}() <>/|[\]\\]/g, "");

    return `https://www.microsoft.com/en-us/p/${productTitle}/${productId}?activetab=pivot%3Aoverviewtab`;
};

export const getAppLink = (productId: string) => {
    return `ms-windows-store://pdp/?ProductId=${productId}`;
};

export const fileSizeFormatter = (num: number) => {
    let n = num / 1024;
    if (n > 800) { // num > 800, decimal + Mb
        return (n / 1024).toFixed(2).toString() + "Mb";
    } else if (800 >= n && n >= 100) { // 800 >= num >= 100, no decimal + Kb
        return n.toFixed(0).toString() + "Kb";
    } else { // 100 > num, decimal + Kb
        return n.toFixed(2).toString() + "Kb";
    }
};
