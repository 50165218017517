import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withCookies, Cookies } from "react-cookie";
import { User } from "@xbox/social-core";
import { AuthConnectionState, AuthActions, ReduxState } from "@xbox/social-redux";

export interface AuthValidorProps {
    currentUser: User;
    currentConnectionState: AuthConnectionState;
    checkIfSignedIn: (payload: Cookies) => void;
    navigateToLogin: () => void;
    cookies: Cookies;
}

export interface AuthValidatorState {
    validated: boolean;
}

function authValidor(Container: any) {
    return class extends React.Component<AuthValidorProps, AuthValidatorState> {

    constructor(props: AuthValidorProps) {
            super(props);
            this.state = {
                validated: false
            };
        }

        componentDidMount() {
            if (!this.props.currentUser) {
                this.props.checkIfSignedIn(this.props.cookies);
            }
        }

        componentDidUpdate(prevProps: Readonly<AuthValidorProps>): void {
            if (this.props.currentConnectionState === AuthConnectionState.loggedOut) {
                this.props.navigateToLogin();
            }
        }

        render() {
            if (this.props.currentUser) {
                return <Container {...this.props} />;
            } else {
                return (<div />);
            }
        }
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        navigateToLogin: () => dispatch(push("/")),
        checkIfSignedIn: (payload: Cookies) => dispatch(AuthActions.checkIfSignedIn(payload)),
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return {
        currentConnectionState: state.authentication.currentState,
        currentUser: state.authentication.currentUser
    };
};

export default function AuthValidator(container: any) {
    return withCookies(connect(
        mapStateToProps,
        mapDispatchToProps
    )(authValidor(container)));
}
