"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routerReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var INITIAL_STATE = Immutable({});
exports.routerReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).build();