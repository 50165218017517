"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userMediaTitleReducer = void 0;

var Immutable = require("seamless-immutable");

var redux_ts_simple_1 = require("redux-ts-simple");

var MediaHubActions_1 = require("../../actions/MediaHubActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.userMediaTitleReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(MediaHubActions_1.MediaHubActions.getUsersMediaTitlesCompleted, function (state, action) {
  return state.merge({
    data: action.payload
  });
}).build();