"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.channelsReducer = void 0;

var _ = require("lodash");

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({
  fetchInProgress: false
});
var indexedSettings = ["feedChannels", "chatChannels"];
exports.channelsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clearClubChannel, function (state, action) {
  return state.setIn(["selectedChannel"], undefined);
}).on(ClubListActions_1.ClubActions.clubChannelSelected, function (state, action) {
  var channel = _.cloneDeep(action.payload);

  channel.type = action.payload["__typename"] === "FeedChannel" ? "feed" : "chat";
  channel.id = channel.id.toString();
  return state.setIn(["selectedChannel"], channel);
}).on(ClubListActions_1.ClubActions.updateClubSettings, function (state, action) {
  if (indexedSettings.indexOf(action.payload.updatedProperty[0]) !== -1) {
    var updatePath = action.payload.updatedProperty;
    updatePath.splice(0, 2);
    return state.setIn(["selectedChannel"].concat(updatePath), action.payload.updatedValue);
  }

  return state;
}).on(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted, function (state, action) {
  var feedChannels = _.cloneDeep(action.payload);

  _.map(feedChannels, function (item) {
    return _.assign(item, {
      type: "feed"
    });
  });

  return state.setIn(["feedChannels"], feedChannels);
}).on(ClubListActions_1.ClubActions.deleteClubChannelComplete, function (state, action) {
  if (state.feedChannels) {
    var feedChannels = _.cloneDeep(state.feedChannels);

    var channelToDelete_1 = action.payload.channelId;
    var index = state.feedChannels.findIndex(function (c) {
      return c.id === channelToDelete_1;
    });

    if (index > 0) {
      feedChannels.splice(index, 1);
      return state.setIn(["feedChannels"], feedChannels);
    }
  }

  return state;
}).build();