
import styled from "styled-components";
import { media } from "../../../common/Generic.SnapPoints";
import { Icon } from "../../../common/GenericElements";

export const SettingsContainer = styled.div`
    padding: 24px;

    //mobile
    ${media.small`
        padding: 0;
    `}
`;

export const SettingsColumn = styled.div`
    float: left;
    width: 50%;
    ${media.medium`width: 50%;`}
    ${media.small`width: 100%;`}
    ${media.xSmall`width: 100%;`}
`;

export const GroupName = styled.p`
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    cursor: pointer;
`;

export const ChannelTag = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin-right: 8px;
    padding-left: 15px;
    font-size: 16px !important;
    width: 100%;
`;

export const ArrowIcon = styled(Icon)`
    font-size: 0.8em;
    margin: -0.5em;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    float: right;
    margin-bottom: 5px;
`;

export const ChatIcon = styled(Icon)`
    font-size: 0.7em;
    margin-left: -0.8em;
    margin-right: -0.6em;
`;

export const ChannelContainer = styled.div`
    margin: 5px 5px 5px 0;
    cursor: pointer;
`;
