"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartyActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var PartyActions;

(function (PartyActions) {
  PartyActions.startParty = redux_ts_simple_1.createAction("PARTY::CREATE_PARTY");
  PartyActions.leaveParty = redux_ts_simple_1.createAction("PARTY::LEAVE_PARTY");
  PartyActions.joinByGamertag = redux_ts_simple_1.createAction("PARTY::JOIN_PARTY_BY_GAMERTAG");
  PartyActions.partyInProgress = redux_ts_simple_1.createAction("PARTY::PARTY_IN_PROGRESS");
  PartyActions.remoteStreamAdded = redux_ts_simple_1.createAction("PARTY::REMOTE_STREAM_ADDED");
  PartyActions.membersAdded = redux_ts_simple_1.createAction("PARTY::MEMBERS_ADDED");
  PartyActions.membersRemoved = redux_ts_simple_1.createAction("PARTY::MEMBERS_REMOVED");
  PartyActions.inviteFriend = redux_ts_simple_1.createAction("PARTY::INVITE_FRIEND");
  PartyActions.inviteByGamertag = redux_ts_simple_1.createAction("PARTY::INVITE_GAMERTAG");
  PartyActions.inviteSent = redux_ts_simple_1.createAction("PARTY::INVITE_SENT");
})(PartyActions = exports.PartyActions || (exports.PartyActions = {}));