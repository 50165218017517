var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ActivityFeed } from "@xbox/social-core";
import { feedViewerRedux } from "@xbox/social-redux";

function coreCommentContainer(commentResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.deleteItem = function (item, shouldBan) {
          if (shouldBan) {
            _this.actOnItem(ActivityFeed.AdminActionTypes.DeleteAndBan, item);
          } else {
            _this.actOnItem(ActivityFeed.AdminActionTypes.Delete, item);
          }
        };

        _this.addToModQ = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.AddToModQ, item);
        };

        _this.report = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Report, item);
        };

        _this.actOnItem = function (action, item) {
          var payload = {
            action: action,
            item: item,
            feedItem: _this.props.feedItem,
            clubId: _this.props.clubId
          };

          _this.props.actOnComment(payload);
        };

        return _this;
      }

      class_1.prototype.render = function () {
        var adminActions = {
          delete: this.deleteItem,
          report: this.report,
          addToModQ: this.addToModQ
        };
        return commentResolver.getCommentContainer(this.props.item, adminActions, this.props.showAdminAction);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return {};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    actOnComment: function actOnComment(payload) {
      return dispatch(feedViewerRedux.actions.adminActOnCommentItem(payload));
    }
  };
};

export function CoreCommentContainer(commentResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreCommentContainer(commentResolver));
}