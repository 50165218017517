import * as React from "react";
import * as styles from "./Popup.Styles";

export const buildMenuItem = (clickHandler: (value: any) => void, value: any, isActive: boolean, displayElement: JSX.Element, key: number ) => {
    return (
        <styles.MenuItem
            key={key}
            onClick={() => clickHandler(value)}
            className={`PopupMenuItem${isActive ? " active" : ""}`}
        >
            {displayElement}
        </styles.MenuItem>
    );
};

interface PopupProps {
    type: "hover" | "click";
    seedElement?: JSX.Element;
    stateBasedSeedElement?: (active: boolean) => JSX.Element | null;
    popupMenuItems: JSX.Element[];
}

interface PopupState {
    showPopup: boolean;
}

export class Popup extends React.Component<PopupProps, PopupState> {
    constructor(props: any) {
        super(props);
        this.state = ({
            showPopup: false
        });
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.closePopup);
    }

    openPopup() {
        this.setUpClickToggle();
        this.setState({showPopup: true});
    }

    closePopup = () => {
        this.setState({showPopup: false});
        window.removeEventListener("click", this.closePopup);
    }

    togglePopup = () => {
        this.setUpClickToggle();
        this.setState({showPopup: !this.state.showPopup});
    }

    setUpClickToggle = () => {
        if (this.props.type !== "click") {
            return;
        }
        // using a time out so that we can be sure that the proper digest cycle of opening
        // the popup has gone through
        if (!this.state.showPopup) {
            setTimeout( () => window.addEventListener("click", this.closePopup), 0);
        } else {
            setTimeout( () => window.removeEventListener("click", this.closePopup), 0);
        }
    }

    showPopupWindow = () => {
        return this.state.showPopup
               ? <styles.PopupWrapper className="PopupWindow">{this.props.popupMenuItems}</styles.PopupWrapper>
               : null;
    }

    getPopupMenu = (seedElement: JSX.Element) => {
        return (
            <div onMouseLeave={() => this.closePopup()} onClick={this.closePopup}>
                <div onMouseEnter={() => this.openPopup()}>
                    {seedElement}
                </div>
                {this.showPopupWindow()}
            </div>
        );
    }

    getDropdownMenu = (seedElement: JSX.Element) => {
        return (
            <div>
                <div onClick={this.togglePopup}>
                    {seedElement}
                </div>
                {this.showPopupWindow()}
            </div>
        );
    }
    render() {
        let seed = this.props.stateBasedSeedElement
                   ? this.props.stateBasedSeedElement(this.state.showPopup)
                   : this.props.seedElement ? this.props.seedElement : undefined;

        if (!seed) {
            return null;
        }

        return this.props.type === "hover"
                ? this.getPopupMenu(seed)
                : this.getDropdownMenu(seed);
    }
}
