"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var SocialActions;

(function (SocialActions) {
  SocialActions.followUser = redux_ts_simple_1.createAction("SOCIAL::FOLLOW_USER");
})(SocialActions = exports.SocialActions || (exports.SocialActions = {}));