import * as React from "react";
import { Person, edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { GetDateTimeDiff } from "../../Utils";
import ProfileCard from "../../components/profileCard/ProfileCard";
import * as styles from "./GamerpicListItem.Styles";
import Util from "../Util";

interface GamerpicListItemProps {
    person?: Person;
    displayPicUrl?: string;
    displayPicName?: string;
    itemPostDate?: Date;
    itemShortDescription?: string;
}
interface GamerpicListItemState {
    isProfileVisible: boolean;
  }

export class GamerpicListItem extends React.Component<GamerpicListItemProps, GamerpicListItemState> {
    listItemRefs: React.RefObject<HTMLLIElement>[] = [];
    constructor(props: GamerpicListItemProps) {
        super(props);
        this.state = {
            isProfileVisible: false
          };
        this.listItemRefs = [];

    }

    getListItemElement(displayPicUrl: string, displayName: string): JSX.Element {
        let description = this.props.itemShortDescription ? (<styles.ShortDescription>{this.props.itemShortDescription}</styles.ShortDescription>) : null;
        let postDate = this.props.itemPostDate ? (GetDateTimeDiff(this.props.itemPostDate)) : null;

        return (
            <styles.PersonListItemDiv>
                <styles.AvatarDiv>
                    <styles.Gamerpic className="Gamerpic" src={edsImageResizer(Util.ensureSSLImage(displayPicUrl), EdsImageSize._320x320)} />
                </styles.AvatarDiv>
                <styles.InfoContainerDiv onKeyDown={this.handleKeyDown} className="Gamerpic-Info" tabIndex={0} aria-label={displayName} aria-haspopup={true}>
                    <styles.GamerTag className="Gamerpic-Tag">{displayName}{description}</styles.GamerTag>
                    <styles.DateContainer className="Gamerpic-Date">{postDate}</styles.DateContainer>
                </styles.InfoContainerDiv>
            </styles.PersonListItemDiv>
        );
    }

    getProfileElement(): JSX.Element {
        if (this.props.person) {
            return (
            <ProfileCard
                person={this.props.person}
                key={this.props.person.gamertag}
                element={this.getListItemElement(this.props.person.displayPicRaw, this.props.person.gamertag)}
            />);
        }

        let personImage = this.props.displayPicUrl ? this.props.displayPicUrl : "";
        let personName = this.props.displayPicName ? this.props.displayPicName : "";
        return this.getListItemElement(personImage, personName);
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement.classList.contains("Gamerpic-Info")) {
            if (event.key === "Enter") {
                setTimeout(() => {
                    const dropdownElement = document.querySelector(".ant-dropdown-trigger") as HTMLElement;
                    dropdownElement.focus();
                }, 500);
                const mouseOverEvent = new MouseEvent("mouseover", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                activeElement.dispatchEvent(mouseOverEvent);
            }
        }
    }

    render() {
        return (
            <div className="GamerpicListItem">
                {this.getProfileElement()}
            </div>
        );
    }
}
