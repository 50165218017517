"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryLogger = void 0;

var inversify_1 = require("inversify");

var ILogger_1 = require("./ILogger");

var InMemoryLogger =
/** @class */
function () {
  function InMemoryLogger() {
    var _this = this; // logger method that captures the requested log level lines


    this.internalLogger = function (logType, args) {
      var payload = Array.prototype.slice.apply(args).join(" ");
      var message = logType.toUpperCase() + ":" + Date.now() + "::" + payload; // if we are full at capacity remove the oldest entry and add one more

      if (_this.currentSize === _this.bufferSize) {
        _this.buffer.shift();
      } else {
        _this.currentSize++;
      }

      _this.buffer.push(message);
    };

    this.currentSize = 0;
    this.bufferSize = 2000; // lines of logs.

    this.buffer = [];
  }

  InMemoryLogger.prototype.warn = function (message) {
    var optionalParams = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }

    console.warn(message, optionalParams);
  };

  InMemoryLogger.prototype.error = function (message) {
    var optionalParams = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }

    console.error(message, optionalParams);
  };

  InMemoryLogger.prototype.info = function (message) {
    var optionalParams = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }

    console.info(message, optionalParams);
  };

  InMemoryLogger.prototype.log = function (message) {
    var optionalParams = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }

    console.log(message, optionalParams);
  };

  InMemoryLogger.prototype.start = function (bufferSize, logLevel) {
    if (bufferSize) {
      this.bufferSize = bufferSize;
    }

    if (!window) {
      return;
    }

    var console = window.console;

    if (!console) {
      return;
    } // capture callbacks and logger level for the
    // function monitor of the logs


    var callback = this.internalLogger; // method to hook the console logs

    function methodInterceptor(method) {
      var original = console[method];

      console[method] = function () {
        var logtType = method;
        callback(logtType, arguments);

        if (original.apply) {
          // Do this for normal browsers
          original.apply(console, arguments);
        } else {
          // Do this for Edge
          var message = Array.prototype.slice.apply(arguments).join(" ");
          original(message);
        }
      };
    } // hook all the methods depending on the level.


    var methods = logLevel ? logLevel : ILogger_1.LoggerLevel.Error;
    ;

    for (var i = 0; i < methods.length; i++) {
      methodInterceptor(methods[i]);
    }
  }; // This will generate the logs using Based64 encode to send them over the wire


  InMemoryLogger.prototype.dump = function () {
    return btoa(this.buffer.join("\n"));
  };

  InMemoryLogger = __decorate([inversify_1.injectable(), __metadata("design:paramtypes", [])], InMemoryLogger);
  return InMemoryLogger;
}();

exports.InMemoryLogger = InMemoryLogger;