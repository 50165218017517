"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubActivityReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({
  fetchInProgress: false
});
exports.clubActivityReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubFeedFetchInProgress, function (state, action) {
  return state.merge({
    fetchInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.clubFeedFetchCompleted, function (state, action) {
  return state.merge({
    clubActivities: action.payload
  }, {
    deep: true
  });
}).build();