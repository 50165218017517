"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefreshXboxTokenBase = void 0;

var http_1 = require("@xbox/http");

var Constants_1 = require("../../Constants");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var XalHttpRequest_1 = require("../../XalHttpRequest");

var XboxTokenData_1 = require("../../XboxTokenData");

var OperationBase_1 = require("../OperationBase");

var RefreshXboxTokenBase =
/** @class */
function (_super) {
  __extends(RefreshXboxTokenBase, _super);

  function RefreshXboxTokenBase(telemetryClient, tokenStackComponents, httpClient, area, requestPath, refreshingToken) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.telemetryClient = telemetryClient;
    _this.tokenStackComponents = tokenStackComponents;
    _this.httpClient = httpClient;
    _this.area = area;
    _this.requestPath = requestPath;
    _this.refreshingToken = refreshingToken;
    Utils_1.assert(!!refreshingToken);
    return _this;
  }

  Object.defineProperty(RefreshXboxTokenBase.prototype, "deviceIdentity", {
    get: function get() {
      return this.tokenStackComponents.xboxCache.deviceIdentity;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(RefreshXboxTokenBase.prototype, "config", {
    get: function get() {
      return this.tokenStackComponents.config;
    },
    enumerable: false,
    configurable: true
  });

  RefreshXboxTokenBase.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var request, requestBody, nsalEntry, response, tokenData, error_1, httpBasedError, tokenData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.xalTrace(Utils_1.TraceLevel.Important, this.area + " token fetch started.");
            request = new XalHttpRequest_1.XalHttpRequest(this.httpClient, http_1.Http.HttpRequestMethod.Post, this.requestPath);
            request.setHeader(Constants_1.HeaderKeys.ContentType, Constants_1.HeaderValues.ApplicationJsonUtf8);
            request.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            request.setHeader(Constants_1.HeaderKeys.XblContractVersion, "1");
            return [4
            /*yield*/
            , this.buildRequestBody()];

          case 1:
            requestBody = _a.sent();

            if (requestBody) {
              request.setBody(requestBody);
            }

            nsalEntry = this.tokenStackComponents.nsal.lookup(this.requestPath);
            if (!nsalEntry.Policy) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , request.signRequest(this.deviceIdentity.key, nsalEntry.Policy)];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 4];

          case 3:
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "NSAL indicates not signing token request");
            _a.label = 4;

          case 4:
            return [4
            /*yield*/
            , request.execute()];

          case 5:
            response = _a.sent();
            if (!Utils_1.isHttpSuccesStatusCode(response.status)) return [3
            /*break*/
            , 10];
            tokenData = XboxTokenData_1.XboxTokenData.deserializeTokenField(response.data);
            Utils_1.assert(!!tokenData); // If this token was a ttoken, the title ID field will be non-zero. If the title ID
            // is present, we should make sure it matches the title ID passed into XAL init

            if (tokenData.titleId && tokenData.titleId !== this.config.titleId) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Title ID returned by XAS call '" + tokenData.titleId + "' does not match title ID in XAL config '" + this.config.titleId + "'");
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "The title ID passed in does not match the title ID associated with the client ID."));
              return [2
              /*return*/
              ];
            }

            _a.label = 6;

          case 6:
            _a.trys.push([6, 8,, 9]);

            return [4
            /*yield*/
            , this.tokenStackComponents.xboxCache.writeToken(this.refreshingToken, tokenData)];

          case 7:
            _a.sent();

            return [3
            /*break*/
            , 9];

          case 8:
            error_1 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Warning, this.area + " token update failed: " + error_1 + ". Ignoring error and continuing.");
            return [3
            /*break*/
            , 9];

          case 9:
            Utils_1.xalTrace(Utils_1.TraceLevel.Important, this.area + " token fetch done.");
            this.succeed(this.refreshingToken);
            return [2
            /*return*/
            ];

          case 10:
            Utils_1.xalTrace(Utils_1.TraceLevel.Important, this.area + " token fetch failed with HTTP status " + response.status);
            httpBasedError = this.interpretHttpStatus(response.status);

            if (httpBasedError) {
              this.fail(httpBasedError);
              return [2
              /*return*/
              ];
            }

            if (response.data) {
              try {
                tokenData = XboxTokenData_1.XboxTokenData.deserializeXerrResponse(response.data);
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "XErr was received from token refresh. XErr: " + tokenData.xerr + " Identity:'" + tokenData.xerrIdentity);
                this.telemetryClient.instrumentAppError(this.area, "XErr received from token refresh. " + tokenData.xerr, ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.Unknown);
                this.refreshingToken.tokenData = tokenData;
                this.succeed(this.refreshingToken);
                return [2
                /*return*/
                ];
              } catch (error) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Failed to parse result of unsuccessful token fetch: " + error);
              }
            } // If we've made it here, we received an http error without an Xerr


            this.telemetryClient.instrumentAppError(this.area, "Token refresh received error with no Xerr", ITelemetryClient_1.ErrorLevel.Error, XalInternalErrorType_1.XalInternalErrorType.NetworkError);
            this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "Token refresh received error with no Xerr"));
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return RefreshXboxTokenBase;
}(OperationBase_1.OperationBase);

exports.RefreshXboxTokenBase = RefreshXboxTokenBase;