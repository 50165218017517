"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.titlesDenormalizer = exports.titlesNormalizer = void 0;

var normalizr_1 = require("normalizr"); // schema definitions


var title = new normalizr_1.schema.Entity("titles", undefined, {
  idAttribute: "titleId"
});
var titleList = [title];

var titlesNormalizer = function titlesNormalizer(result) {
  return normalizr_1.normalize(result, titleList);
};

exports.titlesNormalizer = titlesNormalizer;

var titlesDenormalizer = function titlesDenormalizer(titleIds, entities) {
  return normalizr_1.denormalize(titleIds, titleList, entities);
};

exports.titlesDenormalizer = titlesDenormalizer;