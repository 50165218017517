import * as React from "react";
import { List } from "antd";
import { Mediahub, ContentLocatorType } from "@xbox/social-core";
import * as style from "../../FeedTools.Styles";
import { Button } from "../../../../common/generic/Button";
import { MediaType } from "../../FeedToolsTypes";
import { Localize } from "../../../../common/translation/Localize";
import { getThumbnailLocator } from "../../Utils";

interface ScreenItemsProps {
    screens?: Mediahub.MediahubSearchResult;
    onClick: (item: Mediahub.MediahubItem, itemType: MediaType) => void;
    selectedItemId: string | null;
}

export const ScreenItems = (props: ScreenItemsProps) => {
    if (!props.screens) {
        return (
            <div>
                {Localize("postPreview.noData")}
            </div>
        );
    }

    return (
            <List
                grid={{ gutter: 12, xs: 2, sm: 3, md: 4 }}
                dataSource={props.screens.values}
                loading={false}
                renderItem={(item: Mediahub.MediahubItem) => {
                    let thumbUri = getThumbnailLocator(item.contentLocators, ContentLocatorType.ThumbSmall);
                    return (
                        <List.Item>
                            <Button
                                onClick={() => props.onClick(item, MediaType.Screenshot)}
                                buttonType={props.selectedItemId === item.contentId ? "primary" : "default"}
                                style={{width: "100%"}}
                            >
                                <style.XblItemTitle>{item.titleName}</style.XblItemTitle>
                                <style.ScreenItemContainer>
                                    <style.ScreenItemImage src={thumbUri} alt={item.titleName} />
                                </style.ScreenItemContainer>
                                <div>{item.resolutionWidth && item.resolutionHeight ? `${item.resolutionWidth}x${item.resolutionHeight}` : ""}</div>
                            </Button>
                        </List.Item>
                    );
                }}
            />
    );
};
