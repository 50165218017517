import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import { Person, User } from "@xbox/social-core";
import { PartyActions, peopleRedux, ReduxState } from "@xbox/social-redux";
import { push } from "react-router-redux";
import { ClickParam } from "antd/lib/menu";
import { telemetryService } from "../index";
import { Icon, GenericEmptyState, GenericLoading } from "../common/GenericElements";
import { Glyphs } from "../common/Generic.Icons";
import * as Styles from "./people/PeopleContainer.Styles";
import { InfiniteScrollerSelector } from "../components/infiniteScrollers/InfiniteScrollerSelector";
import { ScrollType } from "../components/infiniteScrollers/base/InfiniteScrollTypes";
import { Localize } from "../common/translation/Localize";

interface FriendsContainerProps {
    currentUser: User;
    isLoading: boolean;
    friends: Person[];
    inParty: boolean;
    inviteSent: boolean;
}

interface FriendsDispatchProps {
    fetchFriends: () => void;
    inviteFriend: (xuid: string) => void;
    navigateHome: () => void;
    navigateUserPage: (userid: string) => void;
}

type FriendsProps = FriendsDispatchProps & FriendsContainerProps;

interface FeedContainerState {
    collapsed?: boolean;
    SelectedItem?: Person;
}

const EmptyState = () => {
    return <GenericEmptyState message={Localize("people.emptyState")} />;
};

class FriendsContainer extends React.Component<FriendsProps, FeedContainerState> {
    constructor(props: FriendsProps) {
        super(props);
    }

    onCollapse = (collapsed: boolean) => {
        console.log(collapsed);
        this.setState({ collapsed });
    }

    componentDidMount() {
        if (this.props.currentUser) {
            this.props.fetchFriends();
        } else {
            this.props.navigateHome();
        }
    }

    componentWillReceiveProps(nextProps: Readonly<FriendsProps>) {
        if (this.props.inviteSent === false && nextProps.inviteSent) {
            notification.open({
                message: "Invite sent",
                description: "",
                icon: <Icon type={Glyphs.MultiPlayer} />
            });
        }
    }

    onFriendsListClick = (param: ClickParam) => {
        let person = _.find(this.props.friends, { "xuid": param.key });
        telemetryService.trackEvent("FriendSelected", { "UserId": param.key });
        this.setState({ SelectedItem: person });
    }

    updateMethod = () => {
        return Promise.resolve(false);
    }

    getFriendCard = (friend: Person) => {
        let navigagteToUser = this.props.navigateUserPage;
        return (
            <Styles.ItemCard key={friend.displayName} onClick={() => navigagteToUser(friend.xuid)}>
                <Styles.ImageDivContainer style={{ float: "left" }}>
                    <Styles.UserAvatar src={friend.displayPicRaw} />
                </Styles.ImageDivContainer>

                <Styles.MainDivContainer>
                    <Styles.FriendPostTextDivContainer>
                        <Styles.GamerTag>{friend.gamertag}</Styles.GamerTag>
                        <Styles.UserName>{friend.displayName}</Styles.UserName>
                    </Styles.FriendPostTextDivContainer>
                    <Styles.SocialButtonDiv>
                        <Styles.SocialButton>Follow</Styles.SocialButton>
                        <Styles.SocialButton>Chat</Styles.SocialButton>
                    </Styles.SocialButtonDiv>
                </Styles.MainDivContainer>

                <Styles.SocialIconDiv>
                    <Icon type={Glyphs.Xenon} />
                    <Icon type={Glyphs.FacebookLogo} />
                    <Icon type={Glyphs.SmartGlassGamerScore} />
                    <Icon type={Glyphs.WindowsLogo} />
                </Styles.SocialIconDiv>
                <Styles.FollowInfoDiv>
                    <div>{friend.detail && friend.detail.followerCount ? `${friend.detail.followerCount} Followers` : 0}</div>
                    <div>{friend.detail && friend.detail.followingCount ? `${friend.detail.followingCount} Following` : 0}</div>
                </Styles.FollowInfoDiv>
            </Styles.ItemCard>
        );
    }

    render() {
        telemetryService.trackPageView("FriendsList");
        if (this.props.isLoading) {
            return (<GenericLoading />);
        }

        let friendsList: any[] = this.props.friends.map(friend => this.getFriendCard(friend));
        return (
            <InfiniteScrollerSelector
                scrollerType={ScrollType.ListStrict}
                listData={friendsList}
                updateMethod={this.updateMethod}
                emptyState={EmptyState}
                overscanRowCount={5}
            />
        );
    }
}

const mapStateToProps = (state: ReduxState): FriendsContainerProps => {
    const friendViewState = peopleRedux.selectors.getFriendContainerViewState(state);
    return {
        currentUser: friendViewState.currentUser,
        isLoading: friendViewState.isLoading,
        friends: friendViewState.friends,
        inParty: friendViewState.inParty,
        inviteSent: friendViewState.inviteSent
    };
};

const mapDispatchToProps = (dispatch: any): FriendsDispatchProps => {
    return {
        fetchFriends: () => dispatch(peopleRedux.actions.fetchFriends()),
        inviteFriend: (xuid: string) => dispatch(PartyActions.inviteFriend(xuid)),
        navigateHome: () => dispatch(push("/")),
        navigateUserPage: (userId: string) => dispatch(push(`/app/people/${userId}`))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FriendsContainer);
