import * as React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { RouterContextProps } from "@xbox/social-views";
import { ReduxState, ClubActions } from "@xbox/social-redux";

import { DiscussionContainerDiv } from "../../containers/Styles";
import ChannelPicker from "../../components/channels/ChannelPicker";
import ActivityFeed from "../activityfeed/ClubFeedContainer";
import { CombinedMenuWidth } from "../../common/Generic.SnapPoints";

interface DiscussionContainerDispatchProps {
    selectChannel: (channel: ClubHub.Channel) => void;
}

interface DiscussionContainerProps extends DiscussionContainerDispatchProps {
    club: ClubHub.Club;
    match: RouterContextProps;
    chatChannels: ClubHub.Channel[];
    feedChannels: ClubHub.Channel[];
    selectedChannel: ClubHub.Channel;
}

interface DiscussionContainerState {}

class DiscussionContainer extends React.Component<DiscussionContainerProps, DiscussionContainerState> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        let channelList = this.props.feedChannels.concat(this.props.chatChannels);
        let channelType = this.props.match.params[0];
        let channelId = this.props.match.params["channelId"];
        let paramSourcedChannel = [];

        if (channelId) {
            paramSourcedChannel = _.filter(channelList, (channel: ClubHub.Channel) => (channel.id.toString() === channelId && channel.type === channelType));
        } else {
            // Auto join default for channels of type feed or chat
            paramSourcedChannel = _.filter(channelList, (channel: ClubHub.Channel) => channel.isDefault === true && channel.type === channelType);
        }

        if ( paramSourcedChannel.length > 0 && (paramSourcedChannel[0].id !== this.props.selectedChannel.id) ) {
            this.props.selectChannel(paramSourcedChannel[0]);
        }
    }

    render() {
        let hideChannelPicker = window.innerWidth < CombinedMenuWidth;
        return (
            <DiscussionContainerDiv className="DiscussionContainer">
                {!hideChannelPicker && <ChannelPicker club={this.props.club}/>}
                {
                    // always return activity feed-- club chat is disabled
                    <ActivityFeed/>
                }
            </DiscussionContainerDiv>
        );
    }
}

const mapStateToProps = (state: ReduxState): any => {
    return {
        club: state.clubs.clubDetails.club,
        selectedChannel: state.channels.selectedChannel,
        feedChannels: state.clubs.clubDetails.club!.feedChannels,
        chatChannels: state.clubs.clubDetails.club!.chatChannels
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {
        selectChannel: (channel: ClubHub.Channel) => dispatch(ClubActions.clubChannelSelected(channel))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscussionContainer);
