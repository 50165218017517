import * as React from "react";
import { Tabs } from "antd";
import { Mediahub } from "@xbox/social-core";
import { FetchMediaState } from "@xbox/social-redux";
import { PostHydrationDataStates } from "@xbox/social-views";

import * as fts from "../../FeedTools.Styles";
import { GenericLoading } from "../../../../common/GenericElements";
import { MediaType } from "../../FeedToolsTypes";
import { ScreenItems } from "./ScreenItems";
import { ClipItems } from "./ClipItems";
import { Localize } from "../../../../common/translation/Localize";
import { XBLMediaChoices } from "../../Utils";

const TabPane = Tabs.TabPane;

export interface XblContainerProps {
    itemSelected: (item: Mediahub.MediahubItem, itemType: MediaType) => void;
    hydroStates: PostHydrationDataStates;
    showItems: XBLMediaChoices;
}

export interface XblContainerState {
    selectedItemId: string | null;
}

export class XboxLibraryContainer extends React.Component<XblContainerProps, XblContainerState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedItemId: null,
        };
    }

    onItemClick = (item: Mediahub.MediahubItem, itemType: MediaType) => {
        this.props.itemSelected(item, itemType);
        this.setState({ selectedItemId: item.contentId });
    }

    getScreenshotsTab = (screensState: FetchMediaState) => {
        return (
            <TabPane tab={Localize("xblContainer.screens")} key="1">
                <fts.VScrollableDiv>
                    <ScreenItems
                        screens={screensState.mediaData}
                        onClick={this.onItemClick}
                        selectedItemId={this.state.selectedItemId}
                    />
                </fts.VScrollableDiv>
            </TabPane>
        );
    }

    getGameclipsTab = (gameclipsState: FetchMediaState) => {
        return (
            <TabPane tab={Localize("xblContainer.gameclips")} key="2">
                <fts.VScrollableDiv>
                    <ClipItems
                        clips={gameclipsState.mediaData}
                        onClick={this.onItemClick}
                        selectedItemId={this.state.selectedItemId}
                    />
                </fts.VScrollableDiv>
            </TabPane>
        );
    }

    render() {
        let gcState = this.props.hydroStates.gameclips;
        let scrState = this.props.hydroStates.screenshots;

        if ((this.props.showItems !== XBLMediaChoices.Gameclips && scrState.inProgress)
            || (this.props.showItems !== XBLMediaChoices.Screenshots && gcState.inProgress)
        ) {
            return <GenericLoading />;
        }

        let screenshotsTab = this.props.showItems !== XBLMediaChoices.Gameclips ? this.getScreenshotsTab(scrState) : null;
        let gameclipsTab = this.props.showItems !== XBLMediaChoices.Screenshots ? this.getGameclipsTab(gcState) : null;
        return (
            <Tabs defaultActiveKey="1">
                {screenshotsTab}
                {gameclipsTab}
            </Tabs>
        );
    }
}
