"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetTtoken = void 0;

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var XboxToken_1 = require("../../XboxToken");

var OperationBase_1 = require("../OperationBase");

var RefreshTtoken_1 = require("../xboxToken/RefreshTtoken");

var StepTracker_1 = require("../StepTracker");

var GetDtoken_1 = require("./GetDtoken");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["GetDtoken"] = "GetDtoken";
  Step["GetTtoken"] = "GetTtoken";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var GetTtoken =
/** @class */
function (_super) {
  __extends(GetTtoken, _super);

  function GetTtoken(telemetryClient, tokenStackComponents, httpClient, forceRefresh) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.tokenStackComponents = tokenStackComponents;
    _this.httpClient = httpClient;
    _this.forceRefresh = forceRefresh; // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL, this is to support anonymous Xtokens

    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.GetTtoken, Step.Start);
    return _this;
  }

  GetTtoken.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getDtoken()];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetTtoken.prototype.getDtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, dtoken;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.GetDtoken);
            operation = new GetDtoken_1.GetDtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, false // forceRefresh
            );
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            dtoken = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.GetDtoken);
            this.dtoken = dtoken;
            return [4
            /*yield*/
            , this.refreshTtoken()];

          case 2:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetTtoken.prototype.refreshTtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, _a, error_1, xalError;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.step.advance(Step.GetTtoken);
            this.ttoken = this.tokenStackComponents.xboxCache.getToken(XboxToken_1.IdentityType.Ttoken, this.tokenStackComponents.config.xboxLiveRelyingParty,
            /*subRelyingParty:*/
            "", "JWT" // Ttokens are always JWT tokens
            );
            Utils_1.assert(!!this.ttoken);
            if (!(!this.ttoken.isValid || this.forceRefresh)) return [3
            /*break*/
            , 5];
            operation = new RefreshTtoken_1.RefreshTtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, this.dtoken, this.ttoken);
            operation.execute();
            _b.label = 1;

          case 1:
            _b.trys.push([1, 3,, 4]);

            _a = this;
            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            _a.ttoken = _b.sent();

            if (!this.ttoken || !this.ttoken.isValid) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Ttoken was invalid after refreshing it");
            }

            this.succeed(this.ttoken);
            return [2
            /*return*/
            ];

          case 3:
            error_1 = _b.sent();
            xalError = error_1;

            if (xalError) {
              this.fail(xalError);
              return [2
              /*return*/
              ];
            } else {
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Unknown error occurred while refreshing Ttoken"));
              return [2
              /*return*/
              ];
            }

            return [3
            /*break*/
            , 4];

          case 4:
            return [3
            /*break*/
            , 6];

          case 5:
            this.succeed(this.ttoken);
            return [2
            /*return*/
            ];

          case 6:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return GetTtoken;
}(OperationBase_1.OperationBase);

exports.GetTtoken = GetTtoken;