import * as React from "react";
import { edsImageResizer, EdsImageSize, ContentLocatorType, ActivityFeed } from "@xbox/social-core";

import * as FeedItemStyles from "./base/FeedItem.Styles";
import { ActivityFeedItem, FeedItemProps } from "./base/ActivityFeedItem";
import { GenericVideoPlayer } from "../../common/GenericElements";
import { parseMediaLocator } from "./base/FeedItemsUtils";
import { PreviewActivityFeedItem, PreviewFeedItemProps } from "./base/PreviewActivityFeedItem";

export class DefaultFeedItem extends ActivityFeedItem {
    constructor(props: FeedItemProps) {
        super(props);
    }

    /*********************************************************************/
    /* Full Base Template (currently in Prod)
    /*********************************************************************/
    getFullTextContainer = (): JSX.Element | null => {
        return (
            <div>
                {this.getTextContainer(this.props)}
            </div>
        );
    }

    getFullFeedItemData = (): JSX.Element | null => {
        let preview = null;
        let locators: any[] = this.props.item.gameMediaContentLocators;

        if (locators) {
            let downloadLocator = parseMediaLocator(locators, ContentLocatorType.Download, "");
            let thumbnailLocator = parseMediaLocator(locators, ContentLocatorType.ThumbLarge, "/video-thumbnail-unavailable.png");

            switch (this.props.item.activityItemType) {
                case ActivityFeed.ActivityItemTypes.GameDVR:
                    preview = (
                        <FeedItemStyles.MediaContainer>
                            <GenericVideoPlayer
                                onload={this.props.onload}
                                posterUrl={thumbnailLocator.uri}
                                downloadUri={downloadLocator.uri}
                            />
                        </FeedItemStyles.MediaContainer>
                    );
                    break;
                case ActivityFeed.ActivityItemTypes.Screenshot:
                    let image = <FeedItemStyles.FeedMedia className="UserPostPreviewImage" onLoad={this.props.onload} alt="previewImage" src={edsImageResizer(downloadLocator["uri"], EdsImageSize._1280x720)} />;
                    preview = this.getImagePreview(image);
                    break;
                default:
                    preview = null;
            }
        } else if (this.props.item.activityItemType === ActivityFeed.ActivityItemTypes.Achievement && this.props.item.itemImage) {
            preview = <FeedItemStyles.FeedMedia className="UserPostPreviewImage" onLoad={this.props.onload} alt="previewImage" src={edsImageResizer(this.props.item.itemImage, EdsImageSize._1280x720)} />;
        }

        if (this.props.item.hasUgc) {
            return (
                <div>
                    <FeedItemStyles.TextContainer>{this.props.item.itemText}</FeedItemStyles.TextContainer>
                    {preview}
                </div>
            );
        }

        return preview;
    }

    /*********************************************************************/
    /* Inline Template (PPE usage)
    /*********************************************************************/
    getInlineImageUrl = () => {
        let url = "";
        let imageUrl = this.props.item.itemImage;
        let locators: any[] = this.props.item.gameMediaContentLocators;
        if (locators) {
            switch (this.props.item.activityItemType) {
                case ActivityFeed.ActivityItemTypes.GameDVR:
                    let thumbnailLocator = parseMediaLocator(locators, ContentLocatorType.ThumbLarge, "/video-thumbnail-unavailable.png");
                    url = thumbnailLocator.uri;
                    break;

                case ActivityFeed.ActivityItemTypes.Screenshot:
                    let downloadLocator = parseMediaLocator(locators, ContentLocatorType.Download, "");
                    url = edsImageResizer(downloadLocator["uri"], EdsImageSize._370x208);
                    break;

                default:
                    break;
            }
        } else if (imageUrl) {
            url = edsImageResizer(imageUrl, EdsImageSize._370x208);
        }

        return url;
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/

    // Fully covered by default ActivityFeedItem functions.
}

interface PreviewDefaultFeedItemProps extends PreviewFeedItemProps {
    imageUrl?: string;
    activityItemType?: string;
    downloadUri?: string;
}

export const PreviewDefaultPostFeedItem: React.SFC<PreviewDefaultFeedItemProps> = props => {
    let preview = <FeedItemStyles.FeedMedia alt="preview image" src={props.imageUrl} />;
    if (props.activityItemType === ActivityFeed.ActivityItemTypes.GameDVR) {
        preview = <GenericVideoPlayer posterUrl={props.imageUrl} downloadUri={props.downloadUri} />;
    }

    let previewContent =  (
        <div>
            <FeedItemStyles.TextContainer>{props.itemText} </FeedItemStyles.TextContainer>
            <FeedItemStyles.MediaContainer>{preview}</FeedItemStyles.MediaContainer>
        </div>
    );

    return <PreviewActivityFeedItem previewContent={previewContent} {...props} />;
};
