import * as React from "react";
import { edsImageResizer, EdsImageSize } from "@xbox/social-core";

import * as styles from "./GamerpicListItem.Styles";

interface ClubpicListItemProps {
    displayPicUrl: string;
    displayPicName: string;
}

export class ClubpicListItem extends React.Component<ClubpicListItemProps> {

    constructor(props: ClubpicListItemProps) {
        super(props);
    }

    render() {
        return (
            <div className="ClubpicListItem">
                <styles.ListItemDiv>
                    <styles.AvatarDiv>
                        <styles.Clubpic alt={this.props.displayPicName} src={edsImageResizer(this.props.displayPicUrl, EdsImageSize._100x100, "jpg")} />
                    </styles.AvatarDiv>
                    <styles.ClubInfoContainerDiv className="Clubpic-Info">
                        <styles.GamerTag title={this.props.displayPicName}>{this.props.displayPicName}</styles.GamerTag>
                    </styles.ClubInfoContainerDiv>
                </styles.ListItemDiv>
            </div>
        );
    }
}
