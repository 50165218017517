"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentsSaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var CommentsFeedNormalizer_1 = require("../reducers/feed/CommentsFeedNormalizer");

var ActivityFeedActions_1 = require("../actions/ActivityFeedActions");

var ClubListActions_1 = require("../actions/ClubListActions");

var CommentsSaga =
/** @class */
function () {
  function CommentsSaga(commentsService, telemetryService, activityFeedService, clubHubService, notificationService, fileSaver) {
    this.commentsService = commentsService;
    this.telemetryService = telemetryService;
    this.activityFeedService = activityFeedService;
    this.clubHubService = clubHubService;
    this.notificationService = notificationService;
    this.fileSaver = fileSaver;
    this.sagas = [this.watchCommentsFetch(), this.watchAdminActCommentItem(), this.watchCommentsExport(), this.watchPostComment(), this.watchCommentLock()];
  }

  CommentsSaga.prototype._fetchComments = function (action) {
    var result, cursor, comments, completedPayload, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchFeedCommentsInProgress(true))];

        case 1:
          _a.sent();

          this.telemetryService.trackEvent("FetchComments", {
            "feedItemId": action.payload.postId
          });
          return [4
          /*yield*/
          , this.commentsService.getComments(action.payload.postId, action.payload.numberOfItems, action.payload.cursor)];

        case 2:
          result = _a.sent();
          cursor = result.data.fetchCommentsByPost.cursor;
          comments = CommentsFeedNormalizer_1.commentsNormalizer(result.data.fetchCommentsByPost.items);
          completedPayload = {
            comments: comments,
            feedItemId: action.payload.postId,
            cursor: cursor
          };
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchFeedCommentsCompleted(completedPayload))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchFeedCommentsInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_1 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.fetchFeedCommentsInProgress(false))];

        case 6:
          _a.sent();

          console.warn("Unable to get comments: " + err_1);
          this.telemetryService.trackException(err_1, "FetchComments", {
            "feedItemId": action.payload.postId
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype._adminActOnCommentItem = function (action) {
    var commentItem, feedItem, commentDeleted, _a, rosterUpdate, reportItem, reportItem, err_2;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          _b.trys.push([0, 15,, 16]);

          commentItem = action.payload.item;
          feedItem = action.payload.feedItem;
          commentDeleted = {
            itemId: commentItem.id,
            feedItemRoot: feedItem.itemRoot
          };
          this.activityFeedService.clearMostRecentPolicy();
          _a = action.payload.action;

          switch (_a) {
            case social_core_1.ActivityFeed.AdminActionTypes.Delete:
              return [3
              /*break*/
              , 1];

            case social_core_1.ActivityFeed.AdminActionTypes.DeleteAndBan:
              return [3
              /*break*/
              , 4];

            case social_core_1.ActivityFeed.AdminActionTypes.AddToModQ:
              return [3
              /*break*/
              , 9];

            case social_core_1.ActivityFeed.AdminActionTypes.Report:
              return [3
              /*break*/
              , 11];
          }

          return [3
          /*break*/
          , 13];

        case 1:
          return [4
          /*yield*/
          , this.commentsService.deleteComment(feedItem.itemRoot, commentItem.id)];

        case 2:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.commentItemDeleted(commentDeleted))];

        case 3:
          _b.sent();

          this.notificationService.notifySuccess("CommentItemAdminAction");
          return [3
          /*break*/
          , 14];

        case 4:
          return [4
          /*yield*/
          , this.commentsService.deleteComment(feedItem.itemRoot, commentItem.id)];

        case 5:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.commentItemDeleted(commentDeleted))];

        case 6:
          _b.sent();

          rosterUpdate = {
            add: true,
            clubId: action.payload.clubId,
            role: social_core_1.ClubHub.ClubRoles.Banned,
            xuid: action.payload.item.author.xuid
          };
          return [4
          /*yield*/
          , this.clubHubService.addClubMemberRole(action.payload.clubId, action.payload.item.author.xuid, [social_core_1.ClubHub.ClubRoles.Banned])];

        case 7:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubRosterUpdateRoleProperty(rosterUpdate))];

        case 8:
          _b.sent();

          this.notificationService.notifySuccess("CommentItemAdminAction");
          return [3
          /*break*/
          , 14];

        case 9:
          reportItem = {
            targetXuid: action.payload.clubId,
            contentId: commentItem.path,
            contentType: social_core_1.ReportClubItemType.Comment
          };
          return [4
          /*yield*/
          , this.activityFeedService.reportToClub(reportItem, action.payload.clubId)];

        case 10:
          _b.sent();

          this.notificationService.notifySuccess("CommentItemAdminAction");
          return [3
          /*break*/
          , 14];

        case 11:
          reportItem = {
            clubId: action.payload.clubId,
            evidenceId: commentItem.id,
            feedbackContext: "Club",
            feedbackType: social_core_1.FeedbackType.UserContentComment
          };
          return [4
          /*yield*/
          , this.activityFeedService.reportToEnforcement(reportItem, action.payload.item.author.xuid)];

        case 12:
          _b.sent();

          this.notificationService.notifySuccess("CommentItemAdminAction");
          return [3
          /*break*/
          , 14];

        case 13:
          return [2
          /*return*/
          ];

        case 14:
          return [3
          /*break*/
          , 16];

        case 15:
          err_2 = _b.sent();
          console.warn("Unable to " + action.payload.action + " comment item, error: " + err_2);
          this.telemetryService.trackException(err_2, "CommentItemAdminAction", {
            "action": action.payload.action
          }, true);
          return [3
          /*break*/
          , 16];

        case 16:
          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype._exportComments = function (action) {
    var actionType, result, blob, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          actionType = "ExportComments";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          this.telemetryService.trackEvent(actionType, {
            "feedItemId": action.payload
          });
          return [4
          /*yield*/
          , this.commentsService.exportComments(action.payload)];

        case 2:
          result = _a.sent();
          blob = new Blob([result.data], {
            type: "text/plain;charset=utf-8"
          });
          this.fileSaver.saveAs(blob, "comments.csv");
          this.notificationService.notifySuccess(actionType);
          return [3
          /*break*/
          , 4];

        case 3:
          err_3 = _a.sent();
          console.warn("Unable to export comments: " + err_3);
          this.telemetryService.trackException(err_3, actionType, {
            "feedItemId": action.payload
          }, true);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype._postComment = function (action) {
    var actionType, addCommentResult, getCommentResult, addedComment, newComment, postCommentCompletedPayload, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          actionType = "PostComment";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 7,, 9]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedCommentInProgress(true))];

        case 2:
          _a.sent();

          this.telemetryService.trackEvent(actionType, {
            "feedItemId": action.payload.feedItemId
          });
          return [4
          /*yield*/
          , this.commentsService.postComment(action.payload.feedItemId, action.payload.commentText, action.payload.xuid)];

        case 3:
          addCommentResult = _a.sent();
          return [4
          /*yield*/
          , this.commentsService.getCommentsByIds(action.payload.feedItemId, addCommentResult.data.addComment.id)];

        case 4:
          getCommentResult = _a.sent();
          addedComment = getCommentResult.data.fetchComment;
          newComment = CommentsFeedNormalizer_1.commentsNormalizer([addedComment]);
          postCommentCompletedPayload = {
            comment: newComment,
            feedItemId: action.payload.feedItemId
          };
          this.activityFeedService.clearMostRecentPolicy();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedCommentCompleted(postCommentCompletedPayload))];

        case 5:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedCommentInProgress(false))];

        case 6:
          _a.sent();

          return [3
          /*break*/
          , 9];

        case 7:
          err_4 = _a.sent();
          console.warn("Unable to post comment item for feedItem id " + action.payload.feedItemId + ", error: " + err_4);
          this.telemetryService.trackException(err_4, actionType, {
            "feedItemId": action.payload.feedItemId
          }, true);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.postFeedCommentInProgress(false))];

        case 8:
          _a.sent();

          return [3
          /*break*/
          , 9];

        case 9:
          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype._loadCommentLock = function (action) {
    var result, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.loadCommentLockInProgress(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.commentsService.getIsCommentThreadLocked(action.payload.itemRoot)];

        case 2:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.loadCommentLockCompleted(result.data.locked))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.loadCommentLockInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_5 = _a.sent();
          console.warn("Unable to load comment lock state for feedItem id " + action.payload.itemRoot + ", error: " + err_5);
          this.telemetryService.trackException(err_5, "CommentItemLockStatus", {
            "feedItemId": action.payload.itemRoot
          }, true);
          return [4
          /*yield*/
          , effects_1.put(ActivityFeedActions_1.ActivityFeedActions.loadCommentLockInProgress(false))];

        case 6:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype.watchCommentsFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.fetchFeedComments.type, this._fetchComments.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype.watchAdminActCommentItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.adminActOnCommentItem.type, this._adminActOnCommentItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype.watchCommentsExport = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.exportFeedComments.type, this._exportComments.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype.watchPostComment = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.postFeedComment.type, this._postComment.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga.prototype.watchCommentLock = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ActivityFeedActions_1.ActivityFeedActions.loadCommentLock.type, this._loadCommentLock.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  CommentsSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.ICommentService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.IActivityFeedService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.IClubHubService)), __param(4, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(5, inversify_1.inject(social_core_1.XSocialSymbols.IFileSaver)), __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object])], CommentsSaga);
  return CommentsSaga;
}();

exports.CommentsSaga = CommentsSaga;