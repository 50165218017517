"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FollowUser = void 0;

var graphql_tag_1 = require("graphql-tag");

exports.FollowUser = graphql_tag_1.default(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nmutation FollowUser($requesterXuid: UserId!, $targetXuid: UserId!) {\n    followUser(requesterXuid: $requesterXuid, targetXuid: $targetXuid)\n}"], ["\nmutation FollowUser($requesterXuid: UserId!, $targetXuid: UserId!) {\n    followUser(requesterXuid: $requesterXuid, targetXuid: $targetXuid)\n}"])));
var templateObject_1;