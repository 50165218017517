import * as React from "react";
import {
    CellMeasurer,
    CellMeasurerCache,
    AutoSizer,
    WindowScroller,
    InfiniteLoader
} from "react-virtualized";
import { createCellPositioner, Positioner } from "react-virtualized/dist/es/Masonry";
import { MIN_INFINITE_BATCH_SIZE } from "./base/InfiniteScrollTypes";
import { MasonryStrickedSized } from "./base/MasonryStrickedSized";
import { BaseInfiniteScrollList, BaseInfiniteScrollProps } from "./base/BaseInfiniteScroller";

const CARD_WIDTH = 280;
const CARD_HEIGHT = 250;
const CARD_BEETWEEN_SPACE = 9;

export default class MasonryWindowScroller extends BaseInfiniteScrollList {
    protected readonly infiniteMansoryRef?: React.RefObject<MasonryStrickedSized>;
    protected cellPositioner: Positioner;

    constructor(props: BaseInfiniteScrollProps) {
        super(props);
        this.infiniteMansoryRef = React.createRef();

        let cache = new CellMeasurerCache({
            fixedHeight: true,
            defaultHeight: props.defaultHeight,
            fixedWidth: props.isFixedWidth || true,
        });
        this.cellPositioner = createCellPositioner({
            cellMeasurerCache: cache,
            columnCount: 1,
            columnWidth: CARD_WIDTH,
            spacer: CARD_BEETWEEN_SPACE,
        });

        this.setState({cache});

        this.onResize = this.onResize.bind(this);
        this.cellRenderer = this.cellRenderer.bind(this);
    }

    /*********************************************************************/
    /* Component life cycle
    /*********************************************************************/
    componentDidMount() {
        this.getScrollerElement();
    }

    componentWillUpdate(nextProps: any) {
        if (this.props.defaultHeight !== nextProps.defaultHeight) {
            this.setState({
                cache: new CellMeasurerCache({
                    defaultHeight: nextProps.defaultHeight || CARD_HEIGHT,
                    fixedWidth: nextProps.isFixedWidth || true,
                    fixedHeight: true
                }),
            });
        }
    }

    componentDidUpdate() {
        if (!this.loading && this.infiniteMansoryRef && this.infiniteMansoryRef.current) {
            this.infiniteMansoryRef.current.forceUpdate();
        }
        this.loading = false;
    }

    /****************************************************************/
    /* Masonry specific
    /****************************************************************/
    onResize(info: { height: number, width: number}) {
        // calculate column count (cards in a row)
        let columnCount = Math.floor((info.width + CARD_BEETWEEN_SPACE) / (CARD_WIDTH + CARD_BEETWEEN_SPACE));
        this.cellPositioner.reset({
            columnCount,
            columnWidth: CARD_WIDTH,
            spacer: CARD_BEETWEEN_SPACE
        });

        if (this.infiniteMansoryRef && this.infiniteMansoryRef.current) {
            this.infiniteMansoryRef.current.recomputeCellPositions();
        }
    }

    cellRenderer(info: { index: any, isScrolling: any, key: any, parent: any, style?: any }) {
        let source = this.props.listData[info.index];
        return (
          <CellMeasurer cache={this.state.cache} index={info.index} key={info.key} parent={info.parent}>
            {({ measure }) => (
                <div style={{...info.style, width: CARD_WIDTH}}>
                    {this.props.itemGenerator
                        ? this.props.itemGenerator(source, measure)
                        : source
                    }
                </div>
            )}
          </CellMeasurer>
        );
    }

    /*********************************************************************/
    /* Rendering
    /*********************************************************************/
    getScrollerContent = (onRowsRendered: any): JSX.Element => {
        return (
            <WindowScroller scrollElement={this.state.mainContentWindow}>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                    <AutoSizer
                        disableHeight={true}
                        onResize={this.onResize}
                        height={height}
                    >
                        {({ width }) => (
                            <MasonryStrickedSized
                                ref={this.infiniteMansoryRef}
                                autoHeight={true}
                                cellCount={this.props.listData.length}
                                cellMeasurerCache={this.state.cache}
                                cellPositioner={this.cellPositioner}
                                cellRenderer={this.cellRenderer}
                                onRowsRendered={onRowsRendered}
                                height={height}
                                scrollTop={scrollTop}
                                width={width}
                            />
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
    }
    render() {
        return (
            <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.loadMoreRows}
                rowCount={this.props.listData.length}
                minimumBatchSize={MIN_INFINITE_BATCH_SIZE}
            >
                {({onRowsRendered, registerChild}) => (
                    <WindowScroller scrollElement={this.state.mainContentWindow}>
                            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                            <AutoSizer
                                disableHeight={true}
                                onResize={this.onResize}
                                height={height}
                            >
                                {({ width }) => (
                                    <MasonryStrickedSized
                                        ref={this.infiniteMansoryRef}
                                        autoHeight={true}
                                        cellCount={this.props.listData.length}
                                        cellMeasurerCache={this.state.cache}
                                        cellPositioner={this.cellPositioner}
                                        cellRenderer={this.cellRenderer}
                                        onRowsRendered={onRowsRendered}
                                        height={height}
                                        scrollTop={scrollTop}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        )}
                    </WindowScroller>
                )}
            </InfiniteLoader>
        );
    }

}
