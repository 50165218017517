import * as React from "react";
import { LayoutContextProvider } from "./LayoutContext";

// wire the context to the props of the
export function withContext(Component: any) {
    return (props: any) => {
        return (
            <LayoutContextProvider.Consumer>
                  {({ isOpen, onToggleSider, toggleSlider, onThemeChange }) => {
                        return (
                            <Component
                                {...props}
                                isOpen={isOpen}
                                onToggleSider={onToggleSider}
                                toggleSlider={toggleSlider}
                                onThemeChange={onThemeChange}
                            />
                        );
                    }}
            </LayoutContextProvider.Consumer>
        );
    };
}
