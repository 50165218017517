import { ActivityFeed, ClubHub, User } from "@xbox/social-core";
import { UploadMediaItemsPayload, FetchMediaItemsPayload, BallotPayload } from "@xbox/social-redux";
import { PostHydrationDataStates } from "@xbox/social-views";

export enum MediaType {
    Screenshot = "Screenshot",
    Gameclip = "Gameclip",
}

export interface ClubCanActPermissions {
    post: boolean;
    postMediaFromDevice: boolean;
    postMediaFromXblLibrary: boolean;
    postStoreLink: boolean;
    postWebLink: boolean;
    schedulePost: boolean;
    pinPost: boolean;
    delete: boolean;
}

export interface CommonPostDetailsProps {
    handlePostClick: (item: ActivityFeed.UserPost, wasPinned: boolean, wasDisabled: boolean, wasScheduled: boolean, scheduledDateTime?: string) => void;
    handleStringValidation: (stringPayload: string[]) => void;
    handleLinkHydration: (webLink: string) => void;
    searchGameInMsCatalog: (gameName: string) => void;
    fetchMediaItems: (payload: FetchMediaItemsPayload, isVideo: boolean) => void;
    uploadMediaItems: (data: UploadMediaItemsPayload, isVideo: boolean) => void;
    getAllTitles: () => void;
    createBallot: (ballotPayload: BallotPayload) => void;
    clubId: string;
    club: ClubHub.Club;
    permissions?: ClubCanActPermissions;
    hydrationStates: PostHydrationDataStates;
    currentUser: User;
    selectedChannel?: ClubHub.Channel;
    forceSchedule: boolean;
}
