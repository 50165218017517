import styled from "styled-components";

export const HomeContainer = styled.div`
    overflow: auto;
    margin: 0 10px;
    height: 100%;
`;

export const HomeMenu = styled.ul`
    display: flex;
    flex: none;
    padding-top: 1em;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    padding-inline-start: 0;
`;

export const HomeMenuItem = styled.li`
    margin: 8px 20px 6px 0;

    a {
        font-size: 15px;
        text-decoration: none;
        color: ${props => props.theme.foregroundBrigth};
    }

    .active {
        border-bottom: 4px solid ${props => props.theme.blueRest};
        text-decoration: none;
        padding-bottom: 5px;
    }
`;
