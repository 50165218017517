var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ActivityFeed } from "@xbox/social-core";
import { PromiseCompletionSource } from "@xbox/framework";
import { feedViewerRedux, CancellationTokenSource } from "@xbox/social-redux";

function allPollPostsFeedContainer(resolver, EmptyState, LoadingState) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.doDelete = function (item) {
          var request = {
            action: ActivityFeed.AdminActionTypes.BallotPostDelete,
            item: item,
            clubId: item.timeline && item.timeline.timelineId ? item.timeline.timelineId : "",
            xuid: item.authorInfo.xuid
          };

          _this.props.actOnFeedItem(request);

          _this.feedItemsUpdated = new PromiseCompletionSource();
          return _this.feedItemsUpdated.promise;
        };

        _this.handleDelete = function (item) {
          _this.doDelete(item).then(function () {
            if (_this.props.clubId) {
              _this.updateFeedItems(_this.props.clubId);
            }
          });
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        if (this.props.clubId) {
          this.updateFeedItems(this.props.clubId);
        }
      };

      class_1.prototype.componentWillUnmount = function () {
        // On Unmount, cancel any outstanding request
        if (this.cancellationSource) {
          this.cancellationSource.cancel();
        }
      };

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.clubId && this.props.selectedChannel && this.props.selectedChannel.id !== prevProps.selectedChannel.id) {
          this.updateFeedItems(this.props.clubId);
        }
      };

      class_1.prototype.componentWillReceiveProps = function (newProps) {
        if (this.feedItemsUpdated && (this.props.results === undefined || this.props.results !== newProps.results)) {
          this.feedItemsUpdated.resolve(true);
        }
      };

      class_1.prototype.render = function () {
        var adminActions = {
          delete: this.handleDelete,
          pin: function pin() {},
          unpin: function unpin() {},
          report: function report() {},
          addToModQ: function addToModQ() {},
          disableComment: function disableComment() {},
          enableComment: function enableComment() {}
        };

        if (!this.props.completed) {
          return React.createElement(LoadingState, null);
        } else if (this.props.results && this.props.results.length > 0) {
          return resolver.getAllPollPostsPanel(this.props.openBallots || [], this.props.closedBallots || [], this.props.clubId, adminActions);
        } else {
          return React.createElement(EmptyState, null);
        }
      };

      class_1.prototype.updateFeedItems = function (clubId) {
        if (this.cancellationSource) {
          this.cancellationSource.cancel();
        }

        this.cancellationSource = new CancellationTokenSource();

        if (this.props.fetchBallotActivityFeed) {
          var request = {
            clubId: clubId,
            cancellationToken: this.cancellationSource.getToken(),
            numItems: 100,
            cursor: "",
            pinCount: 0,
            channelId: this.props.selectedChannel && !this.props.selectedChannel.isDefault ? this.props.selectedChannel.id : undefined
          };
          this.props.fetchBallotActivityFeed(request);
        }
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var activityFeed = feedViewerRedux.selectors.getBallotActivityFeed(state);
  return {
    results: activityFeed.results,
    openBallots: activityFeed.openBallots,
    closedBallots: activityFeed.closedBallots,
    completed: activityFeed.completed,
    selectedChannel: state.channels.selectedChannel
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchBallotActivityFeed: function fetchBallotActivityFeed(action) {
      return dispatch(feedViewerRedux.actions.fetchBallotFeedItems(action));
    },
    actOnFeedItem: function actOnFeedItem(payload) {
      return dispatch(feedViewerRedux.actions.adminActOnItem(payload));
    }
  };
};

export function AllPollPostsFeedContainer(resolver, EmptyState, LoadingState) {
  return connect(mapStateToProps, mapDispatchToProps)(allPollPostsFeedContainer(resolver, EmptyState, LoadingState));
}