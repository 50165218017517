import * as React from "react";
import { xboxColors } from "@xbox/social-core";
import { ClickHandler } from "@xbox/social-views";
import * as Styles from "./ColorPicker.Styles";

interface IColorPickerProps {
    onSelect: ClickHandler;
}

const ColorPicker: React.SFC<IColorPickerProps> = props => {
    return (
        <Styles.ColorsContainer>
            {xboxColors.map(color => {
                return <Styles.ColorLarge key={color.primaryColor} onClick={() => props.onSelect(color)} style={{ background: "#" + color.primaryColor }} />;
            })}
        </Styles.ColorsContainer>
    );
};

export default ColorPicker;
