import * as React from "react";
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import { ClubHub, edsImageResizer, EdsImageSize } from "@xbox/social-core";

import * as style from "./ClubViewer.Styles";
import LfgContainer from "../lfg/LfgContainer";
import ClubRosterContainer from "../clubadmin/ClubRosterContainer";
import ClubProfileContainer from "../clubdetails/ClubProfileContainer";
import ClubManager from "../clubmanager/ClubManagerContainer";
import DiscussionContainer from "../discussion/DiscussionContainer";
import FeedItemViewerContainer from "../feeditemviewer/FeedItemViewerContainer";
import { Localize } from "../../common/translation/Localize";
import { ImageFade } from "../../styles/ImageStyles";
import Util from "../../common/Util";

interface ClubViewerContainerProps {
    club: ClubHub.Club;
    isMember: boolean;
    isModerator: boolean;
    currentChannel?: ClubHub.Channel;
}

interface ClubViewerContainerState {}

export default class ClubViewer extends React.Component<ClubViewerContainerProps, ClubViewerContainerState> {
    constructor(props: any) {
        super(props);
    }

    getDataContainer (statId: string, value: number): JSX.Element {
        return (
            <style.DataContainerDiv>
                <style.DataTitle>{Localize(statId)}</style.DataTitle>
                <span>{value}</span>
            </style.DataContainerDiv>
        );
    }

    getNavigationItem(stringId: string, innerAppLink: string, defaultSelection: string = ""): JSX.Element {
        return (
            <style.ClubLevelItem>
                <NavLink
                    activeClassName="active"
                    to={`/app/${innerAppLink}${defaultSelection}`}
                    exact={!defaultSelection}
                    isActive={(match, location) => location.pathname.includes(innerAppLink)}
                >
                    {Localize(stringId)}
                </NavLink>
             </style.ClubLevelItem>
        );
    }

    getRosterVisibilityRules() {
        if (this.props.club.details.isOfficialClub) {
            return this.props.isModerator;
        }
        return this.props.club.settings!.roster.view!.canViewerAct;
    }

    render() {
        let details = this.props.club.details;
        let imageUrl = details.profile.displayImageUrl.value;
        let showRoster = this.getRosterVisibilityRules();

        return (
            <style.ClubViewerDiv className="ClubViewerDiv">
                <style.ClubHeader className="ClubHeader">
                    <style.ClubPicMainContainer>
                        <ImageFade src={edsImageResizer(Util.ensureSSLImage(imageUrl), EdsImageSize._100x100, "jpg")} style={{height: "100%"}}/>
                    </style.ClubPicMainContainer>

                    <style.InfoContainer >
                        <style.ClubName>{this.props.club.details.profile.name.value}</style.ClubName>
                        <div style={{display: "flex"}}>
                            {this.getDataContainer("clubDetails.hereNow", details.clubPresenceCount)}
                            {details.isOfficialClub !== true && this.getDataContainer("clubDetails.members", details.membersCount)}
                            {details.isOfficialClub !== true && this.getDataContainer("clubDetails.followers", details.followersCount)}
                        </div>
                    </style.InfoContainer>
                </style.ClubHeader>

                <style.ClubLevelMenu className="ClubLevelMenu">
                    {this.getNavigationItem("clubMenu.discussion", `${details.id}/discussion`, `/feed`)}
                    {this.getNavigationItem("clubMenu.lfg", `${details.id}/lfg`)}
                    {this.props.isModerator && this.getNavigationItem("clubMenu.manage", `${details.id}/manage`, `/modq`)}
                    {this.getNavigationItem("clubDetails.profileTab", `${details.id}/profile`)}
                    {showRoster && this.getNavigationItem("clubMenu.clubroster", `${details.id}/roster`)}
                </style.ClubLevelMenu>

                <Switch>
                    <Route path="/app/:id/discussion/(feed|chat)/:channelId?" component={DiscussionContainer}/>
                    <Route path="/app/:id/discussion/post/:formattedFeedId/:channelId?" component={FeedItemViewerContainer}/>
                    <Route path="/app/:id/lfg" component={LfgContainer}/>
                    <Route path="/app/:id/manage" render={() => <ClubManager club={this.props.club}/>}/>
                    <Route path="/app/:id/profile" component={ClubProfileContainer}/>
                    <Route path={"/app/:id/roster"} component={ClubRosterContainer} />
                </Switch>
            </style.ClubViewerDiv>
        );
    }
}
