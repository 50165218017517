"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaHubRedux = exports.uploadMediaToXbl = exports.getXblGameclips = exports.getXblScreenshots = void 0;

var _ = require("lodash");

var MediaHubActions_1 = require("../../actions/MediaHubActions");

var MediaHubReducers_1 = require("./MediaHubReducers"); // selectors


function getXblScreenshots(state) {
  return {
    inProgress: state.screenshots.inProgress,
    respondData: state.screenshots.mediaData
  };
}

exports.getXblScreenshots = getXblScreenshots;

function getXblGameclips(state) {
  return {
    inProgress: state.gameclips.inProgress,
    respondData: state.gameclips.mediaData
  };
}

exports.getXblGameclips = getXblGameclips;

function uploadMediaToXbl(state) {
  return {
    inProgress: state.upload.progressValue,
    respondData: state.upload.uploadData
  };
}

exports.uploadMediaToXbl = uploadMediaToXbl;

function filterForUserTitles(userTitles, allTitles) {
  return _.filter(allTitles, function (item) {
    return userTitles[item.titleId] > 0;
  });
}

exports.mediaHubRedux = {
  actions: __assign({}, MediaHubActions_1.MediaHubActions),
  reducer: MediaHubReducers_1.aggregatedMediaHubReducer,
  selectors: {
    getXblScreenshots: getXblScreenshots,
    getXblGameclips: getXblGameclips,
    uploadMediaToXbl: uploadMediaToXbl
  }
};