"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peopleReducer = void 0;

var _ = require("lodash");

var redux_ts_simple_1 = require("redux-ts-simple");

var PeopleActions_1 = require("../../actions/PeopleActions");

var INITIAL_STATE = {
  fetchPeopleInProgress: false,
  fetchPersonInProgress: false,
  fetchFriendsInProgress: false,
  fetchPeopleByGamertagInProgress: false,
  engagers: []
};
exports.peopleReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(PeopleActions_1.PeopleActions.fetchFriendsInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    fetchFriendsInProgress: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchFriendsCompleted, function (state, action) {
  return __assign(__assign({}, state), {
    people: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPeopleInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    fetchFriendsInProgress: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPeopleCompleted, function (state, action) {
  return __assign(__assign({}, state), {
    people: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPersonInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    fetchFriendsInProgress: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPersonCompleted, function (state, action) {
  return __assign(__assign({}, state), {
    person: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPeopleByGamertagInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    fetchPeopleByGamertagInProgress: action.payload
  });
}).on(PeopleActions_1.PeopleActions.fetchPeopleByGamertagCompleted, function (state, action) {
  var newEngagers = _.cloneDeep(state.engagers);

  newEngagers.push(action.payload);
  return __assign(__assign({}, state), {
    engagers: newEngagers
  });
}).on(PeopleActions_1.PeopleActions.clearEngagers, function (state, action) {
  return __assign(__assign({}, state), {
    engagers: []
  });
}).build();