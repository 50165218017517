import styled from "styled-components";
import { media } from "../Generic.SnapPoints";

export const ListItemDiv = styled.div`
    display: flex;
    max-width: 100%;
`;
export const PersonListItemDiv = ListItemDiv.extend`
    width: fit-content; /* needed to properly place tick for profile card */
`;

export const AvatarDiv = styled.div`
    display: block;
`;

export const InfoContainerDiv = styled.div`
    flex: auto;
    overflow: hidden;
    height: 50px;

    ${media.small`
        height: 40px;`}
`;

export const ClubInfoContainerDiv = InfoContainerDiv.extend`
`;

export const Gamerpic = styled.img`
    margin-right: 12px;
    height: 50px;
    width: 50px;
    border-radius: 25px;

    ${media.small`
        height: 40px;
        width: 40px;
        border-radius: 20px;`}
`;

export const Clubpic = styled.img`
    border-radius: 0px;
    margin-right: 12px;
    height: 40px;
    width: 40px;
`;

export const GamerTag = styled.p`
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${media.medium`font-size: 17px;`}
    ${media.menuOpenSmall`font-size: 14px;`}
`;

export const DateContainer = styled.div`
    opacity: 0.7;
    margin-top: -2px;
    font-size: 15px;

    ${media.medium`font-size: 13px;`}
    ${media.menuOpenSmall`font-size: 12px;`}
    ${media.small`font-size: 11px; margin-top: -2px;`}
`;

export const ShortDescription = styled.span`
    opacity: 0.7;
    margin-top: 1px;
    margin-left: 5px;
    display: inline-block;
    font-size: 15px;

    ${media.medium`font-size: 13px;`}
    ${media.menuOpenSmall`font-size: 12px;`}
    ${media.small`font-size: 11px; margin-top: -2px;`}
`;
