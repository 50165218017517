"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XSocialSymbols = void 0;
exports.XSocialSymbols = {
  IXboxLiveRequestService: Symbol.for("IXboxLiveRequestService"),
  IGraphQLClient: Symbol.for("IGraphQLClient"),
  IActivityFeedService: Symbol.for("IActivityFeedService"),
  IBallotBoxService: Symbol.for("IBallotBoxService"),
  IClubHubService: Symbol.for("IClubHubService"),
  IMpsdPartyService: Symbol.for("IMpsdPartyService"),
  IRtaConnectionFactory: Symbol.for("IRtaConnectionFactory"),
  IPartyRtcClient: Symbol.for("IPartyRtcClient"),
  IPartyService: Symbol.for("IPartyService"),
  IProfileService: Symbol.for("IProfileService"),
  IPeopleHubService: Symbol.for("IPeopleHubService"),
  IChatService: Symbol.for("IChatService"),
  IChatHistoryService: Symbol.for("IChatHistoryService"),
  IPeopleRepository: Symbol.for("IPeopleRepository"),
  ICommentService: Symbol.for("ICommentService"),
  IUserManager: Symbol.for("IUserManager"),
  ITelemetryService: Symbol.for("ITelemetryService"),
  INotificationsService: Symbol.for("INotificationsService"),
  IExperimentationService: Symbol.for("IExperimentationService"),
  IAuthProvider: Symbol.for("IAuthProvider"),
  IAppConfig: Symbol.for("IAppConfig"),
  IEcdsaDeviceIdentityFactory: Symbol.for("IEcdsaDeviceIdentityFactory"),
  ILocalStorageService: Symbol.for("ILocalStorageService"),
  IAnalyticsService: Symbol.for("IAnalyticsService"),
  IMediaHubService: Symbol.for("IMediaHubService"),
  ITitleHubService: Symbol.for("ITitleHubService"),
  IStringValidationService: Symbol.for("IStringValidationService"),
  IFeedbackService: Symbol.for("IFeedbackService"),
  IHttpRequestService: Symbol.for("IHttpRequestService"),
  IXalAuthProvider: Symbol.for("IXalAuthProvider"),
  IAuthComponents: Symbol.for("IAuthComponents"),
  IDeviceInfo: Symbol.for("IDeviceInfo"),
  IImagePickerService: Symbol.for("IImagePickerService"),
  ILogger: Symbol.for("ILogger"),
  ITagService: Symbol.for("ITagService"),
  IPrivacyService: Symbol.for("IPrivacyService"),
  IFileSaver: Symbol.for("IFileSaver"),
  ISocialService: Symbol.for("ISocialService")
};