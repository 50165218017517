"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetTokenAndSignature = void 0;

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var RequestSigner_1 = require("../../RequestSigner");

var Utils_1 = require("../../Utils");

var XalHttpRequest_1 = require("../../XalHttpRequest");

var XboxToken_1 = require("../../XboxToken");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var GetXtoken_1 = require("./GetXtoken");

var InitializeTokenStack_1 = require("./InitializeTokenStack");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["Init"] = "Init";
  Step["GetAcctXtoken"] = "GetAcctXtoken";
  Step["GetSigningXtoken"] = "GetSigningXtoken";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var GetTokenAndSignature =
/** @class */
function (_super) {
  __extends(GetTokenAndSignature, _super);

  function GetTokenAndSignature(telemetryClient, components, httpClient, webView, args) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.components = components;
    _this.httpClient = httpClient;
    _this.webView = webView;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.GetTokenAndSignature, Step.Start); // TODO(alkhayat):NOXAL: This assert isn't in XAL, needed because of anonymous scenario input allowing optional user

    Utils_1.assert(!!args.user);
    _this.data = {
      args: args
    };
    return _this;
  }

  GetTokenAndSignature.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            return [4
            /*yield*/
            , this.init()];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === Step.Init);
            if (!!this.components.nsal.isTitleNsalLoaded) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.getAcctXtoken()];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 5];

          case 3:
            return [4
            /*yield*/
            , this.getSigningXtoken()];

          case 4:
            _a.sent();

            _a.label = 5;

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetTokenAndSignature.prototype.init = function () {
    this.step.advance(Step.Init);
    var operation = new InitializeTokenStack_1.InitializeTokenStack(this.telemetryClient, this.components, this.httpClient, false // offlineMode
    );
    operation.execute();
    return operation.completionPromise;
  };

  GetTokenAndSignature.prototype.getAcctXtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var nsalUri, endpoint, accountXtoken, operation, resultData, tokenData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            nsalUri = this.components.config.xboxLiveEndpoint;
            endpoint = this.components.nsal.lookup(nsalUri);

            if (!endpoint.Endpoint) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Xbox Live endpoint missing from NSAL document.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Xbox Live endpoint missing from NSAL document."));
              return [2
              /*return*/
              ];
            }

            this.step.advance(Step.GetAcctXtoken);
            accountXtoken = this.components.xboxCache.getToken(XboxToken_1.IdentityType.Xtoken, endpoint.Endpoint.RelyingParty, endpoint.Endpoint.SubRelyingParty ? endpoint.Endpoint.SubRelyingParty : "", endpoint.Endpoint.TokenType, true, // hasSignInDisplayClaims
            this.data.args.user.webAccountId);
            operation = new GetXtoken_1.GetXtoken(this.telemetryClient, this.components, this.webView, this.data.args.user.webAccountId, false, // forceRefresh
            false, // Do not force calling SISU for our token
            this.httpClient, accountXtoken, "GetTokenAndSignature");
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            resultData = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.GetAcctXtoken);
            Utils_1.assert(resultData.xtoken.isValid);
            tokenData = resultData.xtoken.tokenData;
            this.data.account = {
              xuid: tokenData.xuid,
              webAccountId: this.data.args.user.webAccountId,
              gamertag: tokenData.gamertag,
              ageGroup: tokenData.ageGroup,
              privileges: tokenData.privileges,
              settingsRestrictions: tokenData.userSettingsRestrictions,
              enforcementRestrictions: tokenData.userEnforcementRestrictions,
              titleRestrictions: tokenData.userTitleRestrictions
            }; // Now that we've ensured the Title NSAL is loaded we can get our
            // signing token

            return [4
            /*yield*/
            , this.getSigningXtoken()];

          case 2:
            // Now that we've ensured the Title NSAL is loaded we can get our
            // signing token
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetTokenAndSignature.prototype.getSigningXtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var url, endpoint, _a, xtoken, operation, resultData, tokenData;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.step.advance(Step.GetSigningXtoken);
            url = Utils_1.tryParseUrl(this.data.args.url);

            if (!url) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Invalid url in token and signature request");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Invalid url in token and signature request"));
              return [2
              /*return*/
              ];
            }

            endpoint = this.components.nsal.lookup(this.data.args.url);
            if (!!endpoint.Endpoint) return [3
            /*break*/
            , 1]; // if there is no endpoint there's nothing to do

            this.step.advance(Step.Done);
            this.succeed(this.data);
            return [2
            /*return*/
            ];

          case 1:
            if (!endpoint.Policy) return [3
            /*break*/
            , 3];
            _a = this.data;
            return [4
            /*yield*/
            , RequestSigner_1.RequestSigner.signRequest(this.components.xboxCache.deviceIdentity.key, endpoint.Policy, this.data.args.method, "" + url.pathname + url.search, this.data.args.headers, this.data.args.body)];

          case 2:
            _a.signature = _b.sent();
            _b.label = 3;

          case 3:
            xtoken = this.components.xboxCache.getToken(XboxToken_1.IdentityType.Xtoken, endpoint.Endpoint.RelyingParty, endpoint.Endpoint.SubRelyingParty ? endpoint.Endpoint.SubRelyingParty : "", endpoint.Endpoint.TokenType, false, // hasSignInDisplayClaims
            this.data.args.user.webAccountId);
            operation = new GetXtoken_1.GetXtoken(this.telemetryClient, this.components, this.webView, this.data.args.user.webAccountId, this.data.args.forceRefresh, false, // forceCallSisu
            this.httpClient, xtoken, "GetTokenAndSignature");
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 4:
            resultData = _b.sent();
            Utils_1.assert(this.step.currentStep === Step.GetSigningXtoken);
            this.step.advance(Step.Done);
            Utils_1.assert(xtoken === resultData.xtoken);
            tokenData = xtoken.tokenData;

            if (!Utils_1.isNullOrWhiteSpace(resultData.url) && !xtoken.isValid) {
              // Need to show UI for some veto
              // Do we show UI when we're just trying to get token and signature?
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ClientError, "Client operation failed"));
              return [2
              /*return*/
              ];
            } else {
              this.data.token = XalHttpRequest_1.XalHttpRequest.buildAuthorizationHeader(tokenData);
              this.succeed(this.data);
              return [2
              /*return*/
              ];
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return GetTokenAndSignature;
}(OperationBase_1.OperationBase);

exports.GetTokenAndSignature = GetTokenAndSignature;