import * as React from "react";
import { ActivityFeed, edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { MsStoreGameInfo } from "@xbox/social-redux";
import { Row } from "antd";

import * as fts from "./FeedTools.Styles";
import { CommonPostDetailsProps, MediaType } from "./FeedToolsTypes";
import { PreviewGameLinkFeedItem } from "../feeditems/GameLinkFeedItem";
import { GenericLoading, GenericErrorMessage, GenericDisplayMode, Icon } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { PostOptionsContainer } from "./PostOptionsContainer";
import { PostTextArea } from "../PostTextArea";
import { PreviewGameItem } from "./MsGameCatalog/PreviewGameItem";
import { PreviewTextPostFeedItem } from "../feeditems/TextPostFeedItem";
import { ChooseMediaContainer } from "./ChooseMediaContainer/ChooseMediaContainer";
import { Glyphs } from "../../common/Generic.Icons";
import { getWebLink, getAppLink, isEdsResizable, XBLMediaChoices } from "./Utils";
import SearchMsGamesBar from "../../common/gameselectors/SearchMsGamesBar";

interface StorePostDataState {
    text: string;
    searchText: string;
    showModal: boolean;
    showGameInfo: boolean;
    showBackgroundWarning: boolean;
    selectedGame?: MsStoreGameInfo;
    showBackgroundImg: boolean;
    backgroundImgUrl: string;
    screenshotContentId: string;
    msWebStoreUrl: string;
    msAppStoreUrl: string;
}

export class CreateStoreFeedItem extends React.Component<CommonPostDetailsProps, StorePostDataState> {
    private readonly chooseMediaRef?: React.RefObject<ChooseMediaContainer>;
    constructor(props: any) {
        super(props);
        this.chooseMediaRef = React.createRef();
        this.state = {
            text: "",
            searchText: "",
            msWebStoreUrl: "",
            msAppStoreUrl: "",
            backgroundImgUrl: "",
            screenshotContentId: "",
            showGameInfo: false,
            showBackgroundImg: false,
            showBackgroundWarning: false,
            showModal: false,
        };
    }

    resetState = () => {
        this.setState({
            text: "",
            searchText: "",
            msWebStoreUrl: "",
            msAppStoreUrl: "",
            backgroundImgUrl: "",
            screenshotContentId: "",
            showGameInfo: false,
            showBackgroundImg: false,
            showBackgroundWarning: false,
            showModal: false,
            selectedGame: undefined,
        });
        if (this.chooseMediaRef && this.chooseMediaRef.current) {
            this.chooseMediaRef.current.resetState();
        }
    }

    componentWillUpdate(nextProps: any) {
        // Only reset post after post is successful
        // check this by seeing if the result data of the redux for the post status is new,
        // this only updates on successful post
        let status = nextProps.hydrationStates.postStatus.resultData;
        if (status && status !== this.props.hydrationStates.postStatus.resultData) {
            this.resetState();
        }
    }

    onChangeText = (e: any) => {
        this.setState({ text: e.target.value });
    }

    onChangeSearchText = (e: any) => {
        this.setState({ searchText: e.target.value });
    }

    onSearchGame = () => {
        if (!this.state.searchText) {
            return;
        }
        this.props.searchGameInMsCatalog(this.state.searchText);
        this.setState({ showModal: true });
    }

    selectMsCatalogGameItem = (item?: MsStoreGameInfo) => {
        if (item) {
            const isResizable = isEdsResizable(item.backgroundImageUrl);
            const imgUrl = isResizable ? edsImageResizer(item.backgroundImageUrl, EdsImageSize._562x316, "jpg") : item.backgroundImageUrl;

            this.setState({
                selectedGame: item,
                backgroundImgUrl: imgUrl,
                showBackgroundImg: item.backgroundImageUrl !== "",
                showBackgroundWarning: !isResizable,
                showGameInfo: item !== undefined
            });
        }
    }

    closeGameCatalogModal = () => {
        this.setState({ showModal: false, showGameInfo: false, selectedGame: undefined });
    }

    setMediaItem = (locator: string, thumbUrl: string, downloadUri?: string, mediaType?: MediaType) => {
        let uri = mediaType === MediaType.Screenshot && downloadUri ? downloadUri : thumbUrl;
        this.setState({ backgroundImgUrl: uri });
    }
    setXBLScreenshotContentId = (contentID: string) => {
        this.setState({ screenshotContentId: contentID });
    }

    onDeleteGameItem = () => {
        this.setState({ selectedGame: undefined, showGameInfo: false });
    }

    onDeleteScreenshot = () => {
        this.setState({
            backgroundImgUrl: "",
            screenshotContentId: "",
            showBackgroundImg: false,
            showBackgroundWarning: false
        });

        if (this.chooseMediaRef && this.chooseMediaRef.current) {
            this.chooseMediaRef.current.resetState();
        }
    }

    onPreviewItem = (isPinned: boolean, scheduledDateTime?: string) => {
        let gamerPic = "", gamerTag = "";
        if (this.props.currentUser && this.props.currentUser.profile) {
            gamerPic = this.props.currentUser.profile.gamerpic;
            gamerTag = this.props.currentUser.profile.gamertag;
        }

        if (!this.state.selectedGame) {
            return (
                <PreviewTextPostFeedItem
                    pinned={isPinned}
                    itemText={this.state.text}
                    userGamerPicUrl={gamerPic}
                    userGamerTag={gamerTag}
                    selectedChannel={this.props.selectedChannel}
                    scheduledDateTime={scheduledDateTime}
                />
            );
        }

        return (
            <PreviewGameLinkFeedItem
                pinned={isPinned}
                itemText={this.state.text}
                userGamerPicUrl={gamerPic}
                userGamerTag={gamerTag}
                webStoreLink={getWebLink(this.state.selectedGame.productId)}
                gameScreenshot={this.state.backgroundImgUrl}
                selectedChannel={this.props.selectedChannel}
                scheduledDateTime={scheduledDateTime}
            />);
    }

    updateGameLinks = (item: MsStoreGameInfo) => {
        const webStore = getWebLink(item.productId, item.productTitle);
        const appStore = getAppLink(item.productId);

        const links: ActivityFeed.DeviceSpecificLink[] = [
            { linkType: ActivityFeed.LinkDeviceType.Xbox, link: appStore },
            { linkType: ActivityFeed.LinkDeviceType.Windows, link: appStore },

            { linkType: ActivityFeed.LinkDeviceType.Web, link: webStore },
            { linkType: ActivityFeed.LinkDeviceType.Android, link: webStore },
            { linkType: ActivityFeed.LinkDeviceType.Apple, link: webStore },
        ];

        return links;
    }

    getPostContentLocator = () => {
        let backImageLink: ActivityFeed.PostContentLocator = {
            locatorType: "Image",
            locatorUri: this.state.backgroundImgUrl,
        };

        if (this.state.screenshotContentId) {
            backImageLink = { locatorType: "GameMediaImage", locatorUri: this.state.screenshotContentId };
        }
        return backImageLink;
    }

    getPostPayload = (scheduledDateTime?: string) => {
        let timeline: ActivityFeed.UserPostTimeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: this.props.clubId,
        };

        if (this.props.selectedChannel && !this.props.selectedChannel.isDefault) {
            timeline.channelId = `${this.props.selectedChannel.id}`;
        }

        let tls = [timeline];

        let item = this.state.selectedGame;
        if (item && item.productId) {
            const gameTitle = this.state.selectedGame ? this.state.selectedGame!.productTitle : "";

            // Create store payload
            let postLinksData: ActivityFeed.PostLink = {
                description: this.state.text,
                title: gameTitle,
                links: this.updateGameLinks(item)
            };

            let details: ActivityFeed.PostTypeData = {
                postLinks: postLinksData,
                postContentLocators: [this.getPostContentLocator()]
            };

            let storePayload: ActivityFeed.UserPost = {
                postText: gameTitle,
                postType: ActivityFeed.PostType.StoreLink,
                postDate: scheduledDateTime,
                timelines: tls,
                postTypeData: details
            };

            return storePayload;
        }

        // Post it as a regular text post
        let textPayload: ActivityFeed.UserPost = {
            postText: this.state.text,
            postType: ActivityFeed.PostType.Text,
            timelines: tls
        };
        return textPayload;
    }

    onPostFeedItem = (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, scheduledDateTime?: string) => {
        if (!this.props.clubId) {
            return;
        }

        this.props.handlePostClick(this.getPostPayload(scheduledDateTime), isPinned, isDisabled, isScheduled, scheduledDateTime);
        this.resetState();
    }

    onCancelPost = () => {
        this.resetState();
    }

    render() {
        let permissions = this.props.permissions;
        if (!permissions) {
            return <GenericLoading />;
        }

        if (!permissions.postStoreLink) {
            return (
                <GenericErrorMessage
                    errorMessage={Localize("feedtools.warning", { context: "store" })}
                    showError={true}
                />
            );
        }

        return (
            <div>
                <fts.Header>
                    {Localize("postFeedItem.postDetails")}
                </fts.Header>
                <div>
                    {Localize("postFeedItem.storeSearch")}
                    <fts.Description>
                        {Localize("postFeedItem.storeSearchDescription")}
                    </fts.Description>

                    <SearchMsGamesBar onSetGame={this.selectMsCatalogGameItem} />

                    <fts.GameRow style={{ display: GenericDisplayMode(this.state.showGameInfo) }} >
                        {this.state.selectedGame && <PreviewGameItem gameInfo={this.state.selectedGame} showDelete={true} onDelete={this.onDeleteGameItem} />}
                    </fts.GameRow>
                </div>

                <Row style={{ margin: "15px 0px" }}>
                    {/* background image preview */}
                    <fts.DetailsColumn span={6} style={{ maxWidth: "200px", marginRight: "8px", display: GenericDisplayMode(this.state.showGameInfo) }}>
                        <div style={{ display: GenericDisplayMode(this.state.showBackgroundImg) }}>
                            {this.state.selectedGame &&
                                <fts.RelativeDiv>
                                    <fts.FitImage
                                        src={this.state.backgroundImgUrl}
                                        alt={Localize("postFeedItem.gameScreenshot", { gameTitle: this.state.selectedGame.voiceTitle })}
                                    />
                                    <fts.DeleteImageButton iconOnly={true} onClick={this.onDeleteScreenshot}>
                                        <Icon type={Glyphs.Delete} />
                                    </fts.DeleteImageButton>
                                </fts.RelativeDiv>
                            }
                            <GenericErrorMessage
                                errorMessage={Localize("postFeedItem.gameImageWarning")}
                                showError={this.state.showBackgroundWarning}
                            />
                        </div>
                        <div style={{ display: GenericDisplayMode(!this.state.showBackgroundImg) }}>
                            <ChooseMediaContainer
                                fetchMediaItems={this.props.fetchMediaItems}
                                uploadMediaItems={this.props.uploadMediaItems}
                                setMediaItem={this.setMediaItem}
                                setXBLScreenshotContentId={this.setXBLScreenshotContentId}
                                permissions={this.props.permissions}
                                hydrationStates={this.props.hydrationStates}
                                showWebLinkOption={true}
                                showItems={XBLMediaChoices.Screenshots}
                                ref={this.chooseMediaRef}
                                preSelectedGame={this.state.selectedGame}
                            />
                        </div>
                    </fts.DetailsColumn>

                    {/* Text input */}
                    <fts.DetailsColumn span={12}>
                        {Localize("postFeedItem.postText")}
                        <br />
                        <PostTextArea
                            onChange={this.onChangeText}
                            value={this.state.text}
                            placeholder={Localize("postFeedItem.textPostPlaceholder")}
                        />
                    </fts.DetailsColumn>
                </Row>

                <PostOptionsContainer
                    onPostFeedItem={this.onPostFeedItem}
                    onCancelPost={this.onCancelPost}
                    getPreviewItem={this.onPreviewItem}
                    postAbilityBlocked={(this.state.selectedGame || this.state.text) ? false : true}
                    canPin={permissions.pinPost}
                    canSchedule={permissions.schedulePost}
                    forceSchedule={this.props.forceSchedule}
                />
            </div>
        );
    }
}
