import * as React from "react";
import * as Styles from "./UserProfile.Styles";
interface IGamerscoreProps {
    value: string;
}

const Gamerscore: React.SFC<IGamerscoreProps> = props => {
    return (
        <div>
            <Styles.BodyLabel>{props.value}</Styles.BodyLabel>
        </div>
    );
};

export default Gamerscore;
