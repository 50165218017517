"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrivacySaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var PrivacyActions_1 = require("../actions/PrivacyActions");

var Utils_1 = require("./Utils");

var PrivacySaga =
/** @class */
function () {
  function PrivacySaga(privacyService, telemetryService, notificationService) {
    this.privacyService = privacyService;
    this.telemetryService = telemetryService;
    this.notificationService = notificationService;
    this.sagas = [this.watchGetPrivacySettings(), this.watchUpdatePrivacySetting(), this.watchBlockUser(), this.watchUnblockUser()];
  }

  PrivacySaga.prototype._getPrivacySettings = function (action) {
    var result, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          this.telemetryService.trackEvent("FetchPrivacySettings", {
            "userId": action.payload
          });
          return [4
          /*yield*/
          , this.privacyService.getPrivacySettings(action.payload)];

        case 1:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(PrivacyActions_1.PrivacyActions.getPrivacySettingsCompleted(result.privacySettings))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_1 = _a.sent();
          console.warn("Unable to get privacy settings: " + err_1);
          this.telemetryService.trackException(err_1, "FetchPrivacySettings", {
            "userId": action.payload
          });
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype._updatePrivacySetting = function (action) {
    var err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          this.telemetryService.trackEvent("UpdatePrivacySetting", {
            "userId": action.payload
          });
          return [4
          /*yield*/
          , this.privacyService.updatePrivacySettings(action.payload.userId, [action.payload.setting])];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PrivacyActions_1.PrivacyActions.updatePrivacySettingCompleted(action.payload.setting))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_2 = _a.sent();
          console.warn("Unable to update privacy settings: " + err_2);
          this.telemetryService.trackException(err_2, "UpdatePrivacySetting", {
            "userId": action.payload
          });
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype._blockUser = function (action) {
    var err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          return [4
          /*yield*/
          , this.privacyService.blockUser(action.payload.currentUserXuid, action.payload.blockedXuid)];

        case 1:
          _a.sent();

          return [3
          /*break*/
          , 3];

        case 2:
          err_3 = _a.sent();
          Utils_1.LogHttpsError("Unable to block user", err_3);
          this.telemetryService.trackException(err_3, "BlockUser", {
            "callingXuid": action.payload.currentUserXuid,
            "blockedXuid": action.payload.blockedXuid
          });
          this.notificationService.notifyError("BlockUser", "");
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype._unblockUser = function (action) {
    var err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          return [4
          /*yield*/
          , this.privacyService.unblockUser(action.payload.currentUserXuid, action.payload.blockedXuid)];

        case 1:
          _a.sent();

          return [3
          /*break*/
          , 3];

        case 2:
          err_4 = _a.sent();
          Utils_1.LogHttpsError("Unable to unblock user", err_4);
          this.telemetryService.trackException(err_4, "UnblockUser", {
            "callingXuid": action.payload.currentUserXuid,
            "blockedXuid": action.payload.blockedXuid
          });
          this.notificationService.notifyError("UnblockUser", "");
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype.watchGetPrivacySettings = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PrivacyActions_1.PrivacyActions.getPrivacySettings.type, this._getPrivacySettings.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype.watchUpdatePrivacySetting = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PrivacyActions_1.PrivacyActions.updatePrivacySetting.type, this._updatePrivacySetting.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype.watchBlockUser = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PrivacyActions_1.PrivacyActions.blockUser.type, this._blockUser.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga.prototype.watchUnblockUser = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PrivacyActions_1.PrivacyActions.unblockUser.type, this._unblockUser.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PrivacySaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IPrivacyService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __metadata("design:paramtypes", [Object, Object, Object])], PrivacySaga);
  return PrivacySaga;
}();

exports.PrivacySaga = PrivacySaga;