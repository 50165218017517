import { ManageMedia, MediaManagerState } from "./ManageMedia";

export class ManageGameClips extends ManageMedia {

    componentDidMount() {
        this.props.fetchMediaItems(this.payload, true);
    }

    componentDidUpdate(prevProps: any, prevState: MediaManagerState) {
        let oldMediaData = prevProps.hydrationStates.gameclips.mediaData;
        let newMediaData = this.props.hydrationStates.gameclips.mediaData;
        if (newMediaData && newMediaData !== oldMediaData) {
            this.payload.continuationToken = newMediaData.continuationToken;
            if (!oldMediaData || newMediaData.values !== oldMediaData.values) {
                this.updateList(newMediaData.values, true);
            } else if (newMediaData.values.length === 0) {
                this.updateList([], true);
            }
        }
        if (this.state.filterOrder !== prevState.filterOrder && newMediaData) {
            this.updateList(newMediaData.values, true);
        }
    }

    loadMoreMedia = () => {
        this.props.fetchMediaItems(this.payload, true);
    }
}
