"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clipsMetadataReducer = exports.screensMetadataReducer = void 0;

var Immutable = require("seamless-immutable");

var redux_ts_simple_1 = require("redux-ts-simple");

var MediaHubActions_1 = require("../../actions/MediaHubActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.screensMetadataReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(MediaHubActions_1.MediaHubActions.fetchScreenshotsMetadataInProgress, function (state, action) {
  if (action.payload === true) {
    return state.merge({
      inProgress: action.payload,
      metaData: undefined
    });
  } else {
    return state.merge({
      inProgress: action.payload
    });
  }
}).on(MediaHubActions_1.MediaHubActions.fetchScreenshotsMetadataCompleted, function (state, action) {
  return state.merge({
    metaData: action.payload
  }, {
    deep: true
  });
}).build();
exports.clipsMetadataReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(MediaHubActions_1.MediaHubActions.fetchGameclipsMetadataInProgress, function (state, action) {
  if (action.payload === true) {
    return state.merge({
      inProgress: action.payload,
      metaData: undefined
    });
  } else {
    return state.merge({
      inProgress: action.payload
    });
  }
}).on(MediaHubActions_1.MediaHubActions.fetchGameclipsMetadataCompleted, function (state, action) {
  return state.merge({
    metaData: action.payload
  }, {
    deep: true
  });
}).build();