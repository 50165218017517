import * as React from "react";
import { Button, Switch, DatePicker, TimePicker } from "antd";
import * as moment from "moment-timezone";
import * as ScheduledPostContainer from "@xbox/social-views";
import { GenericModalFooter, GenericDisplayMode } from "../../common/GenericElements";
import { Divider } from "../../common/generic/Divider";
import * as FTStyles from "./FeedTools.Styles";
import { Localize } from "../../common/translation/Localize";
import { ThemedModal } from "../../containers/Styles";

export interface PostOptionsProps {
    onPostFeedItem: (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, dateTimeISOStr?: string) => void;
    onCancelPost: () => void;
    getPreviewItem: (isPinned: boolean, scheduledDateTime?: string) => JSX.Element | null;
    postAbilityBlocked?: boolean;
    canPin?: boolean;
    canSchedule?: boolean;
    forceSchedule: boolean;
    preventedDateTime?: moment.Moment;
}

export interface CommonPostDataState {
    isPinned: boolean;
    isScheduled: boolean;
    isDisabled: boolean;
    modalVisible: boolean;
    selectedDate?: moment.Moment;
    selectedTime?: moment.Moment;
    dateString?: string;
    timeString?: string;
    showScheduledPostError: boolean;
    timeZoneString: string;
    submitAbilityBlocked?: boolean;
    modalErrorString?: string;
}

let preventedDate: moment.Moment;

export class PostOptionsContainer extends React.Component<PostOptionsProps, CommonPostDataState> {
    public scheduledTime: moment.Moment = moment();
    constructor(props: any) {
        super(props);

        this.state = {
            isPinned: false,
            isScheduled: this.props.forceSchedule ? true : false,
            isDisabled: false,
            modalVisible: false,
            showScheduledPostError: false,
            timeZoneString: this.getTimeZone()
        };
        if (this.props.preventedDateTime) {
            preventedDate = this.props.preventedDateTime;
        }
    }

    resetState = () => {
        this.setState({
            isPinned: false,
            isScheduled: this.props.forceSchedule ? true : false,
            isDisabled: false,
            modalVisible: false
        });
    }

    onDisabledChange = (checked: any) => {
        this.setState({ isDisabled: checked });
    }

    onPinnedChange = (checked: any) => {
        this.setState({ isPinned: checked });
    }

    onScheduledChange = (checked: any) => {
        this.setState({ isScheduled: this.props.forceSchedule ? true : checked });
    }

    onPreviewClick = () => {
        if (this.state.isPinned && this.state.isScheduled) {
            this.setState({
                modalErrorString: Localize("postPreview.scheduledPostPinned"),
                submitAbilityBlocked: true,
                showScheduledPostError: true,
                modalVisible: true
            });
        } else if (this.state.isScheduled && (!this.isDateSelected() || !this.isTimeSelected())) {
            this.setState({
                modalErrorString: Localize("postPreview.scheduledPostNoDateTime"),
                submitAbilityBlocked: true,
                showScheduledPostError: true,
                modalVisible: true
            });
        } else if (this.state.isScheduled
            && this.props.preventedDateTime
            && this.getSchedulePostDateTime().isAfter(this.props.preventedDateTime)
        ) {
            this.setState({
                modalErrorString: Localize("postPreview.scheduledPostAfterPollClose"),
                submitAbilityBlocked: true,
                showScheduledPostError: true,
                modalVisible: true
            });
        } else {
            // Initial state of modal
            this.setState({
                modalErrorString: undefined,
                submitAbilityBlocked: false,
                showScheduledPostError: false,
                modalVisible: true
            });
        }
    }

    onCancelPreview = () => {
        this.setState({ modalVisible: false });
    }

    onCreatePost = () => {
        if (this.state.isScheduled) {
            // convert to ISO8601 format UTC time
            let dateTimeISOStr = this.getSchedulePostDateTime().toISOString();
            this.props.onPostFeedItem(this.state.isPinned, this.state.isDisabled, this.state.isScheduled, dateTimeISOStr);
        } else {
            this.props.onPostFeedItem(this.state.isPinned, this.state.isDisabled, this.state.isScheduled);
        }

        this.resetState();
    }

    onCancelPost = () => {
        this.resetState();
        this.props.onCancelPost();
    }

    onDateChange = (date: moment.Moment, dateString: string) => {
        this.setState({ selectedDate: date, dateString: dateString });
        this.updateExportscheduledTime();
    }

    onTimeChange = (time: moment.Moment, timeString: string) => {
        this.setState({ selectedTime: time, timeString: timeString });
        this.updateExportscheduledTime();
    }

    updateExportscheduledTime() {
        this.scheduledTime = this.getSchedulePostDateTime();
    }

    disabledDate(current: any) {
        return current
            && (
                current.isBefore(moment().startOf("day"))
                || (preventedDate && current.isAfter(preventedDate))
                || current.isAfter(moment().startOf("day").add(ScheduledPostContainer.SCHEDULED_POST_FUTURE_DATE_THRESHOLD, "day"))
            );
    }

    isDateSelected(): boolean {
        return !(this.state.dateString === undefined || this.state.dateString.length === 0);
    }

    isTimeSelected(): boolean {
        return !(this.state.timeString === undefined || this.state.timeString.length === 0);
    }

    getTimeZone(): any {
        let zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment().tz(zoneName).format("z");
    }

    getSchedulePostDateTime(): moment.Moment {
        if (this.state.dateString && this.state.timeString) {
            let dateTimeStr = this.state.dateString + " " + this.state.timeString;
            return moment(dateTimeStr, "MM/DD/YYYY h:mm A");
        } else if (this.state.dateString && this.state.selectedDate) {
            return this.state.selectedDate;
        } else if (this.state.timeString && this.state.selectedTime) {
            return this.state.selectedTime;
        } else {
            return moment();
        }
    }

    getPreviewScheduledTime = () => {
        return this.state.isScheduled
            && this.state.selectedDate
            && this.state.selectedTime
            ? this.getSchedulePostDateTime().format("dddd, MMMM Do YYYY, h:mm a") : "";
    }

    render() {
        return (
            <div>
                <FTStyles.OptionsHeader>
                    {Localize("postFeedItem.postOptions")}
                </FTStyles.OptionsHeader>

                <FTStyles.SwitchOptionsContainer className="SwitchOptionsContainer">
                    <FTStyles.SwitchBlockDiv>
                        {Localize("postFeedItem.pinPost")}
                        <br />
                        <Switch aria-label={Localize("postFeedItem.pinPost")} disabled={!this.props.canPin} checked={this.state.isPinned} onChange={this.onPinnedChange} />
                        <FTStyles.OnOffSpan>
                            {Localize("generic.onOff", { context: this.state.isPinned.toString() })}
                        </FTStyles.OnOffSpan>
                    </FTStyles.SwitchBlockDiv>

                    <FTStyles.SwitchBlockDiv>
                        {Localize("postFeedItem.disableComments")}
                        <br />
                        <Switch aria-label={Localize("postFeedItem.disableComments")} checked={this.state.isDisabled} onChange={this.onDisabledChange} />
                        <FTStyles.OnOffSpan>
                            {Localize("generic.onOff", { context: this.state.isDisabled.toString() })}
                        </FTStyles.OnOffSpan>
                    </FTStyles.SwitchBlockDiv>

                    <FTStyles.SwitchBlockDiv>
                        {Localize("postFeedItem.schedulePost")}
                        <br />
                        {!this.props.forceSchedule && <Switch aria-label={Localize("postFeedItem.schedulePost")} disabled={!this.props.canSchedule} checked={this.state.isScheduled} onChange={this.onScheduledChange} />}
                        {!this.props.forceSchedule &&
                            <FTStyles.OnOffSpan>
                                {Localize("generic.onOff", { context: this.state.isScheduled.toString() })}
                            </FTStyles.OnOffSpan>
                        }
                        <div style={{ margin: "16px 0", display: GenericDisplayMode(this.state.isScheduled) }}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={this.disabledDate}
                                placeholder="MM/DD/YYYY"
                                format="MM/DD/YYYY"
                                onChange={(momentDate: moment.Moment | null, dateString: string) => {
                                    if (momentDate) {
                                        this.onDateChange(momentDate, dateString);
                                    }
                                }}
                                style={{ marginBottom: 10 }}
                            />
                            <TimePicker
                                allowEmpty={false}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={this.onTimeChange}
                            />
                            <span style={{ marginLeft: "8px" }}>{this.state.timeZoneString}</span>
                        </div>

                    </FTStyles.SwitchBlockDiv>
                </FTStyles.SwitchOptionsContainer>

                <Divider style={{ margin: "0 0 24px 0" }} />
                <Button
                    onClick={this.onPreviewClick}
                    disabled={this.props.postAbilityBlocked}
                    type="primary"
                    className="btnColor"
                    style={{ minWidth: "75px" }}
                >
                    {Localize("generic.post")}
                </Button>
                <Button className="btnColor" onClick={this.onCancelPost} style={{ margin: "0 12px" }}>
                    {Localize("generic.cancel")}
                </Button>

                <ThemedModal
                    bodyStyle={{ paddingBottom: 10 }}
                    visible={this.state.modalVisible}
                    title={Localize("postPreview.title")}
                    onCancel={this.onCancelPreview}
                    footer={
                        <GenericModalFooter
                            onOk={this.onCreatePost}
                            onCancel={this.onCancelPreview}
                            disableOK={this.state.submitAbilityBlocked}
                            errorMessage={this.state.modalErrorString}
                            showError={this.state.showScheduledPostError}
                        />}
                >
                    {this.props.getPreviewItem(this.state.isPinned, this.getPreviewScheduledTime())}
                </ThemedModal>
            </div>
        );
    }
}
