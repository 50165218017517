"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeedbackService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var typings_1 = require("../../typings");

var file_a_bug_1 = require("./file-a-bug");

var tfsValues = {
  Area: "SUB_COMMUNITYHUB",
  Category: "CAT_COMMUNITYHUB",
  Source: "CommunityHub"
};
var url = "https://xcev.cloudapp.net/CSE.svc/sdf";
var xcevAuthToken = "CE315E61-6238-4DCF-97D9-E1E4A5A8378E";

var FeedbackService =
/** @class */
function () {
  function FeedbackService(httpService) {
    var _this = this;

    this.httpService = httpService;

    this.submitBug = function (data) {
      var headers = [];
      headers.push({
        Name: "Authorization",
        Value: "" + xcevAuthToken
      });
      headers.push({
        Name: "Content-Type",
        Value: "xblc-binary"
      });
      return _this.httpService.sendRequest({
        url: url,
        data: "," + data,
        headers: headers,
        method: http_1.Http.HttpRequestMethod.Post
      });
    };
  }

  FeedbackService.prototype.submitFeedback = function (feedback, additionalData) {
    if (!feedback) {
      return;
    }

    var bugManager = new file_a_bug_1.BugManager(feedback.version, tfsValues.Area, tfsValues.Category, feedback.platfrom, tfsValues.Source, true);
    bugManager.prepareBug(feedback.feedback, feedback.path ? feedback.path : "", "CPM", "");

    if (feedback.feature) {
      bugManager.appendToDescription("User Experience", feedback.feature);
    }

    if (feedback.clubId) {
      bugManager.appendToDescription("ClubId", feedback.clubId);
    }

    if (feedback.feature) {
      bugManager.appendToDescription("Feedback", feedback.feedback);
    }

    if (feedback.type) {
      bugManager.appendToDescription("Type", feedback.type);
    } // Only report logs and state when user is authenticated (per NGP and File-a-bug requirements)


    if (feedback.xuid && additionalData) {
      bugManager.addAppLogs(additionalData["logs"]);
    }

    var data = bugManager.sendBug();
    var base64String = btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(data))));
    this.submitBug(base64String);
  };

  FeedbackService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IHttpRequestService)), __metadata("design:paramtypes", [Object])], FeedbackService);
  return FeedbackService;
}();

exports.FeedbackService = FeedbackService;