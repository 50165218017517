import * as React from "react";
import * as FeedItemStyles from "./base/FeedItem.Styles";
import { ActivityFeedItem, FeedItemProps } from "./base/ActivityFeedItem";
import { validateHttpPrefix } from "./base/FeedItemsUtils";
import { PreviewFeedItemProps, PreviewActivityFeedItem } from "./base/PreviewActivityFeedItem";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";

export class UserPostFeedItem extends ActivityFeedItem {
    constructor(props: FeedItemProps) {
        super(props);
    }

    verifyLinkData = (): {link: string, displayLink: string|undefined} => {
        const link = this.props.item.postDetails && this.props.item.postDetails.link
                        ? validateHttpPrefix(this.props.item.postDetails.link)
                        : "";
        const displayLink = this.props.item.postDetails
                    ? this.props.item.postDetails.displayLink
                    : link;

        return {link, displayLink};
    }

    handleLinkClick = (event: any, data: any) => {
        if (data.link) {
            window.open(data.link, "_blank");
            event.stopPropagation();
        }
    }

    /*********************************************************************/
    /* Full Base Template
    /*********************************************************************/
    getFullTextContainer = (): JSX.Element | null => {
        return this.getTextLinkContainer(this.props);
    }

    getFullFeedItemData = (): JSX.Element | null => {
        let preview = this.props.item.itemImage ?
            <FeedItemStyles.LinkMedia className="UserPostPreviewImage" style={{ cursor: "pointer" }} onLoad={this.props.onload} alt="preview image" src={this.props.item.itemImage} />
            : null;

        return (
            <div>
                {   /* additional shared data */
                    this.props.item.originalAuthorInfo
                    && <FeedItemStyles.TextContainer>{this.props.item.itemText}</FeedItemStyles.TextContainer>
                }
                {this.getImagePreview(preview)}
            </div>
        );
    }

    /*********************************************************************/
    /* Inline Template
    /*********************************************************************/
    getInlineSecondaryContent = (): JSX.Element | null => {
        let data = this.verifyLinkData();
        if (!data.displayLink) {
            return null;
        }

        return (
            <FeedItemStyles.EllipsisLinkContainer target="_blank" href={data.link} onClick={(e) => e.stopPropagation()}>
                {data.displayLink}
            </FeedItemStyles.EllipsisLinkContainer>
        );
    }

    getInlinePostGlyph = () => {
        return Glyphs.Link;
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/
    getGridLinkButton = (): JSX.Element | null => {
        let data = this.verifyLinkData();

        return (
            <FeedItemStyles.SmallLinkButton onClick={(e: any) => {this.handleLinkClick(e, data); }} buttonType="primary">
                <Icon type={Glyphs.Link} /><span>{data.displayLink}</span>
            </FeedItemStyles.SmallLinkButton>
        );
    }
}

export interface PreviewUserPostFeedItemProps extends PreviewFeedItemProps {
    imageUrl?: string;
    link: string;
    displayLink: string;
}

export const PreviewUserPostFeedItem: React.SFC<PreviewUserPostFeedItemProps> = props => {
    let previewContent =  (
        <div>
            <FeedItemStyles.TextContainer style={{ marginBottom: "0px" }}>{props.itemText} </FeedItemStyles.TextContainer>
            <FeedItemStyles.LinkContainer href={props.link}>{props.displayLink}</FeedItemStyles.LinkContainer>
            <FeedItemStyles.MediaContainer>
                {props.imageUrl && <FeedItemStyles.LinkMedia alt="preview image" src={props.imageUrl} style={{marginTop: "0"}}/>}
            </FeedItemStyles.MediaContainer>
        </div>
    );

    return <PreviewActivityFeedItem previewContent={previewContent} {...props} />;
};
