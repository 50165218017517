"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubInviteQueueReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({
  fetchInProgress: false
});
exports.clubInviteQueueReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubInviteQueueFetchInProgress, function (state, action) {
  return state.merge({
    fetchInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.clubInviteQueueFetchCompleted, function (state, action) {
  return state.merge({
    inviteQueue: action.payload
  }, {
    deep: true
  });
}).on(ClubListActions_1.ClubActions.clubInviteQueueSearchFilter, function (state, action) {
  return state.setIn(["searchFilter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubInviteQueueFilter, function (state, action) {
  return state.setIn(["filter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubInviteGetFilterCompleted, function (state, action) {
  return state.setIn(["filter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubUserGetStatsCompleted, function (state, action) {
  return state.setIn(["userStatsMap"], action.payload);
}).on(ClubListActions_1.ClubActions.clubUpdateInviteQueue, function (state, action) {
  if (state.inviteQueue && action.payload) {
    var inviteQ_1 = Immutable(state.inviteQueue).asMutable();
    action.payload.xuids.forEach(function (xuid) {
      var toRemove = inviteQ_1.findIndex(function (value) {
        return value.profile.xuid === xuid;
      });

      if (toRemove !== -1) {
        inviteQ_1.splice(toRemove, 1);
      }
    });
    return state.setIn(["inviteQueue"], inviteQ_1);
  }

  return state;
}).on(ClubListActions_1.ClubActions.clearState, function (state, action) {
  state = state.setIn(["filter"], undefined);
  state = state.setIn(["userStatsMap"], undefined);
  return state.setIn(["inviteQueue"], undefined);
}).build();