"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PeopleActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var PeopleActions;

(function (PeopleActions) {
  PeopleActions.fetchPeople = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE");
  PeopleActions.fetchPeopleInProgress = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE_IN_PROGRESS");
  PeopleActions.fetchPeopleCompleted = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE_COMPLETED");
  PeopleActions.fetchPerson = redux_ts_simple_1.createAction("PERSON::FETCH_PEOPLE");
  PeopleActions.fetchPersonInProgress = redux_ts_simple_1.createAction("PERSON::FETCH_PERSON_IN_PROGRESS");
  PeopleActions.fetchPersonCompleted = redux_ts_simple_1.createAction("PERSON::FETCH_PERSON_COMPLETED");
  PeopleActions.fetchFriends = redux_ts_simple_1.createAction("PEOPLE::FETCH_FRIENDS");
  PeopleActions.fetchFriendsInProgress = redux_ts_simple_1.createAction("PEOPLE::FETCH_FRIENDS_IN_PROGRESS");
  PeopleActions.fetchFriendsCompleted = redux_ts_simple_1.createAction("PEOPLE::FETCH_FRIENDS_COMPLETED");
  PeopleActions.fetchPeopleByGamertag = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE_BY_GAMERTAG");
  PeopleActions.fetchPeopleByGamertagInProgress = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE_BY_GAMERTAG_IN_PROGRESS");
  PeopleActions.fetchPeopleByGamertagCompleted = redux_ts_simple_1.createAction("PEOPLE::FETCH_PEOPLE_BY_GAMERTAG_COMPLETED");
  PeopleActions.clearEngagers = redux_ts_simple_1.createAction("PEOPLE::CLEAR_ENGAGERS");
})(PeopleActions = exports.PeopleActions || (exports.PeopleActions = {}));