import * as React from "react";
import { Tabs } from "antd";
import { MsStoreGameInfo } from "@xbox/social-redux";
import { MsStoreGameInfoState } from "@xbox/social-views";

import * as fts from "../FeedTools.Styles";
import { GenericLoading, GenericEmptyState } from "../../../common/GenericElements";
import { Localize } from "../../../common/translation/Localize";
import { GamesList } from "./GamesList";

const TabPane = Tabs.TabPane;

interface GameCatalogContainerProps {
    itemSelected: (item: any) => void;
    catalogStates: MsStoreGameInfoState;
}

interface GameCatalogContainerStats {
    selectedItemId: string | null;
}

export class GameCatalogContainer extends React.Component<GameCatalogContainerProps, GameCatalogContainerStats> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedItemId: null,
        };
    }

    onItemClick = (item: MsStoreGameInfo) => {
        this.props.itemSelected(item);
        this.setState({selectedItemId: item.productId});
    }

    render() {
        let xboxGames = this.props.catalogStates.xboxGames;
        let desktopGames = this.props.catalogStates.desktopGames;

        if (this.props.catalogStates.inProgress) {
            return <GenericLoading/>;
        } else if (xboxGames.length === 0 && desktopGames.length === 0) {
            return <GenericEmptyState message={Localize("generic.emptyState")}/>;
        }

        return (
            <Tabs defaultActiveKey="1">
                <TabPane tab={Localize("postFeedItem.storeModal.xboxSection")} key="1">
                    <fts.VScrollableDiv>
                        <GamesList
                            dataSrc={xboxGames}
                            onClick={this.onItemClick}
                            selectedItemId={this.state.selectedItemId}
                        />
                    </fts.VScrollableDiv>
                </TabPane>
                <TabPane tab={Localize("postFeedItem.storeModal.desktopSection")} key="2">
                    <fts.VScrollableDiv>
                        <GamesList
                            dataSrc={desktopGames}
                            onClick={this.onItemClick}
                            selectedItemId={this.state.selectedItemId}
                        />
                    </fts.VScrollableDiv>
                </TabPane>
            </Tabs>
        );
    }
}
