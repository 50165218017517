"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnonymousUserMsaId = exports.SisuDesktopUri = exports.OfflineAccessScope = exports.OauthAuthorizePath = exports.OauthLogoutPath = exports.OauthTokenPath = exports.OauthDesktopPath = exports.Xerr = exports.HeaderValues = exports.HeaderKeys = void 0;
var HeaderKeys;

(function (HeaderKeys) {
  HeaderKeys["Connection"] = "Connection";
  HeaderKeys["ContentType"] = "Content-Type";
  HeaderKeys["XblContractVersion"] = "x-xbl-contract-version";
})(HeaderKeys = exports.HeaderKeys || (exports.HeaderKeys = {}));

var HeaderValues;

(function (HeaderValues) {
  HeaderValues["ApplicationJsonUtf8"] = "application/json; charset=utf-8";
  HeaderValues["ApplicationFormUrlEncoded"] = "application/x-www-form-urlencoded;charset=utf-8";
  HeaderValues["KeepAlive"] = "Keep-Alive";
})(HeaderValues = exports.HeaderValues || (exports.HeaderValues = {}));

var Xerr;

(function (Xerr) {
  Xerr[Xerr["ExpiredDtoken"] = 2148916256] = "ExpiredDtoken";
  Xerr[Xerr["InvalidDtoken"] = 2148916259] = "InvalidDtoken";
  Xerr[Xerr["ExpiredUtoken"] = 2148916258] = "ExpiredUtoken";
  Xerr[Xerr["InvalidUtoken"] = 2148916262] = "InvalidUtoken";
  Xerr[Xerr["ExpiredTtoken"] = 2148916257] = "ExpiredTtoken";
  Xerr[Xerr["InvalidTtoken"] = 2148916260] = "InvalidTtoken";
  Xerr[Xerr["InvalidMsaTicket"] = 2148916276] = "InvalidMsaTicket";
})(Xerr = exports.Xerr || (exports.Xerr = {}));

exports.OauthDesktopPath = "/oauth20_desktop.srf";
exports.OauthTokenPath = "/oauth20_token.srf";
exports.OauthLogoutPath = "/oauth20_logout.srf";
exports.OauthAuthorizePath = "/oauth20_authorize.srf";
exports.OfflineAccessScope = "offline_access";
exports.SisuDesktopUri = "https://sisu.xboxlive.com/sisu_desktop.srf";
exports.AnonymousUserMsaId = "anonymous";