"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartyChatConnectionState = void 0;
var PartyChatConnectionState;

(function (PartyChatConnectionState) {
  PartyChatConnectionState[PartyChatConnectionState["Unset"] = 0] = "Unset";
  PartyChatConnectionState[PartyChatConnectionState["Connecting"] = 1] = "Connecting";
  PartyChatConnectionState[PartyChatConnectionState["Connected"] = 2] = "Connected";
  PartyChatConnectionState[PartyChatConnectionState["Disconnected"] = 3] = "Disconnected";
})(PartyChatConnectionState = exports.PartyChatConnectionState || (exports.PartyChatConnectionState = {}));