import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import {
    CoreClubFeedContainer,
    IClubFeedResolver,
    IFeedItemSocialActionHandlers,
    IFeedItemAdminActionHanders,
    FeedManipulationProps,
    FeedStatus
} from "@xbox/social-views";
import ClubActivityFeed from "../../components/activityfeed/ClubActivityFeed";

const getClubFeedContainer = (
    updateMethod: (cursor?: string) => void,
    clearActivityFeed: () => void,
    feedItemSelected: (feedItem: ActivityFeed.FeedItem) => void,
    socialActions: IFeedItemSocialActionHandlers,
    adminActions: IFeedItemAdminActionHanders,
    feedStatus: FeedStatus,
    feedManipulationProps: FeedManipulationProps,
    isCommentLocked?: boolean,
    cursor?: string,
): JSX.Element | null => {
    return (
        <ClubActivityFeed
            isErrored={feedStatus.isErrored}
            feedItemSelected={feedItemSelected}
            updateMethod={updateMethod}
            clearActivityFeed={clearActivityFeed}
            socialActions={socialActions}
            adminActions={adminActions}
            listData={feedStatus.results}
            cursor={cursor}
            isValid={feedStatus.isValid}
            feedManipulationProps={feedManipulationProps}
            isCommentLocked={isCommentLocked}
        />
    );
};

const ClubFeedResolver: IClubFeedResolver = {
    getClubFeedContainer: getClubFeedContainer
};
export default CoreClubFeedContainer(ClubFeedResolver);
