"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BugManager = void 0;

var text_encoding_shim_1 = require("text-encoding-shim");

var util_1 = require("./util"); // Simplified to match XBLC reqs


var BugManager =
/** @class */
function () {
  function BugManager(appVersion, area, category, platform, source, isBeta) {
    var _this = this;

    this._appVersion = "";
    this._area = "";
    this._category = "";
    this._isBeta = true;
    this._platform = "";
    this._source = ""; // TODO: Place in constant class outside of this file.

    this.bugNotPreparedErrorMessage = "A bug must be prepared before adding optional content to it."; // Send the current Bug Object to the XCEV Service which creates a TFS bug on the app"s behalf.

    this.sendBug = function () {
      try {
        // Check for an existing bug item
        if (_this._currentBug === undefined) {
          return new Error(_this.bugNotPreparedErrorMessage);
        }

        var data = new Uint8Array(0); // Bug Category

        data = _this.appendStringToBuffer(data, "category.txt"
        /* category */
        , _this._category); // Bug Area

        data = _this.appendStringToBuffer(data, "area.txt"
        /* area */
        , _this._currentBug.tfsArea); // Feedback Source

        var source = _this._currentBug.feedbackSource + ": " + _this._currentBug.appVersion;
        data = _this.appendStringToBuffer(data, "feedbackSource.txt"
        /* feedbackSource */
        , source); // Bug Title

        data = _this.appendStringToBuffer(data, "title.txt"
        /* title */
        , _this._currentBug.title); // Bug Description

        data = _this.appendStringToBuffer(data, "description.txt"
        /* description */
        , _this._currentBug.description); // Mandatory "Program" field

        var identifier = (_this._currentBug.isBeta ? "[Internal]" : "[External]") + "[" + _this._currentBug.userExperience + "]";
        var program = {
          Result: {
            ConsoleProgram: "" + identifier,
            ContentProgram: "",
            FullyQualifiedFlightIds: [],
            GroupIds: []
          }
        };
        var programString = JSON.stringify(program);
        data = _this.appendStringToBuffer(data, "program.txt"
        /* program */
        , programString); // Optional - Reporting user (xuid + gamertag are required if sending)

        if (!!_this._currentBug.xuid && !!_this._currentBug.gamertag) {
          var reportingUserValue = "xuid: " + _this._currentBug.xuid + "\ngamertag: " + _this._currentBug.gamertag;
          data = _this.appendStringToBuffer(data, "reportingUser.txt"
          /* reportingUser */
          , reportingUserValue);
        } // Optional - Sender"s Microsoft alias


        if (!!_this._currentBug.alias) {
          data = _this.appendStringToBuffer(data, "alias.txt"
          /* alias */
          , _this._currentBug.alias);
        } // Optional - Logs of the app
        // TOOD: Consider Privacy issue, only log if user is authenticated?


        if (!!_this._currentBug.appLog) {
          data = _this.appendStringToBuffer(data, "XBXFile.log"
          /* appLog */
          , _this._currentBug.appLog);
        } // Optional - State of the app (useful in apps that rely on Flux (ex: Redux))
        // TOOD: Consider Privacy issue, only log if user is authenticated?


        if (!!_this._currentBug.appState) {
          data = _this.appendStringToBuffer(data, "appState.log"
          /* appState */
          , _this._currentBug.appState);
        } // Optional - Screenshot raw binary data as bytes (.png)


        if (!!_this._currentBug.screenshotData) {
          data = _this.appendDataToBuffer(data, "screenshot.png"
          /* screenshot */
          , _this._currentBug.screenshotData);
        }

        return data;
      } catch (error) {
        return error;
      } finally {
        _this._currentBug = undefined;
      }
    };

    this._appVersion = appVersion;
    this._area = area;
    this._category = category;
    this._platform = platform;
    this._source = source;
    this._isBeta = isBeta;
  }
  /**
   * Creates a basic Bug Object, which can then be sent to XCEV.
   * The title of the bug will be the following:
   * [Internal/External][UserSentiment][AppVersion]:|[Platform] - Page - Content...
   * You must populate optional fields on that object as needed after it"s been created.
   * @param  {string} content         The bug"s main content.  Will be used to compose the bug"s title and bug"s description
   * @param  {string} fromPage        The page on which the user invoked the Bug tool (page that preceeded the Feedback)
   * @param  {string} screenshotUri   URI of the page"s screenshot.  If invalid or empty, no screenshot will be sent in the bug"s bundle
   * @param  {string} userExperience  The user experience (ex: Like, Dislike, Crash, etc.).  Used in the title of the TFS bug
   * @param  {boolean} isBeta         Indicates if the bug is from an internal source (beta) or external (test flight, GA)
   * @returns Bug                     The Bug object, minimally ready to be POSTed.
   */


  BugManager.prototype.prepareBug = function (content, fromPage, userExperience, screenshotUri) {
    this._currentBug = new Bug(content, fromPage, this._area, this._source, this._appVersion, this._platform, userExperience, this._isBeta);
  };
  /**
   * Add extra info to the Bug"s content text, in a Key Value format.
   * Will be formatted like so in the Description field:
   * \n {key}: {value}
   * @param  {string} key
   * @param  {string} value
   * @returns void
   */


  BugManager.prototype.appendToDescription = function (key, value) {
    if (!!this._currentBug) {
      this._currentBug.addToDescription(key, value);
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  }; // TODO: Consider using a method decorator to replace all the repeating if/else blocks


  BugManager.prototype.addAlias = function (alias) {
    if (!!this._currentBug) {
      this._currentBug.alias = alias;
      this.appendToDescription("Alias", alias);
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  };

  BugManager.prototype.addAppLogs = function (log) {
    if (!!this._currentBug) {
      this._currentBug.appLog = log;
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  };

  BugManager.prototype.addAppState = function (state) {
    if (!!this._currentBug) {
      this._currentBug.appState = state;
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  };

  BugManager.prototype.addReportingUser = function (gamertag, xuid) {
    if (!!this._currentBug) {
      this._currentBug.xuid = xuid;
      this._currentBug.gamertag = gamertag;
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  };

  BugManager.prototype.addScreenshotData = function (screenshotData) {
    if (!!this._currentBug) {
      this._currentBug.screenshotData = screenshotData;
    } else {
      throw new Error(this.bugNotPreparedErrorMessage);
    }
  };
  /**
   * Append a string key | value pair to an existing Uint8Array Object
   * @param  {Uint8Array} data   existing byte array
   * @param  {string} key        key (ex: "title.txt")
   * @param  {string} value      value (ex: "Some bug title")
   * @returns Uint8Array         Concatenated byte array
   */


  BugManager.prototype.appendStringToBuffer = function (data, key, value) {
    var keyLength = key.length; // KEY:
    // 64bits prefix is count of unicode chars

    var keyIdentifier = util_1.numberToByteArray(keyLength); // Encode KEY in utf16LE per XCEV"s spec

    var keyBuffer = new ArrayBuffer(key.length * 2);
    var bufferView = new Uint16Array(keyBuffer);

    for (var i = 0; i < keyLength; i++) {
      bufferView[i] = key.charCodeAt(i);
    } // Convert KEY utf16le array to utf8 array


    var keyByteArray = new Uint8Array(keyBuffer); // VALUE:
    // Encode VALUE in utf8 per XCEV"s spec

    var utf8Value = new text_encoding_shim_1.TextEncoder("utf-8").encode(value); // 64bits prefix is count of unicode chars

    var valueIdentifier = util_1.numberToByteArray(utf8Value.length); // TOTAL:
    // Concatenate all bytes in a single Uint8Array

    var final = new Uint8Array(data.length + keyIdentifier.length + keyByteArray.length + valueIdentifier.length + utf8Value.length);
    var concat = [data, keyIdentifier, keyByteArray, valueIdentifier, utf8Value];
    var totalLength = 0;

    for (var i = 0; i < concat.length; i++) {
      final.set(concat[i], totalLength);
      totalLength += concat[i].length;
    }

    return final;
  };
  /**
   * Append a string key | Uint8Array data value pair to an existing Uint8Array Object
   * @param  {Uint8Array} data       existing byte array
   * @param  {string} key            key (ex: "screenshot.png")
   * @param  {Uint8Array} value      value (ex: screenshot binary data)
   * @returns Uint8Array             Concatenated byte array
   */


  BugManager.prototype.appendDataToBuffer = function (data, key, value) {
    var keyLength = key.length;
    var valueLength = value.length; // KEY:
    // 64bits prefix is count of unicode chars

    var keyIdentifier = util_1.numberToByteArray(keyLength); // Encode KEY in utf16LE per XCEV"s spec

    var keyBuffer = new ArrayBuffer(key.length * 2);
    var bufferView = new Uint16Array(keyBuffer);

    for (var i = 0; i < keyLength; i++) {
      bufferView[i] = key.charCodeAt(i);
    } // Convert KEY utf16le array to utf8 array


    var keyByteArray = new Uint8Array(keyBuffer); // VALUE:
    // 64bits prefix is count of unicode chars

    var valueIdentifier = util_1.numberToByteArray(valueLength); // TOTAL:
    // Concatenate all bytes in a single Uint8Array

    var final = new Uint8Array(data.length + keyIdentifier.length + keyByteArray.length + valueIdentifier.length + value.length);
    var concat = [data, keyIdentifier, keyByteArray, valueIdentifier, value];
    var totalLength = 0;

    for (var i = 0; i < concat.length; i++) {
      final.set(concat[i], totalLength);
      totalLength += concat[i].length;
    }

    return final;
  };

  return BugManager;
}();

exports.BugManager = BugManager;

var Bug =
/** @class */
function () {
  /**
   * @param  {string} content         The base content of this bug
   * @param  {string} page            The page the user saw before sending bug
   * @param  {string} tfsArea         The TFS area assigned to the app
   * @param  {string} appIdentifier   The Identifier (or Category)
   * @param  {string} appVersion      The app version number, must follow (MAIN.X.Z, ex: 1804.0.1)
   * @param  {string} platform        The platform on which the app is running (ex: Android. iOS)
   * @param  {string} userExperience  The user experience for this bug (ex: Like, Dislike, Suggestion, Crash)
   * @param  {boolean} isBeta         Indicates if this is a Beta or GA bug item.
   */
  function Bug(content, page, tfsArea, appIdentifier, appVersion, platform, userExperience, isBeta) {
    this.content = content;
    this.page = page;
    this.tfsArea = tfsArea;
    this.appIdentifier = appIdentifier;
    this.appVersion = appVersion;
    this.platform = platform;
    this.userExperience = userExperience;
    this.isBeta = isBeta; // Sanitize the content according to the File-a-bug guidelines.  Invalid characters will "break" bug creation.

    content = content.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g, ""); // remove emojis

    content = content.replace(/’/g, "'"); // iOS apostrophes cause trouble with the File-a-bug service, swap for a valid single quote.

    this.description = content;
    this.feedbackSource = "" + appIdentifier;
    this.title = "[" + platform + "] - " + page + " - " + content; // HTML Formatting

    this.description += "<br>"; // Constant description payload

    this.addToDescription("App Version", appVersion);
    this.addToDescription("Platform", platform);
  }

  Bug.prototype.addToDescription = function (key, value) {
    this.description += "<br> " + key + ": " + value;
  };

  return Bug;
}();