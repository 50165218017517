import * as React from "react";
import { ClubHub } from "@xbox/social-core";

export interface ClubSelectionContext {
    clubItem?: ClubHub.Club;
    clubId?: string;
    clubModerators?: string[];
}

export const ClubSelectionContextProvider = React.createContext<ClubSelectionContext>({
    clubId: undefined,
    clubItem: undefined,
    clubModerators: undefined
});

// wire the context to the props of the
export function withClubSelectionContext(Component: any) {
    return (props: any) => {
        return (
            <ClubSelectionContextProvider.Consumer>
                  {({ clubId, clubItem, clubModerators }) => { return ( <Component {...props} clubId={clubId} clubItem={clubItem} clubModerators={clubModerators}/> ); }}
            </ClubSelectionContextProvider.Consumer>
        );
    };
}
