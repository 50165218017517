"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authReducer = exports.AuthConnectionState = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var AuthActions_1 = require("../actions/AuthActions");

var AuthConnectionState;

(function (AuthConnectionState) {
  AuthConnectionState["unknown"] = "unknown";
  AuthConnectionState["loggedOut"] = "loggedOut";
  AuthConnectionState["loggedIn"] = "loggedIn";
})(AuthConnectionState = exports.AuthConnectionState || (exports.AuthConnectionState = {}));

var INITIAL_STATE = {
  showAuthRedirect: false,
  showAuthRedirectVisible: false,
  currentState: AuthConnectionState.unknown
};
exports.authReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(AuthActions_1.AuthActions.updateCurrentUser, function (state, action) {
  return __assign(__assign({}, state), {
    currentUser: action.payload
  });
}).on(AuthActions_1.AuthActions.authRedirectRequired, function (state, action) {
  return __assign(__assign({}, state), {
    showAuthRedirect: true,
    authRedirectUrl: action.payload.startUri,
    authRedirectEndUrl: action.payload.endUri,
    showAuthRedirectVisible: !action.payload.suppressUi,
    authRedirectPromise: action.payload.promiseCompletionSource
  });
}).on(AuthActions_1.AuthActions.authRedirectCompletedWeb, AuthActions_1.AuthActions.authRedirectCompleted, AuthActions_1.AuthActions.siSuRedirectCompletedWeb, function (state, action) {
  return __assign(__assign({}, state), {
    showAuthRedirect: false,
    authRedirectUrl: undefined,
    authRedirectEndUrl: undefined,
    showAuthRedirectVisible: false,
    authRedirectPromise: undefined
  });
}).on(AuthActions_1.AuthActions.authConnectionStateChanged, function (state, action) {
  return __assign(__assign({}, state), {
    currentState: action.payload
  });
}).on(AuthActions_1.AuthActions.authFailed, function (state, action) {
  return __assign(__assign({}, state), {
    authFailure: action.payload
  });
}).build();