"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeedbackActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var FeedbackActions;

(function (FeedbackActions) {
  FeedbackActions.submitFeedback = redux_ts_simple_1.createAction("FEEDBACK:SUBMIT_FEEDBACK");
  FeedbackActions.updateFeedbackContent = redux_ts_simple_1.createAction("FEEDBACK:UPDATE_FEEDBACK_CONTENT");
  FeedbackActions.clearFeedback = redux_ts_simple_1.createAction("FEEDBACK:CLEAR_FEEDBACK");
  FeedbackActions.submitFeedbackInProgress = redux_ts_simple_1.createAction("FEEDBACK::SUBMIT_FEEDBACK_INPROGRESS");
  FeedbackActions.submitFeedbackCompleted = redux_ts_simple_1.createAction("FEEDBACK::SUBMIT_FEEDBACK_COMPLETED");
})(FeedbackActions = exports.FeedbackActions || (exports.FeedbackActions = {}));