import styled from "styled-components";
import { List } from "antd";

const Item = List.Item.Meta;

export const ReportReason = styled(Item)`
  border-bottom: 1px solid ${props => props.theme.borderSecondary};
  padding-bottom: 5px;
  cursor: pointer;
`;

export const ItemTitle = styled.div`
    ${ReportReason}:hover & {
      text-decoration: underline;
    }
`;
