import { Select, Input, Radio } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const ErrorDiv = styled.div`
    span {
        color: ${props => props.theme.errorActive};
    }
`;

export const XboxIcon = styled.span`
    font-family: Segoe Xbox MDL2 Assets;
    padding: 0 8px 0 8px;
`;

export const VideoWrapper = styled.div`
    background-color: ${props => props.theme.blackBackground};
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
`;

export const VideoPoster = styled.img`
    height: inherit;
    width: inherit;
    max-height: inherit;
    max-width: inherit;
    object-fit: contain;
`;

export const VideoElement = styled.video`
    height: inherit;
    width: inherit;
    max-height: inherit;
    max-width: inherit;
    object-position: center;
    object-fit: contain;
`;

export const VideoActivator = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-around;
    left: 0%;
    position: absolute;
    top: 0%;
    width: 100%;
`;

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const CenteredColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const ThemedSelect = styled(Select)`
    max-width: 95%;
    .ant-select-selection-selected-value {
        color: ${props => props.theme.grayText};
    }

    span {
        color: ${props => props.theme.blackText};
    }
`;

export const DisabledSelect = styled(Select)`
    max-width: 95%;
    .ant-select-selection-selected-value {
        color: ${props => props.theme.grayShadeA};
        opacity: 0.6 !important;
    }
`;

export const SelectInput = ThemedSelect.extend`
    font-size: 18px;
    max-width: 300px;
    min-width: 200px;
    width: 100%;
    margin-right: 15px;
`;

export const TextAreaInput = styled(TextArea)`
    max-width: 400px;
    min-width: 250px;
    width: 100%;
    max-height: 300px;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 100px;
`;

export const CenteredRoundButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    width: 5rem;
    height: 5rem;
    font-size: 3rem;
    border-radius: 100%;

    border: 2px ${props => props.theme.whiteText} solid;
    background: ${props => props.theme.backgroundBlackOverlay};

    .Icon {
        color: ${props => props.theme.whiteText};
        padding: 0 0 0 4px;
    }

    &:hover{
        border-color: ${props => props.theme.grayShadeB};

        .Icon {
            color: ${props => props.theme.grayShadeB};
        }
    }
`;

export const ThemedRadio = styled(Radio as any)`
    max-width: 95%;
    .ant-radio {
        color: ${props => props.theme.foregroundOverlayed};
    }

    span {
        color: ${props => props.theme.foregroundOverlayed};
    }
`;

export const InlineContent = styled.div`
    float: left;
`;

export const InfiniteSpinnerSpan = styled.span`
    display: inline-block;
    line-height: unset;
    font-size: 4em;
    /* currently we are using fonts and animation from font-awesome.min.css */
`;

export const EmptyPageContainer = styled.div`
    font-size: 3em;
    font-weight: 500;
    background: ${props => props.theme.backgroundMain};
    border: 1px solid ${props => props.theme.borderMain};
    border-radius: 5px;
    height: 12em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
`;
