"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XalGetMaxUsers = exports.XalSignOutUserIsPresent = exports.XalSignOutUser = exports.XalGetMsaTicketForAdditionalScope = exports.XalUserResolveIssueWithUiAsync = exports.XalUserGetTokenAndSignatureSilently = exports.XalGetAnonymousTokenAndSignatureSilently = exports.XalAddUserWithUrl = exports.XalAddUserWithUi = exports.XalTryAddFirstUserSilently = exports.XalCleanup = exports.XalInitialize = exports.XalErrorType = exports.XalError = exports.XalUserChangeType = exports.XalUser = exports.XalPrivilegeCheckDenyReasons = exports.XalPrivilege = exports.XalAgeGroup = void 0;

var AuthConfig_1 = require("./AuthConfig");

var XalError_1 = require("./errors/XalError");

var XalErrorType_1 = require("./errors/XalErrorType");

var ConsoleLogTelemetryClient_1 = require("./internal/ConsoleLogTelemetryClient");

var XalInternalError_1 = require("./internal/errors/XalInternalError");

var XalInternalErrorType_1 = require("./internal/errors/XalInternalErrorType");

var MsaTicketCache_1 = require("./internal/MsaTicketCache");

var NsalDb_1 = require("./internal/NsalDb");

var State_1 = require("./internal/State");

var TokenStack_1 = require("./internal/TokenStack");

var TokenStackComponents_1 = require("./internal/TokenStackComponents");

var Utils_1 = require("./internal/Utils");

var XboxTokenCache_1 = require("./internal/XboxTokenCache");

var WebViewInternal_1 = require("./internal/WebViewInternal");

var XalUser_1 = require("./XalUser");

Object.defineProperty(exports, "XalAgeGroup", {
  enumerable: true,
  get: function get() {
    return XalUser_1.XalAgeGroup;
  }
});
Object.defineProperty(exports, "XalPrivilege", {
  enumerable: true,
  get: function get() {
    return XalUser_1.XalPrivilege;
  }
});
Object.defineProperty(exports, "XalPrivilegeCheckDenyReasons", {
  enumerable: true,
  get: function get() {
    return XalUser_1.XalPrivilegeCheckDenyReasons;
  }
});
Object.defineProperty(exports, "XalUser", {
  enumerable: true,
  get: function get() {
    return XalUser_1.XalUser;
  }
});
Object.defineProperty(exports, "XalUserChangeType", {
  enumerable: true,
  get: function get() {
    return XalUser_1.XalUserChangeType;
  }
});

var XalError_2 = require("./errors/XalError");

Object.defineProperty(exports, "XalError", {
  enumerable: true,
  get: function get() {
    return XalError_2.XalError;
  }
});

var XalErrorType_2 = require("./errors/XalErrorType");

Object.defineProperty(exports, "XalErrorType", {
  enumerable: true,
  get: function get() {
    return XalErrorType_2.XalErrorType;
  }
});
var globalState; // TODO(alkhayat): translate errors to a XalError before propagating

function getGlobalState() {
  if (!globalState) {
    throw new XalError_1.XalError(XalErrorType_1.XalErrorType.NotInitialized, "Xal must be initialized before calling this method");
  }

  return globalState;
}
/*
 * Initializes XAL for use. Must be called once per app launch, prior to calling any other APIs.
 */


function XalInitialize(initArgs, deviceInfo, cryptoFactory, storage, httpClient, webView, debug) {
  if (debug === void 0) {
    debug = false;
  }

  Utils_1.setDebugSpew(debug);

  if (!globalState) {
    var telemetryClient = new ConsoleLogTelemetryClient_1.ConsoleLogTelemetryClient();
    var platform = platformInit(initArgs, deviceInfo, cryptoFactory, telemetryClient, storage, webView, httpClient);
    globalState = new State_1.State(telemetryClient, platform);
    return globalState.init();
  } else {
    return Promise.resolve();
  }
}

exports.XalInitialize = XalInitialize;
/*
 * Cleans up XAL's state. Typically not needed unless something has gone wrong or attempting to reduce memory load.
 */

function XalCleanup() {
  globalState = undefined;
}

exports.XalCleanup = XalCleanup;
/*
 * If a user has previously signed in, this method signs them back in.
 * Otherwise, it throws an error with XalErrorType.UiRequired, indicating
 * UI must be shown to sign in a user.
 */

function XalTryAddFirstUserSilently() {
  return getGlobalState().tryAddFirstUserSilently();
}

exports.XalTryAddFirstUserSilently = XalTryAddFirstUserSilently;
/*
 * Signs in a user with appropriate UI. If a user can be silently signed in
 * (i.e. they had previously signed in), UI won't be shown and this API will
 * succeed with the pre-existing user.
 * Requires an IWebView during XalInitialize.
 */

function XalAddUserWithUi() {
  return getGlobalState().addUserWithUi();
}

exports.XalAddUserWithUi = XalAddUserWithUi;
/*
 * Signs in a user with appropriate UI. If a user can be silently signed in
 * (i.e. they had previously signed in), UI won't be shown and this API will
 * succeed with the pre-existing user.
 * Requires an IWebView during XalInitialize.
 */

function XalAddUserWithUrl(url) {
  return getGlobalState().addUserWithUrl(url);
}

exports.XalAddUserWithUrl = XalAddUserWithUrl;
/*
 * Gets an anonymous XToken and Signature for direct insertion as header values in an HTTP request.
 * Anonymous XTokens don't have any User display claims, which means user sign in is not required,
 * however, any service calls that require a user will likely fail or behave differently.
 */

function XalGetAnonymousTokenAndSignatureSilently(args) {
  // TODO(alkhayat): NOXAL: This code path is not currently implemented in XAL.
  return getGlobalState().getAnonymousTokenAndSignatureSilently(args);
}

exports.XalGetAnonymousTokenAndSignatureSilently = XalGetAnonymousTokenAndSignatureSilently;
/*
 * Gets an XToken and Signature for the specified user for direct insertion as header values in an HTTP request.
 */

function XalUserGetTokenAndSignatureSilently(user, args) {
  return getGlobalState().getTokenAndSignatureSilently(user, args);
}

exports.XalUserGetTokenAndSignatureSilently = XalUserGetTokenAndSignatureSilently;
/*
 * This method should be called when XalUserGetTokenAndSignatureSilently fails with XalErrorType.UiRequired.
 */

function XalUserResolveIssueWithUiAsync(user, url) {
  return getGlobalState().resolveUserIssueWithUi(user, url);
}

exports.XalUserResolveIssueWithUiAsync = XalUserResolveIssueWithUiAsync;
/*
 * Requests an MSA ticket for an additional scope.
 * NOTE: This method is only intended for first-party apps and it's behavior is undefined for
 * third-party apps.
 * @returns the MSA ticket for the requested scope.
 */

function XalGetMsaTicketForAdditionalScope(user, scope) {
  // TODO(alkhayat): NOXAL: This code path is not currently implemented in XAL.
  return getGlobalState().getMsaTicketForAdditionalScope(user, scope);
}

exports.XalGetMsaTicketForAdditionalScope = XalGetMsaTicketForAdditionalScope;
/*
 * This method signs out and removes the specified user.
 * Subsequent calls to XalUserGetTokenAndSignatureSilently will fail
 * if requested for this user.
 */

function XalSignOutUser(user) {
  return getGlobalState().signOutUser(user);
}

exports.XalSignOutUser = XalSignOutUser;
/*
 * Returns true if signing out is supported on this platform.
 */

function XalSignOutUserIsPresent() {
  return getGlobalState().signOutUserIsPresent();
}

exports.XalSignOutUserIsPresent = XalSignOutUserIsPresent;
/*
 * Returns the maximum number of users that can be signed in on this platform.
 */

function XalGetMaxUsers() {
  return getGlobalState().getMaxUsers();
}

exports.XalGetMaxUsers = XalGetMaxUsers;

function platformInit(initArgs, deviceInfo, cryptoFactory, telemetryClient, storage, webView, httpClient) {
  if (!initArgs.clientId || initArgs.clientId.length === 0) {
    throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Client ID must not be empty");
  }

  if (!initArgs.titleId || initArgs.titleId === 0) {
    throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Title ID is invalid");
  }

  if (!initArgs.sandbox) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Defaulting to RETAIL sandbox");
    initArgs.sandbox = "RETAIL";
  }

  var authConfig = new AuthConfig_1.AuthConfig(initArgs, AuthConfig_1.Environment.Production, deviceInfo, initArgs.thirdPartyConsents);
  var msaCache = new MsaTicketCache_1.MsaTicketCache(authConfig, storage, telemetryClient, deviceInfo.isWeb);
  var xboxCache = new XboxTokenCache_1.XboxTokenCache(authConfig, cryptoFactory, storage, telemetryClient);
  var nsalDb = new NsalDb_1.NsalDb(telemetryClient);
  var webViewInternal = new WebViewInternal_1.WebViewInternal(telemetryClient, storage, webView);
  var tokenStackComponents = new TokenStackComponents_1.TokenStackComponents(authConfig, msaCache, xboxCache, nsalDb, cryptoFactory);
  var tokenStack = new TokenStack_1.TokenStack(tokenStackComponents, telemetryClient, httpClient);
  return {
    settings: {
      maxUsers: 1,
      removeUserSupported: false,
      signOutUserSupported: true
    },
    storage: storage,
    tokenStack: tokenStack,
    webView: webViewInternal,
    httpClient: httpClient
  };
}