"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BallotBoxService = void 0;

var http_1 = require("@xbox/http");

var inversify_1 = require("inversify");

var typings_1 = require("../../typings");

var utils_1 = require("../../utils");

var BallotBoxService =
/** @class */
function () {
  function BallotBoxService(xblService, userManager) {
    this.xblService = xblService;
    this.userManager = userManager;
  }

  BallotBoxService_1 = BallotBoxService;

  BallotBoxService.prototype.createBallot = function (data) {
    var currentUser = this.userManager.currentUser;
    return this.xblService.sendRequest({
      url: BallotBoxService_1.rootUrl + "/users/xuid(" + currentUser.xuid + ")/ballots",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(data),
      headers: BallotBoxService_1.headers
    });
  };

  BallotBoxService.prototype.castVote = function (voteUri) {
    return this.xblService.sendRequest({
      url: "" + BallotBoxService_1.rootUrl + voteUri,
      method: http_1.Http.HttpRequestMethod.Put,
      headers: BallotBoxService_1.headers
    });
  };

  var BallotBoxService_1;
  BallotBoxService.headers = utils_1.GetGenericHeaders(3);
  BallotBoxService.rootUrl = "https://ballotbox.xboxlive.com";
  BallotBoxService = BallotBoxService_1 = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IUserManager)), __metadata("design:paramtypes", [Object, Object])], BallotBoxService);
  return BallotBoxService;
}();

exports.BallotBoxService = BallotBoxService;