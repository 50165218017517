var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { FeedbackActions as Actions } from "@xbox/social-redux";
import { ReportFeedbackTypes } from "@xbox/social-core";

function coreFeedbackContainer(resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.submitFeedback = function () {
          if (!_this.props.content) {
            return;
          }

          var feedback = {
            feedback: _this.props.content ? _this.props.content : "",
            clubId: _this.props.clubId,
            path: _this.props.path,
            xuid: _this.props.xuid,
            type: _this.props.feedbackType ? _this.props.feedbackType : ReportFeedbackTypes.GeneralFeedback,
            platfrom: "web",
            version: _this.props.version || ""
          };
          var payload = {
            feedback: feedback
          };

          _this.props.submitFeedback(payload);
        };

        _this.updateFeedback = function (e) {
          var payload = {
            content: e.target.value
          };

          _this.props.updateFeedback(payload);
        };

        _this.typeChanged = function (e) {
          var payload = {
            type: e
          };

          _this.props.updateFeedback(payload);
        };

        _this.captchaValid = function () {
          _this.setState({
            captchaValid: true
          });
        };

        _this.clear = function (e) {
          _this.props.clearFeedback();
        };

        _this.state = {
          captchaValid: false
        };
        return _this;
      }

      class_1.prototype.render = function () {
        var handlers = {
          submit: this.submitFeedback,
          contentChanged: this.updateFeedback,
          typeChanged: this.typeChanged,
          clear: this.clear,
          captchaValid: this.captchaValid
        };
        var canEdit = !!(this.props.content && this.props.content.length >= 1) && this.state.captchaValid;
        return resolver.getFeedbackPanel(handlers, this.props.closeFeedbackModal, canEdit, this.props.content);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    content: state.feedback.content,
    feedbackType: state.feedback.type,
    clubId: state.clubs && state.clubs.clubDetails && state.clubs.clubDetails.club && state.clubs.clubDetails.club.details.id,
    xuid: state.authentication && state.authentication.currentUser && state.authentication.currentUser.xuid,
    path: state.router.location && state.router.location.pathname
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    submitFeedback: function submitFeedback(payload) {
      return dispatch(Actions.submitFeedback(payload));
    },
    updateFeedback: function updateFeedback(payload) {
      return dispatch(Actions.updateFeedbackContent(payload));
    },
    clearFeedback: function clearFeedback() {
      return dispatch(Actions.clearFeedback());
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreFeedbackContainer(resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreFeedbackContainer(resolver));
}