"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XboxTokenData = void 0;

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var Utils_1 = require("./Utils");

var XboxTokenData =
/** @class */
function () {
  function XboxTokenData(token, expiration, titleId, deviceId, userHash, gamertag, xuid, ageGroup, privileges, userSettingsRestrictions, userEnforcementRestrictions, userTitleRestrictions, xerr, xerrIdentity) {
    if (xerr === void 0) {
      xerr = 0;
    }

    this.token = token;
    this.expiration = expiration;
    this.userHash = userHash;
    this.gamertag = gamertag;
    this.xuid = xuid;
    this.titleId = titleId;
    this.deviceId = deviceId;
    this.ageGroup = ageGroup;
    this.privileges = privileges;
    this.userSettingsRestrictions = userSettingsRestrictions;
    this.userEnforcementRestrictions = userEnforcementRestrictions;
    this.userTitleRestrictions = userTitleRestrictions;
    this.xerr = xerr;
    this.xerrIdentity = xerrIdentity;
  }

  XboxTokenData.create = function (token, expiration, titleId, deviceId, userHash, gamertag, xuid, ageGroup, privileges, userSettingsRestrictions, userEnforcementRestrictions, userTitleRestrictions) {
    var tokenData = new XboxTokenData(token, expiration, titleId, deviceId, userHash, gamertag, xuid, ageGroup, privileges, userSettingsRestrictions, userEnforcementRestrictions, userTitleRestrictions);
    return tokenData;
  };

  XboxTokenData.createFromXerr = function (xerr, xerrIdentity) {
    var tokenData = new XboxTokenData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, xerr, xerrIdentity);
    return tokenData;
  };

  XboxTokenData.deserialize = function (data) {
    return this.create(data.token, data.expiration, data.titleId, data.deviceId, data.userHash, data.gamertag, data.xuid, data.ageGroup, data.privileges, data.userSettingsRestrictions, data.userEnforcementRestrictions, data.userTitleRestrictions);
  };

  XboxTokenData.deserializeXerrResponse = function (xerrObject) {
    if (xerrObject.XErr === 0) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Xerr expected but was 0");
    }

    if (!xerrObject.Identity || xerrObject.Identity.length === 0) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "Xerr response parsed with no identity value.");
    }

    return this.createFromXerr(xerrObject.XErr, xerrObject.Identity);
  };

  XboxTokenData.deserializeTokenField = function (data) {
    if (!data) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Token field for deserialization was undefined");
      return undefined;
    }

    if (data.DisplayClaims) {
      var tid = data.DisplayClaims.xti ? parseInt(data.DisplayClaims.xti.tid, 10) : undefined;
      var xuiDisplayClaims = data.DisplayClaims.xui ? data.DisplayClaims.xui[0] : {};
      return new XboxTokenData(data.Token, data.NotAfter, tid, data.DisplayClaims.xdi ? data.DisplayClaims.xdi.did : undefined, xuiDisplayClaims.uhs, xuiDisplayClaims.gtg, xuiDisplayClaims.xid, xuiDisplayClaims.agg, xuiDisplayClaims.prv, xuiDisplayClaims.usr, xuiDisplayClaims.uer, xuiDisplayClaims.utr);
    } else {
      return new XboxTokenData(data.Token, data.NotAfter);
    }
  };

  XboxTokenData.prototype.serialize = function () {
    return this;
  };

  return XboxTokenData;
}();

exports.XboxTokenData = XboxTokenData;