import * as React from "react";
import { Tabs } from "antd";
import { CreateTextFeedItem } from "./CreateTextFeedItem";
import { CreateBallotFeedItem } from "./CreateBallotFeedItem";
import { CreateMediaFeedItem } from "./CreateMediaFeedItem";
import { CreateStoreFeedItem } from "./CreateStoreFeedItem";
import { CreateWebLinkFeedItem } from "./CreateWebLinkFeedItem";
import { CommonPostDetailsProps } from "./FeedToolsTypes";
import { CreatePostTabsDiv } from "./FeedTools.Styles";
import { Localize } from "../../common/translation/Localize";
import { GenericErrorMessage } from "../../common/GenericElements";

const TabPane = Tabs.TabPane;

export const CreatePostView: React.SFC<CommonPostDetailsProps> = props => {
    setTimeout(() => {
        document.querySelectorAll("[role='tab'][aria-selected='true']").forEach(el => {
            el.setAttribute("tabindex", "0");
            (el as HTMLElement).focus();
        });
        document.querySelectorAll("[role='tab'][aria-selected='false']").forEach(ele => {
            ele.setAttribute("tabindex", "-1");
        });
        document.querySelectorAll("[role='tablist']").forEach(element => {
            element.setAttribute("tabindex", "-1");
        });
    }, 500);

    let permissions = props.permissions;

    if (!permissions || !permissions.post) {
        return (
            <GenericErrorMessage
                errorMessage={Localize("feedtools.warning", { context: "any" })}
                showError={true}
            />
        );
    }
    const handleTabChange = (e: any) => {
        setTimeout(() => {
            document.querySelectorAll("[role='tab'][aria-selected='true']").forEach(el => {
                el.setAttribute("tabindex", "0");
                (el as HTMLElement).focus();
            });
            document.querySelectorAll("[role='tab'][aria-selected='false']").forEach(ele => {
                ele.setAttribute("tabindex", "-1");
                (ele as HTMLElement).blur();

            });
        }, 600);
    };
    const textPost = permissions.post ? (
        <TabPane tab={Localize("feedtools.tabTitles.text")} key="1">
            <CreateTextFeedItem {...props} />
        </TabPane>
    ) : null ;

    const mediaPost = permissions.postMediaFromDevice && permissions.postMediaFromXblLibrary ? (
        <TabPane tab={Localize("feedtools.tabTitles.media")} key="2">
            <CreateMediaFeedItem {...props} />
        </TabPane>
    ) : null ;

    const storePost = permissions.postStoreLink ? (
        <TabPane tab={Localize("feedtools.tabTitles.store")} key="3">
            <CreateStoreFeedItem {...props} />
        </TabPane>
    ) : null ;

    const linkPost = permissions.postWebLink ? (
        <TabPane tab={Localize("feedtools.tabTitles.weblink")} key="4">
            <CreateWebLinkFeedItem {...props} />
        </TabPane>
    ) : null ;

    const ballotPost = permissions.post ? (
        <TabPane tab={Localize("feedtools.tabTitles.poll")} key="5">
            <CreateBallotFeedItem {...props} />
        </TabPane>
    ) : null ;

    return (
        <CreatePostTabsDiv className="CreatePostTabsDiv">
            <Tabs type="card" onChange={handleTabChange}>
                {textPost}
                {mediaPost}
                {storePost}
                {linkPost}
                {ballotPost}
            </Tabs>
        </CreatePostTabsDiv>
    );
};
