import * as React from "react";
import { Route, Switch } from "react-router";
import { ClubHub } from "@xbox/social-core";

import AnalyticsContainer from "../analytics/AnalyticsContainer";
import ClubModQContainer from "../modq/ClubModQContainer";
import ClubRosterContainer from "../clubadmin/ClubRosterContainer";
import ClubInviteQueueContainer from "../clubadmin/ClubInviteQueueContainer";
import ClubSettingsContainer from "../clubadmin/ClubSettingsContainer/ClubSettingsContainer";
import ClubProfileContainer from "../clubdetails/ClubProfileContainer";
import LfgContainer from "../lfg/LfgContainer";
import ManageMediaContainer from "../../components/feedtools/ManageMediaView";
import AllPollsContainer from "../activityfeed/AllPollsFeedContainer";
import ScheduledPostContainer from "../activityfeed/ScheduledFeedContainer";
import { ClubManagerContainer } from "./ClubManagerContainer.Styles";
import { CombinedMenuWidth } from "../../common/Generic.SnapPoints";
import { ClubManagerMenu } from "./ClubManagerMenu";

interface ClubManagerContainerProps {
    club: ClubHub.Club;
}

interface ClubManagerContainerState {}

export default class ClubManager extends React.Component<ClubManagerContainerProps, ClubManagerContainerState> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let details = this.props.club.details;
        let hideMenu = (window.innerWidth < CombinedMenuWidth);
        return (
            <ClubManagerContainer className="ClubManager">
                {!hideMenu && <ClubManagerMenu clubId={details.id}/>}
                <Switch>
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/modq`} component={ClubModQContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/inviteq`} component={ClubInviteQueueContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/lfg`} component={LfgContainer} />
                    <Route path={`/app/${this.props.club.details.id}/manage/analytics/(details|summary)/:formattedFeedId?`} component={AnalyticsContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/roster`} component={ClubRosterContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/media`} component={ManageMediaContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/settings`} component={ClubSettingsContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/profile`} component={ClubProfileContainer} />
                    <Route exact={true} path={`/app/${this.props.club.details.id}/manage/scheduled`} component={ScheduledPostContainer} />
                    <Route path={`/app/${this.props.club.details.id}/manage/allpolls/(closed|open)`} component={AllPollsContainer} />
                </Switch>
            </ClubManagerContainer>
        );
    }
}
