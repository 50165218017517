"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportFeedbackTypes = void 0;
var ReportFeedbackTypes;

(function (ReportFeedbackTypes) {
  ReportFeedbackTypes["Bug"] = "BUG";
  ReportFeedbackTypes["FeatureRequest"] = "FEATURE_REQUEST";
  ReportFeedbackTypes["GeneralFeedback"] = "GENERAL_FEEDBACK";
})(ReportFeedbackTypes = exports.ReportFeedbackTypes || (exports.ReportFeedbackTypes = {}));