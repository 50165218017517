import * as React from "react";
import { Select, Row, Col, Layout, Button, message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { CoreFeedbackContainer, FeedbackHandlers, IFeedbackResolver } from "@xbox/social-views";
import { ReportFeedbackTypes } from "@xbox/social-core";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { Localize, } from "../../common/translation/Localize";
import * as Styles from "../../common/Generic.Styles";
import { Divider } from "../../common/generic/Divider";

const Option = Select.Option;
const Content = Layout.Content;
const captchaKey = "6LdxgWcUAAAAAIzIMrBzJUCeEsvfJq614ax1rqGe";

function getFeedbackPanel(handlers: FeedbackHandlers, closeFeedbackModal: () => void, canSubmit: boolean, content?: string): JSX.Element | null {
    function clear() {
        closeFeedbackModal();
    }

    function onChange(value: any) {
      console.log("Captcha value:", value);
      handlers.captchaValid();
    }

    function submit() {
        handlers.submit();
        message.success(Localize("feedback.success"));
        closeFeedbackModal();
    }

    let modal = (
        <Content>
            <Row>
                <Col>
                    <p>{Localize("feedback.feedbackType")}</p>
                    <Styles.SelectInput onChange={handlers.typeChanged} defaultValue={ReportFeedbackTypes.GeneralFeedback}>
                        <Option value={ReportFeedbackTypes.GeneralFeedback}>{Localize("feedback.general")}</Option>
                        <Option value={ReportFeedbackTypes.Bug}>{Localize("feedback.bug")}</Option>
                        <Option value={ReportFeedbackTypes.FeatureRequest}>{Localize("feedback.featureRequest")}</Option>
                    </Styles.SelectInput>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <p>{Localize("feedback.message")}</p>
                    <Styles.TextAreaInput value={content} onChange={handlers.contentChanged} />
                </Col>
            </Row>
            <Row>
                <div style={{ float: "right", marginTop: 10 }}>
                    <ReCAPTCHA
                        sitekey={captchaKey}
                        onChange={onChange}
                    />
                </div>
            </Row>
            <Divider />
            <Row>
                <div style={{float: "right"}}>
                    <Button disabled={!canSubmit} type="primary" onClick={submit} style={{ marginRight: 10 }}>{Localize("generic.submit")}</Button>
                    <Button onClick={() => clear()}>{Localize("generic.cancel")}</Button>
                </div>
            </Row>
        </Content>
    );

    return modal;
}

const FeedContainerResolver: IFeedbackResolver = {
    getFeedbackPanel: getFeedbackPanel
};

export default withClubSelectionContext(CoreFeedbackContainer(FeedContainerResolver));
