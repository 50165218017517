"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MobileWebViewValidateOperation = void 0;

var OperationBase_1 = require("../OperationBase");

var Utils_1 = require("../../Utils");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType"); // Note: The name of this class is misleading. It is named to match
// the equivalent class in XAL, but is also used for Web, since
// rehydration is also necessary for Web


var MobileWebViewValidateOperation =
/** @class */
function (_super) {
  __extends(MobileWebViewValidateOperation, _super);

  function MobileWebViewValidateOperation(telemetryClient, storage, endUri) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.storage = storage;
    _this.endUri = endUri;
    return _this;
  }

  MobileWebViewValidateOperation.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var webViewStateParamsString, webViewFlowId, webViewStateParams, webViewAdditionalArgs, additionalStoredArgs, key, value;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.storage.read("WebViewStateParams")];

          case 1:
            webViewStateParamsString = _a.sent();

            if (!webViewStateParamsString) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Could not retrieve WebViewFlowId from storage.");
              return [2
              /*return*/
              , this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NoWebViewFlowId, "Could not retrieve WebViewFlowId from storage."))];
            }

            webViewFlowId = "";
            webViewStateParams = JSON.parse(webViewStateParamsString);
            webViewFlowId = webViewStateParams.WebViewFlowId;
            Utils_1.assert(!!webViewFlowId, "WebViewFlowId field was missing in deserialized WebViewStateParams");
            webViewAdditionalArgs = webViewStateParams.WebViewAdditionalArgs;
            additionalStoredArgs = new Map();

            for (key in webViewAdditionalArgs) {
              if (webViewAdditionalArgs.hasOwnProperty(key)) {
                value = webViewAdditionalArgs[key];
                additionalStoredArgs.set(key, value);
              }
            }

            this.finishValidation(webViewFlowId, additionalStoredArgs);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  MobileWebViewValidateOperation.prototype.finishValidation = function (webViewFlowId, additionalStoredArgs) {
    // Verify url
    if (!Utils_1.isValidUri(this.endUri)) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "WebView end URI is invalid.");
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "WebView end URI is invalid."));
      return;
    } // Get WebFlowId from response parameters


    var formData = Utils_1.parseQuery(this.endUri);

    if (!formData.has("state")) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Could not find WebFlowId on Url: " + this.endUri);
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.NetworkError, "Could not find WebFlowId on Url: " + this.endUri));
      return;
    } // Verify IDs match for operation


    if (webViewFlowId !== formData.get("state")) {
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ClientError, "WebViewFlowIds did not match"));
      return;
    }

    this.succeed(additionalStoredArgs);
  };

  return MobileWebViewValidateOperation;
}(OperationBase_1.OperationBase);

exports.MobileWebViewValidateOperation = MobileWebViewValidateOperation;