import styled, { css } from "styled-components";
import { Input } from "antd";
import { Button } from "../../common/generic/Button";
import { ThemedModal } from "../../containers/Styles";
import { media } from "../../common/Generic.SnapPoints";
import { Icon } from "../../common/GenericElements";

export const ChannelsContainer = styled<{mobile: boolean}, "div">("div")`
    min-width: 200px;
    max-width: 300px;
    height: fit-content;
    margin-right: 10px;
    padding: 10px;
    border-radius: 6px 0;
    background: ${props => props.theme.clubManageOverlay};

    .active {
        margin: 0 -12px;
        padding: 10px 6px;
        border-left: 4px solid ${props => props.theme.blueRest};
        background: ${props => props.theme.clubManageActiveItem};

        button {
            margin-right: 6px;
        }
    }

    ${props => props.mobile && css`
        margin-top: -10px;
        margin-right: 2px;
        background: transparent;

        .active {
            border-right: 4px solid ${props.theme.blueRest};
            border-left: none;
            padding 10px 2px 10px 10px;
            background: ${props.theme.backgroundSecondary};
        }
    `}
`;

export const ChannelItem = styled.div`
    display: flex;
`;

export const GroupName = styled.p`
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    margin: 0px;
    margin-top: 5px;
`;

export const ChannelTag = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    margin-right: 8px;
    padding-left: 15px;
    font-size: 16px !important;
    width: 100%;
`;

export const SettingsButton = styled(Button)`
    padding: 2px 0 0 2px;
    font-size: 13px;
    background: transparent;
    border: none;
`;

export const SaveButton = styled(Button)`
    padding: 4px 5px;
    margin-left: 5px;
`;

export const SettingsModal = ThemedModal.extend`
    vertical-align: top;
    width: 100% !important;
    height: 100%;
    max-width: 960px;
    min-width: 320px;
    overflow: auto;
    position: unset !important;
    ${media.menuOpenSmall`margin: 0px !important;`}
    ${media.small`margin: 0px !important;`}

    .ant-modal-content {
        margin: auto;
        overflow: auto;
        width: 95%;
        margin-top: 5%;
        max-height: 90%;
    }

    .ant-modal-body {
        height: 100%;
        overflow: auto;
        border: 3px solid ${props => props.theme.foregroundOverlayed};
    }

    .ant-modal-footer {
        display: none;
    }

    //mobile
    ${media.small`
        .ant-modal-content {
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
            margin: 0;
        }

        .ant-modal-body {
            border: none;
        }
    `}
`;

export const ChatIcon = styled(Icon)`
    font-size: 0.7em;
    margin-left: -0.8em;
    margin-right: -0.6em;
`;

export const NameEditorInput = styled(Input)`
    display: inline;
    max-width: 250px;
`;
