import * as React from "react";
import { AnalyticsData } from "./AnalyticsData";
import * as Styles from "./Analytics.Styles";
import { GenericLoading } from "../../common/GenericElements";

export class DetailsLayout extends AnalyticsData {

    render() {
        let detailsData = <GenericLoading/>;
        if (!this.props.isLoading) {
            detailsData = (
                <div>
                    <Styles.AnalyticsRowDiv>
                        {this.props.stats && <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleStatsSummary", this.props.stats)}</Styles.AnalyticsDiv>}
                        {this.props.topEngagers && <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleTopEngagers", this.props.topEngagers)}</Styles.AnalyticsDiv>}
                    </Styles.AnalyticsRowDiv>
                    {this.props.posts && this.constructRow("analyticsView.titleFeedPosts", this.props.posts)}
                    {this.props.feedEngament && this.constructRow("analyticsView.titleFeedEngagement", this.props.feedEngament)}
                    {this.props.uniqueVisits && this.constructRow("analyticsView.titleUUVisits", this.props.uniqueVisits)}
                    {this.props.followers && this.constructRow("analyticsView.titleNewFollowers", this.props.followers)}
                    {this.props.topPosts && this.constructRow("analyticsView.titleActivityFeedPosts", this.props.topPosts)}
                    {this.props.feedEngagementByLocale && this.constructRow("analyticsView.titleEngagementByLocale", this.props.feedEngagementByLocale)}
                </div>
            );
        }

        return (
            <Styles.LayoutWrapper>
                {this.constructMonthSelector()}
                {detailsData}
            </Styles.LayoutWrapper>
        );
    }
}
