import * as React from "react";
import { Button } from "antd";
import { ITelemetryService } from "@xbox/social-core";
import * as gs from "./Generic.Styles";
import * as GlobalStyles from "../containers/Styles";
import { Glyphs } from "./Generic.Icons";
import { Localize } from "./translation/Localize";
import { StretchWidthContainer } from "../components/feedtools/FeedTools.Styles";

interface GenericErrorMessageProps {
    errorMessage?: string;
    showError?: boolean;
    style?: React.CSSProperties;
}

interface GenericModalFooterProps extends GenericErrorMessageProps {
    onOk: () => void;
    onCancel: () => void;
    okText?: JSX.Element;
    disableOK?: boolean;
}

interface GenericEmptyStateProps {
    message: string;
    subTitle?: string;
    gutters?: any;
    style?: React.CSSProperties;
}

interface IconProps {
    type: Glyphs;
    className?: string;
    onClick?: React.MouseEventHandler<any>;
    style?: React.CSSProperties;
}

interface IconFromCodeProps {
    className?: string;
    onClick?: React.MouseEventHandler<any>;
    style?: React.CSSProperties;
    value: string;
}

export const Icon = (props: IconProps) => {
    return (
        <span className={props.className} style={props.style} onClick={props.onClick}>
            <gs.XboxIcon className="Icon">{props.type}</gs.XboxIcon>
        </span>
    );
};

export const IconFromCode = (props: IconFromCodeProps) => {
    return (
        <span className={props.className} style={props.style} onClick={props.onClick}>
            <gs.XboxIcon>{props.value}</gs.XboxIcon>
        </span>
    );
};

export const GenericDisplayMode = (isVisible: boolean | undefined) => {
    return isVisible ? "block" : "none";
};

export const GenericLoading = (props: any) => {
    return (
        <StretchWidthContainer style={{textAlign: "center"}}>
            <gs.InfiniteSpinnerSpan className="fa fa-spinner fa-pulse fa-fw" />
        </StretchWidthContainer>
    );
};

export const GenericLoadingFlexDiv = (props: any) => {
    return (
        <div style={{flex: "auto", textAlign: "center"}}>
            <gs.InfiniteSpinnerSpan className="fa fa-spinner fa-pulse fa-fw" />
        </div>
    );
};

export const GenericErrorMessage: React.SFC<GenericErrorMessageProps> = props => {
    return (
        <gs.ErrorDiv style={{ display: GenericDisplayMode(props.showError) }}>
            <div style={props.style}>
                <Icon type={Glyphs.Warning}/>
                <span style={{verticalAlign: "top"}}>{props.errorMessage}</span>
            </div>
        </gs.ErrorDiv>
    );
};

export class GenericModalFooter extends React.Component<GenericModalFooterProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let okText = this.props.okText ? this.props.okText : Localize("generic.submit");

        return [(
            <Button key="back" onClick={this.props.onCancel}>
                {Localize("generic.cancel")}
            </Button>
        ), (
            <Button key="submit" className="btnColor" type="primary" onClick={this.props.onOk} disabled={this.props.disableOK}>
                {okText}
            </Button>
        ),
        <GenericErrorMessage key="error" {...this.props} />
        ];
    }
}

export const GenericEmptyState = (props: GenericEmptyStateProps) => {
    return (
        <GlobalStyles.EmptyStateContainer className="GenericEmptyState text-center" style={props.style}>
            <div style={{padding: "15px"}}>
                <div><GlobalStyles.EmptyStateTitle><Icon type={Glyphs.NoContent} /></GlobalStyles.EmptyStateTitle></div>
                <div><GlobalStyles.EmptyStateTitle>{props.message}</GlobalStyles.EmptyStateTitle></div>
                <div><GlobalStyles.EmptyStateSubTitle>{props.subTitle}</GlobalStyles.EmptyStateSubTitle></div>
            </div>
        </GlobalStyles.EmptyStateContainer>
    );
};

export const GenericEmptyPage = () => {
    return (
        <gs.EmptyPageContainer>{Localize("generic.emptyPage")}</gs.EmptyPageContainer>
    );
};

interface GenericVideoControlsProps {
    downloadUri?: string;
    posterUrl?: string;
    maxWidth?: number;
    maxHeight?: number;
    onload?: () => void;
}

export class GenericVideoPlayer extends React.Component<GenericVideoControlsProps, { videoActivated: boolean }> {

    private telemetryService: ITelemetryService | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            videoActivated: false
        };

        this.telemetryService = undefined;

        // only import telemetry service on non SSR mode
        import("../index").then((index) => {
            this.telemetryService = index.telemetryService;
        });
    }

    activateVideo = (e: any) => {
        if (this.props.downloadUri) {
            if (this.telemetryService) {
                this.telemetryService.trackEvent("VideoActivated", { "downloadUri": this.props.downloadUri });
            }
            this.setState({
                videoActivated: true
            });
            e.stopPropagation();
        }
    }

    render() {
        let maxWidth, maxHeight = "inherit";
        if (this.props.maxWidth ) {
            maxWidth = this.props.maxWidth + "px";
            maxHeight = Math.floor(this.props.maxWidth / 1.777) + "px";
        } else if (this.props.maxHeight ) {
            maxHeight = this.props.maxHeight + "px";
            maxWidth = Math.floor(this.props.maxHeight * 1.777) + "px";
        }

        return (
            <gs.VideoWrapper className="GenericVideoPlayer" style={{maxWidth, maxHeight}}>
                {!this.state.videoActivated && <gs.VideoPoster src={this.props.posterUrl}/>}
                {this.state.videoActivated &&
                    <gs.VideoElement
                        onClick={(e) => e.stopPropagation()}
                        autoPlay={true}
                        controls={this.state.videoActivated}
                        controlsList="nodownload"
                        poster={this.props.posterUrl || "/video-thumbnail-unavailable.png"}
                        preload="metadata"
                        src={this.props.downloadUri}
                    />
                }
                <gs.CenteredRoundButton className={`${this.state.videoActivated ? "hidden" : ""}`} onClick={this.activateVideo} type="button">
                    <Icon type={Glyphs.Arrow2RightSolid} />
                </gs.CenteredRoundButton>
            </gs.VideoWrapper>
        );
    }

}
