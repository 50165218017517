"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinishSignIn = void 0;

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var XboxToken_1 = require("../../XboxToken");

var GetXtoken_1 = require("./GetXtoken");

var SignInBase_1 = require("./SignInBase");

var SignIn_1 = require("./SignIn");

var GetMsaTicket_1 = require("./GetMsaTicket");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var FinishSignIn =
/** @class */
function (_super) {
  __extends(FinishSignIn, _super);

  function FinishSignIn(telemetryClient, tokenStackComponents, webView, httpClient, userChecker, responseUrl, reyhdrationParams) {
    var _this = _super.call(this, telemetryClient, tokenStackComponents, undefined, webView, userChecker, httpClient, ITelemetryClient_1.Area.FinishSignIn) || this;

    _this.responseUrl = responseUrl;
    _this.reyhdrationParams = reyhdrationParams;
    _this.operation = undefined;
    return _this;
  }

  FinishSignIn.prototype.serveRequestFromChild = function () {
    var _this = this;

    this.operation = undefined;
    this.operation = this.reyhdrationParams ? this.reyhdrationParams.get("operation") : undefined;

    if (Utils_1.isNullOrWhiteSpace(this.operation)) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "FinishSignIn failed to find operation to complete.");
      this.step.advance(SignInBase_1.SignInBaseStep.Done);
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "FinishSignIn failed to find operation to complete."));
      return true;
    }

    if (this.operation === "SignInMsa") {
      // Rehydrated while trying to sign in to MSA from SignInBase operation.
      this.step.advance(SignInBase_1.SignInBaseStep.AuthenticateWithMsa);
      var operation = GetMsaTicket_1.GetMsaTicket.createWithResponseUrl(this.telemetryClient, false, // forceRefresh,
      this.tokenStackComponents, this.httpClient, this.webView, this.responseUrl, this.reyhdrationParams, this.preexistingMsaUserId);
      operation.execute(); // Continue with SignInBase::AuthenticateWithMsaCallback as usual.

      operation.completionPromise.then(function (result) {
        return _this.authenticateWithMsaCallback(result);
      }).catch(function (error) {
        _this.fail(error);
      });
    } else if (this.operation === "MsaInterrupt") {
      // Rehydrated when authenticating with MSA while running GetXtoken from
      // GetTokenAndSignature operation. Update with new ticket if successful.
      var operation = GetMsaTicket_1.GetMsaTicket.createWithResponseUrl(this.telemetryClient, false, // forceRefresh,
      this.tokenStackComponents, this.httpClient, this.webView, this.responseUrl, this.reyhdrationParams, this.preexistingMsaUserId);
      operation.execute(); // Return user after updating

      operation.completionPromise.then(function (result) {
        return _this.finishAuthenticateWithMsaCallback(result);
      }).catch(function (error) {
        _this.fail(error);
      });
    } else if (this.operation === "SignInGetXtoken" || this.operation === "WelcomeBackSisu" || this.operation === "GetTokenAndSignature") {
      // All these operations have a stored user and should load it
      this.loadUser().catch(function (error) {
        _this.fail(error);
      });
    } else {
      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "FinishSignIn failed to complete operation");
      this.step.advance(SignInBase_1.SignInBaseStep.Done);
      this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "FinishSignIn failed to complete operation"));
      return true;
    } // Stop SignInBase from continuing as FinishSignIn handles call


    return true;
  };

  FinishSignIn.prototype.loadUser = function () {
    return __awaiter(this, void 0, void 0, function () {
      var ticket;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!!this.reyhdrationParams);
            this.preexistingMsaUserId = this.reyhdrationParams ? this.reyhdrationParams.get("msaUserId") : undefined;

            if (Utils_1.isNullOrWhiteSpace(this.preexistingMsaUserId)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "FinishSignIn failed to find msaUserId.");
              this.step.advance(SignInBase_1.SignInBaseStep.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "FinishSignIn failed to find msaUserId."));
              return [2
              /*return*/
              ];
            }

            return [4
            /*yield*/
            , this.components.msaCache.loadUser(this.preexistingMsaUserId)];

          case 1:
            ticket = _a.sent();
            this.webAccountId = ticket.userId;
            this.msaTicket = ticket.getTicket(this.components.config.userSignInScopes);
            return [4
            /*yield*/
            , this.loadXtokens()];

          case 2:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  FinishSignIn.prototype.loadXtokens = function () {
    return __awaiter(this, void 0, void 0, function () {
      var xtoken, error_1, xalInternalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!Utils_1.isNullOrWhiteSpace(this.webAccountId));
            xtoken = undefined;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 9]);

            return [4
            /*yield*/
            , this.components.xboxCache.loadTokensForUser(this.preexistingMsaUserId)];

          case 2:
            xtoken = _a.sent();
            return [3
            /*break*/
            , 9];

          case 3:
            error_1 = _a.sent();
            xalInternalError = error_1;
            if (!(xalInternalError && xalInternalError.errorType && xalInternalError.errorType === XalInternalErrorType_1.XalInternalErrorType.NoDisplayClaimsFound)) return [3
            /*break*/
            , 7];
            if (!(this.operation === "SignInGetXtoken")) return [3
            /*break*/
            , 5]; // Rehydrated on create account. Process SISU veto response and finish sign in.

            return [4
            /*yield*/
            , this.finishGetXtoken()];

          case 4:
            // Rehydrated on create account. Process SISU veto response and finish sign in.
            _a.sent();

            return [2
            /*return*/
            ];

          case 5:
            xalInternalError.errorType = XalInternalErrorType_1.XalInternalErrorType.NoUserFound;
            _a.label = 6;

          case 6:
            return [3
            /*break*/
            , 8];

          case 7:
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Operation FinishSignIn failed to load Xbox tokens with error " + error_1);
            this.step.advance(SignInBase_1.SignInBaseStep.Done);
            this.fail(error_1);
            return [2
            /*return*/
            ];

          case 8:
            return [3
            /*break*/
            , 9];

          case 9:
            this.xtoken = xtoken;
            Utils_1.assert(!!this.xtoken);
            if (!(this.operation === "WelcomeBackSisu")) return [3
            /*break*/
            , 11]; // Rehydrated on welcome back, process response to handle switch user.

            Utils_1.assert(!!this.xtoken && this.xtoken.isValid);
            return [4
            /*yield*/
            , this.handleSisuEndUri(this.responseUrl)];

          case 10:
            _a.sent();

            return [2
            /*return*/
            ];

          case 11:
            // If this is reached, it means we rehydrated from GetTokenAndSignature while showing SISU.
            // Since app is rehydrated, we assume app doesn't need token anymore and just return the user we had.
            this.succeedWithXtoken(this.xtoken);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  FinishSignIn.prototype.finishAuthenticateWithMsaCallback = function (result) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.webAccountId = result.msaUser.userId;
            this.msaTicket = result.ticket;
            this.sisuSessionId = result.sisuSessionId;
            this.sisuClusterAffinity = result.sisuClusterAffinity;
            return [4
            /*yield*/
            , this.loadXtokens()];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  FinishSignIn.prototype.finishGetXtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var accountXtoken, operation, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(SignInBase_1.SignInBaseStep.GetXtoken);
            Utils_1.assert(!!this.signInEndpoint);
            Utils_1.assert(!!this.webAccountId);
            accountXtoken = this.components.xboxCache.getToken(XboxToken_1.IdentityType.Xtoken, this.signInEndpoint.RelyingParty, this.signInEndpoint.SubRelyingParty === undefined ? "" : this.signInEndpoint.SubRelyingParty, this.signInEndpoint.TokenType, true, // hasSignInDisplayClaims
            this.webAccountId);
            operation = new GetXtoken_1.GetXtoken(this.telemetryClient, this.tokenStackComponents, this.webView, this.webAccountId, false, false, this.httpClient, accountXtoken, undefined, this.msaTicket, undefined, undefined, this.responseUrl, this.reyhdrationParams);
            operation.execute();
            result = operation.completionPromise; // Continue with sign in operation from Xtoken callback

            return [4
            /*yield*/
            , this.handleXtokenResult(result)];

          case 1:
            // Continue with sign in operation from Xtoken callback
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return FinishSignIn;
}(SignIn_1.SignIn);

exports.FinishSignIn = FinishSignIn;