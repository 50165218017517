import * as React from "react";
import { Masonry, MasonryProps, MasonryState } from "react-virtualized";

interface MasonryStrickedSizedProps extends MasonryProps {
    onRowsRendered: (params: { startIndex: number; stopIndex: number }) => void;
}

export class MasonryStrickedSized extends React.PureComponent<MasonryStrickedSizedProps, MasonryState> {
    protected readonly mansoryRef?: React.RefObject<Masonry>;

    constructor(props: any) {
        super(props);
        this.mansoryRef = React.createRef();
    }

    recomputeCellPositions() {
        if (this.mansoryRef && this.mansoryRef.current) {
            this.mansoryRef.current.recomputeCellPositions();
        }
    }

    clearCellPositions() {
        if (this.mansoryRef && this.mansoryRef.current) {
            this.mansoryRef.current.clearCellPositions();
        }
    }

    render () {
        return (
            <Masonry
                ref={this.mansoryRef}
                onCellsRendered={this.props.onRowsRendered}
                {...this.props}
            />
        );
    }
}
