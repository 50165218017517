import styled from "styled-components";

export const PopupWrapper = styled.div`
    position: absolute;
    z-index: 999;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    background: ${props => props.theme.popupBackground};
    box-shadow: 3px 3px 10px ${props => props.theme.popupBoxShadow};

    .active {
        // modifies MenuItem
        font-weight: bold;
        color: ${props => props.theme.foregroundBrigth} !important;
        background: ${props => props.theme.popupActiveOverlay};
    }
`;

export const MenuItem = styled.div`
    display: flex;
    cursor: pointer;
    padding: 4px 10px;
    margin: 0 -10px;

    .Icon {
        padding: 0 10px 0 4px;
        vertical-align: sub;
        font-size: 1.2em;
    }

    &:hover {
        background-color: ${props => props.theme.popupBoxShadow};
        color: ${props => props.theme.foregroundBrigth};

        .Icon {
            color: ${props => props.theme.foregroundBrigth}
        }
    }
`;
