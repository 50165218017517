import * as React from "react";
import { CellMeasurer, CellMeasurerCache, Index, IndexRange, InfiniteLoader } from "react-virtualized";
import { ScrollItemStatus, MIN_INFINITE_BATCH_SIZE } from "./InfiniteScrollTypes";

interface ListDataItems {
    element: JSX.Element | null;
}

type ItemGenerator = (dataItem: any, meassure: () => void) => any;

export interface BaseInfiniteScrollProps {
    updateMethod: () => Promise<boolean>;
    emptyState: (props: any) => JSX.Element;
    listData: ListDataItems[];
    defaultHeight?: number;
    isFixedWidth?: boolean;
    overscanRowCount: number;
    emptyListHeight?: number;
    itemGenerator?: ItemGenerator;
    scrollerElement?: string;
    appliedTo?: string;
}

interface InfiniteScrollState {
    loadedRowsMap: { [key: number]: number };
    cache: CellMeasurerCache;
    mainContentWindow: any;
}

export class BaseInfiniteScrollList extends React.Component<BaseInfiniteScrollProps, InfiniteScrollState> {
    protected loading: boolean;

    constructor(props: BaseInfiniteScrollProps) {
        super(props);
        this.loading = false;

        this.state = {
            loadedRowsMap: {},
            cache: new CellMeasurerCache({
                defaultHeight: props.defaultHeight || 100,
                fixedWidth: props.isFixedWidth || true,
                fixedHeight: props.defaultHeight !== undefined
            }),
            mainContentWindow: window
        };
    }

    componentWillUpdate(nextProps: any) {
        // update cellMeasurerCahce if needed
        if (this.props.defaultHeight !== nextProps.defaultHeight) {
            this.setState({
                cache: new CellMeasurerCache({
                    defaultHeight: nextProps.defaultHeight || 100,
                    fixedWidth: nextProps.isFixedWidth || true,
                    fixedHeight: nextProps.defaultHeight !== undefined
                }),
            });
        }
    }

    getScrollerElement() {
        let elementId = this.props.scrollerElement ? this.props.scrollerElement : "MainContentWindow";
        let contentWindow = document.getElementById(elementId);

        if (contentWindow) {
             this.setState({mainContentWindow: contentWindow});
        }
    }

    clearCache() {
        console.warn("Clear Infinite Scroll List Cache");
        if (this.state && this.state.cache) {
            this.state.cache.clearAll();
        }
    }

    /*********************************************************************/
    /* InfiniteLoader related functions
    /*********************************************************************/
    noRowsRenderer = () => {
        return (<this.props.emptyState />);
    }

    rowRenderer = (info: { index: any, isScrolling: any, key: any, parent: any, style: any }) => {
        let source = this.props.listData[info.index];
        return (
            <CellMeasurer
                cache={this.state.cache}
                columnIndex={0}
                key={info.key}
                parent={info.parent}
                rowIndex={info.index}
            >
                {({ measure }) => (
                    // 'style' attribute required to position cell (within parent List)
                    // use custom item generator to allow discrete meassure
                    this.props.itemGenerator
                        ?   <div style={info.style}>
                                {this.props.itemGenerator(source, measure)}
                            </div>
                        : // 'style' attribute required to position cell (within parent List)
                            <div style={info.style} onLoad={measure}>
                                {source}
                            </div>

                )}
            </CellMeasurer>
        );
    }

    isRowLoaded = (index: Index): boolean => {
        return !!this.state.loadedRowsMap[index.index]; // STATUS_LOADING or STATUS_LOADED
    }

    loadMoreRows = (range: IndexRange): Promise<boolean> => {
        this.loading = true;

        // set to STATUS_LOADING before loading next increment
        for (var i = range.startIndex; i <= range.stopIndex; i++) {
            this.state.loadedRowsMap[i] = ScrollItemStatus.Loading;
        }

        return new Promise((resolve, reject) => {
            (this.props.updateMethod())
                .then(() => {
                    for (var j = range.startIndex; j <= range.stopIndex; j++) {
                        this.state.loadedRowsMap[j] = ScrollItemStatus.Loaded;
                    }
                    resolve(true);
                });
        });
    }

    /*********************************************************************/
    /* Default render
    /*********************************************************************/
    getScrollerContent = (onRowsRendered: any): JSX.Element => {
        return <div className="BaseInfiniteScrollList"/>;
    }

    render() {
        return (
            <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.loadMoreRows}
                rowCount={this.props.listData.length}
                minimumBatchSize={MIN_INFINITE_BATCH_SIZE}
            >
                {({onRowsRendered, registerChild}) => (
                    this.getScrollerContent(onRowsRendered)
                )}
            </InfiniteLoader>
        );
    }
}
