"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryMsaTicketCache = void 0;

var InMemoryMsaTicketCache =
/** @class */
function () {
  function InMemoryMsaTicketCache(authConfig) {
    this.authConfig = authConfig;
    this.users = new Map();
  }

  InMemoryMsaTicketCache.prototype.getUser = function (msaUserId) {
    var lowercaseId = msaUserId.toLowerCase();
    return this.users.get(lowercaseId);
  };

  InMemoryMsaTicketCache.prototype.insertToken = function (msaTicketSet) {
    this.users.set(msaTicketSet.userId, msaTicketSet);
  };

  InMemoryMsaTicketCache.prototype.clearTokensForUser = function (msaUserId) {
    var lowercaseId = msaUserId.toLowerCase();
    this.users.delete(lowercaseId);
  };

  InMemoryMsaTicketCache.prototype.insertSessionInfo = function (sessionInfo) {
    this.sessionInfo = sessionInfo;
  };

  InMemoryMsaTicketCache.prototype.getSessionInfo = function () {
    return this.sessionInfo;
  };

  InMemoryMsaTicketCache.prototype.clearSessionInfo = function () {
    this.sessionInfo = undefined;
  };

  return InMemoryMsaTicketCache;
}();

exports.InMemoryMsaTicketCache = InMemoryMsaTicketCache;