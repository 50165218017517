import styled from "styled-components";
import { Icon } from "../../common/GenericElements";

export const Title = styled.h1`
    font-size: 18px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
`;
export const GamerscoreIcon = styled(Icon)`
    vertical-align: text-top;
    line-height: .8em;
    font-size: 1.5em;

    span {
        padding: 0 8px 0 0;
    }
`;

export const BodyLabel = styled.span`
    font-size: .88em;
`;
