"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationQueue = void 0;

var OperationQueue =
/** @class */
function () {
  function OperationQueue() {
    this.operationQueue = [];
    this.currentOperation = null;
  }

  OperationQueue.prototype.queueOperation = function (operation) {
    console.debug("Operation queued");
    this.operationQueue.push(operation);
    this.runNextOperation();
  };

  OperationQueue.prototype.runNextOperation = function () {
    var _this = this;

    if (this.currentOperation) {
      console.debug("Operation is already running");
      return;
    }

    if (this.operationQueue.length === 0) {
      console.debug("Operation queue is empty");
      return;
    }

    var nextOperation = this.operationQueue.shift();

    if (!nextOperation) {
      console.warn("Operation queue was not empty but dequeue returned null");
      return;
    }

    this.currentOperation = nextOperation;
    var cancelationPromise = this.createTimeoutPromise();
    Promise.race([this.currentOperation.execute(), cancelationPromise]).then(function (_) {
      cancelationPromise.catch(function (__) {});
      console.debug("Operation completed");
      _this.currentOperation = null;

      _this.runNextOperation();
    }).catch(function (error) {
      console.debug("Operation failed with error " + error);
      _this.currentOperation = null;

      _this.runNextOperation();
    });
  };

  OperationQueue.prototype.createTimeoutPromise = function () {
    return new Promise(function (_, reject) {
      setTimeout(function () {
        reject("Operation timed out");
      }, 10000);
    });
  };

  return OperationQueue;
}();

exports.OperationQueue = OperationQueue;