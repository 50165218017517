"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getImagePicker = void 0;

function getImagePicker(state) {
  return {
    hasImages: state.imagePicker.hasImages,
    images: state.imagePicker.images,
    clubId: state.clubs && state.clubs.clubDetails && state.clubs.clubDetails.club && state.clubs.clubDetails.club.details.id,
    xuid: state.authentication && state.authentication.currentUser && state.authentication.currentUser.xuid,
    upload: state.mediahub.upload
  };
}

exports.getImagePicker = getImagePicker;