import * as React from "react";
import { DatePicker } from "antd";
import * as moment from "moment";
import * as Styles from "./Analytics.Styles";
import { Divider } from "../../common/generic/Divider";
import { Localize } from "../../common/translation/Localize";

const { MonthPicker } = DatePicker;

interface AnalyticsDataComponents {
    stats?: JSX.Element | null;
    topEngagers?: JSX.Element | null;
    posts?: JSX.Element | null;
    feedEngament?: JSX.Element | null;
    followers?: JSX.Element | null;
    uniqueVisits?: JSX.Element | null;
    feedEngagementByLocale?: JSX.Element | null;
    topPosts?: JSX.Element | null;
    gender?: JSX.Element | null;
    age?: JSX.Element | null;
    locale?: JSX.Element | null;
}

interface AnalyticsDataProps {
    isLoading: boolean;
    month?: string;
    OnMonthChanged: (date: any) => void;
    ExportAnalyticsReport?: () => void;
}

type AnalyticsProps = AnalyticsDataProps & AnalyticsDataComponents;

export abstract class AnalyticsData extends React.Component<AnalyticsProps> {
    constructor(props: any) {
        super(props);
    }

    constructMonthSelector() {
        let defaultMonth;
        if (this.props.month) {
            defaultMonth = moment().month(parseInt(this.props.month, 10) - 1);
        }

        return (
            <Styles.MarginDiv50L20B>
                <MonthPicker defaultValue={defaultMonth} allowClear={false} format={"MMM-YYYY"} onChange={this.props.OnMonthChanged} placeholder={Localize("analyticsView.last30days")} />
                <Styles.ExportButton onClick={this.props.ExportAnalyticsReport}>
                    {Localize("analyticsView.buttonExport")}
                </Styles.ExportButton>
            </Styles.MarginDiv50L20B>
        );
    }

    constructRow(translationKey: string, dataElement: JSX.Element | null) {
        if (dataElement === null) {
            return null;
        } else {
            return (
                <Styles.DataContainer>
                    <Styles.ItemWrapper>
                        <h2>{Localize(translationKey)}</h2>
                        {dataElement}
                    </Styles.ItemWrapper>
                    <Divider />
                </Styles.DataContainer>
            );
        }
    }
}
