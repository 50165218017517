import * as React from "react";
import { MsStoreGameInfo } from "@xbox/social-redux";
import * as msgStyle from "./MsGameCatalog.Styles";
import { Icon, GenericDisplayMode } from "../../../common/GenericElements";
import { Glyphs } from "../../../common/Generic.Icons";
import { Localize } from "../../../common/translation/Localize";

interface PreviewGameItemProps {
    gameInfo: MsStoreGameInfo;
    showDelete: boolean;
    onDelete?: () => void;
}

export const PreviewGameItem = (props: PreviewGameItemProps) => {
    let listPrice: JSX.Element | null = null;
    if (props.gameInfo.listPrice && props.gameInfo.listPrice > 0) {
        listPrice = (<div>{`${props.gameInfo.listPrice} ${props.gameInfo.currency}`}</div>);
    }

    return (
        <msgStyle.PreviewGameContainer>
                <msgStyle.PreviewGameImage src={props.gameInfo.posterUrl} alt={props.gameInfo.voiceTitle}/>
                <div>
                    <msgStyle.GameTitle>{props.gameInfo.productTitle}</msgStyle.GameTitle>
                    <msgStyle.GamePublisher>{props.gameInfo.publisher}</msgStyle.GamePublisher>
                    {props.gameInfo.averageRating && <div>
                        {Localize("postFeedItem.gameRating", {rating: props.gameInfo.averageRating, userCount: props.gameInfo.ratingCount})}
                    </div>}
                    {listPrice}
                </div>

            <msgStyle.RightUpButton iconOnly={true} style={{ display: GenericDisplayMode(props.showDelete) }} onClick={props.onDelete}>
                <Icon type={Glyphs.Delete}/>
            </msgStyle.RightUpButton>
        </msgStyle.PreviewGameContainer>
    );
};
