"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.feedViewerRedux = exports.getFeedItemsHydrationState = exports.getStringValidationState = exports.getLinkHydrationState = exports.getScheduledActivityFeed = exports.getBallotActivityFeed = exports.getSelectedActivityFeedItem = exports.getActivityFeed = void 0;

var _ = require("lodash");

var FeedViewerReducers_1 = require("./FeedViewerReducers");

var ActivityFeedNormalizer_1 = require("./ActivityFeedNormalizer");

var ActivityFeedActions_1 = require("../../actions/ActivityFeedActions"); // selectors


function getActivityFeed(state) {
  if (state.feedViewer.activityFeed.activities) {
    return {
      currentUser: state.authentication.currentUser,
      results: ActivityFeedNormalizer_1.activityFeedDenormalizer(state.feedViewer.activityFeed.activities.result, state.feedViewer.activityFeed.activities.entities),
      completed: !state.feedViewer.activityFeed.fetchInProgress,
      cursor: state.feedViewer.activityFeed.activities.cursor,
      selectedFeedItem: state.feedViewer.activityFeed.selectedFeedItem
    };
  } else {
    return {
      currentUser: state.authentication.currentUser,
      completed: !state.feedViewer.activityFeed.fetchInProgress
    };
  }
}

exports.getActivityFeed = getActivityFeed;

function getSelectedActivityFeedItem(state) {
  if (state.feedViewer.activityFeed.selectedFeedItem) {
    return {
      currentUser: state.authentication.currentUser,
      selectedFeedItem: state.feedViewer.activityFeed.selectedFeedItem,
      isCommentLocked: state.feedViewer.activityFeed.isCommentLocked,
      completed: !state.feedViewer.activityFeed.fetchInProgress
    };
  } else {
    return {
      currentUser: state.authentication.currentUser,
      completed: false
    };
  }
}

exports.getSelectedActivityFeedItem = getSelectedActivityFeedItem;

function getBallotActivityFeed(state) {
  var activityFeed = state.feedViewer.activityFeed;

  if (activityFeed.activities) {
    var results = ActivityFeedNormalizer_1.activityFeedDenormalizer(activityFeed.activities.result, activityFeed.activities.entities).filter(function (result) {
      return !!result;
    });
    var openResults = [];
    var closedResults = [];
    var seedTime = new Date();

    for (var i = 0; i < results.length; i++) {
      if (!results[i].pollData) {
        continue;
      }

      if (seedTime < new Date(results[i].pollData.endTime)) {
        openResults.push(results[i]);
      } else {
        closedResults.push(results[i]);
      }
    }

    return {
      results: results,
      openBallots: openResults,
      closedBallots: closedResults,
      completed: !activityFeed.fetchInProgress
    };
  } else {
    return {
      completed: !activityFeed.fetchInProgress
    };
  }
}

exports.getBallotActivityFeed = getBallotActivityFeed;

function getScheduledActivityFeed(state) {
  if (state.feedViewer.activityFeed.activities) {
    var unOrderedResults = ActivityFeedNormalizer_1.activityFeedDenormalizer(state.feedViewer.activityFeed.activities.result, state.feedViewer.activityFeed.activities.entities);

    var orderedResults = _.cloneDeep(unOrderedResults);

    orderedResults.sort(function (item1, item2) {
      if (item1.date > item2.date) {
        return 1;
      }

      if (item1.date < item2.date) {
        return -1;
      }

      return 0;
    });
    return {
      results: orderedResults,
      completed: !state.feedViewer.activityFeed.fetchInProgress
    };
  } else {
    return {
      completed: !state.feedViewer.activityFeed.fetchInProgress
    };
  }
}

exports.getScheduledActivityFeed = getScheduledActivityFeed;

function getLinkHydrationState(state) {
  return {
    inProgress: state.inProgress,
    linkData: state.linkData
  };
}

exports.getLinkHydrationState = getLinkHydrationState;

function getStringValidationState(state) {
  return {
    inProgress: state.inProgress,
    resultData: state.resultData
  };
}

exports.getStringValidationState = getStringValidationState;

function getFeedItemsHydrationState(state) {
  if (state.feedItems) {
    return {
      inProgress: state.inProgress,
      feedItems: ActivityFeedNormalizer_1.activityFeedDenormalizer(state.feedItems.result, state.feedItems.entities)
    };
  } else {
    return {
      inProgress: state.inProgress
    };
  }
}

exports.getFeedItemsHydrationState = getFeedItemsHydrationState;
exports.feedViewerRedux = {
  actions: __assign({}, ActivityFeedActions_1.ActivityFeedActions),
  reducer: FeedViewerReducers_1.aggregatedFeedViewerReducer,
  selectors: {
    getActivityFeed: getActivityFeed,
    getSelectedActivityFeedItem: getSelectedActivityFeedItem,
    getScheduledActivityFeed: getScheduledActivityFeed,
    getBallotActivityFeed: getBallotActivityFeed,
    getLinkHydrationState: getLinkHydrationState,
    getFeedItemsHydrationState: getFeedItemsHydrationState,
    getStringValidationState: getStringValidationState
  }
};