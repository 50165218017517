"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConvertMsCatalogGamesToGamesInfo = void 0;

var _ = require("lodash");

exports.ConvertMsCatalogGamesToGamesInfo = function (data) {
  var result = [];

  if (data) {
    data.Products.forEach(function (t) {
      return result.push(getGameInfo(t));
    });
  }

  return result;
};

var getGameInfo = function getGameInfo(item) {
  var usageData = getUsageData(item);
  var price = getPrice(item);
  var localProp = item.LocalizedProperties[0]; // TODO: check that all localProp data exists (theoretically it should always be there)

  var info = {
    posterUrl: getPosterUri(item),
    backgroundImageUrl: getScreenshotUri(item),
    logoUrl: getLogo(item),
    boxArtUrl: getBoxArt(item),
    productTitle: localProp.ProductTitle,
    titleId: getTitleId(item.AlternateIds),
    voiceTitle: localProp.VoiceTitle ? localProp.VoiceTitle : localProp.ProductTitle,
    publisher: localProp.PublisherName,
    productId: item.ProductId,
    averageRating: usageData ? usageData.AverageRating : undefined,
    ratingCount: usageData ? usageData.RatingCount : undefined,
    listPrice: price && price.ListPrice !== 0 ? price.ListPrice : undefined,
    currency: price ? price.CurrencyCode : undefined
  };
  return info;
};
/*********************************************************************/

/* Helper functions
/*********************************************************************/


var ImagePurpose;

(function (ImagePurpose) {
  // There are a lot of purposes, mention only once we are interested in
  ImagePurpose["Poster"] = "Poster";
  ImagePurpose["BoxArt"] = "BoxArt";
  ImagePurpose["Logo"] = "Logo";
  ImagePurpose["TitledHeroArt"] = "TitledHeroArt";
  ImagePurpose["ImageGallery"] = "ImageGallery";
  ImagePurpose["Screenshot"] = "Screenshot"; // 1920x1080
})(ImagePurpose || (ImagePurpose = {}));

var getPosterUri = function getPosterUri(item) {
  if (item.LocalizedProperties.length === 0) {
    return "";
  }

  var images = item.LocalizedProperties[0].Images;
  var data = images.find(function (img) {
    return img.ImagePurpose === ImagePurpose.Poster;
  });
  return data ? data.Uri : images.length > 0 ? images[0].Uri : "";
};

var getTitleId = function getTitleId(idList) {
  var val = _.find(idList, function (id) {
    return id.IdType === "XboxTitleId";
  });

  return val ? val.Value : "";
};

var getBoxArt = function getBoxArt(item) {
  if (item.LocalizedProperties.length === 0) {
    return "";
  }

  var images = item.LocalizedProperties[0].Images;
  var data = images.find(function (img) {
    return img.ImagePurpose === ImagePurpose.BoxArt;
  });
  return data ? data.Uri : "";
};

var getLogo = function getLogo(item) {
  if (item.LocalizedProperties.length === 0) {
    return "";
  }

  var images = item.LocalizedProperties[0].Images;
  var data = images.find(function (img) {
    return img.ImagePurpose === ImagePurpose.Logo;
  });
  return data ? data.Uri : "";
};

var getScreenshotUri = function getScreenshotUri(item) {
  if (item.LocalizedProperties.length === 0) {
    return "";
  }

  var images = item.LocalizedProperties[0].Images;
  var data = images.find(function (img) {
    return img.ImagePurpose === ImagePurpose.TitledHeroArt;
  });

  if (!data) {
    // If no Titled hero art specified - use a screenshot from the game
    data = images.find(function (img) {
      return img.ImagePurpose === ImagePurpose.ImageGallery || img.ImagePurpose === ImagePurpose.Screenshot;
    });
  }

  return data ? data.Uri : "";
};

var getUsageData = function getUsageData(item) {
  if (item.MarketProperties[0] || item.MarketProperties[0].UsageData.length === 0) {
    return null;
  }

  var usageData = item.MarketProperties[0].UsageData;
  var data = usageData.find(function (usg) {
    return usg.AggregateTimeSpan === "AllTime";
  });
  return data ? data : usageData[0];
};

var getPrice = function getPrice(item) {
  if (item.DisplaySkuAvailabilities.length === 0 || item.DisplaySkuAvailabilities[0].Availabilities.length === 0) {
    return null;
  }

  if (item.DisplaySkuAvailabilities[0].Availabilities[0].Actions.indexOf("Purchase") === -1) {
    return null;
  }

  return item.DisplaySkuAvailabilities[0].Availabilities[0].OrderManagementData.Price;
};