"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.partyReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var PartyActions_1 = require("../../actions/PartyActions");

var INITIAL_STATE = {
  remoteMediaStreams: [],
  members: [],
  inParty: false,
  inviteSent: false
};
exports.partyReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(PartyActions_1.PartyActions.partyInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    inParty: action.payload
  });
}).on(PartyActions_1.PartyActions.inviteByGamertag, PartyActions_1.PartyActions.inviteFriend, function (state, action) {
  return __assign(__assign({}, state), {
    inviteSent: false
  });
}).on(PartyActions_1.PartyActions.inviteSent, function (state, action) {
  return __assign(__assign({}, state), {
    inviteSent: true
  });
}).on(PartyActions_1.PartyActions.remoteStreamAdded, function (state, action) {
  return __assign(__assign({}, state), {
    remoteMediaStreams: __spreadArrays(state.remoteMediaStreams, [action.payload])
  });
}).on(PartyActions_1.PartyActions.membersAdded, function (state, action) {
  return __assign(__assign({}, state), {
    members: __spreadArrays(state.members, action.payload)
  });
}).on(PartyActions_1.PartyActions.membersRemoved, function (state, action) {
  return __assign(__assign({}, state), {
    members: state.members.filter(function (member) {
      return !action.payload.some(function (removedMember) {
        return removedMember.xuid === member.xuid;
      });
    })
  });
}).build();