"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleHubActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var TitleHubActions;

(function (TitleHubActions) {
  TitleHubActions.fetchAllTitles = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_ALL");
  TitleHubActions.fetchAllTitlesInProgress = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_ALL_INPROGRESS");
  TitleHubActions.fetchAllTitlesCompleted = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_ALL_COMPLETED");
  TitleHubActions.fetchTitlesHistory = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_HISTORY");
  TitleHubActions.fetchTitlesHistoryInProgress = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_HISTORY_INPROGRESS");
  TitleHubActions.fetchTitlesHistoryCompleted = redux_ts_simple_1.createAction("TITLE::TITLE_FETCH_HISTORY_COMPLETED");
  TitleHubActions.fetchTitleByPfn = redux_ts_simple_1.createAction("TITLE::TITLE_CHECK_PFN");
  TitleHubActions.fetchTitleByPfnInProgress = redux_ts_simple_1.createAction("TITLE::TITLE_CHECK_PFN_INPROGRESS");
  TitleHubActions.fetchTitleByPfnCompleted = redux_ts_simple_1.createAction("TITLE::TITLE_CHECK_PFN_COMPLETED");
  TitleHubActions.searchMsCatalog = redux_ts_simple_1.createAction("TITLE::SEARCH_MS_CATALOG");
  TitleHubActions.searchMsCatalogInProgress = redux_ts_simple_1.createAction("TITLE::SEARCH_MS_CATALOG_INPROGRESS");
  TitleHubActions.searchDesktopMsCatalogCompleted = redux_ts_simple_1.createAction("TITLE::SEARCH_XBOX_MS_CATALOG_COMPLETED");
  TitleHubActions.searchXboxMsCatalogCompleted = redux_ts_simple_1.createAction("TITLE::SEARCH_DESKTOP_MS_CATALOG_COMPLETED");
})(TitleHubActions = exports.TitleHubActions || (exports.TitleHubActions = {}));