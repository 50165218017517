import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Localize } from "../../../common/translation/Localize";
import { BaseBarChart } from "./BaseBarChart";
import { AnalyticsColor } from "../../../common/theme/Colors";
import { CustomizedLegend } from "./BaseBarChart";

export class FeedEngamentBars extends BaseBarChart {

    InitializeMonth = (month: string) => {
        this.CreateMonth(month, this.props.timesliceData, this.getEngagementsByDate);
    }

    Initialize30Days = (timestamp: string) => {
        this.Create30Days(timestamp, this.props.timesliceData, this.getEngagementsByDate);
    }

    getEngagementsByDate(date: any, dataList: any) {
        let data: any = _.find(dataList, (item: any) => date === moment(item.timestamp, "X").utc().format("MM/DD"));
        if (data) {
            return data.engagements;
        } else {
            return {
                "Comment": 0,
                "Like": 0,
                "Share": 0,
                "Total": 0
            };
        }
    }

    render() {
        if (!this.props.timesliceData) {
            return this.constructWatermark("analyticsView.engagementWatermark");
        }
        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={600} height={300} data={this.state.chartData}>
                    {/* if you want to add dash to grid, add this property strokeDasharray="0 0" */}
                    <CartesianGrid vertical={false} horizontal={false} />
                    <XAxis dataKey="timestamp" axisLine={false} tickLine={false} />
                    <YAxis axisLine={false} tickLine={false} allowDecimals={false} tickFormatter={this.tickformatter} />
                    <Tooltip itemSorter={() => 1} />
                    <Legend content={CustomizedLegend} wrapperStyle={{ width: "auto" }} align="right" verticalAlign="top" />
                    <Bar dataKey="Like" name={Localize("analyticsView.chartLikes")} stackId="a" fill={AnalyticsColor.green} />
                    <Bar dataKey="Comment" name={Localize("analyticsView.chartComments")} stackId="a" fill={AnalyticsColor.purple} />
                    <Bar dataKey="Share" name={Localize("analyticsView.chartShares")} stackId="a" fill={AnalyticsColor.blue} />
                </BarChart >
            </ResponsiveContainer>
        );
    }
}
