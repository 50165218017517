import styled from "styled-components";
import { ImageFade } from "../../styles/ImageStyles";

export const SquareImage = styled(ImageFade)`
    width: 100%;
    height: 100%;
`;

export const SquareImageContainer = styled.a`
    padding: 0px;
    border: 0px;
    display: inline-block;
    transition: 0.3s;

    &:hover  {
        opacity: 0.7;
    }
`;
