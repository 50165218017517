var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { ActivityFeedActions, feedViewerRedux, CancellationTokenSource } from "@xbox/social-redux";
import { ActivityFeed } from "@xbox/social-core";
import { FeedItemUtils } from "../../Utils";

function coreAggregatesContainer(aggregatesResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.feedType = ActivityFeed.FeedType.All;

        _this.requestFeed = function (props) {
          switch (props.match.params.pathname) {
            case ActivityFeed.ActivityFeedTypes.friends:
              _this.feedType = ActivityFeed.FeedType.People;
              break;

            case ActivityFeed.ActivityFeedTypes.trending:
              _this.feedType = ActivityFeed.FeedType.Trending;
              break;

            case ActivityFeed.ActivityFeedTypes.clubs:
              _this.feedType = ActivityFeed.FeedType.Clubs;
              break;

            default:
              _this.feedType = ActivityFeed.FeedType.All;
              break;
          }

          _this.fetchFeed();
        };

        _this.castVote = function (item, choice) {
          var request = {
            ballotChoice: choice,
            ballotChoiceUri: item.pollData.choices[choice].voteUri,
            itemRoot: item.itemRoot,
            feedItemId: item.feedItemId
          };

          _this.props.castVote(request);
        };

        _this.handleLikeClick = function (item, hasLiked) {
          var request = {
            itemRoot: item.itemRoot,
            like: hasLiked,
            itemType: item.activityItemType
          };

          _this.props.updateLike(request);
        };

        _this.fetchFeed = function (cursor) {
          if (_this.cancellationSource) {
            _this.cancellationSource.cancel();
          }

          _this.cancellationSource = new CancellationTokenSource();

          _this.props.fetchActivityFeed({
            type: _this.feedType,
            numItems: 20,
            cancellationToken: _this.cancellationSource.getToken(),
            excludedTypes: [ActivityFeed.ActivityItemTypes.Achievement, ActivityFeed.ActivityItemTypes.Container, ActivityFeed.ActivityItemTypes.SocialRecommendation],
            cursor: cursor
          });
        };

        _this.fetchCommentLock = function (item) {
          var lockPayload = {
            itemRoot: item.itemRoot
          };

          _this.props.loadCommentLock(lockPayload);

          _this.props.feedItemSelected(item);
        };

        _this.navigateCommentsPage = function (item) {
          _this.fetchCommentLock(item);

          var payload = {
            cursor: item.comments ? item.comments.cursor : "",
            postId: item.itemRoot,
            numberOfItems: 10
          };

          _this.props.loadComments(payload);

          if (item.timeline) {
            _this.props.navigateViewer(_this.feedType.toLowerCase(), FeedItemUtils.parseFeedItemId(item.itemRoot), FeedItemUtils.parseClubId(item.itemRoot));
          } else {
            _this.props.navigateViewer(_this.feedType.toLowerCase(), FeedItemUtils.parseFeedItemId(item.itemRoot), FeedItemUtils.parseUserId(item.itemRoot));
          }
        };

        _this.requestFeed(_this.props);

        return _this;
      }

      class_1.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.match.params.pathname !== this.props.match.params.pathname) {
          if (this.cancellationSource) {
            this.cancellationSource.cancel();
          }

          this.requestFeed(nextProps);
        }
      };

      class_1.prototype.componentWillUnmount = function () {
        // On Unmount, cancel any outstanding request
        if (this.cancellationSource) {
          this.cancellationSource.cancel();
        }
      };

      class_1.prototype.render = function () {
        var socialActions = {
          like: this.handleLikeClick,
          vote: this.castVote,
          viewComments: this.navigateCommentsPage
        };
        var feedStatus = {
          results: this.props.results,
          isValid: this.props.isValid,
          isErrored: this.props.isCompleted && !this.props.results
        };
        return aggregatesResolver.getAggregatesContainer(this.fetchFeed, this.props.clearActivityFeed, this.props.feedItemSelected, socialActions, feedStatus, this.props.cursor);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var activityFeed = feedViewerRedux.selectors.getActivityFeed(state);

  if (activityFeed.currentUser === undefined) {
    throw new Error("undefined User");
  }

  return {
    currentUser: activityFeed.currentUser,
    results: activityFeed.results,
    cursor: activityFeed.cursor,
    isValid: state.feedViewer.activityFeed.activities ? state.feedViewer.activityFeed.activities.feedId === state.feedViewer.activityFeed.selectedFeedId : false,
    isCompleted: activityFeed.completed
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    loadComments: function loadComments(payload) {
      return dispatch(feedViewerRedux.actions.fetchFeedComments(payload));
    },
    navigateViewer: function navigateViewer(activeFeed, formattedId, clubId) {
      return dispatch(push("/app/home/" + activeFeed + "/post/" + formattedId + "/" + clubId));
    },
    loadCommentLock: function loadCommentLock(payload) {
      return dispatch(feedViewerRedux.actions.loadCommentLock(payload));
    },
    fetchActivityFeed: function fetchActivityFeed(request) {
      return dispatch(ActivityFeedActions.fetchPersonalFeedItems(request));
    },
    clearActivityFeed: function clearActivityFeed() {
      return dispatch(ActivityFeedActions.clearFeedState());
    },
    updateLike: function updateLike(likePayload) {
      return dispatch(feedViewerRedux.actions.likeFeedItem(likePayload));
    },
    castVote: function castVote(votePayload) {
      return dispatch(feedViewerRedux.actions.voteFeedItem(votePayload));
    },
    feedItemSelected: function feedItemSelected(feedItem) {
      return dispatch(feedViewerRedux.actions.feedItemSelected(feedItem));
    }
  };
};

export function CoreAggregatesContainer(aggregatesResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreAggregatesContainer(aggregatesResolver));
}