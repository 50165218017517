var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { PrivacySettingId, FeedSettingName } from "@xbox/social-core";
import { ClubActions, PrivacyActions, ActivityFeedActions, getClubSettings } from "@xbox/social-redux";
export var ClubState;

(function (ClubState) {
  ClubState["Suspended"] = "Suspended";
  ClubState["None"] = "None";
})(ClubState || (ClubState = {}));

function coreClubSettingsContainer(ClubSettingsPanelResolver, LoadingState, EmptyState) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.updateSetting = function (updatedPropertyPath, updatedProperty, value) {
          var settingsPayload = {
            clubId: _this.props.clubItem.details.id,
            update: {
              modifiedFields: [updatedProperty],
              requestContract: {}
            },
            updatedProperty: updatedPropertyPath,
            updatedValue: value
          };

          if (settingsPayload.update) {
            settingsPayload.update.requestContract[updatedProperty] = value;
          }

          _this.props.updateSetting(settingsPayload);
        }; // Membership


        _this.requestToJoinEnabledOnChange = function (e) {
          var path = ["details", "profile", "requestToJoinEnabled", "value"];

          _this.updateSetting(path, "RequestToJoinEnabled", e.target.checked);
        };

        _this.inviteOrAcceptOnChange = function (e) {
          var path = ["settings", "roster", "inviteOrAccept", "value"];

          _this.updateSetting(path, "whoCanInvite", e);
        }; // Multiplayer


        _this.lfgCreateOnChange = function (e) {
          var path = ["settings", "lfg", "create", "value"];

          _this.updateSetting(path, "whoCanCreateLfg", e);
        };

        _this.lfgGuestJoinOnChange = function (e) {
          var path = ["settings", "lfg", "join", "value"];

          _this.updateSetting(path, "whoCanJoinLfg", e.target.checked ? "Nonmember" : "Member");
        };

        _this.broadcastsShowMatureOnChange = function (e) {
          var path = ["details", "profile", "matureContentEnabled", "value"];

          _this.updateSetting(path, "matureContentEnabled", e.target.checked);
        };

        _this.broadcastsShowOnlyClubGamesOnChange = function (e) {
          var path = ["details", "profile", "watchClubTitlesOnly", "value"];

          _this.updateSetting(path, "watchClubTitlesOnly", e.target.checked);
        };

        _this.languageOnChange = function (e) {
          var path = ["details", "profile", "preferredLocale", "value"];

          _this.updateSetting(path, "preferredLocale", e);
        };

        _this.languageRegionOnChange = function (e) {
          var path = ["details", "profile", "preferredLocale", "value"];

          _this.updateSetting(path, "preferredLocale", e);
        };

        _this.shareRecordedGameSessionsOnChange = function (e) {
          var setting = {
            setting: PrivacySettingId.ShareRecordedGameSessions,
            value: e
          };
          var payload = {
            setting: setting,
            userId: _this.props.currentUser.xuid
          };

          _this.props.updatePrivacySetting(payload);
        };

        _this.autoPostCapturesOnChage = function (e) {
          var screenshotSetting = {
            name: FeedSettingName.AutopostScreenShots,
            value: e.target.checked
          };
          var clipSetting = {
            name: FeedSettingName.AutopostGameClips,
            value: e.target.checked
          };
          var payload = {
            settings: [screenshotSetting, clipSetting],
            userId: _this.props.currentUser.xuid
          };

          _this.props.updateFeedSetting(payload);
        };

        _this.deleteClubOnClick = function () {
          var settingsPayload = {
            clubId: _this.props.clubItem.details.id,
            updatedProperty: ["state"],
            updatedValue: ClubState.Suspended
          };

          _this.props.deleteClub(settingsPayload); // todo

        };

        _this.cancelDeleteClubOnClick = function () {
          var settingsPayload = {
            clubId: _this.props.clubItem.details.id,
            updatedProperty: ["state"],
            updatedValue: ClubState.None
          };

          _this.props.cancelDeleteClub(settingsPayload); // todo

        };

        _this.transferClubOnClick = function (e) {// todo
        };

        _this.canReorderChannel = function () {
          _this.setState({
            canReorderChannel: !_this.state.canReorderChannel
          });
        };

        _this.moveChannel = function (channel, newPosition) {
          var payload = {
            clubId: _this.props.clubItem.details.id,
            newPosition: newPosition,
            channelId: channel.id
          };

          _this.props.reorderChannel(payload);
        };

        _this.moveChannelGroup = function (group, newPostion) {
          if (!group.name) {
            return;
          }

          var payload = {
            clubId: _this.props.clubItem.details.id,
            newPosition: newPostion,
            groupName: group.name
          };

          _this.props.reorderChannelGroup(payload);
        };

        _this.safetyThresholdOnChange = function (e) {
          var payload = {
            clubId: _this.props.clubItem.details.id,
            updatedValue: e
          };

          _this.props.updateSafetyThreshold(payload);
        };

        _this.state = {
          canReorderChannel: false
        };
        return _this;
      }

      class_1.prototype.render = function () {
        var handlers = {
          requestToJoinEnabledOnChange: this.requestToJoinEnabledOnChange,
          inviteOrAcceptOnChange: this.inviteOrAcceptOnChange,
          lfgCreateOnChange: this.lfgCreateOnChange,
          lfgGuestJoinOnChange: this.lfgGuestJoinOnChange,
          broadcastsShowMatureOnChange: this.broadcastsShowMatureOnChange,
          broadcastsShowOnlyClubGamesOnChange: this.broadcastsShowOnlyClubGamesOnChange,
          languageOnChange: this.languageOnChange,
          languageRegionOnChange: this.languageRegionOnChange,
          deleteClubOnClick: this.deleteClubOnClick,
          cancelDeleteClubOnClick: this.cancelDeleteClubOnClick,
          transferClubOnClick: this.transferClubOnClick,
          shareRecordedGameSessionsOnChange: this.shareRecordedGameSessionsOnChange,
          autoPostCapturesOnChage: this.autoPostCapturesOnChage,
          canReorderChannel: this.canReorderChannel,
          moveChannel: this.moveChannel,
          moveChannelGroup: this.moveChannelGroup,
          safetyThresholdOnChange: this.safetyThresholdOnChange
        }; // there should be always at least GeneralFeed channels.

        var channels = this.props.feedChannels || [];
        var settings = ClubSettingsPanelResolver.getClubSettingsPanel(this.props.clubItem, handlers, this.props.privacy, this.props.feedSettings, channels, this.state.canReorderChannel, this.props.safetyThresholdInProgress, this.props.safetyThreshold);
        return settings;
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return getClubSettings(state);
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    updateSetting: function updateSetting(update) {
      return dispatch(ClubActions.updateClubSettings(update));
    },
    updatePrivacySetting: function updatePrivacySetting(setting) {
      return dispatch(PrivacyActions.updatePrivacySetting(setting));
    },
    updateFeedSetting: function updateFeedSetting(setting) {
      return dispatch(ActivityFeedActions.updateFeedSetting(setting));
    },
    deleteClub: function deleteClub(update) {
      return dispatch(ClubActions.deleteClub(update));
    },
    cancelDeleteClub: function cancelDeleteClub(update) {
      return dispatch(ClubActions.cancelDeleteClub(update));
    },
    reorderChannel: function reorderChannel(update) {
      return dispatch(ClubActions.reorderChannel(update));
    },
    reorderChannelGroup: function reorderChannelGroup(update) {
      return dispatch(ClubActions.reorderChannelGroup(update));
    },
    updateSafetyThreshold: function updateSafetyThreshold(update) {
      return dispatch(ClubActions.updateSafetyThreshold(update));
    }
  };
};

export function CoreClubSettingsContainer(ClubSettingsPanel, LoadingState, EmptyState) {
  return connect(mapStateToProps, mapDispatchToProps)(coreClubSettingsContainer(ClubSettingsPanel, LoadingState, EmptyState));
}