"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileServiceProxy = void 0;

var inversify_1 = require("inversify");

var inversify_2 = require("inversify");

var XSocialSymbols_1 = require("../../typings/XSocialSymbols");

var http_1 = require("@xbox/http");

var ProfileServiceProxy =
/** @class */
function () {
  function ProfileServiceProxy(xblService) {
    this.xblService = xblService;
  }

  ProfileServiceProxy.prototype.getProfileByGamertag = function (gamertag) {
    var url = "https://profile.xboxlive.com/users/gt(" + encodeURIComponent(gamertag) + ")/profile/settings?settings=Gamertag";
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Get,
      headers: [{
        Name: "x-xbl-contract-version",
        Value: 2
      }],
      url: url
    }).then(function (response) {
      return response.data.profileUsers[0];
    });
  };

  ProfileServiceProxy = __decorate([inversify_1.injectable(), __param(0, inversify_2.inject(XSocialSymbols_1.XSocialSymbols.IXboxLiveRequestService)), __metadata("design:paramtypes", [Object])], ProfileServiceProxy);
  return ProfileServiceProxy;
}();

exports.ProfileServiceProxy = ProfileServiceProxy;