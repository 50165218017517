export enum Glyphs {
    UserTypeChild = "\uE2E8",
    UserTypeTeen = "\uE2E9",
    UserTypeAdult = "\uE2EA",
    MultiPlayerGold = "\uE2EB",
    MultiPlayerTimer = "\uE2EC",
    MultiPlayerPartyJoinable = "\uE2ED",
    MultiPlayerJoinChallenge = "\uE2EE",
    MultiPlayerLeaveParty = "\uE2EF",
    MultiPlayerShare = "\uE2F0",
    MultiPlayerWimax = "\uE2F1",
    PlayerKinect = "\uE2F2",
    PlayerRespond = "\uE2F3",
    PlayerRecoverProfile = "\uE2F4",
    PlayerSkipSignIn = "\uE2F5",
    PlayerRemove = "\uE2F6",
    PlayerWait = "\uE2F7",
    PlayerDownloadProfile = "\uE2F8",
    PlayerAchievement = "\uE2F9",
    PlayerSettings = "\uE2FA",
    PlayerSignOut = "\uE2FB",
    PlayerSignIn = "\uE2FC",
    PlayerGold = "\uE2FD",
    PlayerAdd = "\uE2FE",
    Family = "\uE460",
    MultiPlayer = "\uE300",
    Player = "\uE301",
    InternetWithController = "\uE302",
    LicenseStore = "\uE303",
    KinectSettings = "\uE304",
    Subscriptions = "\uE305",
    VideoPopular = "\uE306",
    VideoRecommendations = "\uE307",
    VideoGames = "\uE308",
    VideoNewRelease = "\uE309",
    DiscArtist = "\uE30A",
    DiscBad = "\uE30B",
    DiscGame = "\uE30C",
    DiscMusic = "\uE30D",
    DiscCantPlay = "\uE30E",
    GameWait = "\uE30F",
    GameAdd = "\uE310",
    GameRemove = "\uE311",
    GamePlay = "\uE312",
    GameFavorite = "\uE313",
    GameRecommendations = "\uE314",
    GameJoin = "\uE315",
    ControllerNewRelease = "\uE316",
    ControllerBatteryLow = "\uE317",
    ControllerBatteryHalf = "\uE318",
    ControllerBatteryThreeQuarter = "\uE319",
    ControllerBatteryFull = "\uE31A",
    FolderGamerPics = "\uE31B",
    FolderProfile = "\uE31C",
    FolderGenre = "\uE31D",
    FolderDemos = "\uE31E",
    FolderSystemItems = "\uE31F",
    FolderThemes = "\uE320",
    FolderFindContent = "\uE321",
    FolderGames = "\uE322",
    FolderMusic = "\uE323",
    FolderVideo = "\uE324",
    FolderAllItems = "\uE325",
    TvSettings = "\uE326",
    TvRecommendationsTv = "\uE327",
    ChatInviteFriend = "\uE328",
    ChatMute = "\uE329",
    ChatVideo = "\uE32A",
    MusicTracks = "\uE32B",
    PlaylistMusic = "\uE32C",
    PlaylistGenre = "\uE32D",
    PlaylistRemove = "\uE32E",
    PlaylistAdd = "\uE32F",
    ListBulleted = "\uE330",
    List = "\uE331",
    ListJustified = "\uE332",
    Epg = "\uE333",
    CursorHand = "\uE334",
    NowPlaying = "\uE6EE",
    SignalNone = "\uE336",
    SignalLow = "\uE337",
    SignalHalf = "\uE338",
    SignalThreeQuarter = "\uE339",
    SignalFull = "\uE33A",
    AddToCollection = "\uE33B",
    AddToNowPlaying = "\uE33C",
    CompareGames = "\uE33D",
    Video11 = "\uE33E",
    HdQualityResolution = "\uE33F",
    FavoriteHelp = "\uE340",
    FavoriteAdd = "\uE341",
    MostPopular = "\uE342",
    FavoriteRemove = "\uE343",
    FavoriteRemove2 = "\uE344",
    Ratings = "\uE345",
    Favorite = "\uE346",
    CloudSyncing = "\uE347",
    CloudNotSynced = "\uE348",
    CloudNotSynced2 = "\uE349",
    Cloud = "\uE34A",
    MoreActions = "\uE34B",
    ModeSelector = "\uE34C",
    SelectScene = "\uE34D",
    SmartGlassTablet = "\uE34E",
    SmartGlassPhone = "\uE34F",
    Video1 = "\uE350",
    HeartBroken = "\uE351",
    HeartFull = "\uE352",
    AdornerConnected = "\uE353",
    Streaming = "\uE354",
    Browser = "\uE355",
    Keyboard = "\uE356",
    Cart = "\uE357",
    Related = "\uE503", // Full View
    Delete = "\uE359",
    Home = "\uE35A",
    Smiley = "\uE35B",
    Edit = "\uE35C",
    Color = "\uE35D",
    Save = "\uE35E",
    Recent = "\uE35F",
    Tag = "\uE360",
    Bluetooth = "\uE361",
    BeaconsActivity = "\uE362",
    Sensor = "\uE363",
    SensorWithStand = "\uE364",
    Power = "\uE365",
    XboxClassic = "\uE366",
    InstructionManual = "\uE367",
    MediaRemote = "\uE368",
    HeadsetAdapter = "\uE369",
    ChatHeadset = "\uE36A",
    ForearmStrap = "\uE36B",
    BatteryPack = "\uE36C",
    Batteries = "\uE36D",
    PowerSupply = "\uE36E",
    ChargingCable = "\uE36F",
    PowerCord = "\uE370",
    HdmiCable = "\uE371",
    TransferCable = "\uE372",
    AllDevices = "\uE373",
    Harddrive = "\uE374",
    MemoryUnitInternal = "\uE375",
    MemoryUnitExternal = "\uE376",
    UsbDevice = "\uE377",
    Usb = "\uE378",
    MicOff = "\uE379",
    MicOff2 = "\uE37A",
    MicOn = "\uE37B",
    Key = "\uE37C",
    GamerTagSettings = "\uE37D",
    GamerTagChange = "\uE37E",
    GamerTagTakePicture = "\uE37F",
    UnrecognizedFormat = "\uE380",
    Reading = "\uE381",
    NoThumbnail = "\uE382",
    NetworkDisconnected = "\uE383",
    NetworkDisconnected2 = "\uE384",
    NetworkConnected = "\uE385",
    MediaMusic = "\uE386",
    ComplaintError = "\uE387",
    Complaint = "\uE388",
    MessageJoinParty = "\uE389",
    MessageFriendRequest = "\uE38A",
    MessageVideo = "\uE38B",
    MessageVoice = "\uE38C",
    Message = "\uE38D",
    Sports = "\uE38E",
    Fitness = "\uE38F",
    VolumeDisabled = "\uE390",
    VolumeDisabled2 = "\uE391",
    VolumeMute = "\uE392",
    Volume = "\uE393",
    Unpin = "\uE394",
    Unpin2 = "\uE395",
    Pin = "\uE396",
    CardPrepaid = "\uE397",
    Backspace = "\uE398",
    Networks = "\uE399",
    Apps = "\uE39A",
    ChangeTheme = "\uE39B",
    VirtualKeyboardClose = "\uE39C",
    TvMonitor = "\uE39D",
    Computer = "\uE39E",
    StoreLogoMed20 = "\uE39F",
    Store = "\uE3A0",
    NewReleases = "\uE3A1",
    SmartDj = "\uE3A2",
    SmartVj = "\uE3A3",
    Remove = "\uE3A4",
    Add = "\uE3A5",
    CheckBoxChecked = "\uE3A6",
    CheckBox = "\uE3A7",
    MoveToFront = "\uE3A8",
    Flag = "\uE3A9",
    DownloadSeeAll = "\uE3AA",
    SgControllerDown = "\uE3AB",
    SgControllerUp = "\uE3AC",
    SgControllerLeft = "\uE3AD",
    SgControllerRight = "\uE3AE",
    ChevronDown = "\uE3AF",
    ChevronUp = "\uE3B0",
    ChevronLeft = "\uE3B1",
    ChevronRight = "\uE3B2",
    Arrow3Down = "\uE3B3",
    Arrow3Up = "\uE3B4",
    Arrow3Left = "\uE3B5",
    Arrow3Right = "\uE3B6",
    Arrow2Up = "\uE3B7",
    Arrow2Down = "\uE3B8",
    Arrow2Left = "\uE3B9",
    Arrow2Right = "\uE3BA",
    ArrowDown = "\uE3BB",
    ArrowUp = "\uE3BC",
    ArrowLeft = "\uE3BD",
    ArrowRight = "\uE3BE",
    RepeatOnce = "\uE3BF",
    Repeat = "\uE3C0",
    RotateClockwise = "\uE3C1",
    RotateCounterClockwise = "\uE3C2",
    RepeatRefresh = "\uE70D",
    Share = "\uE73B",
    ArrowsUpDown2 = "\uE3C5",
    ArrowsUpDown = "\uE3C6",
    ArrowsLeftRight = "\uE3C7",
    ShuffleOff = "\uE3C8",
    ShuffleOn = "\uE3C9",
    Download = "\uE3CA",
    ButtonX = "\uE3CB",
    ButtonY = "\uE3CC",
    ButtonB = "\uE3CD",
    ButtonA = "\uE3CE",
    ABXYButtonBaseTopLeft = "\uE3CF",
    ABXYButtonBaseLowerLeft = "\uE3D0",
    ABXYButtonBaseTopRight = "\uE3D1",
    ABXYButtonBaseLowerRight = "\uE3D2",
    MicrosoftPoints = "\uE3D3",
    RingOfLight = "\uE3D4",
    GamerScore = "\uE3D5",
    RightThumbLeft = "\uE3D6",
    RightThumbUp = "\uE3D7",
    RightThumbRight = "\uE3D8",
    RightThumbDown = "\uE3D9",
    LeftThumbLeft = "\uE3DA",
    LeftThumbUp = "\uE3DB",
    LeftThumbRight = "\uE3DC",
    LeftThumbDown = "\uE3DD",
    DpadLeft = "\uE3DE",
    DpadDown = "\uE3DF",
    DpadRight = "\uE3E0",
    DpadUp = "\uE3E1",
    Dpad = "\uE3E2",
    Xenon = "\uE3E3",
    TriggerRight = "\uE3E4",
    SemanticZoom = "\uE3E5",
    TriggerLeft = "\uE3E6",
    LeftStickClick = "\uE3E7",
    Menu = "\uE3E8",
    CircleButtonBackground = "\uE3E9",
    ButtonView = "\uE3EA",
    BumperRight = "\uE3EB",
    ButtonMenu = "\uE3EC",
    BumperLeft = "\uE3ED",
    Accept = "\uE3EE",
    Reward = "\uE3EF",
    Reminder = "\uE3F0",
    BadRecommendation = "\uE3F1",
    ArcadeGame = "\uE3F2",
    Explore = "\uE3F3",
    Infrastructure = "\uE3F4",
    Folder = "\uE3F5",
    TitleMenu = "\uE3F6",
    Search = "\uE3F7",
    ZoomOut = "\uE3F8",
    ZoomIn = "\uE3F9",
    OptionalMediaUpdate = "\uE3FA",
    Network = "\uE3FB",
    VideoCamera = "\uE3FC",
    VideoCameraDisconnected = "\uE3FD",
    NoVideoCamera = "\uE3FE",
    NoVideoCamera2 = "\uE3FF",
    Eject = "\uE400",
    PlayPause = "\uE401",
    Next = "\uE402",
    Previous = "\uE403",
    Warning = "\uE404",
    Question = "\uE405",
    Info = "\uE406",
    Stop = "\uE407",
    RecordInnerCircle = "\uE408",
    Close = "\uE409",
    SkipForward = "\uE40A",
    SkipBack = "\uE40B",
    Pause = "\uE40C",
    Play = "\uE40D",
    Settings = "\uE40E",
    Internet = "\uE40F",
    Movies = "\uE410",
    UnlockedAchievement = "\uE411",
    Achievements = "\uE412",
    Chat = "\uE413",
    SwitcherMultitask = "\uE414",
    AspectRatio = "\uE415",
    MediaHdDvd = "\uE416",
    MediaDvd = "\uE417",
    MediaDisc = "\uE418",
    Wifi = "\uE419",
    Music = "\uE41A",
    GameDemos = "\uE41B",
    Camera = "\uE41C",
    Lock = "\uE41D",
    Calculator = "\uE41E",
    Cables = "\uE41F",
    StartBack = "\uE420",
    SwitchFocus = "\uE421",
    Swap = "\uE422",
    PhoneHangUp = "\uE423",
    PhoneCall = "\uE424",
    RightStickClick = "\uE425",
    ButtonLetterY = "\uE426",
    ButtonLetterX = "\uE427",
    ButtonLetterB = "\uE428",
    ButtonLetterA = "\uE429",
    Details = "\uE42B",
    AvatarItem = "\uE42C",
    Game = "\uE42D",
    Video = "\uE42E",
    ExploreContent = "\uE42F",
    TvBluRaySettings = "\uE430",
    Subscriptions2 = "\uE431",
    Unlock = "\uE432",
    TvCableSettings = "\uE433",
    Console = "\uE434",
    KinectDisconnected = "\uE435",
    Kinect = "\uE436",
    ClosedCaptions = "\uE437",
    SmartGlassConnectingConsole = "\uE438",
    SmartGlassConnectingRingsFull = "\uE439",
    SmartGlassConnectingRingsInner = "\uE43A",
    SmartGlassConnectingRingsMid = "\uE43B",
    SmartGlassConnectingRingsOuter = "\uE43C",
    SmartGlassConnected = "\uE43D",
    Playlist = "\uE43E",
    AddToNowPlaying2 = "\uE43F",
    ConsoleSleep = "\uE440",
    ControllerOff = "\uE441",
    NotOnline = "\uE442",
    UnSnap = "\uE443",
    AddTo = "\uE444",
    SmartGlassRemote = "\uE445",
    AppSnap = "\uE446",
    AppFill = "\uE447",
    CursorHandClosed = "\uE448",
    AppSwap = "\uE449",
    GotoLiveBroadcast = "\uE44A",
    FastForward = "\uE44B",
    Rewind = "\uE44C",
    ClosedCaptionsInternational = "\uE44D",
    StepForward = "\uE44E",
    StepBack = "\uE44F",
    AvatarNew = "\uE450",
    AppIconAvatar = "\uE451",
    CameraTimer = "\uE452",
    AvatarRotateRight = "\uE453",
    AvatarRotateLeft = "\uE454",
    AvatarAward = "\uE455",
    AchievementLocked = "\uE456",
    SmartGlass = "\uE457",
    NetworkSettings = "\uE458",
    FullScreen = "\uE45A",
    Profile = "\uE45B",
    XboxOneDown = "\uE45C",
    XboxOneUp = "\uE45D",
    ApplyAction = "\uE45E",
    AppIconHelp = "\uE45F",
    AppIconPeople = "\uE460",
    AppIconParty = "\uE461",
    SettingsDisplaySound = "\uE462",
    Replay = "\uE463",
    MoveToBack = "\uE464",
    ActivityFeed = "\uE465",
    BluRayRGBY = "\uE467",
    AppFull = "\uE468",
    MessageReply = "\uE469",
    MessageSend = "\uE46A",
    Navigation = "\uE46B",
    StartParty = "\uE46C",
    Repairs = "\uE46D",
    BluRaySubtitles = "\uE46E",
    VideoBanned = "\uE46F",
    PlayerSwitchProfile = "\uE470",
    ApplicationLaunch = "\uE471",
    BrowserHistory = "\uE472",
    BrowserPrivate = "\uE473",
    AppIconDefault = "\uE474",
    GameRemove2 = "\uE475",
    ConsoleInitialSetup = "\uE476",
    ConsoleSettings = "\uE477",
    ConsoleControllerPluggedIn = "\uE478",
    QRCode = "\uE479",
    SubscriptionAdd = "\uE586",
    Xbox360 = "\uE47B",
    AvatarMyFeatures = "\uE47C",
    ApplicationInstall = "\uE47D",
    ConnectToConsole = "\uE47E",
    FolderAvatarItems = "\uE47F",
    ViewProfileDetails = "\uE480",
    PartyLeader = "\uE481",
    ConsoleMultiGuest = "\uE482",
    SmartGlassGamerScore = "\uE483",
    ProfileSearch = "\uE484",
    BrowserCursor = "\uE485",
    ParentalLock = "\uE486",
    KinectBroadcasting = "\uE487",
    Broadcasting = "\uE488",
    KinectIR = "\uE489",
    ArrowsLeftRight2 = "\uE48A",
    RadioBtnOff = "\uE48B",
    RadioBtnOn = "\uE48C",
    SGFavorite = "\uE48D",
    DevUpdate = "\uE48E",
    DevDeploy = "\uE48F",
    SdQualityResolution = "\uE490",
    Sad2 = "\uE491",
    Sad = "\uE492",
    MiniGuide = "\uE493",
    Gold = "\uE494",
    Attach = "\uE495",
    Crown = "\uE496",
    DefaultUser = "\uE497",
    Ribbon = "\uE498",
    Diamond = "\uE49A",
    BatteryCharging = "\uE49B",
    Battery1 = "\uE49C",
    Battery2 = "\uE49D",
    Battery3 = "\uE49E",
    Battery4 = "\uE49F",
    FirstAccessApp = "\uE4A0",
    ListView = "\uE501", // compact
    GridViewSmall = "\uE502", // grid view
    GridViewLarge = "\uE503", // not used
    Characters = "\uE504",
    RecordOuterCircle = "\uE505",
    Cast = "\uE506",
    AspectRatio2 = "\uE507",
    Shirt = "\uE508",
    Pants = "\uE509",
    Dress = "\uE50A",
    Glasses = "\uE50B",
    Shoes = "\uE50C",
    Hat = "\uE50D",
    TShirt = "\uE50E",
    Hanger = "\uE50F",
    Playground = "\uE510",
    Body = "\uE511",
    HeightTopRange = "\uE512",
    HeightBottomRange = "\uE513",
    SizeTopRange = "\uE514",
    SizeBottomRange = "\uE515",
    HairMale = "\uE516",
    HairFemale = "\uE517",
    Photo = "\uE518",
    Move = "\uE519",
    ColorWheel = "\uE51A",
    Eyebrow = "\uE51B",
    Eye = "\uE51C",
    Nose = "\uE51D",
    Ear = "\uE51E",
    FacialHair = "\uE51F",
    Featured = "\uE520",
    Pose = "\uE521",
    Guitar = "\uE522",
    Chin = "\uE523",
    Mouth = "\uE524",
    Ring = "\uE525",
    Wristwear = "\uE526",
    Gloves = "\uE527",
    Earrings = "\uE528",
    Info16 = "\uE529",
    MultiItemJoinable = "\uE52C",
    StoreLogo16 = "\uE52D",
    Heart = "\uE52E",
    Page = "\uE52F",
    ActionCenter = "\uE530",
    EaseOfAccess = "\uE531",
    Speakers = "\uE532",
    Open = "\uE533",
    OpenNewTab = "\uE534",
    PowerSettings = "\uE535",
    StorageInternalExternal = "\uE536",
    VideoProvider = "\uE537",
    Narrator = "\uE538",
    HighContrast = "\uE539",
    TimeLanguage = "\uE53A",
    CameraReset = "\uE53B",
    GameLaunch = "\uE53C",
    CommunityProgramming = "\uE53D",
    Sweepstakes = "\uE53E",
    Dock = "\uE53F",
    Undock = "\uE540",
    GameChat = "\uE541",
    Report = "\uE542",
    Block = "\uE543",
    GameHub = "\uE544",
    Online = "\uE545",
    Offline = "\uE546",
    Compare = "\uE547",
    Uncompare = "\uE548",
    Trim = "\uE549",
    ButtonView2 = "\uE54A",
    Trending = "\uE54B",
    DVR = "\uE54C",
    TVShows = "\uE54D",
    AppChannels = "\uE54E",
    PaddleP1 = "\uE54F",
    PaddleP2 = "\uE550",
    PaddleP3 = "\uE551",
    PaddleP4 = "\uE552",
    Clock = "\uE555",
    NetworkTower = "\uE556",
    MyMoviesTV = "\uE557",
    GamerScoreInline = "\uE558",
    XboxPlayAnywhere = "\uE559",
    OCR1 = "\uE55A",
    OCR2 = "\uE55B",
    OCRP1 = "\uE55C",
    OCRP2 = "\uE55D",
    OCRP3 = "\uE55E",
    OCRP4 = "\uE55F",
    WindowsLogo = "\uE560",
    GripperBarHorizontal = "\uE561",
    GameArt160 = "\uE562",
    GameArt224 = "\uE563",
    ChatHeadsetArt160 = "\uE564",
    ChatHeadsetArt224 = "\uE565",
    WheelArt160 = "\uE566",
    WheelArt224 = "\uE567",
    ArcadeGameArt160 = "\uE568",
    ArcadeGameArt224 = "\uE569",
    GuitarArt160 = "\uE56A",
    GuitarArt224 = "\uE56B",
    GameClip = "\uE56C",
    ChromeExpand = "\uE56D",
    ChromeCollapse = "\uE56E",
    RecordSeries = "\uE56F",
    GamerHistory = "\uE570",
    Send = "\uE571",
    Label = "\uE572",
    SlotOne = "\uE573",
    SlotTwo = "\uE574",
    LeftStick = "\uE575",
    RightStick = "\uE576",
    AppSnapMirrored = "\uE577",
    AppFillMirrored = "\uE578",
    UnSnapMirrored = "\uE579",
    AppSwapMIrrored = "\uE57A",
    SwitchFocusMirrored = "\uE57B",
    Default = "\uE57C",
    Aggressive = "\uE57D",
    Smooth = "\uE57E",
    Instant = "\uE57F",
    Delay = "\uE580",
    Notebook = "\uE581",
    Feedback = "\uE582",
    Blueray = "\uE583",
    Signin = "\uE584",
    NoContent = "\uE585",
    ActivityFeed2 = "\uE586",
    GameClip2 = "\uE587",
    SubscriptionsMirrored = "\uE588",
    MusicTracksMirrored = "\uE589",
    MessageSendMirrored = "\uE58A",
    PlaylistMusicMirrored = "\uE58B",
    PlaylistGenreMirrored = "\uE58C",
    PlaylistRemoveMirrored = "\uE58D",
    PlaylistAddMirrored = "\uE58E",
    ListBulletedMirrored = "\uE58F",
    ListMirrored = "\uE590",
    ChevronLeftMirrored = "\uE591",
    ChevronRightMirrored = "\uE592",
    Arrow3LeftMirrored = "\uE593",
    Arrow3RightMirrored = "\uE594",
    Arrow2LeftMirrored = "\uE595",
    Arrow2RightMirrored = "\uE596",
    ArrowLeftMirrored = "\uE597",
    ArrowRightMirrored = "\uE598",
    SliderThumb = "\uE599",
    QuestionMirrored = "\uE59A",
    DetailsMirrored = "\uE59B",
    Subscriptions2Mirrored = "\uE59C",
    ApplicationLaunchMirrored = "\uE59D",
    ViewProfileDetailsMirrored = "\uE59E",
    MiniGuideMirrored = "\uE59F",
    ListViewMirrored = "\uE5A1",
    CastMirrored = "\uE5A2",
    FeaturedMirrored = "\uE5A3",
    ActionCenterMirrored = "\uE5A4",
    OpenMirrored = "\uE5A5",
    OpenNewTabMirrored = "\uE5A6",
    DockMirrored = "\uE5A7",
    UndockMirrored = "\uE5A8",
    TrendingMirrored = "\uE5A9",
    ChromeExpandMirrored = "\uE5AA",
    ChromeCollapseMirrored = "\uE5AB",
    ActivityFeed2Mirrored = "\uE5AC",
    FavoriteHelpMirrored = "\uE5AD",
    ComplaintErrorMirrored = "\uE5AE",
    AppIconHelpMirrored = "\uE5AF",
    SubscriptionAddMirrored = "\uE5B0",
    SendMirrored = "\uE5B1",
    ToggleFilled = "\uE5B2",
    ToggleBorder = "\uE5B3",
    ToggleThumb = "\uE5B4",
    RecordArrow = "\uE5B5",
    RecordDot = "\uE5B6",
    WirelessDevice = "\uE5B7",
    Arrow2UpSolid = "\uE5B8",
    Arrow2DownSolid = "\uE5B9",
    Arrow2LeftSolid = "\uE5BA",
    Arrow2RightSolid = "\uE5BB",
    DLC = "\uE5BC",
    Bundle = "\uE5BD",
    BackToWindow = "\uE5BE",
    Import = "\uE5BF",
    ImportMirrored = "\uE5C0",
    ImportAll = "\uE5C1",
    ImportAllMirrored = "\uE5C2",
    MultiSelect = "\uE5C3",
    MultiSelectMirrored = "\uE5C4",
    Link = "\uE5C5",
    CopyGames = "\uE5C6",
    CopyAllGames = "\uE5C7",
    RemoveContent = "\uE5C8",
    Uninstall = "\uE5C9",
    CollectionListView = "\uE5CA",
    CollectionListViewMirrored = "\uE5CB",
    ActionCenter2 = "\uE5CC",
    PIP = "\uE5D1",
    Tournament = "\uE5ED",
    LowHardDrive = "\uE5EE",
    ChatPad = "\uE5EF",
    PreviewContent = "\uE5F0",
    FeatureRequest = "\uE5F1",
    Quest = "\uE5F2",
    Megaphone = "\uE5F3",
    Questionnaire = "\uE5F4",
    QuestionnaireMirrored = "\uE5F5",
    PC1 = "\uE5F6",
    CellPhone = "\uE5F7",
    XboxMsgFulfillmentLogo = "\uE5F8",
    ThisPC = "\uE5F9",
    AccessoriesApp = "\uE5FA",
    TagLanguage = "\uE5FB",
    TagCommunications = "\uE5FC",
    TagPeople = "\uE5FD",
    TagPlayStyle = "\uE5FE",
    Clubs = "\uE5FF",
    BuildRelationship = "\uE600",
    HoloLens = "\uE601",
    SurfaceHub = "\uE602",
    ChatBubbles = "\uE603",
    Telemarketer = "\uE604",
    CreateAppointment = "\uE605",
    HeadsetController = "\uE606",
    GameChatTranscription = "\uE607",
    Translate = "\uE608",
    ActionCenterQuiet = "\uE609",
    MixerLogo = "\uE60A",
    Teeth = "\uE60B",
    Eyelash = "\uE60C",
    Fingernail = "\uE60D",
    Toenail = "\uE60E",
    Helmet = "\uE60F",
    Mask = "\uE610",
    Mounts = "\uE611",
    Emoji = "\uE612",
    Leggings = "\uE613",
    Tattoo = "\uE614",
    LeftLegReplacement = "\uE615",
    RightLegReplacement = "\uE616",
    LeftArmReplacement = "\uE617",
    RightArmReplacement = "\uE618",
    NoseRing = "\uE619",
    Anklewear = "\uE61A",
    ToeRing = "\uE61B",
    SelectionWheel = "\uE61C",
    Gamebar = "\uE61D",
    GameMode = "\uE61E",
    BodySkinnyMasculine = "\uE61F",
    BodySkinnyFeminine = "\uE620",
    BodyAverageMasculine = "\uE621",
    BodyAverageFeminine = "\uE622",
    BodyChubbyMasculine = "\uE623",
    BodyChubbyFeminine = "\uE624",
    BodyHeavyMasculine = "\uE625",
    BodyHeavyFeminine = "\uE626",
    BodyStrongMasculine = "\uE627",
    BodyStrongFeminine = "\uE628",
    BodyPregnant = "\uE629",
    BodyMannequin = "\uE62A",
    BodyAthleticMasculine = "\uE62B",
    BodyAthleticFeminine = "\uE62C",
    BDE = "\uE62D",
    SkipForward30 = "\uE62E",
    SkipBack10 = "\uE62F",
    SubtitlesAudio = "\uE630",
    Copilot = "\uE631",
    XboxGamePass = "\uE632",
    XboxGamePassBadge = "\uE633",
    LiveIndicator = "\uE634",
    Mute = "\uE635",
    XboxLogoOutline = "\uE636",
    Wifi1 = "\uE637",
    Wifi2 = "\uE638",
    Wifi3 = "\uE639",
    WifiWarning4 = "\uE63A",
    PinSolid = "\uE63B",
    WelcomeApp = "\uE63C",
    LauncherApp = "\uE63D",
    Filter = "\uE63E",
    FilterSolid = "\uE63F",
    Mouse = "\uE640",
    LaserCutter = "\uE641",
    TriggerLeftDLSet = "\uE642",
    TriggerRightDLSet = "\uE643",
    BumperLeftDLSet = "\uE644",
    BumperRightDLSet = "\uE645",
    DpadDLSet = "\uE646",
    ThumbstickDLSet = "\uE647",
    ABXYDLSet = "\uE648",
    ViewMenuDLSet = "\uE649",
    BackCaseDLSet = "\uE64A",
    GripsDLSet = "\uE64B",
    Eyeshadow = "\uE64C",
    Commitments = "\uE64D",
    ChatSolid = "\uE64E",
    DisabledCamera = "\uE64F",
    DisabledMicrophone = "\uE650",
    GameModeOff = "\uE651",
    StopSolid = "\uE652",
    Upload = "\uE653",
    ColorWheelAvatar = "\uE654",
    ScreenTime = "\uE655",
    LightningBolt = "\uE656",
    CortanaLogoOuter = "\uE657",
    CortanaLogoInner = "\uE658",
    Lightbulb = "\uE659",
    XboxOneConsole = "\uE65A",
    XboxAssistApp = "\uE65B",
    XboxAssistAppMirrored = "\uE65C",
    ActionCenterSettings = "\uE65D",
    AddHome = "\uE65E",
    RepeatBlock = "\uE65F",
    LiveText = "\uE660",
    AttractMode = "\uE661",
    FacebookLogo = "\uE662",
    NetworkTransfer = "\uE663",
    GroupedList = "\uE664",
    BusinessRule = "\uE665",
    Tiles2 = "\uE666",
    GamesApp = "\uE667",
    SingleBookmark = "\uE668",
    SingleBookmarkSolid = "\uE669",
    Ascending = "\uE66A",
    Descending = "\uE66B",
    AddConsole = "\uE66C",
    HeadsetChargeLow = "\uE66D",
    HeadsetChargeMed = "\uE66E",
    HeadsetChargeFull = "\uE66F",
    HeadsetChargeCritical = "\uE670",
    HeadsetChargeCriticalBattery = "\uE671",
    HeadsetCharging = "\uE672",
    GamePassInline = "\uE67E",
    GoldInline = "\uE67F",
    LiveTextInline = "\uE680",
    JoinClub = "\uE682",
    CreateClub = "\uE683",
    CreateTournament = "\uE684",
    ControllerSettings = "\uE685",
    Ringer = "\uE686",
    AccountHacked = "\uE687",
    GameFreeze = "\uE688",
    MailSearch = "\uE689",
    PanMode = "\uE68B",
    ShopBrand = "\uE68C",
    Undo = "\uE68D",
    ActionCenterNotification = "\uE68E",
    ActionCenterNotificationMirrored = "\uE68F",
    Equalizer = "\uE690",
    ServerProcesses = "\uE691",
    VibrateController = "\uE692",
    XboxAmbassadorBaseLogo16 = "\uE693",
    XboxAmbassadorBaseLogo32 = "\uE694",
    RightThumbHorizontal = "\uE695",
    LeftThumbHorizontal = "\uE696",
    CursorHandClosedHorizontal = "\uE697",
    ColorEdit96 = "\uE698",
    ColorEdit961 = "\uE699",
    ColorEdit962 = "\uE69A",
    ColorEdit963 = "\uE69B",
    ColorEdit964 = "\uE69C",
    ColorEdit965 = "\uE69D",
    ColorEdit966 = "\uE69E",
    ColorEdit967 = "\uE69F",
    ColorEdit968 = "\uE6A0",
    GameMod = "\uE6A1",
    TileGroups = "\uE6A2",
    GameCompletion = "\uE6A3",
    GameCompletionDLC = "\uE6A4",
    HeroStats = "\uE6A5",
    ThumbstickXAxisSwap = "\uE6A6",
    ThumbstickYAxisSwap = "\uE6A7",
    ThumbstickXAxisInverted = "\uE6A8",
    ThumbstickYAxisInverted = "\uE6A9",
    Library = "\uE6AA",
    GameClubs = "\uE6AB",
    CreateGameClubs = "\uE6AC",
    JoinGameClubs = "\uE6AD",
    AdaptiveController = "\uE6AE",
    "4K" = "\uE6AF",
    StopWatch = "\uE6B0",
    Timer = "\uE6B1",
    Volume0 = "\uE6B2",
    Volume1 = "\uE6B3",
    Volume2 = "\uE6B4",
    Volume3 = "\uE6B5",
    Brightness = "\uE6B6",
    LowerBrightness = "\uE6B7",
    ChatBot = "\uE6C1",
    LockSolid = "\uE6CD",
    PopIn = "\uE6CF",
    VerifiedGameClub = "\uE6D0",
    VerifiedGameClubBackplate = "\uE760",
    SignOut = "\uE6F2",
    CircledPlay = "\uE6F8",
    MixerSparksSolid = "\uF500",
    MixerViewersSolid = "\uF503",
    MixerCoStreamingSolid = "\uF505",
    MixerInteractiveSolid = "\uF507",
    Poll = "\uF542"
}
