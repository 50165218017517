import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { FeedItemProps } from "./base/ActivityFeedItem";
import { DefaultFeedItem } from "./DefaultFeedItem";
import { BallotFeedItem } from "./BallotFeedItem";
import { TextPostFeedItem } from "./TextPostFeedItem";
import { GameLinkFeedItem } from "./GameLinkFeedItem";
import { UserPostFeedItem } from "./UserPostFeedItem";
export const FeedItemSelector = (props:  FeedItemProps) => {
    switch (props.item.activityItemType) {
        case ActivityFeed.ActivityItemTypes.TextPost:
            return <TextPostFeedItem {...props} />;
        case ActivityFeed.ActivityItemTypes.UserPost:
            if (props.item.postType === ActivityFeed.PostType.StoreLink) {
                return <GameLinkFeedItem {...props} />;
            }
            return <UserPostFeedItem {...props} />;
        case ActivityFeed.ActivityItemTypes.Container:
            return <div/>;
        case ActivityFeed.ActivityItemTypes.SocialRecommendation:
            return <div/>;
        case ActivityFeed.ActivityItemTypes.Ballot:
            return <BallotFeedItem {...props} />;
        default:
            return <DefaultFeedItem {...props} />;
    }
};
