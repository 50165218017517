import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";

import * as fts from "./FeedTools.Styles";
import { Localize } from "../../common/translation/Localize";
import { GenericLoading, GenericErrorMessage } from "../../common/GenericElements";
import { PostOptionsContainer } from "./PostOptionsContainer";
import { CommonPostDetailsProps } from "./FeedToolsTypes";
import { ChooseMediaContainer } from "./ChooseMediaContainer/ChooseMediaContainer";
import { MediaType } from "./FeedToolsTypes";
import { PostTextArea } from "../PostTextArea";
import { PreviewTextPostFeedItem } from "../feeditems/TextPostFeedItem";
import { PreviewDefaultPostFeedItem } from "../feeditems/DefaultFeedItem";
import { XBLMediaChoices } from "./Utils";

interface MediaPostDataState {
    title: string;
    text: string;
    locator: string;
    imagePreview: string;
    downloadUri: string;
    mediaType: MediaType;
    showPreview: boolean;
}

export class CreateMediaFeedItem extends React.Component<CommonPostDetailsProps, MediaPostDataState> {
    private readonly chooseMediaRef?: React.RefObject<ChooseMediaContainer>;
    constructor(props: any) {
        super(props);
        this.chooseMediaRef = React.createRef();
        this.state = {
            title: "",
            text: "",
            locator: "",
            imagePreview: "",
            downloadUri: "",
            mediaType: MediaType.Screenshot,
            showPreview: false,
        };
    }

    resetState = () => {
        this.setState({
            title: "",
            text: "",
            locator: "",
            imagePreview: "",
            downloadUri: "",
            mediaType: MediaType.Screenshot,
            showPreview: false,
        });
        if (this.chooseMediaRef && this.chooseMediaRef.current) {
            this.chooseMediaRef.current.resetState();
        }
    }

    onChangeText = (e: any) => {
        this.setState({ text: e.target.value });
    }

    onPreviewItem = (isPinned: boolean, scheduledDateTime?: string) => {
        let gamerPic = "", gamerTag = "";
        if (this.props.currentUser && this.props.currentUser.profile) {
            gamerPic = this.props.currentUser.profile.gamerpic;
            gamerTag = this.props.currentUser.profile.gamertag;
        }

        if (!this.state.imagePreview) {
            return (
                <PreviewTextPostFeedItem
                    pinned={isPinned}
                    itemText={this.state.text}
                    userGamerPicUrl={gamerPic}
                    userGamerTag={gamerTag}
                    selectedChannel={this.props.selectedChannel}
                    scheduledDateTime={scheduledDateTime}
                />
            );
        }
        const itemType = (this.state.mediaType === MediaType.Screenshot)
            ? ActivityFeed.ActivityItemTypes.Screenshot
            : ActivityFeed.ActivityItemTypes.GameDVR;

        return (
            <PreviewDefaultPostFeedItem
                pinned={isPinned}
                itemText={this.state.text}
                imageUrl={this.state.imagePreview}
                activityItemType={itemType}
                downloadUri={this.state.downloadUri}
                userGamerPicUrl={gamerPic}
                userGamerTag={gamerTag}
                selectedChannel={this.props.selectedChannel}
                scheduledDateTime={scheduledDateTime}
            />);
    }

    setMediaItem = ( locator: string, thumbUrl: string, downloadUri?: string, mediaType?: MediaType) => {
        if (!locator) {
            this.setState({locator: "", imagePreview: "", downloadUri: "", mediaType: MediaType.Screenshot});
            return;
        }

        const original = downloadUri ? downloadUri : "";
        const type = mediaType ? mediaType : MediaType.Screenshot;
        this.setState({locator, imagePreview: thumbUrl, downloadUri: original, mediaType: type});
    }

    prepareXboxLinkItem = (scheduledDateTime?: string) => {
        let timeline: ActivityFeed.UserPostTimeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: this.props.clubId,
        };

        if (this.props.selectedChannel && !this.props.selectedChannel.isDefault) {
            timeline.channelId = `${this.props.selectedChannel.id}`;
        }

        let tls = [timeline];
        let details: ActivityFeed.PostTypeData = {
            locator: this.state.locator
        };

        let payload: ActivityFeed.UserPost = {
            postType: this.state.locator ? ActivityFeed.PostType.XblLink : ActivityFeed.PostType.Text,
            postText: this.state.text,
            postDate: scheduledDateTime,
            timelines: tls,
            postTypeData: details,
        };

        return payload;
    }

    onPostFeedItem = (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, scheduledDateTime?: string) => {
        if (!this.props.clubId) {
            return;
        }

        let payload = this.prepareXboxLinkItem(scheduledDateTime);

        this.props.handlePostClick(payload, isPinned, isDisabled, isScheduled, scheduledDateTime);
        this.resetState();
    }

    onCancelPost = () => {
        this.resetState();
    }
    render() {
        let permissions = this.props.permissions;
        if (!permissions) {
            return <GenericLoading />;
        }

        if (!permissions.postMediaFromDevice && !permissions.postMediaFromXblLibrary) {
            return (
                <GenericErrorMessage
                    errorMessage={Localize("feedtools.warning", { context: "media" })}
                    showError={true}
                />
            );
        }

        return (
            <div>
                <fts.Header>
                    {Localize("postFeedItem.postDetails" )}
                </fts.Header>

                <ChooseMediaContainer
                    fetchMediaItems={this.props.fetchMediaItems}
                    uploadMediaItems={this.props.uploadMediaItems}
                    setMediaItem={this.setMediaItem}
                    getAllTitles={this.props.getAllTitles}
                    permissions={this.props.permissions}
                    hydrationStates={this.props.hydrationStates}
                    showWebLinkOption={false}
                    showItems={XBLMediaChoices.Both}
                    club={this.props.club}
                    ref={this.chooseMediaRef}
                />

                <br/>
                {Localize("postFeedItem.postText")}
                <br/>

                <PostTextArea
                    onChange={this.onChangeText}
                    value={this.state.text}
                    placeholder={Localize("postFeedItem.textPostPlaceholder")}
                />

                <PostOptionsContainer
                    onPostFeedItem={this.onPostFeedItem}
                    onCancelPost={this.onCancelPost}
                    getPreviewItem={this.onPreviewItem}
                    canPin={permissions.pinPost}
                    canSchedule={permissions.schedulePost}
                    postAbilityBlocked={this.state.imagePreview || this.state.text ? false : true}
                    forceSchedule={this.props.forceSchedule}
                />
            </div>
        );
    }
}
