"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImagePickerActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ImagePickerActions;

(function (ImagePickerActions) {
  ImagePickerActions.imageSelected = redux_ts_simple_1.createAction("IMAGE_PICKER:CONFIRM_IMAGE");
  ImagePickerActions.getImages = redux_ts_simple_1.createAction("IMAGE_PICKER:GET_IMAGES");
  ImagePickerActions.imageTypeSelected = redux_ts_simple_1.createAction("IMAGE_PICKER:IMAGE_TYPE_SELECTED");
  ImagePickerActions.getImagesCompleted = redux_ts_simple_1.createAction("IMAGE_PICKER:GET_IMAGES_COMPLETED");
})(ImagePickerActions = exports.ImagePickerActions || (exports.ImagePickerActions = {}));