import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { withContext } from "./ContextUtils";
import { withClubSelectionContext } from "../ClubSelectionContext";
import ClubMenuTabs from "./ClubMenuTabs";

export type ClubMenuMode = "horizontal" | "vertical" | "inline";

interface ClubMenuStateProps {}

interface ClubMenuOtherProps {
    mode: ClubMenuMode;
    isOpen: boolean;
    allClubs: ClubHub.Club[];
    toggleSlider?: () => void;
    toggleMenuCommand?: () => void;
}

interface ClubMenuDispatchProps {
}

type ClubMenuProps = ClubMenuStateProps & ClubMenuDispatchProps & ClubMenuOtherProps;

class ClubMenu extends React.Component<ClubMenuProps> {

    constructor(props: ClubMenuProps) {
        super(props);
    }

    public render() {
        return (
            <nav aria-label="navigation" style={{ paddingBottom: 60 }}>
                <ClubMenuTabs {...this.props} />
            </nav>
        );
    }
}

const mapStateToProps = (state: any): ClubMenuStateProps => {
    return {};
};

const mapDispatchToProps = (dispatch: any): ClubMenuDispatchProps => {
    return {};
};

export default withClubSelectionContext(withContext(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClubMenu)));
