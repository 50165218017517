import * as React from "react";
import { ActivityFeed, User, ClubHub } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers } from "@xbox/social-views";

import * as FeedItemStyles from "../base/FeedItem.Styles";
import { withClubSelectionContext, ClubSelectionContext } from "../../../containers/ClubSelectionContext";
import { withCurrentUserContext } from "../../../containers/CurrentUserContext";
import { Glyphs } from "../../../common/Generic.Icons";
import { Icon } from "../../../common/GenericElements";

export interface SocialActionsProps {
    item: ActivityFeed.FeedItem;
    showSocialActions?: boolean;
    socialActions?: IFeedItemSocialActionHandlers;
    user?: User;
}

export interface SocialActionsState {
    showComments?: boolean;
}

type ISocialActionProps = SocialActionsProps & ClubSelectionContext;

class SocialActionsContainer extends React.Component<ISocialActionProps, SocialActionsState> {
    constructor(props: SocialActionsProps) {
        super(props);
        this.state = {
            showComments: false
        };
    }

    showComments = (e: any) => {
        if (this.props.socialActions && this.props.socialActions.viewComments) {
            this.props.socialActions.viewComments(this.props.item);
        }
        e.stopPropagation();
    }

    onLikeClick = (e: any) => {
        if (this.props.socialActions) {
            this.props.socialActions.like(this.props.item, !this.props.item.hasLiked);
        }
        e.stopPropagation();
    }

    onShareClick = (e: any) => {
        if (this.props.socialActions && this.props.socialActions.showShareModal) {
            this.props.socialActions.showShareModal(this.props.item);
        }
        e.stopPropagation();
    }

    showShareButton = (): JSX.Element | null => {
        if (this.props.clubItem && this.props.clubItem.details.clubType.type !== ClubHub.ClubTypeTypes.Secret) {
            return (
                <FeedItemStyles.SocialButton className="SocialButton" type="button" role="button" aria-label={this.props.item.description + " " + "Share"} onClick={this.onShareClick} title="Share">
                    <FeedItemStyles.SocialCount>
                        <Icon type={Glyphs.Share}/>
                        {this.props.item.stat ? this.props.item.stat.shares : 0}
                    </FeedItemStyles.SocialCount>
                </FeedItemStyles.SocialButton>
            );
        }

        return null;
    }

    render() {
        let isVisible = this.props.showSocialActions === false ? "none" : "flex";
        return (
            <FeedItemStyles.SocialButtonsContainer style={{ display: isVisible }} className="SocialActionsContainer">
                <FeedItemStyles.SocialButton className="SocialButton" type="button" role="button" aria-label={this.props.item.description + " " + "Like"} onClick={this.onLikeClick} title="Like">
                    <FeedItemStyles.SocialCount>
                        <FeedItemStyles.SocialIcon type={this.props.item.hasLiked ? Glyphs.HeartFull : Glyphs.Heart} style={this.props.item.hasLiked ? { color: "red" } : {}} />{this.props.item.stat ? this.props.item.stat.likes : 0}
                    </FeedItemStyles.SocialCount>
                </FeedItemStyles.SocialButton>
                {this.showShareButton()}
                <FeedItemStyles.SocialButton className="SocialButton" role="link" aria-label={this.props.item.description + " " + "Comment"} onClick={this.showComments} title="Comment">
                    <FeedItemStyles.SocialCount>
                        <FeedItemStyles.SocialIcon type={Glyphs.Chat} />{this.props.item.stat ? this.props.item.stat.comments : 0}
                    </FeedItemStyles.SocialCount>
                </FeedItemStyles.SocialButton>
            </FeedItemStyles.SocialButtonsContainer>
        );
    }
}

export default withCurrentUserContext(withClubSelectionContext((SocialActionsContainer)));
