"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartySaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var redux_saga_1 = require("redux-saga");

var PartyActions_1 = require("../actions/PartyActions");

var remoteStreamAddedChannel = redux_saga_1.channel();
var membersAdded = redux_saga_1.channel();
var membersRemoved = redux_saga_1.channel();

var PartySaga =
/** @class */
function () {
  function PartySaga(partyService, peopleRepository) {
    this.partyService = partyService;
    this.peopleRepository = peopleRepository;
    this.sagas = [this.watchStartParty(), this.watchLeaveParty(), this.watchJoinParty(), this.watchRemoteStreamAdded(), this.watchMembersAdded(), this.watchMembersRemoved(), this.watchInviteFriend(), this.watchInviteByGamertag()];
    this.partyService.oPartyMembersAdded.subscribe(function (addedMembers) {
      membersAdded.put(addedMembers);
    });
    this.partyService.oPartyMembersRemoved.subscribe(function (removedMembers) {
      return membersRemoved.put(removedMembers);
    });
    this.partyService.oRemoteStreams.subscribe(function (remoteMediaStream) {
      return remoteStreamAddedChannel.put(remoteMediaStream);
    });
  }

  PartySaga.prototype._startParty = function () {
    var exc_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.partyService.startParty()];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.partyInProgress(true))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_1 = _a.sent();
          console.error(exc_1);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._leaveParty = function () {
    var exc_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.partyService.leaveParty()];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.partyInProgress(false))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_2 = _a.sent();
          console.error(exc_2);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._joinByGamertag = function (action) {
    var exc_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.partyService.joinByGamertag(action.payload)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.partyInProgress(true))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_3 = _a.sent();
          console.error(exc_3);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._inviteFriend = function (action) {
    var exc_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.partyService.inviteUser(action.payload)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.inviteSent())];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_4 = _a.sent();
          console.error(exc_4);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._inviteByGamertag = function (action) {
    var exc_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.partyService.inviteByGamertag(action.payload)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.inviteSent())];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_5 = _a.sent();
          console.error(exc_5);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._sendRemoteMediaAddedAction = function (mediaStream) {
    var exc_6;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.remoteStreamAdded(mediaStream))];

        case 1:
          _a.sent();

          return [3
          /*break*/
          , 3];

        case 2:
          exc_6 = _a.sent();
          console.error(exc_6);
          return [3
          /*break*/
          , 3];

        case 3:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._sendMembersAddedAction = function (addedMembers) {
    var people_1, addedPartyMembers, exc_7;

    var _this = this;

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , effects_1.all(addedMembers.map(function (member) {
            return _this.peopleRepository.getPerson(member.xuid);
          }))];

        case 1:
          people_1 = _a.sent();
          addedPartyMembers = addedMembers.map(function (member) {
            var memberPerson = people_1.filter(function (person) {
              return person.xuid === member.xuid;
            })[0];
            return __assign(__assign({}, member), {
              person: memberPerson
            });
          });
          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.membersAdded(addedPartyMembers))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          exc_7 = _a.sent();
          console.error(exc_7);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype._sendMembersRemovedAction = function (removedMembers) {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.put(PartyActions_1.PartyActions.membersRemoved(removedMembers))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchStartParty = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PartyActions_1.PartyActions.startParty.type, this._startParty.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchLeaveParty = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PartyActions_1.PartyActions.leaveParty.type, this._leaveParty.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchJoinParty = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PartyActions_1.PartyActions.joinByGamertag.type, this._joinByGamertag.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchRemoteStreamAdded = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(remoteStreamAddedChannel, this._sendRemoteMediaAddedAction.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchMembersAdded = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(membersAdded, this._sendMembersAddedAction.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchMembersRemoved = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(membersRemoved, this._sendMembersRemovedAction.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchInviteFriend = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PartyActions_1.PartyActions.inviteFriend.type, this._inviteFriend.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga.prototype.watchInviteByGamertag = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(PartyActions_1.PartyActions.inviteByGamertag.type, this._inviteByGamertag.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  PartySaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IPartyService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.IPeopleRepository)), __metadata("design:paramtypes", [Object, Object])], PartySaga);
  return PartySaga;
}();

exports.PartySaga = PartySaga;