"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UrlSearchParams = void 0;

var UrlSearchParams =
/** @class */
function () {
  function UrlSearchParams(query) {
    var _this = this;

    this.params = new Map();

    if (query) {
      // search and hash can include a leading ? and #, so strip that first
      var sanitizedQuery = query.startsWith("#") || query.startsWith("&") ? query.slice(1) : query;
      var parts = sanitizedQuery.split("&");

      if (!parts || parts.length === 1 && parts[0].length <= 0) {
        return;
      }

      parts.forEach(function (part) {
        var subParts = part.split("=");

        _this.append(subParts[0], subParts[1]);
      });
    }
  }

  UrlSearchParams.prototype.sort = function () {
    throw new Error("Method not implemented.");
  };

  UrlSearchParams.prototype.forEach = function (callbackfn, thisArg) {
    throw new Error("Method not implemented.");
  };

  UrlSearchParams.prototype.append = function (name, value) {
    var valueList = this.params.get(name);

    if (!valueList) {
      valueList = [];
      this.params.set(name, valueList);
    }

    valueList.push(value);
  };

  UrlSearchParams.prototype.delete = function (name) {
    this.params.delete(name);
  };

  UrlSearchParams.prototype.get = function (name) {
    var valueList = this.params.get(name);

    if (valueList && valueList.length > 0) {
      return valueList[0];
    } else {
      return null;
    }
  };

  UrlSearchParams.prototype.getAll = function (name) {
    var valueList = this.params.get(name);

    if (valueList && valueList.length > 0) {
      return valueList;
    } else {
      return [];
    }
  };

  UrlSearchParams.prototype.has = function (name) {
    return this.params.has(name);
  };

  UrlSearchParams.prototype.set = function (name, value) {
    this.params.set(name, [value]);
  };

  UrlSearchParams.prototype.entries = function () {
    return this.params.entries();
  };

  UrlSearchParams.prototype.keys = function () {
    return this.params.keys();
  };

  UrlSearchParams.prototype.values = function () {
    return this.params.values();
  };

  UrlSearchParams.prototype.toString = function () {
    return Array.from(this.params.entries()).map(function (pair) {
      return pair["1"].map(function (value) {
        return encodeURIComponent(pair["0"]) + "=" + encodeURIComponent(value);
      });
    }).map(function (values) {
      return values.join("&");
    }).join("&");
  };

  return UrlSearchParams;
}();

exports.UrlSearchParams = UrlSearchParams;