"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LfgSaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var LfgActions_1 = require("../actions/LfgActions");

var ClubListActions_1 = require("../actions/ClubListActions");

var Utils_1 = require("./Utils");

var LfgSaga =
/** @class */
function () {
  function LfgSaga(mpsdService, telemetryService, peopleHubService, clubHubService, notificationService, tagsService) {
    this.mpsdService = mpsdService;
    this.telemetryService = telemetryService;
    this.peopleHubService = peopleHubService;
    this.clubHubService = clubHubService;
    this.notificationService = notificationService;
    this.tagsService = tagsService;
    this.sagas = [this.watchGetLfgs(), this.watchAdminActOnLfgItem()];
  }

  LfgSaga.prototype._getLfgs = function (action) {
    var cleanTags, result, xuidsToHydrate_1, hydratedPeople, xuidToPersonMap_1, tagsToHydrate_1, tags, tagIdToTagMap_1, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 11,, 13]);

          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.getLfgsInProgress(true))];

        case 1:
          _a.sent();

          cleanTags = action.payload.tag || "";
          cleanTags = cleanTags.replace(/([^a-z0-9]+)/gi, "");
          result = void 0;
          if (!action.payload.scid) return [3
          /*break*/
          , 3];
          return [4
          /*yield*/
          , this.mpsdService.getSessionHandlesByScid(action.payload.scid, action.payload.clubId, cleanTags)];

        case 2:
          result = _a.sent();
          return [3
          /*break*/
          , 5];

        case 3:
          return [4
          /*yield*/
          , this.mpsdService.getSessionHandlesByClub(action.payload.clubId, cleanTags)];

        case 4:
          result = _a.sent();
          _a.label = 5;

        case 5:
          xuidsToHydrate_1 = [];
          result.forEach(function (lfg) {
            if (lfg.relatedInfo && lfg.relatedInfo.sessionOwners) {
              lfg.relatedInfo.sessionOwners.forEach(function (xuid) {
                if (xuidsToHydrate_1.indexOf(xuid) === -1) {
                  xuidsToHydrate_1.push(xuid);
                }
              });
            }
          });
          if (!xuidsToHydrate_1.length) return [3
          /*break*/
          , 7];
          return [4
          /*yield*/
          , this.peopleHubService.getPeople(xuidsToHydrate_1)];

        case 6:
          hydratedPeople = _a.sent();
          xuidToPersonMap_1 = {};
          hydratedPeople.forEach(function (person) {
            xuidToPersonMap_1[person.xuid] = person;
          });
          result.forEach(function (lfg) {
            lfg.relatedInfo.sessionOwners.forEach(function (xuid) {
              var person = xuidToPersonMap_1[xuid];

              if (person) {
                if (lfg.relatedInfo.hydratedSessionOwners) {
                  lfg.relatedInfo.hydratedSessionOwners.push(person);
                } else {
                  lfg.relatedInfo.hydratedSessionOwners = [person];
                }
              }
            });
          });
          _a.label = 7;

        case 7:
          tagsToHydrate_1 = [];
          result.forEach(function (lfg) {
            if (lfg.searchAttributes && lfg.searchAttributes.tags) {
              lfg.searchAttributes.tags.forEach(function (tag) {
                if (tagsToHydrate_1.indexOf(tag) === -1) {
                  tagsToHydrate_1.push(tag);
                }
              });
            }
          });
          return [4
          /*yield*/
          , this.tagsService.hyrdrateTags(tagsToHydrate_1)];

        case 8:
          tags = _a.sent();
          tagIdToTagMap_1 = {};
          tags.forEach(function (tag) {
            tagIdToTagMap_1[tag.tagId] = tag;
          });
          result.forEach(function (lfg) {
            if (lfg.searchAttributes && lfg.searchAttributes.tags) {
              lfg.searchAttributes.tags.forEach(function (id) {
                var tag = tagIdToTagMap_1[id];

                if (tag) {
                  if (lfg.searchAttributes.hydratedTags) {
                    lfg.searchAttributes.hydratedTags.push(tag);
                  } else {
                    lfg.searchAttributes.hydratedTags = [tag];
                  }
                }
              });
            }
          });
          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.getLfgsCompleted(result))];

        case 9:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.getLfgsInProgress(false))];

        case 10:
          _a.sent();

          return [3
          /*break*/
          , 13];

        case 11:
          err_1 = _a.sent();
          this.notificationService.notifyError("LfgTagSearch", "10");
          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.getLfgsInProgress(false))];

        case 12:
          _a.sent();

          Utils_1.LogHttpsError("Error getting Lfgs", err_1);
          this.telemetryService.trackException(err_1, "GetLfgs", {
            "clubId": action.payload
          });
          return [3
          /*break*/
          , 13];

        case 13:
          return [2
          /*return*/
          ];
      }
    });
  };

  LfgSaga.prototype._adminActOnLfgItem = function (action) {
    var lfg, _a, rosterUpdate, err_2;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          _b.trys.push([0, 13,, 14]);

          lfg = action.payload.item;
          _a = action.payload.action;

          switch (_a) {
            case social_core_1.ActivityFeed.AdminActionTypes.Delete:
              return [3
              /*break*/
              , 1];

            case social_core_1.ActivityFeed.AdminActionTypes.DeleteAndBan:
              return [3
              /*break*/
              , 5];
          }

          return [3
          /*break*/
          , 11];

        case 1:
          if (!(lfg.sessionRef.scid && lfg.sessionRef.name)) return [3
          /*break*/
          , 4];
          return [4
          /*yield*/
          , this.mpsdService.deleteSessionHandleForClub(lfg.sessionRef.scid, lfg.sessionRef.name)];

        case 2:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.lfgItemDeleted(lfg.id))];

        case 3:
          _b.sent();

          _b.label = 4;

        case 4:
          return [3
          /*break*/
          , 12];

        case 5:
          if (!(lfg.sessionRef.scid && lfg.sessionRef.name)) return [3
          /*break*/
          , 8];
          return [4
          /*yield*/
          , this.mpsdService.deleteSessionHandleForClub(lfg.sessionRef.scid, lfg.sessionRef.name)];

        case 6:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(LfgActions_1.LfgActions.lfgItemDeleted(lfg.id))];

        case 7:
          _b.sent();

          _b.label = 8;

        case 8:
          rosterUpdate = {
            add: true,
            clubId: action.payload.clubId,
            role: social_core_1.ClubHub.ClubRoles.Banned,
            xuid: action.payload.xuid
          };
          return [4
          /*yield*/
          , this.clubHubService.addClubMemberRole(action.payload.clubId, action.payload.xuid, [social_core_1.ClubHub.ClubRoles.Banned])];

        case 9:
          _b.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubRosterUpdateRoleProperty(rosterUpdate))];

        case 10:
          _b.sent();

          return [3
          /*break*/
          , 12];

        case 11:
          return [2
          /*return*/
          ];

        case 12:
          return [3
          /*break*/
          , 14];

        case 13:
          err_2 = _b.sent();
          console.warn("Unable to " + action.payload.action + " item, error: " + err_2);
          this.telemetryService.trackException(err_2, "LfgItemAdminAction", {
            "action": action.payload.action
          });
          return [3
          /*break*/
          , 14];

        case 14:
          return [2
          /*return*/
          ];
      }
    });
  };

  LfgSaga.prototype.watchAdminActOnLfgItem = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(LfgActions_1.LfgActions.adminActOnItem.type, this._adminActOnLfgItem.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  LfgSaga.prototype.watchGetLfgs = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(LfgActions_1.LfgActions.getLfgs.type, this._getLfgs.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  LfgSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IMpsdPartyService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.IPeopleHubService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.IClubHubService)), __param(4, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(5, inversify_1.inject(social_core_1.XSocialSymbols.ITagService)), __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object])], LfgSaga);
  return LfgSaga;
}();

exports.LfgSaga = LfgSaga;