"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthStorageAdapter = void 0;

var inversify_1 = require("inversify");

var typings_1 = require("../../typings");

var AuthStorageAdapter =
/** @class */
function () {
  function AuthStorageAdapter(localStorageService) {
    this.localStorageService = localStorageService;
  }

  AuthStorageAdapter.prototype.read = function (key) {
    return this.localStorageService.getItem(key);
  };

  AuthStorageAdapter.prototype.write = function (key, data) {
    return this.localStorageService.setItem(key, data);
  };

  AuthStorageAdapter.prototype.clear = function (key) {
    return this.localStorageService.removeItem(key);
  };

  AuthStorageAdapter = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.ILocalStorageService)), __metadata("design:paramtypes", [Object])], AuthStorageAdapter);
  return AuthStorageAdapter;
}();

exports.AuthStorageAdapter = AuthStorageAdapter;