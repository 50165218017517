var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { mediaHubRedux, titleHubRedux } from "@xbox/social-redux";

function manageMediaContainer(manageMediaResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.uploadMediaItems = function (data, isVideo) {
          if (isVideo) {
            _this.props.uploadXblGameclip(data);
          } else {
            _this.props.uploadXblScreenshot(data);
          }
        };

        _this.fetchMediaItems = function (payload, isVideo) {
          if (isVideo) {
            _this.props.fetchXblGameclips(payload);
          } else {
            _this.props.fetchXblScreenshots(payload);
          }
        };

        _this.deleteMediaItem = function (contentId, isVideo) {
          if (isVideo) {
            _this.props.deleteXblGameClip(contentId);
          } else {
            _this.props.deleteXblScreenshot(contentId);
          }
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.props.getUsersQuota();
      };

      class_1.prototype.render = function () {
        var handlers = {
          uploadMediaItem: this.uploadMediaItems,
          fetchMediaItems: this.fetchMediaItems,
          deleteMediaItem: this.deleteMediaItem,
          getUserMediaTitles: this.props.getUserMediaTitles,
          getAllTitles: this.props.getAllTitles
        };
        return manageMediaResolver.getManageMediaViewers(this.props.hydration, handlers);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var hydrationState = {
    uploadState: state.mediahub.upload,
    screenshots: state.mediahub.screenshots,
    gameclips: state.mediahub.gameclips,
    titleOptions: state.mediahub.mediaTitles,
    usersQuota: state.mediahub.usersQuota,
    allTitles: state.titlehub.alltitles
  };
  return {
    hydration: hydrationState,
    currentUser: state.authentication.currentUser
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    uploadXblScreenshot: function uploadXblScreenshot(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.uploadScreenshot(mediaItemPayload));
    },
    uploadXblGameclip: function uploadXblGameclip(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.uploadGameclip(mediaItemPayload));
    },
    fetchXblScreenshots: function fetchXblScreenshots(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.fetchScreenshots(mediaItemPayload));
    },
    fetchXblGameclips: function fetchXblGameclips(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.fetchGameclips(mediaItemPayload));
    },
    deleteXblScreenshot: function deleteXblScreenshot(contentId) {
      return dispatch(mediaHubRedux.actions.deleteScreenshot(contentId));
    },
    deleteXblGameClip: function deleteXblGameClip(contentId) {
      return dispatch(mediaHubRedux.actions.deleteGameClip(contentId));
    },
    getAllTitles: function getAllTitles() {
      return dispatch(titleHubRedux.actions.fetchAllTitles());
    },
    getUserMediaTitles: function getUserMediaTitles() {
      return dispatch(mediaHubRedux.actions.getUsersMediaTitles());
    },
    getUsersQuota: function getUsersQuota() {
      return dispatch(mediaHubRedux.actions.getUsersQuota());
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function ManageMediaContainer(manageMediaResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(manageMediaContainer(manageMediaResolver));
}