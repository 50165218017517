import * as React from "react";
import * as moment from "moment";
import { ActivityFeed, Person } from "@xbox/social-core";
import {
    CoreAnalyticsContainer,
    IAnalyticsResolver,
    IAnalyticsHandlers,
    IFeedItemSocialActionHandlers,
    BatchFeedItems,
} from "@xbox/social-views";
import { AnalyticsState } from "@xbox/social-redux";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { GenericErrorMessage } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import AnalyticsViewer from "./AnalyticsViewer";

const getAnalyticsContainer = (
    analytics: AnalyticsState,
    handlers: IAnalyticsHandlers,
    socialActions: IFeedItemSocialActionHandlers,
    month: string | undefined,
    batchFeedItems: BatchFeedItems,
    engagers: Person[],
    clubId: string,
    navigateCommentsPage: (item: ActivityFeed.FeedItem, returnPage: string) => void,
    navigateBackToPage: (targetPage: string) => void,
): JSX.Element | null => {

    let isLoading = (analytics.fetchAdminAnalyticsInProgress || analytics.fetchFeedAnalyticsInProgress);

    let displayComponent: JSX.Element | null = null;

    if (analytics.adminActionsData && analytics.feedEngagementData) {
        month = month ? moment(month, "MMM").format("MM") : undefined;

        displayComponent = (
            <AnalyticsViewer
                isLoading={isLoading}
                month={month}
                clubId={clubId}
                socialActions={socialActions}
                analyticsData={analytics}
                batchFeedItems={batchFeedItems}
                ExportAnalyticsReport={() => handlers.ExportAnalyticsReport()}
                engagers={engagers}
                navigateCommentsPage={navigateCommentsPage}
                navigateBackToPage={navigateBackToPage}
                OnMonthChanged={date => date
                    ? handlers.OnMonthChanged(moment(date).format("MMM").toLowerCase())
                    : handlers.OnMonthChanged("")
                }
            />
        );
    } else if (!isLoading && !analytics.adminActionsData && !analytics.feedEngagementData) {
        displayComponent = <GenericErrorMessage errorMessage={Localize("analyticsView.errorRetrieving")} showError={true} />;
    }
    return displayComponent;
};

const AnalyticsResolver: IAnalyticsResolver = {
    getAnalyticsContainer: getAnalyticsContainer
};
export default withClubSelectionContext(CoreAnalyticsContainer(AnalyticsResolver));
