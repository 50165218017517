import * as React from "react";
import * as AnalyticsStyles from "./Analytics.Styles";
import { NumberFormatter } from "../../Utils";

export interface StatProps {
    amount: number;
    change?: number;
    name: string;
}

export default function Stat(props: StatProps) {
    return (
        <AnalyticsStyles.LayoutWrapper>
            <AnalyticsStyles.SummaryContainer>
                <AnalyticsStyles.SummaryData>
                    <strong>{NumberFormatter(props.amount)}</strong>
                    {props.change ? <AnalyticsStyles.StatSpan>{props.change > 0 ? "+" : "-"}{props.change}</AnalyticsStyles.StatSpan> : ""}
                </AnalyticsStyles.SummaryData>
                <small>{props.name}</small>
            </AnalyticsStyles.SummaryContainer>
        </AnalyticsStyles.LayoutWrapper>
    );
}
