"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XalInternalErrorType = void 0;
var XalInternalErrorType;

(function (XalInternalErrorType) {
  XalInternalErrorType["Aborted"] = "Aborted";
  XalInternalErrorType["BadDeviceIdentity"] = "BadDeviceIdentity"; // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL, this is to support anonymous Xtokens

  XalInternalErrorType["BadTitleIdentity"] = "BadTitleIdentity";
  XalInternalErrorType["BadUserToken"] = "BadUserToken";
  XalInternalErrorType["ClientError"] = "ClientError";
  XalInternalErrorType["DuplicatedUser"] = "DuplicatedUser";
  XalInternalErrorType["InitializationError"] = "InitializationError";
  XalInternalErrorType["InvalidArguments"] = "InvalidArguments";
  XalInternalErrorType["NetworkError"] = "NetworkError";
  XalInternalErrorType["NoDisplayClaimsFound"] = "NoDisplayClaimsFound";
  XalInternalErrorType["NoSessionInfoFound"] = "NoSessionInfoFound";
  XalInternalErrorType["NoUserFound"] = "NoUserFound";
  XalInternalErrorType["NoWebViewFlowId"] = "NoWebViewFlowId";
  XalInternalErrorType["ParseError"] = "ParseError";
  XalInternalErrorType["SilentRefreshFailed"] = "SilentRefreshFailed";
  XalInternalErrorType["SwitchUser"] = "SwitchUser";
  XalInternalErrorType["TooManyCachedUsers"] = "TooManyCachedUsers";
  XalInternalErrorType["UiRequired"] = "UiRequired";
  XalInternalErrorType["UserSetFull"] = "UserSetFull";
  XalInternalErrorType["UserSetNotEmpty"] = "UserSetNotEmpty";
  XalInternalErrorType["UnexpectedUserSignedIn"] = "UnexpectedUserSignedIn";
  XalInternalErrorType["Unknown"] = "Unknown";
})(XalInternalErrorType = exports.XalInternalErrorType || (exports.XalInternalErrorType = {}));