"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticsActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var AnalyticsActions;

(function (AnalyticsActions) {
  // fetch feed engagemnt data
  AnalyticsActions.fetchFeedAnalytics = redux_ts_simple_1.createAction("ANALYTICS::FETCH_FEED_ANALYTICS");
  AnalyticsActions.fetchFeedAnalyticsInProgress = redux_ts_simple_1.createAction("ANALYTICS::FETCH_FEED_ANALYTICS_INPROGRESS");
  AnalyticsActions.fetchFeedAnalyticsCompleted = redux_ts_simple_1.createAction("ANALYTICS::FETCH_FEED_ANALYTICS_COMPLETED"); // fetch admin action data

  AnalyticsActions.fetchAdminAnalytics = redux_ts_simple_1.createAction("ANALYTICS::FETCH_ADMIN_ANALYTICS");
  AnalyticsActions.fetchAdminAnalyticsInProgress = redux_ts_simple_1.createAction("ANALYTICS::FETCH_ADMIN_ANALYTICS_INPROGRESS");
  AnalyticsActions.fetchAdminAnalyticsCompleted = redux_ts_simple_1.createAction("ANALYTICS::FETCH_ADMIN_ANALYTICS_COMPLETED"); // fetch csv analytics data

  AnalyticsActions.fetchCSVAnalytics = redux_ts_simple_1.createAction("ANALYTICS::FETCH_CSV_ANALYTICS");
  AnalyticsActions.fetchCSVAnalyticsInProgress = redux_ts_simple_1.createAction("ANALYTICS::FETCH_CSV_ANALYTICS_INPROGRESS");
  AnalyticsActions.fetchCSVAnalyticsCompleted = redux_ts_simple_1.createAction("ANALYTICS::FETCH_CSV_ANALYTICS_COMPLETED");
  AnalyticsActions.applyMonth = redux_ts_simple_1.createAction("ANALYTICS::APPLY_ANALYTICS_MONTH");
  AnalyticsActions.resetAnalytics = redux_ts_simple_1.createAction("ANALYTICS::RESET_ANALYTICS");
})(AnalyticsActions = exports.AnalyticsActions || (exports.AnalyticsActions = {}));