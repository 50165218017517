import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import {
    CoreAggregatesContainer,
    IAggregatesResolver,
    IFeedItemSocialActionHandlers,
    FeedStatus
} from "@xbox/social-views";
import ClublessActivityFeed from "../../components/activityfeed/ClublessActivityFeed";

const getAggregatesContainer = (
    updateMethod: (cursor?: string) => void,
    clearActivityFeed: () => void,
    feedItemSelected: (feedItem: ActivityFeed.FeedItem) => void,
    socialActions: IFeedItemSocialActionHandlers,
    feedStatus: FeedStatus,
    cursor?: string
): JSX.Element | null => {

    return (
        <ClublessActivityFeed
            isErrored={feedStatus.isErrored}
            feedItemSelected={feedItemSelected}
            updateMethod={updateMethod}
            clearActivityFeed={clearActivityFeed}
            socialActions={socialActions}
            listData={feedStatus.results}
            cursor={cursor}
            isValid={feedStatus.isValid}
        />
    );
};

const AggregatesResolver: IAggregatesResolver = {
    getAggregatesContainer: getAggregatesContainer
};
export default CoreAggregatesContainer(AggregatesResolver);
