"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducers = void 0;

var AuthReducer_1 = require("./AuthReducer");

var PeopleReducer_1 = require("./People/PeopleReducer");

var PartyReducer_1 = require("./party/PartyReducer");

var ClubSelectors_1 = require("./club/ClubSelectors");

var FeedViewerSelectors_1 = require("./feed/FeedViewerSelectors");

var MediaHubSelectors_1 = require("./mediahub/MediaHubSelectors");

var TitleHubSelectors_1 = require("./titlehub/TitleHubSelectors");

var ExperimentsReducer_1 = require("./ExperimentsReducer");

var ClubModerationReducer_1 = require("./club/ClubModerationReducer");

var ClubInviteQueueReducer_1 = require("./club/ClubInviteQueueReducer");

var ClubRosterReducer_1 = require("./club/ClubRosterReducer");

var AnalyticsDatePickerReducer_1 = require("./analytics/AnalyticsDatePickerReducer");

var FeedbackReducer_1 = require("./common/FeedbackReducer");

var RouterReducer_1 = require("./router/RouterReducer");

var ImagePickerReducer_1 = require("./common/ImagePicker/ImagePickerReducer");

var TagsReducer_1 = require("./common/tagPicker/TagsReducer");

var PrivacyReducer_1 = require("./privacy/PrivacyReducer");

var LfgReducer_1 = require("./lfg/LfgReducer");

var Channelsreducer_1 = require("./club/Channelsreducer");

var ShareReducer_1 = require("./club/ShareReducer");

exports.reducers = {
  authentication: AuthReducer_1.authReducer,
  clubs: ClubSelectors_1.clubsRedux.reducer,
  feedViewer: FeedViewerSelectors_1.feedViewerRedux.reducer,
  party: PartyReducer_1.partyReducer,
  people: PeopleReducer_1.peopleReducer,
  experiments: ExperimentsReducer_1.experimentsReducer,
  moderation: ClubModerationReducer_1.clubModerationReducer,
  inviteQ: ClubInviteQueueReducer_1.clubInviteQueueReducer,
  roster: ClubRosterReducer_1.clubRosterReducer,
  mediahub: MediaHubSelectors_1.mediaHubRedux.reducer,
  titlehub: TitleHubSelectors_1.titleHubRedux.reducer,
  datepicker: AnalyticsDatePickerReducer_1.analyticsDatePickerReducer,
  feedback: FeedbackReducer_1.feedbackReducer,
  router: RouterReducer_1.routerReducer,
  tagsCache: TagsReducer_1.tagsReducer,
  privacy: PrivacyReducer_1.privacyReducer,
  imagePicker: ImagePickerReducer_1.imagePickerReducer,
  lfgs: LfgReducer_1.lfgReducer,
  channels: Channelsreducer_1.channelsReducer,
  share: ShareReducer_1.shareReducer
};