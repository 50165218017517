"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aggregatedFeedViewerReducer = void 0;

var redux_1 = require("redux");

var ActivityFeedViewerReducer_1 = require("./ActivityFeedViewerReducer");

var LinkHydrationReducer_1 = require("./LinkHydrationReducer");

var StringValidationReducer_1 = require("./StringValidationReducer");

var PostStatusReducer_1 = require("./PostStatusReducer");

var CommentStatusReducer_1 = require("./CommentStatusReducer");

var FeedItemsHydrationReducer_1 = require("./FeedItemsHydrationReducer"); // FeedViewer Area


exports.aggregatedFeedViewerReducer = redux_1.combineReducers({
  activityFeed: ActivityFeedViewerReducer_1.activityFeedReducer,
  hydrator: LinkHydrationReducer_1.hydratorReducer,
  feedItems: FeedItemsHydrationReducer_1.feedItemsHydratorReducer,
  stringValidation: StringValidationReducer_1.stringValidateReducer,
  postStatus: PostStatusReducer_1.postStatusReducer,
  commentStatus: CommentStatusReducer_1.commentStatusReducer
});