"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aggregatedMediaHubReducer = void 0;

var redux_1 = require("redux");

var FetchScreenshotsReducers_1 = require("./FetchScreenshotsReducers");

var FetchGameclipsReducers_1 = require("./FetchGameclipsReducers");

var UploadReducers_1 = require("./UploadReducers");

var UserMediaTitleReducers_1 = require("./UserMediaTitleReducers");

var FetchMetadataReducers_1 = require("./FetchMetadataReducers");

var FetchUsersQuotaReducers_1 = require("./FetchUsersQuotaReducers"); // MediaHub Area


exports.aggregatedMediaHubReducer = redux_1.combineReducers({
  screenshots: FetchScreenshotsReducers_1.screenshotsReducer,
  gameclips: FetchGameclipsReducers_1.gameclipsReducer,
  upload: UploadReducers_1.uploadReducer,
  mediaTitles: UserMediaTitleReducers_1.userMediaTitleReducer,
  screensMetadata: FetchMetadataReducers_1.screensMetadataReducer,
  clipsMetadata: FetchMetadataReducers_1.clipsMetadataReducer,
  usersQuota: FetchUsersQuotaReducers_1.usersQuotaReducer
});