"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubViewerReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({
  fetchInProgress: false
});
exports.clubViewerReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubFetchInProgress, function (state, action) {
  return state.merge({
    fetchInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.clubFetchCompleted, function (state, action) {
  return state.merge({
    clubs: action.payload
  }, {
    deep: true
  });
}).on(ClubListActions_1.ClubActions.clubUpdateProperty, function (state, action) {
  if (action.payload.update && action.payload.update.modifiedFields.indexOf("displayImageUrl") >= 0) {
    var newImage = action.payload.updatedValue;
    return state.setIn(["clubs", "entities", "club", action.payload.clubId].concat(action.payload.updatedProperty), newImage.replace("-xl.png", "-md.png"));
  }

  return state;
}).build();