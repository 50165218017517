"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Base64Binary = exports.SanitizeItemRoot = exports.GetExportCSVHeaders = exports.GetGenericHeaders = void 0;

function GetGenericHeaders(contractVersion) {
  var clubChatHeaders = [{
    Name: "x-xbl-contract-version",
    Value: contractVersion
  }, {
    Name: "Accept-Language",
    Value: "en-US, en"
  }, {
    Name: "Accept",
    Value: "application/json"
  }, {
    Name: "Content-Type",
    Value: "application/json"
  }];
  return clubChatHeaders;
}

exports.GetGenericHeaders = GetGenericHeaders;

function GetExportCSVHeaders(contractVersion) {
  var clubChatHeaders = [{
    Name: "x-xbl-contract-version",
    Value: contractVersion
  }, {
    Name: "Accept-Language",
    Value: "en-US, en"
  }, {
    Name: "Accept",
    Value: "text/csv"
  }, {
    Name: "Content-Type",
    Value: "application/json"
  }];
  return clubChatHeaders;
}

exports.GetExportCSVHeaders = GetExportCSVHeaders;

function SanitizeItemRoot(itemRoot) {
  // strip out http:// prefix from item root
  return itemRoot.replace(/^ (\w +:\/\/)|^ (\/ +)/, "");
}

exports.SanitizeItemRoot = SanitizeItemRoot;
var HexUtils;

(function (HexUtils) {
  function hexToBase64(hexString) {
    return btoa(String.fromCharCode.apply(null, hexString.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
  }

  HexUtils.hexToBase64 = hexToBase64;

  function base64ToHex(base64String) {
    for (var i = 0, bin = atob(base64String.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);

      if (tmp.length === 1) {
        tmp = "0" + tmp;
      }

      hex[hex.length] = tmp;
    }

    return hex.join("");
  }

  HexUtils.base64ToHex = base64ToHex; // Convert a hex string to a byte array

  function hexToBytes(hex) {
    for (var bytes = [], c = 0; c < hex.length; c += 2) {
      if (c === 0 && hex.length % 2 !== 0) {
        bytes.push(parseInt(hex.substr(c, 1), 16));
        c--;
      } else {
        bytes.push(parseInt(hex.substr(c, 2), 16));
      }
    }

    return bytes;
  }

  HexUtils.hexToBytes = hexToBytes; // Convert a byte array to a hex string

  function bytesToHex(bytes) {
    for (var hex = [], i = 0; i < bytes.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hex.push((bytes[i] >>> 4).toString(16)); // tslint:disable-next-line:no-bitwise

      hex.push((bytes[i] & 0xF).toString(16));
    }

    return hex.join("");
  }
})(HexUtils || (HexUtils = {}));

var Base64Binary =
/** @class */
function () {
  function Base64Binary() {}

  Base64Binary.base64urlToBase64 = function (base64url) {
    var result = base64url.replace("+", "-").replace("/", "_");

    if (result.length % 4 !== 0) {
      var count = 4 - result.length % 4;

      for (var i = 0; i < count; i++) {
        result += "=";
      }
    }

    return result;
  };

  Base64Binary.base64ToBinary = function (input) {
    var BASE64_MARKER = ";base64,";
    var base64Index = input.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = input.substring(base64Index);
    var raw = atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  };
  /* will return a  Uint8Array type */


  Base64Binary.decodeArrayBuffer = function (input) {
    var bytes = input.length / 4 * 3;
    var ab = new ArrayBuffer(bytes);
    this.decode(input, ab);
    return ab;
  };

  Base64Binary.removePaddingChars = function (input) {
    var lkey = this._keyStr.indexOf(input.charAt(input.length - 1));

    if (lkey === 64) {
      return input.substring(0, input.length - 1);
    }

    return input;
  };

  Base64Binary.decode = function (input, arrayBuffer) {
    // get last chars to see if are valid
    input = this.removePaddingChars(input);
    input = this.removePaddingChars(input);
    var bytes = input.length / 4 * 3;
    var uarray;
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    var j = 0;

    if (arrayBuffer) {
      uarray = new Uint8Array(arrayBuffer);
    } else {
      uarray = new Uint8Array(bytes);
    }

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    for (i = 0; i < bytes; i += 3) {
      // get the 3 octects in 4 ascii chars
      enc1 = this._keyStr.indexOf(input.charAt(j++));
      enc2 = this._keyStr.indexOf(input.charAt(j++));
      enc3 = this._keyStr.indexOf(input.charAt(j++));
      enc4 = this._keyStr.indexOf(input.charAt(j++)); // tslint:disable-next-line:no-bitwise

      chr1 = enc1 << 2 | enc2 >> 4; // tslint:disable-next-line:no-bitwise

      chr2 = (enc2 & 15) << 4 | enc3 >> 2; // tslint:disable-next-line:no-bitwise

      chr3 = (enc3 & 3) << 6 | enc4;
      uarray[i] = chr1;

      if (enc3 !== 64) {
        uarray[i + 1] = chr2;
      }

      if (enc4 !== 64) {
        uarray[i + 2] = chr3;
      }
    }

    return uarray;
  };

  Base64Binary.encodeArrayBuffer = function (arrayBuffer) {
    var bytes = new Uint8Array(arrayBuffer);
    return this.encode(bytes);
  };

  Base64Binary.encode = function (bytes) {
    var base64 = "";
    var encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;
    var a, b, c, d;
    var chunk; // Main loop deals with bytes in chunks of 3

    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      // tslint:disable-next-line:no-bitwise
      chunk = bytes[i] << 16 | bytes[i + 1] << 8 | bytes[i + 2]; // Use bitmasks to extract 6-bit segments from the triplet
      // tslint:disable-next-line:no-bitwise

      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      // tslint:disable-next-line:no-bitwise

      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      // tslint:disable-next-line:no-bitwise

      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      // tslint:disable-next-line:no-bitwise

      d = chunk & 63; // 63       = 2^6 - 1
      // Convert the raw binary segments to the appropriate ASCII encoding

      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    } // Deal with the remaining bytes and padding


    if (byteRemainder === 1) {
      chunk = bytes[mainLength]; // tslint:disable-next-line:no-bitwise

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
      // Set the 4 least significant bits to zero
      // tslint:disable-next-line:no-bitwise

      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + "==";
    } else if (byteRemainder === 2) {
      // tslint:disable-next-line:no-bitwise
      chunk = bytes[mainLength] << 8 | bytes[mainLength + 1]; // tslint:disable-next-line:no-bitwise

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      // tslint:disable-next-line:no-bitwise

      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
      // Set the 2 least significant bits to zero
      // tslint:disable-next-line:no-bitwise

      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + "=";
    }

    return base64;
  };

  Base64Binary._keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  return Base64Binary;
}();

exports.Base64Binary = Base64Binary;