import { saveAs } from "file-saver";
import { injectable } from "inversify";
import { IFileSaver } from "@xbox/social-core";

@injectable()
export class WebFileSaver implements IFileSaver {
    saveAs(blob: any, name: any, noAutoBom?: any): any {
       return  saveAs( blob, name, noAutoBom);
    }
}
