"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MsrCryptoAdapter = void 0;

var msrCrypto = require("./msrcrypto.js");

var MsrCryptoAdapter =
/** @class */
function () {
  function MsrCryptoAdapter() {}

  MsrCryptoAdapter.init = function () {
    var randomBytes = new Uint8Array(48);
    window.crypto.getRandomValues(randomBytes);
    console.log(msrCrypto);
    msrCrypto.subtle.forceSync = true;
    msrCrypto.initPrng(Array.from(randomBytes));
  };

  MsrCryptoAdapter.generateKey = function (algorithm, extractable, keyUsages) {
    return msrCrypto.subtle.generateKey(algorithm, extractable, keyUsages);
  };

  MsrCryptoAdapter.exportKey = function (format, key) {
    return msrCrypto.subtle.exportKey(format, key);
  };

  MsrCryptoAdapter.importKey = function (format, keyData, algorithm, extractable, keyUsages) {
    return msrCrypto.subtle.importKey(format, keyData, algorithm, extractable, keyUsages);
  };

  MsrCryptoAdapter.sign = function (algorithm, key, data) {
    if (typeof algorithm !== "string" && key.algorithm.namedCurve) {
      // MSR crypto expects the named curve in the algorithm parameter instead of the key algorithm
      var ecKeyAlgo = key.algorithm;

      var ecAlgorithm = __assign(__assign({}, algorithm), {
        namedCurve: ecKeyAlgo.namedCurve
      });

      algorithm = ecAlgorithm;
    }

    return msrCrypto.subtle.sign(algorithm, key, data);
  };

  MsrCryptoAdapter.digest = function (algorithm, data) {
    return msrCrypto.subtle.digest(algorithm, data);
  };

  return MsrCryptoAdapter;
}();

exports.MsrCryptoAdapter = MsrCryptoAdapter;