"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var AuthActions;

(function (AuthActions) {
  // This should only be used for first time signin, if checkIfSignedIn indicates a logged out state
  AuthActions.authenticate = redux_ts_simple_1.createAction("AUTH::INIT_AUTH");
  AuthActions.authRedirectRequired = redux_ts_simple_1.createAction("AUTH::AUTH_REDIRECT_REQUIRED");
  AuthActions.authRedirectCompleted = redux_ts_simple_1.createAction("AUTH::AUTH_REDIRECT_COMPLETED");
  AuthActions.authRedirectCompletedWeb = redux_ts_simple_1.createAction("AUTH::AUTH_REDIRECT_COMPLETED_WEB");
  AuthActions.siSuRedirectCompletedWeb = redux_ts_simple_1.createAction("AUTH::SISU_REDIRECT_COMPLETED_WEB");
  AuthActions.authConnectionStateChanged = redux_ts_simple_1.createAction("AUTH::AUTH_CONNECTION_STATE_CHANGED");
  AuthActions.authCompleted = redux_ts_simple_1.createAction("AUTH::AUTH_COMPLETED");
  AuthActions.authFailed = redux_ts_simple_1.createAction("AUTH::AUTH_FAILED"); // Safely checks if we have a user in memory or storage and signs them in silently

  AuthActions.checkIfSignedIn = redux_ts_simple_1.createAction("AUTH::CHECK_SIGNED_IN");
  AuthActions.updateCurrentUser = redux_ts_simple_1.createAction("AUTH::UPDATE_CURRENT_USER");
  AuthActions.signOut = redux_ts_simple_1.createAction("AUTH::SIGN_OUT");
  AuthActions.signOutCompleted = redux_ts_simple_1.createAction("AUTH::SIGN_OUT_COMPLETED");
  AuthActions.signOutFailed = redux_ts_simple_1.createAction("AUTH::SIGN_OUT_FAILED");
})(AuthActions = exports.AuthActions || (exports.AuthActions = {}));