import * as React from "react";
import { Radio, Select } from "antd";
import { Cookies } from "react-cookie";
import { User, edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { CoreUserSettingsModalContainer, IUserSettingsModalResolver, ThemeType } from "@xbox/social-views";

import * as Style from "./UserSettingsModal.Styles";
import { withCurrentUserContext } from "../CurrentUserContext";
import { Localize } from "../../common/translation/Localize";
import { ThemedSelect, ThemedRadio } from "../../common/Generic.Styles";
import { IsDevMode, IsProdMode } from "../../common/GenericChecks";
import { GenericErrorMessage } from "../../common/GenericElements";
import { Button } from "../../common/generic/Button";
import { Gamerpic } from "../../common/generic/GamerpicListItem.Styles";
import { Glyphs } from "../../common/Generic.Icons";

const Option = Select.Option;
const RadioGroup = Radio.Group;

function getUserSettingsModal(
    showUserSettingsModal: boolean,
    closeUserSettingsModal: () => void,
    navigateWhatsNew: () => void,
    changeTheme: (e: any) => void,
    changeLanguage: (locale: string) => void,
    signOutUserModal: (signOutHandler: any) => void,
    handleSignOutUser: (cookies: Cookies) => void,
    changeDisplayMode: (e: any) => void,
    user: User,
    theme: ThemeType,
    displayMode: string,
    currentLocale: string
): JSX.Element {

    let signOutUser = function() {
        signOutUserModal(handleSignOutUser);
    };

    let goToWhatsNew = function() {
        closeUserSettingsModal();
        navigateWhatsNew();
    };

    return (
        <Style.UserSettingsModal
            visible={showUserSettingsModal}
            onCancel={closeUserSettingsModal}
        >
            {getUserInfo(user)}
            <Style.ModalBodyDiv>
                {getThemeToggle(theme, changeTheme)}
                {getLanguageSelect(currentLocale, changeLanguage)}
                {getDisplayModeToggle(displayMode, changeDisplayMode)}
                <div style={{margin: "20px 0"}}>
                    <Style.UniformPseudoLink
                        onClick={goToWhatsNew}
                    >
                        {Localize("whatsnew.link")}
                    </Style.UniformPseudoLink>
                </div>
                <div style={{margin: "15px 0 20px 0"}}>
                    <Style.UniformTrueLink
                        href="https://account.xbox.com/en-US/Settings?activetab=main%3aprivacytab"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {Localize("generic.privacyAndOnlineSafety")}
                    </Style.UniformTrueLink>
                </div>
                <Button buttonType="danger" onClick={signOutUser}><Style.LogOutIcon type={Glyphs.SignOut} />{Localize("generic.signOut")}</Button>
            </Style.ModalBodyDiv>
        </Style.UserSettingsModal>
    );
}

function getUserInfo(
    user: User
): JSX.Element {
    if (!user.profile) {
        return <Style.EmptyHeader/>;
    }
    let color = user.profile.preferredColor && user.profile.preferredColor.tertiaryColor;

    return (
        <div>
            <div
                style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: color ? "#" + color.toString() : "gray",
            }}
            >
                <Style.ModalGamerPic>
                    <Gamerpic className="Gamerpic" alt={Localize("generic.avatar_gamerpic")} src={edsImageResizer(user.profile.gamerpic, EdsImageSize._100x100)} />
                </Style.ModalGamerPic>
            </div>
            <div style={{ paddingTop: "10px", textAlign: "center"}}>
                <div style={{fontWeight: "bold"}}>
                    {user.profile.gamertag}
                </div>
            </div>
        </div>
    );
}

function getThemeToggle(
    theme: ThemeType,
    changeTheme: (e: any) => void
): JSX.Element {
    return (
        <div>
            <Style.SectionHeader>{Localize("settings.theme")}</Style.SectionHeader>
            <RadioGroup onChange={changeTheme} value={theme}>
                <ThemedRadio style={{display: "block"}} value={ThemeType.LightTheme}>{Localize("settings.lightTheme")}</ThemedRadio>
                <ThemedRadio style={{display: "block"}} value={ThemeType.DarkTheme}>{Localize("settings.darkTheme")}</ThemedRadio>
            </RadioGroup>
        </div>
    );
}

function getDisplayModeToggle(
    displayMode: string,
    changeDisplayMode: (e: any) => void
): JSX.Element | null {

    if (IsProdMode()) {
        return null;
    }

    return (
        <div>
            <Style.SectionHeader>{Localize("settings.displayMode")}</Style.SectionHeader>
            <GenericErrorMessage style={{marginLeft: "-0.5em"}} errorMessage={Localize("settings.devDisplayModeHelp")} showError={true}/>
            <RadioGroup onChange={changeDisplayMode} value={displayMode}>
                <ThemedRadio style={{display: "block"}} value={"dev"}>{Localize("settings.devDisplayMode")}</ThemedRadio>
                <ThemedRadio style={{display: "block"}} value={"prod"}>{Localize("settings.prodDisplayMode")}</ThemedRadio>
            </RadioGroup>
        </div>
    );
}

function getLanguageSelect(
    currentLocale: string,
    changeLanguage: (locale: string) => void
): JSX.Element | null {

    if (!IsDevMode()) {
        return null;
    }

    var onChange = function(selection: any) {
        changeLanguage(selection);
    };

    return (
        <div>
            <Style.SectionHeader>{Localize("settings.language")}</Style.SectionHeader>
            <ThemedSelect value={currentLocale} onChange={onChange} style={{width: "200px"}}>
                <Option value="en-US">{Localize("settings.english")}</Option>
                <Option value="ps-PS">{Localize("settings.pseudoloc")}</Option>
            </ThemedSelect>
        </div>
    );

}

const UserSettingsModalResolver: IUserSettingsModalResolver = {
    getUserSettingsModal: getUserSettingsModal
};

export default withCurrentUserContext(CoreUserSettingsModalContainer(UserSettingsModalResolver));
