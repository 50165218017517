import * as React from "react";
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import { BaseInfiniteScrollList, BaseInfiniteScrollProps } from "./base/BaseInfiniteScroller";

export default class ListWindowScroller extends BaseInfiniteScrollList {

    // Using the ref here so we can force update when the coments load
    protected readonly listRef?: React.RefObject<List>;

    constructor(props: BaseInfiniteScrollProps) {
        super(props);
        this.listRef = React.createRef();
    }

    /*********************************************************************/
    /* Component life cycle
    /*********************************************************************/
    componentDidMount() {
        // TEMPORARY FIX
        // This is a work-around to stop the issue of the cache
        // not updating and remeasuring on screen size change
        if (window) {
            window.addEventListener("resize", this.clearCache);
        }

        this.getScrollerElement();
        this.scrollToOrigin();
    }

    scrollToOrigin = () => {
        if (this.listRef && this.listRef.current) {
            this.listRef.current.scrollToRow(0);
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener("resize", this.clearCache);
        }
    }

    componentDidUpdate() {
        if (!this.loading && this.listRef && this.listRef.current) {
            this.listRef.current.forceUpdateGrid();
            this.clearCache();
        }
        this.loading = false;
    }

    /*********************************************************************/
    /* Rendering
    /*********************************************************************/
    getScrollerContent = (onRowsRendered: any): JSX.Element => {
        return (
            <WindowScroller scrollElement={this.state.mainContentWindow}>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (

                    <AutoSizer disableHeight={true}>
                        {({ width }) => (
                            <List
                                ref={this.listRef}
                                deferredMeasurementCache={this.state.cache}
                                autoHeight={true}
                                height={height}
                                width={width}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                overscanRowCount={this.props.overscanRowCount}
                                rowCount={this.props.listData.length}
                                rowHeight={this.state.cache.rowHeight}
                                rowRenderer={this.rowRenderer}
                                scrollTop={scrollTop}
                                onRowsRendered={onRowsRendered}
                                noRowsRenderer={this.noRowsRenderer}
                            />
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
    }
}
