export const DarkTheme = {
    backgroundMain: "#333",
    backgroundSecondary: "#1f1f1f",
    backgroundTertiary: "#3c3c3c",
    backgroundThemedOverlay: "rgba(255, 255, 255, 0.25)",
    backgroundBlackOverlay: "rgba(0,0,0, 0.25)",
    backgroundDisabled: "#4d4d4d",

    oppositeBackground: "#fff",
    oppositeForeground: "#000",

    borderMain: "#393939",
    borderSecondary: "#4c4c4c",
    borderTertiary: "#4c4c4c",
    labelText: "#CCC",
    grayText: "#6a6a6a",

    foregroundMain: "#888",
    foregroundSecondary: "#ccc",
    foregroundBrigth: "#fff",
    foregroundOverlayed: "rgba(255,255,255, .65)",
    foregroundDisabled: "rgba(255, 255, 255, 0.25)",

    lightHeader: "#4c4c4c",
    description: "#999999",

    grayShadeA: "#c2c2c2",
    grayShadeB: "#818c94",
    dangerBtnBackground: "#fff",
    hoverMain: "#262626",

    redRest: "#f50000", // originally "#f5222d"
    redHover: "#ff4d4f",
    btnBgColor: "#FFFFFF",
    btnOutlineColor: "2px solid #FFF",
    redActive: "#d20000",
    errorActive: "#E8978B",

    blueRest: "#1990FF",
    privacyTrueLink: "#08BBF7",
    blueHover: "#40a9ff",
    blueActive: "#0266b8",

    green: "#16a516",
    greenRest: "#33dc33",
    greenDark: "#1A801A",

    footerFrg: "#f1f1f1",
    footerBckg: "#555",

    friendLabelBckg: "#bae7ff",
    favoriteLabelBckg: "#ffadd2",
    followsLabelBckg: "#e8e8e8",

    whiteText: "#fff",
    btnTextColor: "#333",
    whiteBackground: "#fff",
    blackText: "#000",
    blackBackground: "#000",
    barBackground: "#FFF",
    activeTabColor: "#000000",
    activeTabBgColor: "#FFF",

    clubMainOverlay: "rgba(0,0,0, 0.25)",
    clubManageOverlay: "rgba(0,0,0, 0.35)",
    clubManageActiveItem: "#333333b3",
    clubMainBackground: "#1a1a1a",

    chatMessageBackground: "#ddd",
    chatMessageFallback: "#888",

    gridCardOverlayTop: "#00000099",
    gridCardOverlayTopMiddle: "#000000B3",
    gridCardOverlayBottomMiddle: "#333333B3",
    gridCardOverlayBottom: "#333333FF",
    gridContentGradientStart: "transparent",
    gridContentGradientStop: "transparent",

    popupBackground: "#1f1f1f",
    popupActiveOverlay: "#101010",
    popupBoxShadow: "#E6E6E61A",
    signOutBtn: "#FFF",
    deleteBtn: "#000",
    signOutBlackBorder:  "#FFF",
    signOutWhiteBorder: "#000000",
    activeTabBorder: "#000000"
};

/*************************************************************/
/*                  Opacity
/*  0% = #00,  5% = #0D, 10% = #1A, 15% = #26
/* 20% = #33, 25% = #40, 30% = #4D, 35% = #59
/* 40% = #66, 45% = #73, 50% = #80, 55% = #8C
/* 60% = #99, 65% = #A6, 70% = #B3, 75% = #BF
/* 80% = #CC, 85% = #D9, 90% = #E6, 95% = #F2
/*                 100% - #FF
/*************************************************************/
