"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CancellationTokenSource = void 0;

var CancellationToken_1 = require("./CancellationToken"); // CancellationTokenSource allow to cancel token, while CancellationToken should be used only to check cancellation state.


var CancellationTokenSource =
/** @class */
function () {
  function CancellationTokenSource() {
    this.token = new CancellationToken_1.CancellationToken();
  }

  CancellationTokenSource.prototype.getToken = function () {
    return this.token;
  };

  CancellationTokenSource.prototype.cancel = function () {
    this.token[Symbol.for("_cancel_")]();
  };

  return CancellationTokenSource;
}();

exports.CancellationTokenSource = CancellationTokenSource;