import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { Input, Row, Col, Button } from "antd";

import * as fts from "./FeedTools.Styles";
import { PreviewUserPostFeedItem } from "../feeditems/UserPostFeedItem";
import { PostOptionsContainer } from "./PostOptionsContainer";
import { CommonPostDetailsProps, MediaType } from "./FeedToolsTypes";
import { GenericDisplayMode, GenericLoading, GenericErrorMessage, Icon } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { ChooseMediaContainer } from "./ChooseMediaContainer/ChooseMediaContainer";
import { Glyphs } from "../../common/Generic.Icons";
import { PostTextArea } from "../PostTextArea";
import { XBLMediaChoices } from "./Utils";

interface WebLinkPostDataState {
    link: string;
    text: string;
    title: string;
    previewImage: string;
    screenshotContentId: string;
    downloadUri?: string;
    linkTitle: string;
    linkDescription: string;
    hydrationLinkData: ActivityFeed.LinkInfo | null;
    canHydrate: boolean;
    showUpdateImage: boolean;
    readyToPost: boolean;
    showHydrationError: boolean;
}

export class CreateWebLinkFeedItem extends React.Component<CommonPostDetailsProps, WebLinkPostDataState> {
    private readonly chooseMediaRef?: React.RefObject<ChooseMediaContainer>;
    constructor(props: any) {
        super(props);
        this.chooseMediaRef = React.createRef();
        this.state = {
            link: "",
            text: "",
            title: "",
            previewImage: "",
            screenshotContentId: "",
            downloadUri: "",
            linkTitle: "",
            linkDescription: "",
            hydrationLinkData: null,
            canHydrate: false,
            showUpdateImage: false,
            readyToPost: false,
            showHydrationError: false
        };
    }

    resetState = () => {
        this.setState({
            link: "",
            text: "",
            previewImage: "",
            screenshotContentId: "",
            downloadUri: "",
            linkTitle: "",
            linkDescription: "",
            hydrationLinkData: null,
            canHydrate: false,
            showUpdateImage: false,
            readyToPost: false,
            showHydrationError: false
        });
        if (this.chooseMediaRef && this.chooseMediaRef.current) {
            this.chooseMediaRef.current.resetState();
        }
    }

    componentWillReceiveProps(nextProps: CommonPostDetailsProps) {
        let status = nextProps.hydrationStates.postStatus.resultData;

        if (status && status !== this.props.hydrationStates.postStatus.resultData) {
            this.resetState();
            return;
        }

        if (nextProps.hydrationStates.screenshots !== this.props.hydrationStates.screenshots) {
            return;
        }

        let link = nextProps.hydrationStates.webLink.linkData;
        if (!link) {
            this.setState({readyToPost: false});
            return;
        }

        this.setState({showHydrationError: link.LinkType === "ValidWithNulls"});

        if (link !== this.props.hydrationStates.webLink.linkData || this.state.previewImage !== link.ImageUrl) {
            this.setState({
                showUpdateImage: !link.ImageUrl,
                previewImage: link.ImageUrl,
                linkDescription: link.Description,
                showHydrationError: link.LinkType === "ValidWithNulls",
                linkTitle: link.Title,
                hydrationLinkData: link,
                readyToPost: true
            });
        }
    }

    setMediaItem = ( locator: string, thumbUrl: string, downloadUri?: string, mediaType?: MediaType) => {
        let uri = mediaType === MediaType.Screenshot ? downloadUri : "";
        this.setState({previewImage: thumbUrl, downloadUri: uri});
    }

    setXBLScreenshotContentId = (contentID: string) => {
        this.setState({screenshotContentId: contentID});
    }

    onChangeLink = (e: any) => {
        this.setState({ link: e.target.value, canHydrate: e.target.value });
    }

    onChangeText = (e: any) => {
        this.setState({ text: e.target.value });
    }

    onChangeTitle = (e: any) => {
        this.setState({ title: e.target.value });
    }

    onDeleteImageClick = () => {
        this.setState({ showUpdateImage: true, previewImage: "", screenshotContentId: "" });
        if (this.chooseMediaRef && this.chooseMediaRef.current) {
            this.chooseMediaRef.current.resetState();
        }
    }

    onHydrateClick = () => {
        if (this.state.hydrationLinkData
            && this.state.hydrationLinkData.Link === this.state.link
            && this.state.hydrationLinkData.ImageUrl !== this.state.previewImage) {
            this.setState({
                showUpdateImage: !this.state.hydrationLinkData.ImageUrl,
                previewImage: this.state.hydrationLinkData.ImageUrl,
                screenshotContentId: "",
                linkDescription: this.state.hydrationLinkData.Description,
                linkTitle: this.state.hydrationLinkData.Title,
            });
        } else {
            this.props.handleLinkHydration(this.state.link);
        }
    }

    onPreviewItem = (isPinned: boolean, scheduledDateTime?: string) => {
        let gamerPic = "", gamerTag = "";
        if (this.props.currentUser && this.props.currentUser.profile) {
            gamerPic = this.props.currentUser.profile.gamerpic;
            gamerTag = this.props.currentUser.profile.gamertag;
        }

        return (
            <PreviewUserPostFeedItem
                pinned={isPinned}
                itemText={this.state.text}
                link={this.state.link}
                displayLink={this.state.link}
                imageUrl={this.state.previewImage}
                userGamerPicUrl={gamerPic}
                userGamerTag={gamerTag}
                selectedChannel={this.props.selectedChannel}
                scheduledDateTime={scheduledDateTime}
            />);
    }

    onPostFeedItem = (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, scheduledDateTime?: string) => {
        if (!this.props.clubId) {
            return;
        }

        let timeline: ActivityFeed.UserPostTimeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: this.props.clubId,
        };

        if (this.props.selectedChannel && !this.props.selectedChannel.isDefault) {
            timeline.channelId = `${this.props.selectedChannel.id}`;
        }

        let tls = [timeline];

        let image = this.state.downloadUri
                        ? this.state.downloadUri
                        : this.state.screenshotContentId
                            ? ""
                            : this.state.previewImage;
        let linkType = this.state.hydrationLinkData && this.state.hydrationLinkData.LinkType
                            ? this.state.hydrationLinkData.LinkType
                            : "Default";
        let linkData = this.state.hydrationLinkData && this.state.hydrationLinkData.LinkData
                            ? this.state.hydrationLinkData.LinkData
                            : undefined;

        let details: ActivityFeed.LinkPostData = {
            description: this.state.text,
            title: this.state.linkTitle ? this.state.linkTitle : this.state.title,
            image: image,
            link: this.state.link,
            displayLink: this.state.link,
            linkType: linkType,
            linkData: linkData
        };

        const xblImage = { locatorType: "GameMediaImage", locatorUri: this.state.screenshotContentId};

        let payload: ActivityFeed.UserPost = {
            postText: this.state.text,
            postType: ActivityFeed.PostType.WebLink,
            postDate: scheduledDateTime,
            timelines: tls,
            linkPostData: details,
            postContentLocators: this.state.screenshotContentId ? [xblImage] : undefined,
        };

        this.props.handlePostClick(payload, isPinned, isDisabled, isScheduled, scheduledDateTime);
    }

    onCancelPost = () => {
        this.resetState();
    }

    getLinkTitle = () => {
        if (this.state.linkTitle) {
            return (
                <fts.Header style={{marginTop: "4px"}}>
                    {this.state.linkTitle}
                </fts.Header>
            );
        }

        // let user select title if none was hydrated from url
        return (
            <div>
                {Localize("weblinkPostDetails.titleInfo")}
                <br/>
                <PostTextArea
                    onChange={this.onChangeTitle}
                    value={this.state.title}
                    placeholder={Localize("postFeedItem.titlePostPlaceholder")}
                    maxLength={30}
                    Rows={1}
                />
            </div>
        );
    }

    getHydratedElement = () => {
        return (
            <Row gutter={16}>
                <fts.DetailsColumn span={6} style={{maxWidth: "200px"}}>
                    <fts.RelativeDiv style={{display: GenericDisplayMode(!this.state.showUpdateImage)}}>
                        <fts.FitImage
                            src={this.state.previewImage}
                            alt={this.state.linkDescription}
                        />
                        <fts.DeleteImageButton iconOnly={true} onClick={this.onDeleteImageClick}>
                            <Icon type={Glyphs.Delete} />
                        </fts.DeleteImageButton>
                    </fts.RelativeDiv>
                    <div style={{display: GenericDisplayMode(this.state.showUpdateImage)}}>
                        <ChooseMediaContainer
                            fetchMediaItems={this.props.fetchMediaItems}
                            uploadMediaItems={this.props.uploadMediaItems}
                            setMediaItem={this.setMediaItem}
                            setXBLScreenshotContentId={this.setXBLScreenshotContentId}
                            getAllTitles={this.props.getAllTitles}
                            permissions={this.props.permissions}
                            hydrationStates={this.props.hydrationStates}
                            showWebLinkOption={true}
                            showItems={XBLMediaChoices.Screenshots}
                            club={this.props.club}
                            ref={this.chooseMediaRef}
                        />
                    </div>
                </fts.DetailsColumn>

                <fts.DetailsColumn span={12}>
                    {this.getLinkTitle()}
                    {Localize("weblinkPostDetails.textInfo")}
                    <br/>
                    <PostTextArea
                        onChange={this.onChangeText}
                        value={this.state.text}
                        placeholder={Localize("postFeedItem.textPostPlaceholder")}
                    />
            </fts.DetailsColumn>
            </Row>
        );
    }

    render() {
        let permissions = this.props.permissions;
        if (!permissions) {
            return <GenericLoading />;
        }

        if (!permissions.postWebLink) {
            return (
                <GenericErrorMessage
                    errorMessage={Localize("feedtools.warning", { context: "weblink" })}
                    showError={true}
                />
            );
        }

        let hydrationElement: JSX.Element;
        if (this.props.hydrationStates.webLink.inProgress) {
            hydrationElement = <GenericLoading />;
        } else if (this.state.hydrationLinkData && this.state.link) {
            hydrationElement = this.getHydratedElement();
        } else {
            hydrationElement = <div/>;
        }

        return (
            <div>
                <fts.Header>
                    {Localize("postFeedItem.postDetails")}
                </fts.Header>
                {Localize("weblinkPostDetails.weblinkHeader")}
                <Row style={{margin: "8px 0"}}>
                    <fts.DetailsColumn span={8}>
                        <Input
                            onChange={this.onChangeLink}
                            value={this.state.link}
                            placeholder={Localize("weblinkPostDetails.urlPlaceholder")}
                            onPressEnter={this.onHydrateClick}
                            aria-label="textArea"
                        />
                    </fts.DetailsColumn>
                    <Col span={3}>
                        <Button disabled={!this.state.canHydrate} onClick={this.onHydrateClick}>
                            {Localize("weblinkPostDetails.previewLink")}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        {this.state.showHydrationError && <GenericErrorMessage errorMessage={Localize("feedtools.validNullWeblink")} showError={true} />}
                    </Col>
                </Row>

                {hydrationElement}

                <PostOptionsContainer
                    onPostFeedItem={this.onPostFeedItem}
                    onCancelPost={this.onCancelPost}
                    getPreviewItem={this.onPreviewItem}
                    canPin={permissions.pinPost}
                    canSchedule={permissions.schedulePost}
                    postAbilityBlocked={this.state.link && this.state.readyToPost ? false : true}
                    forceSchedule={this.props.forceSchedule}
                />
            </div>
        );
    }
}
