import * as React from "react";
import { Menu, Dropdown, Modal } from "antd";
import { ActivityFeed, Lfg, User } from "@xbox/social-core";
import { ILfgAdminActions } from "@xbox/social-views";
import { Localize } from "../../common/translation/Localize";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";
import { withClubSelectionContext } from "../../containers/ClubSelectionContext";
import { withCurrentUserContext } from "../../containers/CurrentUserContext";

const confirm = Modal.confirm;

export interface LfgAdminActionsProps {
    LfgAdminActions?: ILfgAdminActions;
    item: Lfg.LfgItem;
    showAdminAction?: boolean;
    clubModerators?: string[];
    isModerator: boolean;
    user?: User;
}

class LfgAdminActionsContainer extends React.Component<LfgAdminActionsProps> {
    constructor(props: any) {
        super(props);
    }

    actionSelected = (e: any) => {
        if (e && e.domEvent) {
            e.domEvent.stopPropagation();
        }

        if (!this.props.LfgAdminActions) {
            return;
        }

        const shouldDeleteAndBan = (props: LfgAdminActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.deleteAndBan"),
                content: Localize("activityFeedContainer.deleteAndBanConfirmTitle"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.LfgAdminActions) {
                        props.LfgAdminActions.delete(props.item, true);
                    }
                }
            });
        };

        const shouldReport = (props: LfgAdminActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.report"),
                content: Localize("activityFeedContainer.reportConfirmText"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.LfgAdminActions) {
                        props.LfgAdminActions.report(props.item, false);
                    }
                }
            });
        };

        let action = e.key;
        switch (action) {
            case ActivityFeed.AdminActionTypes.Delete:
                this.props.LfgAdminActions.delete(this.props.item, false);
                break;
            case ActivityFeed.AdminActionTypes.DeleteAndBan:
                shouldDeleteAndBan(this.props);
                break;
            case ActivityFeed.AdminActionTypes.Report:
                shouldReport(this.props);
                break;
            default:
                return;
        }
    }

    getMenuItems = (
        isModerator: boolean,
        actionSelected: (e: any) => void,
        itemAuthorId: string,
        clubModerators?: string[]) => {

        let deleteAndBan = null;
        if (isModerator && (clubModerators && !clubModerators.some(x => x === itemAuthorId))) {
            deleteAndBan = (
                <Menu.Item key={ActivityFeed.AdminActionTypes.DeleteAndBan}>
                    {Localize("activityFeedContainer.deleteAndBan")}
                </Menu.Item>
            );
        }

        return (
            <Menu onClick={actionSelected}>
                <Menu.Item key={ActivityFeed.AdminActionTypes.Delete}>
                    {Localize("activityFeedContainer.delete")}
                </Menu.Item>
                {deleteAndBan}
                {/* Disable for now
                <Menu.Item key={ActivityFeed.AdminActionTypes.Report}>
                    {Localize("activityFeedContainer.report")}
                </Menu.Item> */}
            </Menu>
        );
    }

    render() {
        let isUserTheOwnerOfPost = this.props.user !== undefined && this.props.item.relatedInfo.sessionOwners[0] === this.props.user.xuid;

        if (!this.props.showAdminAction && (!this.props.isModerator && !isUserTheOwnerOfPost)) {
            return <div />;
        }

        return (
            <Dropdown overlay={this.getMenuItems(this.props.isModerator, this.actionSelected, this.props.item.relatedInfo.sessionOwners[0], this.props.clubModerators)} trigger={["click"]}>
                <FeedItemStyles.AdminActionsMenu className="ant-dropdown-link" href="#">
                    <Icon type={Glyphs.MoreActions} />
                </FeedItemStyles.AdminActionsMenu>
            </Dropdown>
        );
    }
}

export default withClubSelectionContext(withCurrentUserContext(LfgAdminActionsContainer));
