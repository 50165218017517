import styled from "styled-components";
import { Layout as antdLayout, Modal, Button } from "antd";
import { media } from "../common/Generic.SnapPoints";

const { Content } = antdLayout;

export const CenterContent = styled.div`
    display: flex;
    justify-content: center;
    transition: 0.5s;
`;

export const ClubMainContent = styled<{backgroundUrl: string}, "div">("div")`
    display: flex;
    justify-content: start;
    padding: 0 10px;
    width: 100%;
    transition: 0.5s;
    overflow: auto;

    background-image:
        linear-gradient(
            ${props => props.theme.clubMainOverlay} 0%,
            ${props => props.theme.clubMainOverlay} 60px,
            ${props => props.theme.clubMainBackground} 30%),
        url(${props => props.backgroundUrl});
    background-repeat: no-repeat;
    background-size: 100%;

    .ClubHeader * {
        color: ${props => props.theme.foregroundOverlayed};
    }

    .ClubHeader h1 {
        color: ${props => props.theme.foregroundBrigth};
    }

    //mobile
    ${media.small`
        padding: 0;
    `}
`;

// Add fixed widths for different screen resolutions.
export const FixedWidthContent = styled(Content)`
    margin-top: 0px;
    padding-top: 10px;
    max-width: 1370px;
    min-width: 320px;
    width: 100%;
    height: 100%;
`;

export const ContentContainer = styled(Content)`
    background: ${props => props.theme.backgroundMain};
    padding: 24px;
    border-bottom: 1px solid ${props => props.theme.borderSecondary};
    border-right: 1px solid ${props => props.theme.borderSecondary};

    //mobile
    ${media.small`
        padding: 12px;
    `}
`;

export const LfgContentContainer = ContentContainer.extend`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const PersonContainer = styled(Content)`
    background: ${props => props.theme.backgroundMain};
    padding: 24px;
`;

export const DiscussionContainerDiv = styled.div`
    flex: auto;
    display: flex;
    flex-direction: row;
`;

export const ChatChannelPanel = styled(Content)`
    background: transparent;
    margin-top: 1rem;
    margin-bottom: .5rem;

    //mobile
    ${media.small`
        padding: 0 10px;
    `}
`;

export const ActivityFeedDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MenuButton = styled(Button as any)`
    font-size: 24px !important;
    border: 0px !important;
    background: transparent !important;
    color: ${props => props.theme.foregroundSecondary} !important;
    font-weight: 800 !important;

    :focus {
      border: 1px solid #FFF !important;
      outline: 1px solid #000 !important;
    }
`;

export const ContentTitle = styled.header`
    background: ${props => props.theme.backgroundMain};
    color: ${props => props.theme.foregroundMain};
    padding: 0 10px;
    font-size: 1.5em;
    margin-bottom: 5%;
    font-weight: bold;
`;

export const EmptyStateTitle = styled.h1`
    color: ${props => props.theme.foregroundMain};
`;

export const EmptyStateSubTitle = styled.h3`
    color: ${props => props.theme.lightHeader};
`;

export const EmptyStateContainer = styled.div`
    background: ${props => props.theme.backgroundMain};
    font-size: 1.5em;
    margin-bottom: 5%;
    font-weight: bold;
    border: 1px solid ${props => props.theme.borderMain};
    border-radius: 5px;
    height: 12em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    //mobile
    ${media.small`
        font-size: 1em;
    `}
`;

export const FooterLink = styled.a`
    background-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    color: ${props => props.theme.footerFrg};
    font-size: 11px;
    line-height: 16px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    text-align: left;
    text-decoration-color: ${props => props.theme.footerFrg};
    text-decoration-line: none;
    text-decoration-style: solid;
    visibility: visible;
    margin-left: 10px;
    :focus  {
        color: ${props => props.theme.whiteText};
    }
`;

export const ThemedModal = styled(Modal)`
    /* this block is .ant-modal and located inside .ant-modal-wrap */
    /* to style overlay we need to change a color of .ant-modal-mask which is one level above */

    .ant-modal-header {
        background: ${props => props.theme.backgroundMain};
        border: none;
        border-bottom: 1px solid ${props => props.theme.borderSecondary};
        .ant-modal-title {
          color: ${props => props.theme.labelText};
        }
    }

    .ant-modal-title {
        color: ${props => props.theme.foregroundMain};
    }

    .ant-modal-close {
        color: ${props => props.theme.foregroundMain};
    }

    .ant-modal-body {
        background: ${props => props.theme.backgroundMain};
        border: none !important;

       > span {
          color: ${props => props.theme.labelText};
         }
    }
    .ant-modal-footer {
        background: ${props => props.theme.backgroundMain};
        border: none;
        border-top: 1px solid ${props => props.theme.borderMain};
    }

    /* tabs info inside Modals */
    .ant-tabs {
        padding: 15px 0;
        background: ${props => props.theme.backgroundMain};
        color: ${props => props.theme.foregroundMain};
    }

    .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        background: ${props => props.theme.backgroundMain};
        color: ${props => props.theme.foregroundSecondary};
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        border: 1px solid ${props => props.theme.activeTabBgColor};
    }

    .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        color: ${props => props.theme.blueRest};
    }

    .ant-tabs-tab-active:focus, .ant-tabs-tab-active:focus-visible {
        border: 1px dashed  ${props => props.theme.activeTabBorder} !important;
        margin-right: 1px !important;
        outline: none;
    }

    .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-nav .ant-tabs-tab:active{
        color: ${props => props.theme.foregroundSecondary};
        background: ${props => props.theme.backgroundMain};
    }

    /* default text colors */
    h1, h2, h3, h4 {
        color: ${props => props.theme.foregroundOverlayed};
    }

    p, div {
        color: ${props => props.theme.foregroundSecondary};
    }

    /* pagination "..." symbol */
    .ant-pagination-jump-prev:after, .ant-pagination-jump-next:after {
        color: ${props => props.theme.foregroundSecondary};
    }

    //mobile
    ${media.small`
        .ant-modal-content {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    `}
`;

export const ModalNoFooter = ThemedModal.extend`
    .ant-modal-footer {
        display: none;
    }
`;

export const GenericModal = ThemedModal.extend`
    vertical-align: top;
    max-width: 960px;
    min-width: 320px;
    width: 100% !important;
    overflow: auto;
    position: unset !important;

    ${media.menuOpenSmall`margin: 0px !important;`}
    ${media.small`margin: 0px !important;`}

    .ant-modal-content {
        margin: auto;
    }

    .ant-modal-body {
        padding: 0px 24px 12px 24px;
    }

    //mobile
    ${media.small`
        .ant-modal-content {
            width: 100%;
            height: 100%;
            margin: 0;
        }
        .ant-modal-body {
            padding: 0px 12px 12px 12px;
        }
    `}
`;

export const PseudoLink = styled.span`
    color: blue;
    cursor: pointer;
    text-decoration: underline;
`;

export const AddChannelButton = styled(Button as any) `
    margin-top: 2rem;
    font-size: initial;
`;
