"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aggregatedClubReducer = void 0;

var redux_1 = require("redux");

var ClubActivityReducer_1 = require("./ClubActivityReducer");

var ClubListReducer_1 = require("./ClubListReducer");

var ClubDetailsReducer_1 = require("./ClubDetailsReducer");

var ClubModerationReducer_1 = require("./ClubModerationReducer");

var AnalyticsReducer_1 = require("../analytics/AnalyticsReducer"); // Club State Area


exports.aggregatedClubReducer = redux_1.combineReducers({
  clubAnalytics: AnalyticsReducer_1.clubAnalyticsReducer,
  clubList: ClubListReducer_1.clubViewerReducer,
  clubActivityFeed: ClubActivityReducer_1.clubActivityReducer,
  clubDetails: ClubDetailsReducer_1.clubDetailsReducer,
  clubModerationReducer: ClubModerationReducer_1.clubModerationReducer
});