import { injectable, inject } from "inversify";
import { AppInsights } from "applicationinsights-js";
import {
    ITelemetryService,
    TelemetryData,
    XSocialSymbols,
    INotificationsService
} from "@xbox/social-core";
const InstrumentationKey = "6bea1f14-689b-45c1-8ced-9da5e9a7e029";

@injectable()
export class AppInsightsTelemetryService implements ITelemetryService {
    private userId: string = "";

    // This is for DMA Compliance. Please refer to work item #43699121 for more information.
    private isUploadEnabled = false;

    constructor(@inject(XSocialSymbols.INotificationsService) private notificationService: INotificationsService) {
        if (this.isUploadEnabled) {
            this.setup();
        }
    }

    setup() {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined") {
            return;
        }

        AppInsights.flush();
        if (AppInsights.downloadAndSetup !== undefined) {
            AppInsights.downloadAndSetup({ instrumentationKey: InstrumentationKey });
        }
    }

    trackPageView(name: string, data?: TelemetryData) {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined" || !this.isUploadEnabled) {
            return;
        }

        data = this.injectDefaults(data);
        AppInsights.trackPageView(name, data);
    }

    trackEvent(name: string, data?: TelemetryData, measurements?: TelemetryData) {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined" || !this.isUploadEnabled) {
            return;
        }

        data = this.injectDefaults(data);
        AppInsights.trackEvent(name, data);
    }

    trackException(exception: any, name: string, data?: TelemetryData, notifyException?: boolean) {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined" || !this.isUploadEnabled) {
            return;
        }

        AppInsights.trackException(exception, data);
        // Leave this open for other exception models to insert their error message as needed
        if (notifyException) {
            let errorCode = exception.response ? exception.response.status.toString() : "000";
            let errorMessage = exception.response ? exception.response.statusText : "";
            this.notificationService.notifyErrorPayload(name, {codePayload: errorCode, textPayload: errorMessage});
        }
    }

    trackMetric(name: string, data?: TelemetryData) {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined" || !this.isUploadEnabled) {
            return;
        }

        data = this.injectDefaults(data);
        AppInsights.trackMetric(name, data);
    }

    trackLoadTime(name: string, startTime: number) {
        this.trackMetric(name, { "loadTime": ((Date.now() - startTime) / 1000).toString() });
    }

    setAuthenticatedUserContext(xuid: string) {
        this.userId = xuid;
    }

    getContext() {
        // TODO: temporarily disable app insights on server side render
        if (typeof window === "undefined") {
            return;
        }

        return AppInsights.context;
    }

    private injectDefaults(data?: TelemetryData) {
        if (!data) {
            data = { };
        }

        data["UserId"] = this.userId;
        data["Environment"] = process.env.REACT_APP_XBL_CONFIG;
        return data;
    }
}
