"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpRequestServiceBuilder = void 0;

var axios_1 = require("axios");

var axiosRetry = require("axios-retry");

var axios_retry_1 = require("axios-retry");

var HttpRequestServiceProxy =
/** @class */
function () {
  function HttpRequestServiceProxy(authenticator, interceptors, postAuthInterceptors, httpClient) {
    this.authenticator = authenticator;
    this.interceptors = interceptors;
    this.postAuthInterceptors = postAuthInterceptors;
    this.httpClient = httpClient;
  } // To disable status validation, pass null. To use default status validation don't pass a value for validateStatus or pass undefined.


  HttpRequestServiceProxy.prototype.sendRequest = function (request, validateStatus) {
    return __awaiter(this, void 0, void 0, function () {
      var _i, _a, interceptor, _b, _c, interceptor, headers, axiosHeaders, requestConfig;

      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _i = 0, _a = this.interceptors;
            _d.label = 1;

          case 1:
            if (!(_i < _a.length)) return [3
            /*break*/
            , 4];
            interceptor = _a[_i];
            return [4
            /*yield*/
            , interceptor.intercept(request)];

          case 2:
            request = _d.sent();
            _d.label = 3;

          case 3:
            _i++;
            return [3
            /*break*/
            , 1];

          case 4:
            if (!this.authenticator) return [3
            /*break*/
            , 6];
            return [4
            /*yield*/
            , this.authenticator.authenticate(request)];

          case 5:
            request = _d.sent();
            _d.label = 6;

          case 6:
            _b = 0, _c = this.postAuthInterceptors;
            _d.label = 7;

          case 7:
            if (!(_b < _c.length)) return [3
            /*break*/
            , 10];
            interceptor = _c[_b];
            return [4
            /*yield*/
            , interceptor.intercept(request)];

          case 8:
            request = _d.sent();
            _d.label = 9;

          case 9:
            _b++;
            return [3
            /*break*/
            , 7];

          case 10:
            headers = request.headers ? request.headers : [];
            axiosHeaders = {};
            headers.forEach(function (h) {
              axiosHeaders[h.Name] = h.Value;
            });
            requestConfig = {
              headers: axiosHeaders,
              method: request.method,
              url: request.url,
              data: request.data
            };

            if (validateStatus || validateStatus === null) {
              requestConfig = __assign(__assign({}, requestConfig), {
                // Axios expects undefined if we don't want any validation
                validateStatus: validateStatus === null ? undefined : validateStatus
              });
            }

            return [2
            /*return*/
            , this.httpClient.request(requestConfig)];
        }
      });
    });
  };

  return HttpRequestServiceProxy;
}();

var HttpRequestServiceBuilder =
/** @class */
function () {
  function HttpRequestServiceBuilder() {
    this.interceptors = [];
    this.postAuthInterceptors = [];
  }

  HttpRequestServiceBuilder.prototype.addInterceptor = function (interceptor, afterAuthenticator) {
    if (afterAuthenticator) {
      this.postAuthInterceptors.push(interceptor);
    } else {
      this.interceptors.push(interceptor);
    }

    return this;
  };

  HttpRequestServiceBuilder.prototype.addAuthenticator = function (authenticator) {
    this.authenticator = authenticator;
    return this;
  };

  HttpRequestServiceBuilder.prototype.build = function () {
    var httpClient = axios_1.default.create();
    axiosRetry(httpClient, {
      retryDelay: axios_retry_1.exponentialDelay,
      retryCondition: function retryCondition(error) {
        return axios_retry_1.isNetworkOrIdempotentRequestError(error) || !error.response || error.response.status >= 500 && error.response.status <= 599;
      }
    });
    return new HttpRequestServiceProxy(this.authenticator, this.interceptors, this.postAuthInterceptors, httpClient);
  };

  return HttpRequestServiceBuilder;
}();

exports.HttpRequestServiceBuilder = HttpRequestServiceBuilder;