import * as React from "react";
import { ClubHub, edsImageResizer, EdsImageSize, ImageSources, UpdateImageTypes, SystemTag } from "@xbox/social-core";
import { Tooltip, Popover } from "antd";
import { IClubProfileResolver, CoreClubProfileContainer, ICustomizeClubHandlers, ICoreClubProfileEditFields } from "@xbox/social-views";
import * as Styles from "./ClubProfile.Styles";
import * as GlobalStyles from "../Styles";
import * as ColorPickerStyles from "../../components/common/ColorPicker.Styles";
import { Localize } from "../../common/translation/Localize";
import { withClubSelectionContext } from "../ClubSelectionContext";
import ImagePickerContainer from "../imagepicker/ImagePickerContainer";
import ColorPicker from "../../components/common/ColorPicker";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon, GenericEmptyState } from "../../common/GenericElements";
import { Divider } from "../../common/generic/Divider";
import { withCurrentUserContext } from "../CurrentUserContext";
import TagPickerContainer from "../tagpicker/TagPickerContainer";
import { GamerpicListItem } from "../../common/generic/GamerpicListItem";
import Util from "../../common/Util";

function getStatContainer(stat: string, value: number) {
    return (
        <Styles.StatContainer>
            <Styles.Stat>{stat}</Styles.Stat>
            <Styles.StatValue>{value}</Styles.StatValue>
        </Styles.StatContainer>
    );
}

function getClubProfile(
    club: ClubHub.Club,
    fields: ICoreClubProfileEditFields,
    role: ClubHub.ClubRoles,
    isEditing: boolean,
    handlers: ICustomizeClubHandlers,
    showImagePicker: boolean,
    updateImageType?: UpdateImageTypes,
    combinedTags?: SystemTag[]
) {
    let description =
        (
            <Styles.SubTitle style={{ marginLeft: 0 }}>
                {fields.description}
            </Styles.SubTitle>
        );

    let clubName =
        (
            <Styles.Title>
                {club.details.profile.name.value}
            </Styles.Title>
        );

    if (isEditing) {
        description = (
            <Styles.TextAreaInput value={fields.description} onChange={handlers.updateDescription} />
        );

        clubName = (
            <Styles.InputBox value={fields.name} onChange={handlers.updateName} />
        );
    }

    let editButton = null;
    if (role === ClubHub.ClubRoles.Owner) {
        editButton = (
            <div style={{ position: "absolute", right: 0 }}>
                { isEditing
                    ? <Styles.EditButton onClick={() => handlers.editProfile(false)} type="primary"><Icon type={Glyphs.Save} style={{ verticalAlign: "middle" }} /> {Localize("generic.save")}</Styles.EditButton>
                    : <Styles.EditButton onClick={() => handlers.editProfile(true)}><Icon type={Glyphs.Edit} style={{ verticalAlign: "middle" }} /> {Localize("generic.edit")}</Styles.EditButton>
                }
            </div>
        );
    }

    let imagePicker = (
        <GlobalStyles.ModalNoFooter
            title={Localize("imagePicker.title")}
            visible={showImagePicker}
            width={960}
            onOk={() => handlers.toggleImagePicker(undefined)}
            okText={Localize("generic.submit")}
            onCancel={() => handlers.toggleImagePicker(undefined)}
        >
            <ImagePickerContainer
                allowedSources={ImageSources.All}
                updateImageType={updateImageType}
                isVisible={showImagePicker}
                hidePicker={handlers.toggleImagePicker}
            />
        </GlobalStyles.ModalNoFooter>
    );

    function getClubBackgroundDisplay() {
        if (club.details.profile.backgroundImageUrl.value) {
            return <Styles.Background src={edsImageResizer(Util.ensureSSLImage(club.details.profile.backgroundImageUrl.value), EdsImageSize._1280x720)} />;
        }
        return <GenericEmptyState message={Localize("clubDetails.noBackground")} />;
    }

    let colorPicker = <ColorPicker onSelect={handlers.updateColor} />;
    const imageURL2 = club.details.profile.displayImageUrl.value.replace(/http.*url=/, "https://images-eds-ssl.xboxlive.com/image?url=");

    return (
        <GlobalStyles.ContentContainer>
            <div style={{position: "relative"}}>
                <Styles.AvatarContainer disabled={!isEditing} onClick={() => handlers.toggleImagePicker(UpdateImageTypes.profile)}>
                    <Styles.AvatarEditIcon type={Glyphs.Edit} style={{ display: isEditing ? "block" : "none" }} />
                    <Styles.Avatar src={edsImageResizer(imageURL2, EdsImageSize._336x336)} />
                </Styles.AvatarContainer>
                <Styles.InfoContainer>
                    {clubName}
                    {club.details.owner &&
                        <Styles.GamerPicContainer>
                            <GamerpicListItem
                                person={club.details.owner}
                                itemShortDescription={`(${Localize("clubRoster.owner")})`}
                            />
                        </Styles.GamerPicContainer>}
                    <div>
                        {getStatContainer(Localize("clubDetails.hereNow"), club.details.clubPresenceCount)}
                        {club.details.isOfficialClub !== true && getStatContainer(Localize("clubDetails.members"), club.details.membersCount)}
                        {club.details.isOfficialClub !== true && getStatContainer(Localize("clubDetails.followers"), club.details.followersCount)}
                    </div>
                </Styles.InfoContainer>
                <Popover placement="bottom" content={colorPicker} trigger="click">
                    <ColorPickerStyles.EditButton style={{ display: isEditing ? "" : "none" }}>
                        <div>
                            <Icon style={{ verticalAlign: "baseline" }} type={Glyphs.Edit} />
                            <ColorPickerStyles.Color
                                style={{ background: "#" + fields.color.primaryColor }}
                            />
                        </div>
                    </ColorPickerStyles.EditButton>
                </Popover>
                {editButton}
            </div>
            <Divider />
            <div>
                <Styles.SubTitle style={{ marginLeft: 0, fontWeight: "bold" }}>
                    {Localize("clubDetails.aboutTitle")}
                </Styles.SubTitle>
                <br />
                {description}
            </div>
            <Divider />
            <div>
                <Styles.SubTitle style={{ marginLeft: 0, marginBottom: 5, fontWeight: "bold" }}>
                    {Localize("clubDetails.associatedTitles")}
                </Styles.SubTitle>
                <br />
                {club.details.profile.associatedTitles.value.map(
                    t => t.displayImage && (
                        <Tooltip placement="top" key={t.titleId} title={t.name}>
                            <Styles.TitleImage alt={t.name} src={edsImageResizer(t.displayImage, EdsImageSize._100x100)} />
                        </Tooltip>
                    )
                )}
            </div>
            <Divider />
            <div>
                <Styles.SubTitle style={{ marginLeft: 0, marginBottom: 5, fontWeight: "bold" }}>
                    {Localize("clubDetails.tags")}
                </Styles.SubTitle>
                <br />
                <TagPickerContainer isEditible={isEditing} submitTags={handlers.submitTags} />
            </div>
            <Divider />
            <div>
                <Styles.SubTitle style={{ marginLeft: 0, fontWeight: "bold" }}>
                    {Localize("clubDetails.background")}
                </Styles.SubTitle>
                <br />
                <Styles.BackgroundContainer disabled={!isEditing} onClick={() => handlers.toggleImagePicker(UpdateImageTypes.background)}>
                    <Styles.AvatarEditIcon type={Glyphs.Edit} style={{ display: isEditing ? "block" : "none", top: 5, bottom: "auto" }} />
                    {getClubBackgroundDisplay()}
                </Styles.BackgroundContainer>
            </div>
            {imagePicker}
        </GlobalStyles.ContentContainer>
    );
}

const ClubProfileResolver: IClubProfileResolver = {
    getClubProfile: getClubProfile
};

export default withClubSelectionContext(withCurrentUserContext(CoreClubProfileContainer(ClubProfileResolver)));
