import * as React from "react";
import { List, AutoSizer } from "react-virtualized";
import { BaseInfiniteScrollList, BaseInfiniteScrollProps } from "./base/BaseInfiniteScroller";

export default class ListStrictScroller extends BaseInfiniteScrollList {

    // Using the ref here so we can force update when the coments load
    protected readonly listRef?: React.RefObject<List>;

    constructor(props: BaseInfiniteScrollProps) {
        super(props);
        this.listRef = React.createRef();
    }

    scrollToOrigin = () => {
        if (this.listRef && this.listRef.current) {
            this.listRef.current.scrollToRow(0);
        }
    }

    /*********************************************************************/
    /* Component life cycle
    /*********************************************************************/
    componentDidMount() {
        // TEMPORARY FIX
        // This is a work-around to stop the issue of the cache
        // not updating and remeasuring on screen size change
        if (window) {
            window.addEventListener("resize", this.clearCache);
        }

        this.scrollToOrigin();
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener("resize", this.clearCache);
        }
    }

    componentDidUpdate(prevProps: BaseInfiniteScrollProps) {
        if (!this.loading && this.listRef && this.listRef.current) {
            this.listRef.current.forceUpdateGrid();
        }

        this.loading = false;
    }

    /*********************************************************************/
    /* Rendering
    /*********************************************************************/
    getScrollerContent = (onRowsRendered: any): JSX.Element => {
        return (
            <div style={{ flex: "1 1 auto", height: "100%", maxHeight: "95%"}}>
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            ref={this.listRef}
                            height={height}
                            width={width}
                            rowCount={this.props.listData.length}
                            rowHeight={this.state.cache.rowHeight}
                            rowRenderer={this.rowRenderer}
                            overscanRowCount={this.props.overscanRowCount}
                            noRowsRenderer={this.noRowsRenderer}
                            onRowsRendered={onRowsRendered}
                            scrollToIndex={this.props.appliedTo === "comment" ? Number.MAX_SAFE_INTEGER : undefined}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}
