"use strict";

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RootSaga = void 0;

require("regenerator-runtime/runtime");

var effects_1 = require("redux-saga/effects");

var AuthSaga_1 = require("./AuthSaga");

var PartySaga_1 = require("./PartySaga");

var PeopleSaga_1 = require("./PeopleSaga");

var ClubAdminSagas_1 = require("./ClubAdminSagas");

var ClubHubsSagas_1 = require("./ClubHubsSagas");

var FeedViewerSagas_1 = require("./FeedViewerSagas");

var TitleHubSagas_1 = require("./TitleHubSagas");

var ExperimentsSagas_1 = require("./ExperimentsSagas");

var ClubAnalyticsSagas_1 = require("./ClubAnalyticsSagas");

var MediaHubSagas_1 = require("./MediaHubSagas");

var CommentsSaga_1 = require("./CommentsSaga");

var FeedbackSagas_1 = require("./FeedbackSagas");

var ImagePickerSagas_1 = require("./common/ImagePickerSagas");

var TagPickerSagas_1 = require("./common/TagPickerSagas");

var PrivacySagas_1 = require("./PrivacySagas");

var LfgSagas_1 = require("./LfgSagas");

var SocialSaga_1 = require("./SocialSaga");

var RootSaga =
/** @class */
function () {
  function RootSaga(container) {
    this.commentsSaga = container.resolve(CommentsSaga_1.CommentsSaga);
    this.peopleSaga = container.resolve(PeopleSaga_1.PeopleSaga);
    this.partySaga = container.resolve(PartySaga_1.PartySaga);
    this.authSaga = container.resolve(AuthSaga_1.AuthSaga);
    this.clubHubSaga = container.resolve(ClubHubsSagas_1.ClubHubSaga);
    this.feedViewerSaga = container.resolve(FeedViewerSagas_1.FeedViewerSaga);
    this.titleHubSaga = container.resolve(TitleHubSagas_1.TitleHubSaga);
    this.experimentsSaga = container.resolve(ExperimentsSagas_1.ExperimentsSaga);
    this.clubAdminSaga = container.resolve(ClubAdminSagas_1.ClubAdminSaga);
    this.clubAnalyticsSaga = container.resolve(ClubAnalyticsSagas_1.ClubAnalyticsSaga);
    this.mediaHubSaga = container.resolve(MediaHubSagas_1.MediaHubSaga);
    this.feedbackSaga = container.resolve(FeedbackSagas_1.FeedbackSaga);
    this.imagePickerSaga = container.resolve(ImagePickerSagas_1.ImagePickerSaga);
    this.tagPickerSaga = container.resolve(TagPickerSagas_1.TagPickerSaga);
    this.privacySaga = container.resolve(PrivacySagas_1.PrivacySaga);
    this.lfgSaga = container.resolve(LfgSagas_1.LfgSaga);
    this.socialSaga = container.resolve(SocialSaga_1.SocialSaga);
    this.sagas = this.sagas.bind(this);
  }

  RootSaga.prototype.sagas = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.all(__spreadArrays(this.authSaga.sagas, this.partySaga.sagas, this.peopleSaga.sagas, this.clubHubSaga.sagas, this.feedViewerSaga.sagas, this.titleHubSaga.sagas, this.experimentsSaga.sagas, this.clubAdminSaga.sagas, this.clubAnalyticsSaga.sagas, this.mediaHubSaga.sagas, this.commentsSaga.sagas, this.feedbackSaga.sagas, this.imagePickerSaga.sagas, this.tagPickerSaga.sagas, this.privacySaga.sagas, this.socialSaga.sagas, this.lfgSaga.sagas))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  return RootSaga;
}();

exports.RootSaga = RootSaga;