"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lfgReducer = void 0;

var _ = require("lodash");

var redux_ts_simple_1 = require("redux-ts-simple");

var ImmutableObj = require("seamless-immutable");

var LfgActions_1 = require("../../actions/LfgActions"); // reducer


var INITIAL_STATE = ImmutableObj({
  inProgress: false
});
exports.lfgReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(LfgActions_1.LfgActions.getLfgsInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(LfgActions_1.LfgActions.getLfgsCompleted, function (state, action) {
  return state.merge({
    lfg: action.payload
  });
}).on(LfgActions_1.LfgActions.lfgItemDeleted, function (state, action) {
  if (!state || !state.lfg) {
    return state;
  }

  var lfgs = _.cloneDeep(state.lfg);

  lfgs.splice(lfgs.findIndex(function (l) {
    return l.id === action.payload;
  }), 1);
  return state.setIn(["lfg"], lfgs);
}).on(LfgActions_1.LfgActions.resetLfgs, function (state, action) {
  return state.setIn(["lfg"], undefined);
}).build();