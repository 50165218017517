import * as React from "react";
import { Tooltip } from "antd";
import { ClubHub, PostStatus } from "@xbox/social-core";

import * as Styles from "./FeedItem.Styles";
import { Popup, buildMenuItem } from "../../../common/generic/Popup";
import { FeedViewType } from "../../infiniteScrollers/base/InfiniteScrollTypes";
import { Glyphs } from "../../../common/Generic.Icons";
import { Icon, GenericDisplayMode } from "../../../common/GenericElements";
import { Localize } from "../../../common/translation/Localize";
import { ModalNoFooter } from "../../../containers/Styles";
import FeedToolsContainer from "../../../containers/activityfeed/FeedToolsContainer";

const FeedViews = [FeedViewType.CompactView, FeedViewType.FullView, FeedViewType.GridView];

const getViewGlyph = (viewType: FeedViewType | undefined): Glyphs => {
    switch (viewType) {
        case FeedViewType.FullView:
            return Glyphs.Related;

        case FeedViewType.GridView:
            return Glyphs.GridViewSmall;

        case FeedViewType.CompactView:
        default:
            return Glyphs.ListView;
    }
};

const getDropdownButton = (isOpen: boolean, displayElement: JSX.Element) => {
    return (
        <Tooltip placement="topLeft" title={Localize(`generic.viewOptions`)} arrowPointAtCenter={true}>
            <Styles.HeaderButton aria-expanded={isOpen ? "true" : "false"}>
                {displayElement}
                <Styles.DropdownIcon type={isOpen ? Glyphs.ChevronUp : Glyphs.ChevronDown}/>
            </Styles.HeaderButton>
        </Tooltip>
    );
};

const ViewSwitchButton = (props: ActivityFeedHeaderButtonsProps): JSX.Element | null => {
    if (!props.setFeedViewType) {
        return null;
    }

    let menuOptions = [];
    for (var i = 0; i < FeedViews.length; i++) {
        let type = FeedViews[i];
        let element = (
            <span style={{height: "100%"}}><Icon type={getViewGlyph(type)}/>{Localize(`generic.${type}`)}</span>
        );
        menuOptions.push(buildMenuItem(props.setFeedViewType, type, type === props.feedViewType, element, i));
    }

    let buttonElement = (active: boolean) => {
        return getDropdownButton(active, <Icon type={getViewGlyph(props.feedViewType)} />);
    };

    return <Popup type={"click"} stateBasedSeedElement={buttonElement} popupMenuItems={menuOptions} />;
};

const ChannelSwitchButton = (props: ScheduledFeedHeaderButtonsProps): JSX.Element | null => {

    if (!props.selectedChannel || !props.channelList) {
        return null;
    }

    let menuOptions = [];
    for (var i = 0; i < props.channelList.length; i++) {
        let val = props.channelList[i];
        let element = (
            <span style={{height: "100%"}}>{val.name}</span>
        );
        menuOptions.push(buildMenuItem(() => props.channelSelect(val) , val, val.id.toString() === props.selectedChannel.id, element, i));
    }

    let buttonElement = (active: boolean) => {
        return getDropdownButton(active, <Icon type={Glyphs.AppChannels} />);
    };

    return <Popup type={"click"} stateBasedSeedElement={buttonElement} popupMenuItems={menuOptions} />;
};

const CreatePostButton = (props: ActivityFeedHeaderButtonsProps): JSX.Element|null => {
    if (!props.openCreatePostModal) {
        return null;
    }
    let enabled: boolean = props.selectedChannel && props.selectedChannel.settings.post && props.selectedChannel.settings.post.canViewerAct
                            ? props.selectedChannel.settings.post.canViewerAct
                            : false;

    let toolTipText: string = enabled ? Localize("feedtools.createPost") : "";

    return (
        <Tooltip placement="topLeft" title={toolTipText} arrowPointAtCenter={true}>
            <Styles.FullSizeButton disabled={!enabled} onClick={props.openCreatePostModal}>
                <Icon type={Glyphs.SubscriptionAdd} />
                <span style={{verticalAlign: "top"}}>{Localize("feedtools.createPost")}</span>
            </Styles.FullSizeButton>
        </Tooltip>
    );
};

const CreateScheduledPostButton = (props: ScheduledFeedHeaderButtonsProps): JSX.Element|null => {
    if (!props.openCreatePostModal) {
        return null;
    }
    let enabled: boolean = props.selectedChannel && props.selectedChannel.settings.post && props.selectedChannel.settings.post.canViewerAct
                            ? props.selectedChannel.settings.post.canViewerAct
                            : false;

    return (
        <Tooltip placement="topLeft" title={Localize("feedtools.createPost")} arrowPointAtCenter={true}>
            <Styles.FullSizeButton disabled={!enabled} onClick={props.openCreatePostModal}>
                <Icon type={Glyphs.SubscriptionAdd} />
                <span style={{verticalAlign: "top"}}>{Localize("postFeedItem.schedulePost")}</span>
            </Styles.FullSizeButton>
        </Tooltip>
    );
};

const AllPollsButton = (props: ActivityFeedHeaderButtonsProps) => {
    if (!props.switchToFeedTools) {
        return null;
    }

    return (
        <Tooltip placement="topLeft" title={Localize("feedtools.allPolls")} arrowPointAtCenter={true}>
            <Styles.HeaderButton role="link" aria-label={Localize("feedtools.allPolls")} onClick={() => props.switchToFeedTools!("allpolls/open")} style={{ display: GenericDisplayMode(props.isModerator) }}>
                <Icon type={Glyphs.Poll} />
            </Styles.HeaderButton>
        </Tooltip>
    );
};

const ScheduledPostButton = (props: ActivityFeedHeaderButtonsProps) => {
    if (!props.switchToFeedTools) {
        return null;
    }

    return (
        <Tooltip placement="topLeft" title={Localize("feedtools.scheduledPosts")} arrowPointAtCenter={true}>
            <Styles.HeaderButton role="link" aria-label={Localize("feedtools.scheduledPosts")} onClick={() => props.switchToFeedTools!("scheduled")} style={{ display: GenericDisplayMode(props.isModerator) }}>
                <Icon type={Glyphs.NewReleases} />
            </Styles.HeaderButton>
        </Tooltip>
    );
};

const RefreshButton = (props: ActivityFeedHeaderButtonsProps) => {

    const style = Object.assign(props.style, { display: GenericDisplayMode(props.showRefresh) });
    return (
        <Tooltip placement="topLeft" title={Localize("generic.refresh")} arrowPointAtCenter={true}>
            <Styles.HeaderButton onClick={props.onRefreshClick} style={style}>
            <Icon type={Glyphs.RepeatRefresh} />
            </Styles.HeaderButton>
        </Tooltip>
    );
};

interface CommonFeedHeaderButtonsProps {
    openCreatePostModal?: () => void;
    isModerator: boolean;
    selectedChannel?: ClubHub.Channel;
    onRefreshClick?: any;
    showRefresh?: boolean;
    postStatus?: PostStatus.PostStatusResult;
    style?: React.CSSProperties;
}

interface ActivityFeedHeaderButtonsProps extends CommonFeedHeaderButtonsProps {
    feedViewType?: FeedViewType;
    setFeedViewType?: (type: FeedViewType) => void;
    switchToFeedTools?: (subpage: string) => void;
}

interface ScheduledFeedHeaderButtonsProps extends CommonFeedHeaderButtonsProps {
    channelList?: ClubHub.Channel[];
    channelSelect: (channel: ClubHub.Channel) => void;
}

interface FeedHeaderButtonsState {
    showPostModal: boolean;
}

export class ActivityFeedHeaderButtons extends React.Component<ActivityFeedHeaderButtonsProps, FeedHeaderButtonsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showPostModal: false
        };
    }
    componentDidUpdate(prevProps: ActivityFeedHeaderButtonsProps) {
        if (this.props.postStatus !== prevProps.postStatus) {
            this.closePostModal();
        }
    }

    openPostModal = () => {
        this.setState({showPostModal: true});
    }

    closePostModal = () => {
        this.setState({showPostModal: false});
    }
    render() {
        return (
            <div>
                <ModalNoFooter
                    visible={this.state.showPostModal}
                    onCancel={this.closePostModal}
                    title={
                        <div role="heading" aria-level={2}>
                            {Localize("feedtools.createPost")}
                        </div>}
                >
                    <FeedToolsContainer/>
                </ModalNoFooter>
                <Styles.HeaderButtonsContainer className="ActivityFeedHeaderButtons">
                    <ViewSwitchButton {...this.props} />
                    <CreatePostButton {...this.props} openCreatePostModal={this.openPostModal}/>
                    <ScheduledPostButton {...this.props}/>
                    <AllPollsButton {...this.props} />
                    <RefreshButton {...this.props} style={{marginRight: 0}}/>
                </Styles.HeaderButtonsContainer>
            </div>
        );
    }
}

export class ScheduledFeedHeaderButtons extends React.Component<ScheduledFeedHeaderButtonsProps, FeedHeaderButtonsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showPostModal: false
        };
    }

    componentDidUpdate(prevProps: ScheduledFeedHeaderButtonsProps) {
        if ( this.props.postStatus !== prevProps.postStatus) {
            this.closePostModal();
        }
    }

    openPostModal = () => {
        this.setState({showPostModal: true});
    }

    closePostModal = () => {
        this.setState({showPostModal: false});
    }

    render() {
        return (
            <div>
                <ModalNoFooter
                    visible={this.state.showPostModal}
                    onCancel={this.closePostModal}
                    title={
                        <div role="heading" aria-level={2}>
                            {Localize("feedtools.createPost")}
                        </div>}
                >
                    <FeedToolsContainer forceSchedule={true}/>
                </ModalNoFooter>
                <Styles.HeaderButtonsContainer className="ScheduledFeedHeaderButtons">
                    <ChannelSwitchButton  {...this.props} />
                    <CreateScheduledPostButton {...this.props} openCreatePostModal={this.openPostModal}/>
                    <RefreshButton {...this.props} style={{marginRight: 0}}/>
                </Styles.HeaderButtonsContainer>
            </div>
        );
    }
}
