import styled from "styled-components";
import { media } from "../../common/Generic.SnapPoints";
import Layout from "./Layout";

/*--------------------*/
/*-      Header      -*/
/*--------------------*/
/*   XblMainContent   */
/*        |           */
/*  Menu  |  Content  */
/*        |           */
/*--------------------*/
/*-      Footer      -*/
/*--------------------*/

export const HeaderDiv = styled.header`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    height: 53px;
    padding: 10px 16px;

    background: ${props => props.theme.backgroundSecondary};
    color: ${props => props.theme.foregroundSecondary};
    border-bottom: 1px ${props => props.theme.borderSecondary} solid;
`;

export const XblFooter = styled.footer`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 36px;
    padding: 10px 16px;

    background: ${props => props.theme.footerBckg};
    color: ${props => props.theme.footerFrg};

    a:not([href]):not([tabindex]) {
        color: ${props => props.theme.footerFrg};
        text-decoration: none;
    }
`;

export const SideDiv = styled.div`
    position: absolute;
    height: 100%;
    z-index: 10;
    width: 0; /* 0 width - change this with JavaScript */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

    background-color: ${props => props.theme.backgroundMain};
    border-right: 1px solid ${props => props.theme.borderSecondary};

    .slick-dots {
        bottom: unset;
        top: 0;
        height: 30px;
    }

    .slick-list {
        margin-top: 30px;
    }
`;

export const XblMainContent = styled.main`
    position: absolute;
    width: 100%;
    top: 53px;
    bottom: 36px;
    display:flex;
    overflow: hidden;
`;

export const FeedbackButton =  styled.a`
    right: 1.5em;
    padding: 20px 10px 1px 10px;
    position: absolute;
    z-index: 1;
    bottom: 3em;
    border-radius: 7px;
    overflow: hidden;
    background: ${props => props.theme.oppositeBackground};
    color: ${props => props.theme.oppositeForeground} !important;

    transform-origin: bottom right;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);

    -webkit-transition: 0.3s;
    transition: 0.3s;

    ${media.small`
        font-size: 10px;`
    }

    &:hover {
        right: 2.5em;
      }
`;

/* Menu */
export const ClubListLayout = styled(Layout)`
    background: ${props => props.theme.backgroundSecondary};
    min-height: 100vh;
    overflow-x: hidden;
    width: 100%;

    /* Club menu on left side */
    .ant-menu {
        background: ${props => props.theme.backgroundMain};
        color: ${props => props.theme.foregroundOverlayed};
        border: 0px;
    }

    .ant-menu-sub.ant-menu-inline {
        background: ${props => props.theme.backgroundMain};
        border-top: 1px solid ${props => props.theme.borderMain};
        border-bottom: 1px solid ${props => props.theme.borderMain};
    }

    .ant-menu-item-active {
        background: ${props => props.theme.hoverMain} !important;
        color: ${props => props.theme.blueHover} !important;
    }

    .ant-menu-item-selected, .ant-menu-item-selected:hover {
        background: ${props => props.theme.hoverMain} !important;
        color: ${props => props.theme.foregroundOverlayed};
    }

    .ant-list-item-meta-title {
        margin-top: 5px;
        margin-left: -5px;
    }

    /* Club pages on right sight */
    .ant-layout {
        height: 100%;
        height: stretch;
        overflow: auto;

        background: ${props => props.theme.clubMainBackground};
    }

    /* roster items separator */
    .ant-list-split .ant-list-item {
        border-bottom: 1px solid  ${props => props.theme.borderMain};
    }

    /* toggle border */
    .ant-switch {
        background: ${props => props.theme.signOutBtn};
        color: ${props => props.theme.foregroundOverlayed};
        ::after {
          background: ${props => props.theme.signOutBtn};
        }
    }
    .ant-switch-checked {
        background: transparent;
    }

    /* checkboxes */
    .ant-checkbox-wrapper {
        color: ${props => props.theme.foregroundOverlayed};
    }

    .ant-checkbox-disabled + span {
        color: ${props => props.theme.foregroundDisabled};
    }

    /* antd tags */
    .ant-tag {
        color: ${props => props.theme.foregroundSecondary};
        background: ${props => props.theme.backgroundSecondary};
    }
    .ant-tag a, .ant-tag a:hover {
        color: ${props => props.theme.foregroundSecondary};
    }

    /* default text colors*/
    h1, h2, h3, h4 {
        color: ${props => props.theme.foregroundOverlayed};
    }

    p, div {
        color: ${props => props.theme.foregroundSecondary};
    }

    strong {
        color: ${props => props.theme.foregroundBrigth};
    }

    /* pagination "..." symbol */
    .ant-pagination-jump-prev:after, .ant-pagination-jump-next:after {
        color: ${props => props.theme.foregroundSecondary};
    }
`;

/* Header related styles */
export const TitleAndMenu = styled.div`
    display: flex;
    justify-content: flex-start;
    color: ${props => props.theme.foregroundSecondary};
    white-space: nowrap;
`;

export const AccountDiv = styled.div`
    padding-right: 20px;

    .Gamerpic {
        height: 32px;
        width: 32px;
        border-radius: 25px;

        ${media.small`
            margin-left: 10px;
            height: 30px;
            width: 30px;
            border-radius: 15px;
        `}
    }

    .Gamerpic-Info {
        height: 32px;

        ${media.small`
            display: none;
        `}
    }
`;

export const SettingsIconDiv = styled.div`
    display: block;
    position: absolute;
    bottom: -2px;
    right: 8px;
    height: 18px;
    width: 18px;
    border-radius: 9px;

    background: ${props => props.theme.backgroundSecondary};
`;

export const XboxIcon = styled.div`
    font-family: Segoe Xbox MDL2 Assets;
    margin-top: -1px;
    padding: 0 0 2px 2px;
`;

export const TitleDiv = styled.div`
    padding: 0;
    font-size: 1.3em;
    margin-top: 5px;
    font-weight: normal;

    ${media.small`
        margin-left: -5px;
        font-size: 1.1em;
    `}
`;

export const AlphaDiv = styled.div`
    padding: 0;
    font-size: 1em;
    margin-left: 3px;
    font-weight: lighter;

    ${media.small`
        font-size: .8em;
    `}
`;
