import * as React from "react";
import * as moment from "moment";
import { EngagementTimesliceData } from "@xbox/social-core";
import * as styles from "../Analytics.Styles";
import { Localize } from "../../../common/translation/Localize";
import { FindMaxYAxis, NumberFormatter, PercentageFormatter } from "../../../Utils";

export interface ChartProps {
    timesliceData?: EngagementTimesliceData[];
    month?: string;
}

export interface ChartState {
    chartData: ChartPostDataObject[];
    maxYAxis: number;
}

export interface ChartPostDataObject {
    timestamp: string;
    comments?: number;
    likes?: number;
    shares?: number;
    posts?: number;
    visitors?: number;
    follow?: number;
    Total?: number;
}

export const CustomizedLegend = ((props: any) => {
    const { payload } = props;

    return (
        <table>
            {
                payload.map((entry: any, index: any) => (
                    <tbody key={`item-${index}`}>
                        <tr>
                            <styles.LegendColorTableCell style={{ backgroundColor: entry.color }} />
                            <styles.LegendTableCell>{entry.value}</styles.LegendTableCell>
                            {entry.payload.percent ? <styles.LegendTableCell>{PercentageFormatter(entry.payload.percent)}</styles.LegendTableCell> : null}
                        </tr>
                    </tbody>
                ))
            }
        </table>
    );
});

export abstract class BaseBarChart extends React.Component<ChartProps, ChartState> {
    constructor(props: any) {
        super(props);
        this.state = { chartData: [], maxYAxis: 0 };
        this.tickformatter = this.tickformatter.bind(this);
    }

    InitializeMonth = (month: string) => {
        console.warn(`InitializeMonth ${month} coming from BaseBarChart`);
    }

    Initialize30Days = (timestamp: string) => {
        console.warn("Initialize30Days coming from BaseBarChart");
    }

    Create30Days = (timestamp: string, timesliceData: any, retrieveValues: (newDay: any, datalist: any) => any) => {
        let localchart: ChartPostDataObject[] = [];
        let seedDate = moment(timestamp, "MM/DD").startOf("day");
        let max = 0;

        for (let i = 0; i < 30; i++) {
            let newDay = seedDate.clone().add(i, "day").format("MM/DD");
            let values: ChartPostDataObject = retrieveValues(newDay, timesliceData);
            localchart[i] = Object.assign({ "timestamp": newDay }, values);

            max = FindMaxYAxis(values, max);
        }

        if (max > 0) {
            this.setState({ chartData: localchart, maxYAxis: max });
        }
    }

    CreateMonth = (month: string, timesliceData: any, retrieveValues: (newDay: any, datalist: any) => any) => {
        let localchart: ChartPostDataObject[] = [];
        let seedDate = moment(month + "/01", "MM/DD").startOf("day");
        let max = 0;

        for (let i = 0; i < moment(month, "MM").daysInMonth(); i++) {
            let newDay = seedDate.clone().add(i, "day").format("MM/DD");
            let values: ChartPostDataObject = retrieveValues(newDay, timesliceData);
            localchart[i] = Object.assign({ "timestamp": newDay }, values);

            max = FindMaxYAxis(values, max);
        }

        if (max > 0) {
            this.setState({ chartData: localchart, maxYAxis: max });
        }
    }

    tickformatter(num: number) {
        return NumberFormatter(num, this.state.maxYAxis);
    }

    constructWatermark(translationKey: string) {
        return <styles.Watermark>{Localize(translationKey)}</styles.Watermark>;
    }

    componentDidMount() {
        if (this.props.month) {
            this.InitializeMonth(this.props.month);
        } else {
            this.Initialize30Days(moment().startOf("day").subtract(30, "days").format("MM/DD"));
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.month) {
            this.InitializeMonth(nextProps.month);
        } else {
            this.Initialize30Days(moment().startOf("day").subtract(30, "days").format("MM/DD"));
        }
    }
}
