"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryXboxTokenCache = void 0;

var EcdsaUniqueIdPair_1 = require("./EcdsaUniqueIdPair");

var Utils_1 = require("./Utils");

var XboxToken_1 = require("./XboxToken");

var InMemoryXboxTokenCache =
/** @class */
function () {
  function InMemoryXboxTokenCache(authConfig, cryptoFactory) {
    this.authConfig = authConfig;
    this.cryptoFactory = cryptoFactory;
    this.tokens = new Map();
    Utils_1.assert(!!authConfig);
    Utils_1.assert(!!cryptoFactory);
  }

  Object.defineProperty(InMemoryXboxTokenCache.prototype, "deviceIdentity", {
    get: function get() {
      return this.deviceIdentityInternal;
    },
    enumerable: false,
    configurable: true
  });

  InMemoryXboxTokenCache.prototype.setDeviceIdentity = function (deviceIdentityData) {
    return __awaiter(this, void 0, void 0, function () {
      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            Utils_1.assert(!this.deviceIdentityInternal);
            _a = this;
            return [4
            /*yield*/
            , EcdsaUniqueIdPair_1.EcdsaUniqueIdPair.deserialize(this.cryptoFactory, deviceIdentityData)];

          case 1:
            _a.deviceIdentityInternal = _b.sent();
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  InMemoryXboxTokenCache.prototype.resetDeviceIdentity = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(!!this.deviceIdentityInternal);
            return [4
            /*yield*/
            , this.deviceIdentityInternal.resetData(this.cryptoFactory)];

          case 1:
            _a.sent();

            this.tokens.forEach(function (value, key) {
              value.resetTokenData();
            });
            return [2
            /*return*/
            , this.deviceIdentityInternal];
        }
      });
    });
  };

  InMemoryXboxTokenCache.prototype.createDeviceIdentity = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!!this.deviceIdentityInternal) return [3
            /*break*/
            , 2];
            _a = this;
            return [4
            /*yield*/
            , EcdsaUniqueIdPair_1.EcdsaUniqueIdPair.create(this.cryptoFactory)];

          case 1:
            _a.deviceIdentityInternal = _b.sent();
            _b.label = 2;

          case 2:
            return [2
            /*return*/
            , this.deviceIdentityInternal];
        }
      });
    });
  };

  InMemoryXboxTokenCache.prototype.insertToken = function (token) {
    Utils_1.assert(!!token);
    var key = new CacheKey(token.identityType, token.relyingParty, token.subRelyingParty, token.tokenType, token.msaUserId);
    var serializedKey = key.serialize(); // assert(!this.tokens.has(serializedKey));

    this.tokens.set(serializedKey, token);
  };

  InMemoryXboxTokenCache.prototype.getUserDisplayClaims = function (msaUserId) {
    var foundPair = Array.from(this.tokens.entries()).find(function (pair) {
      return cacheKeyContainsMsaUserId(pair["0"], msaUserId) && pair["1"].identityType === XboxToken_1.IdentityType.Xtoken && pair["1"].hasSignInDisplayClaims;
    });

    if (foundPair) {
      return foundPair["1"];
    } else {
      return undefined;
    }
  };

  InMemoryXboxTokenCache.prototype.getTokensForUser = function (msaUserId) {
    var result = Array.from(this.tokens.entries()).filter(function (pair) {
      return cacheKeyContainsMsaUserId(pair["0"], msaUserId);
    }).map(function (pair) {
      return pair["1"];
    });
    return result;
  };

  InMemoryXboxTokenCache.prototype.getToken = function (identityType, relyingParty, subRelyingParty, tokenType, hasSignInDisplayClaims, msaUserId) {
    if (msaUserId && hasSignInDisplayClaims !== undefined && hasSignInDisplayClaims !== null) {
      Utils_1.assert(identityType === XboxToken_1.IdentityType.Utoken || identityType === XboxToken_1.IdentityType.Xtoken);
      return this.getTokenInternal(identityType, relyingParty, subRelyingParty, tokenType, hasSignInDisplayClaims, msaUserId);
    } else {
      Utils_1.assert(identityType === XboxToken_1.IdentityType.Dtoken || identityType === XboxToken_1.IdentityType.Ttoken);
      return this.getTokenInternal(identityType, relyingParty, subRelyingParty, tokenType);
    }
  };

  InMemoryXboxTokenCache.prototype.clearTokensForUser = function (msaUserId) {
    var _this = this;

    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(msaUserId));
    var foundTokenKeys = Array.from(this.tokens.keys()).filter(function (key) {
      return cacheKeyContainsMsaUserId(key, msaUserId);
    });
    foundTokenKeys.forEach(function (key) {
      return _this.tokens.delete(key);
    });
  };

  InMemoryXboxTokenCache.prototype.getTokenInternal = function (identityType, relyingParty, subRelyingParty, tokenType, hasSignInDisplayClaims, msaUserId) {
    if (hasSignInDisplayClaims === void 0) {
      hasSignInDisplayClaims = false;
    }

    var key = new CacheKey(identityType, relyingParty, subRelyingParty, tokenType, msaUserId);
    var token = this.tokens.get(key.serialize());

    if (!token) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Failed to find requested token in cache: [identityType:" + identityType + ", relyingParty:" + relyingParty + ", subRelyingParty:" + subRelyingParty + ", tokenType:" + tokenType + "]");
      var newToken = new XboxToken_1.XboxToken(hasSignInDisplayClaims, identityType, this.authConfig.environment, this.authConfig.sandbox, tokenType, relyingParty, subRelyingParty, msaUserId);
      this.insertToken(newToken);
      return newToken;
    } else {
      Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Returning requested cache: [hasSignInDisplayClaims:" + hasSignInDisplayClaims + " identityType:" + identityType + ", relyingParty:" + relyingParty + ", subRelyingParty:" + subRelyingParty + ", tokenType:" + tokenType + "]:{HasSignInDisplayClaims:" + token.hasSignInDisplayClaims + " hasData:" + token.hasData + ", isValid:" + token.isValid + ", xerr:" + (token.hasData ? token.xerr : 0) + ", expiry:" + (token.hasData ? token.expiry ? Date.parse(token.expiry) : "" : "") + ", now:" + Date.now() + "}");
      return token;
    }
  };

  return InMemoryXboxTokenCache;
}();

exports.InMemoryXboxTokenCache = InMemoryXboxTokenCache;

var CacheKey =
/** @class */
function () {
  function CacheKey(identityType, relyingParty, subRelyingParty, tokenType, msaUserId) {
    this.identityType = identityType;
    this.relyingParty = relyingParty;
    this.subRelyingParty = subRelyingParty;
    this.tokenType = tokenType;
    this.msaUserId = msaUserId;
  }

  CacheKey.deserialize = function (keyString) {
    var parts = keyString.split("|");
    return new CacheKey(XboxToken_1.IdentityType[parts[0]], parts[1], parts[2], parts[3], parts.length > 4 ? parts[4] : undefined);
  };

  CacheKey.prototype.keyContainsMsaUserId = function (id) {
    return !!this.msaUserId && this.msaUserId === id;
  };

  CacheKey.prototype.serialize = function () {
    return this.identityType + "|" + this.relyingParty + "|" + this.subRelyingParty + "|" + this.tokenType + "|" + this.msaUserId;
  };

  return CacheKey;
}(); // JavaScript can't lookup objects in a Map unless it's the same exact object
// so we transform the key to a string and use that for lookups


function cacheKeyContainsMsaUserId(cacheKey, id) {
  return CacheKey.deserialize(cacheKey).keyContainsMsaUserId(id);
}