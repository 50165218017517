"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExperimentsActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ExperimentsActions;

(function (ExperimentsActions) {
  ExperimentsActions.fetchExperiments = redux_ts_simple_1.createAction("AUTH::FETCH_EXPERIMENTS");
  ExperimentsActions.fetchExperimentsInProgress = redux_ts_simple_1.createAction("AUTH::FETCH_EXPERIMENTS_IN_PROGRESS");
  ExperimentsActions.fetchExperimentsCompleted = redux_ts_simple_1.createAction("AUTH::FETCH_EXPERIMENTS_COMPLETED");
})(ExperimentsActions = exports.ExperimentsActions || (exports.ExperimentsActions = {}));