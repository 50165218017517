"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClubHubSaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var _ = require("lodash");

var social_core_1 = require("@xbox/social-core");

var http_1 = require("@xbox/http");

var ClubsNormalizer_1 = require("../reducers/club/ClubsNormalizer");

var ClubListActions_1 = require("../actions/ClubListActions");

var Utils_1 = require("./Utils");

var ClubHubSaga =
/** @class */
function () {
  function ClubHubSaga(clubHubService, peopleHubService, notificationService, telemetryService) {
    this.clubHubService = clubHubService;
    this.peopleHubService = peopleHubService;
    this.notificationService = notificationService;
    this.telemetryService = telemetryService;
    this.sagas = [this.watchClubFetch(), this.watchFetchClubDetails(), this.watchCreateChannel(), this.watchDeleteChannel(), this.watchFetchFeedChannels(), this.watchFetchShareFeedChannels()];
  }

  ClubHubSaga.prototype._fetchClubItems = function () {
    var result, clubEntities, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 5,, 7]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubFetchInProgress(true))];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.getMyClubs()];

        case 2:
          result = _a.sent();
          clubEntities = ClubsNormalizer_1.clubNormalizer(result.data.me.user.clubs);
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubFetchCompleted(clubEntities))];

        case 3:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubFetchInProgress(false))];

        case 4:
          _a.sent();

          return [3
          /*break*/
          , 7];

        case 5:
          err_1 = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubFetchInProgress(false))];

        case 6:
          _a.sent();

          this.telemetryService.trackException(err_1, "ClubListFetch", {
            "action": "get_my_clubs"
          });
          return [3
          /*break*/
          , 7];

        case 7:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype._fetchClubDetails = function (action) {
    var club, result, err_2, bannedXuids_1, modXuids_1, presenceXuids_1, clubOwner_1, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          club = null;
          _a.label = 1;

        case 1:
          _a.trys.push([1, 12,, 16]);

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubDetailsFetchInProgress(true))];

        case 2:
          _a.sent();

          result = void 0;
          _a.label = 3;

        case 3:
          _a.trys.push([3, 5,, 6]);

          return [4
          /*yield*/
          , this.clubHubService.getClubDetails(action.payload)];

        case 4:
          result = _a.sent();
          return [3
          /*break*/
          , 6];

        case 5:
          err_2 = _a.sent(); // we may get back error from graphql but still have data

          if (!result || result && !result.data) {
            throw err_2;
          }

          return [3
          /*break*/
          , 6];

        case 6:
          // Clone so we can add in the roles since club presence doesn't come with club info
          club = _.cloneDeep(result.data.club);
          if (!(club.feedChannels && club.feedChannels)) return [3
          /*break*/
          , 8];
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubChannelSelected(club.feedChannels[0]))];

        case 7:
          _a.sent();

          _a.label = 8;

        case 8:
          if (club.clubPresence && club.clubPresence.items && club.roster) {
            bannedXuids_1 = club.roster && club.roster.banned ? club.roster.banned.filter(function (x) {
              return x.profile;
            }).map(function (x) {
              return x.profile.xuid;
            }) : [];
            modXuids_1 = club.roster && club.roster.moderator ? club.roster.moderator.filter(function (x) {
              return x.profile;
            }).map(function (x) {
              return x.profile.xuid;
            }) : [];
            presenceXuids_1 = club.clubPresence.items.filter(function (x) {
              return x.profile;
            }).map(function (x) {
              return x.profile.xuid;
            });
            clubOwner_1 = club.details.owner;

            if (clubOwner_1) {
              if (presenceXuids_1.indexOf(clubOwner_1.xuid) === -1) {
                club.clubPresence.items.push({
                  profile: clubOwner_1
                });
                presenceXuids_1.push(clubOwner_1.xuid);
              }
            }

            if (club.roster.moderator) {
              club.roster.moderator.forEach(function (person) {
                if (club && club.clubPresence && person.profile && presenceXuids_1.indexOf(person.profile.xuid) === -1) {
                  club.clubPresence.items.push(person);
                  presenceXuids_1.push(person.xuid);
                }
              });
            }

            if (club.roster.banned && club.clubPresence) {
              club.roster.banned.forEach(function (person) {
                if (club && club.clubPresence && person.profile && presenceXuids_1.indexOf(person.profile.xuid) === -1) {
                  club.clubPresence.items.push(person);
                  presenceXuids_1.push(person.xuid);
                }
              });
            }

            if (clubOwner_1) {
              club.clubPresence.items.forEach(function (p, idx) {
                if (p && p.profile) {
                  if (bannedXuids_1.indexOf(p.profile.xuid) !== -1) {
                    p.profile.role = social_core_1.ClubHub.ClubRoles.Banned;
                  } else if (clubOwner_1 && p.profile.xuid === clubOwner_1.xuid) {
                    p.profile.role = social_core_1.ClubHub.ClubRoles.Owner;
                  } else if (modXuids_1.indexOf(p.profile.xuid) !== -1) {
                    p.profile.role = social_core_1.ClubHub.ClubRoles.Moderator;
                  } else {
                    p.profile.role = social_core_1.ClubHub.ClubRoles.Member;
                  }
                }
              });
            }
          }

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubDetailsFetchCompleted(club))];

        case 9:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(club.feedChannels))];

        case 10:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubDetailsFetchInProgress(false))];

        case 11:
          _a.sent();

          return [3
          /*break*/
          , 16];

        case 12:
          err_3 = _a.sent();
          if (!club) return [3
          /*break*/
          , 14]; // put in all club data we were able to receive.

          console.warn("_fetchClubDetails: partially recieved club");
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubDetailsFetchCompleted(club))];

        case 13:
          _a.sent();

          _a.label = 14;

        case 14:
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.clubDetailsFetchInProgress(false))];

        case 15:
          _a.sent();

          console.warn("Unable to get club details: " + err_3);
          this.telemetryService.trackException(err_3, "ClubFetchDetails", {
            "clubId": action.payload,
            "action": "club_fetch_details"
          });
          return [3
          /*break*/
          , 16];

        case 16:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype._fetchFeedChannels = function (action) {
    var result, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload)];

        case 1:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(result.data.club.feedChannels))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_4 = _a.sent();
          console.warn("Unable to fetch feed channels list, error: " + err_4);
          this.telemetryService.trackException(err_4, "FetchFeedChannels", {
            "action": action.payload
          }, true);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype._fetchShareFeedChannels = function (action) {
    var result, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload)];

        case 1:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchShareFeedChannelsCompleted(result.data.club.feedChannels))];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          err_5 = _a.sent();
          console.warn("Unable to fetch share feed channels list, error: " + err_5);
          this.telemetryService.trackException(err_5, "FetchShareFeedChannels", {
            "action": action.payload
          }, true);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype._createChannel = function (action) {
    var result, err_6;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 4,, 5]);

          return [4
          /*yield*/
          , this.clubHubService.createClubChannel(action.payload)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , this.clubHubService.getClubFeedChannels(action.payload.clubId)];

        case 2:
          result = _a.sent();
          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted(result.data.club.feedChannels))];

        case 3:
          _a.sent();

          this.notificationService.notifySuccess("CreateChannel");
          return [3
          /*break*/
          , 5];

        case 4:
          err_6 = _a.sent();
          Utils_1.LogHttpsError("Unable to create new channel", err_6);

          if (err_6.response.status === http_1.Http.HttpStatus.BadRequest && err_6.response.data.errorId === 1015) {
            this.notificationService.modalErrorPayload("CreateChannel", {
              codePayload: 1015,
              textPayload: action.payload.channelData.ChannelType.toLowerCase()
            });
          } else {
            this.telemetryService.trackException(err_6, "CreateChannel", {
              "action": action.payload
            }, true);
          }

          return [3
          /*break*/
          , 5];

        case 5:
          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype._deleteChannel = function (action) {
    var err_7;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 3,, 4]);

          return [4
          /*yield*/
          , this.clubHubService.deleteClubChannel(action.payload)];

        case 1:
          _a.sent();

          return [4
          /*yield*/
          , effects_1.put(ClubListActions_1.ClubActions.deleteClubChannelComplete(action.payload))];

        case 2:
          _a.sent();

          this.notificationService.notifySuccess("DeleteChannel");
          return [3
          /*break*/
          , 4];

        case 3:
          err_7 = _a.sent();
          Utils_1.LogHttpsError("Unable to delete channel, error", err_7);
          this.telemetryService.trackException(err_7, "DeleteChannel", {
            "action": action.payload
          }, true);
          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  };
  /*********************************************************************/

  /* Watchers
  /*********************************************************************/


  ClubHubSaga.prototype.watchClubFetch = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.fetchClubItems.type, this._fetchClubItems.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype.watchFetchClubDetails = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.fetchClubDetails.type, this._fetchClubDetails.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype.watchCreateChannel = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.createClubChannel.type, this._createChannel.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype.watchDeleteChannel = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.deleteClubChannel.type, this._deleteChannel.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype.watchFetchFeedChannels = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.fetchClubFeedChannels.type, this._fetchFeedChannels.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga.prototype.watchFetchShareFeedChannels = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ClubListActions_1.ClubActions.fetchShareFeedChannels.type, this._fetchShareFeedChannels.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ClubHubSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IClubHubService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.IPeopleHubService)), __param(2, inversify_1.inject(social_core_1.XSocialSymbols.INotificationsService)), __param(3, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __metadata("design:paramtypes", [Object, Object, Object, Object])], ClubHubSaga);
  return ClubHubSaga;
}();

exports.ClubHubSaga = ClubHubSaga;