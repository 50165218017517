"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EcdsaUniqueIdPair = void 0;

var Utils_1 = require("./Utils");

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var EcdsaUniqueIdPair =
/** @class */
function () {
  function EcdsaUniqueIdPair(uniqueId, key) {
    this.uniqueId = uniqueId;
    this.key = key;
    Utils_1.assert(!!key);
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(uniqueId));
  }

  EcdsaUniqueIdPair.deserialize = function (cryptoFactory, data) {
    return __awaiter(this, void 0, void 0, function () {
      var parsedData, id, keyData, key;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            parsedData = JSON.parse(data);
            id = parsedData.Id;
            keyData = parsedData.Key;
            return [4
            /*yield*/
            , cryptoFactory.deserializeEcdsa(keyData)];

          case 1:
            key = _a.sent();

            if (!id || id.length === 0) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Missing ID from deserialized ECDSA key pair");
            } else if (!key) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Missing ECDSA key from deserialized ECDSA key pair");
            }

            return [2
            /*return*/
            , new EcdsaUniqueIdPair(id, key)];
        }
      });
    });
  };

  EcdsaUniqueIdPair.create = function (cryptoFactory) {
    return __awaiter(this, void 0, void 0, function () {
      var keyAndId;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , cryptoFactory.createEcdsaAndDeviceId(true)];

          case 1:
            keyAndId = _a.sent();
            return [2
            /*return*/
            , new EcdsaUniqueIdPair(keyAndId.deviceId, keyAndId.ecdsa)];
        }
      });
    });
  };

  EcdsaUniqueIdPair.prototype.serialize = function () {
    return __awaiter(this, void 0, void 0, function () {
      var keyData, key;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.key.serialize()];

          case 1:
            keyData = _a.sent();
            key = {
              Id: this.uniqueId,
              Key: keyData
            };
            return [2
            /*return*/
            , JSON.stringify(key)];
        }
      });
    });
  };

  EcdsaUniqueIdPair.prototype.resetData = function (cryptoFactory) {
    return __awaiter(this, void 0, void 0, function () {
      var keyAndId;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , cryptoFactory.createEcdsaAndDeviceId(false)];

          case 1:
            keyAndId = _a.sent();
            this.uniqueId = keyAndId.deviceId;
            this.key = keyAndId.ecdsa;
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return EcdsaUniqueIdPair;
}();

exports.EcdsaUniqueIdPair = EcdsaUniqueIdPair;