import * as moment from "moment";
import { push } from "react-router-redux";
import { ClubHub } from "@xbox/social-core";
import { GroupedChannels } from "@xbox/social-views";
import { Localize } from "./common/translation/Localize";
import { ChartPostDataObject } from "./components/analytics/charts/BaseBarChart";
import { DefaultGroupChannelName } from "./components/channels/ChannelPicker";

export function GetDateTimeDiff(postDateTime: Date): string {
    let current = moment();

    let daysDifference = current.diff(postDateTime, "day");

    if (daysDifference > 7) {
        return current.year() === moment(postDateTime).year()
            ? moment(postDateTime).format("MMMM D")
            : moment(postDateTime).format("MMMM D, YYYY");
    }

    if (daysDifference > 0) {
        return daysDifference + Localize("postFeedItem.days_abbreviation");
    }

    let hoursDifference = current.diff(postDateTime, "hour");
    if (hoursDifference > 0) {
        return hoursDifference + Localize("postFeedItem.hours_abbreviation");
    }

    let minutesDifference = current.diff(postDateTime, "minute");
    if (minutesDifference > 0) {
        return minutesDifference + Localize("postFeedItem.minutes_abbreviation");
    }

    if (daysDifference < 0 || hoursDifference < 0 || minutesDifference < 0) {
        return "";
    }

    return Localize("postFeedItem.justNow");
}

export function GetDateTimeDiffUntil(dateTime: moment.Moment): string {
    if (dateTime.isBefore(moment())) {
        return Localize("postFeedItem.timeLapsed");
    }
    return Localize("postFeedItem.timeLeft").replace("${v}", dateTime.fromNow(true));
}

export function NumberFormatter(num: number, max?: number) {
    if (num === 0) {
        return num.toLocaleString();
    }

    if (max) {
        if (max > 4000000) { // max > 4M, then no decimal with M
            return (num / 1000000).toFixed(0).toLocaleString() + Localize("generic.million_abbreviation");
        } else if (max <= 4000000 && max > 1000000) { // 4M >= max > 1M, then decimal with M
            return (num / 1000000).toFixed(1).toLocaleString() + Localize("generic.million_abbreviation");
        } else if (max <= 1000000 && max > 4000) { // 1M >= max > 4K, then no decimal with K
            return (num / 1000).toFixed(0).toLocaleString() + Localize("generic.thousands_abbreviation");
        } else if (max <= 4000 && max > 1000) { // 4K >= max > 1K, then decimal with K
            return (num / 1000).toFixed(1).toLocaleString() + Localize("generic.thousands_abbreviation");
        } else {
            return num.toLocaleString();
        }
    }

    if (num > 999999) {
        return (num / 1000000).toFixed(1).toLocaleString() + Localize("generic.million_abbreviation");
    } else if (num > 9999) {
        return (num / 1000).toFixed(1).toLocaleString() + Localize("generic.thousands_abbreviation");
    }
    return num.toLocaleString();
}

export function FindMaxYAxis(data: ChartPostDataObject, max: number) {
    if (data.Total && data.Total > max) {
        max = data.Total;
    }
    if (data.visitors && data.visitors > max) {
        max = data.visitors;
    }
    if (data.follow && data.follow > max) {
        max = data.follow;
    }
    if (data.posts && data.posts > max) {
        max = data.posts;
    }
    return max;
}

export function PercentageFormatter(num: number): string {
    return (num * 100).toFixed(0) + "%";
}

export function Swap(array: any[], a: any, b: any) {
    let temp = array[a];
    array[a] = array[b];
    array[b] = temp;
}

export function HandleNavigation(route: string, pattern: string, dispatch: any) {
    let index = location.pathname.indexOf(pattern);
    if (index >= 0) {
        let prefix = location.pathname.substr(0, index + pattern.length);
        dispatch(push(prefix + route));
    }
}

export function ReorgnizeChannels(channels: ClubHub.Channel[]) {
    // separate default channels and channels without a group
    // filter the default main feed and chat channel
    let mainChannels = channels.filter(c => !c.group);
    // filter the remaining channels in default group
    let defaultGroupChannels = channels.filter(c => (c.group && c.group.name === DefaultGroupChannelName));

    let defaultChannels = mainChannels.concat(defaultGroupChannels);
    let groups: GroupedChannels[] = [{ name: DefaultGroupChannelName, order: 0, channels: defaultChannels }];

    // TODO: optimize filtering.
    channels.filter(c => c.group).forEach((channel: ClubHub.Channel) => {
        if (!channel || !channel.group) {
            return;
        }

        let groupName = channel.group.name;
        if (!groups.find(g => g.name === groupName)) {
            let groupedChannels = channels && channels.filter(c => c.group && channel.group && c.group.name === channel.group.name) || [];
            groups.push({ name: channel.group.name, order: channel.group.order, channels: groupedChannels });
        }
    });

    groups.sort((a: GroupedChannels, b: GroupedChannels) => {
        if (a.name === DefaultGroupChannelName) {
            return -1;
        } else if (b.name === DefaultGroupChannelName) {
            return 1;
        }

        if ((a.order || 0) > (b.order || 0)) {
            return 1;
        } else {
            return -1;
        }
    });

    return groups;
}
