"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticsService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var utils_1 = require("../../utils");

var XSocialSymbols_1 = require("../../typings/XSocialSymbols");

var AnalyticsService =
/** @class */
function () {
  function AnalyticsService(xblService) {
    this.xblService = xblService;
  }

  AnalyticsService.prototype.getFeedAnalytics = function (clubId, month, exportCSV) {
    var analyticsHeader;

    if (exportCSV) {
      analyticsHeader = utils_1.GetExportCSVHeaders(12);
    } else {
      analyticsHeader = utils_1.GetGenericHeaders(12);
    }

    var monthSpecification = "";

    if (month) {
      monthSpecification = "?month=" + month;
    }

    return this.xblService.sendRequest({
      url: "https://clubanalytics.xboxlive.com/clubs/" + clubId + "/analytics/feed" + monthSpecification,
      method: http_1.Http.HttpRequestMethod.Get,
      headers: analyticsHeader
    });
  };

  AnalyticsService.prototype.getAdminActionAnalytics = function (clubId, month, exportCSV) {
    var analyticsHeader;

    if (exportCSV) {
      analyticsHeader = utils_1.GetExportCSVHeaders(12);
    } else {
      analyticsHeader = utils_1.GetGenericHeaders(12);
    }

    var monthSpecification = "";

    if (month) {
      monthSpecification = "?month=" + month;
    }

    return this.xblService.sendRequest({
      url: "https://clubanalytics.xboxlive.com/clubs/" + clubId + "/analytics/admin" + monthSpecification,
      method: http_1.Http.HttpRequestMethod.Get,
      headers: analyticsHeader
    });
  };

  AnalyticsService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IXboxLiveRequestService)), __metadata("design:paramtypes", [Object])], AnalyticsService);
  return AnalyticsService;
}();

exports.AnalyticsService = AnalyticsService;