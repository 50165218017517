import { css } from "styled-components";

export const CombinedMenuWidth = 1360;

export const sizes = {
    "1080p": 1920,
    medium: 1024,
    menuOpenSmall: 910,
    menuOpenXSmall: 730,
    small: 580,
    xSmall: 510,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (literals: TemplateStringsArray, ...placeholder: any[]) => css`
        @media (max-width: ${emSize}em) {
            ${ css(literals, ...placeholder) }
        }
    `;
    return accumulator;
}, {} as Record<keyof typeof sizes, (l: TemplateStringsArray, ...p: any[]) => string>);
