import styled from "styled-components";
import { Tag } from "antd";

export const LfgTag = styled(Tag)`
    margin-bottom: 5px !important;
    margin-right: 7px !important;
    user-select: none !important;
    border: 1px solid ${props => props.theme.borderMain} !important;
    font-size: 14px !important;
    height: auto !important;
    cursor: default !important;
`;

export const VerticalSpace = styled.div`
    height: 30px;
    display: block;
    width: 100%;
`;
