import * as React from "react";
import { List } from "antd";
import { MsStoreGameInfo } from "@xbox/social-redux";
import { MiniGameItem } from "./MiniGameItem";
import { Button } from "../../../common/generic/Button";

interface GamesListProps {
    dataSrc?: MsStoreGameInfo[];
    onClick: (item: MsStoreGameInfo) => void;
    selectedItemId: string | null;
}

export const GamesList = (props: GamesListProps) => {
    if (!props.dataSrc) {
        return <div/>;
    }

    return (
        <List
            grid={{ gutter: 1, xs: 2, sm: 4, md: 4, lg: 6}}
            dataSource={props.dataSrc}
            loading={false}
            renderItem={(item: MsStoreGameInfo) => {
                return (
                    <List.Item>
                        <Button
                            onClick={() => props.onClick(item)}
                            style={{padding: "5px"}}
                            buttonType={props.selectedItemId === item.productId ? "primary" : "default"}
                        >
                            <MiniGameItem
                                thumbnailUrl={item.posterUrl}
                                voiceTitle={item.voiceTitle}
                                gameTitle={item.productTitle}
                            />
                        </Button>
                    </List.Item>
                );
            }}
        />
    );
};
