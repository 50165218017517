import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";

import * as FeedItemStyles from "./base/FeedItem.Styles";
import { ActivityFeedItem, FeedItemProps } from "./base/ActivityFeedItem";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import { Localize } from "../../common/translation/Localize";
import { Button } from "../../common/generic/Button";
import { PreviewFeedItemProps, PreviewActivityFeedItem } from "./base/PreviewActivityFeedItem";

export class GameLinkFeedItem extends ActivityFeedItem {
    constructor(props: FeedItemProps) {
        super(props);
    }

    handleLinkClick = (e: any) => {
        let links = this.props.item.postDetails.links;
        if (!links) { return; }

        let storeUrl = links.find(l => l.linkType === ActivityFeed.LinkDeviceType.Web);
        if (!storeUrl) { return; }

        window.open(storeUrl.link, "_blank");
        e.stopPropagation();
    }

    /*********************************************************************/
    /* Full Base Template
    /*********************************************************************/
    getFullTextContainer = (): JSX.Element | null => {
        return (
            <FeedItemStyles.TextContainer style={{ marginBottom: "0px" }}>
                {this.props.item.ugcCaption}
            </FeedItemStyles.TextContainer>
        );
    }

    getFullFeedItemData = (): JSX.Element | null => {
        let preview = this.props.item.itemImage
                        ? <FeedItemStyles.FeedMedia onLoad={this.props.onload} alt="previewImage" src={this.props.item.itemImage} />
                        : null;
        return (
            <div>
                <FeedItemStyles.TextContainer style={{ marginBottom: "0px" }}>{this.props.item.postDetails.description}</FeedItemStyles.TextContainer>
                {this.getImagePreview(preview)}
                <Button onClick={this.handleLinkClick} buttonType="primary" style={{marginBottom: "10px"}}>
                    <Icon type={Glyphs.Link} />{Localize("postFeedItem.openWebStore")}
                </Button>
            </div>
        );
    }

    /*********************************************************************/
    /* Inline Template
    /*********************************************************************/
    getInlineSecondaryContent = (): JSX.Element | null => {
        let links = this.props.item.postDetails.links;
        let storeUrl = links && links.find(l => l.linkType === ActivityFeed.LinkDeviceType.Web);

        return storeUrl
            ? (
                <FeedItemStyles.EllipsisLinkContainer target="_blank" href={storeUrl.link} style={{ marginLeft: "-5px", fontSize: "13px" }}>
                    <Icon type={Glyphs.Link} />{Localize("postFeedItem.openWebStore")}
                </FeedItemStyles.EllipsisLinkContainer>
            )
            : null;

    }

    getInlinePostGlyph = () => {
        return Glyphs.StoreLogo16;
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/
    getGridLinkButton = (): JSX.Element | null => {
        return (
            <FeedItemStyles.SmallLinkButton onClick={this.handleLinkClick} buttonType="primary">
                <Icon type={Glyphs.Link} />{Localize("postFeedItem.openWebStoreShort")}
            </FeedItemStyles.SmallLinkButton>
        );
    }
}

interface PreviewGameLinkFeedItemProps extends PreviewFeedItemProps {
    webStoreLink?: string;
    gameScreenshot?: string;
}

export const PreviewGameLinkFeedItem: React.SFC<PreviewGameLinkFeedItemProps> = props => {
    let preview = props.gameScreenshot ? <FeedItemStyles.FeedMedia alt="previewImage" src={props.gameScreenshot} /> : null;

    const handleLinkClick = (e: any) => {
        window.open(props.webStoreLink, "_blank");
        e.stopPropagation();
    };

    let previewContent =  (
        <div>
            <FeedItemStyles.TextContainer style={{ marginBottom: "0px" }}>{props.itemText} </FeedItemStyles.TextContainer>
            <FeedItemStyles.MediaContainer>{preview}</FeedItemStyles.MediaContainer>
            <Button onClick={handleLinkClick} disabled={!props.webStoreLink} buttonType="primary"><Icon type={Glyphs.Link} />{Localize("postFeedItem.openWebStore")}</Button>
        </div>
    );

    return <PreviewActivityFeedItem previewContent={previewContent} {...props} />;
};
