"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aggregatedTitleHubReducer = void 0;

var redux_1 = require("redux");

var FetchAllTitlesReducer_1 = require("./FetchAllTitlesReducer");

var FetchTitlesHistoryReducer_1 = require("./FetchTitlesHistoryReducer");

var FetchByPfnReducer_1 = require("./FetchByPfnReducer");

var SearchMsCatalogReducer_1 = require("./SearchMsCatalogReducer");

exports.aggregatedTitleHubReducer = redux_1.combineReducers({
  alltitles: FetchAllTitlesReducer_1.fetchAllTitlesReducer,
  titlesHistory: FetchTitlesHistoryReducer_1.fetchTitlesHistoryReducer,
  titleByPfn: FetchByPfnReducer_1.fetchByPfnReducer,
  searchMsCatalog: SearchMsCatalogReducer_1.searchMsCatalogReducer
});