import styled from "styled-components";
import { ImageFade } from "../../styles/ImageStyles";
import { media } from "../../common/Generic.SnapPoints";
import { Button } from "../../common/generic/Button";

export const Gamerpic = styled(ImageFade)`
    width: 100%;
    display: block;
    width: 64px;
    border-radius: 32px;
`;

export const UserName = styled.span`
    font-size: 16px;
    margin-left: 5px;
    vertical-align: text-top;
    ${media.medium`
        font-size: 16px;
        margin-bottom: 15px;`
    }
    ${media.menuOpenSmall`
        font-size: 13px;
        margin-bottom: 5px;
        `
    }
    ${media.small`
        font-size: 14px;
        margin-bottom: 10px;
        `}
    ${media.xSmall`
        font-size: 13px;
        margin-bottom: 5px;
        `}
`;

export const Subtext = styled.p`
    font-size: 14px;
    margin-left: 10px;
    vertical-align: text-top;
    margin-bottom: 0px;
`;

export const LightSubtext = styled.p`
    font-size: 14px;
    margin-left: 10px;
    vertical-align: text-top;
    opacity: 0.7;
`;

export const ViewProfileLink = styled.a`
    position: absolute;
    bottom: 0;
    right: 0;
    margin-top:20px;
`;

export const ProfileActionsMenuLink = styled.a`
    margin-top:20px;
`;

export const HorizontalSeparator = styled.span`
    width: 1em;
    display: inline-block;
`;

export const ProfileCardPopup = styled.div`
    min-width: 200px;
    min-height: 120px;
    position: relative;
    color: ${props => props.theme.foregroundMain};
`;

export const ProfileCardTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ProfileCardContent = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
`;

export const MenuButton = styled(Button)`
    font-size: 24px !important;
    border: 0px !important;
    color: ${props => props.theme.foregroundSecondary} !important;
    font-weight: 800 !important;
    background-color: transparent;
    margin-right: -15px;
`;

const BaseLabel = styled.span`
    margin-right: .25em;
    font-size: .75em;
    padding: .2em .5em;
    border-radius: .25em;
    padding-top: .2em;
    line-height: 2.5em;
    display: inline;
`;

export const FriendLabel = BaseLabel.extend`
    background: ${props => props.theme.friendLabelBckg};
    color: ${props => props.theme.blackText};
`;

export const FavoriteLabel = BaseLabel.extend`
    background: ${props => props.theme.favoriteLabelBckg};
    color: ${props => props.theme.blackText};
`;

export const FollowsYouLabel = BaseLabel.extend`
    background: ${props => props.theme.followsLabelBckg};
    color: ${props => props.theme.blackText};
`;
