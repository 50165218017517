var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { clubsRedux, AuthActions } from "@xbox/social-redux";
export var SIGN_OUT = "sign_out";
export var ThemeType;

(function (ThemeType) {
  ThemeType[ThemeType["LightTheme"] = 0] = "LightTheme";
  ThemeType[ThemeType["DarkTheme"] = 1] = "DarkTheme";
  ThemeType[ThemeType["HighContrastLight"] = 2] = "HighContrastLight";
  ThemeType[ThemeType["HighContrastDark"] = 3] = "HighContrastDark";
})(ThemeType || (ThemeType = {}));

function coreUserSettingsContainer(userSettingsModalResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        return _super.call(this, props) || this;
      }

      class_1.prototype.render = function () {
        return userSettingsModalResolver.getUserSettingsModal(this.props.showUserSettingsModal, this.props.closeUserSettingsModal, this.props.navigateWhatsNew, this.props.changeTheme, this.props.changeLanguage, this.props.signOutUserModal, this.props.handleSignOutUser, this.props.changeDisplayMode, this.props.currentUser, this.props.theme, this.props.displayMode, this.props.currentLocale);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var result = clubsRedux.selectors.getClubViewerState(state);
  return {
    currentUser: result.currentUser
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    handleSignOutUser: function handleSignOutUser(cookies) {
      dispatch(clubsRedux.actions.clearState());
      dispatch(AuthActions.signOut(cookies));
      dispatch(push("/"));
      return;
    },
    navigateWhatsNew: function navigateWhatsNew() {
      return dispatch(push("/app"));
    }
  };
};

export function CoreUserSettingsModalContainer(userSettingsModalResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreUserSettingsContainer(userSettingsModalResolver));
}