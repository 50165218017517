import * as React from "react";
import { ClubState, CoreClubSettingsContainer, IClubSettingsPanelResolver, IClubSettingsHandlers } from "@xbox/social-views";
import { ClubHub, PrivacySetting, PrivacySettingId, PrivacyValue, FeedSetting, FeedSettingName } from "@xbox/social-core";
import { Row, Modal, Select } from "antd";
import { ClubSettingsComboBox, ClubSettingsCheckBox } from "../../../components/clubs/";
import { withClubSelectionContext } from "../../ClubSelectionContext";
import { Localize } from "../../../common/translation/Localize";
import { GenericLoading, GenericEmptyState, GenericDisplayMode } from "../../../common/GenericElements";
import { Button } from "../../../common/generic/Button";
import { Divider } from "../../../common/generic/Divider";
import * as GlobalStyles from "../../Styles";
import * as Styles from "./ClubSettingsContainer.Styles";
import { HorizontalSeparator } from "../ClubAdmin.Styles";
import * as ClubStyles from "../../../components/clubs/Club.Styles";
// import { ReorderChannelsContainer } from "./ReorderChannelsContainer";

const confirm = Modal.confirm;
const Option = Select.Option;

const EmptyState = () => {
    return (
        <GenericEmptyState
            message={Localize("clubSettings.errorState")}
        />
    );
};

function getClubSettingsPanel(
    club: ClubHub.Club,
    handlers: IClubSettingsHandlers,
    privacySetting: PrivacySetting[],
    feedSetting: FeedSetting[],
    channels: ClubHub.Channel[],
    canReorderChannel: boolean,
    safetyThresholdInProgress: boolean,
    safetyThreshold?: ClubHub.SafetyThresholdData,
): JSX.Element | null {
    const gutters = { xs: 8, sm: 16, md: 24 };

    if (!club || !club.settings) {
        return EmptyState();
    }
    const showDeleteConfirm = () => {
        confirm({
            title: Localize("clubSettings.deleteConfirmTitle"),
            content: Localize("clubSettings.deleteConfirmText"),
            okText: Localize("generic.okay"),
            okType: "danger",
            cancelText: Localize("generic.cancel"),
            onOk() {
                handlers.deleteClubOnClick();
            }
        });
    };

    let keepClubButtonStyle: string;
    let deleteClubButtonStyle: string;
    let recordedGameSetting;
    if (privacySetting) {
        recordedGameSetting = privacySetting.find(s => s.setting === PrivacySettingId.ShareRecordedGameSessions);
    }

    let autoPostClipsSetting;
    let autoPostScreenshotsSetting;
    if (feedSetting) {
        autoPostClipsSetting = feedSetting.find(s => s.name === FeedSettingName.AutopostGameClips);
        autoPostScreenshotsSetting = feedSetting.find(s => s.name === FeedSettingName.AutopostScreenShots);
    }

    let autoPostSetting = autoPostClipsSetting && autoPostClipsSetting.value && autoPostScreenshotsSetting && autoPostScreenshotsSetting.value;

    if (club.state === ClubState.Suspended) {
        deleteClubButtonStyle = "hidden";
        keepClubButtonStyle = "";
    } else {
        keepClubButtonStyle = "hidden";
        deleteClubButtonStyle = "";
    }

    let isOwner = club.settings.viewerRoles.roles.some(role => role === ClubHub.ClubRoles.Owner) || false;

    return (
        <GlobalStyles.ContentContainer>
            <Styles.SettingsContainer>
                <Row>
                    <GlobalStyles.ContentTitle>{Localize("clubMenu.clubsettings")}</GlobalStyles.ContentTitle>
                </Row>
                {/* Membership settings */}
                <Row gutter={gutters}>
                    <Styles.SettingsColumn>
                        <h3>{Localize("clubSettings.membershipTitle")}</h3>
                        <ClubSettingsComboBox
                            label={Localize("clubSettings.clubWhoCanInviteInviteLabel")}
                            handler={handlers.inviteOrAcceptOnChange}
                            item={club.settings.roster.inviteOrAccept}
                        />
                        <br />
                    </Styles.SettingsColumn>
                </Row>
                <Divider />

                {/* Moderation settings */}
                <Row gutter={gutters}>
                    <h3>{Localize("clubSettings.moderationTitle")}</h3>
                    <Styles.SettingsColumn>
                        <p>{Localize("clubSettings.moderationFilteringLabel")}</p>
                        <ClubStyles.DropDown
                            onChange={handlers.safetyThresholdOnChange}
                            value={safetyThreshold ? Localize(`clubSettings.moderationOption_${safetyThreshold.value}`) : ""}
                            disabled={safetyThresholdInProgress || !safetyThreshold || !safetyThreshold.canViewerChangeSetting}
                        >
                            {safetyThreshold
                                && safetyThreshold.allowedValues
                                && safetyThreshold.allowedValues.map(
                                    (val: string) => (
                                        <Option key={val} value={val}>
                                            {Localize(`clubSettings.moderationOption_${val}`)}
                                        </Option>
                                    )
                                )}
                        </ClubStyles.DropDown>
                    </Styles.SettingsColumn>
                    {safetyThreshold
                        &&
                        <Styles.SettingsColumn>
                            <div>{Localize(`clubSettings.moderationDecription_${safetyThreshold.value}_1`)}</div>
                            <div>{Localize(`clubSettings.moderationDecription_${safetyThreshold.value}_2`)}</div>
                        </Styles.SettingsColumn>
                    }
                    <br />
                </Row>
                <Divider />

                {/* Privacy and broadcast */}
                <Row gutter={gutters}>
                    <Styles.SettingsColumn>
                        <h2>{Localize("clubSettings.privacyTitle")}</h2>
                        <div>
                            <p>{Localize("clubSettings.privacySetting")}</p>

                            <ClubStyles.DropDown
                                onChange={handlers.shareRecordedGameSessionsOnChange}
                                value={recordedGameSetting ? recordedGameSetting.value : ""}
                            >
                                <Option value={PrivacyValue.Everyone}>{Localize(`privacySettings.${PrivacyValue.Everyone}`)}</ Option>
                                <Option value={PrivacyValue.PeopleOnMyList}>{Localize(`privacySettings.${PrivacyValue.PeopleOnMyList}`)}</ Option>
                                <Option value={PrivacyValue.Blocked}>{Localize(`privacySettings.${PrivacyValue.Blocked}`)}</ Option>
                            </ClubStyles.DropDown>
                        </div>
                        <br />
                        <ClubStyles.CheckBox
                            style={{ width: "100%" }}
                            onChange={handlers.autoPostCapturesOnChage}
                            checked={autoPostSetting}
                        >
                            {Localize("clubSettings.autoPostSetting")}
                        </ClubStyles.CheckBox>
                    </Styles.SettingsColumn>

                    <Styles.SettingsColumn>
                        <h2>{Localize("clubSettings.broadcastTitle")}</h2>
                        <ClubSettingsCheckBox
                            label={Localize("clubSettings.broadcastsShowMatureLabel")}
                            handler={handlers.broadcastsShowMatureOnChange}
                            item={club.details.profile.matureContentEnabled}
                            value={club.details.profile.matureContentEnabled.value}
                        />
                        <br />
                        <br />
                        <ClubSettingsCheckBox
                            handler={handlers.broadcastsShowOnlyClubGamesOnChange}
                            value={club.details.profile.watchClubTitlesOnly.value}
                            item={club.details.profile.watchClubTitlesOnly}
                            label={Localize("clubSettings.broadcastsShowOnlyClubTitlesLabel")}
                        />
                    </Styles.SettingsColumn>
                </Row>
                <br />
                <Divider />

                <Row>
                    { /* Hide languages for now
                    <Styles.SettingsColumn>
                        <h2>{Localize("clubSettings.languageTitle")}</h2>
                        <ClubSettingsComboBox
                            label={Localize("clubSettings.languageLabel")}
                            handler={handlers.languageOnChange}
                            item={club.details.profile.preferredLocale}
                        />
                        <br />
                        <ClubSettingsComboBox
                            label={Localize("clubSettings.languageRegionLabel")}
                            handler={handlers.languageRegionOnChange}
                            item={club.details.profile.preferredLocale}
                        />
                        <br />
                    </Styles.SettingsColumn> */}
                    <Styles.SettingsColumn style={{display: GenericDisplayMode(isOwner)}}>
                        <h2>{Localize("clubSettings.ownershipTitle")}</h2>
                        <Button onClick={showDeleteConfirm} className={deleteClubButtonStyle} buttonType="danger">
                            {Localize("clubSettings.deleteClubLabel")}
                        </Button>
                        <Button onClick={handlers.cancelDeleteClubOnClick} className={keepClubButtonStyle}>
                            {Localize("clubSettings.keepClubLabel")}
                        </Button>
                        <HorizontalSeparator />
                    </Styles.SettingsColumn>
                </Row>
            </Styles.SettingsContainer >
        </GlobalStyles.ContentContainer >
    );
}

const ClubSettingsPanelResolver: IClubSettingsPanelResolver = {
    getClubSettingsPanel: getClubSettingsPanel
};

export default withClubSelectionContext(CoreClubSettingsContainer(ClubSettingsPanelResolver, GenericLoading, EmptyState));
