"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XalErrorType = void 0;
var XalErrorType;

(function (XalErrorType) {
  XalErrorType["Aborted"] = "Aborted";
  XalErrorType["InvalidArgument"] = "InvalidArgument";
  XalErrorType["NotInitialized"] = "NotInitialized";
  XalErrorType["NotSupported"] = "NotSupported";
  XalErrorType["UiRequired"] = "UiRequired";
  XalErrorType["UnknownError"] = "UnknownError";
})(XalErrorType = exports.XalErrorType || (exports.XalErrorType = {}));