var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { ClubActions, clubsRedux } from "@xbox/social-redux";
export var ClubModActions;

(function (ClubModActions) {
  ClubModActions["ban"] = "ban";
  ClubModActions["unban"] = "unban";
  ClubModActions["promote"] = "promote";
  ClubModActions["demote"] = "demote";
  ClubModActions["remove"] = "remove";
  ClubModActions["reportUser"] = "reportUser";
})(ClubModActions || (ClubModActions = {}));

var FilterTypes = function FilterTypes(members, filter) {
  if (!members || !filter) {
    return members;
  }

  switch (filter) {
    case "all":
      return members.filter(function (t) {
        return t && t.role !== ClubHub.ClubRoles.Banned;
      });

    case ClubHub.ClubRoles.Moderator:
      return members.filter(function (t) {
        return t && (t.role === ClubHub.ClubRoles.Moderator || t.role === ClubHub.ClubRoles.Owner);
      });

    default:
      return members.filter(function (t) {
        return t && t.role === filter;
      });
  }
};

var FilterGamerTag = function FilterGamerTag(members, filter) {
  if (!filter) {
    return members;
  }

  return members.filter(function (t) {
    return t.gamertag.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1 || t.realName && t.realName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
  });
};

function coreRosterContainer(LoadingState, EmptyState, resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.search = function (e) {
          // search
          _this.props.applySearchFilter(e);
        };

        _this.filter = function (e) {
          // type filter
          _this.props.applyTypeFilter(e);
        };

        _this.reportUser = function (reporter, offense, memberXuid) {
          var payload = {
            evidenceId: memberXuid,
            feedbackContext: "User",
            feedbackType: offense,
            reporter: reporter
          };

          _this.props.reportUser(payload);
        };

        _this.inviteUser = function (gamertag) {
          if (gamertag !== "") {
            var request = {
              clubId: _this.props.clubId,
              gamertag: gamertag
            };

            _this.props.inviteUser(request);
          }
        };

        _this.updateRole = function (member, action) {
          var updatePayload = {
            xuid: member.xuid,
            clubId: _this.props.clubId
          }; // type filter

          switch (action) {
            case ClubModActions.ban:
              updatePayload["role"] = ClubHub.ClubRoles.Banned;
              updatePayload["add"] = true;
              break;

            case ClubModActions.promote:
              updatePayload["role"] = ClubHub.ClubRoles.Moderator;
              updatePayload["add"] = true;
              break;

            case ClubModActions.demote:
              updatePayload["role"] = ClubHub.ClubRoles.Moderator;
              updatePayload["add"] = false;
              break;

            case ClubModActions.remove:
              updatePayload["role"] = ClubHub.ClubRoles.Member;
              updatePayload["add"] = false;
              break;

            case ClubModActions.unban:
              updatePayload["role"] = ClubHub.ClubRoles.Banned;
              updatePayload["add"] = false;
              break;

            default:
              return;
          }

          _this.props.updateMemberRole(updatePayload);
        };

        _this.onChange = _this.onChange.bind(_this);
        return _this;
      }

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.searchValue !== this.props.searchValue && this.props.searchValue === "") {
          this.props.applySearchFilter(this.props.searchValue);
        }
      };

      class_1.prototype.componentDidMount = function () {
        this.props.updateSerachValue("");
        this.props.applySearchFilter("");
      };

      class_1.prototype.onChange = function (e) {
        this.props.updateSerachValue(e);
      };

      class_1.prototype.render = function () {
        var _this = this;

        var curUser;

        if (this.props.clubItem.clubPresence && this.props.clubItem.clubPresence.items) {
          curUser = this.props.clubItem.clubPresence.items.find(function (m) {
            return m.profile.xuid === _this.props.currentUser.xuid;
          });
        }

        var roster = this.props.members;
        var handlers = {
          search: this.search,
          filter: this.filter,
          updateRole: this.updateRole,
          reportUser: this.reportUser,
          onChange: this.onChange,
          inviteUser: this.inviteUser
        };
        return resolver.getClubRosterPanel(roster, handlers, curUser, this.props.searchValue);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var roster = clubsRedux.selectors.getClubRoster(state);
  return {
    members: FilterTypes(FilterGamerTag(roster, state.roster.searchFilter), state.roster.typeFilter),
    currentUser: state.authentication.currentUser,
    searchValue: state.roster.searchValue
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    applyTypeFilter: function applyTypeFilter(query) {
      return dispatch(ClubActions.clubRosterTypeFilter(query));
    },
    applySearchFilter: function applySearchFilter(query) {
      return dispatch(ClubActions.clubRosterSearchFilter(query));
    },
    updateMemberRole: function updateMemberRole(update) {
      return dispatch(ClubActions.clubRosterUpdateRole(update));
    },
    reportUser: function reportUser(payload) {
      return dispatch(ClubActions.clubRosterReportMember(payload));
    },
    updateSerachValue: function updateSerachValue(query) {
      return dispatch(ClubActions.clubRosterUpdateSearchValue(query));
    },
    inviteUser: function inviteUser(query) {
      return dispatch(ClubActions.clubRosterInviteUser(query));
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreRosterContainer(LoadingState, EmptyState, resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreRosterContainer(LoadingState, EmptyState, resolver));
}