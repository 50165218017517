import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import {
    CoreFeedItemViewer,
    IFeedItemSocialActionHandlers,
    IFeedItemAdminActionHanders,
    IFeedItemViewerResolver,
} from "@xbox/social-views";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { withCurrentUserContext } from "../CurrentUserContext";
import { FeedItemViewer } from "../../components/feeditems/detailedView/FeedItemViewer";

function getFeedItemContainer(
    socialActions: IFeedItemSocialActionHandlers,
    adminActions: IFeedItemAdminActionHanders,
    submitComment: (commentText: string) => void,
    isModerator: boolean,
    isCommentLocked: boolean,
    handleBackButton: () => void,
    fetchCommentLock: (item: ActivityFeed.FeedItem) => void,
    item?: ActivityFeed.FeedItem,
    commentStatus?: any
): JSX.Element {
    if (!item) {
        return (<div />);
    }
    return (
        <FeedItemViewer
            item={item}
            commentStatus={commentStatus}
            socialActions={socialActions}
            adminActions={adminActions}
            isCommentLocked={isCommentLocked}
            isModerator={isModerator}
            submitComment={submitComment}
            handleBackButton={handleBackButton}
            fetchCommentLock={fetchCommentLock}
        />
    );
}

const FeedModalResolver: IFeedItemViewerResolver = {
    getFeedItemContainer: getFeedItemContainer
};

export default withClubSelectionContext(withCurrentUserContext(CoreFeedItemViewer(FeedModalResolver)));
