import styled from "styled-components";
import { media } from "../../common/Generic.SnapPoints";
import { Button } from "../../common/generic/Button";
import { Icon } from "../../common/GenericElements";

export const MarginDiv50L20B = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const PostCards = styled.div`
    display: flex;
`;

export const AnalyticsRowDiv = styled.div`
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
`;

export const AnalyticsDiv = styled.div`
    flex: 1;
`;

export const SocialItemsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
`;

export const AnalyticsLocaleDiv = styled.div`
    text-align: center;
    ${media.menuOpenSmall`
            flex: auto;
            text-align: left;
    `}
    ${media.xSmall`
            width: 80%;
    `}
`;

export const TopPostContanier = styled.div`
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
`;

export const PostItemContanier = styled.div`
    min-width: 250px;
    flex: 1;
    :not(:last-of-type) {
        margin-right: 5px;
    }
`;

export const PostItemCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    margin-bottom: 10px !important;
    color: ${props => props.theme.foregroundMain};
    background: ${props => props.theme.backgroundMain};
    border-right: 1px solid ${props => props.theme.borderSecondary};

    ${media.xSmall`padding: 5px;`}
    ${media.small`padding: 10px;`}
    ${media.medium`padding: 15px;`}
`;

export const ImgTextContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5em;
    max-height: 3em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${media.medium`font-size: 18px;`}
    ${media.menuOpenSmall`font-size: 16px;`}
    ${media.small`font-size: 13px;`}
`;

export const TextPostContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${media.medium`font-size: 18px;`}
    ${media.menuOpenSmall`font-size: 16px;`}
    ${media.small`
        font-size: 13px;
        -webkit-line-clamp: 6;
        height: 8.8em;
        margin-bottom: 18px;
    `}
`;

export const MediaContainer = styled.div`
    border-radius: 8px;
    max-width: 760px;
    margin-bottom: 10px;
    width: 100%;
`;

export const PinnedIcon = styled(Icon) `
    margin-left: -20px;
    margin-top: -10px;
    position: absolute;
    transform: scale(-1,1);
    z-index: 2;
`;

export const ExportButton = styled(Button) `
    margin-left: 20px;
`;

export const FirstLineContainer = styled.div`
    max-width: 300px;
    min-width: 250px;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const SummaryContainer = FirstLineContainer.extend`
    color: ${props => props.theme.foregroundMain};
    background-color: ${props => props.theme.backgroundDisabled};
    user-select: none;
    text-align: center;
`;

export const TableRow = FirstLineContainer.extend`
    margin-top: 30px;
    cursor: pointer;

    .Gamerpic {
        height: 25px;
        width: 25px;
        border-radius: 10px;
    }

    .Gamerpic-Info {
        height: 20px;
    }
`;

export const SummaryData = styled.h2`
    margin-bottom: 0;
    padding-top: 0.3em;
`;

export const LayoutWrapper = styled.div`
    margin-top: 20px;
`;

export const StatDiv = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
`;

export const StatSpan = styled.span`
    background: ${props => props.theme.borderSecondary};
    border-radius: 4;
    padding: 5;
`;

export const Watermark = styled.div`
    font-size: 2.5rem;
    opacity: 0.9;
    z-index: 1;
    text-align: center;
    margin: 4em 0;
    user-select: none;
    white-space: nowrap;
    ${media.medium`font-size: 2.5rem;`}
    ${media.menuOpenSmall`font-size: 2rem; white-space: normal;`}
    ${media.small`font-size: 1.75rem;`}
`;

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const ItemWrapper = styled.div`
    // 80% won't work for everything, but looks necessary for Feed Engagement Locale

    flex: auto;
    min-height: 80%;
    margin: 0 5px;

    .recharts-legend-wrapper{
        background: ${props => props.theme.backgroundMain};
    }
`;

export const LegendTableCell = styled.td`
    border: 1px solid black;
    font-size: 12px;
    text-align: center;
    padding: 0 5px;
`;

export const LegendColorTableCell = LegendTableCell.extend`
    width: 25px;
`;
