import * as React from "react";
import { Col, Row } from "antd";
import { FeedCommentItem, edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { ICommentItemAdminActionHanders, ICommentResolver, CoreCommentContainer } from "@xbox/social-views";
import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";
import AdminCommentActionsContainer from "./AdminCommentActionsContainer";
import { withCurrentUserContext } from "../../containers/CurrentUserContext";
import { withClubSelectionContext } from "../../containers/ClubSelectionContext";
import { GetDateTimeDiff } from "../../Utils";
import ProfileCard from "../../components/profileCard/ProfileCard";
import Util from "../../common/Util";

function getCommentContainer(item: FeedCommentItem, adminActions: ICommentItemAdminActionHanders, showAdminAction: boolean) {
    let gamerPicItem: any = (
        <FeedItemStyles.CommentGamerpic src={edsImageResizer(Util.ensureSSLImage(item.author.displayPicRaw), EdsImageSize._100x100)} />
    );

    let gamerTagItem: any = (
        <Col xs={12} sm={12} md={12} lg={6} xl={6}><FeedItemStyles.CommentGamerTag>{item.author.gamertag}</FeedItemStyles.CommentGamerTag></Col>
    );

    return (
        <FeedItemStyles.CommentItemCard className="CommentContainer">
            <ProfileCard person={item.author} key={item.author.gamertag} element={gamerPicItem}/>
            <FeedItemStyles.CommentContainer>
                <Row>
                    <ProfileCard person={item.author} key={item.author.gamertag} element={gamerTagItem}/>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={18} xl={18}><FeedItemStyles.CommentDateContainer>{GetDateTimeDiff(new Date(item.date))}</FeedItemStyles.CommentDateContainer></Col>
                </Row>
                <Row>
                    <FeedItemStyles.CommentTextContainer dangerouslySetInnerHTML={{__html: item.text}}/>
                </Row>
            </FeedItemStyles.CommentContainer>
            <AdminCommentActionsContainer item={item} adminActions={adminActions} showAdminAction={showAdminAction} />
        </FeedItemStyles.CommentItemCard>);
}

const CommentReslver: ICommentResolver = {
    getCommentContainer: getCommentContainer
};

export default withCurrentUserContext(withClubSelectionContext(CoreCommentContainer(CommentReslver)));
