"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaHubActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var MediaHubActions;

(function (MediaHubActions) {
  // Fetch/search media items
  MediaHubActions.fetchScreenshots = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS");
  MediaHubActions.fetchScreenshotsInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS_INPROGRESS");
  MediaHubActions.fetchScreenshotsCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS_COMPLETED");
  MediaHubActions.fetchGameclips = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS");
  MediaHubActions.fetchGameclipsInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS_INPROGRESS");
  MediaHubActions.fetchGameclipsCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS_COMPLETED"); // Upload media items

  MediaHubActions.uploadScreenshot = redux_ts_simple_1.createAction("MEDIA::MEDIA_UPLOAD_SCREENSHOT");
  MediaHubActions.uploadCustomPic = redux_ts_simple_1.createAction("MEDIA::MEDIA_UPLOAD_CUSTOM_PIC");
  MediaHubActions.uploadGameclip = redux_ts_simple_1.createAction("MEDIA::MEDIA_UPLOAD_GAMECLIP");
  MediaHubActions.uploadMediaInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_UPLOAD_INPROGRESS");
  MediaHubActions.uploadMediaCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_UPLOAD_COMPLETED"); // delete items

  MediaHubActions.deleteScreenshot = redux_ts_simple_1.createAction("MEDIA::MEDIA_DELETE_SCREENSHOT");
  MediaHubActions.deleteGameClip = redux_ts_simple_1.createAction("MEDIA::MEDIA_DELETE_GAMECLIP"); // get items metadata

  MediaHubActions.fetchScreenshotsMetadata = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS_METADATA");
  MediaHubActions.fetchScreenshotsMetadataInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS_METADATA_INPROGRESS");
  MediaHubActions.fetchScreenshotsMetadataCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_SCREENSHOTS_METADATA_COMPLETED");
  MediaHubActions.fetchGameclipsMetadata = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS_METADATA");
  MediaHubActions.fetchGameclipsMetadataInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS_METADATA_INPROGRESS");
  MediaHubActions.fetchGameclipsMetadataCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_FETCH_GAMECLIPS_METADATA_COMPLETED"); // media items info

  MediaHubActions.getUsersMediaTitles = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_TITLES");
  MediaHubActions.getUsersMediaTitlesInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_TITLES_INPROGRESS");
  MediaHubActions.getUsersMediaTitlesCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_TITLES_COMPLETED");
  MediaHubActions.getUsersQuota = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_QUOTA");
  MediaHubActions.getUsersQuotaInProgress = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_QUOTA_INPROGRESS");
  MediaHubActions.getUsersQuotaCompleted = redux_ts_simple_1.createAction("MEDIA::MEDIA_DATA_USER_QUOTA_COMPLETED");
})(MediaHubActions = exports.MediaHubActions || (exports.MediaHubActions = {}));