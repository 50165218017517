import * as React from "react";
import { TextToHTMLInputStyled } from "./Input.Styles";

interface TextToHTMLInputProps {
    onConfirm: (payload: string) => void;
    placeholder: string;
    submitButton?: (props: any) => JSX.Element;
    clearButton?: (props: any) => JSX.Element;
}

export const processTextToHTML = (rawText: string) => {
    // Translate line breaks
    let resultString = rawText.replace(/\n/g, "</br>");

    // URLIFY
    // convert urls to hyperlinks
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let urlified = resultString.replace(urlRegex, function(url: string) {
        // add Http if not at begining
        let trueLink = url.indexOf("http") !== 0 ? "http://" + url : url;
        return url.link(trueLink);
    });

    return urlified;
};

const TextToHTMLInput: React.SFC<TextToHTMLInputProps> = (props: TextToHTMLInputProps) => {

    const process = (text: string) => {
        // Check if an empty string is being passed
        if (props.onConfirm && text.replace(/\s/g, "") !== "") {
            let resultText = processTextToHTML(text);
            props.onConfirm(resultText);
        }
    };

    return (
        <TextToHTMLInputStyled
            placeholder={props.placeholder}
            onPressEnter={process}
            submitButton={props.submitButton}
            clearButton={props.clearButton}
        />
    );
};

export default TextToHTMLInput;
