"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorLevel = exports.EventAction = exports.Area = void 0;
var Area;

(function (Area) {
  Area["AddUser"] = "AddUser";
  Area["AuthorizeWithSisu"] = "AuthorizeWithSisu";
  Area["FinishSignIn"] = "FinishSignIn";
  Area["GetAnonymousTokenAndSignature"] = "GetAnonymousTokenAndSignature";
  Area["GetAnonymousXtoken"] = "GetAnonymousXtoken";
  Area["GetDefaultUser"] = "GetDefaultUser";
  Area["GetDtoken"] = "GetDtoken";
  Area["GetMsaTicket"] = "GetMsaTicket";
  Area["GetTokenAndSignature"] = "GetTokenAndSignature";
  Area["GetTtoken"] = "GetTtoken";
  Area["GetXtoken"] = "GetXtoken";
  Area["LoadDeviceIdentity"] = "LoadDeviceIdentity";
  Area["LoadDefaultUser"] = "LoadDefaultUser";
  Area["LoadSessionInfo"] = "LoadSessionInfo";
  Area["LoadTokensForUser"] = "LoadTokensForUser";
  Area["InitializeTokenStack"] = "InitializeTokenStack";
  Area["NsalDb"] = "NsalDb";
  Area["NsalDb_LoadDefaultNsal"] = "NsalDb_LoadDefaultNsal";
  Area["NsalDb_LoadTitleNsal"] = "NsalDb_LoadTitleNsal";
  Area["SignIn"] = "SignIn";
  Area["SignOut"] = " SignOut";
  Area["RefreshDtoken"] = "RefreshDtoken";
  Area["RefreshTtoken"] = "RefreshTtoken";
  Area["RefreshUser"] = "RefreshUser";
  Area["RefreshXtoken"] = "RefreshXtoken";
  Area["TryAddFirstUserSilently"] = "TryAddFirstUserSilently";
  Area["WriteTicketSet"] = "WriteTicketSet";
  Area["XalAddUser"] = "XalAddUser";
  Area["XalAnonymousGetTokenAndSignatureAsync"] = "XalAnonymousGetTokenAndSignatureAsync";
  Area["XalGetMaxUsers"] = "XalGetMaxUsers";
  Area["XalGetMsaTicketForAdditionalScope"] = "XalGetMsaTicketForAdditionalScope";
  Area["XalPlatformWebFinishUrlAsync"] = "XalPlatformWebFinishUrlAsync";
  Area["XalResumeMsaForWeb"] = "XalResumeMSAForWeb";
  Area["XalResumeSisuForWeb"] = "XalResumeSisuForWeb";
  Area["XalSignOutUser"] = "XalSignOutUser";
  Area["XalSignOutUserIsPresent"] = "XalSignOutUserIsPresent";
  Area["XalTryAddFirstUserSilently"] = "XalTryAddFirstUserSilently";
  Area["XalUserGetTokenAndSignatureAsync"] = "XalUserGetTokenAndSignatureAsync";
  Area["XalUserResolveTokenIssueAsync"] = "XalUserResolveTokenIssueAsync";
})(Area = exports.Area || (exports.Area = {}));

var EventAction;

(function (EventAction) {
  EventAction["ActionStarted"] = "ActionStarted";
  EventAction["ActionCompleted"] = "ActionCompleted";
  EventAction["MsaSignInCanceled"] = "MsaSignInCanceled";
  EventAction["NewUserSwitchUser"] = "NewUserSwitchUser";
  EventAction["SisuWebVeto"] = "SisuWebVeto";
  EventAction["VetoResolutionCanceled"] = "VetoResolutionCanceled";
  EventAction["WelcomeBackCanceledInvalidToken"] = "WelcomeBackCanceledInvalidToken";
  EventAction["WelcomeBackCanceledValidToken"] = "WelcomeBackCanceledValidToken";
  EventAction["WelcomeBackSwitchUser"] = "WelcomeBackSwitchUser";
})(EventAction = exports.EventAction || (exports.EventAction = {}));

var ErrorLevel;

(function (ErrorLevel) {
  ErrorLevel["Info"] = "Info";
  ErrorLevel["Warning"] = "Warning";
  ErrorLevel["Error"] = "Error";
  ErrorLevel["Critical"] = "Critical";
})(ErrorLevel = exports.ErrorLevel || (exports.ErrorLevel = {}));