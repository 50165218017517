import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { Modal, Select } from "antd";
import * as _ from "lodash";
import {
    ReduxState,
    ClubActions,
    ClubSettingsPayload,
    ClubMoveChannelPayload
} from "@xbox/social-redux";

import * as Styles from "../../containers/clubadmin/ClubSettingsContainer/ClubSettingsContainer.Styles";
import { ClubSettingsComboBox } from "../clubs/ClubSettingComboBox";
import { Localize, LocalizeHTML } from "../../common/translation/Localize";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon } from "../../common/GenericElements";
import { SettingsModal, SettingsButton, SaveButton, NameEditorInput } from "./ChannelPicker.Styles";
import { Button } from "../../common/generic/Button";
import * as ClubStyles from "../../components/clubs/Club.Styles";

const confirm = Modal.confirm;
const Option = Select.Option;

export enum ChannelType {
    ChatChannels = "ChatChannel",
    FeedChannel = "FeedChannel"
}

interface ChannelPickerSettingsContainerProps {
    isVisible: boolean;
    appliedChannel?: ClubHub.Channel;
    closeModal: any;
    club?: ClubHub.Club;
    channelType: ChannelType;
}

interface ChannelPickerSettingsStateProps {
    channels?: ClubHub.Channel[];
}

interface ChannelPickerSettingsDispatchProps {
    selectChannel: (channel: ClubHub.Channel) => void;
    updateSetting: (update: ClubSettingsPayload) => void;
    moveChannel: (update: ClubMoveChannelPayload) => void;
    removeChannel: (deleteChannel: ClubHub.DeleteChannel) => void;
}

interface ChannelPickerSettingsState {
    showNameEditor: boolean;
    channelName: string;
}

type ChannelPickerSettingsProps = ChannelPickerSettingsContainerProps & ChannelPickerSettingsStateProps & ChannelPickerSettingsDispatchProps;

const DefaultChannelName = "default";

class ChannelPickerSettings extends React.Component<ChannelPickerSettingsProps, ChannelPickerSettingsState> {

    constructor(props: ChannelPickerSettingsProps) {
        super(props);
        this.state = {
            showNameEditor: false,
            channelName: this.props.appliedChannel ? this.props.appliedChannel.name : ""
        };
    }

    componentWillReceiveProps(nextProps: ChannelPickerSettingsProps) {
        if (nextProps.appliedChannel && nextProps.appliedChannel !== this.props.appliedChannel) {
            this.setState({ channelName: nextProps.appliedChannel.name });
        }
    }

    closeModal = () => {
        this.setState({ showNameEditor: false });
        this.props.closeModal();
    }

    updateSetting = (updatedPropertyPath: any, updatedProperty: string, value: any) => {
        if (!this.props.club) {
            return;
        }

        let settingsPayload: ClubSettingsPayload = {
            clubId: this.props.club.details.id,
            channelId: this.props.appliedChannel ? this.props.appliedChannel.id : "",
            update: {
                modifiedFields: [updatedProperty],
                requestContract: {}
            },
            updatedProperty: updatedPropertyPath,
            updatedValue: value
        };

        if (settingsPayload.update) {
            settingsPayload.update.requestContract[updatedProperty] = value;
        }
        this.props.updateSetting(settingsPayload);
    }

    showDeleteConfirm = () => {
        if (!this.props.appliedChannel) {
            return;
        }

        const deleteChannel = (channelId: string) => {
            if (!this.props.club) {
                return;
            }

            let deleteData: ClubHub.DeleteChannel = {
                clubId: this.props.club.details.id,
                channelId: channelId,
            };
            this.props.removeChannel(deleteData);
            this.props.closeModal();
            if (this.props.channels) {
                this.props.selectChannel(this.props.channels[0]);
            }
        };

        let channel = this.props.appliedChannel;
        confirm({
            title: Localize("channels.deleteChannel"),
            content: <LocalizeHTML id="channels.deleteConfirmText" options={{ channelName: channel.name }} />,
            okText: Localize("generic.okay"),
            okType: "danger",
            cancelText: Localize("generic.cancel"),
            onOk() {
                deleteChannel(channel.id);
            }
        });
    }

    getChannelIndex = () => {
        if (!this.props.club || !this.props.appliedChannel) {
            return 0;
        }
        let index = this.props.appliedChannel.id;
        return this.props.club.feedChannels.findIndex(c => c.id === index);
    }

    updateChannelName = (e: any) => {
        this.setState({ channelName: e.target.value });
    }

    changeChannelName = () => {
        this.setState({ showNameEditor: false });
        this.updateSetting("", "channelName", this.state.channelName);
    }

    whoCanChatOnChange = (e: any) => {
        let path = ["chatChannels", this.getChannelIndex(), "settings", "write", "value"];
        this.updateSetting(path, "whoCanChat", e);
    }

    whoCanViewChatOnChange = (e: any) => {
        let path = ["chatChannels", this.getChannelIndex(), "settings", "view", "value"];
        this.updateSetting(path, "whoCanViewChat", e);
    }

    feedWhoCanViewOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "view", "value"];
        this.updateSetting(path, "whoCanViewFeed", e);
    }

    feedWhoCanPostOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "post", "value"];
        this.updateSetting(path, "whoCanPostToFeed", e);
    }

    feedWhoCanPinPostOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "pinPost", "value"];
        this.updateSetting(path, "pinPost", e);
    }

    feedWhoCanPostMediaFromDeviceOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "postMediaFromDevice", "value"];
        this.updateSetting(path, "postMediaFromDevice", e);
    }

    feedWhoCanPostMediaFromCapturesOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "postMediaFromXblLibrary", "value"];
        this.updateSetting(path, "postMediaFromXblLibrary", e);
    }

    feedWhoCanPostStoreLinkOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "postStoreLink", "value"];
        this.updateSetting(path, "postStoreLink", e);
    }

    feedWhoCanPostWebLinkOnChange = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "postWebLink", "value"];
        this.updateSetting(path, "postWebLink", e);
    }

    feedWhoCanSchedulePost = (e: any) => {
        let path = ["feedChannels", this.getChannelIndex(), "settings", "schedulePost", "value"];
        this.updateSetting(path, "schedulePost", e);
    }

    feedMoveChannel = (e: any) => {
        if (!this.props.club || !this.props.appliedChannel) {
            return;
        }

        let settingsPayload: ClubMoveChannelPayload = {
            clubId: this.props.club.details.id,
            group: e,
            channelId: this.props.appliedChannel.id
        };

        this.props.moveChannel(settingsPayload);
    }

    getFeedMoveChannelSetting = (channel: ClubHub.Channel, channelGroupsName: string[]) => {
        if (!channelGroupsName || !channelGroupsName.length || channel.isDefault) {
            return null;
        }
        return (
            <div>
                <p>{Localize("clubSettings.moveChannelLabel")}</p>
                <ClubStyles.DropDown
                    onChange={this.feedMoveChannel}
                    value={channel.group && channel.group.name || DefaultChannelName}
                >
                    {_.uniq(channelGroupsName).map((channelGroupName: string) =>
                        <Option key={channelGroupName} value={channelGroupName}>
                            {channelGroupName}
                        </Option>
                    )}
                </ClubStyles.DropDown>
            </div>
        );
    }

    getEditChannelNameBtn = (channel: ClubHub.Channel) => {
        if (channel.isDefault) {
            return null;
        }
        return this.state.showNameEditor
            ? <SaveButton buttonType="primary" onClick={() => this.changeChannelName()}>{Localize("generic.save")}</SaveButton>
            : (
                <SettingsButton iconOnly={true} onClick={() => this.setState({ showNameEditor: true })}>
                    <Icon type={Glyphs.Edit} />
                </SettingsButton>
            );
    }

    public render() {
        let channel = this.props.appliedChannel;
        if (!channel || !this.props.club) {
            return null;
        }

        let channels: string[] = this.props.club.feedChannels.map(c => c.group ? c.group.name : DefaultChannelName);

        let feedSettings = this.props.appliedChannel ? (
            <div>
                <Styles.SettingsColumn>
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanViewLabel")}
                        handler={this.feedWhoCanViewOnChange}
                        item={this.props.appliedChannel.settings.view}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPostLabel")}
                        handler={this.feedWhoCanPostOnChange}
                        item={this.props.appliedChannel.settings.post}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPinPostLabel")}
                        handler={this.feedWhoCanPinPostOnChange}
                        item={this.props.appliedChannel.settings.pinPost}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPostMediaFromDeviceLabel")}
                        handler={this.feedWhoCanPostMediaFromDeviceOnChange}
                        item={this.props.appliedChannel.settings.postMediaFromDevice}
                    />
                    <br />
                    {this.getFeedMoveChannelSetting(channel, channels)}
                </Styles.SettingsColumn>
                <Styles.SettingsColumn>
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPostStoreLinkLabel")}
                        handler={this.feedWhoCanPostStoreLinkOnChange}
                        item={this.props.appliedChannel.settings.postStoreLink}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPostWebLinkLabel")}
                        handler={this.feedWhoCanPostWebLinkOnChange}
                        item={this.props.appliedChannel.settings.postWebLink}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanPostMediaFromCaptures")}
                        handler={this.feedWhoCanPostMediaFromCapturesOnChange}
                        item={this.props.appliedChannel.settings.postMediaFromXblLibrary}
                    />
                    <br />
                    <ClubSettingsComboBox
                        label={Localize("clubSettings.feedWhoCanSchedulePost")}
                        handler={this.feedWhoCanSchedulePost}
                        item={this.props.appliedChannel.settings.schedulePost}
                    />
                </Styles.SettingsColumn>
            </div>
        ) : null;

        let chatSettings = this.props.appliedChannel ?
            (
                <div>
                    <Styles.SettingsColumn>
                        <ClubSettingsComboBox
                            label={Localize("clubSettings.chatSettingLabel")}
                            handler={this.whoCanChatOnChange}
                            item={this.props.appliedChannel.settings.write}
                        />
                        <br />

                        <ClubSettingsComboBox
                            label={Localize("clubSettings.chatViewSettingLabel")}
                            handler={this.whoCanViewChatOnChange}
                            item={this.props.appliedChannel.settings.view}
                        />
                        <br />
                    </Styles.SettingsColumn>
                </div>
            ) : null;

        let settingTitle = (
            <div>
                {this.props.channelType === ChannelType.FeedChannel
                    ? Localize("clubSettings.feedTitle")
                    : Localize("clubSettings.chatTitle")
                } -&nbsp;
                {this.state.showNameEditor
                    ? <NameEditorInput
                        defaultValue={this.state.channelName}
                        onChange={this.updateChannelName}
                        onPressEnter={() => this.changeChannelName()}
                    />
                    : <span>{this.state.channelName}</span>
                }
                {this.getEditChannelNameBtn(channel)}
            </div>
        );

        return (
            <SettingsModal
                visible={this.props.isVisible}
                onCancel={this.closeModal}
                title={settingTitle}
            >
                {this.props.channelType === ChannelType.FeedChannel ? feedSettings : chatSettings}
                {this.props.appliedChannel
                    && !this.props.appliedChannel.isDefault
                    && (
                        <div style={{ clear: "both" }}>
                            <Button buttonType="danger" onClick={() => this.showDeleteConfirm()} style={{ marginTop: 10 }}>{Localize("channels.deleteChannel")}</Button>
                        </div>
                    )
                }
            </SettingsModal>
        );
    }
}

const mapStateToProps = (state: ReduxState): ChannelPickerSettingsStateProps => {
    return {
        channels: state.channels.feedChannels
    };
};

const mapDispatchToProps = (dispatch: any): ChannelPickerSettingsDispatchProps => {
    return {
        selectChannel: (channel: ClubHub.Channel) => dispatch(ClubActions.clubChannelSelected(channel)),
        updateSetting: (update: ClubSettingsPayload) => dispatch(ClubActions.updateClubSettings(update)),
        moveChannel: (update: ClubMoveChannelPayload) => dispatch(ClubActions.moveChannel(update)),
        removeChannel: (deleteChannel: ClubHub.DeleteChannel) => dispatch(ClubActions.deleteClubChannel(deleteChannel))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelPickerSettings);
