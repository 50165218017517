"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubInviteQDenormalizer = exports.clubInviteQNormalizer = exports.clubModerationNormalizer = exports.clubModerationDenormalizer = exports.clubDenormalizer = exports.clubNormalizer = void 0;

var normalizr_1 = require("normalizr");

var getClubId = function getClubId(value) {
  return value.details.id;
}; // schema definitions


var club = new normalizr_1.schema.Entity("club", undefined, {
  idAttribute: getClubId
});
var clubList = [club];

var clubNormalizer = function clubNormalizer(clubs) {
  return normalizr_1.normalize(clubs, clubList);
};

exports.clubNormalizer = clubNormalizer;

var clubDenormalizer = function clubDenormalizer(clubsIds, entities) {
  return normalizr_1.denormalize(clubsIds, clubList, entities);
};

exports.clubDenormalizer = clubDenormalizer;
var report = new normalizr_1.schema.Entity("reportedItems", undefined, {
  idAttribute: "reportId"
});
var reportedItems = [report];

var clubModerationNormalizer = function clubModerationNormalizer(reports) {
  return normalizr_1.normalize(reports, reportedItems);
};

exports.clubModerationNormalizer = clubModerationNormalizer;

var clubModerationDenormalizer = function clubModerationDenormalizer(contentIds, entities) {
  return normalizr_1.denormalize(contentIds, reportedItems, entities);
};

exports.clubModerationDenormalizer = clubModerationDenormalizer;
var invite = new normalizr_1.schema.Entity("requests", undefined, {
  idAttribute: "xuid"
});
var inviteList = [invite];

var clubInviteQNormalizer = function clubInviteQNormalizer(invites) {
  return normalizr_1.normalize(invites, inviteList);
};

exports.clubInviteQNormalizer = clubInviteQNormalizer;

var clubInviteQDenormalizer = function clubInviteQDenormalizer(xuids, entities) {
  return normalizr_1.denormalize(xuids, inviteList, entities);
};

exports.clubInviteQDenormalizer = clubInviteQDenormalizer;