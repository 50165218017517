import { Card, Checkbox } from "antd";
import styled from "styled-components";
import { ThemedSelect } from "../../common/Generic.Styles";

export const DropDown = ThemedSelect.extend`
    font-size: 18px;
    width: 300px;
    margin-right: 15px !important;
    max-width: 95%;
`;

export const CheckBox = styled(Checkbox)`
    width: 300px;
`;

export const ClubMember = styled(Card)`
    overflow: hidden;
    height: 300px;
    width: 100%;
    margin-bottom: 5px !important;
`;
