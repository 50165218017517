import * as React from "react";
import { Person } from "@xbox/social-core";
import { Icon } from "../../../common/GenericElements";
import { Divider } from "../../../common/generic/Divider";
import { Glyphs } from "../../../common/Generic.Icons";
import * as Styles from "../PeopleContainer.Styles";
import { Localize } from "../../../common/translation/Localize";

interface PersonAboutViewerProps {
    person?: Person;

}

interface PeopleContainerState {
}

export class PersonAboutViewer extends React.Component<PersonAboutViewerProps, PeopleContainerState> {
    constructor(props: PersonAboutViewerProps) {
        super(props);
    }

    render() {
        if (!this.props.person) {
            return null;
        }

        let bioInfo = this.props.person.detail!.bio
            ? (
                <div style={{paddingRight: 20}}>
                    {this.props.person.detail!.bio}
                </div>
            )
            : "Your Bio is building...";

        return (
            <div>
                <Styles.PersonColumn>
                    <Styles.PersonDivContainer>
                        <h1>{Localize("people.bioTitle")}</h1>
                        {bioInfo}
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />
                    <Styles.PersonDivContainer>
                    <h1>{Localize("people.linkedAccountsTitle")}</h1>

                        <div>Your linked account is building...</div>
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />
                </Styles.PersonColumn>

                <Styles.PersonColumn>
                    <Styles.PersonDivContainer>
                        <h1>{Localize("people.devicesTitle")}</h1>
                        <Styles.DevicesIcon>
                            <Icon type={Glyphs.Xenon} />
                            <Icon type={Glyphs.FacebookLogo} />
                            <Icon type={Glyphs.SmartGlassGamerScore} />
                            <Icon type={Glyphs.WindowsLogo} />
                        </Styles.DevicesIcon>
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />

                    <Styles.PersonDivContainer>
                        <h1>{Localize("people.gameShelfTitle")}</h1>
                        <div>Your game shelf is building...</div>
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />

                    <Styles.PersonDivContainer>
                        <h1>{Localize("people.wishListTitle")}</h1>
                        <div>Your wish list is building...</div>
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />

                    <Styles.PersonDivContainer>
                        <h1>{Localize("people.achievementsTitle")}</h1>
                        <div>Your achievements is building...</div>
                    </Styles.PersonDivContainer>
                    <Divider style={{ width: "50%" }} />
                </Styles.PersonColumn>
            </div>
        );
    }
}
