import * as React from "react";
import { User } from "@xbox/social-core";

export interface CurrentUserContext {
    isModerator: boolean;
    isOwner: boolean;
    user?: User;
}

export const CurrentUserContextProvider = React.createContext<CurrentUserContext>({
    isModerator: false,
    isOwner: false,
    user: undefined
});

// wire the context to the props of the
export function withCurrentUserContext(Component: any) {
    return (props: any) => {
        return (
            <CurrentUserContextProvider.Consumer>
                  {({ isModerator, user, isOwner }) => { return ( <Component {...props} user={user} isModerator={isModerator} isOwner={isOwner}/> ); }}
            </CurrentUserContextProvider.Consumer>
        );
    };
}
