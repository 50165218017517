import * as React from "react";
import { NavLink } from "react-router-dom";

import { ManagerMenu, ClubManageItem } from "./ClubManagerContainer.Styles";
import { Localize } from "../../common/translation/Localize";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon } from "../../common/GenericElements";

interface ClubManagerMenuProps {
    clubId: string;
}

export const ClubManagerMenu: React.SFC<ClubManagerMenuProps> = props => {

    let getNavigationItem = (glyph: Glyphs, stringId: string, innerAppLink: string): JSX.Element => {
        return (
            <ClubManageItem>
                <NavLink activeClassName="active" to={`/app/${innerAppLink}`} exact={true}>
                    <Icon type={glyph}/>{Localize(stringId)}
                </NavLink>
             </ClubManageItem>
        );
    };

    let getNavigationItemSubpages = (glyph: Glyphs, stringId: string, innerAppLink: string, subpageOptions: string[] ): JSX.Element => {
        // For Use with manage pages that have deeplinked subpages
        const checkSubPages = (match: any, location: any) => {
            if (!location) {
                return false;
            }
            let result = false;
            for (var i = 0; i < subpageOptions.length; i++) {
                if (location.pathname.indexOf(subpageOptions[i]) > -1 ) {
                    result = true;
                }
            }
            return result;
        };
        return (
            <ClubManageItem>
                <NavLink activeClassName="active" to={`/app/${innerAppLink}`} isActive={checkSubPages} >
                    <Icon type={glyph}/>{Localize(stringId)}
                </NavLink>
             </ClubManageItem>
        );
    };

    return (
        <ManagerMenu className="ClubManagerMenu">
            {getNavigationItem(Glyphs.Report, "clubMenu.modq", `${props.clubId}/manage/modq`)}
            {getNavigationItemSubpages(Glyphs.NowPlaying, "clubMenu.analytics", `${props.clubId}/manage/analytics/summary`, ["analytics/summary", "analytics/details"])}
            {getNavigationItem(Glyphs.Family, "clubMenu.clubroster", `${props.clubId}/manage/roster`)}
            {getNavigationItem(Glyphs.GameClip, "feedtools.myMedia", `${props.clubId}/manage/media`)}
            {getNavigationItem(Glyphs.Settings, "clubMenu.clubsettings", `${props.clubId}/manage/settings`)}
            {getNavigationItem(Glyphs.NewReleases, "feedtools.scheduledPosts", `${props.clubId}/manage/scheduled`)}
            {getNavigationItemSubpages(Glyphs.Poll, "feedtools.allPolls", `${props.clubId}/manage/allpolls/open`, ["allpolls/open", "allpolls/closed"])}
        </ManagerMenu>
    );
};
