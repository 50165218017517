"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tokenizeScopes = exports.MsaTicketSet = void 0;

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var Utils_1 = require("./Utils");

var EarlyTokenExpiration = 600000; // 10 mins

var MsaTicketSet =
/** @class */
function () {
  function MsaTicketSet(userId, refreshToken, foci, requestParams, scopedTickets, isWeb) {
    if (scopedTickets === void 0) {
      scopedTickets = new Map();
    }

    this.foci = foci;
    this.scopedTickets = scopedTickets;
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(userId));
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(refreshToken) || !!isWeb);
    this.userId = userId.toLowerCase();
    this.refreshTokenInternal = refreshToken;
    this.requestParamsInternal = requestParams;
  }

  MsaTicketSet.deserializeTicketSet = function (data, isWeb) {
    var parsedData = JSON.parse(data);

    if (!parsedData) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Failed to deserialize MsaTicketSet");
    }

    var requestParams;

    if (parsedData.request_params) {
      requestParams = new Map();

      for (var key in parsedData.request_params) {
        if (parsedData.request_params.hasOwnProperty(key)) {
          var element = parsedData.request_params[key];
          requestParams.set(key, element);
        }
      }
    }

    var scopedTickets = new Map();
    parsedData.access_tokens.forEach(function (token) {
      scopedTickets.set(token.scopes, {
        scopes: tokenizeScopes(token.scopes),
        expiryTime: token.xal_expires,
        ticket: token.access_token
      });
    });
    return new MsaTicketSet(parsedData.user_id, parsedData.refresh_token, parsedData.foci, requestParams, scopedTickets, isWeb);
  };

  MsaTicketSet.deserializeResponse = function (data) {
    return {
      accessToken: data.access_token,
      // expires_in is relative to now, in seconds. Date.now() is in milliseconds.
      accessTokenExpiryTime: data.expires_in ? Date.now() + 1000 * data.expires_in : undefined,
      refreshToken: data.refresh_token,
      scopes: tokenizeScopes(data.scope),
      userId: data.user_id.toLowerCase(),
      familyOfClientIdsValue: data.foci,
      error: data.error,
      errorDescription: data.error_description
    };
  };

  MsaTicketSet.deserializeFormResponse = function (form) {
    var accessToken = form.get("access_token");
    var refreshToken = form.get("refresh_token");
    var userId = form.get("user_id");
    var foci = form.get("foci");
    var error = form.get("error");
    var errorDescription = form.get("error_description");
    var code = form.get("code");
    var scopeString = form.get("scope");
    var scopes = tokenizeScopes(scopeString ? scopeString : "");
    var expiresInString = form.get("expires_in");
    var accessTokenExpiryTime;

    if (!Utils_1.isNullOrWhiteSpace(expiresInString)) {
      var expiresIn = parseInt(expiresInString, 10);
      accessTokenExpiryTime = Date.now() + 1000 * expiresIn;
    }

    return {
      accessToken: accessToken,
      accessTokenExpiryTime: accessTokenExpiryTime,
      refreshToken: refreshToken,
      scopes: scopes,
      userId: userId,
      familyOfClientIdsValue: foci,
      code: code,
      error: error,
      errorDescription: errorDescription
    };
  };

  MsaTicketSet.makeNewUserFromResponse = function (responseData, requestParams, isWeb) {
    if (!Utils_1.isNullOrWhiteSpace(responseData.error)) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to create MSA auth data with an error response.");
    } else if (!isWeb && Utils_1.isNullOrWhiteSpace(responseData.refreshToken)) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to create MSA auth data without a refresh token.");
    }

    var ticketSet = new MsaTicketSet(responseData.userId, responseData.refreshToken, responseData.familyOfClientIdsValue, requestParams, undefined, isWeb);
    var scopedTicket = ticketSet.updateScope(responseData.scopes, responseData.accessToken, responseData.accessTokenExpiryTime);
    return {
      scopedTicket: scopedTicket,
      ticketSet: ticketSet
    };
  };

  MsaTicketSet.isTicketExpired = function (ticket) {
    var effectiveNowInUtcUnixEpoch = Date.now() + EarlyTokenExpiration;
    var effectiveNow = new Date(effectiveNowInUtcUnixEpoch);
    return Utils_1.isExpired(ticket.expiryTime, effectiveNow);
  };

  Object.defineProperty(MsaTicketSet.prototype, "refreshToken", {
    get: function get() {
      return this.refreshTokenInternal;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MsaTicketSet.prototype, "requestParams", {
    get: function get() {
      return this.requestParamsInternal;
    },
    enumerable: false,
    configurable: true
  });

  MsaTicketSet.prototype.getTicket = function (scopes) {
    var lowercaseScopes = scopes.map(function (scope) {
      return scope.toLowerCase();
    }).join(" ");
    var scopedTicket = this.scopedTickets.get(lowercaseScopes);

    if (scopedTicket && !MsaTicketSet.isTicketExpired(scopedTicket)) {
      return scopedTicket;
    }

    return undefined;
  };

  MsaTicketSet.prototype.updateTicketSet = function (responseData, requestParams, isWeb) {
    if (!Utils_1.isNullOrWhiteSpace(responseData.error)) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to create MSA auth data with an error response.");
    } else if (!isWeb && Utils_1.isNullOrWhiteSpace(responseData.refreshToken)) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to create MSA auth data without a refresh token.");
    } else if (this.userId !== responseData.userId) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UnexpectedUserSignedIn, "The user was asked to sign into their account but instead signed into a different account.");
    }

    this.refreshTokenInternal = responseData.refreshToken;
    this.foci = responseData.familyOfClientIdsValue;

    if (requestParams) {
      this.requestParamsInternal = requestParams;
    }

    return this.updateScope(responseData.scopes, responseData.accessToken, responseData.accessTokenExpiryTime);
  };

  MsaTicketSet.prototype.serialize = function () {
    // Clear expired tokens so we don't waste space on invalid tokens
    this.clearExpiredTokens();
    var requestParamsObj = undefined;

    if (this.requestParams) {
      requestParamsObj = {};
      Array.from(this.requestParams.entries()).forEach(function (pair) {
        return requestParamsObj[pair["0"]] = pair["1"];
      });
    }

    var accessTokens = Array.from(this.scopedTickets.values()).map(function (scopedTicket) {
      return {
        access_token: scopedTicket.ticket,
        xal_expires: scopedTicket.expiryTime,
        scopes: scopedTicket.scopes.join(" ")
      };
    });
    var result = {
      user_id: this.userId,
      refresh_token: this.refreshToken,
      foci: this.foci,
      request_params: requestParamsObj,
      access_tokens: accessTokens
    };
    return JSON.stringify(result);
  };

  MsaTicketSet.prototype.updateScope = function (scopes, accessToken, expiry) {
    // We assume here that scopes is already lowercased because it's always coming from a response data object
    // which we always lowercase during construction.
    var scopedData = {
      expiryTime: expiry,
      scopes: scopes,
      ticket: accessToken
    };
    this.scopedTickets.set(scopes.join(" "), scopedData);
    return scopedData;
  };

  MsaTicketSet.prototype.clearExpiredTokens = function () {
    var _this = this;

    var expiredTickets = Array.from(this.scopedTickets.entries()).filter(function (pair) {
      return MsaTicketSet.isTicketExpired(pair["1"]);
    }).map(function (pair) {
      return pair["0"];
    });
    expiredTickets.forEach(function (key) {
      return _this.scopedTickets.delete(key);
    });
  };

  return MsaTicketSet;
}();

exports.MsaTicketSet = MsaTicketSet;

function tokenizeScopes(scopeString) {
  var lowercaseScopeString = decodeURIComponent(scopeString.toLowerCase());
  return lowercaseScopeString.split(" ");
}

exports.tokenizeScopes = tokenizeScopes;