import { Col, Row } from "antd";
import styled from "styled-components";
import { ImageFade } from "../../styles/ImageStyles";
import { Button } from "../../common/generic/Button";
import { ThemedSelect } from "../../common/Generic.Styles";

export const DetailsColumn = styled(Col)`
    min-width: 240px;
    max-width: 500px;
`;

export const Header = styled.h3`
    color: ${props => props.theme.foregroundMain};
`;

export const OptionsHeader = styled(Header)`
    padding-top: 30px;
`;

export const SwitchOptionsContainer = styled.div`
    display: flex;
    flex-flow: wrap;
`;

export const SwitchBlockDiv = styled.div`
    min-width: 200px;
    max-width: 250px;
    margin-bottom: 15px;
`;

export const OnOffSpan = styled.span`
    margin-left: 8px;
`;

export const Description = styled.p`
    font-size: 12px;
    max-width: 500px;
    color: ${props => props.theme.description};
`;

export const RelativeDiv = styled.div`
    position: relative;
`;

export const DeleteImageButton = styled(Button)`
    position: absolute !important;
    top: 5%;
    left: 5%;
`;

export const FitImage = styled.img`
    max-width: 100%;
    height: auto;
    max-height: 100%;
`;

export const ScreenItemImage = styled.img`
    margin: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;

export const ScreenItemContainer = styled.div`
    height: 120px;
    position: relative;
    border: 1px solid ${props => props.theme.blackBackground};
    background-color: ${props => props.theme.blackBackground};
`;

export const GameclipItemContainer = styled.div`
    height: 110px;
    position: relative;
    overflow: hidden;
    border: 1px solid ${props => props.theme.blackBackground};
    background-color: ${props => props.theme.blackBackground};
`;

export const NotificationImage = styled.img`
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export const UnderConstructionDiv = styled.div`
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 200px;
    border: 3px solid ${props => props.theme.redActive};
    padding: 10px;
`;

export const VScrollableDiv = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 450px;
`;

export const PlayedTitleImage = styled(ImageFade)`
    width: 25px;
    height: 25px;
    margin-right: 10px;
`;

export const StoreItemImage = styled(ImageFade)`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    float: left;
`;

export const PreviewWrapper = styled.div`
    align-items: center;
    display: flex;
    height: inherit;
    max-width: 50%;
    @media (min-width: 600px) {
        max-width: 33%;
    }
    @media (min-width: 768px) {
        max-width: 25%;
    }
    padding-top: 1%;
    padding-bottom: 1%;
`;

export const MediaManagerContainer = styled.div`
    align-items: center;
    display: flex;
    padding-top: 1%;
    padding-bottom: 1%;

    border-bottom: solid 1px ${props => props.theme.borderSecondary};
`;

export const MediaManagerButtons = styled.div`
    margin-left: 2%;
    margin-bottom: auto;
    margin-top: auto;
    width: 80%;
`;

export const XblItemTitle = styled.div`
    font-weight: bold;
    width: 100%;
    overflow: auto;
`;

export const TabsDiv = styled.div`
    .ant-tabs {
        padding: 15px 0;
        background: transparent;
        color: ${props => props.theme.foregroundMain};
    }

    .ant-tabs-bar {
        margin: 0px;
    }

    .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content {
        margin: 0 1px;
        padding: 16px;
        background: ${props => props.theme.backgroundMain};
    }

    .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        background: ${props => props.theme.backgroundMain};
        color: ${props => props.theme.foregroundSecondary};
    }

    .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        color: ${props => props.theme.activeTabColor};
        background-color: ${props => props.theme.activeTabBgColor};
    }
`;

export const CreatePostTabsDiv = TabsDiv.extend`
    .ant-tabs {
        padding: 0;
    }

    .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content {
        padding: 16px 0 0 0;
    }
`;

export const GameRow = styled(Row)`
    margin-top: 8px 0px;
    max-width: 500px;
    background: ${props => props.theme.borderSecondary};
`;

export const BottomMarginRow = styled(Row)`
    margin-bottom: 10px;
`;

export const OptionWrapper = styled(Row)`
    display: flex !important;
    margin-bottom: 10px;
`;

export const BallotOptionButton = styled(Button)`
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${props => props.theme.signOutBtn};
    color: ${props => props.theme.deleteBtn};
    @media (min-width: 768px) {
        min-height: 2.215em;
    }
     &:hover:enabled, &:focus:enabled, &:active:enabled {
    outline: 2px solid ${props => props.theme.blackText};
    border: 2px solid ${props => props.theme.whiteText};
    border-radius: 5px;
    background-color: ${props => props.theme.signOutBtn};
    color: ${props => props.theme.deleteBtn};
}
`;

export const BallotSelect = ThemedSelect.extend`
    max-width: 500px;
    width: 100%;
`;

export const StretchWidthContainer = styled.div`
    width: 100%;
    width: stretch;
`;

export const FlexDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FlexCol = FlexDiv.extend`
    flex-direction: column;
`;

export const FlexRow = FlexDiv.extend`
    flex-direction: row;
`;

export const ImageUploadPreviewContainer = FlexCol.extend`
    width: 33%;
`;

export const FilterMenuDiv = styled.div`
    min-width: 200px;
`;

export const FilterTextHeader = styled.div`
    margin: 4px 10px 10px 0;
`;
