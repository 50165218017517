import * as React from "react";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { AuthActions, ReduxState, clubsRedux } from "@xbox/social-redux";
import styled from "styled-components";
import { Localize } from "../common/translation/Localize";
import { Glyphs } from "../common/Generic.Icons";
import { Button } from "../common/generic/Button";
import { LogOutIcon } from "./usersettings/UserSettingsModal.Styles";
import AuthValidator from "./AuthValidator";

const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

type INoAccessProps = NoAccessProps & NoAccessDispatchProps;

interface NoAccessProps {
    cookies: Cookies;
}

interface NoAccessDispatchProps {
    signOut: (cookies: Cookies) => void;
}

class NoAdminAccess extends React.Component<INoAccessProps> {

    constructor(props: INoAccessProps) {
        super(props);
    }

    onSignOut = () => {
        this.props.signOut(this.props.cookies);
    }

    render() {
        return (
            <CenteredDiv>
                <h1>{Localize("generic.accessDenied")}</h1>
                <h2>{Localize("warnings.noAccess")}</h2>
                <Button buttonType="danger" onClick={this.onSignOut}>
                    <LogOutIcon type={Glyphs.SignOut} />{Localize("generic.signOut")}
                </Button>
            </CenteredDiv>
        );
    }
}

const mapStateToProps = (state: ReduxState): any => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any): NoAccessDispatchProps => {
    return {
        signOut: (cookies: Cookies) => {
            dispatch(clubsRedux.actions.clearState());
            dispatch(AuthActions.signOut(cookies));
        }
    };
};

export default AuthValidator(withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(NoAdminAccess)));
