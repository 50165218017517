import * as React from "react";
import { AnalyticsData } from "./AnalyticsData";
import * as Styles from "./Analytics.Styles";
import { GenericLoading } from "../../common/GenericElements";

export class SummaryLayout extends AnalyticsData {

    componentWillMount() {
        if (this.props.month) {
            this.props.OnMonthChanged("");
        }
    }

    render() {
        if (this.props.isLoading) {
            return <GenericLoading/>;
        }

        return (
            <Styles.LayoutWrapper>
                <Styles.AnalyticsRowDiv>
                    {this.props.stats && <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleStatsSummary", this.props.stats)}</Styles.AnalyticsDiv>}
                    {this.props.topEngagers && <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleTopEngagers", this.props.topEngagers)}</Styles.AnalyticsDiv>}
                </Styles.AnalyticsRowDiv>
                {this.props.feedEngament && this.constructRow("analyticsView.titleFeedEngagement", this.props.feedEngament)}
                {this.props.topPosts && this.constructRow("analyticsView.titleTopPosts", this.props.topPosts)}
                <Styles.AnalyticsRowDiv>
                    {this.props.gender ? <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleGender", this.props.gender)}</Styles.AnalyticsDiv> : null}
                    {this.props.age ? <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleAge", this.props.age)}</Styles.AnalyticsDiv> : null}
                    {this.props.locale ? <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleLocale", this.props.locale)}</Styles.AnalyticsDiv> : null}
                    {this.props.feedEngagementByLocale ? <Styles.AnalyticsDiv>{this.constructRow("analyticsView.titleEngagementByLocale", this.props.feedEngagementByLocale)}</Styles.AnalyticsDiv> : null}
                </Styles.AnalyticsRowDiv>
            </Styles.LayoutWrapper>
        );
    }
}
