import * as React from "react";
import Moment from "react-moment";
import { Chat } from "@xbox/social-core";
import { edsImageResizer, EdsImageSize } from "@xbox/social-core";
import { Col, Row } from "antd";
import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";

export interface ChatMessageProps {
    item: Chat.Message;
}

export const ChatItem: React.SFC<ChatMessageProps> = props => {
    return (
        <FeedItemStyles.FeedItemCard>
            <Col xs={4} sm={3} md={2} lg={4} xl={2}>
                <FeedItemStyles.Gamerpic src={edsImageResizer(props.item.sender.displayPicRaw, EdsImageSize._100x100)} />
            </Col>
            <Col xs={20} sm={21} md={22} lg={20} xl={22}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}><FeedItemStyles.GamerTag>{props.item.sender.gamertag}</FeedItemStyles.GamerTag></Col>
                    <Col xs={12} sm={12} md={12} lg={18} xl={18}><FeedItemStyles.DateContainer><Moment fromNow={true}>{props.item.messageTime}</Moment></FeedItemStyles.DateContainer></Col>
                </Row>
                <Row>
                    <FeedItemStyles.TextContainer>{/* need regular expression to strip html tags */props.item.message.replace(/(<([^>]+)>)/ig, "")}</FeedItemStyles.TextContainer>
                </Row>
            </Col>
        </FeedItemStyles.FeedItemCard>);
};
