"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefreshUser = void 0;

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var XboxToken_1 = require("../../XboxToken");

var SignInBase_1 = require("./SignInBase");

var RefreshUser =
/** @class */
function (_super) {
  __extends(RefreshUser, _super);

  function RefreshUser(telemetryClient, components, webView, user, httpClient) {
    var _this = _super.call(this, ITelemetryClient_1.Area.RefreshUser, telemetryClient, components, webView, httpClient, false, // forceCallSisuForXtoken
    user.webAccountId) || this;

    _this.components = components;
    _this.preexistingUser = user;
    Utils_1.assert(!!_this.preexistingUser && !Utils_1.isNullOrWhiteSpace(_this.preexistingUser.webAccountId));
    return _this;
  }

  RefreshUser.prototype.serveRequestFromChild = function () {
    var signInEndpoint = this.signInEndpoint;
    Utils_1.assert(!!signInEndpoint);
    var xtoken = this.components.xboxCache.getToken(XboxToken_1.IdentityType.Xtoken, signInEndpoint.RelyingParty, this.signInEndpoint.SubRelyingParty ? this.signInEndpoint.SubRelyingParty : "", signInEndpoint.TokenType, true, // hasSignInDisplayClaims
    this.preexistingUser.webAccountId);

    if (xtoken.isValid) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Refresh user called with valid token in cache.");
      this.succeedWithXtoken(xtoken);
      return true;
    }

    return false;
  };

  RefreshUser.prototype.handleXtokenResult = function (result) {
    return __awaiter(this, void 0, void 0, function () {
      var data, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , result];

          case 1:
            data = _a.sent();
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            this.step.advance(SignInBase_1.SignInBaseStep.Done);
            this.fail(error_1);
            return [2
            /*return*/
            ];

          case 3:
            Utils_1.assert(data.xtoken.isValid);

            if (!Utils_1.isNullOrWhiteSpace(data.url)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Refresh user received a SISU URL from Xtoken call. It is being ignored.");
            }

            this.succeedWithXtoken(data.xtoken);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  RefreshUser.prototype.isUserDuplicated = function (webAccountId) {
    return false;
  };

  return RefreshUser;
}(SignInBase_1.SignInBase);

exports.RefreshUser = RefreshUser;