var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { ActivityFeed } from "@xbox/social-core";
import { feedViewerRedux } from "@xbox/social-redux";
import { FeedItemUtils } from "../../Utils";

function coreFeedItemViewer(feedItemViewerResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.castVote = function (item, choice) {
          var request = {
            ballotChoice: choice,
            ballotChoiceUri: item.pollData.choices[choice].voteUri,
            itemRoot: item.itemRoot,
            feedItemId: item.feedItemId
          };

          _this.props.castVote(request);
        };

        _this.handleLikeClick = function (item, hasLiked) {
          var request = {
            itemRoot: item.itemRoot,
            like: hasLiked,
            itemType: item.activityItemType
          };

          _this.props.updateLike(request);
        };

        _this.deleteItem = function (item, shouldBan) {
          if (shouldBan) {
            _this.actOnItem(ActivityFeed.AdminActionTypes.DeleteAndBan, item);
          } else {
            _this.actOnItem(ActivityFeed.AdminActionTypes.Delete, item);
          }
        };

        _this.addToModQ = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.AddToModQ, item);
        };

        _this.report = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Report, item);
        };

        _this.pin = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Pin, item);
        };

        _this.unpin = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.UnPin, item);
        };

        _this.loadComments = function () {
          var item = _this.props.feedItem;

          if (!item || item.comments && item.comments.items.length && item.comments.cursor == null) {
            return Promise.resolve(false);
          }

          var payload = {
            cursor: item.comments ? item.comments.cursor : "",
            postId: item.itemRoot,
            numberOfItems: 10
          };

          _this.props.loadComments(payload);

          return Promise.resolve(true);
        };

        _this.exportComments = function (itemId) {
          _this.props.exportComments(itemId);
        };

        _this.disableComment = function (item) {
          _this.props.clearComments(item.feedItemId);

          _this.setState({
            isCommentLocked: !_this.state.isCommentLocked
          });

          _this.actOnItem(ActivityFeed.AdminActionTypes.DisableComment, item);
        };

        _this.enableComment = function (item) {
          _this.setState({
            isCommentLocked: !_this.state.isCommentLocked
          });

          _this.actOnItem(ActivityFeed.AdminActionTypes.EnableComment, item);
        };

        _this.fetchCommentLock = function (item) {
          var lockPayload = {
            itemRoot: item.itemRoot
          };

          _this.props.loadCommentLock(lockPayload);
        };

        _this.actOnItem = function (action, item) {
          var payload = {
            action: action,
            clubId: _this.props.clubId ? _this.props.clubId : "",
            xuid: item.authorInfo.xuid,
            item: item
          };

          _this.props.actOnFeedItem(payload);
        };

        _this.submitComment = function (commentText) {
          if (!commentText || !_this.props.currentUser) {
            return;
          }

          var item = _this.props.feedItem;

          if (!item) {
            return;
          }

          var payload = {
            feedItemId: item.itemRoot,
            xuid: _this.props.currentUser.xuid,
            commentText: commentText
          };

          _this.props.postComment(payload);
        };

        _this.handleBackButton = function () {
          if (_this.props.backButtonOverride) {
            _this.props.backButtonOverride();

            return;
          }

          if (_this.props.match.params.channelId) {
            _this.props.nagivateToFeedChannel(_this.props.clubId, _this.props.match.params.channelId);
          } else {
            _this.props.nagivateToFeed(_this.props.clubId);
          }
        };

        _this.state = {
          isCommentLocked: _this.props.isCommentLocked || false
        };
        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        if (!this.props.feedItem && this.props.match) {
          var params = this.props.match.params;
          var itemsId = void 0;

          if (this.props.clubId) {
            itemsId = [FeedItemUtils.constuctFeedItemId(params.formattedFeedId, this.props.clubId, params.channelId)];
          } else {
            itemsId = [FeedItemUtils.constuctAggregateFeedItemId(params.formattedFeedId, params.feedItemSuffix)];
          }

          var request = {
            itemsId: itemsId
          };
          this.props.fetchAndSelectFeedItem(request);
        }
      };

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.isCommentLocked !== prevProps.isCommentLocked) {
          this.setState({
            isCommentLocked: this.props.isCommentLocked || false
          });
        }

        if (this.props.feedItem && (!prevProps.feedItem || this.props.feedItem.comments !== prevProps.feedItem.comments)) {
          this.loadComments();
          this.fetchCommentLock(this.props.feedItem);
        }
      };

      class_1.prototype.render = function () {
        var adminActions = {
          delete: this.deleteItem,
          pin: this.pin,
          unpin: this.unpin,
          report: this.report,
          addToModQ: this.addToModQ,
          disableComment: this.disableComment,
          enableComment: this.enableComment,
          exportComments: this.exportComments
        };
        var socialActions = {
          like: this.handleLikeClick,
          loadMoreComments: this.loadComments,
          vote: this.castVote
        };
        return feedItemViewerResolver.getFeedItemContainer(socialActions, adminActions, this.submitComment, this.props.isModerator, this.state.isCommentLocked, this.handleBackButton, this.fetchCommentLock, this.props.feedItem, this.props.commentStatus);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var feedItemData = feedViewerRedux.selectors.getSelectedActivityFeedItem(state);
  return {
    currentUser: feedItemData.currentUser,
    feedItem: feedItemData.selectedFeedItem,
    isCommentLocked: feedItemData.isCommentLocked,
    commentStatus: state.feedViewer.commentStatus
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchAndSelectFeedItem: function fetchAndSelectFeedItem(payload) {
      return dispatch(feedViewerRedux.actions.fetchAndSelectFeedItem(payload));
    },
    updateLike: function updateLike(likePayload) {
      return dispatch(feedViewerRedux.actions.likeFeedItem(likePayload));
    },
    castVote: function castVote(votePayload) {
      return dispatch(feedViewerRedux.actions.voteFeedItem(votePayload));
    },
    actOnFeedItem: function actOnFeedItem(payload) {
      return dispatch(feedViewerRedux.actions.adminActOnItem(payload));
    },
    loadComments: function loadComments(payload) {
      return dispatch(feedViewerRedux.actions.fetchFeedComments(payload));
    },
    loadCommentLock: function loadCommentLock(payload) {
      return dispatch(feedViewerRedux.actions.loadCommentLock(payload));
    },
    exportComments: function exportComments(feedItemId) {
      return dispatch(feedViewerRedux.actions.exportFeedComments(feedItemId));
    },
    postComment: function postComment(payload) {
      return dispatch(feedViewerRedux.actions.postFeedComment(payload));
    },
    clearComments: function clearComments(feedItemId) {
      return dispatch(feedViewerRedux.actions.clearComments(feedItemId));
    },
    nagivateToFeed: function nagivateToFeed(clubId) {
      return dispatch(push("/app/" + clubId + "/discussion/feed"));
    },
    nagivateToFeedChannel: function nagivateToFeedChannel(clubId, channelId) {
      return dispatch(push("/app/" + clubId + "/discussion/feed/" + channelId));
    }
  };
};

export function CoreFeedItemViewer(feedItemViewer) {
  return connect(mapStateToProps, mapDispatchToProps)(coreFeedItemViewer(feedItemViewer));
}