var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from "react";
import { connect } from "react-redux";
import { ImagePickerActions as Actions, ClubActions, getImagePicker, CancellationTokenSource, mediaHubRedux } from "@xbox/social-redux";
import { UpdateImageTypes } from "@xbox/social-core";

function coreImagePickerContainer(resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.imageSelected = function (image) {
          if (!image) {
            return;
          }

          if (_this.props.updateImageType === UpdateImageTypes.profile) {
            var path = ["details", "profile", "displayImageUrl", "value"];

            _this.updateProfile(path, "displayImageUrl", image.large);
          } else {
            var path = ["details", "profile", "backgroundImageUrl", "value"];

            _this.updateProfile(path, "backgroundImageUrl", image.large);
          }

          _this.props.hidePicker();
        }; // custom upload


        _this.handleUpload = function (data, file, type) {
          var cancellationSource = new CancellationTokenSource();

          if (data) {
            var payload = __assign(__assign({
              file: file,
              cancellationToken: cancellationSource.getToken()
            }, data), {
              associationId: _this.props.clubId,
              customPicType: type
            });

            _this.props.uploadMediaItems(payload);
          }
        };

        _this.updateProfile = function (updatedPropertyPath, updatedProperty, value) {
          if (_this.props.clubId) {
            var settingsPayload = {
              clubId: _this.props.clubId,
              update: {
                modifiedFields: [updatedProperty],
                requestContract: {}
              },
              updatedProperty: updatedPropertyPath,
              updatedValue: value
            };

            if (settingsPayload.update) {
              settingsPayload.update.requestContract[updatedProperty] = value;
            }

            _this.props.updateProfile(settingsPayload);
          }
        };

        _this.getImages = function (forcefetch) {
          if (!(_this.props.isVisible || _this.props.hasImages) && forcefetch) {
            return;
          }

          var payload = {
            clubId: _this.props.clubId,
            xuid: _this.props.xuid,
            types: _this.props.allowedSources,
            updateImageType: _this.props.updateImageType
          };

          _this.props.getImages(payload);
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.getImages(false);
      };

      class_1.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.updateImageType !== this.props.updateImageType) {
          this.getImages(true);
        }
      };

      class_1.prototype.render = function () {
        var handlers = {
          imageSelected: this.imageSelected,
          uploadCustomImage: this.handleUpload
        };
        return resolver.getImagePickerPanel(handlers, this.props);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return getImagePicker(state);
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getImages: function getImages(payload) {
      return dispatch(Actions.getImages(payload));
    },
    updateProfile: function updateProfile(update) {
      return dispatch(ClubActions.updateClubSettings(update));
    },
    uploadMediaItems: function uploadMediaItems(data) {
      return dispatch(mediaHubRedux.actions.uploadCustomPic(data));
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreImagePickerContainer(resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreImagePickerContainer(resolver));
}