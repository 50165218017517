import styled from "styled-components";
import Input from "./Input";

export const TextToHTMLInputStyled = styled(Input)`
    border: 1px solid ${props => props.theme.blueHover};
    background-color: transparent;
    color: ${props => props.theme.foregroundMain};
    outline: none;
    word-wrap: break-word;
`;
