import styled from "styled-components";

export const MediaOffStyle = styled.div`
    min-height: 100px;
    max-height: 200px;
`;

export const ShowManageCookiesStyle = styled.button`
    height: 16px;
    background: transparent;
    font-size: 12px;
    border: 0;
    margin: -3px 46px;
    overflow: hidden;
`;
