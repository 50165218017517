"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commentsDenormalizer = exports.commentsNormalizer = void 0;

var normalizr_1 = require("normalizr");

var comment = new normalizr_1.schema.Entity("comments");
var commentsList = [comment];

var commentsNormalizer = function commentsNormalizer(comments) {
  return normalizr_1.normalize(comments, commentsList);
};

exports.commentsNormalizer = commentsNormalizer;

var commentsDenormalizer = function commentsDenormalizer(commentIds, entities) {
  return normalizr_1.denormalize(commentIds, commentsList, entities);
};

exports.commentsDenormalizer = commentsDenormalizer;