"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsoleLogTelemetryClient = void 0;

var Utils_1 = require("./Utils");

var ConsoleLogTelemetryClient =
/** @class */
function () {
  function ConsoleLogTelemetryClient() {}

  ConsoleLogTelemetryClient.prototype.instrumentAppActivation = function () {
    Utils_1.xalTrace(Utils_1.TraceLevel.Information, "App activated");
  };

  ConsoleLogTelemetryClient.prototype.instrumentAppAction = function (area, action, user) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Information, "XAL::" + area + "::" + action + " " + (user ? user.xuid : ""));
  };

  ConsoleLogTelemetryClient.prototype.instrumentAppError = function (area, message, level, errorType) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Error, "XAL::" + area + "::" + level + " " + errorType + " " + message);
  };

  ConsoleLogTelemetryClient.prototype.instrumentApiCalled = function (area, user) {
    Utils_1.xalTrace(Utils_1.TraceLevel.Information, "XAL::" + area + " API called. User: " + (user ? user.xuid : ""));
  };

  return ConsoleLogTelemetryClient;
}();

exports.ConsoleLogTelemetryClient = ConsoleLogTelemetryClient;