"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClubHubService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var typings_1 = require("../../typings");

var utils_1 = require("../../utils");

var ClubAdminQueries_1 = require("../../queries/ClubAdminQueries");

var ClubDetailsQueries_1 = require("../../queries/ClubDetailsQueries");

var ClubHubService =
/** @class */
function () {
  function ClubHubService(xblService, userManager, xblGraphQlService) {
    var _this = this;

    this.xblService = xblService;
    this.userManager = userManager;
    this.xblGraphQlService = xblGraphQlService;

    this.batchUpdateRoster = function (clubId, xuids, add) {
      if (add) {
        return _this.xblGraphQlService.mutate({
          mutation: ClubAdminQueries_1.BatchAddToRoster,
          variables: {
            clubId: clubId,
            userIds: xuids
          }
        });
      } else {
        return _this.xblGraphQlService.mutate({
          mutation: ClubAdminQueries_1.BatchRemoveToRoster,
          variables: {
            clubId: clubId,
            userIds: xuids
          }
        });
      }
    };
  }

  ClubHubService.prototype.getMyClubs = function () {
    return __awaiter(this, void 0, void 0, function () {
      var experimentsHeaders, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            experimentsHeaders = this.getClubExperimentsHeaders();
            return [4
            /*yield*/
            , this.xblGraphQlService.query({
              query: ClubDetailsQueries_1.MyClubsQuery,
              context: {
                headers: experimentsHeaders
              }
            })];

          case 1:
            result = _a.sent();
            return [2
            /*return*/
            , result];
        }
      });
    });
  };

  ClubHubService.prototype.getClubDetails = function (clubId) {
    return __awaiter(this, void 0, void 0, function () {
      var experimentsHeaders, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            experimentsHeaders = this.getClubExperimentsHeaders();
            return [4
            /*yield*/
            , this.xblGraphQlService.query({
              query: ClubDetailsQueries_1.ClubDetailsQuery,
              variables: {
                clubId: clubId
              },
              context: {
                headers: experimentsHeaders
              },
              fetchPolicy: "network-only",
              errorPolicy: "all"
            })];

          case 1:
            result = _a.sent();
            return [2
            /*return*/
            , result];
        }
      });
    });
  };

  ClubHubService.prototype.getClubFeedChannels = function (clubId) {
    return __awaiter(this, void 0, void 0, function () {
      var experimentsHeaders, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            experimentsHeaders = this.getClubExperimentsHeaders();
            return [4
            /*yield*/
            , this.xblGraphQlService.query({
              query: ClubDetailsQueries_1.ClubFeedChannelsQuery,
              variables: {
                clubId: clubId
              },
              context: {
                headers: experimentsHeaders
              },
              fetchPolicy: "no-cache",
              errorPolicy: "all"
            })];

          case 1:
            result = _a.sent();
            return [2
            /*return*/
            , result];
        }
      });
    });
  };

  ClubHubService.prototype.getClubSafetyThreshold = function (clubId) {
    return __awaiter(this, void 0, void 0, function () {
      var clubHeaders;
      return __generator(this, function (_a) {
        clubHeaders = this.getClubHubHeaders(7);
        return [2
        /*return*/
        , this.xblService.sendRequest({
          url: "https://clubhub.xboxlive.com/clubs/ids(" + clubId + ")/decoration/detail",
          method: http_1.Http.HttpRequestMethod.Get,
          headers: clubHeaders
        })];
      });
    });
  };

  ClubHubService.prototype.updateClubSetting = function (clubId, update) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: ClubAdminQueries_1.UpdateClubSetting,
              variables: {
                clubId: clubId,
                input: update
              }
            })];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  ClubHubService.prototype.deleteClub = function (clubId) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, currentUser, body;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = this.getClubHubHeaders(1);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            body = {
              "actor": "owner",
              "deleteAfter": null
            };
            return [2
            /*return*/
            , this.xblService.sendRequest({
              url: "https://clubaccounts.xboxlive.com/clubs/clubid(" + clubId + ")/suspension/owner",
              method: http_1.Http.HttpRequestMethod.Put,
              headers: headers,
              data: JSON.stringify(body)
            })];
        }
      });
    });
  };

  ClubHubService.prototype.cancelDeleteClub = function (clubId) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = this.getClubHubHeaders(1);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [2
            /*return*/
            , this.xblService.sendRequest({
              url: "https://clubaccounts.xboxlive.com/clubs/clubid(" + clubId + ")/suspension/owner",
              method: http_1.Http.HttpRequestMethod.Delete,
              headers: headers
            })];
        }
      });
    });
  };

  ClubHubService.prototype.createClubChannel = function (createChannel) {
    var createChannelHeader = utils_1.GetGenericHeaders(2);
    return this.xblService.sendRequest({
      url: "https://clubprofile.xboxlive.com/clubs/" + createChannel.clubId + "/channels",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(createChannel.channelData),
      headers: createChannelHeader
    });
  };

  ClubHubService.prototype.deleteClubChannel = function (deleteChannel) {
    var createChannelHeader = utils_1.GetGenericHeaders(2);
    return this.xblService.sendRequest({
      url: "https://clubprofile.xboxlive.com/clubs/" + deleteChannel.clubId + "/channels/" + deleteChannel.channelId + " ",
      method: http_1.Http.HttpRequestMethod.Delete,
      headers: createChannelHeader
    });
  };

  ClubHubService.prototype.getModerationQueue = function (clubId) {
    return this.xblGraphQlService.query({
      query: ClubAdminQueries_1.GetModerationQueueQuery,
      variables: {
        clubId: clubId
      },
      fetchPolicy: "network-only"
    });
  };

  ClubHubService.prototype.actOnModerationItems = function (clubId, request, shouldDelete) {
    return this.xblGraphQlService.mutate({
      mutation: ClubAdminQueries_1.RemoveModerationItemQuery,
      variables: {
        clubId: clubId,
        input: {
          reportedItemsDeleteRequests: request
        },
        delete: shouldDelete
      }
    });
  };

  ClubHubService.prototype.getInviteQueue = function (clubId) {
    return this.xblGraphQlService.query({
      query: ClubAdminQueries_1.GetClubInviteQueue,
      variables: {
        clubId: clubId
      },
      fetchPolicy: "network-only"
    });
  };

  ClubHubService.prototype.getUserStats = function (request) {
    return this.xblGraphQlService.query({
      query: ClubAdminQueries_1.GetUserStats,
      variables: {
        input: request
      }
    });
  };

  ClubHubService.prototype.updateRoster = function (clubId, xuid, add) {
    if (add) {
      return this.xblGraphQlService.mutate({
        mutation: ClubAdminQueries_1.AddToRoster,
        variables: {
          clubId: clubId,
          userId: xuid
        }
      });
    } else {
      return this.xblGraphQlService.mutate({
        mutation: ClubAdminQueries_1.RemoveFromRoster,
        variables: {
          clubId: clubId,
          userId: xuid
        }
      });
    }
  };

  ClubHubService.prototype.addClubMemberRole = function (clubId, xuids, role) {
    return this.xblGraphQlService.mutate({
      mutation: ClubAdminQueries_1.AddClubRole,
      variables: {
        clubId: clubId,
        userId: xuids,
        roles: role
      }
    });
  };

  ClubHubService.prototype.deleteClubMemberRole = function (clubId, xuids, role) {
    return this.xblGraphQlService.mutate({
      mutation: ClubAdminQueries_1.RemoveClubRole,
      variables: {
        clubId: clubId,
        userId: xuids,
        role: role
      }
    });
  };

  ClubHubService.prototype.moveChannel = function (channelId, clubId, groupName) {
    return this.xblGraphQlService.mutate({
      mutation: ClubAdminQueries_1.MoveChannel,
      variables: {
        clubId: clubId,
        channelId: channelId,
        groupName: groupName
      }
    });
  };

  ClubHubService.prototype.reorderChannel = function (clubId, newPosition, channelId) {
    var headers = utils_1.GetGenericHeaders(2);
    var payload = {
      NewPosition: newPosition
    };
    return this.xblService.sendRequest({
      url: "https://clubprofile.xboxlive.com/clubs/" + clubId + "/channels/" + channelId + "/order",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(payload),
      headers: headers
    });
  };

  ClubHubService.prototype.reorderChannelGroup = function (clubId, newPosition, groupName) {
    var headers = utils_1.GetGenericHeaders(2);
    var payload = {
      ChannelGroupName: groupName,
      NewPosition: newPosition
    };
    return this.xblService.sendRequest({
      url: "https://clubprofile.xboxlive.com/clubs/" + clubId + "/channelgroups/order",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(payload),
      headers: headers
    });
  };

  ClubHubService.prototype.updateSafetyThreshold = function (clubId, newValue) {
    var headers = utils_1.GetGenericHeaders(2);
    var payload = {
      requestContract: {
        safetyThreshold: newValue
      },
      modifiedFields: ["safetyThreshold"]
    };
    return this.xblService.sendRequest({
      url: "https://clubprofile.xboxlive.com/clubs/" + clubId + "/profile",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(payload),
      headers: headers
    });
  };

  ClubHubService.prototype.changeName = function (clubId, name, channelId) {
    return __awaiter(this, void 0, void 0, function () {
      var headers, currentUser, data, payload;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            headers = this.getClubHubHeaders(1);
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _a.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            data = {
              Name: name,
              Method: "ChangeName"
            };

            if (channelId) {
              payload = {
                ModifiedFields: ["ChannelName"],
                RequestContract: {
                  ChannelName: name
                }
              };
              return [2
              /*return*/
              , this.xblService.sendRequest({
                url: "https://clubprofile.xboxlive.com/clubs/" + clubId + "/channels/" + channelId,
                method: http_1.Http.HttpRequestMethod.Post,
                headers: headers,
                data: JSON.stringify(payload)
              })];
            } else {
              // change club name
              return [2
              /*return*/
              , this.xblService.sendRequest({
                url: "https://clubaccounts.xboxlive.com/clubs/clubid(" + clubId + ")",
                method: http_1.Http.HttpRequestMethod.Post,
                headers: headers,
                data: JSON.stringify(data)
              })];
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  ClubHubService.prototype.reportMemberToEnforcement = function (userId, item) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2
        /*return*/
        , this.xblGraphQlService.mutate({
          mutation: ClubAdminQueries_1.ReportMemberToEnforcement,
          variables: {
            userId: userId,
            input: item
          },
          fetchPolicy: "no-cache"
        })];
      });
    });
  };

  ClubHubService.prototype.getClubHubHeaders = function (version) {
    var clubHeaders = utils_1.GetGenericHeaders(version ? version : 4);
    clubHeaders["Content-Type"] = "application/json";
    return clubHeaders;
  };

  ClubHubService.prototype.getClubRosterHeaders = function () {
    var clubRosterHeaders = utils_1.GetGenericHeaders(1);
    return clubRosterHeaders;
  };

  ClubHubService.prototype.getClubExperimentsHeaders = function () {
    // TODO:
    // this shoud be read from the experiment service later
    return {
      "xbl-experiments": "officialclubs,officialclubswithpresence"
    };
  };

  ClubHubService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IUserManager)), __param(2, inversify_1.inject(typings_1.XSocialSymbols.IGraphQLClient)), __metadata("design:paramtypes", [Object, Object, Object])], ClubHubService);
  return ClubHubService;
}();

exports.ClubHubService = ClubHubService;