"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthConfig = exports.TitleType = exports.Environment = void 0;

var Constants_1 = require("./internal/Constants");

var XalInternalError_1 = require("./internal/errors/XalInternalError");

var XalInternalErrorType_1 = require("./internal/errors/XalInternalErrorType");

var Utils_1 = require("./internal/Utils");

var Environment;

(function (Environment) {
  Environment["Production"] = "Production";
  Environment["Dnet"] = "Dnet";
})(Environment = exports.Environment || (exports.Environment = {}));

var TitleType;

(function (TitleType) {
  TitleType[TitleType["ThirdParty"] = 0] = "ThirdParty";
  TitleType[TitleType["FirstParty"] = 1] = "FirstParty";
})(TitleType = exports.TitleType || (exports.TitleType = {}));

var AuthConfig =
/** @class */
function () {
  function AuthConfig(xalArgs, environment, deviceInfo, consentList) {
    if (consentList === void 0) {
      consentList = undefined;
    }

    this.environment = environment;
    this.deviceInfo = deviceInfo;
    this.clientId = xalArgs.clientId;
    this.titleId = xalArgs.titleId;
    this.titleType = xalArgs.isFirstParty ? TitleType.FirstParty : TitleType.ThirdParty;
    this.xboxLiveRelyingParty = "http://auth.xboxlive.com";
    this.xboxLiveEndpoint = "https://xboxlive.com";
    this.sandbox = "RETAIL";

    if (xalArgs.sandbox && xalArgs.sandbox.length !== 0) {
      this.sandbox = xalArgs.sandbox;
    }

    this.titleMgmtEndpoint = this.getEndpointPath("title.mgt", environment);
    this.deviceTokenEndpoint = this.getEndpointPath("device.auth", environment);
    this.titleTokenEndpoint = this.getEndpointPath("title.auth", environment);
    this.userTokenEndpoint = this.getEndpointPath("user.auth", environment);
    this.xtokenEndpoint = this.getEndpointPath("xsts.auth", environment);
    this.sisuEndpoint = this.getEndpointPath("sisu", environment);
    this.userTokenSiteName = this.getEndpointPath("open-user.auth", environment, false);
    this.userSignInScopes = this.getUserSignInScopes(consentList, this.titleType, this.userTokenSiteName);
    this.msaLoginEndpoint = this.getMsaEndpointPath("login", environment);
    this.msaRedirectUri = xalArgs.msaRedirectUri ? xalArgs.msaRedirectUri : this.msaLoginEndpoint + Constants_1.OauthDesktopPath;
    this.sisuRedirectUri = xalArgs.sisuRedirectUri;
  }

  AuthConfig.prototype.getEndpointPath = function (serviceName, environment, appendScheme) {
    if (appendScheme === void 0) {
      appendScheme = true;
    }

    var scheme = "";

    if (appendScheme) {
      scheme = "https://";
    }

    return "" + scheme + serviceName + this.environmentPathString(environment) + ".xboxlive.com";
  };

  AuthConfig.prototype.environmentPathString = function (environment) {
    switch (environment) {
      case Environment.Dnet:
        return ".dnet";

      default:
        return "";
    }
  };

  AuthConfig.prototype.getUserSignInScopes = function (consentList, titleType, tokenSiteName) {
    Utils_1.assert(tokenSiteName.length !== 0);
    var scopes = [];

    if (titleType === TitleType.FirstParty) {
      scopes.push("service::" + tokenSiteName + "::MBI_SSL");

      if (consentList && consentList.length > 0) {
        throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "First party titles should not provide a consent list.");
      }
    } else if (titleType === TitleType.ThirdParty) {
      scopes.push("xbl.signin");

      if (consentList && consentList.length > 0) {
        for (var _i = 0, consentList_1 = consentList; _i < consentList_1.length; _i++) {
          var scope = consentList_1[_i];

          if (Utils_1.isNullOrWhiteSpace(scope)) {
            throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Consent list cannot contain null or empty strings.");
          }

          var scopeLower = scope.toLowerCase();

          if (scopeLower !== "offline_access") {
            scopes.push(scopeLower);
          }
        }
      }
    } else {
      Utils_1.assert(false, "Invalid title type");
    } // NOXAL:WEB: Required override for web


    if (this.deviceInfo.isWeb) {
      scopes = ["xboxlive.signin", "xboxlive.offline_access"];
    }

    return scopes;
  };

  AuthConfig.prototype.getMsaEndpointPath = function (serviceName, environment) {
    var env = "";

    switch (environment) {
      case Environment.Dnet:
        env = "-int";
        break;

      default:
        break;
    }

    return "https://" + serviceName + ".live" + env + ".com";
  };

  return AuthConfig;
}();

exports.AuthConfig = AuthConfig;