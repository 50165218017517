"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Nsal = exports.NsalHostNameType = exports.NsalProtocol = void 0;

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var Utils_1 = require("./Utils");

var NsalProtocol;

(function (NsalProtocol) {
  NsalProtocol["Unknown"] = "unknown";
  NsalProtocol["Http"] = "http";
  NsalProtocol["Https"] = "https";
  NsalProtocol["Tcp"] = "tcp";
  NsalProtocol["Udp"] = "udp";
  NsalProtocol["Wss"] = "wss";
})(NsalProtocol = exports.NsalProtocol || (exports.NsalProtocol = {}));

var NsalHostNameType;

(function (NsalHostNameType) {
  NsalHostNameType["Unknown"] = "unknown";
  NsalHostNameType["Fqdn"] = "fqdn";
  NsalHostNameType["Wildcard"] = "wildcard";
  NsalHostNameType["Ip"] = "ip";
  NsalHostNameType["Cidr"] = "cidr";
})(NsalHostNameType = exports.NsalHostNameType || (exports.NsalHostNameType = {}));

function isValidHostType(hostType) {
  return hostType === NsalHostNameType.Cidr || hostType === NsalHostNameType.Fqdn || hostType === NsalHostNameType.Ip || hostType === NsalHostNameType.Wildcard;
}

function isValidProtocol(protocol) {
  return protocol === NsalProtocol.Http || protocol === NsalProtocol.Https || protocol === NsalProtocol.Tcp || protocol === NsalProtocol.Udp || protocol === NsalProtocol.Wss;
}

var Nsal =
/** @class */
function () {
  function Nsal(nsalResponse) {
    var sanitizedEndpoints = nsalResponse.EndPoints.filter(function (endpoint) {
      return !Utils_1.isNullOrWhiteSpace(endpoint.Host) && !Utils_1.isNullOrWhiteSpace(endpoint.RelyingParty) && !Utils_1.isNullOrWhiteSpace(endpoint.TokenType) && isValidHostType(endpoint.HostType) && isValidProtocol(endpoint.Protocol);
    });
    sanitizedEndpoints.push({
      Host: "*.xboxlive.com",
      HostType: NsalHostNameType.Wildcard,
      Port: 80,
      Protocol: NsalProtocol.Wss,
      RelyingParty: "http://xboxlive.com",
      SignaturePolicyIndex: 0,
      TokenType: "JWT",
      Path: "",
      SubRelyingParty: ""
    });
    this.cidrEndpoints = sanitizedEndpoints.filter(function (endpoint) {
      return endpoint.HostType === NsalHostNameType.Cidr;
    }).map(function (endpoint) {
      return endpoint;
    });
    this.fqdnEndpoints = sanitizedEndpoints.filter(function (endpoint) {
      return endpoint.HostType === NsalHostNameType.Fqdn;
    }).map(function (endpoint) {
      return endpoint;
    });
    this.ipEndpoints = sanitizedEndpoints.filter(function (endpoint) {
      return endpoint.HostType === NsalHostNameType.Ip;
    }).map(function (endpoint) {
      return endpoint;
    });
    this.wildcardEndpoints = sanitizedEndpoints.filter(function (endpoint) {
      return endpoint.HostType === NsalHostNameType.Wildcard;
    }).map(function (endpoint) {
      return endpoint;
    });
    this.signaturePolicies = nsalResponse.SignaturePolicies;
  }

  Nsal.prototype.getEndpoint = function (uri) {
    Utils_1.assert(!!uri);
    var protocol = uri.protocol.slice(0, -1);
    var port = getPort(protocol, uri.port === "" ? 0 : parseInt(uri.port, 10));
    var hostNameWithoutEnv = uri.hostname;
    hostNameWithoutEnv = hostNameWithoutEnv.replace(".dnet", "");
    var ipaAddr = IpAddress.parse(hostNameWithoutEnv);

    if (ipaAddr) {
      return undefined;
    } else {
      var endpoint = fqdnLookupHelper(this.fqdnEndpoints, protocol, hostNameWithoutEnv, port, uri.pathname);

      if (endpoint) {
        return endpoint;
      }

      endpoint = wildcardLookupHelper(this.wildcardEndpoints, protocol, hostNameWithoutEnv, port, uri.pathname);

      if (endpoint) {
        return endpoint;
      }
    }

    return undefined;
  };

  Nsal.prototype.getSignaturePolicy = function (policyIndex) {
    return this.signaturePolicies[policyIndex];
  };

  return Nsal;
}();

exports.Nsal = Nsal;

function getPort(protocol, portRaw) {
  if (!portRaw || portRaw === 0) {
    switch (protocol) {
      case NsalProtocol.Https:
        return 443;

      case NsalProtocol.Http:
      case NsalProtocol.Wss:
        return 80;

      default:
        throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Must specify port when protocol is not HTTP or HTTPS.");
    }
  }

  return portRaw;
}

var IpAddress =
/** @class */
function () {
  function IpAddress() {}

  IpAddress.parse = function (hostname) {
    // TODO(alkhayat): implement IP parsing
    return undefined;
  };

  return IpAddress;
}();

function fqdnLookupHelper(endpoints, protocol, hostNameWithoutEnv, port, path) {
  return endpoints.find(function (endpoint) {
    return isMatch(endpoint, protocol, port) && hostNameWithoutEnv.toLowerCase() === endpoint.Host.toLowerCase();
  });
}

function wildcardLookupHelper(endpoints, protocol, hostNameWithoutEnv, port, path) {
  return endpoints.find(function (endpoint) {
    return isMatch(endpoint, protocol, port) && hostNameWithoutEnv.includes(endpoint.Host.slice(1));
  });
}

function isMatch(endpoint, protocol, port) {
  return protocol === endpoint.Protocol && port === getPort(endpoint.Protocol, endpoint.Port);
}