"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XalAuthProvider = void 0;

var xaljs_1 = require("xaljs");

var XalAuthProvider =
/** @class */
function () {
  function XalAuthProvider() {}

  Object.defineProperty(XalAuthProvider.prototype, "currentUser", {
    get: function get() {
      return this.currentUserInternal;
    },
    enumerable: false,
    configurable: true
  });

  XalAuthProvider.prototype.init = function (authConfig, authComponents, httpRequestService, webView) {
    return xaljs_1.XalInitialize(authConfig, authComponents.deviceInfo, authComponents.cryptoFactory, authComponents.storage, httpRequestService, webView, true);
  };

  XalAuthProvider.prototype.signInSilently = function () {
    return __awaiter(this, void 0, void 0, function () {
      var existingUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.currentUser) {
              console.warn("A user already existed. Releasing existing user. Repeated calls to sign in should be avoided to prevent sign in UI interruptions.");
              this.currentUser.release();
            }

            return [4
            /*yield*/
            , xaljs_1.XalTryAddFirstUserSilently()];

          case 1:
            existingUser = _a.sent();
            this.currentUserInternal = existingUser;
            return [2
            /*return*/
            , existingUser];
        }
      });
    });
  };

  XalAuthProvider.prototype.signIn = function () {
    return __awaiter(this, void 0, void 0, function () {
      var newUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.currentUser) {
              console.warn("A user already existed. Releasing existing user. Repeated calls to sign in should be avoided to prevent sign in UI interruptions.");
              this.currentUser.release();
            }

            return [4
            /*yield*/
            , xaljs_1.XalAddUserWithUi()];

          case 1:
            newUser = _a.sent();
            this.currentUserInternal = newUser;
            return [2
            /*return*/
            , newUser];
        }
      });
    });
  };

  XalAuthProvider.prototype.getTokenAndSignature = function (request, forceRefresh) {
    if (forceRefresh === void 0) {
      forceRefresh = false;
    }

    return __awaiter(this, void 0, void 0, function () {
      var getTokenAndSignatureArgs, err_1, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            getTokenAndSignatureArgs = {
              body: request.data,
              forceRefresh: forceRefresh,
              headers: request.headers,
              method: request.method,
              url: request.url
            };
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 8]);

            return [4
            /*yield*/
            , xaljs_1.XalUserGetTokenAndSignatureSilently(this.currentUserInternal, getTokenAndSignatureArgs)];

          case 2:
            return [2
            /*return*/
            , _a.sent()];

          case 3:
            err_1 = _a.sent();
            xalError = err_1;
            if (!(xalError && xalError.errorType && xalError.errorType === xaljs_1.XalErrorType.UiRequired)) return [3
            /*break*/
            , 6];
            return [4
            /*yield*/
            , xaljs_1.XalUserResolveIssueWithUiAsync(this.currentUserInternal, request.url)];

          case 4:
            _a.sent();

            return [4
            /*yield*/
            , xaljs_1.XalUserGetTokenAndSignatureSilently(this.currentUserInternal, getTokenAndSignatureArgs)];

          case 5:
            return [2
            /*return*/
            , _a.sent()];

          case 6:
            console.error("Getting token and signature silently failed with error: %o", err_1);
            throw err_1;

          case 7:
            return [3
            /*break*/
            , 8];

          case 8:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  XalAuthProvider.prototype.resolveIssue = function (url) {
    return xaljs_1.XalUserResolveIssueWithUiAsync(this.currentUserInternal, url);
  };

  XalAuthProvider.prototype.signOut = function () {
    if (this.currentUserInternal) {
      return xaljs_1.XalSignOutUser(this.currentUserInternal);
    }

    return Promise.resolve();
  };

  XalAuthProvider.prototype.resumeMsaForWeb = function (msaCallbackResult) {
    return __awaiter(this, void 0, void 0, function () {
      var newUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , xaljs_1.XalAddUserWithUrl(msaCallbackResult)];

          case 1:
            newUser = _a.sent();
            this.currentUserInternal = newUser;
            return [2
            /*return*/
            , newUser];
        }
      });
    });
  };

  XalAuthProvider.prototype.resumeSisuForWeb = function (sisuCallbackResult) {
    return __awaiter(this, void 0, void 0, function () {
      var newUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , xaljs_1.XalAddUserWithUrl(sisuCallbackResult)];

          case 1:
            newUser = _a.sent();
            this.currentUserInternal = newUser;
            return [2
            /*return*/
            , newUser];
        }
      });
    });
  };

  return XalAuthProvider;
}();

exports.XalAuthProvider = XalAuthProvider;