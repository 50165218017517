var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { clubsRedux, ClubActions, TagPickerActions } from "@xbox/social-redux";

function coreClubProfileContainer(resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.toggleImagePicker = function (imageType) {
          if (imageType) {
            _this.setState({
              showImagePicker: !_this.state.showImagePicker,
              updateImageType: imageType
            });
          } else {
            _this.setState({
              showImagePicker: !_this.state.showImagePicker
            });
          }
        };

        _this.toggleEdit = function (isEditing) {
          _this.setState({
            isEditing: isEditing
          }); // save


          if (!isEditing && _this.state.didUpdate) {
            if (_this.state.editFields.description !== _this.props.clubItem.details.profile.description.value) {
              var path = ["details", "profile", "description", "value"];

              _this.updateProfile(path, "description", _this.state.editFields.description);

              _this.setState({
                didUpdate: false
              });
            }

            if (_this.state.editFields.name !== _this.props.clubItem.details.profile.name.value) {
              var path = ["details", "profile", "name", "value"];

              _this.updateProfile(path, "name", _this.state.editFields.name);

              _this.setState({
                didUpdate: false
              });
            }

            if (_this.state.editFields.color.primaryColor !== _this.props.clubItem.details.profile.primaryColor.value) {
              var path = ["details", "profile", "primaryColor", "value"];

              _this.updateProfile(path, "preferredColor", _this.state.editFields.color);

              _this.setState({
                didUpdate: false
              });
            }
          }
        };

        _this.updateName = function (e) {
          var editFields = _this.state.editFields;
          editFields.name = e.target.value;

          _this.setState({
            didUpdate: true,
            editFields: editFields
          });
        };

        _this.updateDescription = function (e) {
          var editFields = _this.state.editFields;
          editFields.description = e.target.value;

          _this.setState({
            didUpdate: true,
            editFields: editFields
          });
        };

        _this.updateColor = function (color) {
          var editFields = _this.state.editFields;
          editFields.color = color;

          _this.setState({
            didUpdate: true,
            editFields: editFields
          });
        };

        _this.submitTags = function (tags) {
          var path = ["details", "profile", "tags", "value"];

          _this.updateProfile(path, "tags", tags);
        };

        _this.updateProfile = function (updatedPropertyPath, updatedProperty, value) {
          var settingsPayload = {
            clubId: _this.props.clubItem.details.id,
            update: {
              modifiedFields: [updatedProperty],
              requestContract: {}
            },
            updatedProperty: updatedPropertyPath,
            updatedValue: value
          };

          if (settingsPayload.update) {
            settingsPayload.update.requestContract[updatedProperty] = value;
          }

          _this.props.updateProfile(settingsPayload);
        };

        var defaultEditFields = {
          description: props.clubItem.details.profile.description.value,
          name: props.clubItem.details.profile.name.value,
          color: {
            primaryColor: props.clubItem.details.profile.primaryColor.value,
            secondaryColor: props.clubItem.details.profile.secondaryColor.value,
            tertiaryColor: props.clubItem.details.profile.tertiaryColor && props.clubItem.details.profile.tertiaryColor.value
          }
        };
        _this.state = {
          isEditing: false,
          didUpdate: false,
          editFields: defaultEditFields,
          showImagePicker: false,
          updateImageType: undefined
        };
        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.props.getTags();
      };

      class_1.prototype.render = function () {
        var handlers = {
          updateName: this.updateName,
          updateDescription: this.updateDescription,
          editProfile: this.toggleEdit,
          toggleImagePicker: this.toggleImagePicker,
          updateColor: this.updateColor,
          submitTags: this.submitTags
        };
        var role = this.props.isOwner ? ClubHub.ClubRoles.Owner : this.props.isModerator ? ClubHub.ClubRoles.Moderator : ClubHub.ClubRoles.Member;
        var editInProgress = this.props.editInProgress ? true : this.state.isEditing;
        return resolver.getClubProfile(this.props.clubItem, this.state.editFields, role, editInProgress, handlers, this.state.showImagePicker, this.state.updateImageType);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return __assign(__assign({}, clubsRedux.selectors.getClubDetails(state)), {
    tagsCache: state.tagsCache.systemTags,
    editInProgress: state.clubs.clubDetails.editInProgress
  });
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    updateProfile: function updateProfile(update) {
      return dispatch(ClubActions.updateClubSettings(update));
    },
    getTags: function getTags() {
      return dispatch(TagPickerActions.getTags());
    }
  };
};

export function CoreClubProfileContainer(resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreClubProfileContainer(resolver));
}