import * as React from "react";
import { Localize } from "./../translation/Localize";
import { ThemedSelect } from "../Generic.Styles";

interface GameSelectorFromListProps {
    optionsList: JSX.Element[];
    onSetGame: (gameName: string) => void;
}

interface GameSelectorFromListState {
    titleId: string;
}

export class GameSelectorFromList extends React.Component<GameSelectorFromListProps, GameSelectorFromListState> {

    constructor(props: any) {
        super(props);
        this.state = {
            titleId: ""
        };
    }

    selectGame = (e: any) => {
        this.setState({titleId: e.toString()});
        this.props.onSetGame(e.toString());
    }

    render() {
        return (
            <ThemedSelect
                showSearch={true}
                style={{width: "100%"}}
                placeholder={Localize("uploadMedia.associatedGamePlaceholder")}
                value={this.state.titleId ? this.state.titleId : undefined}
                onChange={this.selectGame}
                optionFilterProp="children"
                filterOption={((input: string, option: { props: any} ): boolean => {
                    const fO: boolean = option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    return fO;
                })}
            >
                {this.props.optionsList}
            </ThemedSelect>
        );
    }
}
