"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteComment = exports.AddComment = exports.GetCommentQuery = exports.GetCommentsByPostQuery = void 0;

var graphql_tag_1 = require("graphql-tag");

var SharedFragments_1 = require("./SharedFragments"); // setting up structure.


exports.GetCommentsByPostQuery = graphql_tag_1.default(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery getComments($postUri: Uri!, $first: Int!, $after: String) {\n    fetchCommentsByPost(postUri: $postUri, first: $first, after: $after) {\n        cursor\n        items{\n            text\n            id\n            date\n            path\n            author {\n                ...UserProfileFragment\n            }\n        }\n    }\n}\n", "\n"], ["\nquery getComments($postUri: Uri!, $first: Int!, $after: String) {\n    fetchCommentsByPost(postUri: $postUri, first: $first, after: $after) {\n        cursor\n        items{\n            text\n            id\n            date\n            path\n            author {\n                ...UserProfileFragment\n            }\n        }\n    }\n}\n", "\n"])), SharedFragments_1.UserProfileFragment);
exports.GetCommentQuery = graphql_tag_1.default(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery getComments($postUri: Uri!, $commentId: String!) {\n    fetchComment(postUri: $postUri, commentId: $commentId) {\n        text\n        id\n        date\n        path\n        author {\n            ...UserProfileFragment\n        }\n    }\n}\n", "\n"], ["\nquery getComments($postUri: Uri!, $commentId: String!) {\n    fetchComment(postUri: $postUri, commentId: $commentId) {\n        text\n        id\n        date\n        path\n        author {\n            ...UserProfileFragment\n        }\n    }\n}\n", "\n"])), SharedFragments_1.UserProfileFragment);
exports.AddComment = graphql_tag_1.default(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation AddComment ($postUri: Uri!, $text: String!, $userId: UserId!){\n        addComment(postUri: $postUri, text: $text, userId: $userId) {\n            id\n        }\n    }\n"], ["\n    mutation AddComment ($postUri: Uri!, $text: String!, $userId: UserId!){\n        addComment(postUri: $postUri, text: $text, userId: $userId) {\n            id\n        }\n    }\n"])));
exports.DeleteComment = graphql_tag_1.default(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation DeleteComment ($postUri: Uri!, $commentId: String!){\n        deleteComment(postUri: $postUri, commentId: $commentId)\n    }\n"], ["\n    mutation DeleteComment ($postUri: Uri!, $commentId: String!){\n        deleteComment(postUri: $postUri, commentId: $commentId)\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;