"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenStack = void 0;

var GetAnonymousTokenAndSignature_1 = require("./operations/tokenStack/GetAnonymousTokenAndSignature");

var GetDefaultUser_1 = require("./operations/tokenStack/GetDefaultUser");

var GetMsaTicketForAdditionalScope_1 = require("./operations/tokenStack/GetMsaTicketForAdditionalScope");

var GetTokenAndSignature_1 = require("./operations/tokenStack/GetTokenAndSignature");

var InitializeTokenStack_1 = require("./operations/tokenStack/InitializeTokenStack");

var RefreshUser_1 = require("./operations/tokenStack/RefreshUser");

var SignIn_1 = require("./operations/tokenStack/SignIn");

var SignOut_1 = require("./operations/tokenStack/SignOut");

var FinishUrl_1 = require("./operations/tokenStack/FinishUrl");

var TokenStack =
/** @class */
function () {
  function TokenStack(components, telemetryClient, httpClient) {
    this.components = components;
    this.telemetryClient = telemetryClient;
    this.httpClient = httpClient;
  }

  TokenStack.prototype.initializeTokenStack = function () {
    var operation = new InitializeTokenStack_1.InitializeTokenStack(this.telemetryClient, this.components, this.httpClient, false // offlineMode
    );
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.getDefaultUser = function () {
    var operation = new GetDefaultUser_1.GetDefaultUser(this.telemetryClient, this.components, this.httpClient);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.getTokenAndSignature = function (args, webView) {
    var operation = new GetTokenAndSignature_1.GetTokenAndSignature(this.telemetryClient, this.components, this.httpClient, webView, args);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.getAnonymousTokenAndSignature = function (args) {
    // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL
    var operation = new GetAnonymousTokenAndSignature_1.GetAnonymousTokenAndSignature(this.telemetryClient, this.components, this.httpClient, args);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.getMsaTicketForAdditionalScope = function (user, scope, webView) {
    var operation = new GetMsaTicketForAdditionalScope_1.GetMsaTicketForAdditionalScope(this.telemetryClient, this.components, this.httpClient, webView, user, scope);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.tryRefreshToken = function (user, webView) {
    var operation = new RefreshUser_1.RefreshUser(this.telemetryClient, this.components, webView, user, this.httpClient);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.signIn = function (preexistingMsaUserId, webView, userChecker) {
    var operation = new SignIn_1.SignIn(this.telemetryClient, this.components, preexistingMsaUserId, webView, userChecker, this.httpClient);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.signOut = function (user, webView) {
    var operation = new SignOut_1.SignOut(this.telemetryClient, this.components, webView, user.webAccountId);
    operation.execute();
    return operation.completionPromise;
  };

  TokenStack.prototype.finishUrl = function (webView, userChecker, url, reyhdrationParams) {
    var operation = new FinishUrl_1.FinishUrl(this.telemetryClient, this.components, webView, this.httpClient, userChecker, url, reyhdrationParams);
    operation.execute();
    return operation.completionPromise;
  };

  return TokenStack;
}();

exports.TokenStack = TokenStack;