var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ClubHub, ActivityFeed } from "@xbox/social-core";
import { feedViewerRedux, ClubActions } from "@xbox/social-redux";

function coreShareModalContainer(shareModalResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.clubSelection = [];

        _this.shareItem = function () {
          if (!_this.state.selectedClubId || !_this.props.itemId || !_this.state.selectedChannelId) {
            return;
          }

          var timeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: _this.state.selectedClubId,
            channelId: _this.state.selectedClubId === _this.state.selectedChannelId ? undefined : _this.state.selectedChannelId
          };
          var tls = [timeline];
          var userPost = {
            postText: _this.state.shareCaption,
            postType: ActivityFeed.PostType.XblLink,
            timelines: tls,
            postTypeData: {
              locator: _this.props.itemId
            }
          };
          var request = {
            clubId: _this.state.selectedClubId,
            postData: userPost,
            isShared: true
          };

          _this.props.handlePostFeedItem(request);

          _this.props.onCancelShare();
        };

        _this.onCaptionsChanged = function (e) {
          _this.setState({
            shareCaption: e.target.value
          });
        };

        _this.onClubSelectChange = function (e) {
          _this.props.fetchFeedChannels(e.toString());

          _this.setState({
            selectedClubId: e.toString(),
            selectedChannelId: e.toString()
          });
        };

        _this.onChannelSelectChange = function (e) {
          _this.setState({
            selectedChannelId: e.toString()
          });
        };

        _this.state = {
          shareCaption: ""
        };
        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        if (this.props.user && this.props.user.clubs) {
          this.clubSelection = this.props.user.clubs.filter(function (x) {
            return x.settings.viewerRoles.roles.some(function (y) {
              return y === ClubHub.ClubRoles.Moderator;
            });
          }).map(function (z) {
            return z.details;
          });

          if (!this.state.selectedClubId && this.clubSelection && this.clubSelection.length > 0) {
            this.setState({
              selectedClubId: this.clubSelection[0].id,
              selectedChannelId: this.clubSelection[0].id
            });
            this.props.fetchFeedChannels(this.clubSelection[0].id);
          }
        }
      };

      class_1.prototype.render = function () {
        return shareModalResolver.getShareModalContainer(this.clubSelection, this.props.showShareModal, this.props.onCancelShare, this.shareItem, this.onCaptionsChanged, this.onClubSelectChange, this.onChannelSelectChange, this.state.shareCaption, this.props.channels, this.state.selectedClubId, this.state.selectedChannelId);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    channels: state.share.feedChannels,
    itemId: state.feedViewer.activityFeed.selectedFeedItem ? state.feedViewer.activityFeed.selectedFeedItem.feedItemId : undefined
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    handlePostFeedItem: function handlePostFeedItem(feedItemPayload) {
      return dispatch(feedViewerRedux.actions.postFeedItem(feedItemPayload));
    },
    fetchFeedChannels: function fetchFeedChannels(clubId) {
      return dispatch(ClubActions.fetchShareFeedChannels(clubId));
    }
  };
};

export function CoreShareModalContainer(shareModalResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreShareModalContainer(shareModalResolver));
}