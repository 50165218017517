"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberToByteArray = void 0; // Get bytes representation of a js number (64 bits -> 8 * 8bits)

function numberToByteArray(num) {
  var byteArray = new Uint8Array(8);

  for (var i = 0; i < byteArray.length; i++) {
    // tslint:disable-next-line:no-bitwise
    var byte = num & 0xff;
    byteArray[i] = byte;
    num = (num - byte) / 256;
  }

  return byteArray;
}

exports.numberToByteArray = numberToByteArray;