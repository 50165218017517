import * as React from "react";
import { ActivityFeedHeaderButtons } from "../feeditems/base/ActivityFeedHeaderButtons";
import { FeedViewType } from "../infiniteScrollers/base/InfiniteScrollTypes";
import { BaseActivityFeed, BaseActivityFeedContainerProps } from "./BaseActivityFeed";

export default class ClubActivityFeed extends BaseActivityFeed {
    constructor(props: BaseActivityFeedContainerProps) {
        super(props);
        this.storageKey = "clubFeedType";
        let cachedClubFeedType = localStorage.getItem(this.storageKey);
        let savedClubFeedType = cachedClubFeedType ? FeedViewType[cachedClubFeedType] : null;

        if (!savedClubFeedType) {
            savedClubFeedType = FeedViewType.CompactView;
            localStorage.setItem(this.storageKey, savedClubFeedType);
        }

        this.state = {
            showShareItemModal: false,
            feedViewType: this.props.forcedFeedType || this.loadStoredViewType()
        };
    }

    getHeaderButtons = (): JSX.Element | null => {
        if (!this.props.feedManipulationProps) {
            return null;
        }

        let isModerator: boolean = false;
        if (this.props.feedManipulationProps && this.props.feedManipulationProps.isModerator) {
            isModerator = this.props.feedManipulationProps.isModerator;
        }
        return (
            <ActivityFeedHeaderButtons
                postStatus={this.props.feedManipulationProps.postStatus}
                isModerator={isModerator}
                switchToFeedTools={this.props.feedManipulationProps.switchToFeedTools}
                onRefreshClick={this.refreshFeed}
                selectedChannel={this.props.feedManipulationProps.selectedChannel}
                showRefresh={true}
                feedViewType={this.state.feedViewType}
                setFeedViewType={(type: any) => this.setFeedViewType(type)}
            />
        );
    }
}
