import * as React from "react";
import { Button } from "antd";
import { ClubHub, ContentLocatorType } from "@xbox/social-core";
import { UploadMediaItemsPayload, UploadMediaState, AllTitlesState, MsStoreGameInfo } from "@xbox/social-redux";

import { Localize } from "../../../common/translation/Localize";
import { UploadContainer } from "./UploadContainer";
import { GenericModalFooter } from "../../../common/GenericElements";
import { MediaType } from "../FeedToolsTypes";
import { getThumbnailLocator, XBLMediaChoices } from "../Utils";
import { ThemedModal } from "../../../containers/Styles";

enum UploadDoneState {
    Cancelled = -1,
    Completed = 0,
}
interface UploadMediaPickerProps {
    uploadMediaItems: (data: UploadMediaItemsPayload, isVideo: boolean) => void;
    setXBLMediaItem?: (locator: string, previewUrl: string, downloadUri?: string, mediaType?: MediaType, contentID?: string) => void;
    getAllTitles?: () => void;
    titleList: AllTitlesState;
    uploadState: UploadMediaState;
    uploadItems: XBLMediaChoices;
    club?: ClubHub.Club;
    preSelectedGame?: MsStoreGameInfo;
}

interface UploadMediaPickerState {
    showModal: boolean;
    disableUpload: boolean;
    uploadInProgress: boolean;
    uploadValue: number;
    imagePreview: string;
}

export class UploadMediaPicker extends React.Component<UploadMediaPickerProps, UploadMediaPickerState> {
    private readonly uploadContainerRef?: React.RefObject<UploadContainer>;
    constructor(props: any) {
        super(props);

        this.uploadContainerRef = React.createRef();
        this.state = {
            showModal: false,
            disableUpload: true,
            uploadInProgress: false,
            uploadValue: 0,
            imagePreview: ""
        };
    }

    resetState = () => {
        this.setState({
            showModal: false,
            disableUpload: true,
            uploadInProgress: false,
            uploadValue: 0,
            imagePreview: "",
        });
        if (this.uploadContainerRef && this.uploadContainerRef.current) {
            this.uploadContainerRef.current.resetState();
        }
    }

    componentDidUpdate(prevProps: any) {
        const newValue = this.props.uploadState.progressValue;
        if (newValue !== prevProps.uploadState.progressValue) {
            switch (newValue) {

                case UploadDoneState.Cancelled:
                    this.setState({uploadInProgress: false, disableUpload: false, uploadValue: 0 });
                    break;

                case UploadDoneState.Completed:
                    if (this.props.setXBLMediaItem) {
                        const uploadData = this.props.uploadState.uploadData;

                        if (uploadData) {
                            const mediaType = uploadData.mediaType === "gameclips" ? MediaType.Gameclip : MediaType.Screenshot;
                            const downloadUri = uploadData.mediaItem
                                                    ? getThumbnailLocator(uploadData.mediaItem.contentLocators, ContentLocatorType.Download)
                                                    : undefined;
                            this.props.setXBLMediaItem(uploadData.locatorId , this.state.imagePreview, downloadUri, mediaType, uploadData.contentId);

                        } else {
                            this.props.setXBLMediaItem("", this.state.imagePreview);
                        }
                    }
                    this.resetState();
                    break;

                default:
                    // upload still in progress
                    this.setState({ uploadValue: newValue });
                    break;
            }
        }
    }

    updateUploadState = (enableBtn: boolean) => {
        this.setState({ disableUpload: !enableBtn });
    }

    updateImagePreview = (imagePreview: string) => {
        this.setState({ imagePreview: imagePreview });
    }

    openUploadModal = () => {
        this.setState({ showModal: true });
    }

    startUpload = () => {
        if (this.uploadContainerRef && this.uploadContainerRef.current) {
            this.setState({ uploadInProgress: true });
            this.uploadContainerRef.current.handleUpload();
        }
    }

    cancelUpload = () => {
        if (this.uploadContainerRef && this.uploadContainerRef.current) {
            this.uploadContainerRef.current.cancelUpload();
            this.setState({ uploadInProgress: false, disableUpload: true });
        }
    }

    closeUploadModal = () => {
        if (this.state.uploadInProgress) {
            this.cancelUpload();
        }
        this.resetState();

        if (this.props.setXBLMediaItem) {
            this.props.setXBLMediaItem("", "");
        }
    }

    getUploadActionText = (): JSX.Element => {
        return this.state.uploadInProgress
                    ? Localize("uploadMedia.buttons.CancelUpload", { percents: this.state.uploadValue })
                    : Localize("uploadMedia.buttons.StartUpload");
    }

    render() {
        return (
            <div className="UploadMediaPicker">
                <Button onClick={this.openUploadModal}>
                    {Localize("uploadMedia.uploadNewFile")}
                </Button>
                <ThemedModal
                    title={
                        <div role="heading" aria-level={2}>
                            {Localize("uploadMedia.modal.uploadTitle")}
                        </div>}
                    visible={this.state.showModal}
                    onCancel={this.closeUploadModal}
                    footer={
                        <GenericModalFooter
                            onOk={this.state.uploadInProgress ? this.cancelUpload : this.startUpload}
                            onCancel={this.closeUploadModal}
                            disableOK={this.state.disableUpload}
                            okText={this.getUploadActionText()}
                        />}
                >
                    <div style={{ minHeight: "120px" }}>
                        <UploadContainer
                            uploadMediaItems={this.props.uploadMediaItems}
                            updateUploadState={this.updateUploadState}
                            updateImagePreview={this.updateImagePreview}
                            getAllTitles={this.props.getAllTitles}
                            titleList={this.props.titleList}
                            uploadItems={this.props.uploadItems}
                            showPreviewBtn={false}
                            club={this.props.club}
                            ref={this.uploadContainerRef}
                            preSelectedGame={this.props.preSelectedGame}
                        />
                    </div>
                </ThemedModal>
            </div>
        );
    }
}
