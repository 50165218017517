import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withCookies, Cookies } from "react-cookie";
import { AuthActions, ReduxState, AuthRedirectCompletedPayload } from "@xbox/social-redux";
import { User } from "@xbox/social-core";

import { GenericLoading } from "../common/GenericElements";
import { CenteredColumnDiv } from "../common/Generic.Styles";
import { telemetryService } from "../index";
import TimeSensitiveFeedbackPrompt from "./TimeSensitiveFeedbackPrompt";

type SiSuRedirectProps = {
    currentUser: User;
    authFailed?: any;
    navigateToApp: () => void;
    navigateToRoot: () => void;
    siSuRedirectCompleted: (payload: AuthRedirectCompletedPayload) => void;
    cookies: Cookies;
};

class SiSuRedirectComplete extends React.Component<SiSuRedirectProps, {}> {

    componentDidMount() {
        let authRedirectedCompletedPayload: AuthRedirectCompletedPayload = {
            url: window.location.toString(),
            cookies: this.props.cookies
        };
        // fix this to make it redirects or fix the callback between windows.
        this.props.siSuRedirectCompleted(authRedirectedCompletedPayload);
    }

    componentDidUpdate(prevProps: Readonly<SiSuRedirectProps>): void {
        if (this.props.authFailed) {
            this.props.navigateToRoot();
            return;
        }

        if (this.props.currentUser) {
            telemetryService.setAuthenticatedUserContext(this.props.currentUser.xuid);
            this.props.navigateToApp();
        }
    }

    public render() {
        return (
            <CenteredColumnDiv>
                <GenericLoading/>
                <TimeSensitiveFeedbackPrompt timer={15}/>
            </CenteredColumnDiv>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        navigateToApp: () => dispatch(push("/app")),
        navigateToRoot: () => dispatch(push("/")),
        siSuRedirectCompleted: (payload: AuthRedirectCompletedPayload) => dispatch(AuthActions.siSuRedirectCompletedWeb(payload)),
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return {
        currentUser: state.authentication.currentUser,
        authFailed: state.authentication.authFailure
    };
};

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiSuRedirectComplete));
