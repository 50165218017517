"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StepTracker = void 0; // Step enum replacement regex: (\w+),? => $1 = "$1",

var StepTracker =
/** @class */
function () {
  function StepTracker(area, start) {
    this.area = area;
    this.current = start;
    this.previous = start;
  }

  StepTracker.prototype.advance = function (step) {
    this.previous = this.current;
    this.current = step;
    console.debug("XAL:StepAdvanced:" + this.area + ": Current step: " + this.current + ", Previous step: " + this.previous);
  };

  Object.defineProperty(StepTracker.prototype, "currentStep", {
    get: function get() {
      return this.current;
    },
    enumerable: false,
    configurable: true
  });
  return StepTracker;
}();

exports.StepTracker = StepTracker;