import * as React from "react";
import { connect } from "react-redux";
import { ClubHub } from "@xbox/social-core";
import { ReduxState, ClubActions } from "@xbox/social-redux";
import { GroupedChannels } from "@xbox/social-views";

import * as cpStyles from "./ChannelPicker.Styles";
import ChannelSettings, { ChannelType } from "./ChannelSettings";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon, GenericDisplayMode } from "../../common/GenericElements";
import { AddChannelControl } from "./AddChannelControl";
import { HandleNavigation, ReorgnizeChannels } from "../../Utils";
import { Localize } from "../../common/translation/Localize";

interface ChannelPickerContainerProps {
    feedChannels?: ClubHub.Channel[];
    chatChannels?: ClubHub.Channel[];
    selectedChannel?: ClubHub.Channel;
}

interface ChannelPickerOtherProps {
    club?: ClubHub.Club;
    mobileView?: boolean;
}

interface ChannelPickerMenuDispatchProps {
    selectChannel: (channel: ClubHub.Channel) => void;
    addChannel: (channelData: ClubHub.CreateChannel) => void;
    handleNavigation: (route: string) => void;
}

type ChannelPickerProps = ChannelPickerContainerProps & ChannelPickerMenuDispatchProps & ChannelPickerOtherProps;

interface ChannelPickerState {
    showSettings: boolean;
    settingsForChannel?: ClubHub.Channel;
    groupedChannels: GroupedChannels[];
    addChannelPostValues: string[];
}

export const DefaultGroupChannelName = "default";

class ChannelPicker extends React.Component<ChannelPickerProps, ChannelPickerState> {
    constructor(props: ChannelPickerProps) {
        super(props);
        this.state = { showSettings: false, groupedChannels: [], addChannelPostValues: [] };
    }

    componentDidMount() {
        this.updateGroupChannels();
    }

    componentDidUpdate(prevProps: ChannelPickerProps) {
        if (prevProps.feedChannels !== this.props.feedChannels
            || prevProps.chatChannels !== this.props.chatChannels
        ) {
            this.updateGroupChannels();
        }

        if (prevProps.feedChannels && this.props.feedChannels
            && prevProps.feedChannels.length < this.props.feedChannels.length
        ) {
            // get new channel and navigate to its page
            let newChannel = this.findDifferent(this.props.feedChannels, prevProps.feedChannels);
            this.selectChannel(newChannel[0]);
        }
    }

    updateLink = (channel: ClubHub.Channel) => {
        let channelType = channel.type!;
        let channelId = channel.isDefault ? "" : channel.id;

        this.props.handleNavigation(`/${channelType}/${channelId}`);
    }

    selectChannel = (channel: ClubHub.Channel) => {
        this.props.selectChannel(channel);
        this.updateLink(channel);
    }

    getChannelNameItem = (channel: ClubHub.Channel): JSX.Element | null => {
        if (!this.canViewChannel(channel)) {
            return null;
        }

        let isSelected = this.props.selectedChannel
            && channel.id.toString() === this.props.selectedChannel.id
            && channel.type === this.props.selectedChannel.type;

        return (
            <cpStyles.ChannelItem className={isSelected ? "active" : ""} key={channel.id + "_" + channel.type}>
                {this.getChannelTitle(channel)}
                {this.getSettingsButton(channel)}
            </cpStyles.ChannelItem>
        );
    }

    getChannelTitle = (channel: ClubHub.Channel) => {
        let isFeedChannel = channel.type! === "feed";
        let name = channel.isDefault
            ? (isFeedChannel ? Localize("channels.general") : Localize("channels.generalChat"))
            : channel.name;
        return (
            <cpStyles.ChannelTag onClick={() => this.selectChannel(channel)}>
                {!isFeedChannel ? <cpStyles.ChatIcon type={Glyphs.Chat} /> : "#"}
                {name}
            </cpStyles.ChannelTag>
        );
    }

    getSettingsButton = (channel: ClubHub.Channel): JSX.Element | null => {
        if (!this.props.club || !this.props.club.settings || this.props.club.settings.viewerRoles.roles.indexOf(ClubHub.ClubRoles.Owner) === -1) {
            return null;
        }

        return (
            <cpStyles.SettingsButton iconOnly={true} onClick={() => this.showSettings(channel)}>
                <Icon type={Glyphs.Settings} />
            </cpStyles.SettingsButton>
        );
    }

    showSettings = (channel: ClubHub.Channel) => {
        this.setState({ showSettings: true, settingsForChannel: channel });
    }

    hideSettings = () => {
        this.setState({ showSettings: false, settingsForChannel: undefined });
    }

    canViewChannel = (channel: ClubHub.Channel) => {
        let canView = channel.settings.view;
        return canView && canView.canViewerAct === true;
    }

    canViewChannelGroup = (group: GroupedChannels) => {
        let visible = false;
        if (group.channels) {
            for (let i = 0; i < group.channels.length; i++) {
                if (this.canViewChannel(group.channels[i])) {
                    visible = true;
                    break;
                }
            }
        }
        return visible;
    }

    findDifferent = (newList: ClubHub.Channel[], oldList: ClubHub.Channel[]) => {
        return newList.filter((channel: ClubHub.Channel) => {
            let result = true;
            for (let i = 0; i < oldList.length; i++) {
                if (oldList[i].id === channel.id) {
                    result = false;
                    break;
                }
            }
            return result;
        });
    }

    updateGroupChannels = () => {
        if (!this.props.feedChannels) {
            // there should be always at least GeneralFeed channel
            this.setState({ groupedChannels: [] });
            return;
        }

        let channels = this.props.feedChannels;

        let defaultFeed = channels.find(c => (c.isDefault && c.type === "feed"));
        let defaultFeedPostValues = defaultFeed && defaultFeed.settings.post && defaultFeed.settings.post.allowedValues ? defaultFeed.settings.post.allowedValues : [];

        let groups = ReorgnizeChannels(channels);

        this.setState({ groupedChannels: groups, addChannelPostValues: defaultFeedPostValues});
    }

    public render() {
        let channelType = this.state.settingsForChannel && this.state.settingsForChannel.type === "feed"
            ? ChannelType.FeedChannel
            : ChannelType.ChatChannels;

        return (
            <cpStyles.ChannelsContainer className="ChannelsContainer" mobile={this.props.mobileView === true} >
                {this.state.groupedChannels.map(group => {
                    if (group.name === DefaultGroupChannelName) {
                        return group.channels.map(c => this.getChannelNameItem(c));
                    } else {
                        return (
                            <div key={`${group.name}${group.order}`} style={{ display: GenericDisplayMode(this.canViewChannelGroup(group)) }}>
                                <cpStyles.GroupName className="ChannelGroupName">{group.name}</cpStyles.GroupName>
                                {group.channels.map(c => this.getChannelNameItem(c))}
                            </div>
                        );
                    }
                })}
                {
                    this.props.club
                        && this.props.club.settings
                        && this.props.club.settings.viewerRoles.roles.some(role => role !== ClubHub.ClubRoles.None)
                        ? <AddChannelControl
                                clubId={this.props.club.details.id}
                                addChannel={this.props.addChannel}
                                postSetting={this.state.addChannelPostValues}
                        />
                        : null
                }

                <ChannelSettings
                    club={this.props.club}
                    closeModal={this.hideSettings}
                    isVisible={this.state.showSettings}
                    channelType={channelType}
                    appliedChannel={this.state.settingsForChannel}
                />
            </cpStyles.ChannelsContainer>
        );
    }
}

const mapStateToProps = (state: ReduxState): ChannelPickerContainerProps => {
    return {
        feedChannels: state.channels.feedChannels,
        chatChannels: state.clubs.clubDetails.club && state.clubs.clubDetails.club.chatChannels,
        selectedChannel: state.channels.selectedChannel
    };
};

const mapDispatchToProps = (dispatch: any): ChannelPickerMenuDispatchProps => {
    return {
        selectChannel: (channel: ClubHub.Channel) => dispatch(ClubActions.clubChannelSelected(channel)),
        addChannel: (channelData: ClubHub.CreateChannel) => dispatch(ClubActions.createClubChannel(channelData)),
        handleNavigation: (route: string) => HandleNavigation(route, `discussion`, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelPicker);
