"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BugManager = void 0;

var file_a_bug_1 = require("./file-a-bug");

Object.defineProperty(exports, "BugManager", {
  enumerable: true,
  get: function get() {
    return file_a_bug_1.BugManager;
  }
});