import * as React from "react";

interface PersonHomeViewerProps {
}

interface PersonHomeViewerState {
}

export class PersonHomeViewer extends React.Component<PersonHomeViewerProps, PersonHomeViewerState> {
    constructor(props: PersonHomeViewerProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1>this is person home page</h1>
            </div>
        );
    }
}
