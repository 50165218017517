import * as React from "react";
import { ClubHub } from "@xbox/social-core";

import * as FeedItemStyles from "./FeedItem.Styles";
import { Glyphs } from "../../../common/Generic.Icons";
import { GenericDisplayMode } from "../../../common/GenericElements";
import { GamerpicListItem } from "../../../common/generic/GamerpicListItem";
import { Localize, LocalizeHTML } from "../../../common/translation/Localize";

export interface PreviewFeedItemProps {
    pinned?: boolean;
    itemText: string;
    userGamerPicUrl: string;
    userGamerTag: string;
    selectedChannel?: ClubHub.Channel;
    scheduledDateTime?: string;
}

interface PreviewProps extends PreviewFeedItemProps {
    previewContent: JSX.Element | null;
}

export const PreviewActivityFeedItem: React.SFC<PreviewProps> = props => {
    let gamerTag = props.userGamerTag ? props.userGamerTag : "Gamertag";
    let channelName = props.selectedChannel && props.selectedChannel.isDefault ? Localize("channels.general") : props.selectedChannel!.name;
    return (
        <div>
            <FeedItemStyles.PreviewFeedItemCard className="textPost">
                <FeedItemStyles.FeedItemHeader>
                    <FeedItemStyles.PreviewPinnedIcon type={Glyphs.Pin} style={{ display: GenericDisplayMode(props.pinned) }} />
                    <GamerpicListItem
                        displayPicUrl={props.userGamerPicUrl}
                        displayPicName={gamerTag}
                    />
                </FeedItemStyles.FeedItemHeader>
                {props.previewContent}
            </FeedItemStyles.PreviewFeedItemCard>
            <LocalizeHTML id="feedtools.postChannel" options={{ channelName }} />
            <br />
            {props.scheduledDateTime ? <LocalizeHTML id="feedtools.postScheduled" options={{ scheduledDateTime: props.scheduledDateTime }} /> : null}
        </div>
    );
};
