var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import * as React from "react";
import { connect } from "react-redux";
import { ClubActions, clubsRedux } from "@xbox/social-redux";
export var NoneTitleId = "None";

var FilterGamerTag = function FilterGamerTag(inviteQ, filter) {
  if (!filter || !inviteQ) {
    return inviteQ;
  }

  return inviteQ.filter(function (t) {
    return t.profile.gamertag.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1 || t.profile.realName && t.profile.realName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
  });
};

var ApplyFilter = function ApplyFilter(inviteQ, filter, userStatsMap) {
  if (!inviteQ || !filter) {
    return inviteQ;
  }

  if (filter.gamerscore) {
    inviteQ = inviteQ.filter(function (u) {
      return +u.profile.gamerscore >= (filter.gamerscore ? filter.gamerscore : 0);
    });
  }

  if (filter.recommended) {
    inviteQ = inviteQ.filter(function (u) {
      return u.profile.xuid !== u.actor.xuid;
    });
  }

  if (filter.reputation) {
    inviteQ = inviteQ.filter(function (u) {
      return u.profile.xboxOneRep === "GoodPlayer";
    });
  }

  if (filter.timePlayed && filter.timePlayed.titleId !== NoneTitleId && filter.timePlayed.hours > 0 && userStatsMap) {
    inviteQ = inviteQ.filter(function (u) {
      var userTimePlayedMinutes = userStatsMap[u.actor.xuid];

      if (filter.timePlayed && (userTimePlayedMinutes == null || userTimePlayedMinutes / 60 < filter.timePlayed.hours)) {
        return false;
      }

      return true;
    });
  }

  return inviteQ;
};

function coreInviteQContainer(resolver, LoadingState, EmptyState) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.inviteAll = function () {
          var payload = {
            clubId: _this.props.clubId,
            shouldInvite: true,
            xuids: _this.props.inviteQ.map(function (r) {
              return r.profile.xuid;
            })
          };

          _this.props.inviteUser(payload);
        };

        _this.ignoreAll = function () {
          var payload = {
            clubId: _this.props.clubId,
            shouldInvite: false,
            xuids: _this.props.inviteQ.map(function (r) {
              return r.profile.xuid;
            })
          };

          _this.props.rejectUser(payload);
        };

        _this.search = function (e) {
          // search
          _this.props.applySearchFilter(e);
        };

        _this.getSavedFilter = function () {
          return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
              switch (_a.label) {
                case 0:
                  return [4
                  /*yield*/
                  , this.props.getSavedFilter(this.props.clubId)];

                case 1:
                  _a.sent();

                  return [2
                  /*return*/
                  ];
              }
            });
          });
        };

        _this.filter = function (filter) {
          var payload = {
            filter: filter,
            clubId: _this.props.clubId
          };

          _this.props.saveFilter(payload);

          _this.props.applyFilter(filter);
        };

        _this.invite = function (e) {
          var payload = {
            clubId: _this.props.clubId,
            shouldInvite: true,
            xuids: [e]
          };

          _this.props.inviteUser(payload);
        };

        _this.reject = function (e) {
          var payload = {
            clubId: _this.props.clubId,
            shouldInvite: false,
            xuids: [e]
          };

          _this.props.rejectUser(payload);
        };

        _this.showfilter = function (e) {
          var filter = {
            isVisible: e,
            gamerscore: _this.props.filter ? _this.props.filter.gamerscore : 0,
            gold: _this.props.filter ? _this.props.filter.gold : false,
            recommended: _this.props.filter ? _this.props.filter.recommended : false,
            reputation: _this.props.filter ? _this.props.filter.reputation : false,
            timePlayed: _this.props.filter ? _this.props.filter.timePlayed : undefined
          };

          _this.props.applyFilter(filter);
        };

        _this.getuserstats = function (filter) {
          if (filter.timePlayed && filter.timePlayed.titleId !== NoneTitleId && filter.timePlayed.hours > 0) {
            var xuidsFromProps_1 = [];

            _this.props.inviteQ.forEach(function (value) {
              xuidsFromProps_1.push(value.actor.xuid);
            });

            var payload = {
              titleId: filter.timePlayed.titleId,
              xuids: xuidsFromProps_1
            };

            _this.props.getUserStats(payload);
          }
        };

        _this.clearHourIfNoTitle = function (filter) {
          if (filter.timePlayed && filter.timePlayed.titleId === NoneTitleId) {
            filter.timePlayed.hours = 0;

            _this.filter(filter);
          }
        };

        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        if (this.props.clubId) {
          this.getSavedFilter();
          this.props.fetchInviteQ(this.props.clubId);
        }
      };

      class_1.prototype.render = function () {
        var handlers = {
          search: this.search,
          filter: this.filter,
          invite: this.invite,
          reject: this.reject,
          inviteAll: this.inviteAll,
          rejectAll: this.ignoreAll,
          showFilter: this.showfilter,
          getUserStats: this.getuserstats,
          clearHourIfNoTitle: this.clearHourIfNoTitle
        };
        var InviteQueuePanel = resolver.getInviteQueuePanel(this.props.inviteQ, handlers, this.props.filter, this.props.clubItem);
        return !this.props.fetchInProgress ? InviteQueuePanel : React.createElement(LoadingState, null);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var result = clubsRedux.selectors.getClubInviteQueue(state.inviteQ);
  return {
    inviteQ: ApplyFilter(FilterGamerTag(result.inviteQ, state.inviteQ.searchFilter), state.inviteQ.filter, state.inviteQ.userStatsMap),
    filter: state.inviteQ.filter,
    fetchInProgress: result.fetchInProgress
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchInviteQ: function fetchInviteQ(clubId) {
      return dispatch(ClubActions.clubInviteQueueFetchQueue(clubId));
    },
    applyFilter: function applyFilter(filter) {
      return dispatch(ClubActions.clubInviteQueueFilter(filter));
    },
    applySearchFilter: function applySearchFilter(query) {
      return dispatch(ClubActions.clubInviteQueueSearchFilter(query));
    },
    inviteUser: function inviteUser(action) {
      return dispatch(ClubActions.clubInviteQueueRequestAction(action));
    },
    rejectUser: function rejectUser(action) {
      return dispatch(ClubActions.clubInviteQueueRequestAction(action));
    },
    getSavedFilter: function getSavedFilter(clubId) {
      return dispatch(ClubActions.clubInviteGetFilter(clubId));
    },
    saveFilter: function saveFilter(filter) {
      return dispatch(ClubActions.clubInviteSaveFilter(filter));
    },
    getUserStats: function getUserStats(action) {
      return dispatch(ClubActions.clubUserGetStats(action));
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreInviteQContainer(resolver, LoadingState, EmptyState) {
  return connect(mapStateToProps, mapDispatchToProps)(coreInviteQContainer(resolver, LoadingState, EmptyState));
}