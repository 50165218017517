import * as React from "react";
import * as FeedItemStyles from "./FeedItem.Styles";
import { Localize } from "../../../common/translation/Localize";

export enum PollVisibilityState {
    SHOW_RESULTS,
    SHOW_BALLOT,
    SHOW_CLOSED,
    SHOW_PRECLOSED
}

export function validateHttpPrefix(link: string) {
    return link.indexOf("http") !== 0 ? `http://${link}` : link;
}

export function parseMediaLocator(locators: any[], locatorType: string, fallback: string) {
    // Some of the HTTP requests return capitalized fields, we need to handle both until the returns are uniform
    if (!locators[0]) {
        return { "uri": fallback };
    }

    if (locators[0].Uri) {
        let temp = locators.find(dl => dl.LocatorType === locatorType) || { "Uri": fallback };
        return { uri: temp.Uri, expiration: temp.Expiration, fileSize: temp.FileSize, locatorType: temp.LocatorType };
    } else {
        return locators.find(dl => dl.locatorType === locatorType) || { "uri": fallback };
    }
}

// used for sheduled post - to show time when it would be posted
export function getPostDateElement(postDate?: Date): JSX.Element | null {
    // props.postDate is returned in UTC time.  new Date(postDate) will convert it to user's local time
    if (!postDate) {
        return null;
    }

    return (
        <div>
            <FeedItemStyles.DateContainer className="ScheduleToDateContainer">
                {Localize("feedtools.postDateLabel", { postDate: new Date(postDate) })}
            </FeedItemStyles.DateContainer>
        </div>
    );
}
