import styled from "styled-components";
import { Button } from "antd";

export const Color = styled.div`
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: text-bottom;
`;

export const EditButton = styled(Button as any)`
    max-width: 150px;
    float: left;
    margin-top: 10px;
    width: 25%;
    clear: both;
`;

export const ColorLarge = styled.a`
    width: 50px;
    height: 50px;
    display: inline-block;
`;

export const ColorsContainer = styled.div`
    max-width: 500px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    display: block;
`;
