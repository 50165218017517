"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imagePickerReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ImagePickerActions_1 = require("../../../actions/ImagePickerActions");

var INITIAL_STATE = Immutable({
  inProgress: false,
  hasImages: false
});
exports.imagePickerReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ImagePickerActions_1.ImagePickerActions.imageTypeSelected, function (state, action) {
  return state.setIn(["updateImageType"], action.payload);
}).on(ImagePickerActions_1.ImagePickerActions.getImagesCompleted, function (state, action) {
  return state.merge({
    images: action.payload,
    hasImages: true
  });
}).build();