import styled from "styled-components";
import { media } from "../../common/Generic.SnapPoints";
import { Button } from "../../common/generic/Button";

export const ItemCard = styled.div`
    height: 150px;
    margin: 0px 2px 10px 0px;
    position: relative;
    cursor: pointer;
    background: ${props => props.theme.backgroundMain};
    box-shadow: 2px 2px ${props => props.theme.borderSecondary};
`;

export const MainDivContainer = styled.div`
    float: left;
    padding: 8px 16px 0px 16px;
    min-width: 320px;
    max-width: 65%;

    ${media.menuOpenSmall`min-width: 220px; max-width: 75%`}
    ${media.small`min-width: 200px;`}
`;

export const ImageDivContainer = styled.div`
    float: left;
    height: 100%;
    width: 150px;
    max-width: 35%;
    overflow: hidden;
    display: flex;
    justify-content: start; /* horizontal align */
    align-items: center; /* vertical align */
    ${media.menuOpenSmall`max-width: 25%;`}
`;

export const UserAvatar = styled.img`
    object-fit: scale-down;
    width: 100%;
    height: 100%;
`;

export const SocialIconDiv = styled.div`
    font-size: 1.5em;
    margin-right: 10px;
    position: absolute;
    right: 0;
    top: 5%;
`;

export const SocialButtonDiv = styled.div`
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    position: absolute;
    bottom: 5%;
`;

export const SocialButton = styled(Button)`
    padding: 0 25px;
    font-weight: 500;
`;

export const FriendPostTextDivContainer = styled.div`
    margin-top: 2px;
    line-height: 1.5em;

    ${media.menuOpenSmall`margin-top: 5px;`}
`;

export const GamerTag = styled.div`
    font-weight: 600;
    font-size: 1.8em;
    font-style: italic;
    color: ${props => props.theme.foregroundBrigth};
`;

export const UserName = styled.div`
    margin-top: 10px;
    opacity: 0.6;
    font-size: 1.4em;
`;

export const FollowInfoDiv = styled.div`
    margin-right: 20px;
    position: absolute;
    right: 0;
    bottom: 10%;
`;

export const PersonLevelMenu = styled.ul`
    display: flex;
    flex: none;
    flex-wrap: wrap;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    padding-inline-start: 0;
`;

export const PersonLevelItem = styled.li`
    margin: 8px 20px 6px 0;

    a {
        font-size: 15px;
        text-decoration: none;
        color: ${props => props.theme.foregroundBrigth};
    }

    .active {
        border-bottom: 4px solid ${props => props.theme.blueRest};
        text-decoration: none;
        padding-bottom: 5px;
    }
`;

export const DevicesIcon = styled.div`
    font-size: 1.5em;
    margin-right: 10px;
`;

export const PersonColumn = styled.div`
    width: 50%;
    float: left;
`;

export const PersonDivContainer = styled.div`
    margin-top: 40px;
`;
