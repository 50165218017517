"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MpsdPartyServiceProxy = void 0;

var framework_1 = require("@xbox/framework");

var http_1 = require("@xbox/http");

var inversify_1 = require("inversify");

var XSocialSymbols_1 = require("../../../typings/XSocialSymbols");

var ConnectionState_1 = require("../ConnectionState");

var MpsdPartySession_1 = require("./MpsdPartySession");

var GSI_SET_ID = "f99e1943-4eba-41d6-81e2-988eea9a6d22"; // Q10-Dev
// MPSD Party constants

var mpsdEndpoint = "https://sessiondirectory.xboxlive.com";
var templateName = "chat";
var scid = "7492BACA-C1B4-440D-A391-B7EF364A8D40";
var standardMpsdHeaders = [{
  Name: "User-Agent",
  Value: "XboxServicesAPI/1.0.0.0"
}, {
  Name: "x-xbl-client-name",
  Value: "Xbox Guide"
}, {
  Name: "x-xbl-client-type",
  Value: "XboxOne"
}, {
  Name: "x-xbl-contract-version",
  Value: 107
}];
var ifMatchHeader = {
  Name: "If-Match",
  Value: "*"
};
var ifNoneMatchHeader = {
  Name: "If-None-Match",
  Value: "*"
};

var MpsdPartyServiceProxy =
/** @class */
function () {
  function MpsdPartyServiceProxy(authProvider, xblService, deviceInfo) {
    this.authProvider = authProvider;
    this.xblService = xblService;
    this.deviceInfo = deviceInfo;
  }

  MpsdPartyServiceProxy.prototype.createNewPartySession = function (localXuid, rtaConnectionId, serverMeasurements) {
    return __awaiter(this, void 0, void 0, function () {
      var sessionId, deviceId, subscriptionId, body, url, response;

      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            sessionId = framework_1.guid();
            deviceId = this.deviceInfo.deviceId;
            subscriptionId = framework_1.guid();
            body = {
              members: (_a = {}, _a["me_" + localXuid] = {
                constants: {
                  custom: {
                    clientCapability: 4,
                    deviceId: deviceId
                  },
                  system: {
                    xuid: localXuid
                  }
                },
                properties: {
                  system: {
                    active: true,
                    connection: rtaConnectionId,
                    subscription: {
                      changeTypes: ["host", "initialization", "memberslist", "membersstatus", "customproperty", "memberscustomproperty", "joinability", "cloudcompute"],
                      id: subscriptionId
                    }
                  },
                  custom: {
                    // MAKE_MPSD_VERSION_FIELD(PARTYCHAT_MPSD_FORMAT_VERSION, 0)
                    protocolVersion: 0x00020000,
                    deviceId: deviceId,
                    simpleConnectionState: ConnectionState_1.PartyChatConnectionState.Connecting
                  }
                }
              }, _a),
              constants: {
                custom: {
                  xrnxbl: true
                }
              },
              properties: {
                system: {
                  joinRestriction: "followed",
                  readRestriction: "followed",
                  allocateCloudCompute: true
                }
              }
            };

            if (serverMeasurements) {
              body.members["me_" + localXuid].properties.system.serverMeasurements = serverMeasurements;
            }

            url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
            return [4
            /*yield*/
            , this.xblService.sendRequest({
              method: http_1.Http.HttpRequestMethod.Put,
              data: JSON.stringify(body),
              url: url,
              headers: __spreadArrays(standardMpsdHeaders, [ifNoneMatchHeader])
            })];

          case 1:
            response = _b.sent();
            return [2
            /*return*/
            , new MpsdPartySession_1.MpsdPartySession(sessionId, localXuid, response.data, this)];
        }
      });
    });
  };

  MpsdPartyServiceProxy.prototype.joinPartySession = function (sessionId, localXuid, rtaConnectionId, serverMeasurements) {
    return __awaiter(this, void 0, void 0, function () {
      var deviceId, subscriptionId, body, url, response;

      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            deviceId = this.deviceInfo.deviceId;
            subscriptionId = framework_1.guid();
            body = {
              members: (_a = {}, _a["me_" + localXuid] = {
                constants: {
                  custom: {
                    clientCapability: 4,
                    deviceId: deviceId
                  },
                  system: {
                    xuid: localXuid
                  }
                },
                properties: {
                  system: {
                    active: true,
                    connection: rtaConnectionId,
                    subscription: {
                      changeTypes: ["host", "initialization", "memberslist", "membersstatus", "customproperty", "memberscustomproperty", "joinability", "cloudcompute"],
                      id: subscriptionId
                    }
                  },
                  custom: {
                    // MAKE_MPSD_VERSION_FIELD(PARTYCHAT_MPSD_FORMAT_VERSION, 0)
                    protocolVersion: 0x00020000,
                    deviceId: deviceId,
                    simpleConnectionState: ConnectionState_1.PartyChatConnectionState.Connecting
                  }
                }
              }, _a),
              properties: {
                system: {
                  allocateCloudCompute: true
                }
              }
            };

            if (serverMeasurements) {
              body.members["me_" + localXuid].properties.system.serverMeasurements = serverMeasurements;
            }

            url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
            return [4
            /*yield*/
            , this.xblService.sendRequest({
              method: http_1.Http.HttpRequestMethod.Put,
              data: JSON.stringify(body),
              url: url,
              headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
            })];

          case 1:
            response = _b.sent();
            return [2
            /*return*/
            , new MpsdPartySession_1.MpsdPartySession(sessionId, localXuid, response.data, this)];
        }
      });
    });
  };

  MpsdPartyServiceProxy.prototype.setPartyRtaConnectionId = function (sessionId, rtaConnectionId, localUserXuid, localUserIndex) {
    throw new Error("Method not implemented.");
  };

  MpsdPartyServiceProxy.prototype.getSessionDocumentById = function (sessionId) {
    var url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Get,
      url: url,
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    }).then(function (response) {
      return response.data;
    });
  };

  MpsdPartyServiceProxy.prototype.getSessionHandlesByScid = function (titleScid, clubId, tag) {
    var url = "https://sessiondirectory.xboxlive.com/handles/query?include=relatedInfo,roleInfo,activityInfo";
    var body = {
      type: "search",
      scid: titleScid,
      templateName: "global(lfg)",
      communicatePermissionRequired: true,
      orderBy: "createTime desc",
      includeScheduled: true,
      clubIds: clubId ? [clubId] : undefined
    };

    if (tag) {
      body["filter"] = "tags/any(d:tolower(d) eq '" + tag + "')";
    }

    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Post,
      url: url,
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader, {
        Name: "Accept-Language",
        Value: "en-US, en"
      }]),
      data: JSON.stringify(body)
    }).then(function (response) {
      return response.data.results;
    });
  };

  MpsdPartyServiceProxy.prototype.getSessionHandlesByClub = function (clubId, tag) {
    var url = "https://sessiondirectory.xboxlive.com/handles/query?include=relatedInfo,roleInfo,activityInfo";
    var body = {
      type: "search",
      templateName: "global(lfg)",
      communicatePermissionRequired: true,
      orderBy: "createTime desc",
      includeScheduled: true,
      clubIds: clubId ? [clubId] : undefined
    };

    if (tag) {
      body["filter"] = "tags/any(d:tolower(d) eq '" + tag + "')";
    }

    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Post,
      url: url,
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader, {
        Name: "Accept-Language",
        Value: "en-US, en"
      }]),
      data: JSON.stringify(body)
    }).then(function (response) {
      return response.data.results;
    });
  };

  MpsdPartyServiceProxy.prototype.deleteSessionHandleForClub = function (id, sessionName) {
    var url = "https://sessiondirectory.xboxlive.com/serviceconfigs/" + id + "/sessiontemplates/global(lfg)/sessions/" + sessionName;
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Delete,
      url: url,
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    });
  };

  MpsdPartyServiceProxy.prototype.setWebRtcClientInfo = function (sessionId, localUserXuid, localUserIndex, dtlsCertificateAlgorithm, dtlsCertificateThumbprint, iceUfrag, icePwd) {
    var _a;

    var url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
    var body = {
      members: (_a = {}, _a["me_" + localUserXuid] = {
        constants: {
          system: {
            index: localUserIndex
          }
        },
        properties: {
          custom: {
            webRtcDtlsCertificateAlgorithm: dtlsCertificateAlgorithm,
            webRtcDtlsCertificateThumbprint: dtlsCertificateThumbprint,
            webRtcIceUfrag: iceUfrag,
            webRtcIcePwd: icePwd,
            simpleConnectionState: ConnectionState_1.PartyChatConnectionState.Connecting
          }
        }
      }, _a)
    };
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Put,
      url: url,
      data: JSON.stringify(body),
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    }).then(function (response) {
      return response.data;
    });
  };

  MpsdPartyServiceProxy.prototype.setConnectionState = function (sessionId, localUserXuid, localUserIndex, connectionState) {
    var _a;

    var url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
    var body = {
      members: (_a = {}, _a["me_" + localUserXuid] = {
        constants: {
          system: {
            index: localUserIndex
          }
        },
        properties: {
          custom: {
            simpleConnectionState: connectionState
          }
        }
      }, _a)
    };
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Put,
      url: url,
      data: JSON.stringify(body),
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    }).then(function (response) {
      return response.data;
    });
  };

  MpsdPartyServiceProxy.prototype.inviteUser = function (sessionId, xuid) {
    var url = mpsdEndpoint + "/handles";
    var body = {
      type: "invite",
      sessionRef: {
        scid: scid,
        templateName: templateName,
        name: sessionId
      },
      invitedXuid: xuid
    };
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Post,
      url: url,
      data: JSON.stringify(body),
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    }).then(function (response) {
      return response.data;
    });
  };

  MpsdPartyServiceProxy.prototype.getPartySessionsForUser = function (xuid) {
    return __awaiter(this, void 0, void 0, function () {
      var url, response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions?xuid=" + xuid + "&followed=true";
            return [4
            /*yield*/
            , this.xblService.sendRequest({
              method: http_1.Http.HttpRequestMethod.Get,
              url: url,
              headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
            })];

          case 1:
            response = _a.sent();
            return [2
            /*return*/
            , response.data.results];
        }
      });
    });
  };

  MpsdPartyServiceProxy.prototype.getSessionJoinability = function (sessionId) {
    return __awaiter(this, void 0, void 0, function () {
      var url, response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId + "?nocommit=true&followed=true";
            return [4
            /*yield*/
            , this.xblService.sendRequest({
              method: http_1.Http.HttpRequestMethod.Get,
              url: url,
              headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
            })];

          case 1:
            response = _a.sent();
            return [2
            /*return*/
            , response.status === 200];
        }
      });
    });
  };

  MpsdPartyServiceProxy.prototype.removeLocalMember = function (sessionId, localUserXuid) {
    var _a;

    var body = {
      members: (_a = {}, _a["me_" + localUserXuid] = null, _a)
    };
    var url = mpsdEndpoint + "/serviceconfigs/" + scid + "/sessiontemplates/" + templateName + "/sessions/" + sessionId;
    return this.xblService.sendRequest({
      method: http_1.Http.HttpRequestMethod.Put,
      data: JSON.stringify(body),
      url: url,
      headers: __spreadArrays(standardMpsdHeaders, [ifMatchHeader])
    });
  };

  MpsdPartyServiceProxy = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IXalAuthProvider)), __param(1, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IXboxLiveRequestService)), __param(2, inversify_1.inject(XSocialSymbols_1.XSocialSymbols.IDeviceInfo)), __metadata("design:paramtypes", [Object, Object, Object])], MpsdPartyServiceProxy);
  return MpsdPartyServiceProxy;
}();

exports.MpsdPartyServiceProxy = MpsdPartyServiceProxy;