var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { ClubHub, ActivityFeed } from "@xbox/social-core";
import { LfgActions } from "@xbox/social-redux";
import { push } from "react-router-redux";

function coreLfgContainer(LfgPanelResolver, LoadingState, EmptyState) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.getLfgs = function (tag) {
          // if no games assigned to current club => fetch by club id
          // if games are assigned and not official => fetch by game and club id
          // if games are assigned and official => fetch only by game id
          // design is: in offical clubs we want to show all lfgs for that game regardless of club
          var scid = _this.props.clubItem.details.profile.associatedTitles && _this.props.clubItem.details.profile.associatedTitles.value && _this.props.clubItem.details.profile.associatedTitles.value.length && _this.props.clubItem.details.profile.associatedTitles.value[0].serviceConfigId;

          if (scid) {
            var payload = _this.props.clubItem.details.isOfficialClub ? {
              scid: scid
            } : {
              scid: scid,
              clubId: _this.props.clubItem.details.id
            };

            if (tag) {
              payload.tag = tag;
            }

            _this.props.getLfgs(payload);

            _this.setState({
              selectedTitle: _this.props.clubItem.details.profile.associatedTitles.value[0]
            });
          } else {
            _this.props.getLfgs({
              clubId: _this.props.clubItem.details.id
            });
          }
        };

        _this.selectTitle = function (e) {
          if (!e) {
            return;
          }

          var selected = _this.props.clubItem.details.profile.associatedTitles.value.find(function (t) {
            return t.serviceConfigId === e;
          });

          if (selected && selected.serviceConfigId) {
            _this.setState({
              selectedTitle: selected
            });

            var payload = _this.props.clubItem.details.isOfficialClub ? {
              scid: selected.serviceConfigId
            } : {
              scid: selected.serviceConfigId,
              clubId: _this.props.clubItem.details.id
            };

            _this.props.getLfgs(payload);
          }
        };

        _this.searchTags = function (tag) {
          _this.setState({
            query: tag
          });

          _this.getLfgs(tag);
        };

        _this.deleteItem = function (item, shouldBan) {
          if (shouldBan) {
            _this.actOnItem(ActivityFeed.AdminActionTypes.DeleteAndBan, item);
          } else {
            _this.actOnItem(ActivityFeed.AdminActionTypes.Delete, item);
          }
        };

        _this.report = function (item) {
          _this.actOnItem(ActivityFeed.AdminActionTypes.Report, item);
        };

        _this.actOnItem = function (action, item) {
          var payload = {
            action: action,
            clubId: _this.props.clubItem.details.id,
            xuid: item.relatedInfo.sessionOwners[0],
            item: item
          };

          _this.props.actOnLfgItem(payload);
        };

        _this.state = {};
        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.getLfgs();
      };

      class_1.prototype.componentWillUnmount = function () {
        this.props.resetLfgs();
      };

      class_1.prototype.render = function () {
        var handlers = {
          selectTitle: this.selectTitle,
          searchTags: this.searchTags
        };
        var adminActions = {
          delete: this.deleteItem,
          report: this.report
        };
        var showAdminActions = this.props.clubItem.settings && this.props.clubItem.settings.viewerRoles.roles.indexOf(ClubHub.ClubRoles.Moderator) >= 0 || false;
        return LfgPanelResolver.getLfgPanel(this.props.inProgress, showAdminActions, adminActions, this.props.clubItem, handlers, this.props.lfgs, this.state.selectedTitle, this.state.query);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    lfgs: state.lfgs.lfg,
    inProgress: state.lfgs.inProgress
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getLfgs: function getLfgs(payload) {
      return dispatch(LfgActions.getLfgs(payload));
    },
    navigateHome: function navigateHome(clubId) {
      return dispatch(push("/app/" + clubId + "/discussion/feed"));
    },
    actOnLfgItem: function actOnLfgItem(payload) {
      return dispatch(LfgActions.adminActOnItem(payload));
    },
    resetLfgs: function resetLfgs() {
      return dispatch(LfgActions.resetLfgs());
    }
  };
};

export function CoreLfgContainer(LfgPanel, LoadingState, EmptyState) {
  return connect(mapStateToProps, mapDispatchToProps)(coreLfgContainer(LfgPanel, LoadingState, EmptyState));
}