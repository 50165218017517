import * as React from "react";
import { FeedEngagementData } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers, BatchFeedItems } from "@xbox/social-views";
import { FeedEngamentBars } from "../../components/analytics/charts/FeedEngamenetBars";
import { FeedPostBars } from "../../components/analytics/charts/FeedPostBars";
import AnalyticsPieChart from "../../components/analytics/charts/AnalyticsPieChart";
import TopPosts from "../../components/analytics/TopPosts";

export interface FeedEngagementDataViewProps {
    engament?: JSX.Element | null;
    feedEngagementByLocale?: JSX.Element | null;
    topPosts?: JSX.Element | null;
    posts?: JSX.Element | null;
}

export const getFeedEngagementDataView = (month: string | undefined, socialActions: IFeedItemSocialActionHandlers, feedEngagementData?: FeedEngagementData, batchFeedItems?: BatchFeedItems): FeedEngagementDataViewProps => {
    let engament: JSX.Element | null;
    let feedEngagementByLocale: JSX.Element | null;
    let posts: JSX.Element | null;
    let topPosts: JSX.Element | null;

    engament = (
        feedEngagementData &&
        feedEngagementData.feedEngagement &&
        feedEngagementData.feedEngagement.timesliceData
    ) ? (<FeedEngamentBars timesliceData={feedEngagementData.feedEngagement.timesliceData} month={month} />) : <FeedEngamentBars />;

    feedEngagementByLocale = (
        feedEngagementData &&
        feedEngagementData.feedEngagement &&
        feedEngagementData.feedEngagement.engagementByLocale &&
        Object.keys(feedEngagementData.feedEngagement.engagementByLocale).length
    ) ? (<AnalyticsPieChart engagementByLocale={feedEngagementData.feedEngagement.engagementByLocale} />) : null;

    posts = (
        feedEngagementData &&
        feedEngagementData.userPosts &&
        feedEngagementData.userPosts.timesliceData
    ) ? (<FeedPostBars timesliceData={feedEngagementData.userPosts.timesliceData} month={month} />) : <FeedPostBars />;

    topPosts = (
        batchFeedItems
        && batchFeedItems.feedItems
    ) ? (<TopPosts socialActions={socialActions} batchFeedItems={batchFeedItems} />) : null;

    return {engament, topPosts, feedEngagementByLocale, posts};
};
