import * as React from "react";
import { Row, List, Select, Menu, Modal, Button, Popover, Dropdown, Input } from "antd";
import { ClubHub, edsImageResizer, EdsImageSize, ReportType, Person } from "@xbox/social-core";
import { CoreRosterContainer, IClubRosterResolver, IClubRosterHandlers, ClubModActions } from "@xbox/social-views";
import { withClubSelectionContext } from "../ClubSelectionContext";
import * as Styles from "./ClubAdmin.Styles";
import * as GlobalStyles from "../Styles";
import { Localize } from "../../common/translation/Localize";
import { GenericLoading, GenericEmptyState } from "../../common/GenericElements";
import { Divider } from "../../common/generic/Divider";
import { GetReportOffenseModal } from "../../components/ReportOffenseMenu/ReportOffenseMenu";
import { ThemedSelect, InlineContent } from "../../common/Generic.Styles";
import ProfileCard from "../../components/profileCard/ProfileCard";
import Util from "../../common/Util";

const { Option } = Select;
const { Search } = Input;

const EmptyState = () => {
    const gutters = { xs: 8, sm: 16, md: 24 };
    return <GenericEmptyState message={Localize("clubRoster.emptyState")} gutters={gutters} />;
};

const confirm = Modal.confirm;

function getClubRosterPanel(members: ClubHub.ClubMember[], handlers: IClubRosterHandlers, currentUser: ClubHub.ClubPresenceItem, searchValue?: string) {
    function getButtonVisibility(element: JSX.Element, currentAction: ClubModActions, currentUserRole: string | undefined, itemRole?: string) {
        switch (currentAction) {
            case ClubModActions.promote:
                return currentUserRole === ClubHub.ClubRoles.Owner && itemRole !== ClubHub.ClubRoles.Banned && itemRole !== ClubHub.ClubRoles.Moderator ? element : null;

            case ClubModActions.demote:
                return currentUserRole === ClubHub.ClubRoles.Owner && itemRole === ClubHub.ClubRoles.Moderator ? element : null;

            case ClubModActions.ban:
                return (currentUserRole === ClubHub.ClubRoles.Owner || currentUserRole === ClubHub.ClubRoles.Moderator) && itemRole !== ClubHub.ClubRoles.Banned ? element : null;

            case ClubModActions.unban:
                return (currentUserRole === ClubHub.ClubRoles.Owner || currentUserRole === ClubHub.ClubRoles.Moderator) && itemRole === ClubHub.ClubRoles.Banned ? element : null;

            case ClubModActions.remove:
                return currentUserRole === ClubHub.ClubRoles.Owner || currentUserRole === ClubHub.ClubRoles.Moderator ? element : null;

            case ClubModActions.reportUser:
                return element;

            default:
                return null;
        }
    }

    function ReportUserAction(reporterXuid: string, reportType: ReportType, reportedXuid: string) {
        handlers.reportUser(reporterXuid, reportType, reportedXuid);
    }

    function selectedModAction(action: string, item: ClubHub.ClubMember) {
        let gamertag = item.gamertag;
        switch (action) {
            case ClubModActions.ban:
                confirm({
                    title: Localize("clubRoster.banConfirmTitle", { gamertag: gamertag }),
                    content: Localize("clubRoster.banConfirmText"),
                    okText: Localize("generic.okay"),
                    okType: "primary",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        handlers.updateRole(item, action);
                    }
                });
                break;
            case ClubModActions.unban:
                handlers.updateRole(item, action);
                break;
            case ClubModActions.promote:
                confirm({
                    title: Localize("clubRoster.promoteToAdmin", { gamertag: gamertag }),
                    content: Localize("clubRoster.promoteToAdminConfirmTitle", { gamertag: gamertag }),
                    okText: Localize("generic.okay"),
                    okType: "primary",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        handlers.updateRole(item, action);
                    }
                });
                break;
            case ClubModActions.demote:
                handlers.updateRole(item, action);
                break;
            case ClubModActions.remove:
                confirm({
                    title: Localize("clubRoster.removeConfirmTitle", { gamertag: gamertag }),
                    okText: Localize("generic.okay"),
                    okType: "primary",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        handlers.updateRole(item, action);
                    }
                });
                break;
            case "reportOffenseMenu":
                GetReportOffenseModal(currentUser.profile.xuid, item.gamertag, item.xuid, ReportUserAction);
                break;
            default:
                return;
        }
    }

    function getMember(item: ClubHub.ClubMember) {
        let friendship: any = <Styles.HorizontalSeparator />;
        if (item) {
            if (item.isFavorite) {
                friendship = <Styles.FavoriteLabel>{Localize("userDetails.favorite")}</Styles.FavoriteLabel>;
            } else if (item.isFollowingCaller) {
                friendship = <Styles.FriendLabel>{Localize("userDetails.friend")}</Styles.FriendLabel>;
            } else if (item.isFollowedByCaller) {
                friendship = <Styles.FollowsYouLabel>{Localize("userDetails.followsYou")}</Styles.FollowsYouLabel>;
            }

            let userRole: string = "";
            if (item.role === ClubHub.ClubRoles.Moderator) {
                userRole = ` (${Localize("clubSettings.Moderator")})`;
            } else if (item.role === ClubHub.ClubRoles.Owner) {
                userRole = ` (${Localize("clubSettings.Owner")})`;
            }

            function getProfileCard(elem: any, person: Person) {
                return (
                    <ProfileCard person={person} key={person.gamertag} element={elem}/>
                );
            }

            let actionButton = <Button style={{ float: "right" }}>{Localize("generic.actions")}</Button>;

            // if item and currentUser are both Owner or both Moderator, then button won't appear.
            if (item.role === ClubHub.ClubRoles.Owner || (item.role === ClubHub.ClubRoles.Moderator && currentUser.profile.role !== ClubHub.ClubRoles.Owner)) {
                actionButton = <div />;
            }

            let menu: JSX.Element | null =  currentUser ? (
                <Menu onClick={k => selectedModAction(k.key, item)}>
                    {getButtonVisibility(<Menu.Item key={ClubModActions.promote}>{Localize("clubRoster.promoteToAdmin")}</Menu.Item>, ClubModActions.promote, currentUser.profile.role, item.role)}
                    {getButtonVisibility(<Menu.Item key={ClubModActions.demote}>{Localize("clubRoster.removeAsAdmin")}</Menu.Item>, ClubModActions.demote, currentUser.profile.role, item.role)}
                    {getButtonVisibility(<Menu.Item key={ClubModActions.ban}>{Localize("clubRoster.ban")}</Menu.Item>, ClubModActions.ban, currentUser.profile.role, item.role)}
                    {getButtonVisibility(<Menu.Item key={ClubModActions.unban}>{Localize("clubRoster.unban")}</Menu.Item>, ClubModActions.unban, currentUser.profile.role, item.role)}
                    {getButtonVisibility(<Menu.Item key={ClubModActions.remove}>{Localize("clubRoster.remove")}</Menu.Item>, ClubModActions.remove, currentUser.profile.role, item.role)}
                    {getButtonVisibility(<Menu.Item key={"reportOffenseMenu"}>{Localize("clubRoster.reportUser")}</Menu.Item>, ClubModActions.reportUser, currentUser.profile.role, item.role)}
                </Menu>
            ) : null;

            return (
                <List.Item key={item.xuid}>
                    <div style={{ clear: "both", width: "100%" }}>
                        <div style={{ float: "left" }}>
                            {getProfileCard(<Styles.Avatar src={edsImageResizer(Util.ensureSSLImage(item.displayPicRaw), EdsImageSize._100x100)} />, item)}
                        </div>
                        <div style={{ float: "right" }}>
                            {menu &&
                                <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
                                    {actionButton}
                                </Dropdown>
                            }
                        </div>
                        <div>
                            {getProfileCard(<Styles.BodyText>{item.gamertag}</Styles.BodyText>, item)}<Styles.BodySubText>{userRole}</Styles.BodySubText>
                            {friendship}
                        </div>
                    </div>
                </List.Item>
            );
        }
        return <div />;
    }

    let items = members.length > 0 ? (
        <List
            itemLayout="horizontal"
            dataSource={members}
            renderItem={(item: ClubHub.ClubMember) => getMember(item)}
            pagination={{
                pageSize: 20,
            }}
        />
    ) : <EmptyState />;

    let addFriend = (
        <Search
            maxLength={15}
            placeholder={Localize("ClubInviteQ.searchPlaceholder")}
            enterButton={Localize("ClubInviteQ.inviteButton")}
            size="default"
            autoFocus={true}
            onSearch={value => handlers.inviteUser(value)}
        />
    );

    return (
        <GlobalStyles.ContentContainer>
            <Row>
                <GlobalStyles.ContentTitle>
                    {Localize("clubRoster.title")}
                </GlobalStyles.ContentTitle>
            </Row>
            <Row>
                <InlineContent style={{ marginRight: 10, marginBottom: 10 }}>
                    <Styles.GamertagSearch
                        style={{ width: "100%", maxWidth: "300px" }}
                        placeholder={Localize("generic.search")}
                        onSearch={value => handlers.search(value)}
                        onBlur={e => handlers.search(e.target.value)}
                        onChange={e => handlers.onChange(e.target.value)}
                        aria-label="search"
                        value={searchValue}
                    />
                </InlineContent>

                <Styles.AdminInlineBlock>
                    <ThemedSelect
                        aria-label={Localize("clubRoster.title") + " " + Localize("generic.filter")}
                        placeholder={Localize("generic.filter")}
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onChange={value => handlers.filter(value)}
                    >
                        <Option value="all">{Localize("clubRoster.all")}</Option>
                        <Option value={ClubHub.ClubRoles.Moderator}>{Localize("clubRoster.administrators")}</Option>
                        <Option value={ClubHub.ClubRoles.Owner}>{Localize("clubRoster.owner")}</Option>
                        <Option value={ClubHub.ClubRoles.Member}>{Localize("clubRoster.members")}</Option>
                        <Option value={ClubHub.ClubRoles.Banned}>{Localize("clubRoster.banned")}</Option>
                    </ThemedSelect>
                </Styles.AdminInlineBlock>
                <InlineContent>
                    <Popover content={addFriend} destroyTooltipOnHide={true} trigger="click">
                        <Button>{Localize("clubRoster.inviteUser")}</Button>
                    </Popover>
                </InlineContent>
            </Row>
            <Row>
                <Divider />
            </Row>
            <Row>
                {items}
            </Row>
        </GlobalStyles.ContentContainer>
    );
}

const ClubRosterResolver: IClubRosterResolver = {
    getClubRosterPanel: getClubRosterPanel
};

export default withClubSelectionContext(CoreRosterContainer(GenericLoading, EmptyState, ClubRosterResolver));
