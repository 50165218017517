"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaServiceType = void 0;
var MediaServiceType;

(function (MediaServiceType) {
  MediaServiceType["Screenshots"] = "screenshots";
  MediaServiceType["Gameclips"] = "gameclips";
  MediaServiceType["CustomPics"] = "customPics";
})(MediaServiceType = exports.MediaServiceType || (exports.MediaServiceType = {}));