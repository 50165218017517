import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers } from "@xbox/social-views";

import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";
import { Localize } from "../../common/translation/Localize";
import { Glyphs } from "../../common/Generic.Icons";

export interface SocialActionsProps {
    item: ActivityFeed.FeedItem;
    showSocialActions?: boolean;
    socialActions?: IFeedItemSocialActionHandlers;
}

export interface SocialActionsState {
    showComments?: boolean;
}

export class AnalyticsSocialActionsContainer extends React.Component<SocialActionsProps, SocialActionsState> {
    constructor(props: SocialActionsProps) {
        super(props);
        this.state = {
            showComments: false
        };
    }

    showComments = () => {
        if (this.props.socialActions && this.props.socialActions.viewComments) {
            this.props.socialActions.viewComments(this.props.item);
        }
    }

    render() {
        return (
            <FeedItemStyles.SocialButtonsContainer className="SocialActionsContainer" style={{justifyContent: "space-around"}}>
                <FeedItemStyles.SocialButton className="SocialButton" aria-label="like">
                    <FeedItemStyles.SocialCount>
                        <FeedItemStyles.SocialIcon type={this.props.item.hasLiked ? Glyphs.HeartFull : Glyphs.Heart} style={this.props.item.hasLiked ? { color: "red" } : {}} />{this.props.item.stat ? this.props.item.stat.likes : 0}
                    </FeedItemStyles.SocialCount>
                </FeedItemStyles.SocialButton>
                <FeedItemStyles.SocialButton className="SocialButton" role="link" aria-label="Comment">
                    <FeedItemStyles.SocialCount>
                        <FeedItemStyles.SocialIcon type={Glyphs.Chat} />{this.props.item.stat ? this.props.item.stat.comments : 0}
                    </FeedItemStyles.SocialCount>
                </FeedItemStyles.SocialButton>
                <FeedItemStyles.SocialButton style={{height: "100%"}} className="SocialButton" aria-label="Details" onClick={this.showComments}>
                    <FeedItemStyles.SocialText>
                        {Localize("analyticsView.postSeePostDetails")}
                    </FeedItemStyles.SocialText>
                </FeedItemStyles.SocialButton>
            </FeedItemStyles.SocialButtonsContainer>
        );
    }
}
