var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { TagPickerActions, ClubActions, getTagPicker } from "@xbox/social-redux";

function coreTagPickerContainer(resolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.resetTags = function (club) {
          if (!club) {
            return;
          }

          var tags = club.details.profile.tags.value;
          var selected = [];

          _this.props.combinedTagsList.forEach(function (tag) {
            if (tags.indexOf(tag.tagId) >= 0) {
              selected.push(tag);
            }
          });

          _this.setState({
            selectedTags: selected,
            updatedTags: true
          });
        };

        _this.tagSelected = function (tag) {
          if (!tag) {
            return;
          }

          var selected = _this.state.selectedTags;

          if (selected) {
            var index = selected.findIndex(function (t) {
              return t.tagId === tag.tagId;
            });

            if (index >= 0) {
              selected.splice(index, 1);
            } else {
              selected.push(tag);
            }
          }

          _this.setState({
            selectedTags: selected
          });
        };

        _this.updateProfile = function (updatedPropertyPath, updatedProperty, value) {
          if (_this.props.club) {
            var settingsPayload = {
              clubId: _this.props.club.details.id,
              update: {
                modifiedFields: [updatedProperty],
                requestContract: {}
              },
              updatedProperty: updatedPropertyPath,
              updatedValue: value
            };

            if (settingsPayload.update) {
              settingsPayload.update.requestContract[updatedProperty] = value;
            }

            _this.props.updateProfile(settingsPayload);
          }
        };

        _this.submitTags = function () {
          var selectedTags = _this.state.selectedTags.map(function (tag) {
            return tag.tagId;
          });

          _this.props.submitTags(selectedTags);

          _this.setState({
            isVisible: false
          });
        };

        _this.showTagPicker = function () {
          _this.setState({
            isVisible: true
          });
        };

        _this.hideTagPicker = function () {
          _this.setState({
            isVisible: false
          });

          _this.resetTags(_this.props.club);
        };

        _this.state = {
          isVisible: false,
          selectedTags: [],
          updatedTags: false
        };
        return _this;
      }

      class_1.prototype.componentDidMount = function () {
        this.props.getTags();
      };

      class_1.prototype.componentWillUpdate = function () {
        if (!this.state.updatedTags && this.props.club && this.state.selectedTags.length === 0) {
          this.resetTags(this.props.club);
        }
      };

      class_1.prototype.render = function () {
        var handlers = {
          tagSelected: this.tagSelected,
          submitTags: this.submitTags,
          showTagPicker: this.showTagPicker,
          hideTagPicker: this.hideTagPicker
        };
        return resolver.getTagPickerPanel(this.props.isEditible ? this.props.isEditible : false, this.state.isVisible, this.state.selectedTags, handlers, this.props);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  return getTagPicker(state);
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    updateProfile: function updateProfile(update) {
      return dispatch(ClubActions.updateClubSettings(update));
    },
    getTags: function getTags() {
      return dispatch(TagPickerActions.getTags());
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreTagPickerContainer(resolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreTagPickerContainer(resolver));
}