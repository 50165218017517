import styled from "styled-components";
import { ThemedModal } from "../Styles";
import { media } from "../../common/Generic.SnapPoints";

export const PreviewItemModal = ThemedModal.extend`
    overflow: auto;

    .ant-modal-content {
        margin: auto;
        max-width: 760px;
    }

    .ant-modal-body {
        overflow: auto;
        border: 3px solid ${props => props.theme.foregroundOverlayed};
    }

    .ant-modal-footer {
        display: none;
    }

    //mobile
    ${media.small`
        .ant-modal-content {
            width: 100%;
            height: 100%;
            margin: 0;
        }
        .ant-modal-body {
            overflow: auto;
            border: none;
        }
    `}
`;

export const FeedItemModal = PreviewItemModal.extend`
    max-width: 960px;
    min-width: 320px;
`;

export const FullscreenImageModal = PreviewItemModal.extend`
    max-width: 1280px;
    min-width: 320px;

    .ant-modal-content {
        overflow: hidden;
    }

    .ant-modal-close {
        right: -1rem;
        top: -1rem;
    }

    //mobile
    ${media.small`
        .ant-modal-content {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    `}
`;

export const FlexCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
