"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.feedbackReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var social_core_1 = require("@xbox/social-core");

var FeedbackActions_1 = require("../../actions/FeedbackActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.feedbackReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(FeedbackActions_1.FeedbackActions.submitFeedbackInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(FeedbackActions_1.FeedbackActions.clearFeedback, function (state) {
  var newState = state.setIn(["content"], undefined);
  newState = newState.setIn(["type"], undefined);
  return newState;
}).on(FeedbackActions_1.FeedbackActions.updateFeedbackContent, function (state, action) {
  var newState = state;

  if (action.payload.content) {
    newState = state.setIn(["content"], action.payload.content);
  }

  return newState.setIn(["type"], action.payload.type ? action.payload.type : social_core_1.ReportFeedbackTypes.GeneralFeedback);
}).build();