"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CancellationToken = exports.CancellationErrorMsg = void 0; // Plan to be referenced only inside redux package.
// CancellationToken hides ability to cancel it, and should be used only to check cancellation state

exports.CancellationErrorMsg = "Task cancelled";

var CancellationToken =
/** @class */
function () {
  function CancellationToken() {
    this.cancelled = false;
  }

  CancellationToken.prototype.isCancelled = function () {
    return this.cancelled === true;
  };

  CancellationToken.prototype.throwIfCancelled = function () {
    if (this.cancelled) {
      throw Error(exports.CancellationErrorMsg);
    }
  };

  CancellationToken.prototype[Symbol.for("_cancel_")] = function () {
    this.cancelled = true;
  };

  return CancellationToken;
}();

exports.CancellationToken = CancellationToken;