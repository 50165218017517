import * as React from "react";

interface PersonEventsViewerProps {
}

interface PersonEventsViewerState {
}

export class PersonEventsViewer extends React.Component<PersonEventsViewerProps, PersonEventsViewerState> {
    constructor(props: PersonEventsViewerProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1>this is person events page</h1>
            </div>
        );
    }
}
