"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubDetailsReducer = void 0;

var _ = require("lodash");

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var social_core_1 = require("@xbox/social-core");

var ClubListActions_1 = require("../../actions/ClubListActions"); // reducer


var INITIAL_STATE = {
  fetchInProgress: false,
  safetyInProgress: false,
  editInProgress: false
};
var indexedSettings = ["feedChannels", "chatChannels"];
exports.clubDetailsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubDetailsFetchInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    fetchInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.fetchSafetyInProgress, function (state, action) {
  return __assign(__assign({}, state), {
    safetyInProgress: action.payload
  });
}).on(ClubListActions_1.ClubActions.fetchSafetyCompleted, function (state, action) {
  if (typeof action.payload === "string") {
    // we got value as a string on Update from our side
    var sTD = Object.assign({}, state.safetyThresholdData, {
      value: action.payload
    });
    return __assign(__assign({}, state), {
      safetyThresholdData: sTD
    });
  }

  return __assign(__assign({}, state), {
    safetyThresholdData: action.payload
  });
}).on(ClubListActions_1.ClubActions.clearCurrentClubDetails, function (state, action) {
  return __assign(__assign({}, state), {
    club: undefined,
    safetyThresholdData: undefined
  });
}).on(ClubListActions_1.ClubActions.clubDetailsFetchCompleted, function (state, action) {
  var clubDetails = _.cloneDeep(action.payload);

  _.map(clubDetails.feedChannels, function (item) {
    return _.assign(item, {
      type: "feed"
    });
  });

  _.map(clubDetails.chatChannels, function (item) {
    return _.assign(item, {
      type: "chat"
    });
  });

  return __assign(__assign({}, state), {
    club: clubDetails
  });
}).on(ClubListActions_1.ClubActions.clubUpdateProperty, function (state, action) {
  var _a, _b;

  if (state.club && action.payload.updatedProperty !== "") {
    if (action.payload.update && action.payload.update.modifiedFields[0] === "preferredColor") {
      var club = __assign(__assign({}, state.club), (_a = {}, _a[action.payload.updatedProperty] = action.payload.updatedValue.primaryColor, _a));

      return __assign(__assign({}, state), {
        club: club
      });
    } else if (indexedSettings.indexOf(action.payload.updatedProperty[0]) !== -1) {
      var updatePath = action.payload.updatedProperty;

      var club = _.cloneDeep(state.club);

      var key = updatePath[0];
      var index = updatePath[1];
      var updateProperty = Immutable(club[updatePath[0]][updatePath[1]]);
      updatePath.splice(0, 2);
      updateProperty = updateProperty.setIn(updatePath, action.payload.updatedValue);
      club[key][index] = updateProperty;
      return __assign(__assign({}, state), {
        club: club
      });
    } else {
      var club = __assign(__assign({}, state.club), (_b = {}, _b[action.payload.updatedProperty] = action.payload.updatedValue, _b));

      return __assign(__assign({}, state), {
        club: club
      });
    }
  }

  return state;
}).on(ClubListActions_1.ClubActions.clubRosterUpdateRoleProperty, function (state, action) {
  if (state.club) {
    var club = __assign({}, state.club);

    if (club.clubPresence) {
      var roster = club.clubPresence.items;

      if (roster) {
        var personToUpdate = roster.find(function (p) {
          return p.profile.xuid === action.payload.xuid;
        });

        if (personToUpdate) {
          if (action.payload.add) {
            personToUpdate.profile.role = action.payload.role;
          } else {
            switch (action.payload.role) {
              case social_core_1.ClubHub.ClubRoles.Banned:
                personToUpdate.profile.role = social_core_1.ClubHub.ClubRoles.Member;
                break;

              case social_core_1.ClubHub.ClubRoles.Moderator:
                personToUpdate.profile.role = social_core_1.ClubHub.ClubRoles.Member;
                break;

              case social_core_1.ClubHub.ClubRoles.Member:
                roster = roster.splice(roster.indexOf(personToUpdate), 1);
                break;

              default:
            }
          }

          return __assign(__assign({}, state), {
            club: club
          });
        }
      }
    }
  }

  return state;
}).on(ClubListActions_1.ClubActions.moveChannelComplete, function (state, action) {
  if (state.club) {
    var club = __assign({}, state.club);

    if (club.feedChannels) {
      var channelToMove_1 = action.payload.channelId;
      var newGroup_1 = action.payload.group;
      var index = club.feedChannels.findIndex(function (c) {
        return c.id === channelToMove_1;
      });

      if (index > 0) {
        if (newGroup_1 === "" || newGroup_1.toLowerCase() === "default") {
          club.feedChannels[index].group = undefined;
        } else {
          var channel = club.feedChannels.find(function (c) {
            return c.group ? c.group.name === newGroup_1 : false;
          });
          club.feedChannels[index].group = channel && channel.group;
        }

        return __assign(__assign({}, state), {
          club: club
        });
      }
    }
  }

  return state;
}).on(ClubListActions_1.ClubActions.deleteClubChannelComplete, function (state, action) {
  if (state.club) {
    var club = _.cloneDeep(state.club);

    if (club.feedChannels) {
      var channelToDelete_1 = action.payload.channelId;
      var index = club.feedChannels.findIndex(function (c) {
        return c.id.toString() === channelToDelete_1;
      });

      if (index > 0) {
        club.feedChannels.splice(index, 1);
        return __assign(__assign({}, state), {
          club: club
        });
      }
    }
  }

  return state;
}).on(ClubListActions_1.ClubActions.fetchClubFeedChannelsCompleted, function (state, action) {
  var feedChannels = _.cloneDeep(action.payload);

  _.map(feedChannels, function (item) {
    return _.assign(item, {
      type: "feed"
    });
  });

  var club = Object.assign(state.club, {
    feedChannels: feedChannels
  });
  return __assign(__assign({}, state), {
    club: club
  });
}).on(ClubListActions_1.ClubActions.updateClubSettingsInProgress, function (state, action) {
  // After an attempt to update club settings, editInProgress could remain true when
  // the update operation fails so that we can give the user an opportunity to retry without
  // losing any unsaved changes
  return __assign(__assign({}, state), {
    editInProgress: action.payload
  });
}).build();