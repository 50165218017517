import * as React from "react";
import { omit } from "lodash";
import { LayoutContext } from "./LayoutContext";
import { withContext } from "./ContextUtils";
import { SideDiv } from "./ShellLayout.Styles";

interface PushSideMenuProps extends LayoutContext {
    style: React.CSSProperties;
}

interface PushSideMenuState {
    isOpen: boolean;
}

class PushSideMenu extends React.Component<PushSideMenuProps, PushSideMenuState> {
    constructor(props: PushSideMenuProps) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    _handleToggleClick = () => {
        const newValue = !this.state.isOpen;
        this.setState({ isOpen: newValue });
        if (this.props.onToggleSider) {
            this.props.onToggleSider(newValue);
        }
    }

    componentDidUpdate(prevProps: PushSideMenuProps) {
        if (this.props.isOpen !== undefined && prevProps.isOpen !== this.props.isOpen) {
            this.setState({isOpen: this.props.isOpen});
            if (this.props.onToggleSider) {
                this.props.onToggleSider(this.props.isOpen);
            }
        }
    }

    render() {
        // setup target properties
        const { children, style, ...extraProps } = this.props;

        const finalProps = omit(extraProps, "onToggleSider");

        // setup the proper styles
        const { minWidth, width, ...otherStyles } = style;
        const targetStyle = {
            width: this.props.isOpen ? width : minWidth ? minWidth : 0,
            ...otherStyles
        };

        return (
            <SideDiv style={targetStyle} {...finalProps}>
                {children}
            </SideDiv>
        );
    }
}

export default withContext(PushSideMenu);
