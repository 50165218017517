"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaHubService = exports.GetAzureHttpHeaders = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var typings_1 = require("../../typings");

var utils_1 = require("../../utils"); // More info: xboxwiki/wiki/GameMedia


function GetAzureHttpHeaders(_blobSize, msVersion) {
  var dataUTC = new Date().toUTCString();
  var blobHeaders = [{
    Name: "Content-Type",
    Value: "xblc-binary"
  }, {
    Name: "x-ms-version",
    Value: msVersion
  }, {
    Name: "x-ms-date",
    Value: dataUTC
  }];
  return blobHeaders;
}

exports.GetAzureHttpHeaders = GetAzureHttpHeaders;

var MediaHubService =
/** @class */
function () {
  function MediaHubService(xblService, httpService) {
    this.xblService = xblService;
    this.httpService = httpService;
  }

  MediaHubService_1 = MediaHubService;

  MediaHubService.prototype.getMediaItems = function (mediaType, dataRequest) {
    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/" + mediaType + "/search",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(dataRequest),
      headers: MediaHubService_1.headers
    });
  };

  MediaHubService.prototype.getMediaItemMetadata = function (mediaType, contentIds) {
    var contentData = {
      ContentIds: contentIds
    };
    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/" + mediaType + "/batch",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(contentData),
      headers: MediaHubService_1.headers
    });
  };

  MediaHubService.prototype.uploadMediaItem = function (mediaType, dataRequest) {
    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/" + mediaType + "/Create",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(dataRequest),
      headers: MediaHubService_1.headers
    });
  };

  MediaHubService.prototype.deleteMediaItem = function (mediaType, contentId) {
    var headers = utils_1.GetGenericHeaders(3); // expected respond is 202 - ACCEPTED

    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/" + mediaType + "/" + contentId,
      method: http_1.Http.HttpRequestMethod.Delete,
      headers: headers
    });
  };

  MediaHubService.prototype.getUsersMediaTitles = function (xuid) {
    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/users/xuid(" + xuid + ")/titles",
      method: http_1.Http.HttpRequestMethod.Get,
      headers: MediaHubService_1.headers
    });
  };

  MediaHubService.prototype.getUsersMediaQuota = function (xuid) {
    return this.xblService.sendRequest({
      url: MediaHubService_1.rootUrl + "/users/xuid(" + xuid + ")/quota",
      method: http_1.Http.HttpRequestMethod.Get,
      headers: MediaHubService_1.headers
    });
  };
  /*********************************************************************/

  /* Upload Media Items - Supporting functions
  /*********************************************************************/


  MediaHubService.prototype.sendDataSlice = function (uploadUri, blockId, dataSlice) {
    // most common ms version, use in case if uploadUri doesn't contain one
    var msVersion = "2015-12-11";
    var msVsPattern = "sv=";
    var index = uploadUri.indexOf(msVsPattern);
    msVersion = index >= 0 ? uploadUri.substr(index + msVsPattern.length, msVersion.length) : msVersion; // block number should contain not more than 7 digits

    var idStr = ("0000000" + blockId).slice(-7);
    var encBlockId = btoa("BlockId" + idStr);
    var headers = GetAzureHttpHeaders(dataSlice.length, msVersion);
    var dataSliceBinary = utils_1.Base64Binary.base64ToBinary(dataSlice);
    var httpRequestserviceBuilder = new http_1.HttpRequestServiceBuilder();
    return httpRequestserviceBuilder.build().sendRequest({
      url: uploadUri + "&comp=block&blockId=" + encBlockId,
      method: http_1.Http.HttpRequestMethod.Put,
      data: dataSliceBinary,
      headers: headers
    });
  };

  MediaHubService.prototype.updateMediaItemReputation = function (contentId, isVideo) {
    var headers = utils_1.GetGenericHeaders(101);
    var type = isVideo ? "UserContentReviewRequestGameDVR" : "UserContentReviewRequestScreenshot";
    var data = {
      feedbackType: type,
      evidenceId: contentId
    };
    return this.xblService.sendRequest({
      url: "https://reputation.xboxlive.com/users/me/feedback",
      method: http_1.Http.HttpRequestMethod.Post,
      headers: headers,
      data: JSON.stringify(data)
    });
  };

  MediaHubService.prototype.publishMediaItem = function (publishUri) {
    var headers = utils_1.GetGenericHeaders(2); // expected respond is 202 - ACCEPTED

    return this.xblService.sendRequest({
      url: "" + publishUri,
      method: http_1.Http.HttpRequestMethod.Post,
      headers: headers
    });
  };

  var MediaHubService_1;
  MediaHubService.headers = utils_1.GetGenericHeaders(3);
  MediaHubService.rootUrl = "https://mediahub.xboxlive.com";
  MediaHubService = MediaHubService_1 = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IHttpRequestService)), __metadata("design:paramtypes", [Object, Object])], MediaHubService);
  return MediaHubService;
}();

exports.MediaHubService = MediaHubService;