"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImagePickerSaga = void 0;

var inversify_1 = require("inversify");

var effects_1 = require("redux-saga/effects");

var social_core_1 = require("@xbox/social-core");

var ImagePickerActions_1 = require("../../actions/ImagePickerActions");

var Utils_1 = require("../Utils");

var ImagePickerSaga =
/** @class */
function () {
  function ImagePickerSaga(ImagePickerService, telemetryService) {
    this.ImagePickerService = ImagePickerService;
    this.telemetryService = telemetryService;
    this.sagas = [this.watchGetImages()];
  }

  ImagePickerSaga.prototype._getImages = function (action) {
    var imageUri, xlSizeSuffix, mdSizeSuffix, types, response, gamerpics, gamerpicImages, clubpics, clubpicImages, captures, clubpicImages, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          imageUri = "https://dlassets-ssl.xboxlive.com/public/content/ppl/gamerpics/";
          xlSizeSuffix = "-xl.png";
          mdSizeSuffix = "-md.png";
          _a.label = 1;

        case 1:
          _a.trys.push([1, 12,, 13]);

          types = action.payload.types ? action.payload.types : social_core_1.ImageSources.All;
          response = new Map();
          if (!(action.payload.updateImageType && action.payload.updateImageType === social_core_1.UpdateImageTypes.profile)) return [3
          /*break*/
          , 7];
          return [4
          /*yield*/
          , effects_1.put(ImagePickerActions_1.ImagePickerActions.imageTypeSelected(social_core_1.UpdateImageTypes.profile))];

        case 2:
          _a.sent();

          if (!(types === social_core_1.ImageSources.All || types === social_core_1.ImageSources.GenericProfiles)) return [3
          /*break*/
          , 4];
          return [4
          /*yield*/
          , this.ImagePickerService.getGamerpics()];

        case 3:
          gamerpics = _a.sent();
          gamerpicImages = gamerpics.data.gamerpics.map(function (res) {
            var image = {
              large: imageUri + res.id + xlSizeSuffix,
              medium: imageUri + res.id + mdSizeSuffix,
              imageType: social_core_1.ImageSources.GenericProfiles
            };
            return image;
          });
          response[social_core_1.ImageSources.GenericProfiles] = gamerpicImages;
          _a.label = 4;

        case 4:
          if (!(types === social_core_1.ImageSources.All || types === social_core_1.ImageSources.GenericClubs)) return [3
          /*break*/
          , 6];
          return [4
          /*yield*/
          , this.ImagePickerService.getClubpics()];

        case 5:
          clubpics = _a.sent();
          clubpicImages = clubpics.data.clubpics.map(function (res) {
            var image = {
              large: imageUri + res.id + xlSizeSuffix,
              medium: imageUri + res.id + mdSizeSuffix,
              imageType: social_core_1.ImageSources.GenericClubs
            };
            return image;
          });
          response[social_core_1.ImageSources.GenericClubs] = clubpicImages;
          _a.label = 6;

        case 6:
          return [3
          /*break*/
          , 10];

        case 7:
          if (!(action.payload.updateImageType && action.payload.updateImageType === social_core_1.UpdateImageTypes.background)) return [3
          /*break*/
          , 10];
          return [4
          /*yield*/
          , effects_1.put(ImagePickerActions_1.ImagePickerActions.imageTypeSelected(social_core_1.UpdateImageTypes.background))];

        case 8:
          _a.sent();

          if (!(action.payload.xuid && (types === social_core_1.ImageSources.All || types === social_core_1.ImageSources.Captures))) return [3
          /*break*/
          , 10];
          return [4
          /*yield*/
          , this.ImagePickerService.getCaptures(action.payload.xuid)];

        case 9:
          captures = _a.sent();
          clubpicImages = captures ? captures.data.values.map(function (res) {
            var download = res.contentLocators.find(function (c) {
              return c.locatorType === social_core_1.ContentLocatorType.Download;
            });
            var thumbnail = res.contentLocators.find(function (c) {
              return c.locatorType === social_core_1.ContentLocatorType.ThumbSmall;
            });

            if (!download || !thumbnail) {
              return null;
            }

            var image = {
              large: download && download.uri,
              medium: thumbnail && thumbnail.uri,
              imageType: social_core_1.ImageSources.Captures
            };
            return image;
          }) : null;

          if (clubpicImages) {
            response[social_core_1.ImageSources.Captures] = clubpicImages;
          }

          _a.label = 10;

        case 10:
          return [4
          /*yield*/
          , effects_1.put(ImagePickerActions_1.ImagePickerActions.getImagesCompleted(response))];

        case 11:
          _a.sent();

          return [3
          /*break*/
          , 13];

        case 12:
          err_1 = _a.sent();
          Utils_1.LogHttpsError("Error submitting ImagePicker", err_1);
          this.telemetryService.trackException(err_1, "ImagePicker", {
            "action": "select image"
          });
          return [3
          /*break*/
          , 13];

        case 13:
          return [2
          /*return*/
          ];
      }
    });
  };

  ImagePickerSaga.prototype.watchGetImages = function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , effects_1.takeEvery(ImagePickerActions_1.ImagePickerActions.getImages.type, this._getImages.bind(this))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  ImagePickerSaga = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(social_core_1.XSocialSymbols.IImagePickerService)), __param(1, inversify_1.inject(social_core_1.XSocialSymbols.ITelemetryService)), __metadata("design:paramtypes", [Object, Object])], ImagePickerSaga);
  return ImagePickerSaga;
}();

exports.ImagePickerSaga = ImagePickerSaga;