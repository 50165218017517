"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefreshTtoken = void 0;

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var RefreshXboxTokenBase_1 = require("./RefreshXboxTokenBase");

var RefreshTtoken =
/** @class */
function (_super) {
  __extends(RefreshTtoken, _super); // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL.
  // XAL's existing implmentation is a stale one using RPS.
  // This implementation is for acquiring anonymous Xtokens


  function RefreshTtoken(telemetryClient, tokenStackComponents, httpClient, deviceToken, titleToken) {
    var _this = _super.call(this, telemetryClient, tokenStackComponents, httpClient, ITelemetryClient_1.Area.RefreshDtoken, tokenStackComponents.config.titleTokenEndpoint + "/title/authenticate", titleToken) || this;

    _this.deviceToken = deviceToken;
    Utils_1.assert(!!deviceToken && deviceToken.isValid && deviceToken.hasData);
    return _this;
  }

  RefreshTtoken.prototype.buildRequestBody = function () {
    return __awaiter(this, void 0, void 0, function () {
      var serializedKey, body;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.deviceIdentity.key.proofKey()];

          case 1:
            serializedKey = _a.sent();
            body = {
              RelyingParty: this.config.xboxLiveRelyingParty,
              TokenType: this.refreshingToken.tokenType,
              Properties: {
                TitleId: this.config.titleId,
                DeviceToken: this.deviceToken.tokenValue
              }
            };
            return [2
            /*return*/
            , JSON.stringify(body)];
        }
      });
    });
  };

  RefreshTtoken.prototype.interpretHttpStatus = function (httpStatus) {
    if (httpStatus === 403) {
      return new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.BadDeviceIdentity, "XAST returned a 403 status code");
    }

    return undefined;
  };

  return RefreshTtoken;
}(RefreshXboxTokenBase_1.RefreshXboxTokenBase);

exports.RefreshTtoken = RefreshTtoken;