"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.edsImageResizer = exports.EdsImageSize = void 0;
var EdsImageSize;

(function (EdsImageSize) {
  EdsImageSize["_50x50"] = "w=50&h=50";
  EdsImageSize["_100x100"] = "w=100&h=100";
  EdsImageSize["_208x208"] = "w=208&h=208";
  EdsImageSize["_230x316"] = "w=230&h=316";
  EdsImageSize["_248x248"] = "w=248&h=248";
  EdsImageSize["_280x280"] = "w=280&h=280";
  EdsImageSize["_282x424"] = "w=282&h=424";
  EdsImageSize["_320x320"] = "w=320&h=320";
  EdsImageSize["_336x336"] = "w=336&h=336";
  EdsImageSize["_370x208"] = "w=370&h=208";
  EdsImageSize["_420x236"] = "w=420&h=236";
  EdsImageSize["_441x441"] = "w=441&h=441";
  EdsImageSize["_562x316"] = "w=562&h=316";
  EdsImageSize["_640x640"] = "w=640&h=640";
  EdsImageSize["_854x480"] = "w=854&h=480";
  EdsImageSize["_1138x640"] = "w=1138&h=640";
  EdsImageSize["_1280x720"] = "w=1280&h=720";
  EdsImageSize["_1920x1080"] = "w=1920&h=1080";
})(EdsImageSize = exports.EdsImageSize || (exports.EdsImageSize = {}));

function edsImageResizer(url, size, format) {
  if (url && !url.includes("images-eds")) {
    return url;
  }

  if (format) {
    return url + "&format=" + format + "&" + size;
  } else {
    return url + "&" + size;
  }
}

exports.edsImageResizer = edsImageResizer;