import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemAdminActionHanders, IFeedItemSocialActionHandlers } from "@xbox/social-views";

import { FeedViewType } from "../../infiniteScrollers/base/InfiniteScrollTypes";
import * as FeedItemStyles from "./FeedItem.Styles";
import * as InlineStyle from "./InlineFeedItem.Styles";
import * as GridStyle from "./GridFeedItem.Styles";
import * as FeedModalStyles from "../../../containers/activityfeed/FeedModal.Styles";
import ProfileCard from "../../profileCard/ProfileCard";
import FeedImagePreview from "./FeedImagePreview";
import SocialActionsContainer from "../actions/SocialActionsContainer";
import AdminActionsContainer from "../actions/AdminActionsContainer";
import { Glyphs } from "../../../common/Generic.Icons";
import { Icon, GenericDisplayMode, GenericVideoPlayer } from "../../../common/GenericElements";
import { GetDateTimeDiff } from "../../../Utils";
import { PollVisibilityState, validateHttpPrefix, getPostDateElement } from "./FeedItemsUtils";
import { GamerpicListItem } from "../../../common/generic/GamerpicListItem";
import { MediaOff, CookieConsent } from "../../CookieConsent";
import Util from "../../../common/Util";

export interface FeedItemProps {
    adminActions?: IFeedItemAdminActionHanders;
    showAdminAction?: boolean;
    customAdminActionsMenu?: ActivityFeed.CustomAdminActionMenu;
    customSocialActionsMenu?: JSX.Element;
    item: ActivityFeed.FeedItem;
    showSocialActions?: boolean;
    socialActions?: IFeedItemSocialActionHandlers;
    hideBorders?: boolean;
    postDate?: Date;
    showFullDescription?: boolean;
    onload?: () => void;
    enableAdminView?: boolean;
    viewType?: FeedViewType;
    isCommentLocked?: boolean;
    fetchCommentLock?: (item: ActivityFeed.FeedItem) => void;
}

interface ActivityFeedItemState {
    showImg: boolean;
    showPollState?: PollVisibilityState;
    showBallotInlineModal: boolean;
}

export abstract class ActivityFeedItem extends React.Component<FeedItemProps, ActivityFeedItemState> {
    constructor(props: FeedItemProps) {
        super(props);
        this.state = { showImg: false, showBallotInlineModal: false };
    }

    render() {
        switch (this.props.viewType) {
            case FeedViewType.FullView:
                return this.getFullItemView();

            case FeedViewType.GridView:
                return this.getGridItemView();

            case FeedViewType.CompactView:
            default:
                return this.getInlineItemView();

        }
    }

    // could be redefined
    onOpenImage = (e: any) => {
        this.setState({ showImg: true });
        e.stopPropagation();
    }

    closeImg = (e: any) => {
        this.setState({ showImg: false });
        e.stopPropagation();
    }

    getSocialActionsContainer = () => {
        if (this.props.customSocialActionsMenu) {
            return this.props.customSocialActionsMenu;
        }

        return (
            <SocialActionsContainer
                socialActions={this.props.socialActions}
                showSocialActions={this.props.showSocialActions}
                item={this.props.item}
            />
        );
    }

    getTextContainer(feedItemProps: FeedItemProps, useSingleLine: boolean = false): JSX.Element | null {
        if (!useSingleLine && this.props.showFullDescription) {
            return <FeedItemStyles.TextContainer>{this.props.item.hasUgc ? this.props.item.ugcCaption : this.props.item.description} </FeedItemStyles.TextContainer>;
        } else {
            return <FeedItemStyles.EllipsisTextContainer>{this.props.item.hasUgc ? this.props.item.ugcCaption : this.props.item.description} </FeedItemStyles.EllipsisTextContainer>;
        }
    }

    getSharedFullFeedItemHeader(): JSX.Element | null {
        if (!this.props.item.originalAuthorInfo) {
            return null;
        }
        return (
            <FeedItemStyles.FeedItemHeader>
                <GamerpicListItem person={this.props.item.originalAuthorInfo} />
            </FeedItemStyles.FeedItemHeader>
        );
    }

    getSharedAvatarHeader(): JSX.Element | null {
        if (!this.props.item.originalAuthorInfo) {
            return null;
        }
        return (
            <FeedItemStyles.SharedGridCardDiv>
                <GamerpicListItem person={this.props.item.originalAuthorInfo} />
            </FeedItemStyles.SharedGridCardDiv>
        );
    }

    getTextLinkContainer(feedItemProps: FeedItemProps): JSX.Element | null {
        let link = this.props.item.postDetails.link ? validateHttpPrefix(this.props.item.postDetails.link) : "";
        let displayLink = this.props.item.postDetails ? this.props.item.postDetails.displayLink : link;
        let itemDescription = this.props.item.ugcCaption ? this.props.item.ugcCaption : this.props.item.description;

        if (this.props.showFullDescription) {
            return (
                <div>
                    <FeedItemStyles.TextContainer style={{ marginBottom: "0px" }}>{itemDescription}</FeedItemStyles.TextContainer>
                    <FeedItemStyles.LinkContainer href={link} target="_blank">{displayLink}</FeedItemStyles.LinkContainer>
                </div>
            );
        } else {
            return (
                <div>
                    <FeedItemStyles.EllipsisTextContainer style={{ marginBottom: "0px" }}>{itemDescription}</FeedItemStyles.EllipsisTextContainer>
                    <FeedItemStyles.EllipsisLinkContainer target="_blank" href={link}>{displayLink}</FeedItemStyles.EllipsisLinkContainer>
                </div>
            );
        }
    }

    getLinkToPreviewModalImage = (type: ActivityFeed.ActivityItemTypes, imageUrl: string): JSX.Element | null => {
        let preview = null;
        switch (type) {
            case ActivityFeed.ActivityItemTypes.GameDVR:
                // Without a download link, this will bascially be a static image that allows
                // us to have the preview be identical to the player
                preview = <GenericVideoPlayer posterUrl={imageUrl} maxWidth={760}/>;
                break;
            case ActivityFeed.ActivityItemTypes.Screenshot:
            default:
                // check for cookies images here : Grid View
                if (!CookieConsent.isXboxUrl(imageUrl) && !CookieConsent.getSocialMediaConsent()) {
                    preview = <MediaOff />;
                } else {
                    preview = <FeedItemStyles.FeedPreviewMedia alt="previewImage" src={imageUrl} style={{ height: "100%" }}/>;
                }
                break;
        }
        return preview;
    }

    getImagePreview(preview: any) {
        if (!preview) {
            return null;
        }
        // check for cookies images here
        if (!CookieConsent.isXboxUrl(preview.props.src) && !CookieConsent.getSocialMediaConsent()) {
            return <MediaOff />; // Full View
        }
        return (
            <FeedItemStyles.MediaContainer>
                <div style={{ position: "relative", display: "inline-block" }}>
                    {preview}
                    <FeedItemStyles.PreviewButton iconOnly={true} onClick={this.onOpenImage} >
                        <Icon type={Glyphs.Cast} />
                    </FeedItemStyles.PreviewButton>
                </div>
                {this.state.showImg && this.getImagePreviewModal()}
            </FeedItemStyles.MediaContainer>
        );
    }

    getImagePreviewModal = () => {
        return (
            <FeedModalStyles.FullscreenImageModal visible={true} onCancel={this.closeImg} >
                <FeedImagePreview item={this.props.item} closeImg={this.closeImg} />
            </FeedModalStyles.FullscreenImageModal>
        );
    }

    showDetailsView = (e: any) => {
        if (this.props.socialActions && this.props.socialActions.viewComments) {
            this.props.socialActions.viewComments(this.props.item);
        }
    }

    /*********************************************************************/
    /* Inline Template
    /*********************************************************************/
    getInlineButton = (): JSX.Element | null => null;
    getInlineSecondaryContent = (): JSX.Element | null => null;
    getInlineImageUrl = () => Util.ensureSSLImage(this.props.item.itemImage);

    getInlinePostText = () => {
        let itemData = this.props.item;
        if (itemData.originalAuthorInfo) {
            // item shared
            return itemData.itemText ? itemData.itemText
                : itemData.ugcCaption ? itemData.ugcCaption : itemData.description;
        }

        // regular item
        return itemData.ugcCaption ? itemData.ugcCaption
            : itemData.itemText ? itemData.itemText : itemData.description;
    }

    getInlinePreviewComponent = () => {
        let imageUrl = this.getInlineImageUrl();
        if (imageUrl) {
            return (
                <InlineStyle.ImageDivContainer
                    className="MediaItemContainer"
                    onClick={(e: any) => { imageUrl ? this.onOpenImage(e) : e.stopPropagation(); }}
                >
                    {imageUrl && this.getLinkToPreviewModalImage(this.props.item.activityItemType, imageUrl)}
                    {this.state.showImg && this.getImagePreviewModal()}
                </InlineStyle.ImageDivContainer>
            );
        } else {
            return (
                <InlineStyle.ImageDivContainer className="IconItemContainer">
                    <InlineStyle.FallBackIcon type={this.getInlinePostGlyph()}/>
                </InlineStyle.ImageDivContainer>
            );
        }
    }

    getInlinePostGlyph = () => {
        return Glyphs.Chat;
    }

    getInlineItemView() {
        const postText = this.getInlinePostText();
        const inline2ndContent = this.getInlineSecondaryContent();
        const inlineBtn = this.getInlineButton();

        let gamertagElement: any = (
            <InlineStyle.GamerTag>
                {this.props.item.authorInfo.gamertag}
                <span> -</span>
                <InlineStyle.DateSpan>{GetDateTimeDiff(this.props.item.date)}</InlineStyle.DateSpan>
            </InlineStyle.GamerTag>);
        return (
            <InlineStyle.ItemCard className="InlineFeedItemContainer" onLoad={this.props.onload} onClick={this.showDetailsView} >
                {this.getInlinePreviewComponent()}
                <InlineStyle.MainDivContainer>
                    <InlineStyle.HeaderDivContainer className="PostTextContainer">
                        <InlineStyle.PinnedIcon type={Glyphs.PinSolid} style={{ display: GenericDisplayMode(this.props.item.pinned) }} />
                        <div style={{display: "block"}}>
                            {   /* if item shared */
                                this.props.item.originalAuthorInfo &&
                                <InlineStyle.ShareDivContainer>
                                    <Icon type={Glyphs.Share} />
                                </InlineStyle.ShareDivContainer>
                            }
                            <InlineStyle.ContentDivContainer>
                                <InlineStyle.EllipsisPostText role="heading" aria-level={2}>{postText}</InlineStyle.EllipsisPostText>
                            </InlineStyle.ContentDivContainer>
                        </div>
                    </InlineStyle.HeaderDivContainer>

                    <InlineStyle.PostTextDivContainer>
                        <ProfileCard person={this.props.item.authorInfo} key={this.props.item.authorInfo.gamertag} element={gamertagElement} />
                        <InlineStyle.SecondaryContentRow>
                            {inlineBtn && <span style={{marginRight: "5px"}}>{inlineBtn}</span>}
                            {inline2ndContent}
                        </InlineStyle.SecondaryContentRow>
                    </InlineStyle.PostTextDivContainer>
                    <InlineStyle.ActionsDivContainer className="Actions">
                        {this.props.socialActions && this.getSocialActionsContainer()}
                        {this.props.adminActions &&
                            <AdminActionsContainer
                                item={this.props.item}
                                adminActions={this.props.adminActions}
                                showAdminAction={this.props.showAdminAction}
                                customAdminActionsMenu={this.props.customAdminActionsMenu}
                                isCommentLocked={this.props.isCommentLocked}
                                fetchCommentLock={this.props.fetchCommentLock}
                                style={{ float: "none", fontSize: "20px", marginTop: "0" }}
                            />
                        }
                    </InlineStyle.ActionsDivContainer>
                </InlineStyle.MainDivContainer>
            </InlineStyle.ItemCard>
        );
    }

    /*********************************************************************/
    /* Full Base Template
    /*********************************************************************/
    getFullTextContainer = (): JSX.Element | null => null;
    getFullFeedItemData = (): JSX.Element | null => null;
    useFullOnLoadOnMainDiv = (): boolean => false;

    getFullItemView = (): JSX.Element | null => {
        let style = this.props.hideBorders ? { border: "none" } : {};
        let item = this.props.item;
        const schedulePostContainer = () => {
            setTimeout(() => {
                const items = document.querySelectorAll(".schedulePostContainer");
                if (items.length > 0) {
                    items.forEach((list, index) => {
                        const listItems = items[index].querySelector("div");
                        (listItems as HTMLDivElement).setAttribute("role", "heading");
                        (listItems as HTMLDivElement).setAttribute("aria-level", "2");
                    });
                }
            }, 1000);
        };
        return (
            // onLoad could be placed in different spots
            <FeedItemStyles.FeedItemCard
                style={style}
                className="FullFeedItemContainer"
                onLoad={this.useFullOnLoadOnMainDiv() ? this.props.onload : () => { return; }}
                onClick={this.showDetailsView}
            >
                <FeedItemStyles.FeedItemHeader>
                    {this.props.adminActions &&
                        <AdminActionsContainer
                            item={item}
                            adminActions={this.props.adminActions}
                            showAdminAction={this.props.showAdminAction}
                            customAdminActionsMenu={this.props.customAdminActionsMenu}
                            isCommentLocked={this.props.isCommentLocked}
                            fetchCommentLock={this.props.fetchCommentLock}
                        />
                    }
                    <FeedItemStyles.PinnedIcon type={Glyphs.Pin} style={{ display: GenericDisplayMode(item.pinned) }} />
                    <GamerpicListItem
                        person={item.authorInfo}
                        itemPostDate={item.date}
                        itemShortDescription={item.shortDescription}
                    />
                </FeedItemStyles.FeedItemHeader>
                <div className="schedulePostContainer">
                    {this.getFullTextContainer()}
                    {schedulePostContainer()}
                    {
                        this.props.item.originalAuthorInfo
                            ? /* shared */
                            <FeedItemStyles.SharedFeedItemCard>
                                {this.getSharedFullFeedItemHeader()}
                                <div>
                                    {this.getFullFeedItemData()}
                                </div>
                            </FeedItemStyles.SharedFeedItemCard>
                            : /* regular */
                            this.getFullFeedItemData()
                    }
                    {this.props.socialActions && this.getSocialActionsContainer()}
                    {this.props.postDate && getPostDateElement(this.props.postDate)}
                </div>
            </FeedItemStyles.FeedItemCard>
        );
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/
    getGridImageUrl = () => this.getInlineImageUrl();
    getGridLinkButton = (): JSX.Element | null => null;

    getGridNonImageContent = (): JSX.Element | null => {
            return (
                <GridStyle.MainTextContent>
                    <span>{this.getInlinePostText()}</span>
                </GridStyle.MainTextContent>
            );
        }

    getGridItemView = (): JSX.Element | null => {
        let item = this.props.item;
        const imageUrl = this.getGridImageUrl();
        let backColor = item.authorInfo.preferredColor ? `#${item.authorInfo.preferredColor.tertiaryColor}99` : undefined;

        return (
            <GridStyle.GridItemCard className="GridFeedItemContainer" onLoad={this.props.onload} onClick={this.showDetailsView}>
                <GridStyle.GridHeader>
                    {/* Pinned item should have separate visual treatment */}
                    <InlineStyle.PinnedIcon type={Glyphs.PinSolid} style={{ display: GenericDisplayMode(this.props.item.pinned) }} />
                    <GamerpicListItem
                        person={item.authorInfo}
                        itemPostDate={item.date}
                    />
                    {this.props.adminActions &&
                        <AdminActionsContainer
                            item={item}
                            adminActions={this.props.adminActions}
                            showAdminAction={this.props.showAdminAction}
                            customAdminActionsMenu={this.props.customAdminActionsMenu}
                            isCommentLocked={this.props.isCommentLocked}
                            fetchCommentLock={this.props.fetchCommentLock}
                        />
                    }
                </GridStyle.GridHeader>

                <GridStyle.GridContentDiv>
                    {/* image or description */}
                    {imageUrl
                        ?
                        <GridStyle.ImageDivContainer onClick={this.onOpenImage}>
                            {this.getLinkToPreviewModalImage(this.props.item.activityItemType, imageUrl)}
                            {this.state.showImg && this.getImagePreviewModal()}
                        </GridStyle.ImageDivContainer>
                        :
                        <GridStyle.MainContentDiv background={backColor} >
                            {this.getGridNonImageContent()}
                        </GridStyle.MainContentDiv>
                    }
                    {   /* if item shared */
                        this.props.item.originalAuthorInfo
                        && this.getSharedAvatarHeader()
                    }
                    {this.getGridLinkButton()}
                </GridStyle.GridContentDiv>
                <GridStyle.GridFooter>
                    {this.props.socialActions && this.getSocialActionsContainer()}
                </GridStyle.GridFooter>
            </GridStyle.GridItemCard>
        );
    }
}
