import * as React from "react";
import { notification, Modal } from "antd";
import { injectable } from "inversify";
import { INotificationsService, NotificationDataPayload } from "@xbox/social-core";
import { Localize, LocalizeHTML } from "../common/translation/Localize";

enum NotificationTypes {
    Error = "Error",
    Success = "Success",
    Info = "Info",
    Warn = "Warn"
}

@injectable()
export class NotifcationService implements INotificationsService {
    private defaultDuration: number = 10;

    setDefaultDuration = (duration: number) => {
        this.defaultDuration = duration;
    }

    /******************************************************************************
    * Modal Methods
    *******************************************************************************/

    modalError = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.error.message".replace("{$v}", key), {context: code});
        this.createModal(NotificationTypes.Error, Localize( "notifications.{$v}.error.title".replace("{$v}", key) ), secondaryText);
    }

    modalSuccess = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.success.message".replace("{$v}", key), {context: code});
        this.createModal(NotificationTypes.Success, Localize( "notifications.{$v}.success.title".replace("{$v}", key) ), secondaryText);
    }

    /************************
    * MODAL PAYLOAD METHODS
    *************************/

    modalErrorPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = <LocalizeHTML id={`notifications.${key}.error.message`} options={this.constructParameters(payload)}/>;
        this.createModal(NotificationTypes.Error, Localize( "notifications.{$v}.error.title".replace("{$v}", key) ), secondaryText);
    }

    modalSuccessPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = Localize( "notifications.{$v}.success.message".replace("{$v}", key), this.constructParameters(payload));
        this.createModal(NotificationTypes.Success, Localize( "notifications.{$v}.success.title".replace("{$v}", key) ), secondaryText);
    }

    /******************************************************************************
    * Notification Methods
    *******************************************************************************/

    notifyError = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.error.message".replace("{$v}", key), {context: code});
        this.createNotification(NotificationTypes.Error, Localize( "notifications.{$v}.error.title".replace("{$v}", key) ), secondaryText);
    }

    notifySuccess = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.success.message".replace("{$v}", key), {context: code});
        this.createNotification(NotificationTypes.Success, Localize( "notifications.{$v}.success.title".replace("{$v}", key) ), secondaryText);
    }

    notifyInfo = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.info.message".replace("{$v}", key), {context: code});
        this.createNotification(NotificationTypes.Info, Localize( "notifications.{$v}.info.title".replace("{$v}", key) ), secondaryText);
    }

    notifyWarning = (key: string, code?: string | number) => {
        let secondaryText = Localize( "notifications.{$v}.warning.message".replace("{$v}", key), {context: code});
        this.createNotification(NotificationTypes.Warn, Localize( "notifications.{$v}.warning.title".replace("{$v}", key) ), secondaryText);
    }

    /************************
    * NOTIFICATION PAYLOAD METHODS
    *************************/

    notifyErrorPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = Localize( "notifications.{$v}.error.message".replace("{$v}", key), this.constructParameters(payload));
        this.createNotification(NotificationTypes.Error, Localize( "notifications.{$v}.error.title".replace("{$v}", key) ), secondaryText);
    }

    notifySuccessPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = Localize( "notifications.{$v}.success.message".replace("{$v}", key), this.constructParameters(payload));
        this.createNotification(NotificationTypes.Success, Localize( "notifications.{$v}.success.title".replace("{$v}", key) ), secondaryText);
    }

    notifyInfoPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = Localize( "notifications.{$v}.info.message".replace("{$v}", key), this.constructParameters(payload));
        this.createNotification(NotificationTypes.Info, Localize( "notifications.{$v}.info.title".replace("{$v}", key) ), secondaryText);
    }

    notifyWarningPayload = (key: string, payload: NotificationDataPayload) => {
        let secondaryText = Localize( "notifications.{$v}.warning.message".replace("{$v}", key), this.constructParameters(payload));
        this.createNotification(NotificationTypes.Warn, Localize( "notifications.{$v}.warning.title".replace("{$v}", key) ), secondaryText);
    }

    /******************************************************************************
    * Private/Construction Methods
    *******************************************************************************/

    private createNotification = (type: NotificationTypes, primaryText: string, secondaryText: string) => {
        switch (type) {
            case NotificationTypes.Error:
                notification.error({message: primaryText, description: secondaryText, duration: this.defaultDuration});
                break;
            case NotificationTypes.Warn:
                notification.warning({message: primaryText, description: secondaryText, duration: this.defaultDuration});
                break;
            case NotificationTypes.Success:
                notification.success({message: primaryText, description: secondaryText, duration: this.defaultDuration});
                break;
            case NotificationTypes.Info:
                notification.info({message: primaryText, description: secondaryText, duration: this.defaultDuration});
                break;
            default:
                break;
        }
    }

    private createModal = (type: NotificationTypes, primaryText: string | JSX.Element, secondaryText: string | JSX.Element) => {
        switch (type) {
            case NotificationTypes.Error:
                Modal.error({title: primaryText, content: secondaryText});
                break;
            case NotificationTypes.Warn:
                Modal.warning({title: primaryText, content: secondaryText});
                break;
            case NotificationTypes.Success:
                Modal.success({title: primaryText, content: secondaryText});
                break;
            case NotificationTypes.Info:
                Modal.info({title: primaryText, content: secondaryText});
                break;
            default:
                break;
        }
    }

    private constructParameters(notifyPayload?: NotificationDataPayload) {
        let result = {context: "", payload: ""};
        if (notifyPayload) {
            if (notifyPayload.textPayload && notifyPayload.textPayload instanceof Array) {
                result.payload = (notifyPayload.textPayload as string[]).join(", ");
            } else {
                result.payload = notifyPayload.textPayload || "";
            }

            if (notifyPayload.codePayload) {
                result.context = notifyPayload.codePayload.toString() || "";
            }
        }
        return result;
    }
}
