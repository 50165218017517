"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NsalDb = void 0;

var http_1 = require("@xbox/http");

var Constants_1 = require("./Constants");

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var ITelemetryClient_1 = require("./ITelemetryClient");

var Nsal_1 = require("./Nsal");

var NsalFetchOperation_1 = require("./operations/NsalFetchOperation");

var Utils_1 = require("./Utils");

var XalHttpRequest_1 = require("./XalHttpRequest");

var NsalDb =
/** @class */
function () {
  function NsalDb(telemetryClient) {
    this.telemetryClient = telemetryClient;
  }

  NsalDb.lookup = function (nsal, url) {
    var endpoint = nsal.getEndpoint(url);

    if (!endpoint) {
      return undefined;
    }

    if (endpoint.hasOwnProperty("SignaturePolicyIndex") && endpoint.SignaturePolicyIndex >= 0) {
      return {
        Endpoint: endpoint,
        Policy: nsal.getSignaturePolicy(endpoint.SignaturePolicyIndex)
      };
    } else {
      return {
        Endpoint: endpoint
      };
    }
  };

  NsalDb.prototype.lookup = function (urlString) {
    Utils_1.assert(!!this.defaultNsal);
    var nsalUri;

    try {
      nsalUri = new http_1.Url(urlString);
    } catch (_a) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Invalid url for Nsal database lookup");
    }

    if (!nsalUri) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Invalid url for Nsal database lookup");
    }

    if (this.defaultNsal) {
      var lookupResult = NsalDb.lookup(this.defaultNsal, nsalUri);

      if (lookupResult) {
        return lookupResult;
      }
    }

    if (this.titleNsal) {
      var lookupResult = NsalDb.lookup(this.titleNsal, nsalUri);

      if (lookupResult) {
        return lookupResult;
      }
    } // If we are looking up an endpoint that isn't in the default NSAL
    // we should always have the title NSAL loaded.


    Utils_1.assert(!!this.titleNsal, "Looking up an endpoint that isn't in the default NSAL, title NSAL should already be loaded");

    if (!this.titleNsal || !this.defaultNsal) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Important, "Endpoint was not found in Nsal: TitleNsalExists: " + !!this.titleNsal + ", DefaultNsalExists: " + !!this.defaultNsal);
      this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.NsalDb, "Lookup - Endpoint was not found in Nsal", this.titleNsal ? ITelemetryClient_1.ErrorLevel.Info : ITelemetryClient_1.ErrorLevel.Error, XalInternalErrorType_1.XalInternalErrorType.Unknown);
    }

    return {};
  };

  NsalDb.prototype.loadDefaultNsal = function (config, httpClient) {
    return __awaiter(this, void 0, void 0, function () {
      var url, request, operation, nsal, nsalResponse, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.defaultNsal) {
              return [2
              /*return*/
              ];
            }

            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.NsalDb_LoadDefaultNsal, ITelemetryClient_1.EventAction.ActionStarted);
            url = config.titleMgmtEndpoint + "/titles/default/endpoints?type=1";
            request = new XalHttpRequest_1.XalHttpRequest(httpClient, http_1.Http.HttpRequestMethod.Get, url);
            request.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            request.setHeader(Constants_1.HeaderKeys.XblContractVersion, "1");
            operation = new NsalFetchOperation_1.NsalFetchOperation(ITelemetryClient_1.Area.NsalDb_LoadDefaultNsal, this.telemetryClient, request);
            operation.execute();
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            nsalResponse = _a.sent();
            nsal = new Nsal_1.Nsal(nsalResponse);
            return [3
            /*break*/
            , 4];

          case 3:
            error_1 = _a.sent(); // TODO(alkhayat): What error code should this use?

            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.NsalDb_LoadDefaultNsal, "Unsuccessful Result From LoadDefaultNsal Operation", ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.Unknown);
            throw error_1;

          case 4:
            if (!this.defaultNsal) {
              this.defaultNsal = nsal;
            }

            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.NsalDb_LoadDefaultNsal, ITelemetryClient_1.EventAction.ActionCompleted);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  NsalDb.prototype.loadTitleNsal = function (config, deviceId, xTokenData, httpClient) {
    return __awaiter(this, void 0, void 0, function () {
      var url, request, endpointInfo, operation, nsal, nsalResponse, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.titleNsal) {
              return [2
              /*return*/
              ];
            }

            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.NsalDb_LoadTitleNsal, ITelemetryClient_1.EventAction.ActionStarted);
            url = config.titleMgmtEndpoint + "/titles/current/endpoints";
            request = new XalHttpRequest_1.XalHttpRequest(httpClient, http_1.Http.HttpRequestMethod.Get, url);
            request.setHeader(Constants_1.HeaderKeys.Connection, Constants_1.HeaderValues.KeepAlive);
            request.setHeader(Constants_1.HeaderKeys.XblContractVersion, "1");
            endpointInfo = this.lookup(config.xboxLiveEndpoint);
            if (!endpointInfo.Policy) return [3
            /*break*/
            , 2];
            request.setAuthorizationWithXtoken(xTokenData);
            return [4
            /*yield*/
            , request.signRequest(deviceId.key, endpointInfo.Policy)];

          case 1:
            _a.sent();

            _a.label = 2;

          case 2:
            operation = new NsalFetchOperation_1.NsalFetchOperation(ITelemetryClient_1.Area.NsalDb_LoadTitleNsal, this.telemetryClient, request);
            operation.execute();
            _a.label = 3;

          case 3:
            _a.trys.push([3, 5,, 6]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 4:
            nsalResponse = _a.sent();
            nsal = new Nsal_1.Nsal(nsalResponse);
            return [3
            /*break*/
            , 6];

          case 5:
            error_2 = _a.sent(); // TODO(alkhayat): What error code should this use?

            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.NsalDb_LoadTitleNsal, "Unsuccessful Result From LoadTitleNsal Operation", ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.Unknown);
            throw error_2;

          case 6:
            if (!this.titleNsal) {
              this.titleNsal = nsal;
            }

            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.NsalDb_LoadTitleNsal, ITelemetryClient_1.EventAction.ActionCompleted);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  Object.defineProperty(NsalDb.prototype, "isTitleNsalLoaded", {
    get: function get() {
      return !!this.titleNsal;
    },
    enumerable: false,
    configurable: true
  });
  return NsalDb;
}();

exports.NsalDb = NsalDb;