"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignIn = void 0;

var http_1 = require("@xbox/http");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var RequestSigner_1 = require("../../RequestSigner");

var Utils_1 = require("../../Utils");

var SisuEndUri_1 = require("../SisuEndUri");

var SignInBase_1 = require("./SignInBase");

var SignOut_1 = require("./SignOut");

var Constants_1 = require("../../Constants");

var framework_1 = require("@xbox/framework");

var SignIn =
/** @class */
function (_super) {
  __extends(SignIn, _super);

  function SignIn(telemetryClient, components, preexistingMsaUserId, webView, userChecker, httpClient, area) {
    var _this = _super.call(this, area === undefined ? ITelemetryClient_1.Area.SignIn : area, telemetryClient, components, webView, httpClient, true, // forceCallSisuForXtoken
    preexistingMsaUserId) || this;

    _this.components = components;
    _this.userChecker = userChecker;
    return _this;
  }

  SignIn.prototype.serveRequestFromChild = function () {
    // At this moment, SignIn can never be served from cache
    return false;
  };

  SignIn.prototype.handleXtokenResult = function (result) {
    return __awaiter(this, void 0, void 0, function () {
      var data, error_1, xalError, uri, query, sisuUriForNsal, sisuNsalEntry, signature, webViewFlowId, queryString, rehydrationParams, webViewResult, error_2, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 9]);

            return [4
            /*yield*/
            , result];

          case 1:
            data = _a.sent();
            return [3
            /*break*/
            , 9];

          case 2:
            error_1 = _a.sent();
            xalError = error_1;
            if (!(xalError && xalError.errorType && xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.SwitchUser)) return [3
            /*break*/
            , 4];
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.SignIn, ITelemetryClient_1.EventAction.NewUserSwitchUser);
            this.xtoken = undefined;
            return [4
            /*yield*/
            , this.signOutAndRestartSignIn(ITelemetryClient_1.Area.SignIn)];

          case 3:
            _a.sent();

            return [2
            /*return*/
            ];

          case 4:
            if (!Utils_1.isNullOrWhiteSpace(this.preexistingMsaUserId)) return [3
            /*break*/
            , 6]; // If there was a preexisting user, we don't want to remove their credentials here

            return [4
            /*yield*/
            , this.clearCachedTokensAndFail(error_1)];

          case 5:
            // If there was a preexisting user, we don't want to remove their credentials here
            _a.sent();

            return [3
            /*break*/
            , 7];

          case 6:
            this.step.advance(SignInBase_1.SignInBaseStep.Done);
            this.fail(error_1);
            _a.label = 7;

          case 7:
            return [2
            /*return*/
            ];

          case 8:
            return [3
            /*break*/
            , 9];

          case 9:
            Utils_1.assert(data.xtoken.isValid);
            this.xtoken = data.xtoken;
            if (!!Utils_1.isNullOrWhiteSpace(data.url)) return [3
            /*break*/
            , 19];
            uri = void 0;

            try {
              uri = new http_1.Url(data.url);
            } catch (error) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Sign in operation received invalid URL. Continuing on with sign in process.");
              this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.SignIn, "Invalid SISU URL received during sign in.", ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.Unknown);
              this.succeedWithXtoken(this.xtoken);
              return [2
              /*return*/
              ];
            }

            query = Utils_1.parseQuery(uri.search);
            sisuUriForNsal = this.components.config.sisuEndpoint;
            sisuNsalEntry = this.components.nsal.lookup(sisuUriForNsal);
            if (!sisuNsalEntry.Policy) return [3
            /*break*/
            , 11];
            return [4
            /*yield*/
            , RequestSigner_1.RequestSigner.signRequest(this.components.xboxCache.deviceIdentity.key, sisuNsalEntry.Policy, http_1.Http.HttpRequestMethod.Post, "/proxy?sessionid=" + data.sisuSessionId)];

          case 10:
            signature = _a.sent();
            query.set("sig", signature);
            return [3
            /*break*/
            , 12];

          case 11:
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "NSAL indicates not signing SISU web request.");
            _a.label = 12;

          case 12:
            query.set("sid", data.sisuSessionId);

            if (this.components.config.sisuRedirectUri) {
              // NOXAL:WEB This allows web clients to redirect to themselves after SISU
              query.set("redirect", this.components.config.sisuRedirectUri);
            }

            webViewFlowId = framework_1.guid();
            query.set("state", webViewFlowId);
            queryString = Utils_1.createQuery(query);
            uri.search = queryString;
            rehydrationParams = new Map();
            rehydrationParams.set("operation", "WelcomeBackSisu");

            if (this.webAccountId) {
              rehydrationParams.set("msaUserId", this.webAccountId);
            }

            this.step.advance(SignInBase_1.SignInBaseStep.ShowSisuUi);

            if (!this.webView) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "Operation needed to show UI but was not provided a web view.");
              this.step.advance(SignInBase_1.SignInBaseStep.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.UiRequired, "Operation needed to show UI but was not provided a web view."));
              return [2
              /*return*/
              ];
            }

            webViewResult = void 0;
            _a.label = 13;

          case 13:
            _a.trys.push([13, 15,, 17]);

            return [4
            /*yield*/
            , this.webView.showUrl(uri.toString(), Constants_1.SisuDesktopUri, false, // suppressUi
            webViewFlowId, rehydrationParams)];

          case 14:
            webViewResult = _a.sent();
            return [3
            /*break*/
            , 17];

          case 15:
            error_2 = _a.sent();
            xalError = error_2;

            if (xalError && xalError.errorType && xalError.errorType === XalInternalErrorType_1.XalInternalErrorType.Aborted) {
              if (this.xtoken.isValid) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Information, "SISU sign in web view was manually closed by either user or client with a valid token.");
                this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.SignIn, ITelemetryClient_1.EventAction.WelcomeBackCanceledValidToken);
              } else {
                Utils_1.xalTrace(Utils_1.TraceLevel.Information, "SISU sign in web view was manually closed by either user or client with an invalid token.");
                this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.SignIn, ITelemetryClient_1.EventAction.WelcomeBackCanceledInvalidToken);
              }
            }

            return [4
            /*yield*/
            , this.clearCachedTokensAndFail(error_2)];

          case 16:
            _a.sent();

            return [2
            /*return*/
            ];

          case 17:
            return [4
            /*yield*/
            , this.handleSisuEndUri(webViewResult)];

          case 18:
            _a.sent();

            return [2
            /*return*/
            ];

          case 19:
            this.succeedWithXtoken(this.xtoken);
            _a.label = 20;

          case 20:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  SignIn.prototype.isUserDuplicated = function (webAccountId) {
    return this.userChecker.isDuplicateUser(webAccountId);
  };

  SignIn.prototype.handleSisuEndUri = function (sisuEndUri) {
    return __awaiter(this, void 0, void 0, function () {
      var endUrl, error_3, sisuStatus;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 1,, 3]);

            if (Utils_1.isNullOrWhiteSpace(sisuEndUri)) {
              throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InvalidArguments, "Sign in web view received invalid SISU end URL.");
            }

            endUrl = new SisuEndUri_1.SisuEndUri(sisuEndUri);
            return [3
            /*break*/
            , 3];

          case 1:
            error_3 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Sign in web view received invalid SISU end URL.");
            return [4
            /*yield*/
            , this.clearCachedTokensAndFail(error_3)];

          case 2:
            _a.sent();

            return [2
            /*return*/
            ];

          case 3:
            if (!Utils_1.isNullOrWhiteSpace(endUrl.logMessage)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Sign in web view passed back log data: " + endUrl.logMessage);
            }

            sisuStatus = endUrl.result;
            if (!(sisuStatus && sisuStatus === XalInternalErrorType_1.XalInternalErrorType.SwitchUser)) return [3
            /*break*/
            , 5];
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.SignIn, ITelemetryClient_1.EventAction.WelcomeBackSwitchUser);
            this.xtoken = undefined;
            return [4
            /*yield*/
            , this.signOutAndRestartSignIn(ITelemetryClient_1.Area.SignIn)];

          case 4:
            _a.sent();

            return [2
            /*return*/
            ];

          case 5:
            if (!sisuStatus) return [3
            /*break*/
            , 7]; // NOXAL: XAL removed this in PR 2197003, but that seems like an error

            return [4
            /*yield*/
            , this.clearCachedTokensAndFail(new XalInternalError_1.XalInternalError(sisuStatus, "SISU UI returned a failure"))];

          case 6:
            // NOXAL: XAL removed this in PR 2197003, but that seems like an error
            _a.sent();

            return [2
            /*return*/
            ];

          case 7:
            // Success
            this.succeedWithXtoken(this.xtoken);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  SignIn.prototype.clearCachedTokensAndFail = function (error) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, error_4, xalError;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(SignInBase_1.SignInBaseStep.SignOut);
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Operation " + ITelemetryClient_1.Area.SignIn + " is clearing tokens and failing.");
            operation = new SignOut_1.SignOut(this.telemetryClient, this.components, this.webView, this.webAccountId);
            operation.execute();
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Operation " + ITelemetryClient_1.Area.SignIn + " failed sign out with code " + error_4);
            return [3
            /*break*/
            , 4];

          case 4:
            xalError = error;

            if (xalError && xalError.errorType) {
              this.fail(xalError);
            } else {
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Operation " + ITelemetryClient_1.Area.SignIn + " failed and cleared tokens with error: " + error));
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return SignIn;
}(SignInBase_1.SignInBase);

exports.SignIn = SignIn;