import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers, IFeedItemAdminActionHanders } from "@xbox/social-views";

import { FeedItemSelector } from "../../components/feeditems/FeedItemSelector";
import { ScheduledFeedHeaderButtons } from "../feeditems/base/ActivityFeedHeaderButtons";
import { BaseActivityFeedContainerProps } from "./BaseActivityFeed";
import ClubActivityFeed  from "./ClubActivityFeed";
import { FeedViewType } from "../infiniteScrollers/base/InfiniteScrollTypes";

export default class ScheduledClubActivityFeed extends ClubActivityFeed {
    constructor(props: BaseActivityFeedContainerProps) {
        super(props);
        this.state = {
            showShareItemModal: false,
            feedViewType: FeedViewType.FullView
        };
    }

    getFeedItemType = (
        item: ActivityFeed.FeedItem,
        socialActions?: IFeedItemSocialActionHandlers,
        adminActions?: IFeedItemAdminActionHanders,
        feedViewType?: FeedViewType,
        onload?: () => void
    ): JSX.Element | null => {
        if (!item) {
            return (<div />);
        }
        return (
            <FeedItemSelector
                key={item.feedItemId}
                item={item}
                postDate={item.date}
                showAdminAction={true}
                showSocialActions={false}
                customAdminActionsMenu={ActivityFeed.CustomAdminActionMenu.DeleteFeedItemOnly}
                adminActions={adminActions}
                viewType={FeedViewType.FullView}
            />
        );
    }

    getHeaderButtons = (): JSX.Element | null => {

        if (!this.props.feedManipulationProps) {
            return null;
        }

        return (
            <ScheduledFeedHeaderButtons
                postStatus={this.props.feedManipulationProps.postStatus}
                channelList={this.props.feedManipulationProps.channelList}
                channelSelect={this.props.feedManipulationProps.selectChannel!}
                isModerator={true}
                selectedChannel={this.props.feedManipulationProps.selectedChannel}
                onRefreshClick={this.refreshFeed}
                showRefresh={true}
            />
        );
    }
}
