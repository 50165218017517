"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubsRedux = exports.getClubSettings = exports.getClubRoster = exports.getClubInviteQueue = exports.getClubModerationQueue = exports.getClubActivityState = exports.getClubDetails = exports.getClubViewerState = void 0;

var _ = require("lodash");

var social_core_1 = require("@xbox/social-core");

var ClubListActions_1 = require("../../actions/ClubListActions");

var ClubHubsReducers_1 = require("./ClubHubsReducers");

var ClubsNormalizer_1 = require("./ClubsNormalizer");

var ActivityFeedNormalizer_1 = require("../feed/ActivityFeedNormalizer"); // clubs selectors


function getOfficalClubs(clubData) {
  return _.filter(clubData, function (club) {
    return club.details.isOfficialClub;
  });
}

function checkAdminPrivileges(clubData) {
  var adminAccess = false;

  _.forEach(clubData, function (value) {
    if (value.settings && (value.settings.viewerRoles.roles.includes(social_core_1.ClubHub.ClubRoles.Moderator) || value.settings.viewerRoles.roles.includes(social_core_1.ClubHub.ClubRoles.Owner))) {
      adminAccess = true;
    }
  });

  return adminAccess;
}

function getClubViewerState(state) {
  if (state.clubs.clubList.clubs) {
    var items = _.uniqBy(ClubsNormalizer_1.clubDenormalizer(state.clubs.clubList.clubs.result, state.clubs.clubList.clubs.entities), function (e) {
      return e.details.id;
    });

    var allClubs = items;
    var adminAccess = checkAdminPrivileges(getOfficalClubs(items)); // Use of remove to make the items variable at the end be mutated

    var invitedClubs = _.remove(items, function (clubItem) {
      return clubItem.settings.viewerRoles.roles.indexOf(social_core_1.ClubHub.ClubRoles.Invited) > -1;
    });

    return {
      currentUser: state.authentication.currentUser,
      allClubs: allClubs,
      invitedClubs: invitedClubs,
      adminAccess: adminAccess
    };
  } else {
    return {
      currentUser: state.authentication.currentUser,
      results: {}
    };
  }
}

exports.getClubViewerState = getClubViewerState;

function getClubDetails(state) {
  if (state.clubs.clubDetails.club) {
    return {
      item: state.clubs.clubDetails.club,
      completed: !state.clubs.clubDetails.fetchInProgress,
      currentUser: state.authentication.currentUser,
      currentChannel: state.channels.selectedChannel
    };
  } else {
    return {
      currentUser: state.authentication.currentUser,
      completed: !state.clubs.clubDetails.fetchInProgress
    };
  }
}

exports.getClubDetails = getClubDetails;

function getClubActivityState(state) {
  if (state.clubActivities) {
    var items = _.uniqBy(ActivityFeedNormalizer_1.activityFeedDenormalizer(state.clubActivities.result, state.clubActivities.entities), function (e) {
      return e.feedItemId;
    });

    return items;
  } else {
    return {};
  }
}

exports.getClubActivityState = getClubActivityState;

function getClubModerationQueue(state) {
  if (state.clubModerationQueue) {
    return {
      reportedItems: ClubsNormalizer_1.clubModerationDenormalizer(state.clubModerationQueue.result, state.clubModerationQueue.entities),
      completed: !state.fetchInProgress
    };
  } else {
    return {
      completed: !state.fetchInProgress
    };
  }
}

exports.getClubModerationQueue = getClubModerationQueue;

function getClubInviteQueue(state) {
  if (state.inviteQueue) {
    return {
      inviteQ: state.inviteQueue,
      fetchInProgress: state.fetchInProgress
    };
  } else {
    return {
      fetchInProgress: state.fetchInProgress
    };
  }
}

exports.getClubInviteQueue = getClubInviteQueue;

function getClubRoster(state) {
  var club;

  if (state.clubs.clubDetails.club) {
    club = state.clubs.clubDetails.club;
  } else {
    return [];
  }

  return club.clubPresence && club.clubPresence.items ? club.clubPresence.items.map(function (m) {
    return m.profile;
  }) : [];
}

exports.getClubRoster = getClubRoster;

function getClubSettings(state) {
  return {
    privacy: state.privacy.privacySettings,
    currentUser: state.authentication.currentUser,
    feedSettings: state.feedViewer.activityFeed.settings,
    feedChannels: state.channels.feedChannels,
    chatChannels: state.clubs.clubDetails.club && state.clubs.clubDetails.club.chatChannels,
    safetyThresholdInProgress: state.clubs.clubDetails.safetyInProgress,
    safetyThreshold: state.clubs.clubDetails.safetyThresholdData
  };
}

exports.getClubSettings = getClubSettings; // redux exports

exports.clubsRedux = {
  actions: __assign({}, ClubListActions_1.ClubActions),
  reducer: ClubHubsReducers_1.aggregatedClubReducer,
  selectors: {
    getClubDetails: getClubDetails,
    getClubViewerState: getClubViewerState,
    getClubActivityState: getClubActivityState,
    getClubModerationQueue: getClubModerationQueue,
    getClubInviteQueue: getClubInviteQueue,
    getClubRoster: getClubRoster
  }
};