"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllTitlesReducer = void 0;

var Immutable = require("seamless-immutable");

var redux_ts_simple_1 = require("redux-ts-simple");

var TitleHubActions_1 = require("../../actions/TitleHubActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.fetchAllTitlesReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(TitleHubActions_1.TitleHubActions.fetchAllTitlesInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(TitleHubActions_1.TitleHubActions.fetchAllTitlesCompleted, function (state, action) {
  return state.merge({
    titles: action.payload
  }, {
    deep: true
  });
}).build();