"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadDeviceIdentity = void 0;

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var XboxTokenCache_1 = require("../../XboxTokenCache");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var LoadDeviceIdentityStep;

(function (LoadDeviceIdentityStep) {
  LoadDeviceIdentityStep["Start"] = "Start";
  LoadDeviceIdentityStep["ReadingIdentity"] = "ReadingIdentity";
  LoadDeviceIdentityStep["ReadingDtoken"] = "ReadingDtoken";
  LoadDeviceIdentityStep["ReadingTtoken"] = "ReadingTtoken";
  LoadDeviceIdentityStep["CreatingIdentity"] = "CreatingIdentity";
  LoadDeviceIdentityStep["Done"] = "Done";
})(LoadDeviceIdentityStep || (LoadDeviceIdentityStep = {}));

var LoadDeviceIdentity =
/** @class */
function (_super) {
  __extends(LoadDeviceIdentity, _super);

  function LoadDeviceIdentity(telemetryClient, memoryCache, storage, deviceIdentityKey, dtokenKey, ttokenKey) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.memoryCache = memoryCache;
    _this.storage = storage;
    _this.deviceIdentityKey = deviceIdentityKey;
    _this.dtokenKey = dtokenKey;
    _this.ttokenKey = ttokenKey;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.LoadDeviceIdentity, LoadDeviceIdentityStep.Start);
    return _this;
  }

  LoadDeviceIdentity.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var identity, data, error_1, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            identity = this.memoryCache.deviceIdentity;

            if (identity) {
              this.step.advance(LoadDeviceIdentityStep.Done);
              this.succeed(identity);
              return [2
              /*return*/
              ];
            }

            this.step.advance(LoadDeviceIdentityStep.ReadingIdentity);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 5]);

            return [4
            /*yield*/
            , this.storage.read(this.deviceIdentityKey)];

          case 2:
            data = _a.sent();
            return [3
            /*break*/
            , 5];

          case 3:
            error_1 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "Reading identity from storage failed, creating identity. Error: " + error_1);
            return [4
            /*yield*/
            , this.createIdentity()];

          case 4:
            _a.sent();

            return [2
            /*return*/
            ];

          case 5:
            if (!Utils_1.isNullOrWhiteSpace(data)) return [3
            /*break*/
            , 7];
            Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "No device identity found");
            return [4
            /*yield*/
            , this.createIdentity()];

          case 6:
            _a.sent();

            return [2
            /*return*/
            ];

          case 7:
            _a.trys.push([7, 9,, 11]);

            return [4
            /*yield*/
            , this.memoryCache.setDeviceIdentity(data)];

          case 8:
            _a.sent();

            return [3
            /*break*/
            , 11];

          case 9:
            error_2 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Failed to deserialize device identity " + error_2);
            return [4
            /*yield*/
            , this.createIdentity()];

          case 10:
            _a.sent();

            return [2
            /*return*/
            ];

          case 11:
            return [4
            /*yield*/
            , this.readDtoken()];

          case 12:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  LoadDeviceIdentity.prototype.createIdentity = function () {
    return __awaiter(this, void 0, void 0, function () {
      var identity, serializedIdentity;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(LoadDeviceIdentityStep.CreatingIdentity);
            Utils_1.assert(!this.memoryCache.deviceIdentity);
            return [4
            /*yield*/
            , this.memoryCache.createDeviceIdentity()];

          case 1:
            identity = _a.sent();
            return [4
            /*yield*/
            , identity.serialize()];

          case 2:
            serializedIdentity = _a.sent();
            return [4
            /*yield*/
            , this.storage.write(this.deviceIdentityKey, serializedIdentity)];

          case 3:
            _a.sent();

            Utils_1.assert(this.step.currentStep === LoadDeviceIdentityStep.CreatingIdentity);
            this.step.advance(LoadDeviceIdentityStep.Done);
            this.succeed(this.memoryCache.deviceIdentity);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  LoadDeviceIdentity.prototype.readDtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var data, error_3, token;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(LoadDeviceIdentityStep.ReadingDtoken);
            Utils_1.assert(this.step.currentStep === LoadDeviceIdentityStep.ReadingDtoken);
            data = null;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , this.storage.read(this.dtokenKey)];

          case 2:
            data = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "Failed to read Dtoken with error: " + error_3);
            return [3
            /*break*/
            , 4];

          case 4:
            if (Utils_1.isNullOrWhiteSpace(data)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "No Dtoken found");
            } else {
              try {
                token = XboxTokenCache_1.parseSingleTokenBlob(data, this.memoryCache.deviceIdentity.uniqueId);

                if (token) {
                  this.memoryCache.insertToken(token);
                }
              } catch (error) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Failed to deserialize Dtoken: " + error);
              }
            }

            return [4
            /*yield*/
            , this.readTtoken()];

          case 5:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  LoadDeviceIdentity.prototype.readTtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var data, error_4, token;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(LoadDeviceIdentityStep.ReadingTtoken);
            Utils_1.assert(this.step.currentStep === LoadDeviceIdentityStep.ReadingTtoken);
            data = null;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , this.storage.read(this.ttokenKey)];

          case 2:
            data = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            Utils_1.xalTrace(Utils_1.TraceLevel.Verbose, "Failed to read Ttoken with error: " + error_4);
            return [3
            /*break*/
            , 4];

          case 4:
            if (Utils_1.isNullOrWhiteSpace(data)) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "No Ttoken found");
            } else {
              try {
                token = XboxTokenCache_1.parseSingleTokenBlob(data, this.memoryCache.deviceIdentity.uniqueId);

                if (token) {
                  this.memoryCache.insertToken(token);
                }
              } catch (error) {
                Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Failed to deserialize Ttoken: " + error);
              }
            }

            this.step.advance(LoadDeviceIdentityStep.Done);
            this.succeed(this.memoryCache.deviceIdentity);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return LoadDeviceIdentity;
}(OperationBase_1.OperationBase);

exports.LoadDeviceIdentity = LoadDeviceIdentity;