"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Http = void 0;
var Http;

(function (Http) {
  var HttpRequestMethod;

  (function (HttpRequestMethod) {
    HttpRequestMethod["Delete"] = "DELETE";
    HttpRequestMethod["Get"] = "GET";
    HttpRequestMethod["Post"] = "POST";
    HttpRequestMethod["Put"] = "PUT";
  })(HttpRequestMethod = Http.HttpRequestMethod || (Http.HttpRequestMethod = {}));

  var HttpStatus;

  (function (HttpStatus) {
    // 2xx - Success
    HttpStatus[HttpStatus["Ok"] = 200] = "Ok";
    HttpStatus[HttpStatus["Created"] = 201] = "Created";
    HttpStatus[HttpStatus["Accepted"] = 202] = "Accepted";
    HttpStatus[HttpStatus["NoContent"] = 204] = "NoContent"; // 4xx - Client Errors

    HttpStatus[HttpStatus["BadRequest"] = 400] = "BadRequest";
    HttpStatus[HttpStatus["Unauthorized"] = 401] = "Unauthorized";
    HttpStatus[HttpStatus["Forbidden"] = 403] = "Forbidden";
    HttpStatus[HttpStatus["NotFound"] = 404] = "NotFound";
    HttpStatus[HttpStatus["Conflict"] = 409] = "Conflict"; // 5xx - Server Errors

    HttpStatus[HttpStatus["InternalServerError"] = 500] = "InternalServerError";
    HttpStatus[HttpStatus["BadGateway"] = 502] = "BadGateway";
    HttpStatus[HttpStatus["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    HttpStatus[HttpStatus["GatewayTimeout"] = 504] = "GatewayTimeout";
  })(HttpStatus = Http.HttpStatus || (Http.HttpStatus = {}));
})(Http = exports.Http || (exports.Http = {}));