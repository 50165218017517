"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shareReducer = void 0;

var _ = require("lodash");

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions");

var INITIAL_STATE = Immutable({});
exports.shareReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.fetchShareFeedChannelsCompleted, function (state, action) {
  var feedChannels = _.cloneDeep(action.payload);

  _.map(feedChannels, function (item) {
    return _.assign(item, {
      type: "feed"
    });
  });

  return state.setIn(["feedChannels"], feedChannels);
}).build();