import * as React from "react";
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import { ActivityFeed } from "@xbox/social-core";
import {
    IAllPollPostsFeedResolver,
    AllPollPostsFeedContainer,
    IFeedItemAdminActionHanders
} from "@xbox/social-views";

import * as GlobalStyles from "../../containers/Styles";
import * as avs from "../analytics/AnalyticsViewer.Styles";
import { GenericEmptyState, GenericLoading } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { withClubSelectionContext } from "../../containers/ClubSelectionContext";
import { telemetryService } from "../../index";
import AllPollsClubActivityFeed from "../../components/activityfeed/AllPollsClubActivityFeed";
import { FeedViewType } from "../../components/infiniteScrollers/base/InfiniteScrollTypes";

const EmptyState = () => {
    return <GenericEmptyState message={Localize("feedtools.ballotPostsEmptyState")} />;
};

function getAllPollPostsPanel(
    openBallots: ActivityFeed.FeedItem[],
    closedBallots: ActivityFeed.FeedItem[],
    clubId: string,
    adminActions: IFeedItemAdminActionHanders
): JSX.Element | null {
    telemetryService.trackPageView("AllPollsFeed");
    let openPolls = (
        <AllPollsClubActivityFeed
            isErrored={false}
            feedItemSelected={() => null}
            updateMethod={() => null}
            clearActivityFeed={() => null}
            adminActions={adminActions}
            listData={openBallots}
            isValid={true}
            forcedFeedType={FeedViewType.FullView}
        />
    );

    let closedPolls = (
        <AllPollsClubActivityFeed
            isErrored={false}
            feedItemSelected={() => null}
            updateMethod={() => null}
            clearActivityFeed={() => null}
            adminActions={adminActions}
            listData={closedBallots}
            isValid={true}
            forcedFeedType={FeedViewType.FullView}
        />
    );

    return (
        <GlobalStyles.ContentContainer>
            <avs.AnalyticsMenu>
                <avs.AnalyticsMenuItem>
                    <NavLink activeClassName="active" to={`/app/${clubId}/manage/allpolls/open`}>
                        {Localize("feedtools.tabTitles.openPolls")}
                    </NavLink>
                </avs.AnalyticsMenuItem>
                <avs.AnalyticsMenuItem>
                    <NavLink activeClassName="active" to={`/app/${clubId}/manage/allpolls/closed`}>
                        {Localize("feedtools.tabTitles.closedPolls")}
                    </NavLink>
                </avs.AnalyticsMenuItem>
            </avs.AnalyticsMenu>
            <Switch>
                <Route exact={true} path={`/app/${clubId}/manage/allpolls/open`} render={() => openPolls}/>
                <Route exact={true} path={`/app/${clubId}/manage/allpolls/closed`} render={() => closedPolls}/>
            </Switch>
        </GlobalStyles.ContentContainer>
    );
}

const AllPollPostsFeedResolver: IAllPollPostsFeedResolver = {
    getAllPollPostsPanel: getAllPollPostsPanel
};

export default withClubSelectionContext(AllPollPostsFeedContainer(AllPollPostsFeedResolver, EmptyState, GenericLoading));
