"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartyService = void 0;

var inversify_1 = require("inversify");

var rxjs_1 = require("rxjs");

var services_1 = require("../../services");

var typings_1 = require("../../typings");

var PartyService =
/** @class */
function () {
  function PartyService(mpsdService, partyRtcClient, profileService, rtaConnectionFactory, userManager, xblService) {
    this.mpsdService = mpsdService;
    this.partyRtcClient = partyRtcClient;
    this.profileService = profileService;
    this.rtaConnectionFactory = rtaConnectionFactory;
    this.userManager = userManager;
    this.xblService = xblService;
    this.sPartyMembersAdded = new rxjs_1.Subject();
    this.sPartyMembersRemoved = new rxjs_1.Subject();
    this.sRemoteStreams = new rxjs_1.Subject();
    this.subscribePartyRtcClientEvents();
  }

  PartyService.prototype.startParty = function () {
    return __awaiter(this, void 0, void 0, function () {
      var currentUser, partyRtaConnectionId, _a;

      var _this = this;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 1:
            currentUser = _b.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [4
            /*yield*/
            , this.partyRtaConnectionId];

          case 2:
            partyRtaConnectionId = _b.sent();
            _a = this;
            return [4
            /*yield*/
            , this.mpsdService.createNewPartySession(currentUser.xuid, partyRtaConnectionId, this.getFakeQoSMeasurements())];

          case 3:
            _a.mpsdSession = _b.sent();
            this.mpsdSession.oSessionDocumentUpdate.subscribe(function (changeNumber) {
              return _this.onSessionDocumentUpdated();
            });
            this.mpsdSession.oMembersAdded.subscribe(function (addedMembers) {
              return _this.onMembersAdded(addedMembers);
            });
            this.mpsdSession.oMembersRemoved.subscribe(function (removedMembers) {
              return _this.onMembersRemoved(removedMembers);
            });
            return [4
            /*yield*/
            , this.initializeParty(this.mpsdSession)];

          case 4:
            _b.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.leaveParty = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.mpsdSession) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , this.mpsdSession.leaveParty()];

          case 1:
            _a.sent();

            this.mpsdSession = undefined;
            this.partyRtcClient.closeConnection();
            _a.label = 2;

          case 2:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.joinPartyByXuid = function (xuid) {
    return __awaiter(this, void 0, void 0, function () {
      var sessions, sessionId;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.mpsdService.getPartySessionsForUser(xuid)];

          case 1:
            sessions = _a.sent();
            sessionId = sessions[0].sessionRef.name;
            if (!sessionId) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.joinPartyById(sessionId)];

          case 2:
            _a.sent();

            return [3
            /*break*/
            , 4];

          case 3:
            throw new Error("Invalid session ID");

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.joinPartyById = function (sessionId) {
    return __awaiter(this, void 0, void 0, function () {
      var isJoinable, currentUser, partyRtaConnectionId, _a;

      var _this = this;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4
            /*yield*/
            , this.mpsdService.getSessionJoinability(sessionId)];

          case 1:
            isJoinable = _b.sent();

            if (!isJoinable) {
              throw new Error("Party is not joinable");
            }

            return [4
            /*yield*/
            , this.userManager.currentUser];

          case 2:
            currentUser = _b.sent();

            if (!currentUser) {
              throw new Error("No user signed in");
            }

            return [4
            /*yield*/
            , this.partyRtaConnectionId];

          case 3:
            partyRtaConnectionId = _b.sent();
            _a = this;
            return [4
            /*yield*/
            , this.mpsdService.joinPartySession(sessionId, currentUser.xuid, partyRtaConnectionId, this.getFakeQoSMeasurements())];

          case 4:
            _a.mpsdSession = _b.sent();
            this.mpsdSession.oSessionDocumentUpdate.subscribe(function (changeNumber) {
              return _this.onSessionDocumentUpdated();
            });
            this.mpsdSession.oMembersAdded.subscribe(function (addedMembers) {
              return _this.onMembersAdded(addedMembers);
            });
            this.mpsdSession.oMembersRemoved.subscribe(function (removedMembers) {
              return _this.onMembersRemoved(removedMembers);
            });
            return [4
            /*yield*/
            , this.initializeParty(this.mpsdSession)];

          case 5:
            _b.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.inviteUser = function (xuid) {
    if (this.mpsdSession) {
      this.mpsdSession.inviteUser(xuid).catch(function (err) {
        return console.log("Failed to invite user. xuid = " + xuid);
      });
    }
  };

  PartyService.prototype.inviteByGamertag = function (gamertag) {
    return __awaiter(this, void 0, void 0, function () {
      var profile;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.profileService.getProfileByGamertag(gamertag)];

          case 1:
            profile = _a.sent();

            if (profile) {
              this.inviteUser(profile.id);
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.joinByGamertag = function (gamertag) {
    return __awaiter(this, void 0, void 0, function () {
      var profile;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.profileService.getProfileByGamertag(gamertag)];

          case 1:
            profile = _a.sent();

            if (profile) {
              this.joinPartyByXuid(profile.id);
            } else {
              throw new Error("Unable to find profile for gamertag: " + gamertag);
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.getFakeQoSMeasurements = function () {
    var _a;

    var measurements = (_a = {}, _a["north central us"] = {
      latency: 100
    }, _a["west us"] = {
      latency: 1
    }, _a);
    return measurements;
  };

  Object.defineProperty(PartyService.prototype, "oPartyMembersAdded", {
    get: function get() {
      return this.sPartyMembersAdded;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyService.prototype, "oPartyMembersRemoved", {
    get: function get() {
      return this.sPartyMembersRemoved;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyService.prototype, "oRemoteStreams", {
    get: function get() {
      return this.sRemoteStreams;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyService.prototype, "partyRtaConnectionId", {
    get: function get() {
      var _this = this; // There should only ever be a single RTA connection and subscription
      // for Party and it should be reused for new Parties


      if (this._partyRtaConnectionId) {
        return Promise.resolve(this._partyRtaConnectionId);
      } else {
        return this.createPartyRtaSubscription().then(function (connectionId) {
          _this._partyRtaConnectionId = connectionId;
          return connectionId;
        });
      }
    },
    enumerable: false,
    configurable: true
  });

  PartyService.prototype.createPartyRtaSubscription = function () {
    return __awaiter(this, void 0, void 0, function () {
      var response, responseParts, partyRtaConnectionId;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            // There should only ever be a single RTA connection and subscription
            // for Party and it should be reused for new Parties
            if (this.rtaConnection) {
              throw new Error("An RTA connection has already been established");
            }

            this.rtaConnection = this.rtaConnectionFactory.createRtaConnection(function (message) {
              console.log("RTA message received: " + message);
              var messageParts = JSON.parse(message);
              var messageType = messageParts[0]; // tslint:disable-next-line:triple-equals

              if (_this.mpsdSession && messageType == 3) {
                // incoming shoulder tap
                var messageObj = messageParts[2];
                var changeNumber = messageObj.shoulderTaps[0].changeNumber; // tslint:disable-next-line:triple-equals

                if (changeNumber && changeNumber != _this.mpsdSession.changeNumber) {
                  _this.mpsdSession.refreshSessionDocument().catch(function (error) {
                    return console.log("Error refreshing document after shoulder tap: " + error);
                  });

                  _this.partyRtcClient.sendShoulderTapMessage(+changeNumber);
                }
              }
            });
            return [4
            /*yield*/
            , this.rtaConnection.connect()];

          case 1:
            _a.sent();

            return [4
            /*yield*/
            , this.rtaConnection.sendMessageAndAwait(services_1.RtaMessageType.Subscribe, "https://sessiondirectory.xboxlive.com/connections/")];

          case 2:
            response = _a.sent();
            responseParts = JSON.parse(response);
            partyRtaConnectionId = responseParts[4].ConnectionId;

            if (!partyRtaConnectionId) {
              throw new Error("Failed to create Party RTA subscription");
            }

            return [2
            /*return*/
            , partyRtaConnectionId];
        }
      });
    });
  };

  PartyService.prototype.initializeParty = function (session) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!session.cloudCompute) return [3
            /*break*/
            , 1]; // Forcibly update the party relay info, since a shoulder tap may not come immediately

            this.updatePartyRelay();
            return [3
            /*break*/
            , 3];

          case 1:
            if (!session.allocateCloudCompute) {// TODO: Request relay
            }

            return [4
            /*yield*/
            , this.partyRtcClient.createConnection()];

          case 2:
            _a.sent();

            _a.label = 3;

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.subscribePartyRtcClientEvents = function () {
    var _this = this;

    this.partyRtcClient.oClientInfo.subscribe(function (clientInfoValue) {
      if (clientInfoValue && _this.mpsdSession) {
        _this.mpsdSession.setWebRtcClientInfo(clientInfoValue.dtlsCertificateAlgorithm, clientInfoValue.dtlsCertificateThumbprint, clientInfoValue.iceUfrag, clientInfoValue.icePwd).catch(function (err) {
          return console.error(err);
        });
      } else {
        console.log("Client info was updated but clientInfo or mpsdSession was null.");
      }
    });
    this.partyRtcClient.oMemberJoined.subscribe(function (mpsdMemberIndex) {
      // TODO: Update party member state to connected
      if (_this.mpsdSession && mpsdMemberIndex === _this.mpsdSession.localMemberIndex) {
        _this.mpsdSession.setConnectionState(services_1.PartyChatConnectionState.Connected);
      }
    });

    this.partyRtcClient.isLocalMemberIndex = function (mpsdMemberIndex) {
      if (_this.mpsdSession && mpsdMemberIndex === _this.mpsdSession.localMemberIndex) {
        return true;
      }

      return false;
    };

    this.partyRtcClient.oDataChannelOpened.subscribe(function () {
      if (_this.mpsdSession) {
        _this.mpsdSession.members.forEach(function (member) {
          _this.partyRtcClient.sendMemberSeenMessage(member.xuid, member.index);
        });
      }
    });
    this.partyRtcClient.oRemoteStream.subscribe(function (mediaStream) {
      _this.sRemoteStreams.next(mediaStream);
    });
  };

  PartyService.prototype.onSessionDocumentUpdated = function () {
    this.updatePartyRelay().catch(function (err) {
      return console.log("Failed to update party relay " + err);
    });
  };

  PartyService.prototype.updatePartyRelay = function () {
    return __awaiter(this, void 0, void 0, function () {
      var updated;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(this.mpsdSession && this.mpsdSession.cloudCompute)) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.partyRtcClient.updatePartyRelay(this.mpsdSession.cloudCompute.properties.custom.webRtc)];

          case 1:
            updated = _a.sent();
            if (!(updated && this.mpsdSession)) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , this.mpsdSession.setConnectionState(services_1.PartyChatConnectionState.Connecting)];

          case 2:
            _a.sent();

            _a.label = 3;

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyService.prototype.onMembersAdded = function (addedMembers) {
    var _this = this;

    addedMembers.forEach(function (member) {
      _this.partyRtcClient.sendMemberSeenMessage(member.xuid, member.index);
    });
    this.sPartyMembersAdded.next(addedMembers);
  };

  PartyService.prototype.onMembersRemoved = function (removedMembers) {
    this.sPartyMembersRemoved.next(removedMembers);
  };

  PartyService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IMpsdPartyService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IPartyRtcClient)), __param(2, inversify_1.inject(typings_1.XSocialSymbols.IProfileService)), __param(3, inversify_1.inject(typings_1.XSocialSymbols.IRtaConnectionFactory)), __param(4, inversify_1.inject(typings_1.XSocialSymbols.IUserManager)), __param(5, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object])], PartyService);
  return PartyService;
}();

exports.PartyService = PartyService;