import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Localize } from "../../../common/translation/Localize";
import { BaseBarChart } from "./BaseBarChart";
import { AnalyticsColor } from "../../../common/theme/Colors";

export class UniqueVisitorBars extends BaseBarChart {

    InitializeMonth = (month: string) => {
        this.CreateMonth(month, this.props.timesliceData, this.getDataByDate);
    }

    Initialize30Days = (timestamp: string) => {
        this.Create30Days(timestamp, this.props.timesliceData, this.getDataByDate);
    }

    getDataByDate(date: any, dataList: any) {
        let data: any = _.find(dataList, (item: any) => date === moment(item.timestamp, "X").format("MM/DD"));
        return { "visitors": data ? data.visitors : 0 };
    }

    render() {
        if (!this.props.timesliceData) {
            return this.constructWatermark("analyticsView.visitorsWatermark");
        }
        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={600} height={300} data={this.state.chartData}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                    <XAxis dataKey="timestamp" axisLine={false} tickLine={false} />
                    <YAxis axisLine={false} tickLine={false} allowDecimals={false} tickFormatter={this.tickformatter} />
                    <Tooltip />
                    <Bar dataKey="visitors" name={Localize("analyticsView.chartVisitors")} stackId="a" fill={AnalyticsColor.green} />
                </BarChart >
            </ResponsiveContainer>
        );
    }
}
