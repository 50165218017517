import * as React from "react";
import { Select, DatePicker, TimePicker } from "antd";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import { BallotPayload } from "@xbox/social-redux";
import { ActivityFeed, BallotResultAvailability, BallotOptions } from "@xbox/social-core";
import * as fts from "./FeedTools.Styles";
import { CommonPostDetailsProps } from "./FeedToolsTypes";
import { GenericErrorMessage, GenericLoading } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { PostTextArea } from "../PostTextArea";
import { PostOptionsContainer } from "./PostOptionsContainer";
import { PreviewBallotFeedItem } from "../feeditems/BallotFeedItem";
import { Moment } from "moment-timezone";

interface PollPostDataState {
    pollChoices: string[];
    pollCloseDate: moment.Moment | undefined;
    pollCloseTime: moment.Moment | undefined;
    pollQuestion: string;
    visible: BallotResultAvailability;
}

const Option = Select.Option;
const MIN_OPTIONS_COUNT: number = 2;
const MAX_OPTIONS_COUNT: number = 3;

export class CreateBallotFeedItem extends React.Component<CommonPostDetailsProps, PollPostDataState> {
    private exportTime: moment.Moment = moment();
    private postOptionsContainerRef: React.RefObject<any> = React.createRef();

    constructor(props: any) {
        super(props);
        this.state = {
            pollChoices: Array(MIN_OPTIONS_COUNT).fill(""),
            pollCloseDate: undefined,
            pollCloseTime: undefined,
            pollQuestion: "",
            visible: BallotResultAvailability.AfterVoting
        };
    }

    componentWillUpdate(nextProps: any) {
        // Only reset post after post is successful
        // check this by seeing if the result data of the redux for the post status is new,
        // this only updates on successful post
        let status = nextProps.hydrationStates.postStatus.resultData;
        if (status && status !== this.props.hydrationStates.postStatus.resultData) {
            this.resetState();
        }
    }

    createPoll = (isPinned: boolean, isDisabled: boolean, isScheduled: boolean, scheduledDateTime?: string) => {

        let formattedChoices = _.filter( this.state.pollChoices, (choice) => choice !== "");

        let timeline: ActivityFeed.UserPostTimeline = {
            timelineType: ActivityFeed.TimelineType.Club,
            timelineOwner: this.props.clubId
        };

        if (this.props.selectedChannel && !this.props.selectedChannel.isDefault) {
            timeline.channelId = `${this.props.selectedChannel.id}`;
        }

        let options: BallotOptions = {
            Choices: formattedChoices,
            Visible: this.state.visible,
            EndTime: _.cloneDeep(this.exportTime).utc().format(),
            PollQuestion: this.state.pollQuestion,
        };

        let rawpostPayload: ActivityFeed.UserPost = {
            postText: this.state.pollQuestion,
            postType: ActivityFeed.PostType.Ballot,
            timelines: [timeline],
            postDate: scheduledDateTime,
            postTypeData: {
                pollId: "",
                creatorId: ""
            }
        };

        let ballotPayload: BallotPayload = {
            clubId: this.props.clubId,
            ballotOptions: options,
            postData: rawpostPayload,
            isScheduled: isScheduled,
            isPinned: isPinned,
            isDisabled: isDisabled
        };

        this.props.createBallot(ballotPayload);
    }

    resetState = () => {
        this.setState({
            pollChoices: Array(MIN_OPTIONS_COUNT).fill(""),
            pollCloseDate: undefined,
            pollCloseTime: undefined,
            pollQuestion: "",
            visible: BallotResultAvailability.AfterVoting
        });
        this.exportTime = moment();
    }

    getTimeZone(): string {
        let zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment().tz(zoneName).format("z");
    }

    validateBallot = () => {
        let blockBallot = true;
        if (this.state.pollCloseDate && this.state.pollCloseTime) {
            this.exportTime.set({
               "date"     : this.state.pollCloseDate.get("date"),
               "month"    : this.state.pollCloseDate.get("month"),
               "year"     : this.state.pollCloseDate.get("year"),
               "hour"     : this.state.pollCloseTime.get("hour"),
               "minute"   : this.state.pollCloseTime.get("minute")
            });
            if ((this.state.pollQuestion !== "")
                && (_.filter(this.state.pollChoices, (choice: string) => choice !== "").length >= MIN_OPTIONS_COUNT)
                && (moment().isBefore(this.exportTime))
            ) {
                blockBallot = false;
            }
        }
        return blockBallot;
    }

    onChangeQuestion = (e: any) => {
        this.setState({ pollQuestion: e.target.value });
    }

    onChangePollChoice = (choice: string, index: number) => {
        let newValues = this.state.pollChoices;
        newValues[index] = choice.replace("\n", "");
        this.setState({ pollChoices: newValues });
    }

    onResultsVisibility = (value: any) => {
        this.setState({visible: value});
    }

    onChangeEndDate = (dateTime: moment.Moment) => {
        if (!dateTime || dateTime === null) {
            return;
        }
        this.setState({pollCloseDate: dateTime});
    }

    onChangeEndTime = (dateTime: moment.Moment) => {
        if (!dateTime || dateTime === null) {
            return;
        }
        this.setState({pollCloseTime: dateTime});
    }

    generateOptionField = (index: number, canDelete: boolean, isLast: boolean) => {
        return (
            <fts.OptionWrapper key={index}>
                <PostTextArea
                    placeholder={Localize("postFeedItem.ballot.optionWithNum").replace("${index}", index + 1)}
                    value={this.state.pollChoices[index]}
                    onChange={(x: any) => this.onChangePollChoice(x.target.value, index)}
                    maxLength={25}
                    Rows={1}
                    style={{width: "100%"}}
                />
                {canDelete && <fts.BallotOptionButton buttonType={"danger"} onClick={() => this.deleteOptionField(index)}>{Localize("generic.delete")}</fts.BallotOptionButton>}
                {isLast && this.state.pollChoices.length < MAX_OPTIONS_COUNT && <fts.BallotOptionButton className="addOptionButton" onClick={this.addOptionField}>{Localize("postFeedItem.ballot.generateOption")}</fts.BallotOptionButton>}
            </fts.OptionWrapper>
        );
    }

    deleteOptionField(index: number) {
        let temp = this.state.pollChoices;
        temp = temp.slice(0, index).concat(temp.slice(index + 1));
        this.setState({pollChoices: temp});
        setTimeout(() => {
            let addOptionBtn = document.querySelector(".addOptionButton") as HTMLButtonElement;
            addOptionBtn.focus();
        }, 500);
    }

    generateSelectTag = () => {
        // In an enum keys, first half of the list is keys, other is values
        // We just need to see which half is which and use the half without the numbers
        let keys = Object.keys(BallotResultAvailability);
        let optionsRaw = Number.isNaN(parseInt(keys[0], 10)) ? keys.slice(0, keys.length / 2) : keys.slice(keys.length / 2);

        let resultsList: JSX.Element[] = [];
        _.forEach(optionsRaw, (option: string) => resultsList.push(
            <Option key={option} value={BallotResultAvailability[option]}>{Localize(`postFeedItem.ballot.${option}`)}</Option>
        ));
        return (
            <fts.BottomMarginRow>
                <div>{Localize("postFeedItem.ballot.showResults")}</div>
                <fts.BallotSelect value={this.state.visible} onChange={this.onResultsVisibility}>
                    {resultsList}
                </fts.BallotSelect>
            </fts.BottomMarginRow>
        );
    }

    onPreviewItem = (isPinned: boolean, scheduledDateTime?: string) => {
        let gamerPic = "", gamerTag = "";
        if (this.props.currentUser && this.props.currentUser.profile) {
            gamerPic = this.props.currentUser.profile.gamerpic;
            gamerTag = this.props.currentUser.profile.gamertag;
        }

        return (
            <PreviewBallotFeedItem
                pinned={isPinned}
                itemText={this.state.pollQuestion}
                userGamerPicUrl={gamerPic}
                userGamerTag={gamerTag}
                choices={this.state.pollChoices}
                endTime={this.exportTime}
                visbility={this.state.visible}
                selectedChannel={this.props.selectedChannel}
                scheduledDateTime={scheduledDateTime}
            />);
    }

    createOptionFields = () => {
        let optionList = [];
        for (var i = 0; i < this.state.pollChoices.length; i++) {
            optionList.push(this.generateOptionField(i, i >= MIN_OPTIONS_COUNT, i === this.state.pollChoices.length - 1));
        }
        return optionList;
    }

    addOptionField = () => {
        if (this.state.pollChoices.length < MAX_OPTIONS_COUNT) {
            this.setState({pollChoices: this.state.pollChoices.concat([""])});
        }
    }

    disabledDate(current: any) {
        return current && current < moment().startOf("day");
    }

    render() {
        if (!this.props.permissions) {
            return <GenericLoading/>;
        }

        if (!this.props.permissions.post) {
            return (
                <GenericErrorMessage
                    errorMessage={Localize("feedtools.warning", { context: "poll" })}
                    showError={true}
                />
            );
        }

        return (
            <div>
                <fts.Header>
                    {Localize("postFeedItem.postDetails")}
                </fts.Header>
                <fts.BottomMarginRow>
                    <PostTextArea
                        placeholder={Localize("postFeedItem.ballot.pollQuestion")}
                        value={this.state.pollQuestion}
                        onChange={this.onChangeQuestion}
                        Rows={2}
                        maxLength={75}
                    />
                </fts.BottomMarginRow>
                {this.createOptionFields()}
                <fts.BottomMarginRow>
                    {this.state.pollChoices.length === MAX_OPTIONS_COUNT && <GenericErrorMessage errorMessage={Localize("postFeedItem.ballot.maxOptions")} showError={true} />}
                </fts.BottomMarginRow>
                {/*this.generateSelectTag()*/}
                <fts.Header>
                    {Localize("postFeedItem.ballot.setEndTime")}
                </fts.Header>
                <fts.BottomMarginRow gutter={4}>
                    <DatePicker placeholder={"MM/DD/YYYY"} value={this.state.pollCloseDate} disabledDate={this.disabledDate} allowClear={false} onChange={(date: Moment | null, dateString: string) => {if (date) { this.onChangeEndDate(date); }}} />
                    <TimePicker value={this.state.pollCloseTime} format={"h:mm A"} use12Hours={true} onChange={this.onChangeEndTime}/>
                    <span>{this.getTimeZone()}</span>
                </fts.BottomMarginRow>

                <fts.BottomMarginRow>
                    <PostOptionsContainer
                        ref={this.postOptionsContainerRef}
                        preventedDateTime={this.exportTime}
                        onPostFeedItem={this.createPoll}
                        onCancelPost={this.resetState}
                        getPreviewItem={this.onPreviewItem}
                        canPin={this.props.permissions.pinPost}
                        canSchedule={this.props.permissions.schedulePost}
                        postAbilityBlocked={this.validateBallot()}
                        forceSchedule={this.props.forceSchedule}
                    />
                </fts.BottomMarginRow>
            </div>
        );
    }
}
