"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hydratorReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ActivityFeedActions_1 = require("../../actions/ActivityFeedActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.hydratorReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkInProgress, function (state, action) {
  return state.merge({
    inProgress: action.payload
  });
}).on(ActivityFeedActions_1.ActivityFeedActions.HydrateWebLinkCompleted, function (state, action) {
  return state.merge({
    linkData: action.payload
  }, {
    deep: true
  });
}).build();