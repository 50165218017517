import * as React from "react";
import { ResponsiveContainer, Legend, PieChart, Pie, Cell } from "recharts";
import { AnalyticsColor } from "../../../common/theme/Colors";
import { Swap } from "../../../Utils";
import { CustomizedLegend } from "./BaseBarChart";
import * as countryList from "./CountryList.json";

interface AnalyticsPieChartProps {
    engagementByLocale?: any;
    locale?: any;
    age?: any;
    gender?: any;
}

interface ChartDataProps {
    name: string;
    value: number;
}

export default function AnalyticsPieChart(props: AnalyticsPieChartProps) {
    let chartData: ChartDataProps[] = [];
    let data = props.age || props.engagementByLocale || props.gender || props.locale;
    let dataKeys = Object.keys(data);
    let sum: number = 0;
    let unknownIndex: number = -1;

    const PieChartColors = [
        "#0088FE",  // blue
        "#00C49F",  // teal
        "#FFBB28",  // yellow ochre
        "#FF8042",  // orange
        "#00E51E",  // bright green
        "#FF0088",  // magenta
        "#87cefa",  // sky blue
        "#FF2266",  // pink
        "#C0C0C0",  // grey
    ];

    if (data) {
        // sum all
        dataKeys.forEach(key => {
            if (data[key]) {
                sum += data[key];
            }
        });

        // assign chartData and record "unknown" index
        // legend name is combined with chartData name
        dataKeys.forEach(key => {
            if (data[key]) {
                if (key.match(/Unknown|UNKNOWN/)) {
                    unknownIndex = chartData.length;
                }

                // [TEMP] sync feedEngagementLocale and locale country name as short name
                // service fix would happen in April
                chartData.push({ name: countryList[key] ? countryList[key] : key, value: data[key] });
            }
        });

        // if unknown exists, swap it with the last elem
        if (unknownIndex !== -1) {
            Swap(chartData, unknownIndex, chartData.length - 1);
        }

        // [temporary] sort gender and age in proper order
        if (chartData[0].name.indexOf("Female") !== -1) {
            Swap(chartData, 0, 1);
        } else if (!isNaN(parseFloat(chartData[0].name.charAt(0)))) {
            chartData.sort((a: ChartDataProps, b: ChartDataProps) => {
                return parseFloat(a.name.charAt(0)) - parseFloat(b.name.charAt(0));
            });
        }
    }

    if (props.locale) {
        // if less than 6 items, show all
        // if more than 6 items, get top 5 and sum the rest as "Other"
        chartData.sort((a: ChartDataProps, b: ChartDataProps) => { return b.value - a.value; });

        if (dataKeys.length > 6) {
            chartData = chartData.slice(0, 5);

            let otherVal = sum - chartData.reduce((accumulator: number, item: ChartDataProps) => {
                return accumulator + item.value;
            }, 0);

            let otherObj = { name: "Other", value: otherVal };
            chartData.push(otherObj);
        }
    }

    return (
        <ResponsiveContainer width={350} height={200}>
            <PieChart>
                <Legend content={CustomizedLegend} align="right" layout="vertical" verticalAlign="middle" />
                <Pie cx="45%" outerRadius="90%" data={chartData} dataKey="value" nameKey="name" labelLine={false} fill={AnalyticsColor.purple}>
                    {
                        chartData.map((entry, index) => {
                            // assign "unknown" with grey color
                            if (entry.name.match(/Unknown|UNKNOWN/)) {
                                return (<Cell key={index} fill={PieChartColors[PieChartColors.length - 1]} />);
                            }
                            return (<Cell key={index} fill={PieChartColors[index % PieChartColors.length]} />);
                        })
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer >
    );
}
