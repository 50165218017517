import * as React from "react";
import { Select, List, Input, message } from "antd";
import { ClubHub, edsImageResizer, EdsImageSize, TitleHub, Lfg } from "@xbox/social-core";
import { ILfgPanelResolver, CoreLfgContainer, ILfgHandlers, ILfgAdminActions } from "@xbox/social-views";

import * as GlobalStyles from "../Styles";
import * as ClubAdminStyles from "../clubadmin/ClubAdmin.Styles";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { withCurrentUserContext } from "../CurrentUserContext";
import { GenericEmptyState, GenericLoading } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { LfgCard } from "../../components/lfg/LfgCard";
import { ThemedSelect, InlineContent } from "../../common/Generic.Styles";
import Util from "../../common/Util";

const Option = Select.Option;
const Search = Input.Search;

function getLfgPanel(
    isLoading: boolean,
    showAdminAction: boolean,
    adminActions: ILfgAdminActions,
    club: ClubHub.Club,
    handlers: ILfgHandlers,
    lfgs?: Lfg.LfgItem[],
    selected?: TitleHub.Title,
    query?: string
) {
    let titles = club.details.profile.associatedTitles.value.filter(t => t.serviceConfigId !== null);
    let content;

    if (!isLoading) {
        if (lfgs && lfgs.length) {
            content = (
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        pageSize: 15,
                    }}
                    dataSource={lfgs}
                    renderItem={(lfgItem: Lfg.LfgItem) => (
                        <List.Item key={lfgItem.id.toString()}>
                            <LfgCard item={lfgItem} showAdminAction={showAdminAction} adminActions={adminActions} tagClicked={search}/>
                        </List.Item>
                    )}
                />
            );
        } else {
            content = <GenericEmptyState message={Localize("lfgs.emptyState")}/>;
        }
    } else {
        content = <GenericLoading />;
    }

    function search(value: string) {
        value = value.toLowerCase();
        let validRegEx = /^[a-zA-Z0-9-_\s]*$/;
        if (!value.match(validRegEx)) {
            message.warn(Localize("lfgs.tagsSearchError"));
            return;
        }
        handlers.searchTags(value);
    }

    return (
        <GlobalStyles.LfgContentContainer className="LfgContentContainer" style={{flex: "unset"}}>
            <h1>{isLoading ? Localize("lfgs.lfgs") : Localize("lfgs.lfgsWithCount", { count: lfgs ? lfgs.length : 0 })}</h1>
            <div>
                <InlineContent style={{ width: "100%", minWidth: "200px", maxWidth: "400px", marginBottom: 10, marginRight: 10 }}>
                    <ThemedSelect aria-label={Localize("clubMenu.lfg")} defaultValue={Localize("lfgs.selectTitle")} value={selected && selected.serviceConfigId} style={{ width: "100%"}} onChange={e => handlers.selectTitle(e)} defaultActiveFirstOption={true}>
                        {titles.map(title => title.name ? <Option key={title.titleId} value={title.serviceConfigId}><ClubAdminStyles.PlayedTitleImage src={edsImageResizer( Util.ensureSSLImage(title.displayImage || ""), EdsImageSize._100x100)} />
                            {title.name}</Option> : null)}
                    </ThemedSelect>
                </InlineContent>
                <InlineContent style={{ width: 200, marginRight: 10}}>
                    <Search
                        maxLength={50}
                        placeholder={Localize("lfgs.searchTags")}
                        onSearch={value => search(value)}
                    />
                </InlineContent>
            </div>

            <h3>{query ? Localize("lfgs.searchingFor") + " " + query  : ""}</h3>
            {content}
        </GlobalStyles.LfgContentContainer>);
}

const LfgResolver: ILfgPanelResolver = {
    getLfgPanel: getLfgPanel
};

export default withClubSelectionContext(withCurrentUserContext(CoreLfgContainer(LfgResolver, GenericLoading, GenericEmptyState)));
