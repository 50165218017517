export const LightTheme = {
    backgroundMain: "#fff",
    backgroundSecondary: "#f0f2f5",
    backgroundTertiary: "#f5f5f5",
    backgroundThemedOverlay: "rgba(0, 0, 0, 0.25)",
    backgroundBlackOverlay: "rgba(0,0,0, 0.25)",
    backgroundDisabled: "#f5f5f5",

    oppositeBackground: "#000",
    oppositeForeground: "#fff",

    borderMain: "#d9d9d9",
    borderSecondary: "#ddd",
    borderTertiary: "#ccc",
    labelText: "#000",
    grayText: "#6a6a6a",

    foregroundMain: "#666",
    foregroundSecondary: "#333",
    foregroundBrigth: "#000",
    foregroundOverlayed: "rgba(0,0,0, .65)",
    foregroundDisabled: "rgba(0,0,0, .25)",

    lightHeader: "#999",
    description: "#929292",

    grayShadeA: "#5b5b5b",
    grayShadeB: "#818c94",
    dangerBtnBackground: "#1a1a1a",
    hoverMain: "#e9e9e9",

    redRest: "#f5222d",
    redHover: "#ff4d4f",
    btnBgColor: "#333333",
    btnOutlineColor: "2px solid #333",
    redActive: "#d20000",
    errorActive: "#d20000",

    blueRest: "#1990FF",
    privacyTrueLink: "#000",
    blueHover: "#40a9ff",
    blueActive: "#0266b8",

    green: "#20ff20",
    greenRest: "#16a516",
    greenDark: "#1A801A",

    footerFrg: "#f1f1f1",
    footerBckg: "#555",

    friendLabelBckg: "#bae7ff",
    favoriteLabelBckg: "#ffadd2",
    followsLabelBckg: "#e8e8e8",

    whiteText: "#fff",
    btnTextColor: "#fff",
    whiteBackground: "#fff",
    blackText: "#000",
    blackBackground: "#000",
    barBackground: "#000",
    activeTabColor: "#FFF",
    activeTabBgColor: "#000000",

    clubMainOverlay: "rgba(255,255,255, 0.25)",
    clubManageOverlay: "rgba(255,255,255, 0.5)",
    clubManageActiveItem: "#fff",
    clubMainBackground: "#e8e8e8",

    chatMessageBackground: "#cbcbcb",
    chatMessageFallback: "#777",

    gridCardOverlayTop: "#ffffff99",
    gridCardOverlayTopMiddle: "#ffffffB3",
    gridCardOverlayBottomMiddle: "#ffffffB3",
    gridCardOverlayBottom: "#e6e6e6FF",
    gridContentGradientStart: "#000000FF",
    gridContentGradientStop: "#b3b3b3b3",

    popupBackground: "#fff",
    popupActiveOverlay: "#f6f6f6",
    popupBoxShadow: "#1A1A1A1A",
    signOutBtn: "#000000",
    deleteBtn: "#FFF",
    signOutBlackBorder:  "#000000",
    signOutWhiteBorder: "#FFF",
    activeTabBorder: "#FFF"
};
