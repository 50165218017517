"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignInBase = exports.SignInBaseStep = void 0;

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var Utils_1 = require("../../Utils");

var XboxToken_1 = require("../../XboxToken");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var GetMsaTicket_1 = require("./GetMsaTicket");

var GetXtoken_1 = require("./GetXtoken");

var InitializeTokenStack_1 = require("./InitializeTokenStack");

var SignOut_1 = require("./SignOut");

var SignInBaseStep;

(function (SignInBaseStep) {
  SignInBaseStep["Start"] = "Start";
  SignInBaseStep["Init"] = "Init";
  SignInBaseStep["AuthenticateWithMsa"] = "AuthenticateWithMsa";
  SignInBaseStep["GetXtoken"] = "GetXtoken";
  SignInBaseStep["ShowSisuUi"] = "ShowSisuUi";
  SignInBaseStep["SignOut"] = "SignOut";
  SignInBaseStep["Done"] = "Done";
})(SignInBaseStep = exports.SignInBaseStep || (exports.SignInBaseStep = {}));

var SignInBase =
/** @class */
function (_super) {
  __extends(SignInBase, _super);

  function SignInBase(area, telemetryClient, tokenStackComponents, webView, httpClient, forceCallSisuForXtoken, preexistingMsaUserId) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.tokenStackComponents = tokenStackComponents;
    _this.webView = webView;
    _this.httpClient = httpClient;
    _this.forceCallSisuForXtoken = forceCallSisuForXtoken;
    _this.preexistingMsaUserId = preexistingMsaUserId;
    _this.hasRestarted = false;
    _this.step = new StepTracker_1.StepTracker(area, SignInBaseStep.Start); // XAL Native creates an AccountData object and assigns this to it
    // however, this is more painful to deal with in TS, so we save it
    // in a separate field and add it to AccountData when needed

    _this.webAccountId = _this.preexistingMsaUserId;
    return _this;
  }

  SignInBase.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var nsalUri, endpoint, ticketResult;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === SignInBaseStep.Start);
            this.step.advance(SignInBaseStep.Init);
            return [4
            /*yield*/
            , this.init()];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === SignInBaseStep.Init);
            nsalUri = this.tokenStackComponents.config.xboxLiveEndpoint;
            endpoint = this.tokenStackComponents.nsal.lookup(nsalUri);

            if (!endpoint.Endpoint) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Xbox Live endpoint missing from NSAL document.");
              this.step.advance(SignInBaseStep.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Xbox Live endpoint missing from NSAL document."));
              return [2
              /*return*/
              ];
            }

            this.signInEndpoint = endpoint.Endpoint; // Give the child class a chance to serve the request without making any network calls

            if (this.serveRequestFromChild()) {
              // If the child class returned true, it means they've already completed the operation
              return [2
              /*return*/
              ];
            }

            return [4
            /*yield*/
            , this.authenticateWithMsa()];

          case 2:
            ticketResult = _a.sent();
            return [4
            /*yield*/
            , this.authenticateWithMsaCallback(ticketResult)];

          case 3:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  SignInBase.prototype.succeedWithXtoken = function (xtoken) {
    var tokenData = xtoken.tokenData;
    var account = {
      webAccountId: this.webAccountId,
      xuid: tokenData.xuid,
      gamertag: tokenData.gamertag,
      ageGroup: tokenData.ageGroup,
      privileges: tokenData.privileges,
      settingsRestrictions: tokenData.userSettingsRestrictions,
      enforcementRestrictions: tokenData.userEnforcementRestrictions,
      titleRestrictions: tokenData.userTitleRestrictions
    };
    this.step.advance(SignInBaseStep.Done);
    this.succeed(account);
  };

  SignInBase.prototype.signOutAndRestartSignIn = function (area) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, ticketResult;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(SignInBaseStep.SignOut);
            Utils_1.xalTrace(Utils_1.TraceLevel.Information, "Operation " + area + " is signing out and starting over");
            this.hasRestarted = true;
            this.preexistingMsaUserId = undefined;
            operation = new SignOut_1.SignOut(this.telemetryClient, this.tokenStackComponents, this.webView, this.webAccountId);
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === SignInBaseStep.SignOut); // Sign out was successful. Reset data back to original values.

            this.webAccountId = this.preexistingMsaUserId;
            this.msaTicket = undefined;
            this.sisuSessionId = undefined;
            this.sisuClusterAffinity = undefined;
            return [4
            /*yield*/
            , this.authenticateWithMsa()];

          case 2:
            ticketResult = _a.sent();
            return [4
            /*yield*/
            , this.authenticateWithMsaCallback(ticketResult)];

          case 3:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  SignInBase.prototype.authenticateWithMsa = function () {
    this.step.advance(SignInBaseStep.AuthenticateWithMsa);
    var operation = new GetMsaTicket_1.GetMsaTicket(this.telemetryClient, this.tokenStackComponents.config.userSignInScopes, false, // forceRefresh,
    this.hasRestarted, // skipSplash
    this.tokenStackComponents, this.httpClient, this.webView, "SignInMsa", // callingOperation
    this.preexistingMsaUserId);
    operation.execute();
    return operation.completionPromise;
  };

  SignInBase.prototype.authenticateWithMsaCallback = function (ticketResult) {
    return __awaiter(this, void 0, void 0, function () {
      var xtokenResultPromise;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === SignInBaseStep.AuthenticateWithMsa);
            Utils_1.assert(Utils_1.isNullOrWhiteSpace(this.preexistingMsaUserId) || this.preexistingMsaUserId === ticketResult.msaUser.userId);

            if (this.isUserDuplicated(ticketResult.msaUser.userId)) {
              this.step.advance(SignInBaseStep.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.DuplicatedUser, "Duplicate user received"));
              return [2
              /*return*/
              ];
            }

            this.webAccountId = ticketResult.msaUser.userId;
            this.msaTicket = ticketResult.ticket;
            this.sisuSessionId = ticketResult.sisuSessionId;
            this.sisuClusterAffinity = ticketResult.sisuClusterAffinity;
            xtokenResultPromise = this.getXtoken();
            return [4
            /*yield*/
            , this.handleXtokenResult(xtokenResultPromise)];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  SignInBase.prototype.getXtoken = function () {
    this.step.advance(SignInBaseStep.GetXtoken);
    var accountXtoken = this.tokenStackComponents.xboxCache.getToken(XboxToken_1.IdentityType.Xtoken, this.signInEndpoint.RelyingParty, this.signInEndpoint.SubRelyingParty ? this.signInEndpoint.SubRelyingParty : "", this.signInEndpoint.TokenType, true, // hasSignInDisplayClaims
    this.webAccountId);
    var operation = new GetXtoken_1.GetXtoken(this.telemetryClient, this.tokenStackComponents, this.webView, this.webAccountId, false, // forceRefresh,
    this.forceCallSisuForXtoken, this.httpClient, accountXtoken, "SignInGetXtoken", // callingOperation
    this.msaTicket, this.sisuSessionId, this.sisuClusterAffinity);
    operation.execute();
    return operation.completionPromise;
  };

  SignInBase.prototype.init = function () {
    this.step.advance(SignInBaseStep.Init);
    var operation = new InitializeTokenStack_1.InitializeTokenStack(this.telemetryClient, this.tokenStackComponents, this.httpClient, false // offlineMode
    );
    operation.execute();
    return operation.completionPromise;
  };

  return SignInBase;
}(OperationBase_1.OperationBase);

exports.SignInBase = SignInBase;