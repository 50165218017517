"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.State = void 0;

var XalError_1 = require("../errors/XalError");

var XalErrorType_1 = require("../errors/XalErrorType");

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var ITelemetryClient_1 = require("./ITelemetryClient");

var OperationQueue_1 = require("./operations/OperationQueue");

var AddUser_1 = require("./operations/state/AddUser");

var GetAnonymousTokenAndSignature_1 = require("./operations/state/GetAnonymousTokenAndSignature");

var GetMsaTicketForAdditionalScope_1 = require("./operations/state/GetMsaTicketForAdditionalScope");

var GetTokenAndSignature_1 = require("./operations/state/GetTokenAndSignature");

var InitializeComponents_1 = require("./operations/state/InitializeComponents");

var ResolveTokenIssue_1 = require("./operations/state/ResolveTokenIssue");

var SignOutUser_1 = require("./operations/state/SignOutUser");

var TryAddFirstUserSilently_1 = require("./operations/state/TryAddFirstUserSilently");

var UserSet_1 = require("./UserSet");

var Utils_1 = require("./Utils");

var FinishUrl_1 = require("./operations/state/FinishUrl");

var State =
/** @class */
function () {
  function State(telemetryClient, platform) {
    this.telemetryClient = telemetryClient;
    this.platform = platform;
    this.queue = new OperationQueue_1.OperationQueue();
    this.users = new UserSet_1.UserSet(platform.settings.maxUsers);

    if (!platform.tokenStack) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InitializationError, "Token stack is null");
    }

    if (!platform.storage) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InitializationError, "Storage is null");
    }

    if (!platform.webView) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.InitializationError, "WebView is null");
    }

    telemetryClient.instrumentAppActivation();
  }

  State.prototype.init = function () {
    var operation = new InitializeComponents_1.InitializeComponents(this.telemetryClient, this.platform);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  State.prototype.tryAddFirstUserSilently = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, user, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalTryAddFirstUserSilently);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalTryAddFirstUserSilently, ITelemetryClient_1.EventAction.ActionStarted);
            operation = new TryAddFirstUserSilently_1.TryAddFirstUserSilently(this.telemetryClient, this.users, this.platform);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            user = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalTryAddFirstUserSilently, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            , user];

          case 3:
            error_1 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalTryAddFirstUserSilently, "Unsuccessful Result From TryAddFirstUserSilently Operation", ITelemetryClient_1.ErrorLevel.Warning, error_1.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalTryAddFirstUserSilently, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_1;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.addUserWithUi = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, user, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalAddUser);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAddUser, ITelemetryClient_1.EventAction.ActionStarted);
            operation = new AddUser_1.AddUser(this.telemetryClient, this.users, this.platform);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            user = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAddUser, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            , user];

          case 3:
            error_2 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalAddUser, "Unsuccessful Result From AddUser Operation", ITelemetryClient_1.ErrorLevel.Warning, error_2.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAddUser, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_2;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.addUserWithUrl = function (url) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, user, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalPlatformWebFinishUrlAsync);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalPlatformWebFinishUrlAsync, ITelemetryClient_1.EventAction.ActionStarted);
            operation = new FinishUrl_1.FinishUrl(this.telemetryClient, this.users, this.platform, url);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            user = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalPlatformWebFinishUrlAsync, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            , user];

          case 3:
            error_3 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalPlatformWebFinishUrlAsync, "Unsuccessful Result From FinishUrl Operation", ITelemetryClient_1.ErrorLevel.Warning, error_3.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalPlatformWebFinishUrlAsync, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_3;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.getTokenAndSignatureSilently = function (user, args) {
    return __awaiter(this, void 0, void 0, function () {
      var tokenArgs, operation, data, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!user) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid user");
            }

            if (!args) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid getTokenAndSignature args");
            }

            if (Utils_1.isNullOrWhiteSpace(args.method)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid HTTP method");
            }

            if (Utils_1.isNullOrWhiteSpace(args.url)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid request URL");
            }

            if (args.headers) {
              args.headers.forEach(function (header) {
                if (Utils_1.isNullOrWhiteSpace(header.Name) || typeof header.Value === "string" && Utils_1.isNullOrWhiteSpace(header.Value)) {
                  throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid header key or value specified. " + header.Name + ": " + header.Value);
                }
              });
            }

            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalUserGetTokenAndSignatureAsync);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionStarted, user);
            tokenArgs = {
              user: user,
              method: args.method,
              url: args.url,
              headers: args.headers,
              body: args.body,
              forceRefresh: args.forceRefresh
            };
            operation = new GetTokenAndSignature_1.GetTokenAndSignature(this.telemetryClient, this.platform, this.users, tokenArgs);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            data = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            , {
              token: data.token,
              signature: data.signature
            }];

          case 3:
            error_4 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalUserGetTokenAndSignatureAsync, "Unsuccessful Result From GetTokenAndSignature Operation", ITelemetryClient_1.ErrorLevel.Warning, error_4.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_4;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.getAnonymousTokenAndSignatureSilently = function (args) {
    return __awaiter(this, void 0, void 0, function () {
      var tokenArgs, operation, data, error_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL
            if (!args) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid getTokenAndSignature args");
            }

            if (Utils_1.isNullOrWhiteSpace(args.method)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid HTTP method");
            }

            if (Utils_1.isNullOrWhiteSpace(args.url)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid request URL");
            }

            if (args.headers) {
              args.headers.forEach(function (header) {
                if (Utils_1.isNullOrWhiteSpace(header.Name) || Utils_1.isNullOrWhiteSpace(header.Value)) {
                  throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid header key or value specified. " + header.Name + ": " + header.Value);
                }
              });
            }

            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalAnonymousGetTokenAndSignatureAsync);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAnonymousGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionStarted);
            tokenArgs = {
              method: args.method,
              url: args.url,
              headers: args.headers,
              body: args.body,
              forceRefresh: args.forceRefresh
            };
            operation = new GetAnonymousTokenAndSignature_1.GetAnonymousTokenAndSignature(this.telemetryClient, this.platform, tokenArgs);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            data = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAnonymousGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionCompleted);
            return [2
            /*return*/
            , {
              token: data.token,
              signature: data.signature
            }];

          case 3:
            error_5 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalAnonymousGetTokenAndSignatureAsync, "Unsuccessful Result From GetAnonymousTokenAndSignature Operation", ITelemetryClient_1.ErrorLevel.Warning, error_5.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalAnonymousGetTokenAndSignatureAsync, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_5;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.resolveUserIssueWithUi = function (user, url) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, error_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!user) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid user");
            }

            if (Utils_1.isNullOrWhiteSpace(url) || !Utils_1.isValidUri(url)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid url");
            }

            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalUserResolveTokenIssueAsync);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserResolveTokenIssueAsync, ITelemetryClient_1.EventAction.ActionStarted, user);
            operation = new ResolveTokenIssue_1.ResolveTokenIssue(this.telemetryClient, this.platform, this.users, user, url);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            _a.sent();

            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserResolveTokenIssueAsync, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [3
            /*break*/
            , 4];

          case 3:
            error_6 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalUserResolveTokenIssueAsync, "Unsuccessful Result From ResolveTokenIssue Operation", ITelemetryClient_1.ErrorLevel.Warning, error_6.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalUserResolveTokenIssueAsync, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_6;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.getMsaTicketForAdditionalScope = function (user, scope) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, ticket, error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (Utils_1.isNullOrWhiteSpace(scope)) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid scope requested");
            }

            if (!user) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid user");
            }

            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalGetMsaTicketForAdditionalScope);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalGetMsaTicketForAdditionalScope, ITelemetryClient_1.EventAction.ActionStarted, user);
            operation = new GetMsaTicketForAdditionalScope_1.GetMsaTicketForAdditionalScope(this.telemetryClient, this.platform, user, scope);
            this.queue.queueOperation(operation);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , operation.completionPromise];

          case 2:
            ticket = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalGetMsaTicketForAdditionalScope, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            , ticket];

          case 3:
            error_7 = _a.sent();
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.XalGetMsaTicketForAdditionalScope, "Unsuccessful Result From GetMsaTicketForAdditionalScope Operation", ITelemetryClient_1.ErrorLevel.Warning, error_7.ErrorType);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalGetMsaTicketForAdditionalScope, ITelemetryClient_1.EventAction.ActionCompleted);
            throw error_7;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  State.prototype.getMaxUsers = function () {
    this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalGetMaxUsers);
    return this.users.maxUsers;
  };

  State.prototype.signOutUserIsPresent = function () {
    this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalSignOutUserIsPresent);
    return this.platform.settings.signOutUserSupported;
  };

  State.prototype.signOutUser = function (oldUser) {
    return __awaiter(this, void 0, void 0, function () {
      var operation, user;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.platform.settings.signOutUserSupported) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.NotSupported, "Sign out is not supported on this platform");
            }

            if (!oldUser) {
              throw new XalError_1.XalError(XalErrorType_1.XalErrorType.InvalidArgument, "Invalid user");
            }

            this.telemetryClient.instrumentApiCalled(ITelemetryClient_1.Area.XalSignOutUser, oldUser);
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalSignOutUser, ITelemetryClient_1.EventAction.ActionStarted, oldUser);
            operation = new SignOutUser_1.SignOutUser(this.telemetryClient, this.platform, this.users, oldUser);
            this.queue.queueOperation(operation);
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            user = _a.sent();
            this.telemetryClient.instrumentAppAction(ITelemetryClient_1.Area.XalSignOutUser, ITelemetryClient_1.EventAction.ActionCompleted, user);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return State;
}();

exports.State = State;