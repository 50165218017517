import * as React from "react";
import { Row, Select, Input, Popover, Avatar, Button, Modal, List, Col } from "antd";
import { CoreModQContainer, IClubModerationQueueResolver, IClubModQueueHandlers, ActivityType } from "@xbox/social-views";
import { ClubHub, ActivityFeed, edsImageResizer, EdsImageSize, Chat, ContentLocatorType, FeedCommentItem } from "@xbox/social-core";

import { FeedViewType } from "../../components/infiniteScrollers/base/InfiniteScrollTypes";
import * as ClubModQStyles from "./ClubModQContainer.Styles";
import * as GlobalStyles from "../Styles";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { ChatItem } from "../../components/activityItems";
import { Localize, LocalizeHTML } from "../../common/translation/Localize";
import { GenericLoading, GenericEmptyState } from "../../common/GenericElements";
import { Divider } from "../../common/generic/Divider";
import { FeedItemSelector } from "../../components/feeditems/FeedItemSelector";
import CommentItem from "../../components/activityItems/CommentItem";
import { ThemedSelect } from "../../common/Generic.Styles";

import ItemType = ActivityFeed.ActivityItemTypes;
import Util from "../../common/Util";

const confirm = Modal.confirm;
const Search = Input.Search;
const Option = Select.Option;

const EmptyState = () => {
    const gutters = { xs: 8, sm: 16, md: 24 };
    return (
        <GenericEmptyState
            message={Localize("ClubModQ.errorState")}
            subTitle={Localize("ClubModQ.subTitle")}
            gutters={gutters}
        />
    );
};

function ModItemPanel(props: any) {
    let content: any;
    let count = 0;
    if (props.items && props.items.length) {
        count = props.items.length;

        content = (
            <List
                itemLayout="vertical"
                size="large"
                pagination={{ pageSize: 8 }}
                dataSource={props.items}
                renderItem={(item: ClubHub.ClubModerationItem) => {
                    let feedItem = getModerationItem(item, props.handlers, props.moderators);
                    return (
                        <List.Item key={item.contentId}>
                            {feedItem}
                        </List.Item>
                    );
                }}
            />
        );
    } else {
        content = EmptyState();
    }

    function ignoreAll() {
        confirm({
            title: Localize("ClubModQ.ignoreConfirmTitle"),
            content: Localize("ClubModQ.ignoreAllConfirmText"),
            okText: Localize("generic.okay"),
            okType: "danger",
            cancelText: Localize("generic.cancel"),
            onOk() {
                props.handlers.ignoreAll();
            }
        });
    }

    return (
        <GlobalStyles.ContentContainer>
            <GlobalStyles.ContentTitle>
                {Localize("ClubModQ.modQTitle", { count: count })}
            </GlobalStyles.ContentTitle>
            <ClubModQStyles.Block>
                <Row>
                    <Col xs={24} md={8}>
                        <Search
                            placeholder={Localize("ClubModQ.searchInputPlaceholder")}
                            onSearch={value => props.handlers.search(value)}
                            style={{ width: 300, maxWidth: "95%", marginBottom: 10 }}
                            aria-label="searchGamerTag"
                        />
                    </Col>
                    <Col xs={24} md={8} aria-labelledby="filterish">
                        <ThemedSelect
                            aria-label={Localize("clubMenu.modq") + " " + Localize("generic.filter")}
                            style={{ width: 300, maxWidth: "95%", marginBottom: 10 }}
                            placeholder={Localize("generic.filter")}
                            optionFilterProp="children"
                            onChange={value => props.handlers.filter(value)}
                            aria-expanded="false"
                        >
                            <Option aria-labelledby="all" value="All">{Localize("ClubModQ.filterItemsPlaceholder")}</Option>
                            <Option aria-labelledby="feedPosts" value="ActivityFeedItem">{Localize("ClubModQ.filterFeedItems")}</Option>
                            <Option aria-labelledby="comments" value="Comment">{Localize("ClubModQ.filterCommentItems")}</Option>
                            <Option aria-labelledby="chat" value="Chat">{Localize("ClubModQ.filterChatItems")}</Option>
                        </ThemedSelect>
                    </Col>
                    <Col xs={24} md={8}>
                        <Button
                            onClick={ignoreAll}
                            style={{ display: props.items && props.items.length ? "inline" : "none" }}
                            type="danger"
                        >
                            {Localize("ClubModQ.reportItemActionIgnoreAllLabel")}
                        </Button>
                    </Col>
                </Row>
            </ClubModQStyles.Block>

            <Divider />
            {content}
        </GlobalStyles.ContentContainer>
    );
}

function ModItemHolder(props: any) {
    const gutters = { xs: 8, sm: 16, md: 24 };
    return (
        <Row gutter={gutters} {...props} />
    );
}

function getUserSummary(report: ClubHub.Report): JSX.Element | null {
    return (
        <ClubModQStyles.UserSummaryListItem key={0}>
            <Avatar src={edsImageResizer(Util.ensureSSLImage(report.reportingUser.displayPicRaw), EdsImageSize._100x100)} style={{ float: "left" }} />
            <ClubModQStyles.GamertagLabel>{report.reportingUser.gamertag}</ClubModQStyles.GamertagLabel>
            <ClubModQStyles.ReportReason>{report.textReason ? ": " + report.textReason : ""}</ClubModQStyles.ReportReason>
        </ClubModQStyles.UserSummaryListItem>
    );
}

function getReporters(item: ClubHub.ClubModerationItem): JSX.Element | null {
    const content = (
        <ClubModQStyles.UserSummaryList>
            {item.reports.map(report => getUserSummary(report))}
        </ClubModQStyles.UserSummaryList>
    );

    return content;
}

function getReportHistory(item: ClubHub.ClubModerationItem): JSX.Element | null {
    const content = (<div>{Localize("ClubModQ.noPreviousTransgressions")}</div>);

    return content;
}

function getModerationItem(item: ClubHub.ClubModerationItem, handlers: IClubModQueueHandlers, moderators: string[]): JSX.Element | null {
    let reportsText;
    let socialItem: JSX.Element;
    let author: string;
    let authorXuid: string;
    let postType: any;

    if (!item || !item.item) {
        socialItem = (
            <ClubModQStyles.ActivityItemErrorContainer>
                {Localize("notifications.ModQReports.errorLoadingFeedItemContent")}
            </ClubModQStyles.ActivityItemErrorContainer>
        );
    } else {
        switch (item.contentType) {
            case ActivityType.Chat:
                let chatItem: Chat.Message = item.item;
                author = chatItem.sender.gamertag;
                authorXuid = chatItem.sender.xuid;
                postType = Localize("ClubModQ.chatItemType");

                socialItem = (
                    <div>
                        <ChatItem item={chatItem} key={chatItem.messageId} />
                    </div>
                );
                break;
            case ActivityType.Comment:
                let commentItem: FeedCommentItem = item.item;
                author = commentItem.author.gamertag;
                authorXuid = commentItem.author.xuid;
                postType = Localize("ClubModQ.commentItemType");

                socialItem = (
                    <div style={{ padding: 25 }}>
                        <CommentItem style={{ padding: 0 }} item={commentItem} key={item.contentId} showAdminAction={false} showSocialActions={false} />
                    </div>
                );
                break;
            case ActivityType.FeedItem:
                let feedItem: ActivityFeed.FeedItem;
                feedItem = { ...item.item.itemInfo };
                author = feedItem.authorInfo.gamertag;
                authorXuid = feedItem.authorInfo.xuid;
                postType = Localize("ClubModQ.feedItemType");

                if (feedItem.activityItemType === ItemType.GameDVR || feedItem.activityItemType === ItemType.Screenshot) {
                    let locators: any[] = item.item.gameMediaContentLocators;
                    feedItem.gameMediaContentLocators = item.item.gameMediaContentLocators;
                    // Room for generic fallback video in uri field if needed later
                    let downloadLocator = locators.find(dl => dl.locatorType === ContentLocatorType.Download) || {"uri" : ""};
                    feedItem.downloadUri = downloadLocator["uri"];

                    // Room for generic fallback thumbnail in uri field if needed
                    let thumbnailLocator = locators.find(dl => dl.locatorType === ContentLocatorType.ThumbLarge) || {"uri" : "/video-thumbnail-unavailable.png"};
                    feedItem.clipThumbnail = thumbnailLocator["uri"];

                } else if (feedItem.activityItemType === ItemType.UserPost) {
                    feedItem.postType = item.item.postType;
                    feedItem.postDetails = item.item.postDetails;
                } else if (feedItem.activityItemType === ItemType.Ballot) {
                    feedItem.pollData = {
                        pollQuestion: item.item.pollQuestion,
                        startTime: item.item.startTime,
                        endTime: item.item.endTime,
                        canVote: item.item.canVote,
                        choices: item.item.choices,
                        pollId: item.item.pollId,
                        myChoice: item.item.myChoice,
                        visible: item.item.visible
                    };
                }

                socialItem = (
                    <div>
                        {getFeedItemType(feedItem, null)}
                    </div>
                );
                break;
            default:
                author = "";
                socialItem = (<div/>);
                break;
        }

        const first = item.reports[0].reportingUser.gamertag;
        const culprit = author;
        const post = postType;

        if (item.reportCount === 1) {
            reportsText = <LocalizeHTML id="ClubModQ.singleReporterReportHeader" options={{ first: first, culprit: culprit, post: post }} />;
        } else if (item.reportCount === 2) {
            const second = item.reports[1].reportingUser.gamertag;
            reportsText = <LocalizeHTML id="ClubModQ.doubleReporterReportHeader" options={{ first: first, second: second, culprit: culprit, post: post }} />;
        } else {
            const second = item.reports[1].reportingUser.gamertag;
            const count = item.reports.length - 2;
            reportsText = <LocalizeHTML id="ClubModQ.multipleReporterReportHeader" options={{ first: first, second: second, count: count, culprit: culprit, post: post }} />;
        }
    }

    function handleAction(eventHandler: IClubModQueueHandlers, key: string, actionItem: ClubHub.ClubModerationItem) {
        switch (key) {
            case "remove":
                confirm({
                    title: Localize("ClubModQ.deleteConfirmTitle"),
                    content: Localize("ClubModQ.deleteConfirmText"),
                    okText: Localize("generic.okay"),
                    okType: "danger",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        handlers.modActionSelected("remove", actionItem);
                    }
                });
                break;
            case "ban":
                confirm({
                    title: <LocalizeHTML id="ClubModQ.banUserConfirmTitle" options={{ culprit: author }} />,
                    content: <LocalizeHTML id="ClubModQ.banUserConfirmText" options={{ culprit: author }} />,
                    okText: Localize("generic.okay"),
                    okType: "danger",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        // pass the key to the modActionSelected method
                        handlers.modActionSelected(key, actionItem);
                    }
                });
                break;
            case "ignore":
                // pass the key to the modActionSelected method
                handlers.modActionSelected(key, actionItem);
                break;
            case "removeandban":
                confirm({
                    title: <LocalizeHTML id="ClubModQ.removeAndBanConfirmTitle" options={{ culprit: author }} />,
                    content: <LocalizeHTML id="ClubModQ.removeAndBanConfirmText" options={{ culprit: author }} />,
                    okText: Localize("generic.okay"),
                    okType: "danger",
                    cancelText: Localize("generic.cancel"),
                    onOk() {
                        // pass the key to the modActionSelected method
                        handlers.modActionSelected(key, actionItem);
                    }
                });
                break;
            default:
                break;
        }
    }

    function actionsMenu(actionItem: ClubHub.ClubModerationItem): JSX.Element | null {
        return (
            <div>
                <p><ClubModQStyles.ReportActionButton key="remove" onClick={() => handleAction(handlers, "remove", actionItem)}>
                    {Localize("ClubModQ.reportItemActionRemoveLabel")}
                </ClubModQStyles.ReportActionButton></p>
                <p><ClubModQStyles.ReportActionButton key="ignore" onClick={() => handleAction(handlers, "ignore", actionItem)}>
                    {Localize("ClubModQ.reportItemActionIgnoreLabel")}
                </ClubModQStyles.ReportActionButton></p>
                {banUserActions(actionItem)}
            </div>
        );
    }

    function banUserActions(actionItem: ClubHub.ClubModerationItem): JSX.Element | null {
        if (!authorXuid || moderators.some(x => x === authorXuid)) {
            return null;
        }

        return (
            <div>
                <p><ClubModQStyles.ReportActionButton key="ban" onClick={() => handleAction(handlers, "ban", actionItem)}>
                    <LocalizeHTML id="ClubModQ.reportItemActionBanLabel" options={{ culprit: author }} />
                </ClubModQStyles.ReportActionButton></p>
                <p><ClubModQStyles.ReportActionButton key="removeandban" onClick={() => handleAction(handlers, "removeandban", actionItem)}>
                    <LocalizeHTML id="ClubModQ.reportItemActionRemoveAndBanLabel" options={{ culprit: author }} />
                </ClubModQStyles.ReportActionButton></p>
            </div>
        );
    }

    return (
        <ClubModQStyles.ReportItem>
            <ClubModQStyles.ReportTitle>{reportsText}</ClubModQStyles.ReportTitle>
            <ClubModQStyles.SocialItemContainer>
                {socialItem}
            </ClubModQStyles.SocialItemContainer>
            <ClubModQStyles.ReportActionsContainer>
                <ClubModQStyles.PopoverContainer>
                    <Popover
                        content={getReporters(item)}
                        title={Localize("ClubModQ.showAllreportsTitle", { count: item.reports.length })}
                        trigger="click"
                    >
                        <ClubModQStyles.ReportActionButton>
                            {Localize("ClubModQ.showAllreports")}
                        </ClubModQStyles.ReportActionButton>
                    </Popover>
                </ClubModQStyles.PopoverContainer>

                <ClubModQStyles.PopoverContainer>
                    <Popover
                        content={getReportHistory(item)}
                        title={Localize("ClubModQ.userReportHistory", { count: item.reports.length })}
                        trigger="click"
                    >
                        <ClubModQStyles.ReportActionButton>
                            {Localize("ClubModQ.userReportHistory")}
                        </ClubModQStyles.ReportActionButton>
                    </Popover>
                </ClubModQStyles.PopoverContainer>

                {actionsMenu(item)}
            </ClubModQStyles.ReportActionsContainer>
        </ClubModQStyles.ReportItem>
    );
}

function getFeedItemType(item: ActivityFeed.FeedItem, handler: any): JSX.Element | null {
    if (!item) {
        return null;
    }

    return (
        <FeedItemSelector
            key={item.feedItemId}
            item={item}
            showSocialActions={false}
            showAdminAction={false}
            hideBorders={true}
            enableAdminView={true}
            viewType={FeedViewType.FullView}
        />
    );
}

function getClubModQPanel(reportedItems: ClubHub.ClubModerationItem[], handlers: any): JSX.Element | null {
    return <GlobalStyles.ContentContainer />;
}

const ClubModQPanelResolver: IClubModerationQueueResolver = {
    getClubModerationQueuePanel: getClubModQPanel,
    getFeedItemType: getFeedItemType,
    getModerationItem: getModerationItem,
};

export default withClubSelectionContext(CoreModQContainer(ModItemPanel, ModItemHolder, GenericLoading, ClubModQPanelResolver));
