"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.analyticsDatePickerReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var ImmutableObj = require("seamless-immutable");

var AnalyticsActions_1 = require("../../actions/AnalyticsActions"); // reducer


var INITIAL_STATE = ImmutableObj({});
exports.analyticsDatePickerReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(AnalyticsActions_1.AnalyticsActions.applyMonth, function (state, action) {
  return state.setIn(["month"], action.payload);
}).build();