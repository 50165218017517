var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { clubAnalyticsRedux, feedViewerRedux, AnalyticsActions, PeopleActions } from "@xbox/social-redux";
import { FeedItemUtils } from "../../Utils";

function coreAnalyticsContainer(analyticsResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.handleLikeClick = function (item, hasLiked) {};

        _this.ExportAnalyticsReport = function () {
          if (_this.exportLink) {
            _this.exportLink.click();
          } else {
            var payload = {
              clubId: _this.props.clubItem.details.id,
              month: _this.props.month
            };

            _this.props.fetchCSVAnalytics(payload);
          }
        };

        _this.OnMonthChanged = function (month) {
          if (_this.exportLink) {
            window.URL.revokeObjectURL(_this.exportLink.getAttribute("href"));
            document.body.removeChild(_this.exportLink);
            _this.exportLink = undefined;
          }

          _this.props.applyMonth(month);
        };

        _this.navigateBackToPage = function (returnPage) {
          _this.props.navigateBackToPage(_this.props.clubItem.details.id, returnPage);
        };

        _this.navigateCommentsPage = function (item, returnPage) {
          _this.props.feedItemSelected(item);

          _this.props.navigateViewer(_this.props.clubItem.details.id, returnPage, FeedItemUtils.parseFeedItemId(item.itemRoot));
        };

        return _this;
      }

      class_1.prototype.requestAnalytics = function (payload) {
        this.props.fetchFeedAnalytics(payload);
        this.props.fetchAdminAnalytics(payload);
      };

      class_1.prototype.componentDidMount = function () {
        this.requestAnalytics({
          clubId: this.props.clubItem.details.id,
          month: this.props.month
        });
      };

      class_1.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;

        if (nextProps.clubItem && nextProps.clubItem.details.id !== this.props.clubItem.details.id || nextProps.month !== this.props.month) {
          this.props.clearEngagers();
          this.requestAnalytics({
            clubId: nextProps.clubItem.details.id,
            month: nextProps.month
          });
        }

        if (nextProps.analytics.feedEngagementData !== this.props.analytics.feedEngagementData && nextProps.analytics.feedEngagementData) {
          if (nextProps.analytics.feedEngagementData.topPosts && this.props.analytics.feedEngagementData && nextProps.analytics.feedEngagementData.topPosts !== this.props.analytics.feedEngagementData.topPosts || !this.props.analytics.feedEngagementData && nextProps.analytics.feedEngagementData.topPosts) {
            var itemsId = nextProps.analytics.feedEngagementData.topPosts.map(function (i) {
              var index = i.postId.indexOf("(");
              return "userposts.xboxlive.com/users/xuid(" + i.postId.substring(0, index) + ")/posts/" + i.postId + "/timelines/Club/" + _this.props.clubItem.details.id;
            });
            var request = {
              itemsId: itemsId
            };
            this.props.handleBatchItemsHydration(request);
          } else {
            this.props.resetFeedItems();
          }
        }

        if (nextProps.analytics.adminActionsData !== this.props.analytics.adminActionsData && nextProps.analytics.adminActionsData) {
          if (nextProps.analytics.adminActionsData.topEngagers && this.props.analytics.adminActionsData && nextProps.analytics.adminActionsData.topEngagers !== this.props.analytics.adminActionsData.topEngagers || !this.props.analytics.adminActionsData && nextProps.analytics.adminActionsData.topEngagers) {
            // [TODO]: after people hub service updates, we can get all xuids in one service call
            // then remove this forEach
            nextProps.analytics.adminActionsData.topEngagers.slice(0, 5).forEach(function (topEngager) {
              return _this.props.fetchPeopleByGamertag(topEngager.gamertag);
            });
          }
        }

        if (nextProps.analytics.csvAnalyticsData && nextProps.analytics.csvAnalyticsData !== this.props.analytics.csvAnalyticsData) {
          var csvData = nextProps.analytics.csvAnalyticsData;
          var formattedCSV = "";

          if (csvData.feedData) {
            if (csvData.adminData) {
              formattedCSV = csvData.feedData + ",\n\n" + csvData.adminData;
            } else {
              formattedCSV = csvData.feedData;
            }
          } else {
            formattedCSV = csvData.adminData;
          }

          var blob = new Blob([formattedCSV], {
            type: "text/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.setAttribute("id", "exportLink");
          link.setAttribute("href", url);
          link.setAttribute("download", "myReport.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          this.exportLink = link;
          link.click();
        }
      };

      class_1.prototype.componentWillUnmount = function () {
        this.props.resetAnalytics();
        this.props.resetFeedItems();
        this.props.clearEngagers();
      };

      class_1.prototype.render = function () {
        var handlers = {
          OnMonthChanged: this.OnMonthChanged,
          ExportAnalyticsReport: this.ExportAnalyticsReport
        };
        var socialActions = {
          like: this.handleLikeClick
        };
        return analyticsResolver.getAnalyticsContainer(this.props.analytics, handlers, socialActions, this.props.month, this.props.batchFeedItems, this.props.engagers, this.props.clubItem.details.id, this.navigateCommentsPage, this.navigateBackToPage);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state) {
  var analyticsState = {
    fetchFeedAnalyticsInProgress: state.clubs.clubAnalytics.fetchFeedAnalyticsInProgress,
    fetchAdminAnalyticsInProgress: state.clubs.clubAnalytics.fetchAdminAnalyticsInProgress,
    feedEngagementData: state.clubs.clubAnalytics.feedEngagementData,
    adminActionsData: state.clubs.clubAnalytics.adminActionsData,
    fetchCSVAnalyticsInProgress: state.clubs.clubAnalytics.fetchCSVAnalyticsInProgress,
    csvAnalyticsData: state.clubs.clubAnalytics.csvAnalyticsData
  };
  return {
    clubItem: state.clubs.clubDetails.club,
    analytics: analyticsState,
    month: state.datepicker.month,
    batchFeedItems: feedViewerRedux.selectors.getFeedItemsHydrationState(state.feedViewer.feedItems),
    feedItem: state.feedViewer.activityFeed.selectedFeedItem,
    engagers: state.people.engagers.slice(0, 5)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    navigateBackToPage: function navigateBackToPage(clubId, targetPage) {
      return dispatch(push("/app/" + clubId + "/manage/analytics/" + targetPage));
    },
    navigateViewer: function navigateViewer(clubId, returnPage, formattedId) {
      return dispatch(push("/app/" + clubId + "/manage/analytics/" + returnPage + "/" + formattedId));
    },
    fetchFeedAnalytics: function fetchFeedAnalytics(payload) {
      return dispatch(clubAnalyticsRedux.actions.fetchFeedAnalytics(payload));
    },
    fetchAdminAnalytics: function fetchAdminAnalytics(payload) {
      return dispatch(clubAnalyticsRedux.actions.fetchAdminAnalytics(payload));
    },
    handleBatchItemsHydration: function handleBatchItemsHydration(payload) {
      return dispatch(feedViewerRedux.actions.hydrateFeedItems(payload));
    },
    applyMonth: function applyMonth(query) {
      return dispatch(AnalyticsActions.applyMonth(query));
    },
    loadComments: function loadComments(payload) {
      return dispatch(feedViewerRedux.actions.fetchFeedComments(payload));
    },
    feedItemSelected: function feedItemSelected(feedItem) {
      return dispatch(feedViewerRedux.actions.feedItemSelected(feedItem));
    },
    fetchCSVAnalytics: function fetchCSVAnalytics(payload) {
      return dispatch(clubAnalyticsRedux.actions.fetchCSVAnalytics(payload));
    },
    resetAnalytics: function resetAnalytics() {
      return dispatch(clubAnalyticsRedux.actions.resetAnalytics());
    },
    resetFeedItems: function resetFeedItems() {
      return dispatch(feedViewerRedux.actions.resetFeedItems());
    },
    fetchPeopleByGamertag: function fetchPeopleByGamertag(gamertag) {
      return dispatch(PeopleActions.fetchPeopleByGamertag(gamertag));
    },
    clearEngagers: function clearEngagers() {
      return dispatch(PeopleActions.clearEngagers());
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreAnalyticsContainer(analyticsResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreAnalyticsContainer(analyticsResolver));
}