"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagsReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var TagPickerActions_1 = require("../../../actions/TagPickerActions");

var INITIAL_STATE = Immutable({
  systemTags: [],
  combinedTagsList: []
});
exports.tagsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(TagPickerActions_1.TagPickerActions.getTagsCompleted, function (state, action) {
  var combinedTags = [];
  action.payload.forEach(function (element) {
    combinedTags = combinedTags.concat(element.tags);
  });
  return state.merge({
    systemTags: action.payload,
    combinedTagsList: combinedTags
  });
}).build();