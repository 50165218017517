"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.experimentsReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ExperimentsActions_1 = require("../actions/ExperimentsActions");

var INITIAL_STATE = Immutable({
  experiments: []
});
exports.experimentsReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ExperimentsActions_1.ExperimentsActions.fetchExperimentsCompleted, function (state, action) {
  return state.merge({
    experiments: action.payload.experiments
  });
}).build();