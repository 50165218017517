import styled from "styled-components";
import { List, Input, Button } from "antd";
import { ImageFade } from "../../styles/ImageStyles";
import { media } from "../../common/Generic.SnapPoints";
import { InlineContent } from "../../common/Generic.Styles";

const Search = Input.Search;

export const Avatar = styled(ImageFade)`
    padding: .25em;
    margin-right: .5em;
    /* due to display: inline NO-EFFECT from width, height, float, margin-top, margin-bottom */
    display: inline;
    max-width: 100px;
    border-radius: 50px;
    ${media.menuOpenSmall`max-width: 75px;`}
`;

export const FilterLabel = styled.div`
    width: 50%;
    display: block;
    font-size: 1em;
    float: left;
`;

export const FilterTitle = styled.p`
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
    float: left;
    width: 100%;
`;

export const RequestTitle = styled.p`
    font-size: 1.125em;
    margin-bottom: .5em;
`;

export const RequestContentContainer = styled.div`
    float: left;
    width: 100%;
    max-width: 80%;
    ${media.menuOpenSmall`max-width: 70%;`}
    ${media.small`max-width: 80%;`}
    ${media.xSmall`max-width: 70%;`}
`;

export const RequestActionsContainer = styled.div`
    float: right;
`;

export const ItemBlock = styled.div`
    padding-left: .5em;
`;

export const Block = styled.div`
    margin-right: 1.25em;
    margin-bottom: .5em;
    width: 100%;
`;

export const BodyText = styled.span`
    font-size: 1em;
    font-weight: bold;
`;

export const BodySubText = styled.span`
    font-weight: 300;
    color: ${props => props.theme.foregroundSecondary};
`;

const BaseLabel = styled.span`
    margin-right: .25em;
    font-size: .75em;
    padding-left: .5em;
    padding-right: .5em;
    padding-bottom: .2em;
    border-radius: .25em;
    margin: 0;
    padding-top: .2em;
    line-height: 2.5em;
    display: inline;
`;

export const FriendLabel = BaseLabel.extend`
    background: ${props => props.theme.friendLabelBckg};
    color: ${props => props.theme.blackText};
`;

export const FavoriteLabel = BaseLabel.extend`
    background: ${props => props.theme.favoriteLabelBckg};
    color: ${props => props.theme.blackText};
`;

export const FollowsYouLabel = BaseLabel.extend`
    background: ${props => props.theme.followsLabelBckg};
    color: ${props => props.theme.blackText};
`;

export const RequestsList = styled(List)`
    margin-top: .5em;
    width: 100%;
`;

export const GoldIcon = styled.span`
    text-transform: uppercase;
    cursor: default;
    font-size: .7em;
    width: 50px;
    text-align: center;
    margin-top: .2em;
    font-weight: 400;
    padding-left: .5em;
    padding-right: .5em;
    padding-top: .15em;
    padding-bottom: .15em;
    color: ${props => props.theme.foregroundMain};
    border: 1px solid ${props => props.theme.borderMain};
`;

export const HorizontalSeparator = styled.span`
    width: 1em;
    display: inline-block;
`;

export const FilterList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding-left: 0;
`;

export const FilterListItem = styled.li`
    border-bottom: 1px solid ${props => props.theme.borderSecondary};
    padding-top: .5em;
    margin-bottom: .25em;
`;

export const GamertagSearch = styled(Search)`
    width: 100%;
    margin-right: 10px;
`;

export const PlayedTitleImage = styled(ImageFade)`
    width: 2.25em;
    height: 2.25em;
    margin-right: .5em;
`;

export const SearchBarButton = styled(Button as any)`
    margin-bottom: .5em;
    margin-right: .25em;
    margin-top: .5em;
`;

export const AdminInlineBlock = styled(InlineContent)`
    width: 100%;
    max-width: 300px;
    margin-right: 10px;
    margin-bottom: 10px;
`;
