import * as React from "react";
import { Modal } from "antd";
import { Cookies } from "react-cookie";
import * as ls from "./ShellLayout.Styles";
import { LayoutContext } from "./LayoutContext";
import { withContext } from "./ContextUtils";
import { MenuButton } from "../Styles";
import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import { ThemeType } from "@xbox/social-views";
import i18nInstance from "../../i18n.config";
import UserSettingsModal from "../usersettings/UserSettingsModal";
import { Localize } from "../../common/translation/Localize";
import { SetIsEmulatedProdMode, IsEmulatedProdMode, IsProdMode } from "../../common/GenericChecks";
import { AvatarDiv, InfoContainerDiv, GamerTag, Gamerpic } from "../../common/generic/GamerpicListItem.Styles";
import { edsImageResizer, EdsImageSize } from "@xbox/social-core";

const confirmSignout = Modal.confirm;

interface HeaderState {
    showUserSettingsModal: boolean;
    isExpandedHamburger: boolean;
    theme: ThemeType;
    currentLocale: string | null;
    displayMode: string;
}

interface HeaderProps extends LayoutContext {
    userGamerpic: string;
    userGamertag: string;
    cookies: Cookies;
}

class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);

        let cachedTheme = localStorage.getItem("theme");
        let savedTheme = cachedTheme ? ThemeType[cachedTheme] : null;
        let savedDisplayMode = localStorage.getItem("displayMode");

        if (savedTheme == null) {
            savedTheme = ThemeType.DarkTheme;
            localStorage.setItem("theme", ThemeType[savedTheme]);
        }

        if (!savedDisplayMode) {
            savedDisplayMode = "dev";
            localStorage.setItem("displayMode", savedDisplayMode);
        }

        this.state = {
            showUserSettingsModal: false,
            isExpandedHamburger: true,
            theme: savedTheme,
            displayMode: savedDisplayMode,
            currentLocale: localStorage.getItem("userLocale")
        };
    }

    componentDidMount() {
        SetIsEmulatedProdMode(this.state.displayMode === "prod" ? true : false);

        if (this.props.onThemeChange) {
            this.props.onThemeChange(this.state.theme);
        }
        i18nInstance.on("languageChanged", this.loadLanguage);
    }

    componentWillUnmount() {
        i18nInstance.off("languageChanged", this.loadLanguage);
    }

    loadLanguage = () => {
        this.setState({ currentLocale: localStorage.getItem("userLocale") });
    }

    changeLanguage = (locale: string) => {
        localStorage.setItem("userLocale", locale);
        i18nInstance.changeLanguage(locale);
    }

    onToggleClick = () => {
        if (this.props.toggleSlider) {
            this.props.toggleSlider();
        }
        this.setState(prevState => ({
            isExpandedHamburger: !prevState.isExpandedHamburger
          }));
    }

    onToggleMessangerClick = () => {
        //
    }

    openUserSettingsModalOnEnter = (e: any) => {
        if (e.key === "Enter") {
            this.setState({ showUserSettingsModal: true });
        }
    }

    openUserSettingsModal = () => {
            this.setState({ showUserSettingsModal: true });
    }

    closeUserSettingsModal = () => {
        this.setState({ showUserSettingsModal: false });
    }

    changeTheme = (e: any) => {
        if (this.props.onThemeChange) {
            let selectedTheme = this.state.theme;

            switch (ThemeType[e.target.value]) {
                case ThemeType[ThemeType.DarkTheme.toString()]:
                    selectedTheme = ThemeType.DarkTheme;
                    break;
                case ThemeType[ThemeType.LightTheme.toString()]:
                    selectedTheme = ThemeType.LightTheme;
                    break;
                default:
                    break;
            }

            this.props.onThemeChange(selectedTheme);
            this.setState({ theme: selectedTheme });
            localStorage.setItem("theme", ThemeType[selectedTheme]);
        }
    }

    signOutUser = (signOutUser: any) => {
        let cookies = this.props.cookies;
        confirmSignout({
            title: Localize("clubMenu.confirmSignout"),
            okText: Localize("generic.yes"),
            onOk() {
                signOutUser(cookies);
            }
        });
    }

    changeDisplayMode = (e: any) => {
        let selectedDisplayMode = this.state.displayMode;
        switch (e.target.value) {
            case "prod":
                SetIsEmulatedProdMode(true);
                selectedDisplayMode = "prod";
                break;
            case "dev":
            default:
                SetIsEmulatedProdMode(false);
                selectedDisplayMode = "dev";
                break;
        }

        this.setState({ displayMode: selectedDisplayMode });
        localStorage.setItem("displayMode", selectedDisplayMode);
    }

    getImage(imageUrl: string) {
        return <Gamerpic className="Gamerpic" alt={Localize("generic.avatar_gamerpic")} src={edsImageResizer(imageUrl, EdsImageSize._208x208)} />;
    }

    render() {
        return (
            <ls.HeaderDiv>
                <ls.TitleAndMenu>
                    <MenuButton aria-expanded={this.state.isExpandedHamburger} aria-label="menuIcon" onClick={this.onToggleClick}>
                        <Icon type={Glyphs.Menu} />
                    </MenuButton>
                    <ls.TitleDiv>Xbox </ls.TitleDiv>
                    <ls.AlphaDiv>
                        {
                            IsEmulatedProdMode && !IsProdMode()
                                ? <span style={{ color: "red", fontWeight: 500 }}>(PROD)</span>
                                : "(beta)"
                        }
                    </ls.AlphaDiv>
                </ls.TitleAndMenu>

                <ls.AccountDiv className="UsersMainProfile" onKeyDown={this.openUserSettingsModalOnEnter} onClick={this.openUserSettingsModal}>
                    <div style={{ display: "flex", width: "fit-content", maxWidth: "100%" }}>
                        <div style={{ position: "relative" }}>
                            <AvatarDiv>{this.getImage(this.props.userGamerpic)}</AvatarDiv>
                            <ls.SettingsIconDiv>
                                <ls.XboxIcon tabIndex={0} aria-label={Localize("clubMenu.clubsettings")} role="button">{Glyphs.Settings}</ls.XboxIcon>
                            </ls.SettingsIconDiv>
                        </div>
                        <InfoContainerDiv className="Gamerpic-Info" style={{ paddingTop: "5px" }}>
                            <GamerTag>{this.props.userGamertag}</GamerTag>
                        </InfoContainerDiv>
                    </div>
                </ls.AccountDiv>

                <UserSettingsModal
                    showUserSettingsModal={this.state.showUserSettingsModal}
                    closeUserSettingsModal={this.closeUserSettingsModal}
                    changeLanguage={this.changeLanguage}
                    changeTheme={this.changeTheme}
                    signOutUserModal={this.signOutUser}
                    theme={this.state.theme}
                    changeDisplayMode={this.changeDisplayMode}
                    displayMode={this.state.displayMode}
                    currentLocale={this.state.currentLocale}
                />

                <MenuButton tabindex={-1} aria-hidden="true" aria-label="menuIcon" onClick={this.onToggleMessangerClick}>
                    {/* <Icon type={Glyphs.Menu} /> */}
                    {/* once menu will applied tabindex and aria-hidden should be removed */}
                </MenuButton>
            </ls.HeaderDiv>
        );
    }
}

export default withContext(Header);
