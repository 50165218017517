import * as React from "react";
import { Row, List, Modal, Form, Select, Checkbox } from "antd";
import { ClubHub, edsImageResizer, EdsImageSize, TitleHub, Person } from "@xbox/social-core";
import { CoreInviteQContainer, IClubInviteQueueResolver, IClubInviteQueueHandlers, NoneTitleId } from "@xbox/social-views";
import { IClubInviteQueueFilter, IClubInviteQueueTimePlayedFilter } from "@xbox/social-redux";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { Localize, LocalizeHTML } from "../../common/translation/Localize";
import { GenericLoading, GenericEmptyState } from "../../common/GenericElements";
import * as Styles from "./ClubAdmin.Styles";
import * as GlobalStyles from "../Styles";
import Gamerscore from "../../components/userprofile/Gamerscore";
import { ThemedSelect } from "../../common/Generic.Styles";
import ProfileCard from "../../components/profileCard/ProfileCard";

const confirm = Modal.confirm;
const FormItem = Form.Item;
const Option = Select.Option;
const gamerScores: number[] = [
    0,
    100,
    500,
    1000,
    2000,
    5000,
    10000,
    25000,
    50000,
    75000,
    100000,
    200000,
    500000,
    1000000,
    2000000,
];

const hours: number[] = [0, 1, 5, 10, 20, 50, 100, 500, 1000, 2000];

const EmptyState = () => {
    const gutters = { xs: 8, sm: 16, md: 24 };
    return (
        <GenericEmptyState
            message={Localize("ClubInviteQ.emptyState")}
            gutters={gutters}
        />
    );
};

function getPerson(request: ClubHub.ClubInviteRequest, handlers: IClubInviteQueueHandlers): JSX.Element | null {
    let content: JSX.Element;
    let gamertag = request.profile.gamertag;
    let label;
    let realName = request.profile.realName ? "(" + request.profile.realName + ")" : "";
    if (request.actor.xuid !== request.profile.xuid) {
        let actor = request.actor.gamertag;
        label = (
            <Styles.RequestTitle>
                <LocalizeHTML id="ClubInviteQ.recommendedByLabel" options={{ gamertag, realName, actor }} />
            </Styles.RequestTitle>
        );
    } else {
        label = (
            <Styles.RequestTitle>
                <LocalizeHTML id="ClubInviteQ.askedToJoinLabel" options={{ gamertag, realName }} />
            </Styles.RequestTitle>
        );
    }

    let friendship: any = <Styles.HorizontalSeparator />;
    if (request.profile.isFavorite) {
        friendship = <Styles.FavoriteLabel>{Localize("userDetails.favorite")}</Styles.FavoriteLabel>;
    } else if (request.profile.isFollowingCaller) {
        friendship = <Styles.FriendLabel>{Localize("userDetails.friend")}</Styles.FriendLabel>;
    } else if (request.profile.isFollowedByCaller) {
        friendship = <Styles.FollowsYouLabel>{Localize("userDetails.followsYou")}</Styles.FollowsYouLabel>;
    }

    function getProfileCard(displayPicRawUrl: string, person: Person) {
        return (
            <ProfileCard
                person={person}
                key={person.gamertag}
                element={<Styles.Avatar src={edsImageResizer(displayPicRawUrl, EdsImageSize._100x100)} style={{ float: "left" }} />}
            />
        );
    }

    content = (
        <List.Item>
            <Styles.ItemBlock>
                <Row justify="start">
                    <Styles.RequestContentContainer>
                        {getProfileCard(request.profile.displayPicRaw, request.profile)}
                        {label}
                        <p>
                            <Styles.GoldIcon>Gold</Styles.GoldIcon>
                        </p>
                        <Row>
                            {friendship}
                        </Row>
                    </Styles.RequestContentContainer>
                    <Styles.RequestActionsContainer>
                        <Styles.SearchBarButton onClick={() => handlers.invite(request.profile.xuid)}>{Localize("ClubInviteQ.inviteButton")}</Styles.SearchBarButton>
                        <br />
                        <Styles.SearchBarButton onClick={() => handlers.reject(request.profile.xuid)}>{Localize("ClubInviteQ.ignoreButton")}</Styles.SearchBarButton>
                    </Styles.RequestActionsContainer>
                </Row>
            </Styles.ItemBlock>
        </List.Item>
    );

    return content;
}

function getClubInviteQPanel(inviteQ: ClubHub.ClubInviteRequest[], handlers: IClubInviteQueueHandlers, filters: IClubInviteQueueFilter, club: ClubHub.Club): JSX.Element | null {
    let content: any;
    let count = 0;

    if (inviteQ && inviteQ.length) {
        count = inviteQ.length;

        content = (
            <Styles.RequestsList
                itemLayout="vertical"
                size="large"
                pagination={{ pageSize: 15 }}
                dataSource={inviteQ}
                renderItem={(item: unknown, index: number) => {
                    const item2 = item as ClubHub.ClubInviteRequest;
                    return (
                        getPerson(item2, handlers)
                    );
                }}
            />);
    } else {
        content = EmptyState();
    }

    function ignoreAll() {
        confirm({
            title: Localize("ClubInviteQ.ignoreAllConfirmTitle"),
            content: Localize("ClubInviteQ.ignoreAllConfirmText"),
            okText: Localize("generic.okay"),
            okType: "danger",
            cancelText: Localize("generic.cancel"),
            onOk() {
                handlers.rejectAll();
            }
        });
    }

    function inviteAll() {
        confirm({
            title: Localize("ClubInviteQ.inviteAllConfirmTitle"),
            content: Localize("ClubInviteQ.inviteAllConfirmText"),
            okText: Localize("generic.okay"),
            okType: "primary",
            cancelText: Localize("generic.cancel"),
            onOk() {
                handlers.inviteAll();
            }
        });
    }

    let filtersButton = <Styles.SearchBarButton onClick={() => handlers.showFilter(true)}>{Localize("ClubInviteQ.filterButton")}</Styles.SearchBarButton>;
    if (filters && (filters.gamerscore || filters.gold || filters.recommended || filters.reputation || filters.timePlayed)) {
        filtersButton = <Styles.SearchBarButton onClick={() => handlers.showFilter(true)} type="primary">{Localize("ClubInviteQ.filterButton")}</Styles.SearchBarButton>;
    }

    function getNoneTitleItem() {
        return (
            <Option value={NoneTitleId}>
                {Localize("ClubInviteQFilter.noTitleText")}
            </Option>
        );
    }

    function getTitleItem(titleId: string) {
        if (titleId === "0") {
            return null;
        }

        let titleSearch: any = club.details.profile.associatedTitles.value.find(t => t.titleId === titleId);
        if (titleSearch) {
            let title: TitleHub.Title = titleSearch;
            let image = title.displayImage ? title.displayImage : "";
            return (
                <Option value={titleId}>
                    <Styles.PlayedTitleImage src={edsImageResizer(image, EdsImageSize._100x100)} />
                    {title.name}
                </Option>
            );
        }
        return null;
    }

    return (
        <GlobalStyles.ContentContainer>
            <GlobalStyles.ThemedModal
                mask={true}
                maskClosable={true}
                visible={filters ? filters.isVisible : false}
                onCancel={() => handlers.showFilter(false)}
                title={Localize("ClubInviteQFilter.filterTitle")}
                okText={Localize("generic.apply")}
                onOk={() => handlers.showFilter(false)}
            >
                {/* narusina - this modal ideally should be separated to a different file */}
                <Form layout="vertical">
                    <Styles.FilterList>
                        <Styles.FilterListItem>
                            <FormItem>
                                <Styles.FilterTitle>{Localize("ClubInviteQFilter.gamerScoreTitle")}</Styles.FilterTitle>
                                <Styles.FilterLabel>{Localize("ClubInviteQFilter.gamerScoreText")}</Styles.FilterLabel>

                                <ThemedSelect
                                    showSearch={true}
                                    style={{ width: 200 }}
                                    value={filters && filters.gamerscore ? filters.gamerscore : 0}
                                    optionFilterProp="children"
                                    onChange={e => {
                                        let filter: IClubInviteQueueFilter = {
                                            isVisible: filters.isVisible,
                                            gamerscore: Number(e),
                                            gold: filters.gold,
                                            reputation: filters.reputation,
                                            recommended: filters.recommended
                                        };
                                        handlers.filter(filter);
                                    }}
                                    filterOption={(input: any, option: any) => option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {gamerScores.map(g => (<Option value={g}><Gamerscore value={g.toString()}/></Option>))}
                                </ThemedSelect>
                            </FormItem>
                        </Styles.FilterListItem>
                        <Styles.FilterListItem>
                            <FormItem>
                                <Styles.FilterTitle>{Localize("ClubInviteQFilter.goldSubscriptionTitle")}</Styles.FilterTitle>
                                <Styles.FilterLabel>{Localize("ClubInviteQFilter.goldSubscriptionText")}</Styles.FilterLabel>
                                <Styles.HorizontalSeparator />
                                <Styles.HorizontalSeparator />
                                <Checkbox
                                    checked={filters && filters.gold ? filters.gold : false}
                                    aria-label="checkboxGold"
                                    onChange={e => {
                                        let filter: IClubInviteQueueFilter = {
                                            isVisible: filters.isVisible,
                                            gold: e.target.checked,
                                            gamerscore: filters.gamerscore,
                                            reputation: filters.reputation,
                                            recommended: filters.recommended
                                        };
                                        handlers.filter(filter);
                                    }}
                                />
                            </FormItem>
                        </Styles.FilterListItem>
                        <Styles.FilterListItem>
                            <FormItem>
                                <Styles.FilterTitle>{Localize("ClubInviteQFilter.recommendationTitle")}</Styles.FilterTitle>
                                <Styles.FilterLabel>{Localize("ClubInviteQFilter.recommendationText")}</Styles.FilterLabel>
                                <Styles.HorizontalSeparator />
                                <Styles.HorizontalSeparator />
                                <Checkbox
                                    checked={filters && filters.recommended ? filters.recommended : false}
                                    aria-label="checkboxRecommended"
                                    onChange={e => {
                                        let filter: IClubInviteQueueFilter = {
                                            isVisible: filters.isVisible,
                                            gold: filters.gold,
                                            gamerscore: filters.gamerscore,
                                            reputation: filters.reputation,
                                            recommended: e.target.checked
                                        };
                                        handlers.filter(filter);
                                    }}
                                />
                            </FormItem>
                        </Styles.FilterListItem>
                        <Styles.FilterListItem>
                            <FormItem>
                                <Styles.FilterTitle>{Localize("ClubInviteQFilter.reputationTitle")}</Styles.FilterTitle>
                                <Styles.FilterLabel>{Localize("ClubInviteQFilter.reputationText")}</Styles.FilterLabel>
                                <Styles.HorizontalSeparator />
                                <Styles.HorizontalSeparator />
                                <Checkbox
                                    checked={filters && filters.reputation ? filters.reputation : false}
                                    aria-label="checkboxReputation"
                                    onChange={e => {
                                        let filter: IClubInviteQueueFilter = {
                                            isVisible: filters.isVisible,
                                            reputation: e.target.checked,
                                            gold: filters.gold,
                                            gamerscore: filters.gamerscore,
                                            recommended: filters.recommended
                                        };
                                        handlers.filter(filter);
                                    }}
                                />
                            </FormItem>
                        </Styles.FilterListItem>
                        <Styles.FilterListItem>
                            <FormItem>
                                <Styles.FilterTitle>{Localize("ClubInviteQFilter.playedTitle")}</Styles.FilterTitle>

                                <ThemedSelect
                                    showSearch={true}
                                    style={{ width: 200, marginRight: 10 }}
                                    placeholder={Localize("ClubInviteQFilter.playedText")}
                                    value={filters && filters.timePlayed && filters.timePlayed.titleId ? filters.timePlayed.titleId : 0}
                                    optionFilterProp="children"
                                    onChange={e => {
                                        let newTimePlayed: IClubInviteQueueTimePlayedFilter;
                                        if (filters.timePlayed) {
                                            newTimePlayed = {
                                                titleId: String(e),
                                                hours: filters.timePlayed.hours,
                                            };
                                        } else {
                                            newTimePlayed = {
                                                titleId: String(e),
                                                hours: 0,
                                            };
                                        }

                                        let filter: IClubInviteQueueFilter = {
                                            isVisible: filters.isVisible,
                                            gamerscore: filters.gamerscore,
                                            gold: filters.gold,
                                            reputation: filters.reputation,
                                            recommended: filters.recommended,
                                            timePlayed: newTimePlayed
                                        };
                                        handlers.clearHourIfNoTitle(filter);
                                        handlers.getUserStats(filter);
                                        handlers.filter(filter);
                                    }}
                                    filterOption={(input: any, option: any) => option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {getNoneTitleItem()}
                                    {club.details.profile.associatedTitles.value.map(t => getTitleItem(t.titleId))}
                                </ThemedSelect>

                                <ThemedSelect
                                    showSearch={true}
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    disabled={!filters || !filters.timePlayed || !filters.timePlayed.titleId}
                                    value={filters && filters.timePlayed && filters.timePlayed.hours ? filters.timePlayed.hours : 0}
                                    onChange={e => {
                                        if (filters) {
                                            let newTimePlayed: any;
                                            if (filters.timePlayed) {
                                                newTimePlayed = {
                                                    hours: Number(e),
                                                    titleId: filters.timePlayed.titleId
                                                };
                                            } else {
                                                newTimePlayed = filters.timePlayed;
                                            }

                                            let filter: IClubInviteQueueFilter = {
                                                isVisible: filters.isVisible,
                                                gamerscore: filters.gamerscore,
                                                gold: filters.gold,
                                                reputation: filters.reputation,
                                                recommended: filters.recommended,
                                                timePlayed: newTimePlayed
                                            };
                                            handlers.filter(filter);
                                        }
                                    }}
                                    filterOption={(input: any, option: any) => option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {hours.map(h => (<Option value={h}>{Localize("ClubInviteQFilter.playedTimeLabel", { hours: h })}</Option>))}
                                </ThemedSelect>

                            </FormItem>
                        </Styles.FilterListItem>
                    </Styles.FilterList>
                </Form>
            </GlobalStyles.ThemedModal>
            <GlobalStyles.ContentTitle>
                {Localize("ClubInviteQ.inviteQTitle", { count: count })}
            </GlobalStyles.ContentTitle>
            <Styles.Block>
                <Row>
                    <Styles.GamertagSearch
                        style={{ width: "100%", maxWidth: "65%" }}
                        placeholder={Localize("ClubInviteQ.searchLabel")}
                        onSearch={value => handlers.search(value)}
                        aria-label="searchGamerTag"
                    />
                    <Styles.HorizontalSeparator />
                    {filtersButton}
                    <Styles.SearchBarButton onClick={() => inviteAll()}>{Localize("ClubInviteQ.inviteAllButton")}</Styles.SearchBarButton>
                    <Styles.SearchBarButton onClick={() => ignoreAll()}>{Localize("ClubInviteQ.ignoreAllButton")}</Styles.SearchBarButton>
                </Row>
            </Styles.Block>

            <Styles.Block>
                {content}
            </Styles.Block>
        </GlobalStyles.ContentContainer >
    );
}

const ClubInviteQPanelResolver: IClubInviteQueueResolver = {
    getInviteQueuePanel: getClubInviteQPanel
};

export default withClubSelectionContext(CoreInviteQContainer(ClubInviteQPanelResolver, GenericLoading, EmptyState));
