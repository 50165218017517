import * as React from "react";
import * as Styles from "./Styles";
import { Localize } from "../common/translation/Localize";
import FeedbackContainer from "./feedback/FeedbackContainer";

interface TimedFeedbackProps {
    timer: number;
}

interface TimedFeedbackState {
    showLink: boolean;
    showFeedbackModal: boolean;
}

export default class TimeSensitiveFeedbackPrompt extends React.Component<TimedFeedbackProps, TimedFeedbackState> {

    constructor(props: TimedFeedbackProps) {
        super(props);
        this.state = {showLink: false, showFeedbackModal: false};
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showLink: true});
        }, this.props.timer * 1000);
    }

    showFeedbackModal = (show: boolean) => {
        this.setState({ showFeedbackModal: show });
    }

    render() {
        return (
            <div>
                {this.state.showLink && <span>{Localize("feedback.notLoading")} </span>}
                {this.state.showLink && <Styles.PseudoLink onClick={() => this.showFeedbackModal(true)}>{Localize("feedback.clickHere")}</Styles.PseudoLink>}
                <Styles.ModalNoFooter
                    title={Localize("feedback.title")}
                    wrapClassName="vertical-center-modal"
                    visible={this.state.showFeedbackModal}
                    onOk={() => this.showFeedbackModal(false)}
                    okText={Localize("generic.submit")}
                    onCancel={() => this.showFeedbackModal(false)}
                >
                    <FeedbackContainer showFeedbackModal={this.showFeedbackModal} version={`${process.env.REACT_APP_VERSION || 0}`} />
                </Styles.ModalNoFooter>
            </div>
        );
    }
}
