import * as React from "react";
import * as _ from "lodash";
import { EdsImageSize, edsImageResizer, ClubHub, TitleHub } from "@xbox/social-core";
import { UploadMediaItemsPayload, CancellationTokenSource, AllTitlesState, MsStoreGameInfo } from "@xbox/social-redux";
import { Upload, Select } from "antd";

import * as fts from "../FeedTools.Styles";
import { Localize } from "../../../common/translation/Localize";
import { Icon, GenericErrorMessage } from "../../../common/GenericElements";
import { Glyphs } from "../../../common/Generic.Icons";
import {
    FileData,
    readFileAsDataUrl,
    createImageThumbnail,
    createVideoThumbnail,
    XBLMediaChoices
} from "../Utils";
import SearchMsGamesBar from "../../../common/gameselectors/SearchMsGamesBar";
import { GameSelectorFromList }  from "../../../common/gameselectors/GameSelectorFromList";
import { Button } from "../../../common/generic/Button";
import { SingleLinePostTextArea } from "../../PostTextArea";

const Option = Select.Option;

export interface UploadContainerProps {
    uploadMediaItems: (data: UploadMediaItemsPayload, isVideo: boolean) => void;
    updateUploadState: (enableBtn: boolean) => void;
    updateImagePreview: (imagePreview: string) => void;
    getAllTitles?: () => void;
    titleList: AllTitlesState;
    uploadItems: XBLMediaChoices;
    showPreviewBtn?: boolean;
    club?: ClubHub.Club;
    preSelectedGame?: any;
}

interface UploadContainerState {
    mediaTitle: string;
    titleId: string;
    isVideoUpload?: boolean;
    mediaFileList?: object[];
    sortingList: JSX.Element[];
    uploading?: boolean;
    initialData?: FileData;
    fileToUse?: File;
    selectedGame?: MsStoreGameInfo;
}

export class UploadContainer extends React.Component<UploadContainerProps, UploadContainerState> {
    private cancellationSource?: CancellationTokenSource;

    constructor(props: any) {
        super(props);

        this.state = {
            sortingList: [],
            mediaTitle: "",
            titleId: this.props.preSelectedGame ? this.props.preSelectedGame.titleId : "",
            selectedGame: this.props.preSelectedGame ? this.props.preSelectedGame : undefined
        };
    }

    componentWillMount() {
        if (this.props.getAllTitles) {
            const associatedTitles = this.props.club ? this.props.club.details.profile.associatedTitles.value : [];
            if (associatedTitles.length > 0) {
                this.setState({sortingList: _.map(associatedTitles, (item: TitleHub.Title) => this.getGameOption(item.titleId, item.name, item.displayImage)) });
            } else {
                this.props.getAllTitles();
            }
        }
    }

    componentWillUpdate(nextProps: any) {
        if (this.props.getAllTitles) {
            let list = nextProps.titleList as AllTitlesState;
            if (list && list.titles && (list !== this.props.titleList)) {
                this.setState({
                    sortingList: _.map(list.titles.entities.titles, (item: TitleHub.Title) => this.getGameOption(item.titleId, item.name, item.displayImage)),
                });
            }
        }
    }

    resetState = () => {
        this.setState({
            mediaTitle: "",
            titleId: this.props.preSelectedGame ? this.props.preSelectedGame.titleId : "",
            isVideoUpload: undefined,
            mediaFileList: undefined,
            initialData: undefined,
            fileToUse: undefined,
            selectedGame: this.props.preSelectedGame ? this.props.preSelectedGame : undefined
        });
    }

    setPreviewData = (data: FileData) => {
        this.setState({initialData: data});
        const allDataPresent: boolean = data && this.state.titleId && this.state.mediaTitle ? true : false;
        this.props.updateUploadState(allDataPresent);
        this.props.updateImagePreview(data.thumbnailBase64);
    }

    onTitleChange = (e: any) => {
        this.setState({ mediaTitle: e.target.value});
        const allDataPresent: boolean = this.state.initialData && this.state.titleId && e.target.value ? true : false;
        this.props.updateUploadState(allDataPresent);
    }

    getScreenshotThumbnail = (file: File) => {
        readFileAsDataUrl(file)
            .then((dataUrl) => {
                return createImageThumbnail(dataUrl);
            })
            .then((data) => {
                this.setPreviewData(data);
            })
            .catch((err) => {
                console.warn(`Failed to create screenshot thumbnail, err: `, err);
            });
    }

    getVideoThumbnail = (file: File) => {
        readFileAsDataUrl(file)
            .then((dataUrl) => {
                return createVideoThumbnail(dataUrl);
            })
            .then((data) => {
                this.setPreviewData(data);
            })
            .catch((err) => {
                console.warn(`Failed to create videoclip thumbnail, err: `, err);
            });
    }

    /**
     * Make a data URL of an image from an HTML Video. Used to generate clip thumbnails.
     */
    generateImageDataURL = (video: HTMLVideoElement, scaleFactor = 1) => {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = video.videoWidth * scaleFactor;
        canvas.height = video.videoHeight * scaleFactor;
        canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();
    }

    createSmallImage = (fileInfo: any) => {
        const inputFile = fileInfo.file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            let blob;
            if (fileReader.result === null) {
                throw new Error("Error: fileReader.result is null");
            }
            blob = new Blob([fileReader.result], { type: inputFile.type });
            const url = URL.createObjectURL(blob);
            const video = document.createElement("video");
            const snapImage = () => {
                let image = this.generateImageDataURL(video);

                const imageSize = image.length;

                let success = imageSize > 100000;

                // HACK. We were running into an error when uploading thumbnails for certain videos because the
                // thumbnail was larger than the maximum allowable block size for append blobs in Azure Blob Storage
                // (more info here: https://docs.microsoft.com/en-us/azure/storage/blobs/scalability-targets).
                // The number here is the number that was returned to us in the error response from the service.
                // Check to see if the image is larger than that and if it is, scale it down.
                const maxAzureBlockSize = 4194304;
                const imageTooLarge = success && imageSize > maxAzureBlockSize;

                if (imageTooLarge) {
                    const scaleFactor = maxAzureBlockSize / imageSize;
                    image = this.generateImageDataURL(video, scaleFactor);
                    success = imageSize > 10000;
                }

                if (success) {
                    const img = document.createElement("img");
                    img.src = image;
                    this.setState(s => {
                        return Object.assign({}, s, { initialData: { thumbnailBase64: image } });
                    });
                    const thumbTrim = image.slice(image.indexOf(`,`) + 1, image.length);

                    const fileData: FileData = {
                        originalHeight: video.videoHeight,
                        originalWidth: video.videoWidth,
                        thumbnailBase64: image,
                        thumbnailOriginSize: atob(thumbTrim).length,
                        duration: Math.ceil(video.duration)
                    };

                    this.setPreviewData(fileData);
                    URL.revokeObjectURL(url);
                }
                return success;
            };
            const timeupdate = function () {
                if (snapImage()) {
                    video.removeEventListener("timeupdate", timeupdate);
                    video.pause();
                }
            };
            video.addEventListener("loadeddata", function () {
                if (snapImage()) {
                    video.removeEventListener("timeupdate", timeupdate);
                }
            });
            video.addEventListener("timeupdate", timeupdate);
            video.preload = "metadata";
            video.src = url;
            // Load video in Safari / IE11
            video.muted = true;
            // video.playsInline = true;
            video.play();
        };
        fileReader.readAsArrayBuffer(inputFile);
    }

    handleChange = (fileInfo: any) => {
        // Drag'n'Drop upload from antd allows to put more than 1 file, in any case we will use only first one in the queue.
        this.setState({ mediaFileList: fileInfo.fileList, initialData: undefined });
        if (fileInfo.fileList.length === 0) {
            this.props.updateUploadState(false);
            this.props.updateImagePreview("");
        } else {
            let name = fileInfo.fileList[0].name;
            let isVideo = fileInfo.fileList[0].type.startsWith("video/");
            let file = fileInfo.fileList[0].originFileObj;

            this.setState({
                fileToUse: file,
                mediaTitle: name.substr(0, name.lastIndexOf(`.`)),
                isVideoUpload: isVideo,
            });
            if (isVideo) {
                this.createSmallImage(fileInfo);
            } else {
                this.getScreenshotThumbnail(file);
            }
        }
    }

    handleUpload = () => {
        let data = this.state.initialData;
        let file = this.state.fileToUse;
        this.cancellationSource = new CancellationTokenSource;

        if (data && file) {
            let payload: UploadMediaItemsPayload = {
                file: file,
                mediaTitle: this.state.mediaTitle,
                gameId: Number(this.state.titleId),
                cancellationToken: this.cancellationSource.getToken(),
                shouldHydrate: this.state.isVideoUpload,
                ...data
            };
            this.props.uploadMediaItems(payload, this.state.isVideoUpload === true);
        }
    }

    cancelUpload = () => {
        if (this.cancellationSource) {
            this.cancellationSource.cancel();
            this.onDeleteGameItem();
        }
    }

    getGameOption(gameId: string, gameName: string, gameImage?: string) {
        let image = gameImage ? gameImage : "";
        return (
            <Option key={gameId} value={gameId}>
                <fts.PlayedTitleImage src={edsImageResizer(image, EdsImageSize._100x100)} />
                {gameName}
            </Option>
        );
    }

    getUploadElement() {
        let acceptTypes = "image/*, video/*";
        if (this.props.uploadItems === XBLMediaChoices.Screenshots) {
            acceptTypes = "image/*";
        } else if (this.props.uploadItems === XBLMediaChoices.Gameclips) {
            acceptTypes = "video/*";
        }

        const uploadArea = (
            <div>
                {Localize("uploadMedia.dragNDropInfo")}
                <Button className="uploadMedia" style={{width: "200px"}}>
                    {Localize("uploadMedia.selectFile")}
                </Button>
            </div>
        );

        return (
            <Upload
                accept={acceptTypes}
                listType="picture-card"
                beforeUpload={() => { return false; }}
                onChange={this.handleChange}
                showUploadList={false}
                multiple={false}
            >
                {uploadArea}
            </Upload>
        );
    }

    onGameIdChanged = (gameId: string) => {
        this.setState({titleId: gameId});
        const allDataPresent: boolean = this.state.initialData && gameId && this.state.mediaTitle ? true : false;
        this.props.updateUploadState(allDataPresent);
    }

    setSearchedGame = (game?: MsStoreGameInfo) => {
        if (game) {
            this.setState({selectedGame: game, titleId: game.titleId});
            const allDataPresent: boolean = this.state.initialData && game.titleId && this.state.mediaTitle ? true : false;
            this.props.updateUploadState(allDataPresent);
        }
    }

    onDeleteGameItem = () => {
        this.setState({selectedGame: undefined, titleId: ""});
        this.props.updateUploadState(false);
    }

    SearchPreview = () => {
        let gameData = this.props.preSelectedGame ? this.props.preSelectedGame : this.state.selectedGame!;
        let imgUrl = gameData.logoUrl ? gameData.logoUrl : gameData.boxArtUrl ?  gameData.boxArtUrl :  gameData.posterUrl;
        return (
            <div>
                <fts.PlayedTitleImage src={edsImageResizer(imgUrl, EdsImageSize._208x208)} />{gameData!.voiceTitle}
                {!this.props.preSelectedGame && <Button iconOnly={true} onClick={this.onDeleteGameItem} style={{float: "right"}}><Icon type={Glyphs.Delete}/></Button>}
            </div>
        );
    }

    GameSelector = () => {
        if (this.props.preSelectedGame) {
            return this.SearchPreview();
        } else if (this.props.club && this.props.club.details.profile.associatedTitles.value.length > 0) {
            return <GameSelectorFromList onSetGame={this.onGameIdChanged} optionsList={this.state.sortingList}/>;
        } else {
            return  (
                <div>
                    <SearchMsGamesBar onSetGame={this.setSearchedGame}/>
                    {this.state.selectedGame && this.SearchPreview()}
                </div>
            );
        }
    }

    render() {
        let showPreview = this.state.mediaFileList && this.state.mediaFileList.length >= 1;
        if (!showPreview) {
            return this.getUploadElement();
        }

        let thumbnail = this.state.initialData && this.state.initialData.thumbnailBase64 ? this.state.initialData.thumbnailBase64 : "";
        return (
            <fts.FlexRow>
                <fts.ImageUploadPreviewContainer>
                    <fts.RelativeDiv style={{margin: "0 8px"}}>
                        <fts.FitImage
                            src={thumbnail}
                            alt={`thumbnail for upload media item`}
                        />
                        <fts.DeleteImageButton iconOnly={true} onClick={this.resetState}>
                            <Icon type={Glyphs.Delete}/>
                        </fts.DeleteImageButton>
                    </fts.RelativeDiv>
                </fts.ImageUploadPreviewContainer>

                <fts.FlexCol>
                    {Localize("uploadMedia.mediaTitle")}
                    <SingleLinePostTextArea
                        style={{margin: "0px 0px 8px 0px"}}
                        onChange={this.onTitleChange}
                        value={this.state.mediaTitle}
                        placeholder={Localize("uploadMedia.mediaTitlePlaceholder")}
                        aria-label="textArea"
                        maxLength={50}
                    />
                    {Localize("uploadMedia.associatedGame")}
                    <br/>
                    {this.state.selectedGame && !this.state.titleId && <GenericErrorMessage errorMessage={Localize("uploadMedia.noTitleId")} showError={true}/>}
                    {this.GameSelector()}
                </fts.FlexCol>
            </fts.FlexRow>
        );
    }
}
