import * as React from "react";
import * as gs from "./Divider.Styles";

export interface DividerProps {
    style?: React.CSSProperties;
}

export function Divider({ style }: DividerProps) {

    return (
        <gs.DividerBase style={style} />
    );
}
