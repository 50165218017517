"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActivityFeedService = void 0;

var http_1 = require("@xbox/http");

var inversify_1 = require("inversify");

var typings_1 = require("../../typings");

var utils_1 = require("../../utils");

var FeedQueries_1 = require("../../queries/FeedQueries");

var FetchPolicyType;

(function (FetchPolicyType) {
  FetchPolicyType["Network"] = "network-only";
  FetchPolicyType["Cache"] = "cache-first";
})(FetchPolicyType || (FetchPolicyType = {}));

var ActivityFeedService =
/** @class */
function () {
  function ActivityFeedService(xblService, xblGraphQlService) {
    this.xblService = xblService;
    this.xblGraphQlService = xblGraphQlService;
    this.feedFetchManager = {};
    this.mostRecentFeedFetch = "";
  }

  ActivityFeedService.prototype.getMinutesBetween = function (date1, date2) {
    return Math.round((date1 - date2) % 86400000 % 3600000 / 60000);
  };

  ActivityFeedService.prototype.clearMostRecentPolicy = function () {
    delete this.feedFetchManager[this.mostRecentFeedFetch];
  };

  ActivityFeedService.prototype.clearFetchPolicyById = function (marker) {
    delete this.feedFetchManager[marker];
  };

  ActivityFeedService.prototype.getFetchPolicyById = function (marker) {
    var fetchPolicy = FetchPolicyType.Network;

    if (this.feedFetchManager[marker]) {
      if (this.getMinutesBetween(Date.now(), this.feedFetchManager[marker].lastFetchTime) < 10) {
        fetchPolicy = FetchPolicyType.Cache;
      }
    }

    this.feedFetchManager[marker] = {
      lastFetchTime: Date.now()
    };
    this.mostRecentFeedFetch = marker;
    return fetchPolicy;
  };

  ActivityFeedService.prototype.getClubActivityFeeds = function (clubId, numberItems, channelId, cursor, activityTypes, excludeTypes, pinCount, startDate, endDate) {
    return __awaiter(this, void 0, void 0, function () {
      var actvityHeaders, variables, fetchPolicy;
      return __generator(this, function (_a) {
        actvityHeaders = {
          "xbl-experiments": "officialclubs,officialclubswithpresence"
        };
        variables = {
          clubId: clubId,
          count: numberItems,
          after: cursor,
          startDateTime: startDate,
          endDateTime: endDate,
          pinCount: pinCount,
          activityTypes: activityTypes,
          excludeTypes: excludeTypes
        };

        if (channelId) {
          variables["channelId"] = "" + channelId;
        }

        fetchPolicy = this.getFetchPolicyById(channelId ? channelId : clubId);
        return [2
        /*return*/
        , this.xblGraphQlService.query({
          query: FeedQueries_1.ClubActivityFeedQuery,
          variables: variables,
          context: {
            headers: actvityHeaders
          },
          fetchPolicy: fetchPolicy
        })];
      });
    });
  };

  ActivityFeedService.prototype.getMyActivityFeed = function (type, numberItems, cursor, activityTypes, excludeTypes) {
    return __awaiter(this, void 0, void 0, function () {
      var actvityHeaders, variables, fetchPolicy;
      return __generator(this, function (_a) {
        actvityHeaders = {
          "xbl-experiments": "officialclubs,officialclubswithpresence"
        };
        variables = {
          type: type,
          first: numberItems,
          after: cursor,
          activityTypes: activityTypes,
          excludeTypes: excludeTypes
        };
        fetchPolicy = this.getFetchPolicyById(type);
        return [2
        /*return*/
        , this.xblGraphQlService.query({
          query: FeedQueries_1.MyFeedQuery,
          variables: variables,
          context: {
            headers: actvityHeaders
          },
          fetchPolicy: fetchPolicy
        })];
      });
    });
  };
  /*------------------------------------------------------------------ */

  /* FeedItem actions                                                  */

  /*------------------------------------------------------------------ */


  ActivityFeedService.prototype.createPost = function (postData) {
    // to distinguish User/Club post timeline's Type&Owner should be completed correctly
    var createPostHeader = utils_1.GetGenericHeaders(2);
    return this.xblService.sendRequest({
      url: "https://userposts.xboxlive.com/users/me/posts",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(postData),
      headers: createPostHeader
    });
  };

  ActivityFeedService.prototype.createPost_GraphQL = function (postType, timelines, postText, postTypeData, postDate) {
    return __awaiter(this, void 0, void 0, function () {
      var postData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            postData = {
              timelines: timelines,
              postType: postType,
              postText: postText,
              postDate: postDate
            };

            if (postTypeData) {
              if (postData.postType === typings_1.ActivityFeed.PostType.WebLink) {
                postData["linkPostData"] = postTypeData;
              } else {
                postData["postTypeData"] = postTypeData;
              }
            }

            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: FeedQueries_1.CreatePost,
              variables: {
                input: postData
              }
            })];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  ActivityFeedService.prototype.deletePost = function (locator) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: FeedQueries_1.DeletePost,
              variables: {
                postUri: locator
              },
              fetchPolicy: "no-cache"
            })];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  ActivityFeedService.prototype.reportToEnforcement = function (item, userId) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: FeedQueries_1.ReportToEnforcement,
              variables: {
                userId: userId,
                input: item
              },
              fetchPolicy: "no-cache"
            })];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  ActivityFeedService.prototype.reportToClub = function (item, clubId) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: FeedQueries_1.ReportToClubAdmin,
              variables: {
                clubId: clubId,
                input: item
              },
              fetchPolicy: "no-cache"
            })];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  ActivityFeedService.prototype.addPin = function (locatorStr, timelineType, timelineId, channelId) {
    var actvityHeaders = utils_1.GetGenericHeaders(14);
    var locatorData = {
      locator: locatorStr
    };
    var channel = "";

    if (channelId && channelId !== timelineId) {
      channel = "/channels/" + channelId;
    }

    return this.xblService.sendRequest({
      url: "https://avty.xboxlive.com/timelines/" + timelineType + "/" + timelineId + channel + "/pins",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(locatorData),
      headers: actvityHeaders
    });
  };

  ActivityFeedService.prototype.removePin = function (locatorStr, timelineType, timelineId, channelId) {
    var actvityHeaders = utils_1.GetGenericHeaders(14);
    var locatorData = {
      locator: locatorStr
    };
    var channel = "";

    if (channelId && channelId !== timelineId) {
      channel = "/channels/" + channelId;
    }

    return this.xblService.sendRequest({
      url: "https://avty.xboxlive.com/timelines/" + timelineType + "/" + timelineId + channel + "/unpin",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(locatorData),
      headers: actvityHeaders
    });
  };

  ActivityFeedService.prototype.hydrateWebLink = function (weblink) {
    var hydratorHeader = utils_1.GetGenericHeaders(2);
    var linkData = {
      Link: weblink
    };
    return this.xblService.sendRequest({
      url: "https://hydrator.xboxlive.com/preview",
      method: http_1.Http.HttpRequestMethod.Post,
      data: JSON.stringify(linkData),
      headers: hydratorHeader
    });
  };

  ActivityFeedService.prototype.getFeedItemPreview = function (itemId) {
    var actvityHeaders = utils_1.GetGenericHeaders(12);
    return this.xblService.sendRequest({
      url: "https://avty.xboxlive.com/preview",
      method: http_1.Http.HttpRequestMethod.Post,
      headers: actvityHeaders,
      data: JSON.stringify({
        locator: itemId
      })
    });
  };

  ActivityFeedService.prototype.getBatchFeedItemsPreview = function (itemIds) {
    return __awaiter(this, void 0, void 0, function () {
      var actvityHeaders;
      return __generator(this, function (_a) {
        actvityHeaders = {
          "xbl-experiments": "officialclubs,officialclubswithpresence"
        };
        return [2
        /*return*/
        , this.xblGraphQlService.query({
          query: FeedQueries_1.HydrateItemsQuery,
          variables: {
            locators: itemIds
          },
          context: {
            headers: actvityHeaders
          },
          fetchPolicy: "network-only"
        })];
      });
    });
  };

  ActivityFeedService.prototype.validateStrings = function (stringsToVerify) {
    var actvityHeaders = utils_1.GetGenericHeaders(1);
    var data = {
      "stringsToVerify": stringsToVerify
    };
    return this.xblService.sendRequest({
      url: "https://client-strings.xboxlive.com/system/strings/validate",
      method: http_1.Http.HttpRequestMethod.Post,
      headers: actvityHeaders,
      data: JSON.stringify(data)
    });
  };

  ActivityFeedService.prototype.getFeedSettings = function (xuid) {
    return __awaiter(this, void 0, void 0, function () {
      var result, settings;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.query({
              query: FeedQueries_1.FeedSettings,
              variables: {
                userId: xuid
              }
            })];

          case 1:
            result = _a.sent();
            settings = result.data;
            return [2
            /*return*/
            , settings];
        }
      });
    });
  };

  ActivityFeedService.prototype.updateFeedSettings = function (xuid, settings) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.xblGraphQlService.mutate({
              mutation: FeedQueries_1.UpdateFeedSettings,
              variables: {
                userId: xuid,
                settings: settings
              }
            })];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  ActivityFeedService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IGraphQLClient)), __metadata("design:paramtypes", [Object, Object])], ActivityFeedService);
  return ActivityFeedService;
}();

exports.ActivityFeedService = ActivityFeedService;