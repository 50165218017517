import * as React from "react";
import { ClubHub } from "@xbox/social-core";
import { Route, Switch } from "react-router";

import { Icon } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import { Localize } from "../../common/translation/Localize";
import { ClubpicListItem } from "../../common/generic/ClubpicListItem";
import DeepLinkMenu, { MenuItem } from "../clubviewer/DeepLinkTabs";
import i18nInstance from "../../../src/i18n.config";

interface DiscoveryContainerProps {
    invitedClubs?: ClubHub.Club[];
}

interface DiscoveryContainerState {}

export class DiscoveryContainer extends React.Component<DiscoveryContainerProps, DiscoveryContainerState> {
    private tabData: MenuItem[] = [];
    constructor(props: any) {
        super(props);

        this.tabData = [
            {titleKey: "discovery.clubs", link: "/app/discovery/clubs", linkKey: "clubs" },
            {titleKey: "discovery.games", link: "/app/discovery/games", linkKey: "games" },
            {titleKey: "discovery.people", link: "/app/discovery/people", linkKey: "people" },
            {titleKey: "discovery.invites", link: "/app/discovery/invites", linkKey: "invites" }
        ];
    }

    getClubsItems = () => {
        return (
            <div>
                {this.props.invitedClubs && this.props.invitedClubs.map( (item: ClubHub.Club) => {
                    return (
                        <div key={item.details.id}>
                            {item.details.isOfficialClub && <Icon style={{float: "right"}} type={Glyphs.VerifiedGameClub}/>}
                            <ClubpicListItem
                                displayPicUrl={item.details.profile.displayImageUrl.value}
                                displayPicName={item.details.profile.name.value}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div>
                {Localize("clubMenu.home")}
                <DeepLinkMenu items={this.tabData} language={i18nInstance.language} mode="horizontal"/>
                <Switch>
                    <Route path="/app/discovery/clubs">
                        <div>Clubs</div>
                    </Route>
                    <Route path="/app/discovery/games">
                        <div>games</div>
                    </Route>
                    <Route path="/app/discovery/people">
                        <div>people</div>
                    </Route>
                    <Route path="/app/discovery/invites">
                        <div>invites</div>
                    </Route>
                    <Route path="/app/discovery">
                        <div>Clubs</div>
                    </Route>
                </Switch>
            </div>
        );
    }
}
