import * as React from "react";
import { NavLink } from "react-router-dom";
import { Route, Switch } from "react-router";
import { ActivityFeed } from "@xbox/social-core";

import * as style from "./HomeContainer.Styles";
import { Localize } from "../../common/translation/Localize";
import AggregateContainer from "../aggregates/AggregateContainer";
import FeedItemViewer from "../feeditemviewer/FeedItemViewerContainer";

interface HomeContainerProps {
    navigateHomeSubPage: (subpage: string) => void;
}
interface HomeContainerState {}

export default class HomeContainer extends React.Component<HomeContainerProps, HomeContainerState> {
    constructor(props: any) {
        super(props);
    }

    getNavigationItem(stringId: string, innerAppLink: string): JSX.Element {
        return (
            <style.HomeMenuItem>
                <NavLink activeClassName="active" to={`/app/home/${innerAppLink}`}>
                    {Localize(stringId)}
                </NavLink>
             </style.HomeMenuItem>
        );
    }

    getFeedItemViewer = (props: any) => {
        let bbOverride = () => {
            this.props.navigateHomeSubPage(props.match.params.pathname);
        };

        return <FeedItemViewer {...props} backButtonOverride={bbOverride} clubId={null}/>;
    }

    render() {
        return (
            <style.HomeContainer id="MainContentWindow" className="HomeContainer">
                <h1 className="sr-only">{Localize("generic.feedActivity")}</h1>
                <style.HomeMenu className="HomeMenu">
                    {this.getNavigationItem("home.all", ActivityFeed.ActivityFeedTypes.all)}
                    {this.getNavigationItem("home.friends", ActivityFeed.ActivityFeedTypes.friends)}
                    {this.getNavigationItem("home.clubs", ActivityFeed.ActivityFeedTypes.clubs)}
                    {this.getNavigationItem("home.trending", ActivityFeed.ActivityFeedTypes.trending)}
                </style.HomeMenu>
                <Switch>
                    <Route path="/app/home/:pathname/post/:formattedFeedId/:feedItemSuffix" render={(props: any) => this.getFeedItemViewer(props)}/>
                    <Route path="/app/home/:pathname" component={AggregateContainer}/>
                </Switch>
            </style.HomeContainer>
        );
    }
}
