import * as i18n from "i18next";
import * as LanguageDetector from "i18next-browser-languagedetector";
import * as Backend from "i18next-chained-backend";
import * as LocalStorageBackend from "i18next-localstorage-backend";
import * as XHR from "i18next-xhr-backend";
import { reactI18nextModule } from "react-i18next";

enum LangType {
    en = "en-US",
    ru = "ru",
    pseudo = "ps-PS"
}

let backendOptions = [
    // TODO: Add full list of version per languange once we have them
    {expirationTime: 2 * 24 * 60 * 60 * 1000, versions: {en: `${process.env.REACT_APP_VERSION || 0}`}},
    {loadPath: "/locales/{{lng}}/strings.json", queryStringParams: {v: `${process.env.REACT_APP_VERSION || 0}`} },
];

let i18nBackend;
let backends;

// TODO: we will for now only do en-US on server side render (since localStorage doesn't exist)
let initLng: any = "en-US";

// check to see if we are running in server configuration
if (typeof window !== "undefined") {
    i18nBackend = Backend;
    backends = [
        LocalStorageBackend,
        XHR
    ];

    if (process.env.NODE_ENV === "development") {
        backendOptions = [{loadPath: "/locales/{{lng}}/strings.json", queryStringParams: {v: `${process.env.REACT_APP_VERSION || 0}`}}];
        backends = [XHR];
    }

    if (!localStorage.getItem("userLocale")) {
        localStorage.setItem("userLocale", window.navigator.language);
    }

    initLng = localStorage.getItem("userLocale")!;
} else {
    console.warn("code not being run on front-end.");
}

const i18nInstance = i18n.use(i18nBackend)
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        lng: initLng,
        backend: {
            backendOptions: backendOptions,
            backends: backends
        },
        debug: true,
        fallbackLng: LangType.en,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    });
export default i18nInstance;
