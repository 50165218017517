"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestSigner = void 0;

var framework_1 = require("@xbox/framework");

var base64 = require("base64-js");

var RequestSigner =
/** @class */
function () {
  function RequestSigner() {}

  RequestSigner.signRequest = function (ecdsa, signaturePolicy, httpMethod, urlPathAndQuery, headers, body) {
    return __awaiter(this, void 0, void 0, function () {
      var offset, protocolVersion, buffer, dataView, filesystemTime, longView, method, authHeader, bodyBytesToInclude, i, bodyBytesToInclude, bodyToInclude, i, resizedBuffer, signed, signatureBuffer, signatureBufferDV, signatureBufferLongView, tmp, base64Sig;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            offset = 0;
            protocolVersion = signaturePolicy.Version;
            buffer = new ArrayBuffer(signaturePolicy.MaxBodyBytes + 10000);
            dataView = new DataView(buffer);
            dataView.setUint32(offset, protocolVersion, false);
            offset += 4;
            dataView.setUint8(offset++, 0);
            printByteArray(dataView, 0, offset, "protocolVersion:");
            filesystemTime = (Date.now() + 11644473600000) * 10000;

            if (!filesystemTime) {
              throw new Error("Unable to determine accurate time for auth signature");
            }

            longView = new framework_1.LongView(buffer, offset);
            longView.setLong(0, filesystemTime.toString(10));
            offset += 8;
            dataView.setUint8(offset++, 0);
            printByteArray(dataView, 0, offset, "timestamp:");
            method = httpMethod.toString();
            offset = RequestSigner.writeStringToDataView(method, dataView, offset);
            printByteArray(dataView, 0, offset, "method:");
            offset = RequestSigner.writeStringToDataView(urlPathAndQuery, dataView, offset);
            printByteArray(dataView, 0, offset, "url path and query:"); // extra headers from NSAL, if any or null byte

            if (headers) {
              if (Array.isArray(signaturePolicy.ExtraHeaders) && signaturePolicy.ExtraHeaders.length > 0) {
                signaturePolicy.ExtraHeaders.forEach(function (headerName) {
                  if (headers) {
                    var foundHeader = headers.find(function (header) {
                      return header.Name.toLowerCase() === headerName.toLowerCase();
                    });

                    if (foundHeader) {
                      offset = RequestSigner.writeStringToDataView(foundHeader.Value, dataView, offset);
                      printByteArray(dataView, 0, offset, headerName + " header:");
                    }
                  }
                });
              } else {
                authHeader = headers.find(function (header) {
                  return header.Name.toLowerCase() === "authorization";
                });

                if (authHeader) {
                  offset = RequestSigner.writeStringToDataView(authHeader.Value, dataView, offset);
                } else {
                  dataView.setUint8(offset++, 0);
                }

                printByteArray(dataView, 0, offset, "auth header:");
              }
            } else {
              dataView.setUint8(offset++, 0);
              printByteArray(dataView, 0, offset, "auth header:");
            }

            if (typeof body === "string") {
              bodyBytesToInclude = Math.min(signaturePolicy.MaxBodyBytes, body.length);

              for (i = 0; i < bodyBytesToInclude; i++) {
                dataView.setUint8(offset++, body.charCodeAt(i));
              }

              dataView.setUint8(offset++, 0);
              printByteArray(dataView, 0, offset, "body:");
            } else if (body) {
              bodyBytesToInclude = Math.min(signaturePolicy.MaxBodyBytes, body.byteLength);
              bodyToInclude = new DataView(body, 0, bodyBytesToInclude);

              for (i = 0; i < bodyBytesToInclude; i++) {
                dataView.setUint8(offset++, bodyToInclude.getUint8(i));
              }

              dataView.setUint8(offset++, 0);
              printByteArray(dataView, 0, offset, "body:");
            } else {
              dataView.setUint8(offset++, 0);
              printByteArray(dataView, 0, offset, "body:");
            }

            resizedBuffer = buffer.slice(0, offset);
            return [4
            /*yield*/
            , ecdsa.signAndHash(resizedBuffer)];

          case 1:
            signed = _a.sent();
            printByteArray(signed, 0, signed.byteLength, "signed hash:");
            offset = 0;
            signatureBuffer = new ArrayBuffer(4 + 8);
            signatureBufferDV = new DataView(signatureBuffer);
            signatureBufferDV.setUint32(offset, protocolVersion, false);
            offset += 4;
            signatureBufferLongView = new framework_1.LongView(signatureBuffer, offset);
            signatureBufferLongView.setLong(0, filesystemTime.toString(10));
            tmp = new Uint8Array(4 + 8 + signed.byteLength);
            tmp.set(new Uint8Array(signatureBuffer), 0);
            tmp.set(new Uint8Array(signed), 4 + 8);
            printByteArray(tmp, 0, 4 + 8 + signed.byteLength, "signature:");
            base64Sig = base64.fromByteArray(tmp); // console.debug(`signature base64:\n${base64Sig}`);

            return [2
            /*return*/
            , base64Sig];
        }
      });
    });
  };

  RequestSigner.writeStringToDataView = function (data, dataView, offset) {
    for (var i = 0; i < data.length; i++) {
      dataView.setUint8(offset++, data.charCodeAt(i));
    }

    dataView.setUint8(offset++, 0);
    return offset;
  };

  return RequestSigner;
}();

exports.RequestSigner = RequestSigner;
var debugSigning = false;

function printByteArray(array, offset, count, message) {
  if (debugSigning) {
    var dataView = void 0;

    if (array instanceof ArrayBuffer) {
      dataView = new DataView(array, offset);
    } else if (array instanceof DataView) {
      dataView = array;
    }

    var output = (message ? message : "") + "\n[ ";

    for (var i = offset; i < count; i++) {
      if (dataView) {
        output += ("00" + dataView.getUint8(i).toString(16)).slice(-2) + " ";
      } else if (array instanceof Uint8Array) {
        output += ("00" + array[i].toString(16)).slice(-2) + " ";
      }
    }

    output += "]";
    console.debug(output);
  }
}