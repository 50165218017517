import styled, { css } from "styled-components";
import { Icon } from "../../../common/GenericElements";

export const GridItemCard = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 330px;
    min-width: 250px;
    max-height: 250px;
    margin: 0 0 10px 0;

    background: ${props => props.theme.backgroundMain};
    background: linear-gradient(to bottom right,
        ${props => props.theme.gridCardOverlayTop},
        ${props => props.theme.gridCardOverlayTopMiddle},
        ${props => props.theme.gridCardOverlayBottomMiddle},
        ${props => props.theme.gridCardOverlayBottom}
    );
    border-radius: 5px;
    box-shadow: 1px 1px ${props => props.theme.borderTertiary};
`;

export const GridHeader = styled.div`
    flex: none;
    display: flex;
    justify-content: space-between;
    margin: 8px 8px 0px 8px;

    .Gamerpic {
        height: 40px;
        width: 40px;
    }

    .Gamerpic-Info {
        height: 40px;
    }

    .Gamerpic-Tag {
        font-size: 14px;
    }

    .border-inline-end-styleGamerpic-Date {
        font-size: 12px;
    }

    .AdminActionsMenu {
        float: none;
        height: auto;
        margin-top: -5px;
    }
`;

export const GridFooter = styled.div`
    flex: none;
    display: flex;
    justify-content: space-between;
    margin: 4px 8px;
`;

export const GridContentDiv = styled.div`
    position: relative;
    height: 160px;
    width: 100%;

    background: linear-gradient(to bottom right,
        ${props => props.theme.gridContentGradientStart},
        ${props => props.theme.gridContentGradientStop}
    );
`;

export const ImageDivContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center; /* horizontal align */
    align-items: center; /* vertical align */
    background: ${props => props.theme.backgroundTertiary};
`;

export const ImagePreview = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const MainContentDiv = styled<{background: string|undefined}, "div">("div")`
    width: 100%;
    height: 100%;
    color: ${props => props.theme.whiteText};

    ${props => props.background && css`
        background: ${props.background};
    `}

    span {
        color: ${props => props.theme.whiteText};
    }
`;

export const MainTextContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center; /* horizontal align */
    align-items: center; /* vertical align */
    padding: 20px;
    font-size: 22px;
    overflow: hidden;
`;

export const PlayIcon = styled(Icon)`
    font-size: 3em;
    position: relative;
    top: -2.5em;
    left: 2.5em;
`;
