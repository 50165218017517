import styled from "styled-components";
import { media } from "../../../common/Generic.SnapPoints";
import { Icon } from "../../../common/GenericElements";
import { ImageFade } from "../../../styles/ImageStyles";
import { Button } from "../../../common/generic/Button";

export const FeedItemCard = styled.div`
    padding: 25px;
    width: 100%;
    margin-bottom: 10px !important;
    display: inline-block;
    position: relative;

    background: ${props => props.theme.backgroundMain};
    border-right: 1px solid ${props => props.theme.borderSecondary};
    border-bottom: 1px solid ${props => props.theme.borderSecondary};
`;

export const SharedFeedItemCard = styled.div`
    padding: 25px;
    width: 100%;
    margin-bottom: 10px !important;
    display: inline-block;

    border: 1px solid ${props => props.theme.borderSecondary};
    background: ${props => props.theme.backgroundMain};
`;

export const CommentItemCard = styled.div`
    background: ${props => props.theme.backgroundMain};
    float: left;
    width: 100%;
    padding-bottom: 5px;
    display: block;
`;

export const FeedItemHeader = styled.div`
    display: inline-block;
    width: 100%;
`;

export const GamerTag = styled.p`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0px;
    float: left;
    display: block;
    clear: both;

    ${media.medium`font-size: 18px;`}
    ${media.menuOpenSmall`font-size: 16px;`}
    ${media.small`font-size: 15px;`}
`;

export const SocialIcon = styled(Icon) `
    vertical-align: middle;
`;

export const SocialCount = styled.span`
    display: block;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 16px;
    text-align: center;
    ${media.small`font-size: 14px;`}
`;

export const SocialText = SocialCount.extend`
    height: 100%;
`;

export const DateContainer = styled.div`
    opacity: 0.7;
    margin-top: 1px;
    font-size: 15px;
    float: left;
    clear: both;
    display: block;

    ${media.medium`font-size: 13px;`}
    ${media.menuOpenSmall`font-size: 12px;`}
    ${media.small`font-size: 11px; margin-top: -2px;`}
`;

export const TextContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    font-size: 20px;
    word-wrap: break-word;

    ${media.medium`font-size: 18px;`}
    ${media.menuOpenSmall`font-size: 16px;`}
    ${media.small`font-size: 13px;`}
`;

export const EllipsisTextContainer = styled(TextContainer) `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LinkContainer = styled.a`
    white-space: nowrap;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;

    ${media.medium`font-size: 13px;`}
    ${media.menuOpenSmall`font-size: 12px;`}
    ${media.small`font-size: 11px;`}
`;

export const EllipsisLinkContainer = styled(LinkContainer) `
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MediaContainer = styled.div`
    max-width: 760px;
    margin-bottom: 10px;
    width: 100%;
`;

export const Gamerpic = styled.img`
    margin-right: 12px;
    height: 50px;
    width: 50px;
    border-radius: 25px;

    ${media.medium`
        height: 40px;
        width: 40px;
        border-radius: 20px;`}
    ${media.menuOpenSmall`
        height: 50px;
        width: 50px;
        border-radius: 25px;`}
    ${media.small`
        height: 40px;
        width: 40px;
        border-radius: 20px;`}
`;

export const SharedGridCardDiv = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    margin: 3px;
    opacity: 0.75;

    p {
        color: ${props => props.theme.whiteText};
    }

    .Gamerpic-Tag {
        font-size: 14px;
    }

    .Gamerpic {
        height: 40px;
        width: 40px;
    }
`;

export const SocialButtonsContainer = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    align-items: center;

    .Icon:first-of-type {
        padding-left: 0;
    }

    .SocialButton:not(:last-child) {
        margin-right: 15px;
    }

    .SocialButton:focus {
       border: 1px solid #FFF !important;
       outline: 1px solid black !important;
     }


    ${media.menuOpenSmall`display:flex;`}
    ${media.small`display:none;`}
    ${media.xSmall`display:flex;`}
`;

export const PinnedIcon = styled(Icon) `
    bottom: 8px;
    right: 5px;
    position: absolute;
    font-size: 32px;
    z-index: 2;

    ${media.medium`font-size: 32px;`}
    ${media.menuOpenSmall`font-size: 24px;`}
    ${media.small`font-size: 20px;`}
`;

export const PreviewFeedItemCard = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 10px !important;
    padding: 10px;

    background: transparent;
    border: 1px solid ${props => props.theme.borderSecondary};
`;

export const PreviewPinnedIcon = PinnedIcon.extend`
    bottom: 0px;
    right: 0px;
`;

export const SocialButton = styled(Button) `
    min-width: 25px;
    padding: 0px !important;
    text-align: left !important;
    border: 0px transparent !important;
    background: transparent !important;
`;

export const AdminActionsMenu = styled.a`
    float: right;
    font-size: 32px;
    height: 20px;
    margin-top: -20px;
    text-decoration: none !important;
`;

export const AdminCommentActionMenu = styled(Icon) `
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 23px;
`;

export const FeedMedia = styled(ImageFade) `
    object-fit: scale-down;
    object-position: left;
    max-width: 100%;
    position: relative;
`;

export const FeedPreviewMedia = styled(ImageFade) `
    object-fit: scale-down;
    max-width: 100%;
    display: block;
    margin: auto;
`;

export const LinkMedia = styled(ImageFade) `
    object-fit: scale-down;
    object-position: left;
    max-width: 100%;
    margin-top: 10px;
`;

export const SmallLinkButton = styled(Button)`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 3px;
    padding: 2px 8px 2px 2px;
    max-width: stretch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const PreviewButton = styled(Button) `
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-115%, -120%);
    -ms-transform: translate(-115%, -120%);
    font-size: 1.5rem;
`;

export const CommentDateContainer = styled.div`
    opacity: 0.7;
    margin-top: 1px;
    font-size: 13px;
    float: left;
    clear: both;
    display: block;

    ${media.medium`font-size: 12px;`}
    ${media.menuOpenSmall`font-size: 11px;`}
    ${media.small`font-size: 10px; margin-top: -2px;`}
`;

export const CommentTextContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;

    ${media.medium`font-size: 16px;`}
    ${media.menuOpenSmall`font-size: 15px;`}
    ${media.small`font-size: 14px;`}
`;

export const CommentGamerTag = styled.p`
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    float: left;
    clear: both;
    display: block;

    ${media.medium`font-size: 15px;`}
    ${media.menuOpenSmall`font-size: 15px;`}
    ${media.small`font-size: 14px;`}
`;

export const CommentGamerpic = styled.img`
    float: left;
    max-width: 45px;
    margin-top: 5px;
    margin-right: 12px;
    height: 45px;
    width: 45px;
    border-radius: 25px;

    ${media.medium`
        height: 36px;
        width: 36px;
        border-radius: 20px;`}
    ${media.menuOpenSmall`
        height: 32px;
        width: 32px;
        border-radius: 25px;`}
    ${media.small`
        height: 32px;
        width: 32px;
        border-radius: 20px;`}
`;

export const CommentContainer = styled.div`
    float: left;
    display: block;
    width: 80%;
`;

export const ReturnTopButton = styled.div`
    text-align: center;
    font-size: 3rem;
    user-select: none;

    right: 2em;
    bottom: 2em;
    position: fixed;
    z-index: 1;

    opacity: 0.7;
    color: ${props => props.theme.grayShadeB};
    background: ${props => props.theme.backgroundMain};
    border: 2px ${props => props.theme.grayShadeB} solid;
    border-radius: 100%;
    transition: opacity 0.5s;

    &:hover{
        cursor: pointer;
        border-color: ${props => props.theme.grayShadeB};
        opacity: 1;
    }
`;

export const HeaderButtonsContainer = styled.div`
    flex: none;
    display: flex;
`;

export const HeaderButton = styled(Button)`
    margin: 0 1rem 1rem 0;
    padding-top: 0.35rem;
    text-align: center;
    font-size: 2rem;
    user-select: none;

    color: ${props => props.theme.grayShadeB};
    background: ${props => props.theme.backgroundMain};
    border-right: 1px solid ${props => props.theme.borderSecondary};
    border-bottom: 1px solid ${props => props.theme.borderSecondary};

    .Icon {
        padding: 0 8px 0 10px;
    }

    &:hover{
        cursor: pointer;
        background: ${props => props.theme.hoverMain};
    }

    //mobile
    ${media.small`
        margin: 0 0 .5rem .5rem;
        padding: 0;
    `}
`;

export const DropdownHeaderButton = HeaderButton.extend`
    user-select: none;
    margin-left: 0;
    display: flex;
    align-items: center;

    span {
        ${media.small`
            font-size: 12px;
        `}
    }

    .Icon {
        padding: 0;
    }

    //mobile
    //need some space for the two buttons
    ${media.small`
        padding: 0 1rem 0 1rem;
    `}
`;

export const DropdownIcon = styled(Icon)`
    font-size: 0.5em;
    margin: 0 -4px 0 10px;
`;

export const FullSizeButton = HeaderButton.extend`
    width: 100%;
`;
