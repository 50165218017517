import styled from "styled-components";

export const ClubManagerContainer = styled.div`
    display: flex;
    flex: none;

    ul.ClubManagerMenu {
        //ManagerMenu update
        width: 150px;
        background: ${props => props.theme.clubManageOverlay};

        li {
            //ClubManageItem update
            .active {
                border-left: 4px solid ${props => props.theme.blueRest};
                border-right: none;
                background: ${props => props.theme.clubManageActiveItem};
                margin-right: 0;
                padding: 10px 0;
            }
        }
    }
`;

export const ManagerMenu = styled.ul`
    display: flex;
    flex: none;
    flex-direction: column;
    height: fit-content;
    min-width: 200px;
    border-radius: 6px 0;
    margin-right: 10px;
    padding: 10px 0;
    padding-inline-start: 0;

    list-style: none;
    text-decoration: none;
    font-weight: 500;

    background: transparent;
`;

export const ClubManageItem = styled.li`
    margin: 8px -2px 6px 0;
    padding-inline-start: 6px;

    a {
        display: flex;
        font-size: 15px;
        padding-right: 10px;
        text-decoration: none;
        color: ${props => props.theme.foregroundBrigth};
    }

    .active {
        border-right: 4px solid ${props => props.theme.blueRest};
        background: ${props => props.theme.backgroundSecondary};
        margin: 0 -8px;
        text-decoration: none;
        width: stretch;
        padding: 10px 8px;
    }
`;
