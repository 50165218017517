import * as React from "react";
import { Button } from "antd";
import { Mediahub, ContentLocatorType } from "@xbox/social-core";
import { FetchMediaItemsPayload } from "@xbox/social-redux";
import { PostHydrationDataStates } from "@xbox/social-views";

import * as Styles from "../../../containers/Styles";
import { Localize } from "../../../common/translation/Localize";
import { ClubCanActPermissions, MediaType } from "../FeedToolsTypes";
import { XboxLibraryContainer } from "./XboxLibraryContainer/XboxLibraryContainer";
import { getLocator, getThumbnailLocator, XBLMediaChoices } from "../Utils";
import { GenericModalFooter } from "../../../common/GenericElements";

interface XBLMediaPickerProps {
    fetchMediaItems: (payload: FetchMediaItemsPayload, isVideo: boolean) => void;
    setXBLMediaItem: (locator: string, previewUrl: string, downloadUri: string, mediaType: MediaType, contentID?: string) => void;
    permissions?: ClubCanActPermissions;
    hydrationStates: PostHydrationDataStates;
    showItems: XBLMediaChoices;
}

interface XBLMediaPickerState {
    showXblModal: boolean;
    selectedMedia?: Mediahub.MediahubItem;
    mediaType: MediaType;
}

export class XBLMediaPicker extends React.Component <XBLMediaPickerProps, XBLMediaPickerState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showXblModal: false,
            selectedMedia: undefined,
            mediaType: MediaType.Screenshot,
        };
    }

    resetState = () => {
        this.setState({
            showXblModal: false,
            selectedMedia: undefined,
            mediaType: MediaType.Screenshot,
        });
    }

    mediaItemSelected = (item: Mediahub.MediahubItem, itemType: MediaType) => {
        this.setState({selectedMedia: item, mediaType: itemType});
    }

    openXblModal = () => {
        const payload: FetchMediaItemsPayload = {
                continuationToken: "",
                maxPageSize: 100,
            };
        this.setState({showXblModal: true});

        switch (this.props.showItems) {
            case XBLMediaChoices.Screenshots:
                this.props.fetchMediaItems(payload, false);
                break;

            case XBLMediaChoices.Gameclips:
                this.props.fetchMediaItems(payload, true);
                break;

            case XBLMediaChoices.Both:
            default:
                this.props.fetchMediaItems(payload, false);
                this.props.fetchMediaItems(payload, true);
                break;
        }
    }

    useXblModalChoice = () => {
        let item = this.state.selectedMedia;
        if (item) {
            const locator = getLocator(item.ownerXuid, item.contentId, this.state.mediaType);
            const previewUrl = getThumbnailLocator(item.contentLocators, ContentLocatorType.ThumbSmall);
            const downloadUri = getThumbnailLocator(item.contentLocators, ContentLocatorType.Download);
            const contentID = this.state.mediaType === MediaType.Screenshot ? item.contentId : "";

            this.props.setXBLMediaItem(locator, previewUrl, downloadUri, this.state.mediaType, contentID);
        }

        this.resetState();
    }

    closeXblModal = () => {
        this.resetState();
    }

    render() {
        return (
            <div>
                <Button onClick={this.openXblModal} style={{margin: "8px 0"}}>
                    {Localize("uploadMedia.pickXBLLibrary")}
                </Button>
                <Styles.GenericModal
                    title={
                        <div role="heading" aria-level={2}>
                            {Localize("uploadMedia.modal.xblTitle")}
                        </div>}
                    visible={this.state.showXblModal}
                    onCancel={this.closeXblModal}
                    destroyOnClose={true}
                    footer={
                        <GenericModalFooter
                            onOk={this.useXblModalChoice}
                            onCancel={this.closeXblModal}
                            okText={Localize("generic.select")}
                            disableOK={!this.state.selectedMedia}
                        />
                    }
                >
                    <XboxLibraryContainer
                        itemSelected={this.mediaItemSelected}
                        hydroStates={this.props.hydrationStates}
                        showItems={this.props.showItems}
                    />
                </Styles.GenericModal>
            </div>
        );
    }
}
