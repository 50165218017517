import * as React from "react";

interface PersonGamesViewerProps {
}

interface PersonGamesViewerState {
}

export class PersonGamesViewer extends React.Component<PersonGamesViewerProps, PersonGamesViewerState> {
    constructor(props: PersonGamesViewerProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1>this is person games page</h1>
            </div>
        );
    }
}
