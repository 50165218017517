"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.partyRedux = exports.getPartyContainerViewState = void 0;

var PartyActions_1 = require("../../actions/PartyActions");

var PartyReducer_1 = require("./PartyReducer");

function getPartyContainerViewState(state) {
  return {
    currentUser: state.authentication.currentUser,
    remoteMediaStreams: state.party.remoteMediaStreams,
    members: state.party.members,
    inParty: state.party.inParty
  };
}

exports.getPartyContainerViewState = getPartyContainerViewState; // Main export for PartyContainers

exports.partyRedux = {
  reducer: PartyReducer_1.partyReducer,
  actions: PartyActions_1.PartyActions,
  selectors: {
    getPartyContainerViewState: getPartyContainerViewState
  }
};