"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitializeTokenStack = void 0;

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var GetDtoken_1 = require("./GetDtoken");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["GetDeviceIdentity"] = "GetDeviceIdentity";
  Step["GetDefaultNsal"] = "GetDefaultNsal";
  Step["GetDeviceToken"] = "GetDeviceToken";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var InitializeTokenStack =
/** @class */
function (_super) {
  __extends(InitializeTokenStack, _super);

  function InitializeTokenStack(telemetryClient, tokenStackComponents, httpClient, offlineMode) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.tokenStackComponents = tokenStackComponents;
    _this.httpClient = httpClient;
    _this.offlineMode = offlineMode;
    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.InitializeTokenStack, Step.Start);
    return _this;
  }

  InitializeTokenStack.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var deviceToken;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            return [4
            /*yield*/
            , this.getDeviceIdentity()];

          case 1:
            _a.sent();

            if (this.offlineMode) {
              this.step.advance(Step.Done);
              this.succeed(undefined);
              return [2
              /*return*/
              ];
            }

            Utils_1.assert(this.step.currentStep === Step.GetDeviceIdentity);
            return [4
            /*yield*/
            , this.getDefaultNsal()];

          case 2:
            _a.sent();

            Utils_1.assert(this.step.currentStep === Step.GetDefaultNsal);
            return [4
            /*yield*/
            , this.getDeviceToken()];

          case 3:
            deviceToken = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.GetDeviceToken);
            Utils_1.assert(!!deviceToken);
            this.step.advance(Step.Done);
            this.succeed(undefined);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  InitializeTokenStack.prototype.getDefaultNsal = function () {
    this.step.advance(Step.GetDefaultNsal);
    return this.tokenStackComponents.nsal.loadDefaultNsal(this.tokenStackComponents.config, this.httpClient);
  };

  InitializeTokenStack.prototype.getDeviceIdentity = function () {
    this.step.advance(Step.GetDeviceIdentity);
    return this.tokenStackComponents.xboxCache.loadDeviceIdentity();
  };

  InitializeTokenStack.prototype.getDeviceToken = function () {
    this.step.advance(Step.GetDeviceToken);
    var operation = new GetDtoken_1.GetDtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient,
    /* forceRefresh: */
    false);
    operation.execute();
    return operation.completionPromise;
  };

  return InitializeTokenStack;
}(OperationBase_1.OperationBase);

exports.InitializeTokenStack = InitializeTokenStack;