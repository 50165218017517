import * as React from "react";
import { Tabs } from "antd";
import { ManageMediaContainer, IManageMediaResolver, MediaHydrationDataStates, ManageMediaHandlers } from "@xbox/social-views";
import { withCurrentUserContext } from "../../containers/CurrentUserContext";
import { ManageScreenshots } from "./managemedia/ManageScreenshots";
import { ManageGameClips } from "./managemedia/ManageGameClips";
import { MediaManagerProps } from "./managemedia/ManageMedia";
import { UploadMediaPicker } from "./ChooseMediaContainer/UploadMediaPicker";
import { XBLMediaChoices } from "./Utils";
import { GenericErrorMessage } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { TabsDiv } from "./FeedTools.Styles";

const TabPane = Tabs.TabPane;

function getManageMediaViewers(hydration: MediaHydrationDataStates, handlers: ManageMediaHandlers): JSX.Element | null {
    let props: MediaManagerProps = {
        fetchMediaItems: handlers.fetchMediaItems,
        deleteMediaItem: handlers.deleteMediaItem,
        getUserMediaTitles: handlers.getUserMediaTitles,
        hydrationStates: hydration,
    };
    setTimeout(() => {
        document.querySelectorAll("[role='tab'][aria-selected='true']").forEach(el => {
            el.setAttribute("tabindex", "0");
            (el as HTMLElement).focus();
        });
        document.querySelectorAll("[role='tab'][aria-selected='false']").forEach(ele => {
            ele.setAttribute("tabindex", "-1");
            (ele as HTMLElement).blur();
        });
        document.querySelectorAll("[role='tablist']").forEach(element => {
            element.setAttribute("tabindex", "-1");
        });
    }, 500);
    const handleTabChange = (e: any) => {
        setTimeout(() => {
            document.querySelectorAll("[role='tab'][aria-selected='true']").forEach(el => {
                el.setAttribute("tabindex", "0");
                (el as HTMLElement).focus();
            });
            document.querySelectorAll("[role='tab'][aria-selected='false']").forEach(ele => {
                ele.setAttribute("tabindex", "-1");
                (ele as HTMLElement).blur();
            });
        }, 700);
    };

    let showQuotaError = hydration.usersQuota.percentageUsed !== undefined && hydration.usersQuota.percentageUsed >= 90;

    return (
        <div style={{flex: "auto"}}>
            { showQuotaError
                && <GenericErrorMessage
                    style={{marginTop: "5px"}}
                    errorMessage={Localize("feedtools.usersQuota")}
                    showError={true}
                />
            }
            <UploadMediaPicker
                uploadMediaItems={handlers.uploadMediaItem}
                getAllTitles={handlers.getAllTitles}
                titleList={hydration.allTitles}
                uploadState={hydration.uploadState}
                uploadItems={XBLMediaChoices.Both}
            />
            <TabsDiv>
                <Tabs type="card" style={{ margin: "-8px 0 0 0" }} onChange={handleTabChange}>
                    <TabPane tab={Localize("xblContainer.screens")} key="1">
                        <ManageScreenshots {...props} />
                    </TabPane>
                    <TabPane tab={Localize("xblContainer.gameclips")} key="2">
                        <ManageGameClips {...props} />
                    </TabPane>
                </Tabs>
            </TabsDiv>
        </div>
    );
}

const ManageMediaResolver: IManageMediaResolver = {
    getManageMediaViewers: getManageMediaViewers
};

export default withCurrentUserContext(ManageMediaContainer(ManageMediaResolver));
