var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { mediaHubRedux, titleHubRedux, feedViewerRedux } from "@xbox/social-redux";

function coreFeedToolsContainer(postFeedResolver) {
  return (
    /** @class */
    function (_super) {
      __extends(class_1, _super);

      function class_1(props) {
        var _this = _super.call(this, props) || this;

        _this.handlePostClick = function (item, wasPinned, wasDisabled, wasScheduled, scheduledDateTime) {
          if (wasScheduled && scheduledDateTime) {
            item.postDate = scheduledDateTime;
          }

          var request = {
            clubId: item.timelines[0].timelineOwner,
            postData: item,
            isPinned: wasPinned,
            isScheduled: wasScheduled,
            isDisabled: wasDisabled
          };

          _this.props.handlePostFeedItem(request);
        };

        _this.handleLinkHydration = function (webLink) {
          var request = {
            link: webLink
          };

          _this.props.handleWebLinkHydration(request);
        };

        _this.handleStringValidation = function (stringList) {
          var request = {
            stringsToVerify: stringList
          };

          _this.props.handleStringValidation(request);
        };

        _this.fetchMediaItems = function (payload, isVideo) {
          if (isVideo) {
            _this.props.fetchXblGameclips(payload);
          } else {
            _this.props.fetchXblScreenshots(payload);
          }
        };

        _this.uploadMediaItems = function (data, isVideo) {
          if (isVideo) {
            _this.props.uploadXblGameclip(data);
          } else {
            _this.props.uploadXblScreenshot(data);
          }
        };

        _this.searchGame = function (gameName) {
          _this.props.searchGameInMsCatalog(gameName);
        };

        return _this;
      }

      class_1.prototype.render = function () {
        return postFeedResolver.getPostFeedItemContainer(this.props.clubItem, this.props.currentUser, this.props.hydration, this.handlePostClick, this.handleLinkHydration, this.handleStringValidation, this.searchGame, this.fetchMediaItems, this.uploadMediaItems, this.props.getAllTitles, this.props.createBallot, this.props.forceSchedule ? this.props.forceSchedule : false, this.props.selectedChannel);
      };

      return class_1;
    }(React.Component)
  );
}

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var hydrationState = {
    webLink: state.feedViewer.hydrator,
    screenshots: state.mediahub.screenshots,
    gameclips: state.mediahub.gameclips,
    upload: state.mediahub.upload,
    stringValidation: state.feedViewer.stringValidation,
    postStatus: state.feedViewer.postStatus,
    titleList: state.titlehub.alltitles,
    msCatalog: titleHubRedux.selectors.getMsCatalogGameItems(state.titlehub.searchMsCatalog)
  };
  return {
    hydration: hydrationState,
    currentUser: state.authentication.currentUser,
    selectedChannel: state.channels.selectedChannel
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    handlePostFeedItem: function handlePostFeedItem(feedItemPayload) {
      return dispatch(feedViewerRedux.actions.postFeedItem(feedItemPayload));
    },
    handleWebLinkHydration: function handleWebLinkHydration(linkPayload) {
      return dispatch(feedViewerRedux.actions.hydrateWebLink(linkPayload));
    },
    handleStringValidation: function handleStringValidation(stringPayload) {
      return dispatch(feedViewerRedux.actions.validateString(stringPayload));
    },
    searchGameInMsCatalog: function searchGameInMsCatalog(gameName) {
      return dispatch(titleHubRedux.actions.searchMsCatalog(gameName));
    },
    fetchXblScreenshots: function fetchXblScreenshots(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.fetchScreenshots(mediaItemPayload));
    },
    fetchXblGameclips: function fetchXblGameclips(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.fetchGameclips(mediaItemPayload));
    },
    uploadXblScreenshot: function uploadXblScreenshot(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.uploadScreenshot(mediaItemPayload));
    },
    uploadXblGameclip: function uploadXblGameclip(mediaItemPayload) {
      return dispatch(mediaHubRedux.actions.uploadGameclip(mediaItemPayload));
    },
    createBallot: function createBallot(ballotPayload) {
      return dispatch(feedViewerRedux.actions.createBallot(ballotPayload));
    },
    getAllTitles: function getAllTitles() {
      return dispatch(titleHubRedux.actions.fetchAllTitles());
    },
    navigateHome: function navigateHome() {
      return dispatch(push("/"));
    }
  };
}; // main wrapped export that will connect redux and pass in all the render types.


export function CoreFeedToolsContainer(postFeedResolver) {
  return connect(mapStateToProps, mapDispatchToProps)(coreFeedToolsContainer(postFeedResolver));
}