import { injectable } from "inversify";
import { IAppConfig } from "@xbox/social-core";
import { IsDesktop } from "../common/GenericChecks";

@injectable()
export class ProductionAppConfig implements IAppConfig {
    get clientId(): string {
        return process.env.REACT_APP_CLIENT_ID || "0000000048A5D21F";
    }

    get titleId(): number {
        return parseInt((process.env.REACT_APP_TITLE_ID)! as string, 10) || 2004508811;
    }

    get sandbox(): string {
        return process.env.REACT_APP_SANDBOX || "RETAIL";
    }

    get msaRedirectUri(): string {
        if (IsDesktop()) {
            return `https://login.live.com/oauth20_desktop.srf`;
        } else {
            return `${window.location.origin}/authcomplete`;
        }
    }

    get siSuRedirectUri(): string {
        if (IsDesktop()) {
            return `https://sisu.xboxlive.com/sisu_desktop.srf`;
        } else {
            return `${window.location.origin}/sisucomplete`;
        }
    }

    get isFirstParty(): boolean {
        return true;
    }

    get sendGridApiKey(): string {
        return process.env.REACT_APP_SENDGRID_API_KEY + "";
    }

    get graphQLUri(): string {
        return process.env.REACT_APP_XBL_GRAPHQL_URL || "https://xblgraphql.xboxlive.com/graphql";
    }

    get isServerSideRendered(): boolean {
        return process.env.IS_SERVER_SIDE_RENDERED && JSON.parse(process.env.IS_SERVER_SIDE_RENDERED);
    }
}
