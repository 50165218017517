import styled from "styled-components";
import { Button } from "antd";
import { media } from "../../common/Generic.SnapPoints";

export const ReportItem = styled.div`
    width: 92%;
    display: inline-block;
    border-bottom: .1% solid ${props => props.theme.borderSecondary};
    margin-bottom: 2%;
`;

export const ActivityItemErrorContainer = styled.div`
    color: ${props => props.theme.redActive};
    text-align: center;
`;

export const ReportActionsContainer = styled.div`
    float: left;
    display: block;
    margin-left: 2%;
    width: 33%;

    ${media.medium`width:43%;`}
    ${media.menuOpenSmall`
        width:100%;
        margin-left: 0;`
    }
    ${media.small`
        width:43%;
        margin-left: 2%;`
    }
    ${media.xSmall`
        width:100%;
        margin-left: 0;`
    }
`;

export const SocialItemContainer = styled.div`
    width: 65%;
    float: left;
    border: 1px solid ${props => props.theme.borderSecondary};

    ${media.medium`width:55%;`}
    ${media.menuOpenSmall`width:100%;`}
    ${media.small`width:55%;`}
    ${media.xSmall`width:100%;`}
`;

export const ReportActionButton = styled(Button as any)`
    width: 100%;
`;

export const ReportTitle = styled.div`
    font-size: 1.1em;
    margin-left: .6em;
    margin-bottom: .6em;

    ${media.medium`font-size: 1em;`}
    ${media.menuOpenSmall`font-size: .8em;`}
    ${media.small`font-size: .7em;`}
`;

export const Block = styled.div`
    margin-right: 1.25em;
    margin-bottom: .6em;
`;

export const UserSummaryList = styled.ul`
    list-style-type: none;
    padding: 0px;
`;

export const UserSummaryListItem = styled.li`
    min-height: 30px;
    width: 100%;
    clear: both;
    margin-bottom: 5px;
`;

export const GamertagLabel = styled.span`
    margin-left: .6em;
    font-weight: 500;
    float: left;
    font-size: 1em;

    ${media.medium`font-size: .8em;`}
    ${media.menuOpenSmall`font-size: .7em;`}
    ${media.small`font-size: .6em;`}
`;

export const ReportReason = styled.div`
    max-width: 500px;
    float: left;
    font-size: 1em;

    ${media.medium`font-size: .8em;`}
    ${media.menuOpenSmall`font-size: .7em;`}
    ${media.small`font-size:  .6em;`}
`;

export const PopoverContainer = styled.div`
    margin-top: .25em;
    margin-bottom: .6em;
`;

export const HorizontalSpacing = styled.div`
    width: .6em;
    display: inline-block;
`;
