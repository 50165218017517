import styled from "styled-components";
import { media } from "../../../common/Generic.SnapPoints";
import { Icon } from "../../../common/GenericElements";

export const ItemCard = styled.div`
    height: 120px;
    margin: 0px 2px 10px 0px;

    border: none;
    background: ${props => props.theme.backgroundMain};
    box-shadow: 1px 1px ${props => props.theme.borderSecondary};
`;

export const MainDivContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding: 8px 16px 0px 16px;
    min-width: 320px;
    max-width: 80%;

    ${media.menuOpenSmall`min-width: 220px; max-width: 75%`}
    ${media.small`min-width: 200px;`}
`;

export const ImageDivContainer = styled.div`
    float: left;
    height: 100%;
    width: 200px;
    max-width: 35%;
    overflow: hidden;
    display: flex;
    justify-content: center; /* horizontal align */
    align-items: center; /* vertical align */
    background: ${props => props.theme.backgroundTertiary};

    ${media.menuOpenSmall`max-width: 25%;`}
`;

export const PostTextDivContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
`;

export const HeaderDivContainer = styled.div`
    clear: both;
    margin-top: 2px;
    display: flex;
    flex: 1;

    ${media.menuOpenSmall`margin-top: 5px;`}
`;

export const ShareDivContainer = styled.div`
    float: left;
    height: 100%;
    width: 30px;
    background: ${props => props.theme.backgroundThemedOverlay};
    border-right: 1px solid ${props => props.theme.foregroundMain};
    margin-right: 6px;
`;

export const ContentDivContainer = styled.div`
    display: flow-root;
`;

export const SecondaryContentRow = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 2px;
`;

export const ActionsDivContainer = styled.div`
    clear: both;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
`;

export const PinnedIcon = styled(Icon) `
    float: left;
    margin: 4px 4px 0 -2px;

    .Icon {
        color: ${props => props.theme.blueActive};
    }
`;

export const GamerTag = styled.p`
    margin-bottom: 0px;
    display: inline-block;
    color: ${props => props.theme.foregroundBrigth};
`;

export const DateSpan = styled.span`
    opacity: 0.7;
    margin-top: 1px;
    margin-left: 4px;
`;

export const EllipsisPostText = styled.div`
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${media.medium`font-size: 18px;`}
    ${media.menuOpenSmall`font-size: 16px;`}
    ${media.small`font-size: 15px;`}
`;

export const FallBackIcon = styled(Icon)`
    font-size: 4em;
    opacity: 0.25;
`;
