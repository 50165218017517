"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetAnonymousXtoken = void 0;

var Constants_1 = require("../../Constants");

var XalInternalError_1 = require("../../errors/XalInternalError");

var XalInternalErrorType_1 = require("../../errors/XalInternalErrorType");

var ITelemetryClient_1 = require("../../ITelemetryClient");

var Utils_1 = require("../../Utils");

var OperationBase_1 = require("../OperationBase");

var StepTracker_1 = require("../StepTracker");

var GetDtoken_1 = require("./GetDtoken");

var InitializeTokenStack_1 = require("./InitializeTokenStack");

var GetTtoken_1 = require("./GetTtoken");

var RefreshAnonymousXtoken_1 = require("../xboxToken/RefreshAnonymousXtoken");

var Step;

(function (Step) {
  Step["Start"] = "Start";
  Step["Init"] = "Init";
  Step["GetDtoken"] = "GetDtoken";
  Step["GetTtoken"] = "GetTtoken";
  Step["RefreshXtoken"] = "RefreshXtoken";
  Step["LoadTitleNsal"] = "LoadTitleNsal";
  Step["Done"] = "Done";
})(Step || (Step = {}));

var GetAnonymousXtoken =
/** @class */
function (_super) {
  __extends(GetAnonymousXtoken, _super);

  function GetAnonymousXtoken(telemetryClient, tokenStackComponents, forceRefresh, forceRefreshTtoken, httpClient, xtoken) {
    var _this = _super.call(this, telemetryClient) || this;

    _this.tokenStackComponents = tokenStackComponents;
    _this.forceRefresh = forceRefresh;
    _this.forceRefreshTtoken = forceRefreshTtoken;
    _this.httpClient = httpClient;
    _this.xtoken = xtoken; // TODO(alkhayat): NOXAL: This isn't yet implemented in XAL

    _this.step = new StepTracker_1.StepTracker(ITelemetryClient_1.Area.GetAnonymousXtoken, Step.Start);
    _this.xtokenRefreshRetries = 0;
    _this.refreshingConstituentTokens = false;
    _this.forceRefreshDtoken = false;
    Utils_1.assert(!!_this.xtoken);
    return _this;
  }

  GetAnonymousXtoken.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.step.currentStep === Step.Start);
            this.step.advance(Step.Init);
            operation = new InitializeTokenStack_1.InitializeTokenStack(this.telemetryClient, this.tokenStackComponents, this.httpClient, false // offlineMode
            );
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === Step.Init);
            if (!(!this.forceRefreshTtoken && !this.forceRefresh && this.xtoken.isValid)) return [3
            /*break*/
            , 3]; // We still need to make sure title NSAL is loaded

            return [4
            /*yield*/
            , this.loadTitleNsal()];

          case 2:
            // We still need to make sure title NSAL is loaded
            _a.sent();

            return [2
            /*return*/
            ];

          case 3:
            // Our current xtoken is either missing or not present so now we have to
            // start getting all the constituent tokens.
            return [4
            /*yield*/
            , this.getDtoken()];

          case 4:
            // Our current xtoken is either missing or not present so now we have to
            // start getting all the constituent tokens.
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetAnonymousXtoken.prototype.getDtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, dtoken;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.GetDtoken);
            operation = new GetDtoken_1.GetDtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, this.forceRefreshDtoken);
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            dtoken = _a.sent();
            Utils_1.assert(this.step.currentStep === Step.GetDtoken);
            this.dtoken = dtoken;
            this.forceRefreshDtoken = false;
            return [4
            /*yield*/
            , this.getTtoken()];

          case 2:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetAnonymousXtoken.prototype.getTtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            operation = new GetTtoken_1.GetTtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, this.forceRefreshTtoken);
            operation.execute();
            _a = this;
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            _a.ttoken = _b.sent();
            return [4
            /*yield*/
            , this.refreshXtoken()];

          case 2:
            _b.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetAnonymousXtoken.prototype.refreshXtoken = function () {
    return __awaiter(this, void 0, void 0, function () {
      var operation, result, _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.step.advance(Step.RefreshXtoken);
            Utils_1.assert(!!this.xtoken);
            Utils_1.assert(this.forceRefresh || !this.xtoken.isValid);
            operation = new RefreshAnonymousXtoken_1.RefreshAnonymousXtoken(this.telemetryClient, this.tokenStackComponents, this.httpClient, this.dtoken, this.ttoken, this.xtoken);
            operation.execute();
            return [4
            /*yield*/
            , operation.completionPromise];

          case 1:
            result = _b.sent();
            Utils_1.assert(this.step.currentStep === Step.RefreshXtoken);
            Utils_1.assert(result === this.xtoken);
            if (!(this.xtoken.xerr !== 0)) return [3
            /*break*/
            , 8];
            Utils_1.assert(!this.xtoken.isValid);
            _a = this.xtoken.xerr;

            switch (_a) {
              case Constants_1.Xerr.ExpiredDtoken:
                return [3
                /*break*/
                , 2];

              case Constants_1.Xerr.InvalidDtoken:
                return [3
                /*break*/
                , 2];

              case Constants_1.Xerr.ExpiredUtoken:
                return [3
                /*break*/
                , 2];

              case Constants_1.Xerr.InvalidUtoken:
                return [3
                /*break*/
                , 2];

              case Constants_1.Xerr.ExpiredTtoken:
                return [3
                /*break*/
                , 2];

              case Constants_1.Xerr.InvalidTtoken:
                return [3
                /*break*/
                , 2];
            }

            return [3
            /*break*/
            , 4];

          case 2:
            return [4
            /*yield*/
            , this.refreshConstituentTokensAndRestart()];

          case 3:
            _b.sent();

            return [3
            /*break*/
            , 5];

          case 4:
            return [3
            /*break*/
            , 5];

          case 5:
            if (!(this.xtokenRefreshRetries > 2)) return [3
            /*break*/
            , 6];
            Utils_1.xalTrace(Utils_1.TraceLevel.Error, "XSTS returned with Xerr, maximum number of retries exceeded");
            this.step.advance(Step.Done);
            this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "XSTS returned with Xerr, maximum number of retries exceeded"));
            return [2
            /*return*/
            ];

          case 6:
            this.xtokenRefreshRetries++;
            Utils_1.xalTrace(Utils_1.TraceLevel.Important, "XSTS returned with Xerr, refreshing title token. Attempt " + this.xtokenRefreshRetries);
            this.forceRefreshTtoken = true;
            return [4
            /*yield*/
            , this.getTtoken()];

          case 7:
            _b.sent();

            return [2
            /*return*/
            ];

          case 8:
            return [4
            /*yield*/
            , this.loadTitleNsal()];

          case 9:
            _b.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetAnonymousXtoken.prototype.refreshConstituentTokensAndRestart = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            Utils_1.assert(this.xtoken.xerr !== 0);
            this.telemetryClient.instrumentAppError(ITelemetryClient_1.Area.GetAnonymousXtoken, "Invalid user credentials error received", this.refreshingConstituentTokens ? ITelemetryClient_1.ErrorLevel.Error : ITelemetryClient_1.ErrorLevel.Warning, XalInternalErrorType_1.XalInternalErrorType.BadUserToken);

            if (this.refreshingConstituentTokens) {
              Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Xtoken operation received bad token errors twice in a row. Failing out.");
              this.step.advance(Step.Done);
              this.fail(new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Xtoken operation received bad token errors twice in a row. Failing out."));
              return [2
              /*return*/
              ];
            } else {
              Utils_1.xalTrace(Utils_1.TraceLevel.Important, "Xtoken operation received bad token error. Retrying with fresh tokens.");
            } // Set a flag so we only restart like this once


            this.refreshingConstituentTokens = true;
            this.forceRefreshDtoken = true;
            this.forceRefreshTtoken = true; // Restart at step 1

            return [4
            /*yield*/
            , this.getDtoken()];

          case 1:
            // Restart at step 1
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  GetAnonymousXtoken.prototype.loadTitleNsal = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.step.advance(Step.LoadTitleNsal);
            Utils_1.assert(!!this.xtoken && this.xtoken.isValid);
            return [4
            /*yield*/
            , this.tokenStackComponents.nsal.loadTitleNsal(this.tokenStackComponents.config, this.tokenStackComponents.xboxCache.deviceIdentity, this.xtoken.tokenData, this.httpClient)];

          case 1:
            _a.sent();

            Utils_1.assert(this.step.currentStep === Step.LoadTitleNsal);
            this.step.advance(Step.Done);
            this.succeed(this.xtoken);
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return GetAnonymousXtoken;
}(OperationBase_1.OperationBase);

exports.GetAnonymousXtoken = GetAnonymousXtoken;