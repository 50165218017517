import * as React from "react";
import { Menu } from "antd";
import { User, ActivityFeed } from "@xbox/social-core";
import { Localize } from "../../../common/translation/Localize";

export interface BaseAdminActionsProps {
    showAdminAction?: boolean;
    clubModerators?: string[];
    isModerator: boolean;
    isOwner: boolean;
    user?: User;
    isCommentLocked?: boolean;
}

function GetDeleteAndBanAction(itemAuthorId: string, isCurrentUserModerator: boolean, clubModerators?: string[]) {
    if (isCurrentUserModerator && (clubModerators !== undefined && !clubModerators.some(x => x === itemAuthorId))) {
        return (
            <Menu.Item key={ActivityFeed.AdminActionTypes.DeleteAndBan}>
                {Localize("activityFeedContainer.deleteAndBan")}
            </Menu.Item>
        );
    }
    return null;
}

export function GetAllowedAdminActions(
    isUserTheOwnerOfComment: boolean,
    isModerator: boolean,
    isOwner: boolean,
    actionSelected: (e: any) => void,
    itemAuthorId: string,
    getPinAction: () => JSX.Element | null,
    getExportCommentsAction: () => JSX.Element | null,
    commentActionMenuItem: () => JSX.Element | null,
    clubModerators?: string[]
): JSX.Element {
    if (!isModerator && isUserTheOwnerOfComment) {
        return (
            <Menu onClick={actionSelected}>
                <Menu.Item key={ActivityFeed.AdminActionTypes.Delete}>
                    {Localize("activityFeedContainer.delete")}
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Menu onClick={actionSelected}>
            {getPinAction()}
            <Menu.Item key={ActivityFeed.AdminActionTypes.Delete}>
                {Localize("activityFeedContainer.delete")}
            </Menu.Item>
            {getExportCommentsAction()}
            {GetDeleteAndBanAction(itemAuthorId, isModerator, clubModerators)}
            {commentActionMenuItem()}
            <Menu.Item key={ActivityFeed.AdminActionTypes.AddToModQ}>
                {Localize("activityFeedContainer.addToModQ")}
            </Menu.Item>
            <Menu.Item key={ActivityFeed.AdminActionTypes.Report}>
                {Localize("activityFeedContainer.report")}
            </Menu.Item>
        </Menu>
    );
}
