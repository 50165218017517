import * as React from "react";

export interface AbstractInputProps {
    className?: string;
    defaultValue?: any;
    value?: any;
    tabIndex?: number;
    style?: React.CSSProperties;
    placeholder?: string;
    submitButton?: (props: any) => JSX.Element;
    clearButton?: (props: any) => JSX.Element;
}

export interface InputProps extends AbstractInputProps {
    onPressEnter?: (text: string) => void;
    onKeyDown?: React.FormEventHandler<HTMLTextAreaElement>;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

interface InputState {
    rowCount: number;
}

/**
 * React input element wrapper to provide the functionality of action on Enter key
 */
export default class Input extends React.Component<InputProps, InputState> {

    public input?: HTMLTextAreaElement;
    private ctrled: boolean = false;
    private shifted: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            rowCount: 1
        };
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { onPressEnter, onKeyDown } = this.props;
        if (e.keyCode === 16) {
            this.shifted = true;
        }

        if (e.keyCode === 17) {
            this.ctrled = true;
        }

        if (e.keyCode === 13) {
            if (onPressEnter && !this.shifted && !this.ctrled) {
                e.preventDefault();
                onPressEnter(e.target["value"]);

                // If no clear button is passed, aggressively clear on submit
                if (!this.props.clearButton) {
                    this.clear();
                }
                return;
            }
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 16) {
            this.shifted = false;
        }

        if (e.keyCode === 17) {
            this.ctrled = false;
        }

        if (this.input) {
            let potentialRowCount = this.input.value.split("\n").length;
            this.setState({rowCount: Math.min(potentialRowCount, 4)});
        }

    }

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    blur() {
        if (this.input) {
            this.input.blur();
        }
    }

    clear() {
        if (this.input) {
            this.input.value = "";
            this.setState({rowCount: 1});
        }
    }

    manuallySubmit = () => {
        if (this.input && this.props.onPressEnter) {
            this.props.onPressEnter(this.input.value);
        }
    }

    saveInput = (node: HTMLTextAreaElement) => {
        this.input = node;
    }

    renderInput() {
        const { className } = this.props;
        return (
            <textarea
                style={{flex: "1", backgroundColor: "white", borderRadius: "5px"}}
                placeholder={this.props.placeholder}
                className={className}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                ref={this.saveInput}
                rows={this.state.rowCount}
            />
        );
      }

    render() {
        return (
            <div style={{display: "flex"}}>
                {this.renderInput()}
                {this.props.submitButton && <this.props.submitButton onClick={this.manuallySubmit}/>}
                {this.props.clearButton && <this.props.clearButton onClick={() => this.clear()}/>}
            </div>
        );
    }
}
