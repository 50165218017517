import * as React from "react";
import { Layout, Tabs, List, Upload, Progress } from "antd";
import { ImageSources, XboxLiveImage, UpdateImageTypes } from "@xbox/social-core";
import { CoreImagePickerContainer, ImagePickerHandlers, IImagePickerResolver, ImagePickerContainerProps } from "@xbox/social-views";
import { withClubSelectionContext } from "../ClubSelectionContext";
import { Localize, } from "../../common/translation/Localize";
import * as Styles from "./ImagePicker.Styles";
import { readFileAsDataUrl, createScaledImage } from "../../components/feedtools/Utils";
import { Glyphs } from "../../common/Generic.Icons";
import { Icon } from "../../common/GenericElements";
import { notificationService } from "../../index";

const Dragger = Upload.Dragger;
const Content = Layout.Content;
const TabPane = Tabs.TabPane;

function getImagePickerPanel(handlers: ImagePickerHandlers, imagePickerProps: ImagePickerContainerProps): JSX.Element | null {
    function getTab(dataSource: any) {
        return (
            <List
                itemLayout="vertical"
                size="default"
                pagination={{
                    pageSize: 24,
                }}
                grid={{ gutter: 10, column: 6 }}
                dataSource={dataSource}
                renderItem={(item: XboxLiveImage) => (
                    <List.Item>
                        <Styles.SquareImageContainer onClick={() => handlers.imageSelected(item)}><Styles.SquareImage src={item.medium} /></Styles.SquareImageContainer>
                    </List.Item>
                )}
            />
        );
    }

    let customImage = null;

    function onChange(info: any, type: string) {
        const status = info.file.status;
        if (status !== "uploading") {
            readFileAsDataUrl(info.file)
                .then((dataUrl) => {
                    return createScaledImage(dataUrl, type);
                })
                .then((data) => {
                    handlers.uploadCustomImage(data, info.file, type);
                })
                .catch((err) => {
                    let errNumber = "001";
                    if (err === "size") {
                        errNumber = (type === UpdateImageTypes.background) ? "002" : "003";
                    }

                    notificationService.notifyError("UploadMediaItem", errNumber);
                });
        }
    }

    let progress = <Icon type={Glyphs.Upload} style={{ fontSize: "3.5em" }} />;
    let inProgress = imagePickerProps.upload && imagePickerProps.upload.progressValue > 0;
    if (inProgress) {
        progress = <Progress type="circle" percent={imagePickerProps.upload && imagePickerProps.upload.progressValue} />;
    }

    if (imagePickerProps.allowedSources === ImageSources.Custom || imagePickerProps.allowedSources === ImageSources.All) {
        let uploader = null;

        uploader = (
            <Dragger
                onChange={e => onChange(e, imagePickerProps.updateImageType ? imagePickerProps.updateImageType.toString() : "")}
                multiple={false}
                disabled={inProgress}
                accept="image/*"
                listType="picture-card"
                beforeUpload={() => { return false; }}
                showUploadList={false}
            >
                {progress}
                <p className="ant-upload-text">{inProgress ? "" : Localize("imagePicker.uploaderDescription")}</p>
            </Dragger>
        );

        customImage = (
            <TabPane tab={Localize("imagePicker.customTabTitle")} key="1">
                {uploader}
            </TabPane>
        );
    }

    let screenshots = imagePickerProps.images && imagePickerProps.images[ImageSources.Captures] && (imagePickerProps.allowedSources === ImageSources.Captures || imagePickerProps.allowedSources === ImageSources.All) ? (
        <TabPane tab={Localize("imagePicker.screenshotsTabTitle")} key="2">
            {getTab(imagePickerProps.images[ImageSources.Captures])}
        </TabPane>
    ) : null;

    let genericProfiles = imagePickerProps.images && imagePickerProps.images[ImageSources.GenericProfiles] && (imagePickerProps.allowedSources === ImageSources.GenericProfiles || imagePickerProps.allowedSources === ImageSources.All) ? (
        <TabPane tab={Localize("imagePicker.genericProfilesTabTitle")} key="3">
            {getTab(imagePickerProps.images[ImageSources.GenericProfiles])}
        </TabPane>
    ) : null;

    let genericClubs = imagePickerProps.images && imagePickerProps.images[ImageSources.GenericClubs] && (imagePickerProps.allowedSources === ImageSources.GenericClubs || imagePickerProps.allowedSources === ImageSources.All) ? (
        <TabPane tab={Localize("imagePicker.genericClubsTabTitle")} key="4">
            {getTab(imagePickerProps.images[ImageSources.GenericClubs])}
        </TabPane>
    ) : null;

    let modal = (
        <Content>
            <Tabs defaultActiveKey="1">
                {customImage}
                {genericClubs}
                {screenshots}
                {genericProfiles}
            </Tabs>
        </Content>
    );

    return modal;
}

const FeedContainerResolver: IImagePickerResolver = {
    getImagePickerPanel: getImagePickerPanel
};

export default withClubSelectionContext(CoreImagePickerContainer(FeedContainerResolver));
