import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withCookies, Cookies } from "react-cookie";
import { User } from "@xbox/social-core";
import { AuthActions, ReduxState, AuthRedirectCompletedPayload } from "@xbox/social-redux";
import { telemetryService } from "../index";
import { GenericLoading } from "../common/GenericElements";
import { CenteredColumnDiv } from "../common/Generic.Styles";
import TimeSensitiveFeedbackPrompt from "./TimeSensitiveFeedbackPrompt";

declare const window: any;

type AuthRedirectProps = {
    currentUser: User;
    authFailure?: Error;
    navigateToApp: () => void;
    authRedirectCompleted: (payload: AuthRedirectCompletedPayload) => void;
    cookies: Cookies;
};

class AuthRedirectComplete extends React.Component<AuthRedirectProps, {}> {

    componentDidMount() {

        // clear the pending as soon as we are done with refresh to avoid the case where complete
        // is written and something happens processing and we are in a bad state.
        let isRefreshPending = localStorage.getItem("MSA_REFRESH_PENDING") != null;
        localStorage.removeItem("MSA_REFRESH_PENDING");
        // Handle the special case of MSA redirecting to us after a successful logout operation
        // by redirecting back to home instead of attempting to complete auth.
        const url = new URL(window.location.toString());
        if (url.search !== "" && !url.searchParams.has("error") && !url.searchParams.has("access_token")) {
            console.log("Completed sign out");
            this.props.navigateToApp();
        } else if (isRefreshPending) {
            localStorage.setItem("MSA_REFRESH_COMPLETE", window.location.toString());
            if (window.refreshCompleteCallback) {
                window.refreshCompleteCallback(window.location.toString());
            }
        } else {
            let authRedirectedCompletedPayload: AuthRedirectCompletedPayload = {
                url: window.location.toString(),
                cookies: this.props.cookies
            };

            this.props.authRedirectCompleted(authRedirectedCompletedPayload);
        }
    }

    componentDidUpdate(prevProps: Readonly<AuthRedirectProps>): void {
        if (this.props.currentUser) {
            telemetryService.setAuthenticatedUserContext(this.props.currentUser.xuid);
            this.props.navigateToApp();
        } else if (this.props.authFailure) {
            this.props.navigateToApp();
        }
    }

    public render() {
        return (
            <CenteredColumnDiv>
                <GenericLoading/>
                <TimeSensitiveFeedbackPrompt timer={15}/>
            </CenteredColumnDiv>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        navigateToApp: () => dispatch(push("/app")),
        authRedirectCompleted: (payload: AuthRedirectCompletedPayload) => dispatch(AuthActions.authRedirectCompletedWeb(payload)),
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return {
        currentUser: state.authentication.currentUser,
        authFailure: state.authentication.authFailure
    };
};

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthRedirectComplete));
