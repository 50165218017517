import styled from "styled-components";
import { Menu } from "antd";

export const ClubMenuItem = styled(Menu.Item)`
    padding-left: 24px !important;
    margin: 0px !important;
    min-height: 40px;
`;

export const MenuItemIcon = styled.span`
    font-size: 1.5em;
    margin-left: .4em;
    margin-right: .1em;
    float: left;
`;

export const MenuItemLabel = styled.div`
    font-size: 1.2em;
    position: relative;
    left: 1.6em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
