import * as React from "react";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { AuthActions, ReduxState } from "@xbox/social-redux";
import { push } from "react-router-redux";
import { User } from "@xbox/social-core";
import { telemetryService } from "../index";

interface LoginProps {
    currentUser: User;
    navigateToApp: () => void;
    authenticate: (cookies: Cookies) => void;
    cookies: Cookies;
}

class Login extends React.Component<LoginProps, {}> {
    componentDidMount() {
        if (!this.props.currentUser) {
            this.props.authenticate(this.props.cookies);
        }
    }

    componentDidUpdate(prevProps: Readonly<LoginProps>): void {

        if (this.props.currentUser) {
            telemetryService.setAuthenticatedUserContext(this.props.currentUser.xuid);
            this.props.navigateToApp();
        }
    }

    public render() {
        telemetryService.trackPageView("Login");
        return (<div/>);
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        navigateToApp: () => dispatch(push("/app")),
        authenticate: (cookies: Cookies) => dispatch(AuthActions.authenticate(cookies))
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return {
        currentUser: state.authentication.currentUser
    };
};

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login));
