import * as React from "react";
import { List } from "antd";
import { Mediahub, ContentLocatorType } from "@xbox/social-core";
import * as style from "../../FeedTools.Styles";
import { Button } from "../../../../common/generic/Button";
import { MediaType } from "../../FeedToolsTypes";
import { Localize } from "../../../../common/translation/Localize";
import { GenericVideoPlayer } from "../../../../common/GenericElements";
import { getThumbnailLocator } from "../../Utils";

interface ClipItemsProps {
    clips?: Mediahub.MediahubSearchResult;
    onClick: (item: Mediahub.MediahubItem, itemType: MediaType) => void;
    selectedItemId: string | null;
}

export const ClipItems = (props: ClipItemsProps) => {
    if (!props.clips) {
        return (
            <div>
                {Localize("postPreview.noData")}
            </div>
        );
    }

    const pad = (val: number) => {
        return ("0" + val).slice(-2);
    };

    const secToHHMMSS = (seconds: number) => {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds = seconds % 60;
        minutes = minutes % 60;
        return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    };

    return (
        <List
            grid={{ gutter: 12, xs: 2, sm: 3, md: 4 }}
            dataSource={props.clips.values}
            loading={false}
            renderItem={(item: Mediahub.MediahubItem) => {
                let videoUri = getThumbnailLocator(item.contentLocators, ContentLocatorType.Download, "/video-thumbnail-unavailable.png");
                let videoThumbUri = getThumbnailLocator(item.contentLocators, ContentLocatorType.ThumbSmall);
                return (
                    <List.Item>
                        <Button
                            onClick={() => props.onClick(item, MediaType.Gameclip)}
                            buttonType={props.selectedItemId === item.contentId ? "primary" : "default"}
                            style={{width: "100%"}}
                        >
                            <style.XblItemTitle>{item.titleName}</style.XblItemTitle>
                            <style.GameclipItemContainer>
                                <GenericVideoPlayer
                                    maxHeight={110}
                                    downloadUri={videoUri}
                                    posterUrl={videoThumbUri}
                                />
                            </style.GameclipItemContainer>
                            <div>
                                {Localize("feedtools.duration", { duration: secToHHMMSS(item.durationInSeconds ? item.durationInSeconds : 0) })}
                                <br />
                                {item.resolutionWidth && item.resolutionHeight ? `${item.resolutionWidth}x${item.resolutionHeight}` : ""}
                            </div>
                        </Button>
                    </List.Item>
                );
            }}
        />
    );
};
