import * as React from "react";
import { ContentLocatorType, ActivityFeed } from "@xbox/social-core";
import * as FeedItemStyles from "./FeedItem.Styles";
import { Localize } from "../../../common/translation/Localize";
import { GenericVideoPlayer } from "../../../common/GenericElements";
import { parseMediaLocator } from "./FeedItemsUtils";
import { MediaOff, CookieConsent } from "../../CookieConsent";
import Util from "../../../common/Util";

interface FeedImagePreViewProps {
    item: ActivityFeed.FeedItem;
    closeImg: any;
}

export default function FeedImagePreview(props: FeedImagePreViewProps): JSX.Element | null {
    let locators: any[] = props.item.gameMediaContentLocators;
    if (locators) {
        let downloadLocator = parseMediaLocator(locators, ContentLocatorType.Download, "");
        if (downloadLocator.uri) {
            switch (props.item.activityItemType) {
                case ActivityFeed.ActivityItemTypes.Screenshot:
                    return <FeedItemStyles.FeedPreviewMedia alt="previewImage" src={downloadLocator.uri} onClick={props.closeImg} />;

                case ActivityFeed.ActivityItemTypes.GameDVR:
                    let thumbnailLocator = parseMediaLocator(locators, ContentLocatorType.ThumbLarge, "/video-thumbnail-unavailable.png");
                    return (
                            <GenericVideoPlayer
                                posterUrl={thumbnailLocator.uri}
                                downloadUri={downloadLocator.uri}
                                maxWidth={760}
                            />
                    );
                default:
                    break;
            }
        }
    }

    // mostly WebLinks + StoreLinks
    if (props.item.itemImage) {
        if (!CookieConsent.isXboxUrl(props.item.itemImage) && !CookieConsent.getSocialMediaConsent()) {
            return <MediaOff />; // Modal
        }
        return <FeedItemStyles.FeedPreviewMedia alt="previewImage" src={Util.ensureSSLImage(props.item.itemImage)} onClick={props.closeImg} />;
    }

    // image is not available
    return <FeedItemStyles.TextContainer onClick={props.closeImg}>{Localize("warnings.noPreview")}</FeedItemStyles.TextContainer>;
}
