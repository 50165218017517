"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MsaTicketCache = void 0;

var InMemoryMsaTicketCache_1 = require("./InMemoryMsaTicketCache");

var MsaTicketSet_1 = require("./MsaTicketSet");

var LoadUser_1 = require("./operations/cache/LoadUser");

var OperationQueue_1 = require("./operations/OperationQueue");

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var WriteTicketSet_1 = require("./operations/cache/WriteTicketSet");

var StorageCacheHelpers_1 = require("./StorageCacheHelpers");

var LoadSessionInfo_1 = require("./operations/cache/LoadSessionInfo");

var MsaTicketCache =
/** @class */
function () {
  function MsaTicketCache(authConfig, storage, telemetryClient, isWeb) {
    this.authConfig = authConfig;
    this.storage = storage;
    this.telemetryClient = telemetryClient;
    this.isWeb = isWeb;
    this.memoryCache = new InMemoryMsaTicketCache_1.InMemoryMsaTicketCache(authConfig);
    this.queue = new OperationQueue_1.OperationQueue();
  }

  MsaTicketCache.makeDefaultUserKey = function (authConfig) {
    return "Xal." + authConfig.titleId + "." + authConfig.environment + ".Default";
  };

  MsaTicketCache.makeTicketSetKey = function (authConfig, userId) {
    return "Xal." + authConfig.titleId + "." + authConfig.environment + ".Msa." + userId;
  };

  MsaTicketCache.makeSessionInfoKey = function (authConfig) {
    return "Xal." + authConfig.titleId + "." + authConfig.environment + ".SisuSessionInfo";
  };

  MsaTicketCache.prototype.loadDefaultUser = function () {
    var operation = LoadUser_1.LoadUser.Default(this.telemetryClient, this.authConfig, this.memoryCache, this.storage, this.isWeb);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  MsaTicketCache.prototype.loadUser = function (msaUserId) {
    var operation = LoadUser_1.LoadUser.WithId(this.telemetryClient, this.authConfig, this.memoryCache, this.storage, msaUserId, this.isWeb);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  MsaTicketCache.prototype.getUser = function (msaUserId) {
    return this.memoryCache.getUser(msaUserId);
  };

  MsaTicketCache.prototype.writeToken = function (msaTicketSet, msaRequestParms, authData, isWeb) {
    var resultTicketSet = undefined;

    if (msaTicketSet) {
      var u = this.memoryCache.getUser(msaTicketSet.userId);

      if (u !== msaTicketSet) {
        throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.Unknown, "Attempting to update a ticket set that does not exist in the ticket cache.");
      }

      resultTicketSet = msaTicketSet;
    } else {
      resultTicketSet = this.memoryCache.getUser(authData.userId);
    }

    var result;

    if (!resultTicketSet) {
      result = MsaTicketSet_1.MsaTicketSet.makeNewUserFromResponse(authData, msaRequestParms, isWeb);
      this.memoryCache.insertToken(result.ticketSet);
    } else {
      result = {
        ticketSet: resultTicketSet,
        scopedTicket: resultTicketSet.updateTicketSet(authData, msaRequestParms, isWeb)
      };
    }

    var operation = new WriteTicketSet_1.WriteTicketSet(this.telemetryClient, this.authConfig, this.storage, result);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  MsaTicketCache.prototype.clearTokensForUser = function (msaUserId) {
    this.memoryCache.clearTokensForUser(msaUserId);
    var key = MsaTicketCache.makeTicketSetKey(this.authConfig, msaUserId);
    var operation = new StorageCacheHelpers_1.ClearCacheData(this.telemetryClient, this.storage, key);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  MsaTicketCache.prototype.writeSessionInfo = function (sessionInfo) {
    this.memoryCache.insertSessionInfo(sessionInfo);
    var key = MsaTicketCache.makeSessionInfoKey(this.authConfig);
    var operation = new StorageCacheHelpers_1.WriteCacheData(this.telemetryClient, this.storage, key, JSON.stringify(sessionInfo));
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  MsaTicketCache.prototype.readSessionInfo = function () {
    return __awaiter(this, void 0, void 0, function () {
      var key, operation;
      return __generator(this, function (_a) {
        key = MsaTicketCache.makeSessionInfoKey(this.authConfig);
        operation = new LoadSessionInfo_1.LoadSesionInfo(this.telemetryClient, this.memoryCache, this.storage, key);
        this.queue.queueOperation(operation);
        return [2
        /*return*/
        , operation.completionPromise];
      });
    });
  };

  MsaTicketCache.prototype.clearSessionInfo = function () {
    this.memoryCache.clearSessionInfo();
    var key = MsaTicketCache.makeSessionInfoKey(this.authConfig);
    var operation = new StorageCacheHelpers_1.ClearCacheData(this.telemetryClient, this.storage, key);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  return MsaTicketCache;
}();

exports.MsaTicketCache = MsaTicketCache;