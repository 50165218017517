import * as React from "react";
import { Input } from "antd";
import { ClubHub } from "@xbox/social-core";
import { PostHydrationDataStates } from "@xbox/social-views";
import { UploadMediaItemsPayload, FetchMediaItemsPayload, MsStoreGameInfo } from "@xbox/social-redux";

import * as fts from "../FeedTools.Styles";
import { Localize } from "../../../common/translation/Localize";
import { GenericDisplayMode, Icon } from "../../../common/GenericElements";
import { Glyphs } from "../../../common/Generic.Icons";
import { XBLMediaPicker } from "./XBLMediaPicker";
import { UploadMediaPicker } from "./UploadMediaPicker";
import { ClubCanActPermissions, MediaType } from "../FeedToolsTypes";
import { XBLMediaChoices } from "../Utils";

const Search = Input.Search;

interface ChooseMediaProps {
    fetchMediaItems: (payload: FetchMediaItemsPayload, isVideo: boolean) => void;
    uploadMediaItems: (data: UploadMediaItemsPayload, isVideo: boolean) => void;
    setMediaItem: (locator: string, thumbUrl: string, downloadUri?: string, mediaType?: MediaType) => void;
    setXBLScreenshotContentId?: (contentID: string) => void;
    getAllTitles?: () => void;
    permissions?: ClubCanActPermissions;
    hydrationStates: PostHydrationDataStates;
    showWebLinkOption: boolean;
    showItems: XBLMediaChoices;
    club?: ClubHub.Club;
    preSelectedGame?: MsStoreGameInfo;
}

interface ChooseMediaContainerState {
    showMediaPickers: boolean;
    showPreview: boolean;
    imagePreview: string;
    downloadUri?: string;
    title: string;
}

export class ChooseMediaContainer extends React.Component<ChooseMediaProps, ChooseMediaContainerState> {
    constructor(props: ChooseMediaProps) {
        super(props);
        this.state = {
           showMediaPickers: true,
           showPreview: false,
           imagePreview: "",
           downloadUri: "",
           title: "",
        };
    }

    resetState = () => {
        this.setState({
            showMediaPickers: true,
            showPreview: false,
            imagePreview: "",
            downloadUri: "",
            title: "",
        });
    }

    setXBLMediaItem = (locator: string, previewUrl: string, downloadUri?: string, mediaType?: MediaType, contentID?: string) => {
        this.setState({imagePreview: previewUrl, showPreview: previewUrl !== "", downloadUri: downloadUri});
        this.props.setMediaItem(locator, previewUrl, downloadUri, mediaType);
        if (contentID && this.props.setXBLScreenshotContentId) {
            this.props.setXBLScreenshotContentId(contentID);
        }
    }

    onChangeImageLink = (e: any) => {
        this.setState({imagePreview: e.target.value});
    }

    onChangeImageEnter = () => {
        this.setState({showPreview: true});
        this.props.setMediaItem("", this.state.imagePreview, this.state.imagePreview, MediaType.Screenshot);
    }

    onDeleteImageClick = () => {
        this.resetState();
        this.props.setMediaItem("", "");
        if (this.props.setXBLScreenshotContentId) {
            this.props.setXBLScreenshotContentId("");
        }
    }

    render() {
        let permissions = this.props.permissions;
        if (!permissions) {
            return <div/>;
        }

        return(
            <div>
                {Localize("feedtools.postMedia")}
                <div style={{display: GenericDisplayMode(permissions.postMediaFromXblLibrary && !this.state.showPreview)}}>
                    <XBLMediaPicker
                        permissions={this.props.permissions}
                        hydrationStates={this.props.hydrationStates}
                        fetchMediaItems={this.props.fetchMediaItems}
                        setXBLMediaItem={this.setXBLMediaItem}
                        showItems={this.props.showItems}
                    />
                </div>

                <div style={{display: GenericDisplayMode(permissions.postMediaFromDevice && !this.state.showPreview)}}>
                    <UploadMediaPicker
                        uploadMediaItems={this.props.uploadMediaItems}
                        setXBLMediaItem={this.setXBLMediaItem}
                        getAllTitles={this.props.getAllTitles}
                        titleList={this.props.hydrationStates.titleList}
                        uploadState={this.props.hydrationStates.upload}
                        uploadItems={this.props.showItems}
                        club={this.props.club}
                        preSelectedGame={this.props.preSelectedGame}
                    />
                </div>

                <div style={{display: GenericDisplayMode(this.props.showWebLinkOption && permissions.postWebLink && !this.state.showPreview)}}>
                    <Search
                        style={{margin: "8px 0", maxWidth: "300px", display: "block"}}
                        onChange={this.onChangeImageLink}
                        value={this.state.imagePreview}
                        placeholder={Localize("weblinkPostDetails.urlImagePlaceholder")}
                        onPressEnter={this.onChangeImageEnter}
                        onSearch={this.onChangeImageEnter}
                        enterButton={true}
                        aria-label="input"
                    />
                    <fts.Description>
                        {Localize("feedtools.mediaSectionInfo")}
                    </fts.Description>
                </div>

                <fts.RelativeDiv style={{display: GenericDisplayMode(this.state.showPreview), maxWidth: "240px"}}>
                    <fts.FitImage
                        src={this.state.imagePreview}
                        alt={`media thumbnail for title: ${this.state.title}`}
                    />
                    <fts.DeleteImageButton iconOnly={true} onClick={this.onDeleteImageClick}>
                        <Icon type={Glyphs.Delete}/>
                    </fts.DeleteImageButton>
                </fts.RelativeDiv>
            </div>
        );
    }
}
