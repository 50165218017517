"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagService = void 0;

var inversify_1 = require("inversify");

var http_1 = require("@xbox/http");

var typings_1 = require("../../typings");

var utils = require("../../utils/Utils");

var TagService =
/** @class */
function () {
  function TagService(xblClient, appConfig, localStorageService) {
    this.xblClient = xblClient;
    this.appConfig = appConfig;
    this.localStorageService = localStorageService;
  }

  TagService.prototype.getTags = function () {
    return __awaiter(this, void 0, void 0, function () {
      var localTags, tagsUri, response, tags_1, responseTags;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getTagsFromCache()];

          case 1:
            localTags = _a.sent();
            if (!localTags) return [3
            /*break*/
            , 2];
            return [2
            /*return*/
            , localTags];

          case 2:
            tagsUri = "https://editorial.xboxlive.com/Site/XboxLiveFeeds/Content/systemTags/xboxTags";
            return [4
            /*yield*/
            , this.xblClient.sendRequest({
              method: http_1.Http.HttpRequestMethod.Get,
              url: tagsUri,
              headers: utils.GetGenericHeaders(1)
            })];

          case 3:
            response = _a.sent();
            tags_1 = [];
            responseTags = response.data["systemTagGroupList.list"];
            responseTags.forEach(function (tagGroup) {
              var newTagGroup = {
                tagGroupName: tagGroup["systemTagGroup.name"],
                tags: tagGroup["systemTagGroup.Tags"].map(function (tag) {
                  return {
                    emoji: tag["systemTag.tagEmoji"],
                    tagName: tag["systemTag.tagName"],
                    tagId: tag["systemTag.tagID"]
                  };
                })
              };
              tags_1.push(newTagGroup);
            });
            this.saveTagsToCache(tags_1);
            return [2
            /*return*/
            , tags_1];
        }
      });
    });
  };

  TagService.prototype.hyrdrateTags = function (tagIds) {
    return __awaiter(this, void 0, void 0, function () {
      var tags, combinedTags, hyrdrateTags;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getTags()];

          case 1:
            tags = _a.sent();
            combinedTags = [];
            tags.forEach(function (tagGroup) {
              combinedTags = combinedTags.concat(tagGroup.tags);
            });
            hyrdrateTags = [];
            tagIds.forEach(function (tagId) {
              var hydratedTag = combinedTags.find(function (t) {
                return t.tagId === tagId;
              });

              if (hydratedTag) {
                hyrdrateTags.push(hydratedTag);
              } else {
                var newTag = {
                  emoji: "",
                  tagId: tagId,
                  tagName: tagId
                };
                hyrdrateTags.push(newTag);
              }
            });
            return [2
            /*return*/
            , hyrdrateTags];
        }
      });
    });
  }; // Read from cache


  TagService.prototype.getTagsFromCache = function () {
    return __awaiter(this, void 0, void 0, function () {
      var json, tags;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.localStorageService.getItem("system_tags")];

          case 1:
            json = _a.sent();

            if (!json) {
              return [2
              /*return*/
              , null];
            }

            tags = JSON.parse(json);
            return [2
            /*return*/
            , tags];
        }
      });
    });
  }; // Store to cache


  TagService.prototype.saveTagsToCache = function (value) {
    return this.localStorageService.setItem("system_tags", JSON.stringify(value));
  };

  TagService = __decorate([inversify_1.injectable(), __param(0, inversify_1.inject(typings_1.XSocialSymbols.IXboxLiveRequestService)), __param(1, inversify_1.inject(typings_1.XSocialSymbols.IAppConfig)), __param(2, inversify_1.inject(typings_1.XSocialSymbols.ILocalStorageService)), __metadata("design:paramtypes", [Object, Object, Object])], TagService);
  return TagService;
}();

exports.TagService = TagService;