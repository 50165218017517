import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";
import { BallotResultAvailability, ActivityFeed } from "@xbox/social-core";

import * as Style from "./Ballot.Styles";
import { ActivityFeedItem, FeedItemProps } from "./base/ActivityFeedItem";
import { Localize } from "../../common/translation/Localize";
import { GetDateTimeDiffUntil } from "../../Utils";
import { PollVisibilityState } from "./base/FeedItemsUtils";
import { PreviewFeedItemProps, PreviewActivityFeedItem } from "./base/PreviewActivityFeedItem";
import { TextContainer } from "./base/FeedItem.Styles";
import { Glyphs } from "../../common/Generic.Icons";

export class BallotFeedItem extends ActivityFeedItem {
    private pollIsOpen: boolean = true;
    private totalVotes: number = 0;
    private winningChoice: number = -1;

    constructor(props: FeedItemProps) {
        super(props);
    }

    componentDidMount() {
        let pollData = this.props.item.pollData;
        if (pollData) {
            this.updateData(pollData);
        }
    }

    componentDidUpdate(prevProps: any) {
        let pollData = this.props.item.pollData;
        if (pollData && pollData !== prevProps.item.pollData) {
            this.updateData(pollData);
        }
    }

    openBallotInlineModal = (e: any) => {
        this.setState({ showBallotInlineModal: true });
        e.stopPropagation();
    }

    closeBallotInlineModal = (e: any) => {
        this.setState({ showBallotInlineModal: false });
        e.stopPropagation();
    }

    updateData(pollData: ActivityFeed.BallotData) {
        this.defaultVisibility(pollData.myChoice, pollData.endTime);
        this.totalVotes = _.sumBy(pollData.choices, function (choice: ActivityFeed.VoteChoice) { return choice.numVotes; });
    }

    defaultVisibility = (choice: number, closeTime: Date) => {
        let pollIsOpen = moment().isBefore(closeTime);
        let showBallot = false;
        let showResults = true;
        if (pollIsOpen) {
            showBallot = choice < 0;
            if (BallotResultAvailability[this.props.item.pollData!.visible].toString() === BallotResultAvailability.AfterClose.toString()) {
                showResults = false;
            } else {
                showResults = !showBallot;
            }

            if (showResults || this.props.enableAdminView) {
                this.setState({ showPollState: PollVisibilityState.SHOW_RESULTS });
            } else if (showBallot) {
                this.setState({ showPollState: PollVisibilityState.SHOW_BALLOT });
            } else {
                this.setState({ showPollState: PollVisibilityState.SHOW_PRECLOSED });
            }
        } else {
            let max = 0;
            for (var i = 0; i < this.props.item.pollData!.choices.length; i++) {
                if (this.props.item.pollData!.choices[i].numVotes > max) {
                    max = this.props.item.pollData!.choices[i].numVotes;
                    this.winningChoice = i;
                }
            }
            this.setState({ showPollState: PollVisibilityState.SHOW_RESULTS });
        }
    }

    resetState = () => {
        let pollData = this.props.item.pollData;
        if (pollData && pollData !== this.props.item.pollData) {
            this.updateData(pollData);
        }
    }

    castVote = (choice: number) => {
        if (choice >= 0 && this.props.socialActions) {
            this.props.socialActions.vote!(this.props.item, choice);
        }
    }

    forceSwapVisibility = () => {
        if (this.state.showPollState === PollVisibilityState.SHOW_BALLOT) {
            this.setState({ showPollState: PollVisibilityState.SHOW_RESULTS });
        } else {
            this.setState({ showPollState: PollVisibilityState.SHOW_BALLOT });
        }
    }

    /****************************************************************************************************************
    * COMPONENT METHODS START
    ****************************************************************************************************************/

    getBallot = (isFullView: boolean = true) => {
        if (!this.props.item.pollData) {
            return <div />;
        }
        let includeResults = BallotResultAvailability[this.props.item.pollData.visible].toString() === BallotResultAvailability.BeforeVoting.toString();

        return this.getChoices(this.props.item.pollData.choices, includeResults, this.totalVotes, isFullView);
    }

    getChoices(choices: ActivityFeed.VoteChoice[], includeResults: boolean, totalVotes: number, isFullView: boolean = true) {
        let resultList: JSX.Element[] = [];
        for (let i = 0; i < choices.length; i++) {
            let val = choices[i];
            let percentage: number = includeResults && totalVotes ? ((val.numVotes / totalVotes) * 100) : 0;

            resultList.push(
                <Style.BallotButtonWrapper key={i}>
                    <Style.BallotButton
                        className="BallotButton"
                        value={i}
                        style={isFullView ? {wordBreak: "break-word"} : {textOverflow: "ellipsis", overflow: "hidden"}}
                        onClick={() => this.castVote(i)}
                        disabled={this.props.enableAdminView}
                    >
                        {val.choice}
                    </Style.BallotButton>
                    {includeResults && <Style.PercentageText>{percentage + "%"}</Style.PercentageText>}
                </Style.BallotButtonWrapper>
            );
        }
        return resultList;
    }

    getResults = (isFullView: boolean = true) => {
        if (!this.props.item.pollData) {
            return <div/>;
        }

        let pollData = this.props.item.pollData;
        let resultList: JSX.Element[] = [];

        for (let i = 0; i < pollData.choices.length; i++) {
            let val = pollData.choices[i];
            let percentage: number = this.totalVotes ? Math.round(((val.numVotes / this.totalVotes) * 100)) : 0;
            let result = isFullView
                            ? this.getSingleFullResult(val.choice, percentage, pollData.myChoice === i, !this.pollIsOpen && this.winningChoice !== i, i)
                            : this.getSingleCompactResult(val.choice, percentage, pollData.myChoice === i, i);
            resultList.push(result);
        }

        return resultList;
    }

    getSingleFullResult = (choiceName: string, choiceValue: number, isMyChoice: boolean, isWinner: boolean, key: number) => {
        let barStyle = isMyChoice
                        ? { width: Math.max(2, choiceValue) + "%", background: `#${this.props.item.authorInfo.preferredColor.primaryColor}` }
                        : { width: Math.max(2, choiceValue) + "%" };

        return (
            <Style.FullResultDiv key={key} selected={isWinner}>
                {choiceName}
                <div style={{display: "flex"}}>
                    <Style.BallotGraphBar style={barStyle} />
                    <Style.PercentageText>{choiceValue + "%"}</Style.PercentageText>
                </div>
            </Style.FullResultDiv >
        );
    }

    getSingleCompactResult = (choiceName: string, choiceValue: number, isMyChoice: boolean, key: number) => {
        return (
            <Style.GridResultDiv className="GridResultDiv" key={key} selected={isMyChoice}>
                <div style={{textOverflow: "ellipsis", overflow: "hidden"}}>{choiceName}</div>
                <Style.PercentageText>{choiceValue + "%"}</Style.PercentageText>
            </Style.GridResultDiv>
        );
    }

    resultsAfterClose = () => {
        let myChoice;
        if (this.props.item.pollData && this.props.item.pollData.myChoice > -1) {
            myChoice = this.props.item.pollData.choices[this.props.item.pollData.myChoice].choice;
        }
        return (
            <div
                className="resultsAfterClose"
                onClick={(event) => event.stopPropagation()}
                style={{textAlign: "center", margin: "5px"}}
            >
                <span>{Localize("postFeedItem.ballot.thankYou")}</span>
                <span>{Localize("postFeedItem.ballot.resultsShowAfterClose")}</span>
                {
                    myChoice
                    && <div>{Localize("postFeedItem.ballot.yourChoice")}<b>{myChoice}</b></div>
                }
            </div>
        );
    }

    getTimeTillPollClose = () => {
        let pollCloseMessage: JSX.Element = <Style.GreenText>{Localize("postFeedItem.ballot.pollClosed")}</Style.GreenText>;
        if (this.props.item.pollData && moment().isBefore(this.props.item.pollData.endTime)) {
            pollCloseMessage = <span>{GetDateTimeDiffUntil(moment(this.props.item.pollData.endTime))}</span>;
        }
        return pollCloseMessage;
    }

    getDataDisplay = (isFullView: boolean = true) => {
        let pollDataToDisplay: JSX.Element|JSX.Element[] = <div />;
        switch (this.state.showPollState) {
            case PollVisibilityState.SHOW_BALLOT:
                pollDataToDisplay = this.getBallot(isFullView);
                break;
            case PollVisibilityState.SHOW_RESULTS:
                pollDataToDisplay = this.getResults(isFullView);
                break;
            case PollVisibilityState.SHOW_PRECLOSED:
            default:
                pollDataToDisplay = this.resultsAfterClose();
                break;
        }
        return pollDataToDisplay;
    }

    /*********************************************************************/
    /* Common redefinition
    /*********************************************************************/
    onOpenImage = (e: any) => {
        // it's a poll "icon" - propagate click further
    }

    /*********************************************************************/
    /* Full Base Template
    /*********************************************************************/
    useFullOnLoadOnMainDiv = (): boolean => true;
    getFullTextContainer = (): JSX.Element | null => {
        let question = this.props.item.pollData ? this.props.item.pollData.pollQuestion : this.props.item.description;

        return <TextContainer style={{marginTop: "10px"}}>{question}</TextContainer>;
    }

    getFullFeedItemData = (): JSX.Element | null => {
        return (
            <div>
                <Style.FullBallotContent className="FullBallotContent" onClick={(e) => e.stopPropagation()}>
                    {this.getDataDisplay()}
                </Style.FullBallotContent>
                <Style.FullBallotFooter>
                    <span>{Localize("postFeedItem.ballot.pollResults").replace("${v}", this.totalVotes)}</span>
                    {this.getTimeTillPollClose()}
                </Style.FullBallotFooter>
            </div>
        );
    }

    /*********************************************************************/
    /* Inline Template
    /*********************************************************************/

    getInlinePostGlyph = () => {
        return Glyphs.Poll;
    }

    getInlinePostText = () => {
        return this.props.item.pollData && this.props.item.pollData.pollQuestion
            ? this.props.item.pollData.pollQuestion
            : this.props.item.description;
    }

    getInlineSecondaryContent = () => {
        return this.getTimeTillPollClose();
    }

    getInlineButton = () => {
        if (this.props.item.pollData && this.props.socialActions) {
            let btnText = this.props.item.pollData.myChoice === -1 && this.props.item.pollData.canVote
                ? Localize("postFeedItem.ballot.vote")
                : Localize("postFeedItem.ballot.results");
            return (
                <span>
                    <Style.InlineVoteButton buttonType="primary" onClick={this.openBallotInlineModal}>
                        {btnText}
                    </Style.InlineVoteButton>
                    {this.state.showBallotInlineModal ? this.getBallotInlineModal() : null}
                </span>
            );
        }
        return null;
    }

    getBallotInlineModal = () => {
        return (
            <Style.FullItemModal visible={this.state.showBallotInlineModal} onCancel={this.closeBallotInlineModal} >
                {this.getFullItemView()}
            </Style.FullItemModal>
        );
    }

    /*********************************************************************/
    /* Grid Base Template
    /*********************************************************************/
    getGridImageUrl = () => {
        return "";
    }

    getGridNonImageContent = (): JSX.Element | null => {
        return (
            <Style.GridContentDiv>
                <Style.GridHeader>{this.getInlinePostText()}</Style.GridHeader>

                <Style.GridBallotContent
                    className="GridBallotContent"
                    onClick={(e) => e.stopPropagation()}
                >
                    {this.getDataDisplay(false)}
                </Style.GridBallotContent>

                <Style.GridBallotFooter>
                    <span>{Localize("postFeedItem.ballot.pollResults").replace("${v}", this.totalVotes)}</span>
                    {this.getTimeTillPollClose()}
                </Style.GridBallotFooter>
            </Style.GridContentDiv>
        );
    }
}

/****************************************************************************************************************
* PREVIEW COMPONENT START
****************************************************************************************************************/

export interface PreviewBallotFeedItemProps extends PreviewFeedItemProps {
    choices: string[];
    endTime: moment.Moment;
    visbility: BallotResultAvailability;
}

export const PreviewBallotFeedItem: React.SFC<PreviewBallotFeedItemProps> = props => {
    let resultList: JSX.Element[] = [];
    let includeResults = props.visbility.toString() === BallotResultAvailability.BeforeVoting.toString();
    for (let i = 0; i < props.choices.length; i++) {
        let val = props.choices[i];
        if (val !== "") {
            resultList.push(
                <Style.BallotButtonWrapper key={i}>
                    <Style.BallotButton value={i} style={{ pointerEvents: "none" }}>
                        {val}
                    </Style.BallotButton>
                    {includeResults && <Style.PercentageText>{"0%"}</Style.PercentageText>}
                </Style.BallotButtonWrapper>
            );
        }
    }

    let previewContent = (
        <div>
            <TextContainer style={{marginTop: "10px"}}>{props.itemText}</TextContainer>
            <Style.FullBallotContent onClick={(e) => e.stopPropagation()}>
                {resultList}
            </Style.FullBallotContent>
            <div style={{textAlign: "end"}}>
                {GetDateTimeDiffUntil(props.endTime)}
            </div>
        </div>
    );

    return <PreviewActivityFeedItem previewContent={previewContent} {...props} />;
};
