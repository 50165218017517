"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.engineContainer = void 0; // file inversify.config.ts

var inversify_inject_decorators_1 = require("inversify-inject-decorators");

var inversify_1 = require("inversify");

var typings_1 = require("./typings");

var repositories_1 = require("./repositories");

var services_1 = require("./services");

var http_1 = require("@xbox/http");

var XboxLiveAuthenticator_1 = require("./interceptors/XboxLiveAuthenticator");

var CorrelationVectorInterceptor_1 = require("./interceptors/CorrelationVectorInterceptor");

var InMemoryLogger_1 = require("./utils/InMemoryLogger");

var engineContainer = new inversify_1.Container();
exports.engineContainer = engineContainer; // default bindings

engineContainer.bind(typings_1.XSocialSymbols.IXboxLiveRequestService).toDynamicValue(function (context) {
  var xboxLiveAuthenticator = context.container.resolve(XboxLiveAuthenticator_1.XboxLiveAuthenticator);
  var httpRequestServiceBuilder = new http_1.HttpRequestServiceBuilder().addInterceptor(new CorrelationVectorInterceptor_1.CorrelationVectorInterceptor()).addAuthenticator(xboxLiveAuthenticator);
  return httpRequestServiceBuilder.build();
}).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IHttpRequestService).toDynamicValue(function (context) {
  var httpRequestServiceBuilder = new http_1.HttpRequestServiceBuilder().addInterceptor(new CorrelationVectorInterceptor_1.CorrelationVectorInterceptor());
  return httpRequestServiceBuilder.build();
}).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IGraphQLClient).to(services_1.GraphQLClientProxy).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IBallotBoxService).to(services_1.BallotBoxService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IActivityFeedService).to(services_1.ActivityFeedService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IClubHubService).to(services_1.ClubHubService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IMpsdPartyService).to(services_1.MpsdPartyServiceProxy).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IRtaConnectionFactory).to(services_1.RtaConnectionFactory).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IPartyRtcClient).to(services_1.PartyRtcClient).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IPartyService).to(services_1.PartyService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IProfileService).to(services_1.ProfileServiceProxy).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IPeopleHubService).to(services_1.PeopleHubServiceProxy).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IPeopleRepository).to(repositories_1.PeopleRepository);
engineContainer.bind(typings_1.XSocialSymbols.ICommentService).to(services_1.CommentService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IUserManager).to(services_1.UserManager).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IExperimentationService).to(services_1.ExperimentationService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IAnalyticsService).to(services_1.AnalyticsService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IMediaHubService).to(services_1.MediaHubService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.ITitleHubService).to(services_1.TitleHubService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IFeedbackService).to(services_1.FeedbackService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IImagePickerService).to(services_1.ImagePickerService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.ILogger).to(InMemoryLogger_1.InMemoryLogger).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.ITagService).to(services_1.TagService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.IPrivacyService).to(services_1.PrivacyService).inSingletonScope();
engineContainer.bind(typings_1.XSocialSymbols.ISocialService).to(services_1.SocialService).inSingletonScope();
var lazyInject = inversify_inject_decorators_1.default(engineContainer).lazyInject;