import * as _ from "lodash";
import * as React from "react";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { ClubHub } from "@xbox/social-core";

import * as cmtStyles from "./ClubMenuTabs.Styles";
import { ClubMenuMode } from "./ClubMenu";
import { CurrentUserContext, withCurrentUserContext } from "../CurrentUserContext";
import { ClubSelectionContext, withClubSelectionContext } from "../ClubSelectionContext";
import { telemetryService } from "../../index";
import { Glyphs } from "../../common/Generic.Icons";
import { Localize } from "../../common/translation/Localize";
import { Icon } from "../../common/GenericElements";
import { ClubpicListItem } from "../../common/generic/ClubpicListItem";
import { IsDevMode } from "../../common/GenericChecks";
import Util from "../../common/Util";

const SubMenu = Menu.SubMenu;

interface ClubMenuTabsStateProps {
}

interface ClubMenuTabsDispatchProps {
    handleNavigation: (route: string) => void;
}

export interface ClubMenuTabsOtherProps {
    mode: ClubMenuMode;
    isOpen: boolean;
    allClubs: ClubHub.Club[];
    currentPath?: string;
    toggleMenuCommand: () => void;
}

type ClubMenuTabsProps = ClubMenuTabsStateProps & ClubMenuTabsDispatchProps & ClubMenuTabsOtherProps & CurrentUserContext & ClubSelectionContext;

export interface ClubMenuTabState {
    managedClubs: ClubHub.Club[];
    officialClubs: ClubHub.Club[];
    socialClubs: ClubHub.Club[];
}

class ClubMenuTabs extends React.Component<ClubMenuTabsProps, ClubMenuTabState> {
    static PRIVACY: string = `/privacy`;

    constructor(props: ClubMenuTabsProps) {
        super(props);

        this.state = {
            managedClubs: [],
            officialClubs: [],
            socialClubs: []
        };
    }

    componentDidMount() {
        if (this.props.allClubs) {
            this.updateClubList();
        }
        window.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps: ClubMenuTabsProps) {
        if (prevProps.allClubs !== this.props.allClubs) {
            this.updateClubList();
        }
    }

    updateClubList = () => {
        let allData = this.props.allClubs.slice();
        // separate to managed and others:
        let managed = _.remove(allData, (club: ClubHub.Club) => club && club.settings!.viewerRoles.roles.indexOf(ClubHub.ClubRoles.Moderator) > -1);
        // separate left-overs to official and others (social):
        let official = _.remove(allData, (club: ClubHub.Club) => club && club.details.isOfficialClub);
        const mainMenu = document.querySelectorAll(".ant-menu-submenu-title") as NodeListOf<Element>;
        mainMenu.forEach((menu) => {
            menu.setAttribute("tabindex", "0");
            menu.setAttribute("role", "button");
            menu.setAttribute("type", "button");
        });
        this.setState({
            managedClubs: managed,
            officialClubs: official,
            socialClubs: allData,
        });
    }

    onClick = (param: ClickParam) => {
        // Allows passing the open menu function down to the child members of the menu
        // and avoids the navigation behavior of the router
        if (param.key === `AlternateFunction` && this.props.toggleMenuCommand) {
            this.props.toggleMenuCommand();
            return;
        }

        if (param.key === this.props.currentPath) {
            // avoid routing on click if already at that route location
            return;
        }

        if (param.keyPath.length > 2 || param.key === "ignore") {
            // this is used to ignore already handled events.
            return;
        }

        let pageRoute = param.key;
        telemetryService.trackEvent("NavigationMenuItemClicked", { "PageName": `/app/${pageRoute}` });
        if (isNaN(parseInt(pageRoute, 10))) {
            this.props.handleNavigation(`/app/${pageRoute}`);
        } else {
            this.props.handleNavigation(`/app/${pageRoute}/discussion/feed`);
        }
    }

    getClubsItems = (clubList: ClubHub.Club[], key: string) => {
        return (
            <SubMenu key={key} title={Localize(`clubMenu.${key}`)}>
                {clubList.map((item: ClubHub.Club) => {
                    return (
                        <cmtStyles.ClubMenuItem key={item.details.id} tabIndex={0}>
                            {this.props.isOpen && item.details.isOfficialClub &&
                                <Icon className="club-badge" type={Glyphs.VerifiedGameClubBackplate}/>
                            }
                            <ClubpicListItem
                                displayPicUrl={Util.ensureSSLImage(item.details.profile.displayImageUrl.value)}
                                displayPicName={this.props.isOpen ? item.details.profile.name.value : ""}
                            />
                        </cmtStyles.ClubMenuItem>
                    );
                })}
            </SubMenu>
        );
    }

    handleKeyDown = (event: KeyboardEvent): void => {
        const activeElement = document.activeElement as HTMLElement;
        if (event.key === "Enter" || activeElement.classList.contains("ant-menu-submenu")) {
                activeElement.click();
        }
    }

    getDefaultItems = (navigationKey: string, glyph: Glyphs, displayKey?: string) => {
        let translationKey = displayKey ? displayKey : navigationKey;
        return (
            <Menu.Item key={navigationKey} tabIndex={0}>
                <cmtStyles.MenuItemIcon><Icon type={glyph} /></cmtStyles.MenuItemIcon>
                <cmtStyles.MenuItemLabel>{Localize(`clubMenu.${translationKey}`)}</cmtStyles.MenuItemLabel>
            </Menu.Item>
        );
    }

    render() {
        return (
            <Menu
                mode={this.props.mode}
                onClick={this.onClick}
                style={{ height: "100%" }}
                selectedKeys={this.props.currentPath ? [this.props.currentPath] : []}
                defaultOpenKeys={[`managed`, `official`, `social`]}
                className="clubMenu"
            >
                {this.getDefaultItems("home/all", Glyphs.Home, "home")}
                {IsDevMode() && this.getDefaultItems("people", Glyphs.PlayerAdd)}
                {IsDevMode() && this.getDefaultItems("discovery", Glyphs.Internet)}

                {this.getClubsItems(this.state.managedClubs, "managed")}
                {this.getClubsItems(this.state.officialClubs, "official")}
                {this.getClubsItems(this.state.socialClubs, "social")}
            </Menu>
        );
    }
}

const mapStateToProps = (state: any): ClubMenuTabsStateProps => {
    return {};
};

const mapDispatchToProps = (dispatch: any): ClubMenuTabsDispatchProps => {
    return {
        handleNavigation: (route: string) => dispatch(push(route))
    };
};

export default withClubSelectionContext(withCurrentUserContext(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClubMenuTabs)));
