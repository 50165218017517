import * as React from "react";
import { Menu, Dropdown, Modal } from "antd";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemAdminActionHanders } from "@xbox/social-views";

import * as FeedItemStyles from "../base/FeedItem.Styles";
import { BaseAdminActionsProps, GetAllowedAdminActions } from "./BaseAdminActions";
import { withClubSelectionContext } from "../../../containers/ClubSelectionContext";
import { withCurrentUserContext } from "../../../containers/CurrentUserContext";
import { Localize } from "../../../common/translation/Localize";
import { Glyphs } from "../../../common/Generic.Icons";
import { Icon } from "../../../common/GenericElements";

const confirm = Modal.confirm;
interface State {
    isExpanded: boolean;
    visible: boolean;
}
export interface AdminActionsProps extends BaseAdminActionsProps {
    adminActions?: IFeedItemAdminActionHanders;
    item: ActivityFeed.FeedItem;
    customAdminActionsMenu?: ActivityFeed.CustomAdminActionMenu;
    style?: React.CSSProperties;
    fetchCommentLock?: (item: ActivityFeed.FeedItem) => void;
}

class AdminActionsContainer extends React.Component<AdminActionsProps, State> {
    lastTriggerEle: any;
    constructor(props: any) {
        super(props);
        this.state = {
            isExpanded: false,
            visible: false
        };
    }

    actionSelected = (e: any) => {
        if (e && e.domEvent) {
            e.domEvent.stopPropagation();
        }

        if (!this.props.adminActions) {
            return;
        }

        const shouldDeleteAndBan = (props: AdminActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.deleteAndBan"),
                content: Localize("activityFeedContainer.deleteAndBanConfirmTitle"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.adminActions) {
                        props.adminActions.delete(props.item, true);
                    }
                }
            });
        };

        const shouldReport = (props: AdminActionsProps) => {
            confirm({
                title: Localize("activityFeedContainer.report"),
                content: Localize("activityFeedContainer.reportConfirmText"),
                okText: Localize("generic.okay"),
                okType: "danger",
                cancelText: Localize("generic.cancel"),
                onOk() {
                    if (props.adminActions) {
                        props.adminActions.report(props.item);
                    }
                }
            });
        };

        let action = e.key;
        switch (action) {
            case ActivityFeed.AdminActionTypes.Delete:
                this.props.adminActions.delete(this.props.item, false);
                break;
            case ActivityFeed.AdminActionTypes.DeleteAndBan:
                shouldDeleteAndBan(this.props);
                break;
            case ActivityFeed.AdminActionTypes.DisableComment:
                this.props.adminActions.disableComment(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.EnableComment:
                this.props.adminActions.enableComment(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.Pin:
                this.props.adminActions.pin(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.UnPin:
                this.props.adminActions.unpin(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.AddToModQ:
                this.props.adminActions.addToModQ(this.props.item);
                break;
            case ActivityFeed.AdminActionTypes.Report:
                shouldReport(this.props);
                break;
            case ActivityFeed.AdminActionTypes.ExportComments:
                if (this.props.adminActions.exportComments) {
                    this.props.adminActions.exportComments(this.props.item.feedItemId);
                }
                break;
            default:
                return;
        }
    }

    getPinAction = () => {
        return (this.props.item.pinned ?
            <Menu.Item key={ActivityFeed.AdminActionTypes.UnPin}>{Localize("activityFeedContainer.unpin")}</Menu.Item> :
            <Menu.Item key={ActivityFeed.AdminActionTypes.Pin}>{Localize("activityFeedContainer.pin")}</Menu.Item>);
    }

    isUserTheOwnerOfPost = (): boolean => {
        if (this.props.user) {
            return this.props.item.authorInfo.xuid === this.props.user.xuid;
        }

        return false;
    }

    getExportCommentsAction = () => {
        let allowedUser: boolean = this.isUserTheOwnerOfPost() || this.props.isModerator || this.props.isOwner;
        if (this.props.adminActions && allowedUser && this.props.item.stat && this.props.item.stat.comments > 0) {
            return (
                <Menu.Item key={ActivityFeed.AdminActionTypes.ExportComments}>{Localize("activityFeedContainer.exportComments")}</Menu.Item>
            );
        }

        return null;
    }

    commentActionMenuItem = () => {
        // use this if condition to make sure "disable/enable comment" only appear in the feed viewer page
        if (this.props.isCommentLocked !== undefined) {
            if (this.isUserTheOwnerOfPost() || this.props.isModerator || this.props.isOwner) {
                return (this.props.isCommentLocked ?
                    <Menu.Item key={ActivityFeed.AdminActionTypes.EnableComment}>{Localize("activityFeedContainer.enableComment")}</Menu.Item> :
                    <Menu.Item key={ActivityFeed.AdminActionTypes.DisableComment}>{Localize("activityFeedContainer.disableComment")}</Menu.Item>);
            }
        }
        return null;
    }

    getMenuItems = () => {
        switch (this.props.customAdminActionsMenu) {
            case ActivityFeed.CustomAdminActionMenu.DeleteFeedItemOnly:
                return (
                    <Menu onClick={this.actionSelected}>
                        <Menu.Item key={ActivityFeed.AdminActionTypes.Delete}>
                            {Localize("activityFeedContainer.delete")}
                        </Menu.Item>
                    </Menu>
                );
            default:
                return (
                    GetAllowedAdminActions(this.isUserTheOwnerOfPost(),
                        this.props.isModerator,
                        this.props.isOwner,
                        this.actionSelected,
                        this.props.item.authorInfo.xuid,
                        this.getPinAction,
                        this.getExportCommentsAction,
                        this.commentActionMenuItem,
                        this.props.clubModerators
                    ));
        }
    }

    onClick = (e: any) => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded
        }));
        if (this.props.fetchCommentLock) {
            this.props.fetchCommentLock(this.props.item);
        }
        e.stopPropagation();
    }

    onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            this.lastTriggerEle = e.target;
            this.onClick(e);
            setTimeout(() => {
                const items = document.querySelectorAll(".ant-dropdown-menu-vertical");
                const liListItems = document.querySelectorAll(".ant-dropdown-menu-item");
                if (items.length > 0) {
                    items.forEach((list, index) => {
                        const listItems = items[index].querySelectorAll("li");
                        if (listItems.length > 0) {
                            listItems.forEach(li => li.setAttribute("tabindex", "0"));
                            listItems[0].focus();
                        }
                    });
                }

                liListItems?.forEach((li, index) => {
                    liListItems[index]?.addEventListener("keydown", (event: any) => {
                        const activeElement = document.activeElement as HTMLElement;
                        if (activeElement.classList.contains("ant-dropdown-menu-item")) {
                            if (event.key === "Enter") {
                                (liListItems[index] as HTMLElement).click();
                                this.setState(prevState => ({
                                    isExpanded: false
                                }));
                                this.lastTriggerEle.focus();
                            } else if (event.key === "Escape") {
                                this.setState(prevState => ({
                                    isExpanded: false
                                }));
                                this.lastTriggerEle.focus();
                            } else if (activeElement === liListItems[liListItems.length - 1]) {
                                 if (event.key === "Tab") {
                                    event.preventDefault();
                                    this.setState(prevState => ({
                                        isExpanded: false
                                    }));
                                    this.lastTriggerEle.focus();
                                }
                            }
                        }
                    });
                });

                e.stopPropagation();
            }, );
        }
    }

    render() {
        const { isExpanded } = this.state;
        if (!this.props.showAdminAction || (!this.props.isModerator && !this.isUserTheOwnerOfPost())) {
            return <div />;
        }

        return (
            <Dropdown overlay={this.getMenuItems()} trigger={["click"]} visible={isExpanded}>
                <FeedItemStyles.AdminActionsMenu tabIndex={0} role="button" aria-expanded={isExpanded ? "true" : "false"} aria-label={Localize("postFeedItem.more_options")} className="AdminActionsMenu ant-dropdown-link" onKeyDown={this.onKeyDown} onClick={this.onClick} style={this.props.style}>
                    <Icon type={Glyphs.MoreActions} />
                </FeedItemStyles.AdminActionsMenu>
            </Dropdown>
        );
    }
}

export default withClubSelectionContext(withCurrentUserContext(AdminActionsContainer));
