"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MsrCryptographyFactory = void 0;

var framework_1 = require("@xbox/framework");

var MsrCryptoAdapter_1 = require("./MsrCryptoAdapter");

var keyGenParams = {
  name: "ECDSA",
  namedCurve: "P-256"
};
var ecdsaParams = {
  name: "ECDSA",
  hash: "SHA-256"
};

var MsrEcdsa =
/** @class */
function () {
  function MsrEcdsa(identityKeyPair) {
    this.identityKeyPair = identityKeyPair;
  }

  MsrEcdsa.prototype.serialize = function () {
    return __awaiter(this, void 0, void 0, function () {
      var privateKeyJwk, publicKeyJwk;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.exportKey("jwk", this.identityKeyPair.privateKey)];

          case 1:
            privateKeyJwk = _a.sent();
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.exportKey("jwk", this.identityKeyPair.publicKey)];

          case 2:
            publicKeyJwk = _a.sent();
            return [2
            /*return*/
            , JSON.stringify({
              privateKey: privateKeyJwk,
              publicKey: publicKeyJwk
            })];
        }
      });
    });
  };

  MsrEcdsa.prototype.proofKey = function () {
    return __awaiter(this, void 0, void 0, function () {
      var publicKeyJwk;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.exportKey("jwk", this.identityKeyPair.publicKey)];

          case 1:
            publicKeyJwk = _a.sent();
            return [2
            /*return*/
            , {
              crv: "P-256",
              alg: "ES256",
              use: "sig",
              kty: "EC",
              x: publicKeyJwk.x,
              y: publicKeyJwk.y
            }];
        }
      });
    });
  };

  MsrEcdsa.prototype.signAndHash = function (buffer) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.sign(ecdsaParams, this.identityKeyPair.privateKey, buffer)];

          case 1:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  return MsrEcdsa;
}();

var MsrCryptographyFactory =
/** @class */
function () {
  function MsrCryptographyFactory() {
    MsrCryptoAdapter_1.MsrCryptoAdapter.init();
  }

  MsrCryptographyFactory.prototype.createEcdsaAndDeviceId = function (loadFromCache) {
    return __awaiter(this, void 0, void 0, function () {
      var deviceId, key;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            deviceId = framework_1.guid();
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.generateKey(keyGenParams, true, ["sign", "verify"])];

          case 1:
            key = _a.sent();
            return [2
            /*return*/
            , {
              deviceId: deviceId,
              ecdsa: new MsrEcdsa(key)
            }];
        }
      });
    });
  };

  MsrCryptographyFactory.prototype.deserializeEcdsa = function (data) {
    return __awaiter(this, void 0, void 0, function () {
      var parsedDeviceIdentity, privateKey, publicKey;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            parsedDeviceIdentity = JSON.parse(data);
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.importKey("jwk", parsedDeviceIdentity.privateKey, keyGenParams, true, ["sign"])];

          case 1:
            privateKey = _a.sent();
            return [4
            /*yield*/
            , MsrCryptoAdapter_1.MsrCryptoAdapter.importKey("jwk", parsedDeviceIdentity.publicKey, keyGenParams, true, ["verify"])];

          case 2:
            publicKey = _a.sent();
            return [2
            /*return*/
            , new MsrEcdsa({
              privateKey: privateKey,
              publicKey: publicKey
            })];
        }
      });
    });
  };

  return MsrCryptographyFactory;
}();

exports.MsrCryptographyFactory = MsrCryptographyFactory;