"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogHttpsError = void 0;

function LogHttpsError(info, err) {
  var data = err.response && err.response.data ? err.response.data : null;
  var additionalInfo = data && data.errorId && data.description ? "errID: " + data.errorId + " - " + data.description : "";
  var MSCV = err.response && err.response.headers["ms-cv"] ? "MS-CV: " + err.response.headers["ms-cv"] : "";
  console.warn(info + ", Error: " + err + "; " + additionalInfo + "; " + MSCV);
}

exports.LogHttpsError = LogHttpsError;