import "../App.css";
import "../styles/Override.css";
import "../styles/Slick.css";
import * as React from "react";
import * as _ from "lodash";
import Slider from "react-slick";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route, Switch } from "react-router";
import { withCookies, Cookies } from "react-cookie";
import { ClickParam } from "antd/lib/menu";
import { ClubHub, User } from "@xbox/social-core";
import { clubsRedux, ReduxState, AuthActions, TagPickerActions } from "@xbox/social-redux";
import { ThemeType } from "@xbox/social-views";

import * as Styles from "./Styles";
import { Glyphs } from "../common/Generic.Icons";
import { telemetryService } from "../index";
import { GenericLoading, GenericEmptyPage } from "../common/GenericElements";
import { Localize } from "../common/translation/Localize";
import { ClubSelectionContextProvider } from "./ClubSelectionContext";
import { sizes, CombinedMenuWidth } from "../common/Generic.SnapPoints";
import { CurrentUserContextProvider } from "./CurrentUserContext";
import { XblMainContent, XblFooter, ClubListLayout } from "./layout/ShellLayout.Styles";
import { WhatsNewContainer } from "./whatsnew/WhatsNewContainer";
import { DiscoveryContainer } from "./discovery/DiscoveryContainer";
import { PeopleContainer } from "./people/PeopleContainer";
import PersonContainer from "./people/PersonContainer";
import HomeContainer from "./home/HomeContainer";
import { ThemeProvider } from "../../node_modules/styled-components";
import { LightTheme } from "../common/theme/LightTheme";
import { DarkTheme } from "../common/theme/DarkTheme";
import ClubPresenterContainer from "./ClubPresenterContainer";
import AuthValidator from "./AuthValidator";
import PushSideMenu from "./layout/PushSideMenu";
import Content from "./layout/Content";
import ClubMenu from "./layout/ClubMenu";
import Header from "./layout/Header";
import ChannelPicker from "../components/channels/ChannelPicker";
import { ClubManagerMenu } from "./clubmanager/ClubManagerMenu";
import { Button } from "../common/generic/Button";
import { LogOutIcon } from "./usersettings/UserSettingsModal.Styles";
import { IsDevMode } from "../common/GenericChecks";
import { CookieConsent, ShowManageCookies } from "../components/CookieConsent";

const OPEN_MENU_WIDTH = 300;

interface ShellContainerDispatchProps {
    handleItemFetch: (request: ClubHub.ClubRecommendationsRequest) => void;
    navigate: (path: string) => void;
    navigateHome: () => void;
    navigateHomeSubPage: (subpage: string) => void;
    handleSelectClub: (clubId: string) => void;
    navigateNoAccess: () => void;
    navigateUserPage: (userid: string) => void;
    navigateToClubSubPage: (clubId: string, key: string, item?: ClubHub.ClubDetails) => void;
    signOut: (cookies: Cookies) => void;
    getTags: () => void;
}

interface ShellContainerProps extends ShellContainerDispatchProps {
    currentUser: User;
    allClubs: ClubHub.Club[];
    invitedClubs: ClubHub.Club[];
    match: any;
    location: any;
    selectedClub?: ClubHub.Club;
    adminAccess: boolean;
    cookies: Cookies;
}

interface ShellContainerState {
    isOpen: boolean;
    selectedItem?: ClubHub.Club;
    isNarrow: boolean;
    isFirstExperience: boolean;
    isCurrentUserModerator?: boolean;
    theme: ThemeType;
}

class ShellContainer extends React.Component<ShellContainerProps, ShellContainerState> {
    static NARROW_WIDTH: number = sizes.small;
    private clubSelector: any;
    constructor(props: ShellContainerProps) {
        super(props);
        this.state = {
            isOpen: true,
            isNarrow: window ? window.innerWidth <= ShellContainer.NARROW_WIDTH : false,
            isFirstExperience: true,
            theme: ThemeType.LightTheme,
        };

        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        // initilize the CookieConsent library
        CookieConsent.init();

        window.addEventListener("resize", this.handleWindowResize);
        this.props.getTags();

        if (this.props.currentUser) {
            telemetryService.trackEvent("ClubRecommendationsRequest");
            let request: ClubHub.ClubRecommendationsRequest = {
                headers: [],
                xuid: this.props.currentUser.xuid
            };

            this.props.handleItemFetch(request);

        } else {
            this.props.navigateHome();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (!IsDevMode() && !this.props.adminAccess) {
            this.props.navigateNoAccess();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
    }

    handleWindowResize = (ev: UIEvent) => {
        this.setState({ isNarrow: window.innerWidth <= ShellContainer.NARROW_WIDTH });
    }

    onClubListClick = (param: ClickParam) => {
        this.internalClubSelection(param.key);
    }

    internalClubSelection = (clubId: string, deepLink: boolean = false) => {

        let club = _.find(this.props.allClubs, function (c: ClubHub.Club) { return c.details.id === clubId; });
        if (!club) {
            return;
        }

        telemetryService.trackEvent("ClubSelected", { "ClubId": clubId });
        this.setState({ selectedItem: club });

        let isModerator: boolean = club.settings && club.settings.viewerRoles.roles.some(x => x === ClubHub.ClubRoles.Moderator) || false;
        this.setState({ isCurrentUserModerator: isModerator });

        this.props.handleSelectClub(clubId);
    }

    // capture html reference to emulate click
    captureSelector = (e: any) => {
        // cleanup
        if (e === null) {
            this.clubSelector = null;
            return;
        }

        this.clubSelector = e.subMenuTitle;
        this.initialSelection();
    }

    initialSelection = () => {

        if (this.state.isFirstExperience && this.clubSelector) {
            this.setState({ isFirstExperience: false });

            if (this.props.match && this.props.match.params && this.props.match.params.id) {
                this.internalClubSelection(this.props.match.params.id, false);
            } else {
                this.clubSelector.click();
            }
        }
    }

    selectPage = (param: ClickParam) => {
        if (param.keyPath.length > 1 || param.key === "ignore") {
            // this is used to ignore already handled events
            return;
        }

        telemetryService.trackEvent("NavigationMenuItemClicked", { "PageName": param.key });
        // unselect current club
        this.setState({ selectedItem: undefined });
        this.props.navigate(param.key);
    }

    onToggleMenuClicked = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    onThemeChange = (theme: ThemeType) => {
        this.setState({ theme });
    }

    onSignOut = () => {
        this.props.signOut(this.props.cookies);
    }

    showMobileMenu = (pathPart: string) => {
        return location && location.pathname
            && location.pathname.indexOf(pathPart) !== -1
            && this.state.isOpen;
    }

    EmptyState = () => {
        return (
            <Styles.CenterContent>
                <h1>{Localize("warnings.noClubs")}</h1>
                <Button buttonType="danger" onClick={this.onSignOut}>
                    <LogOutIcon type={Glyphs.SignOut} />{Localize("generic.signOut")}
                </Button>
            </Styles.CenterContent>
        );
    }

    getSideMenu = () => {
        let navigationMenu = (
            <ClubMenu
                mode="inline"
                allClubs={this.props.allClubs}
                showMenu={this.state.selectedItem}
                toggleMenuCommand={this.onToggleMenuClicked}
                currentPath={this.props.match.params.id}
            />
        );

        if (window.innerWidth < CombinedMenuWidth) {
            return (
                <Slider dots={true} infinite={false} customPaging={(index) => (<button className="slickButtonActive" type="button" role="button" title="slick tab" tabIndex={0}> {index + 1}</button>)}>
                    {this.showMobileMenu("/discussion") && <ChannelPicker club={this.props.selectedClub} mobileView={true} />}
                    {this.showMobileMenu("/manage") && this.props.selectedClub && <ClubManagerMenu clubId={this.props.selectedClub.details.id} />}
                    {navigationMenu}
                </Slider>
            );
        }

        return navigationMenu;

    }
    render() {

        telemetryService.trackPageView("ClubsList");

        // remove temporary spinner
        var element = document.getElementById("firstspinner");
        if (element && element.parentElement) {
            element.parentElement.removeChild(element);
        }

        if (this.props.allClubs && this.props.allClubs.length) {

            let clubSelectionContext: any = {};
            if (this.state.selectedItem) {
                clubSelectionContext = { clubItem: this.state.selectedItem, clubId: this.state.selectedItem.details.id };
            }

            let currentUserContext: any = {};
            if (this.props.currentUser && this.state.isCurrentUserModerator) {
                currentUserContext = { user: this.props.currentUser, isModerator: this.state.isCurrentUserModerator };
            }

            const closedWidth = this.state.isNarrow ? 0 : 94;

            return (
                <ThemeProvider theme={this.state.theme === ThemeType.LightTheme ? LightTheme : DarkTheme}>
                    <ClubListLayout sideMenuIsOpen={this.state.isOpen} toggleSideMenu={this.onToggleMenuClicked} onThemeChange={this.onThemeChange}>
                        <Header
                            className="XblHeader"
                            userGamerpic={this.props.currentUser.profile ? this.props.currentUser.profile.gamerpic : ""}
                            userGamertag={this.props.currentUser.gamertag}
                            cookies={this.props.cookies}
                        />

                        <XblMainContent className="XblContent">
                            <PushSideMenu
                                className="PushSideMenu"
                                style={{ width: OPEN_MENU_WIDTH, minWidth: closedWidth }}
                            >
                                <ClubSelectionContextProvider.Provider value={clubSelectionContext}>
                                    <CurrentUserContextProvider.Provider value={currentUserContext}>
                                        {this.getSideMenu()}
                                    </CurrentUserContextProvider.Provider>
                                </ClubSelectionContextProvider.Provider>
                            </PushSideMenu>
                            <Content
                                maxMenuOffset={this.state.isNarrow ? closedWidth : OPEN_MENU_WIDTH}
                                minMenuOffset={closedWidth}
                                style={{ flex: "auto", maxWidth: "100%" }}
                            >
                                <Switch>
                                    <Route path="/app/discovery" render={() => IsDevMode() ? <DiscoveryContainer invitedClubs={this.props.invitedClubs} /> : <GenericEmptyPage />} />
                                    <Route path="/app/home/:subpage" render={() => <HomeContainer navigateHomeSubPage={this.props.navigateHomeSubPage}/>} />
                                    <Route exact={true} path="/app/people" render={() => IsDevMode() ? <PeopleContainer /> : <GenericEmptyPage />} />
                                    <Route exact={true} path="/app/people/:xuid" render={({ match }) => IsDevMode() ? <PersonContainer match={match} /> : <GenericEmptyPage />} />
                                    <Route path="/app/:id" component={ClubPresenterContainer} />
                                    <Route component={WhatsNewContainer} />
                                </Switch>
                            </Content>
                        </XblMainContent>

                        <XblFooter className="XblFooter">
                            {CookieConsent.isConsentRequired() &&
                                <ShowManageCookies />
                            }
                            <Styles.FooterLink href="https://www.xbox.com/legal/community-standards" target="_blank" rel="noopener noreferrer">{Localize("generic.communityStandards")}</Styles.FooterLink>
                            <Styles.FooterLink href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noopener noreferrer">{Localize("generic.privacyAndCookies")}</Styles.FooterLink>
                            <Styles.FooterLink href="https://go.microsoft.com/fwlink/?LinkID=206977" target="_blank" rel="noopener noreferrer">{Localize("generic.termOfUse")}</Styles.FooterLink>
                            <Styles.FooterLink>© Microsoft 2021</Styles.FooterLink>
                        </XblFooter>
                    </ClubListLayout>
                </ThemeProvider>
            );
        } else if (this.props.allClubs && this.props.allClubs.length === 0) {
            return this.EmptyState();
        }

        return <GenericLoading />;
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleItemFetch: (request: ClubHub.ClubRecommendationsRequest) => dispatch(clubsRedux.actions.fetchClubItems()),
        navigate: (path: string) => dispatch(push(path)),
        navigateHome: () => dispatch(push(`/app/home/all`)),
        navigateHomeSubPage: (subpage: string) => dispatch(push(`/app/home/${subpage}`)),
        navigateUserPage: (userId: string) => dispatch(push(`/app/people/${userId}`)),
        navigateNoAccess: () => dispatch(push("noadminaccess")),
        handleSelectClub: (clubId: string) => {
            dispatch(clubsRedux.actions.clearState());
            dispatch(push(`/app/${clubId}/discussion/feed`));
        },
        getTags: () => dispatch(TagPickerActions.getTags()),
        navigateToClubSubPage: (clubId: string, key: string, item?: ClubHub.ClubDetails) => dispatch(push(`/app/${clubId}/${key}`, { item: item })),
        signOut: (cookies: Cookies) => {
            dispatch(clubsRedux.actions.clearState());
            dispatch(AuthActions.signOut(cookies));
        }
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    let result = clubsRedux.selectors.getClubViewerState(state);

    return {
        allClubs: result.allClubs,
        invitedClubs: result.invitedClubs,
        currentUser: result.currentUser,
        adminAccess: !_.isUndefined(result.adminAccess) ? result.adminAccess : true,
        selectedClub: state.clubs.clubDetails.club
    };
};

export default AuthValidator(withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(ShellContainer)));
