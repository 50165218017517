declare const window: any;

export let IsEmulatedProdMode: boolean = false;

export const IsProdMode = () => !(process.env.NODE_ENV === "development" || process.env.REACT_APP_XBL_CONFIG === "ppe");

export const IsDevMode = () => !(IsEmulatedProdMode || IsProdMode());

// check for electron
export function IsDesktop(): boolean {
    return window && window.process && window.process.type;
}

export function SetIsEmulatedProdMode(e: boolean) {
    IsEmulatedProdMode = e;
}
