import * as React from "react";
import { TranslationOptions } from "./TranslationTypes";
import i18nInstance from "../../i18n.config";

export const Localize = (id: string|string[], options?: TranslationOptions) => {
    return i18nInstance.t(id, options);
};

interface LocalizeHtmlProps {
    id: string|string[];
    options?: TranslationOptions;
}

export const LocalizeHTML: React.SFC<LocalizeHtmlProps> = props => {
    // Values set through the options still get escaped to save you from possible xss attacks.
    // You can toggle escaping off, by either putting - before the key, or set the escapeValue option to false when requesting a translation.
    return <span dangerouslySetInnerHTML={{ __html: i18nInstance.t(props.id, props.options) }} />;
};
