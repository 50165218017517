"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LfgActions = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var LfgActions;

(function (LfgActions) {
  LfgActions.getLfgs = redux_ts_simple_1.createAction("LFG:GET_LFGS");
  LfgActions.getLfgsCompleted = redux_ts_simple_1.createAction("LFG::GET_LFGS_COMPLETED");
  LfgActions.getLfgsInProgress = redux_ts_simple_1.createAction("LFG::GET_LFGS_IN_PROGRESS");
  LfgActions.adminActOnItem = redux_ts_simple_1.createAction("LFG::ADMIN_ACT_ON_ITEM");
  LfgActions.lfgItemDeleted = redux_ts_simple_1.createAction("LFG::ITEM_DELETED");
  LfgActions.resetLfgs = redux_ts_simple_1.createAction("LFG::RESET_LFGS");
})(LfgActions = exports.LfgActions || (exports.LfgActions = {}));