import { injectable } from "inversify";
import { ILocalStorageService } from "@xbox/social-core";

@injectable()
export class AsyncLocalStorageService implements ILocalStorageService {
    constructor() {
        // Required constructor
    }

    async getItem(key: string): Promise<string | null> {
        return localStorage.getItem(key);
    }

    async setItem(key: string, value: string): Promise<void> {
        return localStorage.setItem(key, value);
    }

    async removeItem(key: string): Promise<void> {
        return localStorage.removeItem(key);
    }
}
