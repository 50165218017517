import { IDeviceInfo } from "xaljs";
import { injectable } from "inversify";
import { IsDesktop } from "../common/GenericChecks";

@injectable()
export class WebDeviceInfo implements IDeviceInfo {
    // private deviceIdInternal = guid();
    get deviceType(): "iOS" | "Android" | "Win32" {
        // Web (prod) acts like a Win32 device for now until
        // the service adds support for "Web" device types
        return "Win32";
    }

    get deviceId(): string | undefined {
        // XAL: 8e54b18d8c6056b20c42856b3e255cc832543386:
        // Disabling this as XASD rejects calls with serial number
        // and device ID if they don't match the previous serial number and device ID
        // return this.deviceIdInternal;
        return undefined;
    }

    get isWeb(): boolean {
        return !IsDesktop();
    }
}
