import * as React from "react";
import * as msgStyle from "./MsGameCatalog.Styles";

interface MiniGameItemProps {
    thumbnailUrl: string;
    gameTitle: string;
    voiceTitle: string;
}

export const MiniGameItem = (props: MiniGameItemProps) => {
    return (
        <msgStyle.MiniGameDiv>
            <msgStyle.MiniGameImage src={props.thumbnailUrl} alt={props.voiceTitle}/>
            <msgStyle.MiniGameTitle>{props.gameTitle}</msgStyle.MiniGameTitle>
        </msgStyle.MiniGameDiv>
    );
};
