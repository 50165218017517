"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchMsCatalogReducer = void 0;

var Immutable = require("seamless-immutable");

var redux_ts_simple_1 = require("redux-ts-simple");

var TitleHubActions_1 = require("../../actions/TitleHubActions");

var INITIAL_STATE = Immutable({
  inProgress: false
});
exports.searchMsCatalogReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(TitleHubActions_1.TitleHubActions.searchMsCatalogInProgress, function (state, action) {
  if (action.payload === true) {
    // reset data prior to search
    return state.merge({
      inProgress: action.payload,
      xboxGames: undefined,
      desktopGames: undefined
    });
  }

  return state.merge({
    inProgress: action.payload
  });
}).on(TitleHubActions_1.TitleHubActions.searchXboxMsCatalogCompleted, function (state, action) {
  return state.merge({
    xboxGames: action.payload
  }, {
    deep: true
  });
}).on(TitleHubActions_1.TitleHubActions.searchDesktopMsCatalogCompleted, function (state, action) {
  return state.merge({
    desktopGames: action.payload
  }, {
    deep: true
  });
}).build();