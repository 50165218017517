import styled from "styled-components";
import { media } from "../../common/Generic.SnapPoints";

export const NewsItemCard = styled.div`
    padding: 25px;
    width: 100%;
    min-height: 120px;
    margin-bottom: 10px !important;
    display: inline-block;
    background: ${props => props.theme.backgroundMain};
    border-bottom: 1px solid ${props => props.theme.borderSecondary};
    border-right: 1px solid ${props => props.theme.borderSecondary};
`;

export const TextContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    display: block;
    ${media.medium`font-size: 14px;`}
    ${media.menuOpenSmall`font-size: 14px;`}
    ${media.small`font-size: 12px;`}
`;

export const Title = styled.p`
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
    font-size: 22px;
    clear: both;
    ${media.medium`font-size: 22px;`}
    ${media.menuOpenSmall`font-size: 20px;`}
    ${media.small`font-size: 18px;`}
`;

export const ContentTitle = styled.p`
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    font-size: 24px;
    clear: both;
    ${media.medium`font-size: 24px;`}
    ${media.menuOpenSmall`font-size: 22px;`}
    ${media.small`font-size: 20px;`}
`;

export const CenterContent = styled.div`
    display: flex;
    justify-content: center;
    transition: 0.5s;
    width: 98%;
    margin: auto;
    overflow: auto;
    height: 100%;
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
`;
