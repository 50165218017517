import styled from "styled-components";
import { Tag } from "antd";
import { IconFromCode } from "../../common/GenericElements";

const { CheckableTag } = Tag;

export const ToggleTag = styled(CheckableTag)`
    margin-bottom: 5px !important;
    margin-right: 7px !important;
    user-select: none !important;
    font-size: 14px !important;
    height: auto !important;
    padding-top: 3px !important;
    padding-bottom: 4px !important;
    padding-left: 3px !important;
    padding-right: 9px !important;

    border: 1px solid ${props => props.theme.borderMain} !important;
`;

export const SystemTag = styled(Tag)`
    padding-top: 3px !important;
    padding-bottom: 4px !important;
    padding-left: 3px !important;
    padding-right: 9px !important;
    cursor: default !important;
    font-size: 14px !important;
    height: auto !important;
    user-select: none;
`;

export const TagIcon = styled(IconFromCode)`
    vertical-align: sub;
`;

export const AddTag = styled(Tag)`
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    cursor: default;
    font-size: 14px !important;
    height: auto !important;
    vertical-align: sub;
`;
