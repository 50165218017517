"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenStackComponents = void 0;

var TokenStackComponents =
/** @class */
function () {
  function TokenStackComponents(config, msaCache, xboxCache, nsal, cryptoFactory) {
    this.config = config;
    this.msaCache = msaCache;
    this.xboxCache = xboxCache;
    this.nsal = nsal;
    this.cryptoFactory = cryptoFactory;
  }

  return TokenStackComponents;
}();

exports.TokenStackComponents = TokenStackComponents;