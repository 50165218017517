"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoggerLevel = void 0;
exports.LoggerLevel = {
  Debug: ["log", "warn", "error", "info", "trace", "exception", "debug"],
  Verbose: ["log", "warn", "error", "info", "trace", "exception"],
  Info: ["log", "warn", "error", "info", "exception"],
  Warning: ["warn", "error", "exception"],
  Error: ["error", "exception"]
};