"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XboxToken = exports.IdentityType = void 0;

var XalInternalError_1 = require("./errors/XalInternalError");

var XalInternalErrorType_1 = require("./errors/XalInternalErrorType");

var Utils_1 = require("./Utils");

var XboxTokenData_1 = require("./XboxTokenData");

var IdentityType;

(function (IdentityType) {
  IdentityType["Xtoken"] = "Xtoken";
  IdentityType["Dtoken"] = "Dtoken";
  IdentityType["Ttoken"] = "Ttoken";
  IdentityType["Utoken"] = "Utoken";
})(IdentityType = exports.IdentityType || (exports.IdentityType = {}));

var EarlyTokenExpiration = 600000; // 10 mins

var XboxToken =
/** @class */
function () {
  function XboxToken(hasSignInDisplayClaims, identityType, environment, sandbox, tokenType, relyingParty, subRelyingParty, msaUserId) {
    this.hasSignInDisplayClaims = hasSignInDisplayClaims;
    this.identityType = identityType;
    this.environment = environment;
    this.sandbox = sandbox;
    this.tokenType = tokenType;
    this.relyingParty = relyingParty;
    this.subRelyingParty = subRelyingParty;
    this.msaUserId = msaUserId; // Assert that msaUserId is non-empty if and only if this is a Utoken or Xtoken

    Utils_1.assert((identityType === IdentityType.Utoken || identityType === IdentityType.Xtoken) === !Utils_1.isNullOrWhiteSpace(msaUserId)); // Only Xtokens should have true for hasSignInDisplayClaims

    Utils_1.assert(!hasSignInDisplayClaims || identityType === IdentityType.Xtoken);
  }

  XboxToken.deserialize = function (data) {
    var parsedData = data;

    if (!parsedData) {
      throw new XalInternalError_1.XalInternalError(XalInternalErrorType_1.XalInternalErrorType.ParseError, "Failed to deserialize XboxToken");
    }

    var token = new XboxToken(parsedData.HasSignInDisplayClaims, parsedData.IdentityType, parsedData.Environment, parsedData.Sandbox, parsedData.TokenType, parsedData.RelyingParty, parsedData.SubRelyingParty, parsedData.MsaUserId);

    if (parsedData.TokenData) {
      var parsedTokenData = XboxTokenData_1.XboxTokenData.deserialize(parsedData.TokenData);
      token.tokenData = parsedTokenData;
    }

    return token;
  };

  Object.defineProperty(XboxToken.prototype, "hasData", {
    get: function get() {
      return !!this.tokenDataInternal;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XboxToken.prototype, "xerr", {
    get: function get() {
      Utils_1.assert(this.hasData);

      if (!this.tokenDataInternal) {
        return 0;
      }

      return this.tokenDataInternal.xerr;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XboxToken.prototype, "expiry", {
    get: function get() {
      Utils_1.assert(this.hasData);

      if (!this.tokenDataInternal) {
        return undefined;
      }

      return this.tokenDataInternal.expiration;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XboxToken.prototype, "isValid", {
    get: function get() {
      if (!this.hasData || this.tokenDataInternal.xerr !== 0) {
        return false;
      }

      var effectiveNowInUtcUnixEpoch = Date.now() + EarlyTokenExpiration;
      var effectiveNow = new Date(effectiveNowInUtcUnixEpoch);
      return !Utils_1.isExpired(this.tokenDataInternal.expiration, effectiveNow);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XboxToken.prototype, "tokenValue", {
    get: function get() {
      Utils_1.assert(!!this.tokenDataInternal && this.tokenDataInternal.xerr === 0);

      if (!this.tokenDataInternal) {
        return undefined;
      }

      return this.tokenDataInternal.token;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(XboxToken.prototype, "tokenData", {
    get: function get() {
      Utils_1.assert(!!this.tokenDataInternal);
      return this.tokenDataInternal;
    },
    set: function set(tokenData) {
      this.tokenDataInternal = tokenData;
    },
    enumerable: false,
    configurable: true
  });

  XboxToken.prototype.resetTokenData = function () {
    this.tokenDataInternal = undefined;
  };

  XboxToken.prototype.serialize = function () {
    Utils_1.assert(!!this.tokenDataInternal);

    if (!this.tokenData) {
      Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "Serializing an Xbox token with no token data.");
    }

    var data = {
      MsaUserId: this.msaUserId,
      HasSignInDisplayClaims: this.hasSignInDisplayClaims,
      IdentityType: this.identityType,
      Environment: this.environment,
      Sandbox: this.sandbox,
      TokenType: this.tokenType,
      RelyingParty: this.relyingParty,
      SubRelyingParty: this.subRelyingParty
    };

    if (this.tokenDataInternal) {
      var tokenDataSerialized = this.tokenDataInternal.serialize();
      data.TokenData = tokenDataSerialized;
    }

    return data;
  };

  return XboxToken;
}();

exports.XboxToken = XboxToken;