import * as React from "react";
import * as Styles from "./WhatsNewContainer.Styles";
import { Localize } from "../../common/translation/Localize";
import { FixedWidthContent } from "../Styles";
import { isArray } from "util";
import i18nInstance from "../../i18n.config";

interface JsonNewsItem {
    title: string;
    intro?: string;
    data: string | string[];
    note?: string;
}

interface WhatsNewState {
    jsonData: JsonNewsItem[];
    listDataItems: JSX.Element[];
}

export class WhatsNewContainer extends React.Component<{}, WhatsNewState> {
    constructor(props: any) {
        super(props);

        this.state = {
            jsonData: [],
            listDataItems: []
        };
        this.fetchData(localStorage.getItem("userLocale"));
        i18nInstance.on("languageChanged", (lng) => this.fetchData(lng));
    }

    fetchData = (lang: any) => {
        fetch(`/whatsnew/${lang}/whatsnew_v2.json?v=${new Date().getTime()}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ jsonData: data });
                this.generateDataItems(data);
            }).catch(err => console.error(err.toString()));
    }

    generateDataItems = (data: JsonNewsItem[]) => {
        let items = data.map(function (item: JsonNewsItem) {
            let dataItems: any = null;
            if (isArray(item.data) && item.data.length > 1) {
                let styleItem: React.CSSProperties =  item.intro ? {marginBottom: 8, marginLeft: 20} : {};
                dataItems = item.data.map((dataItem: string, index: number) => { return <li key={index} style={styleItem}>{dataItem}</li>; });
            } else {
                dataItems = <Styles.TextContainer>{item.data}</Styles.TextContainer>;
            }

            let intro = item.intro ? <Styles.TextContainer style={{marginBottom: 10}}>{item.intro}</Styles.TextContainer> : null;
            let notes = item.note ? <Styles.TextContainer>{item.note}</Styles.TextContainer> : null;

            return (
                <Styles.NewsItemCard key={item.title}>
                    <Styles.Title>{item.title}</Styles.Title>
                    {intro}
                    {dataItems}
                    {notes}
                </Styles.NewsItemCard>
            );
        });
        this.setState({listDataItems: items});
    }

    render() {
        return (
            <Styles.CenterContent>
                <FixedWidthContent style={{ paddingTop: 50, marginBottom: 10 }}>
                <Styles.ContentTitle role="heading" aria-level={1} style={{ background: "transparent", textAlign: "center" }}> {Localize("whatsnew.title")}</Styles.ContentTitle>
                        {this.state.listDataItems}
                </FixedWidthContent>
            </Styles.CenterContent>);
    }
}
