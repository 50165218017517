"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.titleHubRedux = exports.getMsCatalogGameItems = exports.getTitlesState = void 0;

var TitleHubActions_1 = require("../../actions/TitleHubActions");

var TitleHubReducers_1 = require("./TitleHubReducers");

var TitlesNormalizer_1 = require("./TitlesNormalizer");

var MSCatalogItemsConverter_1 = require("./MSCatalogItemsConverter"); // selectors will go here


function getTitlesState(state) {
  if (state.titles) {
    return TitlesNormalizer_1.titlesDenormalizer(state.titles.result, state.titles.entities);
  } else {
    return [];
  }
}

exports.getTitlesState = getTitlesState;

function getMsCatalogGameItems(state) {
  return {
    inProgress: state.inProgress,
    xboxGames: MSCatalogItemsConverter_1.ConvertMsCatalogGamesToGamesInfo(state.xboxGames),
    desktopGames: MSCatalogItemsConverter_1.ConvertMsCatalogGamesToGamesInfo(state.desktopGames)
  };
}

exports.getMsCatalogGameItems = getMsCatalogGameItems;
exports.titleHubRedux = {
  actions: __assign({}, TitleHubActions_1.TitleHubActions),
  reducer: TitleHubReducers_1.aggregatedTitleHubReducer,
  selectors: {
    getTitlesState: getTitlesState,
    getMsCatalogGameItems: getMsCatalogGameItems
  }
};