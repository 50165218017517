import * as React from "react";
import { LayoutContext } from "./LayoutContext";
import { withContext } from "./ContextUtils";
import { omit } from "lodash";

export interface ContentProps extends React.HTMLAttributes<HTMLDivElement>, LayoutContext {
    maxMenuOffset?: number;
    minMenuOffset?: number;
}

export interface ContentState {
}

class Content extends React.Component<ContentProps, ContentState> {
    constructor(props: ContentProps) {
        super(props);
    }

    render() {

        let { style, maxMenuOffset, minMenuOffset, isOpen, ...otherProps } = this.props;
        let { transition, marginLeft, ...otherStyle } = style ? style : { transition: undefined, marginLeft: undefined };

        const finalProps = omit(otherProps, "onToggleSider", "toggleSlider", "onThemeChange");

        transition = "0.5s";
        marginLeft = isOpen ? maxMenuOffset : minMenuOffset;

        return (
            <div {...finalProps} style={{transition: transition, marginLeft: marginLeft, ...otherStyle }} >
                {this.props.children}
            </div>
        );
    }
}

export default withContext(Content);
