import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { BatchFeedItems, IFeedItemSocialActionHandlers } from "@xbox/social-views";
import { FeedViewType } from "../infiniteScrollers/base/InfiniteScrollTypes";
import { AnalyticsSocialActionsContainer } from "./AnalyticsSocialActionsContainer";
import { Localize } from "../../common/translation/Localize";
import { FeedItemSelector } from "../../components/feeditems/FeedItemSelector";
import { GenericLoading, GenericErrorMessage } from "../../common/GenericElements";
import * as Styles from "./Analytics.Styles";

export interface TopPostsProps {
    batchFeedItems: BatchFeedItems;
    socialActions?: IFeedItemSocialActionHandlers;
}

export default function TopPosts(props: TopPostsProps) {
    let postContent: JSX.Element;
    let getCustomSocialActions = (item: ActivityFeed.FeedItem, socialActions?: IFeedItemSocialActionHandlers) => {
       return <AnalyticsSocialActionsContainer socialActions={socialActions} item={item} />;
    };
    if (props.batchFeedItems.inProgress) {
        postContent = <GenericLoading />;
    } else if (props.batchFeedItems.feedItems) {
        postContent = (
            <Styles.TopPostContanier>
                {
                    props.batchFeedItems.feedItems.map((item: ActivityFeed.FeedItem) => (
                        <Styles.PostItemContanier key={item.feedItemId}>
                            <FeedItemSelector
                                item={item}
                                showAdminAction={true}
                                viewType={FeedViewType.GridView}
                                socialActions={props.socialActions}
                                customSocialActionsMenu={getCustomSocialActions(item, props.socialActions)}
                            />
                        </Styles.PostItemContanier>
                    ))
                }
            </Styles.TopPostContanier>
        );
    } else {
        postContent = <GenericErrorMessage errorMessage={Localize("analyticsView.errorRetrieving")} showError={true} />;
    }
    return postContent;
}
