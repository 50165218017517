import * as React from "react";
import { ActivityFeed } from "@xbox/social-core";
import { IFeedItemSocialActionHandlers, IFeedItemAdminActionHanders } from "@xbox/social-views";

import { FeedItemSelector } from "../../components/feeditems/FeedItemSelector";
import { BaseActivityFeedContainerProps } from "./BaseActivityFeed";
import ClubActivityFeed  from "./ClubActivityFeed";
import { FeedViewType } from "../infiniteScrollers/base/InfiniteScrollTypes";

export default class AllPollsClubActivityFeed extends ClubActivityFeed {
    constructor(props: BaseActivityFeedContainerProps) {
        super(props);
        this.state = {
            showShareItemModal: false,
            feedViewType: FeedViewType.FullView
        };
    }

    getFeedItemType = (
        item: ActivityFeed.FeedItem,
        socialActions?: IFeedItemSocialActionHandlers,
        adminActions?: IFeedItemAdminActionHanders,
        feedViewType?: FeedViewType,
        onload?: () => void
    ): JSX.Element | null => {
        if (!item) {
            return (<div />);
        }
        return (
            <FeedItemSelector
                key={item.feedItemId}
                item={item}
                adminActions={adminActions}
                socialActions={socialActions}
                showAdminAction={true}
                onload={onload}
                viewType={feedViewType}
                isCommentLocked={this.props.isCommentLocked}
                fetchCommentLock={this.props.fetchCommentLock}
                showFullDescription={true}
                enableAdminView={true}
            />
        );
    }

    getHeaderButtons = (): JSX.Element | null => {
        return null;
    }
}
