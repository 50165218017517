import styled from "styled-components";
import { Input, Button } from "antd";
import { ImageFade } from "../../styles/ImageStyles";
import { media } from "../../common/Generic.SnapPoints";
import { Icon } from "../../common/GenericElements";
const { TextArea } = Input;

export const Avatar = styled(ImageFade)`
    width: 100%;
    height: 100%;
`;

export const AvatarContainer = styled.button`
    float: left;
    display: block;
    width: 30%;
    max-width: 150px;
    margin-right: 10px;
    padding: 0px;
    border: 0px;
    position: relative;
    ${media.menuOpenSmall`width: 25%;`};
`;

export const AvatarEditIcon = styled(Icon)`
    right: 5px;
    position: absolute;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.33);
    padding: 3px;
`;

export const TitleImage = styled(ImageFade)`
    width: 100%;
    float: left;
    display: block;
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
    ${media.medium`width: 75px;`}
    ${media.menuOpenSmall`width: 50px;`}
    ${media.small`width: 50px;`}
    ${media.xSmall`width: 32px;`}
`;
export const Title = styled.p`
    font-size: 32px;
    margin-bottom: 0px;
    font-weight: bold;
    ${media.medium`
        font-size: 32px;
        margin-bottom: 10px;`
    }
    ${media.menuOpenSmall`
        font-size: 22px;
        margin-bottom: 5px;
        `
    }
    ${media.small`
        font-size: 26px;
        margin-bottom: 10px;
        `}
    ${media.xSmall`
        font-size: 18px;
        margin-bottom: 5px;
        `}
`;

export const EditButton = styled(Button as any)`
    font-size: 15px !important;
    padding-left: 2px !important;
    padding-right: 10px !important;
    ${media.medium`
        font-size: 15px !important;
        `}
    ${media.menuOpenSmall`
        font-size: 15px !important;
        `}
    ${media.small`
        font-size: 14px !important;
        `}
    ${media.xSmall`
        font-size: 12px !important;
        `}
`;

export const InputBox = styled(Input)`
    font-size: 32px !important;
    margin-bottom: 0px !important;
    font-weight: bold !important;
    height: 100% !important;
    ${media.medium`
        font-size: 32px !important;
        margin-bottom: 10px !important;`
    }
    ${media.menuOpenSmall`
        font-size: 22px !important;
        margin-bottom: 5px !important;
        `
    }
    ${media.small`
        font-size: 26px !important;
        margin-bottom: 10px;
        `}
    ${media.xSmall`
        font-size: 18px !important;
        margin-bottom: 5px !important;
        `}
`;

export const SubTitle = styled.span`
    font-size: 18px;
    margin-left: 5px;
    ${media.medium`
        font-size: 16px;
        margin-bottom: 15px;`
    }
    ${media.menuOpenSmall`
        font-size: 13px;
        margin-bottom: 5px;
        `
    }
    ${media.small`
        font-size: 14px;
        margin-bottom: 10px;
        `}
    ${media.xSmall`
        font-size: 13px;
        margin-bottom: 5px;
        `}
`;

export const TextAreaInput = styled(TextArea)`
    min-width: 250px;
    width: 100%;
    max-height: 500px;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 100px !important;
    font-size: 18px !important;
    ${media.medium`font-size: 16px;`}
    ${media.menuOpenSmall`font-size: 15px;`}
    ${media.small`font-size: 14px;`}
    ${media.xSmall`font-size: 13px;`}
`;

export const InfoContainer = styled.div`
    width: 100%;
    float: left;
    display: block;
    max-width: calc(80% - 150px);
    margin-left: 15px;
    min-width: 182px;

    ${media.medium`margin-left: 10px;`}
    ${media.menuOpenSmall`margin-left: 0px`}
    ${media.small`margin-left: 8px;`}
    ${media.xSmall`margin-left: 5px;`}
`;

export const StatContainer = styled.div`
    float: left;
    display: block;
    margin-right: 45px;

    ${media.medium`margin-right: 30px;`}
    ${media.menuOpenSmall`margin-right: 10px`}
    ${media.small`margin-right: 20px;`}
    ${media.xSmall`margin-right: 10px;`}
`;

export const Stat = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: left
    ${media.medium`font-size: 14px`}
    ${media.menuOpenSmall`font-size: 13px`}
    ${media.small`font-size: 14px;`}
    ${media.xSmall`font-size: 13px;`}
`;

export const StatValue = styled.span`
    font-size: 16px;
    text-align: left
    ${media.medium`font-size: 13px`}
    ${media.menuOpenSmall`font-size: 13px`}
    ${media.small`font-size: 13px;`}
    ${media.xSmall`font-size: 12px;`}
`;

export const BackgroundContainer = styled.button`
    width: 100%;
    padding: 0px;
    border: 0px;
    position: relative;
`;

export const Background = styled.img`
    width: 100%;
`;

export const GamerPicContainer = styled.div`
    margin: 10px 0;
`;
