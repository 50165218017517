import * as React from "react";
import { List, Modal } from "antd";
import { ReportType } from "@xbox/social-core";
import { Localize } from "../../common/translation/Localize";
import * as Styles from "./ReportOffenseMenu.Styles";

const info = Modal.info;
const confirm = Modal.confirm;

export const ReportOffenseMenuData = [
    {
        key: ReportType.UserContentGamertag,
        title: "reportOffenseMenu.title.userContentGamertag",
        description: "reportOffenseMenu.description.userContentGamertag",
        confirm: "reportOffenseMenu.confirm.userContentGamertag"
    },
    {
        key: ReportType.UserContentRealName,
        title: "reportOffenseMenu.title.userContentRealName",
        description: "reportOffenseMenu.description.userContentRealName",
        confirm: "reportOffenseMenu.confirm.userContentRealName"
    },
    {
        key: ReportType.UserContentGamerpic,
        title: "reportOffenseMenu.title.userContentGamerpic",
        description: "reportOffenseMenu.description.userContentGamerpic",
        confirm: "reportOffenseMenu.confirm.userContentGamerpic"
    },
    {
        key: ReportType.UserContentPersonalInfo,
        title: "reportOffenseMenu.title.userContentPersonalInfo",
        description: "reportOffenseMenu.description.userContentPersonalInfo",
        confirm: "reportOffenseMenu.confirm.userContentPersonalInfo"
    },
    {
        key: ReportType.CommsTextMessage,
        title: "reportOffenseMenu.title.commsTextMessage",
        description: "reportOffenseMenu.description.commsTextMessage",
        confirm: "reportOffenseMenu.confirm.commsTextMessage"
    }
];

export const ReportOffenseMenu = (props: any) => {
    const menuItems = props.menuData.map((md: any) => {
        return(
                <List.Item key={md.key}>
                    <div onClick={() => props.selectedItem(md.key, props.offender, md.confirm)} style={{ marginRight: 20, width: "100%" }}>
                        <Styles.ReportReason
                            title={<Styles.ItemTitle>{Localize(md.title)}</Styles.ItemTitle>}
                            description={Localize(md.description)}
                        />
                    </div>
                </List.Item>
        );
    });
    return menuItems;
};

export const GetReportOffenseModal = (
    reporterXuid: string,
    reportedGamertag: string,
    reportedXuid: string,
    reportUserAction: (reporterXuid: string, reportType: ReportType, reportedXuid: string) => void) => {
    return info({
        maskClosable: true,
        width: 700,
        title: Localize("clubRoster.reportReasonTitle"),
        okType: "default",
        okText: Localize("generic.cancel"),
        iconType: "question-circle",
        content: (
            <ReportOffenseMenu
                selectedItem={(reportType: ReportType, subject: any, confirmText: string) => {
                    confirm({
                        title: Localize("clubRoster.reportUserConfirm", { gamertag: reportedGamertag }),
                        content: Localize(confirmText),
                        okText: Localize("reportOffenseMenu.report"),
                        iconType: "question-circle",
                        okType: "primary",
                        cancelText: Localize("generic.cancel"),
                        onOk() {
                            reportUserAction(reporterXuid, reportType, reportedXuid);
                        }
                    });
                }}
                offender={reportedXuid}
                menuData={ReportOffenseMenuData}
            />
        )
    });
};
