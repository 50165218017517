import * as React from "react";
import { Input, Row, Col } from "antd";
import { connect } from "react-redux";
import { titleHubRedux, MsStoreGameInfo } from "@xbox/social-redux";
import { MsStoreGameInfoState } from "@xbox/social-views";

import * as fts from "../../components/feedtools/FeedTools.Styles";
import * as styles from "../../containers/Styles";
import { Localize } from "./../translation/Localize";
import { GenericModalFooter } from "./../GenericElements";
import { Button } from "./../generic/Button";
import { GameCatalogContainer } from "../../components/feedtools/MsGameCatalog/GameCatalogContainer";

interface SearchMsGamesStatProps {}
interface SearchMsGamesDispatchProps {
    searchGameInMsCatalog: (gameName: string) => void;
}

interface SearchMsGamesProps extends SearchMsGamesDispatchProps {
    msCatalog?: MsStoreGameInfoState;
    onSetGame: (game?: MsStoreGameInfo) => void;
}

interface SearchMsGamesState {
    searchText: string;
    selectedGame?: MsStoreGameInfo;
    showModal: boolean;
}

export class SearchMsGamesBar extends React.Component<SearchMsGamesProps, SearchMsGamesState> {

    constructor(props: any) {
        super(props);
        this.state = {
            searchText: "",
            showModal: false,
            selectedGame: undefined
        };
    }

    onChangeSearchText = (e: any) => {
        this.setState({ searchText: e.target.value });
    }

    onSearchGame = () => {
        if (!this.state.searchText) {
            return;
        }
        this.props.searchGameInMsCatalog(this.state.searchText);
        this.setState({showModal: true});
    }

    selectGame = (item: MsStoreGameInfo) => {
        this.setState({selectedGame: item});
    }

    closeGameCatalogModal = () => {
        this.setState({showModal: false});
        this.props.onSetGame();
    }

    useChosenGame = () => {
        this.setState({showModal: false});
        this.props.onSetGame(this.state.selectedGame);
    }

    render() {
        return (
            <Row style={{margin: "8px 0"}}>
                <fts.DetailsColumn span={8}>
                    <Input
                        onChange={this.onChangeSearchText}
                        value={this.state.searchText}
                        placeholder={Localize("postFeedItem.searchPlaceholder")}
                        onPressEnter={this.onSearchGame}
                        aria-label="searchArea"
                    />
                </fts.DetailsColumn>
                <Col span={2}>
                    <Button buttonType="primary" onClick={this.onSearchGame} disabled={!this.state.searchText} style={{padding: "5px"}}>
                        {Localize("postFeedItem.search")}
                    </Button>
                </Col>

                <styles.GenericModal
                    title={Localize("postFeedItem.storeModal.title")}
                    visible={this.state.showModal}
                    onCancel={this.closeGameCatalogModal}
                    destroyOnClose={true}
                    footer={
                        <GenericModalFooter
                            onOk={this.useChosenGame}
                            okText={Localize("generic.select")}
                            disableOK={!this.state.selectedGame}
                            onCancel={this.closeGameCatalogModal}
                        />
                    }
                >
                    <GameCatalogContainer
                        itemSelected={this.selectGame}
                        catalogStates={this.props.msCatalog!}
                    />
                </styles.GenericModal>
            </Row>
        );
    }
}

const mapStateToProps = (state: any): SearchMsGamesStatProps => {
    return {
        msCatalog: titleHubRedux.selectors.getMsCatalogGameItems(state.titlehub.searchMsCatalog)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchGameInMsCatalog: (gameName: string) => dispatch(titleHubRedux.actions.searchMsCatalog(gameName))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchMsGamesBar);
