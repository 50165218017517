// this import is needed for the DI freamework this needs to be loaded
// before we load any injectable type in memory.
import "core-js/es7/reflect";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { combineReducers, applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { routerReducer, routerMiddleware, ConnectedRouter } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import * as Loadable from "react-loadable";
import { CookiesProvider } from "react-cookie";
import { reducers as rootReducer, RootSaga } from "@xbox/social-redux";
import { XSocialSymbols, ITelemetryService, INotificationsService, ILogger, LoggerLevel, IBallotBoxService } from "@xbox/social-core";
import { unregister } from "./registerServiceWorker";
import "./index.css";
import NoAdminAccess from "./containers/NoAdminAccess";
import "antd/dist/antd.css";
import { XSocialContainer } from "./inversify.config";
import i18nInstance from "./i18n.config";
import { I18nextProvider } from "react-i18next";
import Reactotron, { trackGlobalErrors } from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";
import * as sagaPlugin from "reactotron-redux-saga";
import { GenericLoading } from "./common/GenericElements";
import AuthRedirectContainer from "./containers/AuthRedirectContainer";

// issue https://github.com/ReactTraining/react-router/issues/2907
const createBrowserHistory = require("history/createBrowserHistory");

const isDevelopmentMode = process.env.NODE_ENV === "development";

const AsyncShellContainer = Loadable({
    loader: () => import("./containers/ShellContainer"),
    loading: GenericLoading,
});

const AsyncLogin = Loadable({
    loader: () => import("./containers/Login"),
    loading: GenericLoading,
});

const AsyncAuthRedirectComplete = Loadable({
    loader: () => import("./containers/AuthRedirectComplete"),
    loading: GenericLoading,
});

const AsyncSiSuRedirectComplete = Loadable({
    loader: () => import("./containers/SiSuRedirectComplete"),
    loading: GenericLoading,
});

let sagaOpts = {};
if (isDevelopmentMode) {
    Reactotron
        .configure()
        .use(trackGlobalErrors())
        .use(reactotronRedux())
        .use(sagaPlugin())
        .connect();
    const sagaMonitor = Reactotron.createSagaMonitor();
    sagaOpts = { sagaMonitor };
}

const sagaMiddleware = createSagaMiddleware(sagaOpts);

const reducer = combineReducers({
    ...rootReducer,
    router: routerReducer
});

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const createStoreMethod = isDevelopmentMode ? Reactotron.createStore : createStore;
const store = createStoreMethod(
    reducer,
    composeWithDevTools(
        applyMiddleware(middleware, sagaMiddleware)
    )
);

const rootSaga = new RootSaga(XSocialContainer);
sagaMiddleware.run(rootSaga.sagas);

export const telemetryService: ITelemetryService = XSocialContainer.get<ITelemetryService>(XSocialSymbols.ITelemetryService);
export const notificationService: INotificationsService = XSocialContainer.get<INotificationsService>(XSocialSymbols.INotificationsService);
export const xblLogger: ILogger = XSocialContainer.get<ILogger>(XSocialSymbols.ILogger);
export const ballotBoxService: IBallotBoxService = XSocialContainer.get<IBallotBoxService>(XSocialSymbols.IBallotBoxService);
xblLogger.start(2000, LoggerLevel.Debug);

function primeRender() {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <I18nextProvider i18n={i18nInstance as any}>
                    <CookiesProvider>
                        <div className="fill">
                            <AuthRedirectContainer />
                            <Switch>
                                <Route exact={true} path="/" component={AsyncLogin} />
                                <Route exact={true} path="/app" component={AsyncShellContainer} />
                                <Route path="/app/:id" component={AsyncShellContainer} />
                                <Route path="/authcomplete" component={AsyncAuthRedirectComplete} />
                                <Route path="/sisucomplete" component={AsyncSiSuRedirectComplete} />
                                <Route path="/noadminaccess" component={NoAdminAccess} />
                                // When running as Electron app, route the main app page (index.html) to Login.
                                <Route path="*/index.html" component={AsyncLogin} />
                            </Switch>
                        </div>
                    </CookiesProvider>
                </I18nextProvider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById("root") as HTMLElement
    );
}

primeRender();
// unregister service worker
unregister();
i18nInstance.on("languageChanged", primeRender);
