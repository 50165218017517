"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.privacyReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var _ = require("lodash");

var PrivacyActions_1 = require("../../actions/PrivacyActions");

var INITIAL_STATE = {
  privacySettings: []
};
exports.privacyReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(PrivacyActions_1.PrivacyActions.getPrivacySettingsCompleted, function (state, action) {
  return __assign(__assign({}, state), {
    privacySettings: action.payload
  });
}).on(PrivacyActions_1.PrivacyActions.updatePrivacySettingCompleted, function (state, action) {
  var settings = _.cloneDeep(state.privacySettings);

  var index = settings.findIndex(function (f) {
    return f.setting === action.payload.setting;
  });
  settings[index] = action.payload;
  return __assign(__assign({}, state), {
    privacySettings: settings
  });
}).build();