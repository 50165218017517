"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebViewInternal = void 0;

var Utils_1 = require("./Utils");

var WebViewClientOperation_1 = require("./operations/webView/WebViewClientOperation");

var OperationQueue_1 = require("./operations/OperationQueue");

var MobileWebViewOperation_1 = require("./operations/webView/MobileWebViewOperation");

var MobileWebViewValidateOperation_1 = require("./operations/webView/MobileWebViewValidateOperation");

var WebViewInternal =
/** @class */
function () {
  function WebViewInternal(telemetryClient, storage, webView) {
    this.telemetryClient = telemetryClient;
    this.storage = storage;
    this.webView = webView;
    this.queue = new OperationQueue_1.OperationQueue();
  }

  WebViewInternal.prototype.showUrl = function (startUri, endUri, suppressUi, webViewFlowId, additionalStoredArgs) {
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(startUri));
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(endUri));
    Utils_1.assert(!Utils_1.isNullOrWhiteSpace(webViewFlowId)); // If UI is being suppressed, there can't be an out-of-proc browser window;
    // no need to store and validate.

    if (suppressUi) {
      var operationSuppressed = new WebViewClientOperation_1.WebViewClientOperation(this.telemetryClient, startUri, endUri, suppressUi, this.webView);
      this.queue.queueOperation(operationSuppressed);
      return operationSuppressed.completionPromise;
    }

    var operation = new MobileWebViewOperation_1.MobileWebViewOperation(this.telemetryClient, startUri, endUri, suppressUi, this, this.storage, webViewFlowId, additionalStoredArgs, this.webView);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  WebViewInternal.prototype.validateCompleteUrl = function (endUri) {
    var operation = new MobileWebViewValidateOperation_1.MobileWebViewValidateOperation(this.telemetryClient, this.storage, endUri);
    this.queue.queueOperation(operation);
    return operation.completionPromise;
  };

  return WebViewInternal;
}();

exports.WebViewInternal = WebViewInternal;