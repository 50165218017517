import * as React from "react";
import { AdminAnalyticsData, FeedEngagementData } from "@xbox/social-core";
import { Localize } from "../../common/translation/Localize";
import Stat from "./Stat";
import * as Styles from "./Analytics.Styles";

export interface StatsSectionProps {
    actionActionsData: AdminAnalyticsData;
    feedEngagementData?: FeedEngagementData;
}

function createStatElement(key: string, value: number) {
    return (
        <div key={key}>
            <Stat name={Localize(`analyticsView.stat${key}`)} amount={value} />
        </div>
    );
}

export enum AdminAnalyticsDataKeys {
    UniqueFollowers = "UniqueFollowers",
    Invite = "Invite",
    RejectInvite = "RejectInvite",
    AcceptInvite = "AcceptInvite",
    CancelInvite = "CancelInvite",
    Follow = "Follow",
    Unfollow = "Unfollow",
    RequestToJoin = "RequestToJoin",
    AutoJoin = "AutoJoin",
    CancelRequestToJoin = "CancelRequestToJoin",
    Leave = "Leave",
    Ban = "Ban",
    DemoteModerator = "DemoteModerator",
    Kick = "Kick",
    PromoteModerator = "PromoteModerator",
    Unban = "Unban",
    TotalMembers = "TotalMembers",
    UniqueVisits = "UniqueVisits"
}

export default function StatsSection(props: StatsSectionProps) {

    const AAD = props.actionActionsData;
    let totalUniqueVisitors,
        invited,
        accepted: JSX.Element | null = null;

    if (AAD.uniqueVisitors) {
        totalUniqueVisitors = createStatElement(AdminAnalyticsDataKeys.UniqueVisits, AAD.uniqueVisitors.total!);
    }

    if (AAD.adminActions && AAD.adminActions.adminActionsSummary) {
        let summary = AAD.adminActions.adminActionsSummary;
        if (summary.Invite) {
            invited = createStatElement(AdminAnalyticsDataKeys.Invite, summary.Invite);
        }
        if (summary.AcceptInvite) {
            accepted = createStatElement(AdminAnalyticsDataKeys.AcceptInvite, summary.AcceptInvite);
        }
    }

    return (
        <Styles.StatDiv>
            {totalUniqueVisitors} {invited} {accepted}
        </Styles.StatDiv>
    );
}
