import * as React from "react";
import { Input, Select } from "antd";
import { ClubHub } from "@xbox/social-core";

import { ThemedModal, AddChannelButton } from "../../containers/Styles";
import { GenericModalFooter } from "../../common/GenericElements";
import { Localize } from "../../common/translation/Localize";
import { ThemedSelect, DisabledSelect } from "../../common/Generic.Styles";
import { IsDevMode } from "../../common/GenericChecks";

const MAX_CHANNEL_NAME = 30;
const Option = Select.Option;

enum AddChannelTypes {
    Feed = 1,

    // Chat channels are disabled for xblc
    // Chat = 2,
}

interface AddChannelProps {
    clubId?: string;
    postSetting: string[];
    addChannel: (channelData: ClubHub.CreateChannel) => void;
}

interface AddChannelStats {
    showAddModal: boolean;
    channelName: string;
    groupName: string;
    channelType: AddChannelTypes;
    whoCanView: ClubHub.ClubRoles;
    whoCanWrite: string;
}

enum ViewChannelsRoles {
    Moderator = "Moderator",
    Member = "Member",
    Owner = "Owner",
    Nonmember = "Nonmember"
}

export class AddChannelControl extends React.Component<AddChannelProps, AddChannelStats> {
    constructor(props: AddChannelProps) {
        super(props);

        this.state = {
            showAddModal: false,
            channelName: "",
            groupName: "",
            channelType: AddChannelTypes.Feed,
            whoCanView: ClubHub.ClubRoles.Member,
            whoCanWrite: props.postSetting ? props.postSetting[0] : "",
        };
    }

    componentDidMount() {
        this.setState({ whoCanWrite: this.props.postSetting[0]});
    }

    componentDidUpdate(prevProps: AddChannelProps) {
        if (this.props.postSetting !== prevProps.postSetting) {
            this.setState({ whoCanWrite: this.props.postSetting[0]});
        }
    }

    resetState = () => {
        this.setState({
            showAddModal: false,
            channelName: "",
            groupName: "",
            channelType: AddChannelTypes.Feed,
        });
    }

    triggerAddModal = (show: boolean) => {
        this.setState({showAddModal: show});
    }

    onAddChannelClick = () => {
        let channelData: ClubHub.ChannelData = {
            ChannelName: this.state.channelName,
            ChannelGroupName: this.state.groupName,
            ChannelType: AddChannelTypes[this.state.channelType],
            CreatedDateTimeUtc: new Date(),
            WhoCanView: this.state.whoCanView,
            whoCanWrite: this.state.whoCanWrite
        };

        let createData: ClubHub.CreateChannel = {
            clubId: this.props.clubId!,
            channelData: channelData
        };

        this.props.addChannel(createData);
        this.triggerAddModal(false);
        this.resetState();
    }

    selectChannelType = () => {
        let resultsList: JSX.Element[] = [];
        // iteration through enum pairs "string=number"
        for (const chType in AddChannelTypes) {
            if (!Number(chType)) {
                resultsList.push(<Option key={chType} value={AddChannelTypes[chType]}>{Localize(`channels.types.${chType}`)}</Option>);
            }
        }

        return (
            <div>
                <div>{Localize("channels.selectType")}</div>
                {
                    IsDevMode() ?
                        <ThemedSelect value={this.state.channelType} onChange={this.onChangeChanneltype} style={{ minWidth: "150px" }}>
                            {resultsList}
                        </ThemedSelect>
                        :
                        <DisabledSelect value={this.state.channelType} style={{ minWidth: "150px" }} disabled={true}>
                            {resultsList}
                        </DisabledSelect>
                }
            </div>
        );
    }

    selectWhoCanView = () => {
        let resultsList: JSX.Element[] = [];
        // iteration through enum pairs "string=string"
        for (const val in ViewChannelsRoles) {
            if (val) {
                resultsList.push(<Option key={val} value={val}>{Localize(`clubSettings.${val}`)}</Option>);
            }
        }

        return (
            <div>
                <div>{Localize("channels.selectWhoCanView")}</div>
                <ThemedSelect value={this.state.whoCanView} onChange={this.onChangeWhoCanView} style={{minWidth: "150px"}}>
                    {resultsList}
                </ThemedSelect>
            </div>
        );
    }

    selectWhoCanWrite = () => {
        return (
            <div>
                <div>{Localize("channels.selectWhoCanWrite")}</div>
                <ThemedSelect
                    onChange={this.onChangeWhoCanWrite}
                    style={{ minWidth: "150px" }}
                    value={this.state.whoCanWrite ? Localize(`clubSettings.${this.state.whoCanWrite}`) : ""}
                >
                    {this.props.postSetting.map(
                            (val: string) => (
                                <Option key={val} value={val}>
                                    {Localize(`clubSettings.${val}`)}
                                </Option>
                            )
                        )}
                </ThemedSelect>
            </div>
        );
    }

    onChannelNameChange = (e: any) => {
        this.setState({ channelName: e.target.value});
    }

    onGroupNameChange = (e: any) => {
        this.setState({ groupName: e.target.value});
    }

    onChangeChanneltype = (value: any) => {
        this.setState({channelType: value});
    }

    onChangeWhoCanView = (value: any) => {
        this.setState({whoCanView: value});
    }

    onChangeWhoCanWrite = (value: any) => {
        this.setState({whoCanWrite: value});
    }

    render() {
        return (
            <div>
                <AddChannelButton className="AddChannelButton" onClick={() => { this.triggerAddModal(true); }}>
                    {Localize("channels.addChannel")}
                </AddChannelButton>

                <ThemedModal
                    visible={this.state.showAddModal}
                    title={Localize("channels.addChannelTitle")}
                    onCancel={() => { this.triggerAddModal(false); }}
                    footer={
                        <GenericModalFooter
                            onOk={this.onAddChannelClick}
                            onCancel={() => { this.triggerAddModal(false); }}
                            disableOK={!this.state.channelName || !this.state.groupName}
                        />}
                >
                    <span>{Localize("channels.channelNamePlaceholder")}</span>
                    <Input
                        style={{margin: "0px 0px 8px 0px"}}
                        onChange={this.onChannelNameChange}
                        value={this.state.channelName}
                        placeholder={Localize("channels.channelNamePlaceholder")}
                        aria-label={Localize("channels.channelNamePlaceholder")}
                        aria-required="true"
                        maxLength={MAX_CHANNEL_NAME}
                    />
                    <br/>
                    <span>{Localize("channels.groupNamePlaceholder")}</span>
                    <Input
                        style={{margin: "0px 0px 8px 0px"}}
                        onChange={this.onGroupNameChange}
                        value={this.state.groupName}
                        placeholder={Localize("channels.groupNamePlaceholder")}
                        aria-label={Localize("channels.groupNamePlaceholder")}
                        aria-required="true"
                        maxLength={MAX_CHANNEL_NAME}
                    />
                    {this.selectChannelType()}
                    {this.selectWhoCanView()}
                    {this.selectWhoCanWrite()}
                </ThemedModal>
            </div>
        );
    }
}
