"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clubRosterReducer = void 0;

var redux_ts_simple_1 = require("redux-ts-simple");

var Immutable = require("seamless-immutable");

var ClubListActions_1 = require("../../actions/ClubListActions"); // reducer


var INITIAL_STATE = Immutable({});
exports.clubRosterReducer = new redux_ts_simple_1.ReducerBuilder(INITIAL_STATE).on(ClubListActions_1.ClubActions.clubRosterSearchFilter, function (state, action) {
  return state.setIn(["searchFilter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubRosterTypeFilter, function (state, action) {
  return state.setIn(["typeFilter"], action.payload);
}).on(ClubListActions_1.ClubActions.clubRosterUpdateSearchValue, function (state, action) {
  return state.setIn(["searchValue"], action.payload);
}).build();