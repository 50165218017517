"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SisuEndUri = void 0;

var http_1 = require("@xbox/http");

var Utils_1 = require("../Utils");

var XalInternalErrorType_1 = require("../errors/XalInternalErrorType");

var SisuEndUri =
/** @class */
function () {
  function SisuEndUri(endUri) {
    this.uri = new http_1.Url(endUri);
    this.query = Utils_1.parseQuery(this.uri.search);
  }

  Object.defineProperty(SisuEndUri.prototype, "logMessage", {
    get: function get() {
      return this.query.get("log");
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SisuEndUri.prototype, "result", {
    get: function get() {
      var status = this.query.get("status");

      if (!status || status.length < 0) {
        Utils_1.xalTrace(Utils_1.TraceLevel.Warning, "No status received from SISU web. Assuming success.");
        return undefined;
      } else if (status === "success") {
        return undefined;
      } else if (status === "cancel") {
        return XalInternalErrorType_1.XalInternalErrorType.Aborted;
      } else if (status === "switch_account") {
        return XalInternalErrorType_1.XalInternalErrorType.SwitchUser;
      } else if (status === "fail") {
        // Server error
        return XalInternalErrorType_1.XalInternalErrorType.Unknown;
      }

      Utils_1.xalTrace(Utils_1.TraceLevel.Error, "Unrecognized status received from SISU web: " + status);
      return XalInternalErrorType_1.XalInternalErrorType.Unknown;
    },
    enumerable: false,
    configurable: true
  });
  return SisuEndUri;
}();

exports.SisuEndUri = SisuEndUri;