"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RtaMessageType = void 0;
var RtaMessageType;

(function (RtaMessageType) {
  RtaMessageType[RtaMessageType["Subscribe"] = 1] = "Subscribe";
  RtaMessageType[RtaMessageType["Unsubscribe"] = 2] = "Unsubscribe";
})(RtaMessageType = exports.RtaMessageType || (exports.RtaMessageType = {}));