"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserSet = exports.UserChangeType = void 0;

var XalUser_1 = require("../XalUser");

var Utils_1 = require("./Utils");

var UserChangeType;

(function (UserChangeType) {
  UserChangeType[UserChangeType["None"] = 0] = "None";
  UserChangeType[UserChangeType["Gamertag"] = 1] = "Gamertag";
  UserChangeType[UserChangeType["Privileges"] = 2] = "Privileges";
})(UserChangeType = exports.UserChangeType || (exports.UserChangeType = {}));

var UserSet =
/** @class */
function () {
  function UserSet(maxUsers) {
    this.maxUsers = maxUsers;
    this.users = new Map();
  }

  UserSet.prototype.addUser = function (user) {
    Utils_1.assert(!!user);
    Utils_1.assert(this.users.size < this.maxUsers);
    Utils_1.assert(!this.users.has(user.xuid));
    this.users.set(user.xuid, user);
  };

  UserSet.prototype.canAddUser = function () {
    return this.users.size < this.maxUsers;
  };

  UserSet.prototype.empty = function () {
    return this.users.size === 0;
  };

  UserSet.prototype.isDuplicateUser = function (webAccountId) {
    return Array.from(this.users.values()).some(function (user) {
      return user.webAccountId === webAccountId;
    });
  };

  UserSet.prototype.updateUserAccountData = function (accountData) {
    var user = this.users.get(accountData.xuid);

    if (!user) {
      Utils_1.assert(false);
      return;
    }

    var fieldsChanged = user.updateAccountData(accountData); // tslint:disable-next-line:no-bitwise

    if ((fieldsChanged & UserChangeType.Gamertag) === UserChangeType.Gamertag) {
      if (this.userChanged) {
        this.userChanged(user, XalUser_1.XalUserChangeType.Gamertag);
      }
    } // tslint:disable-next-line:no-bitwise


    if ((fieldsChanged & UserChangeType.Privileges) === UserChangeType.Privileges) {
      if (this.userChanged) {
        this.userChanged(user, XalUser_1.XalUserChangeType.Privileges);
      }
    }
  };

  UserSet.prototype.remove = function (userId) {
    this.users.delete(userId);
  };

  return UserSet;
}();

exports.UserSet = UserSet;