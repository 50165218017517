import * as React from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Person } from "@xbox/social-core";
import { ReduxState, peopleRedux } from "@xbox/social-redux";
import { Icon, GenericLoading } from "../../common/GenericElements";
import { Glyphs } from "../../common/Generic.Icons";
import * as Styles from "./PeopleContainer.Styles";
import { Localize } from "../../common/translation/Localize";
import { PersonAboutViewer } from "./peopleView/PersonAboutViewer";
import { PersonEventsViewer } from "./peopleView/PersonEventsViewer";
import { PersonGamesViewer } from "./peopleView/PersonGamesViewer";
import { PersonHomeViewer } from "./peopleView/PersonHomeViewer";
import { PersonMediaViewer } from "./peopleView/PersonMediaViewer";

interface PersonContainerOtherProps {
    match: any;
}

interface PersonContainerBaseProps {
    person?: Person;
    isLoading: boolean;
}

interface PersonContainerDispatchProps {
    fetchPerson: (xuid: string) => void;
}

type PersonContainerProps = PersonContainerBaseProps & PersonContainerDispatchProps & PersonContainerOtherProps;

interface PersonContainerState { }

class PersonContainer extends React.Component<PersonContainerProps, PersonContainerState> {
    constructor(props: PersonContainerProps) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPerson(this.props.match.params["xuid"]);
    }

    getNavigationItem(stringId: string, innerAppLink: string): JSX.Element {
        return (
            <Styles.PersonLevelItem>
                <NavLink
                    activeClassName="active"
                    to={`/app/${innerAppLink}`}
                    exact={true}
                >
                    {Localize(stringId)}
                </NavLink>
            </Styles.PersonLevelItem>
        );
    }

    getPeopleCard = (person: Person) => {
        return (
            <Styles.ItemCard key={person.displayName}>
                <Styles.ImageDivContainer style={{ float: "left" }}>
                    <Styles.UserAvatar src={person.displayPicRaw} />
                </Styles.ImageDivContainer>

                <Styles.MainDivContainer>
                    <Styles.FriendPostTextDivContainer>
                        <Styles.GamerTag>{person.gamertag}</Styles.GamerTag>
                        <Styles.UserName>{person.displayName}</Styles.UserName>
                    </Styles.FriendPostTextDivContainer>
                    <Styles.SocialButtonDiv>
                        <Styles.SocialButton>Follow</Styles.SocialButton>
                        <Styles.SocialButton>Chat</Styles.SocialButton>
                    </Styles.SocialButtonDiv>
                </Styles.MainDivContainer>

                <Styles.SocialIconDiv>
                    <Icon type={Glyphs.Xenon} />
                    <Icon type={Glyphs.FacebookLogo} />
                    <Icon type={Glyphs.SmartGlassGamerScore} />
                    <Icon type={Glyphs.WindowsLogo} />
                </Styles.SocialIconDiv>
                <Styles.FollowInfoDiv>
                    <div>{person.detail && person.detail.followerCount ? `${person.detail.followerCount} Followers` : 0}</div>
                    <div>{person.detail && person.detail.followingCount ? `${person.detail.followingCount} Following` : 0}</div>
                </Styles.FollowInfoDiv>
            </Styles.ItemCard>
        );
    }
    render() {
        let xuid = this.props.match.params["xuid"];
        let person = this.props.person;

        if (this.props.isLoading) {
            return <GenericLoading />;
        }

        return (
            <div style={{ margin: 30 }}>
                <h1>Person</h1>
                {this.props.person ? this.getPeopleCard(this.props.person) : null}

                <Styles.PersonLevelMenu>
                    {this.getNavigationItem("clubMenu.home", `people/${xuid}/home`)}
                    {this.getNavigationItem("feedtools.tabTitles.media", `people/${xuid}/media`)}
                    {this.getNavigationItem("discovery.games", `people/${xuid}/games`)}
                    {this.getNavigationItem("clubMenu.events", `people/${xuid}/events`)}
                    {this.getNavigationItem("clubMenu.about", `people/${xuid}`)}
                </Styles.PersonLevelMenu>

                <Switch>
                    <Route exact={true} path={`/app/people/${xuid}/home`} render={() => <PersonHomeViewer />} />
                    <Route exact={true} path={`/app/people/${xuid}/media`} render={() => <PersonMediaViewer />} />
                    <Route exact={true} path={`/app/people/${xuid}/games`} render={() => <PersonGamesViewer />} />
                    <Route exact={true} path={`/app/people/${xuid}/events`} render={() => <PersonEventsViewer />} />
                    <Route exact={true} path={`/app/people/${xuid}/`} render={() => <PersonAboutViewer person={person} />} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state: ReduxState): PersonContainerBaseProps => {
    return {
        person: state.people.person,
        isLoading: state.people.fetchPersonInProgress
    };
};

const mapDispatchToProps = (dispatch: any): PersonContainerDispatchProps => {
    return {
        fetchPerson: (xuid: string) => dispatch(peopleRedux.actions.fetchPerson(xuid))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonContainer);
