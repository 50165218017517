import * as React from "react";
import { connect } from "react-redux";
import { PromiseCompletionSource } from "@xbox/framework";
import { Url } from "@xbox/http";
import { ReduxState } from "@xbox/social-redux";

interface IFrameWindow extends Window {
    refreshCompleteCallback?: any;
}

type AuthRedirectProps = {
    showAuthRedirect: boolean;
    authRedirectUrl: string | undefined;
    authRedirectEndUrl: string | undefined;
    showAuthRedirectVisible: boolean;
    authRedirectPromise?: PromiseCompletionSource<string>;
};

class AuthRedirectContainer extends React.Component<AuthRedirectProps, {}> {
    private iframeRef: React.RefObject<HTMLIFrameElement>;

    constructor(props: any) {
        super(props);
        this.iframeRef = React.createRef<HTMLIFrameElement>();
        if (this.props.showAuthRedirect && this.props.authRedirectUrl && this.isRpsRefresh) {
            localStorage.setItem("MSA_REFRESH_PENDING", "true");
        }
    }

    clearXalSession = () => {
        const key = `Xal.${process.env.REACT_APP_TITLE_ID}.`;
        const keyMsa = `MSA_`;

        let toRemove: any[] = [];
        // Iterate over localStorage and insert the keys that meet the condition into toRemove
        for (var i = 0; i < localStorage.length; i++) {
            let keyValue = localStorage.key(i);
            if (keyValue && (keyValue.indexOf(key) !== -1 || keyValue.indexOf(keyMsa) !== -1)) {
                toRemove.push(localStorage.key(i));
            }
        }

        toRemove.forEach(element => {
            localStorage.removeItem(element);
        });
    }
    render() {
        if (this.isRpsRefresh) {
            return (
                <div>
                    <iframe
                        sandbox="allow-scripts allow-same-origin"
                        onLoad={this.handleUrlLoaded}
                        src={this.props.authRedirectUrl}
                        style={{ display: "none", width: 0, height: 0 }}
                        ref={this.iframeRef}
                    />
                </div>
            );
        } else {
            return <div id="auth-redirect-container" />;
        }
    }

    componentDidMount(): void {
        this.ensureRefreshCallback();
    }

    componentDidUpdate(prevProps: Readonly<AuthRedirectProps>, prevState: Readonly<{}>, prevContext: any) {
        if (this.props.showAuthRedirect && this.props.authRedirectUrl) {
            if (!this.isRpsRefresh) {
                console.log(`AuthRedirectContainer: componentDidUpdate: isRpsRefresh: false, authRedirectUrl: ${this.props.authRedirectUrl}`);
                // RPS ticket refresh is the only scenario we can avoid redirecting
                // on web, because we don't need the response URL
                window.location.assign(this.props.authRedirectUrl);
            } else {
                console.log(`AuthRedirectContainer: componentDidUpdate: isRpsRefresh: true, authRedirectUrl: ${this.props.authRedirectUrl}`);
                this.ensureRefreshCallback();
                localStorage.setItem("MSA_REFRESH_PENDING", "true");
                setTimeout(() => this.refreshComplete(undefined), 10000);
            }
        }
    }

    handleUrlLoaded = (e: any) => {
        if (this.isRpsRefresh && this.props.authRedirectPromise) {
            const refreshCompleteResult = localStorage.getItem("MSA_REFRESH_COMPLETE");
            if (refreshCompleteResult) {
                localStorage.removeItem("MSA_REFRESH_PENDING");
                localStorage.removeItem("MSA_REFRESH_COMPLETE");
                this.props.authRedirectPromise.resolve(refreshCompleteResult);
            }
        }
    }

    get isRpsRefresh(): boolean {
        if (this.props.authRedirectPromise && this.props.authRedirectUrl) {
            const url = new Url(this.props.authRedirectUrl);
            return !this.props.showAuthRedirectVisible
                && url.pathname === "/oauth20_authorize.srf";
        }

        return false;
    }

    private ensureRefreshCallback(): void {
        if (this.iframeRef && this.iframeRef.current) {
            const iframeWindow: IFrameWindow | null = this.iframeRef.current.contentWindow;
            if (iframeWindow && !iframeWindow.refreshCompleteCallback) {
                iframeWindow.refreshCompleteCallback = (endUrl: string) => this.refreshComplete(endUrl);
            }
        }
    }

    private refreshComplete(endUrl: string | undefined): void {
        if (this.isRpsRefresh && this.props.authRedirectPromise) {
            const isRefreshPending = localStorage.getItem("MSA_REFRESH_PENDING");
            if (isRefreshPending) {
                localStorage.removeItem("MSA_REFRESH_COMPLETE");
                localStorage.removeItem("MSA_REFRESH_PENDING");
                console.debug("Completed MSA refresh for Web %s", endUrl);
                this.props.authRedirectPromise.resolve(endUrl);
            }
        }
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

const mapStateToProps = (state: ReduxState, ownProps: any) => {
    return {
        showAuthRedirect: state.authentication.showAuthRedirect,
        authRedirectUrl: state.authentication.authRedirectUrl,
        authRedirectEndUrl: state.authentication.authRedirectEndUrl,
        showAuthRedirectVisible: state.authentication.showAuthRedirectVisible,
        authRedirectPromise: state.authentication.authRedirectPromise
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthRedirectContainer);
