"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tryParseUrl = exports.isValidUri = exports.isNullOrWhiteSpace = exports.createQuery = exports.parseQuery = exports.xalTrace = exports.TraceLevel = exports.isExpired = exports.isHttpSuccesStatusCode = exports.assert = exports.setDebugSpew = void 0;

var http_1 = require("@xbox/http");

var isReactNative = typeof navigator !== "undefined" ? navigator.product === "ReactNative" : false;
var enableDebugSpew = true;

function setDebugSpew(enabled) {
  enableDebugSpew = enabled;
}

exports.setDebugSpew = setDebugSpew;

function assert(expression, message) {
  if (enableDebugSpew) {
    if (!expression) {
      if (console.assert) {
        console.assert(expression, message);
      } else {
        xalTrace(TraceLevel.Warning, "Assertion failed: " + message);
      }

      console.trace("Assertion trace");
    }
  }
}

exports.assert = assert;

function isHttpSuccesStatusCode(statusCode) {
  return statusCode >= 200 && statusCode < 300;
}

exports.isHttpSuccesStatusCode = isHttpSuccesStatusCode;

function isExpired(notAfter, now) {
  var effectiveNow = now ? now.getTime() : Date.now();

  if (!notAfter) {
    return false;
  } else if (notAfter instanceof Date) {
    return notAfter.getTime() <= effectiveNow;
  } else if (typeof notAfter === "number") {
    return notAfter <= effectiveNow;
  } else if (typeof notAfter === "string") {
    return Date.parse(notAfter) <= effectiveNow;
  } else {
    return false;
  }
}

exports.isExpired = isExpired;
var TraceLevel;

(function (TraceLevel) {
  TraceLevel["Error"] = "Error";
  TraceLevel["Warning"] = "Warning";
  TraceLevel["Important"] = "Important";
  TraceLevel["Information"] = "Information";
  TraceLevel["Verbose"] = "Verbose";
})(TraceLevel = exports.TraceLevel || (exports.TraceLevel = {}));

function xalTrace(level, message, area) {
  if (area === void 0) {
    area = "XAL";
  }

  if (process.env.NODE_ENV === "development" && enableDebugSpew) {
    var trace = console.debug;

    switch (level) {
      case TraceLevel.Error:
        trace = isReactNative ? console.warn : console.error;
        break;

      case TraceLevel.Important:
        trace = console.log;
        break;

      case TraceLevel.Information:
        trace = console.info;
        break;

      case TraceLevel.Warning:
        trace = console.warn;
        break;

      case TraceLevel.Verbose:
      default:
        trace = console.debug;
        break;
    }

    trace(area + "::" + level + ":: " + message);
  }
}

exports.xalTrace = xalTrace;

function parseQuery(searchOrHash) {
  var params = new Map(); // search and hash include a leading ? and #, so strip that first

  var parts = searchOrHash.slice(1).split("&");

  if (!parts || parts.length === 1 && parts[0].length <= 0) {
    return params;
  }

  parts.forEach(function (part) {
    var subParts = part.split("=");
    params.set(subParts[0], subParts[1]);
  });
  return params;
}

exports.parseQuery = parseQuery; // This method won't include a leading ? or #

function createQuery(queryMap) {
  return Array.from(queryMap.entries()).map(function (pair) {
    return encodeURIComponent(pair["0"]) + "=" + encodeURIComponent(pair["1"]);
  }).join("&");
}

exports.createQuery = createQuery;

function isNullOrWhiteSpace(str) {
  return !str || str.trim().length <= 0;
}

exports.isNullOrWhiteSpace = isNullOrWhiteSpace;

function isValidUri(uriStr) {
  try {
    var uri = new http_1.Url(uriStr);
    return true;
  } catch (_a) {
    return false;
  }
}

exports.isValidUri = isValidUri;

function tryParseUrl(uriStr) {
  try {
    var uri = new http_1.Url(uriStr);
    return uri;
  } catch (_a) {
    return undefined;
  }
}

exports.tryParseUrl = tryParseUrl;