import * as React from "react";
import * as Style from "./Club.Styles";

interface ClubSettingCheckBoxProps {
    value: any;
    item: any;
    handler: any;
    label: string;
}

export const ClubSettingsCheckBox: React.SFC<ClubSettingCheckBoxProps> = props => {
    return (
            <Style.CheckBox
                style={{ width: "100%" }}
                onChange={props.handler}
                checked={props.value}
                disabled={!props.item.canViewerChangeSetting}
            >
                {props.label}
            </Style.CheckBox>
    );
};
