"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromiseCompletionSource = void 0;

var PromiseCompletionSource =
/** @class */
function () {
  function PromiseCompletionSource() {
    var _this = this; // tslint:disable-next-line:no-empty


    this.resolve = function () {}; // tslint:disable-next-line:no-empty


    this.reject = function () {};

    this.promise = new Promise(function (resolve, reject) {
      _this.resolve = resolve;
      _this.reject = reject;
    });
  }

  return PromiseCompletionSource;
}();

exports.PromiseCompletionSource = PromiseCompletionSource;