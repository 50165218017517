import * as React from "react";
import { ScrollType } from "./base/InfiniteScrollTypes";
import { BaseInfiniteScrollList, BaseInfiniteScrollProps } from "./base/BaseInfiniteScroller";
import ListStrictScroller from "./ListStrictScroller";
import ListWindowScroller from "./ListWindowScroller";
import MasonryWindowScroller from "./MasonryWindowScroller";

interface InfiniteScrollProps extends BaseInfiniteScrollProps {
    scrollerType: ScrollType;
}

/***********************************************************************************/
/* Parent div for strict InfiniteScrollerSelector should have next two properties:
/*      display: flex;
/*      flex-direction: column;
/***********************************************************************************/
export const InfiniteScrollerSelector = (props:  InfiniteScrollProps) => {
    switch (props.scrollerType) {
        case ScrollType.ListStrict:
            return <ListStrictScroller {...props} />;

        case ScrollType.ListWindow:
            return <ListWindowScroller {...props} />;

        case ScrollType.MansoryWindow:
            return <MasonryWindowScroller {...props}/>;

        default:
            return <BaseInfiniteScrollList {...props} />;
    }
};
