import * as React from "react";

interface PersonMediaViewerProps {
}

interface PersonMediaViewerState {
}

export class PersonMediaViewer extends React.Component<PersonMediaViewerProps, PersonMediaViewerState> {
    constructor(props: PersonMediaViewerProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1>this is person media page</h1>
            </div>
        );
    }
}
