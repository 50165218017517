import * as React from "react";
import { Lfg, SystemTag } from "@xbox/social-core";
import { ILfgAdminActions } from "@xbox/social-views";

import * as Styles from "./LfgCard.Styles";
import * as FeedItemStyles from "../feeditems/base/FeedItem.Styles";
import LfgAdminActionsContainer from "./LfgAdminActionsContainer";
import { GamerpicListItem } from "../../common/generic/GamerpicListItem";

export interface LfgCardProps {
    adminActions?: ILfgAdminActions;
    showAdminAction?: boolean;
    item: Lfg.LfgItem;
    tagClicked?: any;
}

export interface LfgCardState {
    showImg: boolean;
}

export class LfgCard extends React.Component<LfgCardProps, LfgCardState> {
    constructor(props: LfgCardProps) {
        super(props);
    }

    getTags(lfgItem: Lfg.LfgItem, handler: any) {
        if (!lfgItem || !lfgItem.searchAttributes || !lfgItem.searchAttributes.hydratedTags) {
            return null;
        }
        return lfgItem.searchAttributes.hydratedTags.map((tag: SystemTag) =>
            (
                <Styles.LfgTag key={tag.tagName} onClick={() => handler(tag.tagName)}>
                    {tag.tagName}
                </Styles.LfgTag>)
        );
    }

    render() {
        return (
            <FeedItemStyles.FeedItemCard style={{ border: 0, padding: 5, marginBottom: 0 }}>
                <FeedItemStyles.FeedItemHeader>
                    <LfgAdminActionsContainer item={this.props.item} LfgAdminActions={this.props.adminActions} showAdminAction={this.props.showAdminAction} />
                    <GamerpicListItem
                        person={this.props.item.relatedInfo.hydratedSessionOwners[0]}
                        itemPostDate={this.props.item.relatedInfo.postedTime}
                    />
                </FeedItemStyles.FeedItemHeader>

                <div>
                    {this.props.item.relatedInfo.description ? this.props.item.relatedInfo.description.text : ""}
                </div>
                <Styles.VerticalSpace />
                {this.getTags(this.props.item, this.props.tagClicked)}
            </FeedItemStyles.FeedItemCard>
        );
    }
}
