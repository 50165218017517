"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartyRtcClient = void 0; /// <reference types="webrtc" />

require("webrtc-adapter");

var inversify_1 = require("inversify");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators"); // WebRTC constants


var ptime = 20;
var fec = true;
var dtx = true;
var bitrate = 24000;
var sampleRate = 24000;

var PartyRtcClient =
/** @class */
function () {
  function PartyRtcClient() {
    this.sClientInfo = new rxjs_1.BehaviorSubject(undefined);
    this.sMemberJoined = new rxjs_1.Subject();
    this.sDataChannelOpened = new rxjs_1.Subject();
    this.sRemoteStream = new rxjs_1.Subject();
    this.dataChannelOpened = false;
    this.localDescriptionPromise = Promise.resolve();
    this.remoteDescriptionPromise = Promise.resolve();
    this.remoteClients = new Map(); // Required constructor for injection
  }

  PartyRtcClient_1 = PartyRtcClient;

  PartyRtcClient.modifyDescription = function (sessionDescriptionInit) {
    var result = sessionDescriptionInit;
    var sdp = sessionDescriptionInit.sdp;

    if (sdp) {
      if (sdp.match(/a=rtpmap:(\d+) opus\//)) {
        var payloadType = parseInt(RegExp.$1, 10);
        var newSDPString = sdp.replace(new RegExp("a=fmtp:" + payloadType + " .*"), "a=fmtp:" + payloadType + " stereo=0;sprop-stereo=0;cbr=0;sprop-maxcapturerate=" + sampleRate + ";maxplaybackrate=" + sampleRate + ";minptime=" + ptime + ";ptime=" + ptime + ";maxptime=" + ptime + ";useinbandfec=" + (fec ? 1 : 0) + ";usedtx=" + (dtx ? 1 : 0) + ";maxaveragebitrate=" + bitrate);
        var newSessionDescriptionInit = {
          sdp: newSDPString,
          type: sessionDescriptionInit.type ? sessionDescriptionInit.type : undefined
        };
        result = newSessionDescriptionInit;
      }
    }

    return result;
  };

  PartyRtcClient.prototype.createConnection = function () {
    if (!this.connectionPromise) {
      this.remoteClients.clear();
      this.connectionPromise = this.initializeConnection();
    }

    return this.connectionPromise;
  };

  PartyRtcClient.prototype.closeConnection = function () {
    if (this.dataChannel && this.dataChannelOpened) {
      this.dataChannel.close();
    }

    this.dataChannelOpened = false;
    this.remoteClients.clear();
    this.connectionPromise = undefined;
    this.localDescriptionPromise = Promise.resolve();
    this.remoteDescriptionPromise = Promise.resolve();
    this._parsedLocalSdp = undefined;

    if (this.connection) {
      if (this.connection.signalingState !== "closed" && this.connection.getLocalStreams) {
        var connection_1 = this.connection;
        var localStreams = this.connection.getLocalStreams();
        localStreams.forEach(function (stream) {
          stream.getTracks().forEach(function (track) {
            return track.stop();
          });

          if (connection_1.removeStream) {
            connection_1.removeStream(stream);
          }
        });
      }

      this.connection.close();
    }

    this.connection = undefined;
    this.serverInfo = undefined;
  };

  PartyRtcClient.prototype.updatePartyRelay = function (webRtcServerInfo) {
    // tslint:disable-next-line:triple-equals
    if (!this.serverInfo || this.serverInfo.candidateHostname != webRtcServerInfo.candidateHostname || this.serverInfo.candidateIpv4 != webRtcServerInfo.candidateIpv4 || this.serverInfo.candidatePort != webRtcServerInfo.candidatePort // tslint:disable-next-line:triple-equals
    || this.serverInfo.dtlsAlgorithm != webRtcServerInfo.dtlsAlgorithm || this.serverInfo.dtlsThumbprint != webRtcServerInfo.dtlsThumbprint || this.serverInfo.icePwd != webRtcServerInfo.icePwd || this.serverInfo.iceUfrag != webRtcServerInfo.iceUfrag) {
      if (this.serverInfo) {// TODO: clearPeerConnection();
      }

      this.serverInfo = webRtcServerInfo;
      this.serverInfo.setup = "active";
      return this.updateRemoteDescription().then(function () {
        return true;
      });
    } else {
      return Promise.resolve(false);
    }
  };

  PartyRtcClient.prototype.sendShoulderTapMessage = function (changeNumber) {
    if (this.dataChannelOpened) {
      this.sendDataChannelMessage({
        type: "requestRosterUpdate",
        changeNumber: changeNumber
      });
    }
  };

  PartyRtcClient.prototype.sendMemberSeenMessage = function (xuid, mpsdMemberIndex) {
    if (this.dataChannelOpened) {
      this.sendDataChannelMessage({
        type: "mpsdMemberSeen",
        mpsdMemberIndex: mpsdMemberIndex,
        xuid: parseInt(xuid, 10)
      });
    }
  };

  Object.defineProperty(PartyRtcClient.prototype, "oClientInfo", {
    get: function get() {
      return this.sClientInfo.pipe(operators_1.filter(function (clientInfo) {
        return !!clientInfo;
      }));
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyRtcClient.prototype, "oMemberJoined", {
    get: function get() {
      return this.sMemberJoined;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyRtcClient.prototype, "oDataChannelOpened", {
    get: function get() {
      return this.sDataChannelOpened;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PartyRtcClient.prototype, "oRemoteStream", {
    get: function get() {
      return this.sRemoteStream;
    },
    enumerable: false,
    configurable: true
  });

  PartyRtcClient.prototype.initializeConnection = function () {
    return __awaiter(this, void 0, void 0, function () {
      var cert, config, audioDeviceInfo, mediaStream;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.generateCertificate()];

          case 1:
            cert = _a.sent();
            config = {
              bundlePolicy: "max-bundle",
              certificates: [cert]
            };
            this.connection = this.createPeerConnection(config);
            this.dataChannel = this.createDataChannel(this.connection);
            return [4
            /*yield*/
            , this.getAudioDeviceInfo()];

          case 2:
            audioDeviceInfo = _a.sent();

            if (!audioDeviceInfo) {
              console.error("Failed to find an audio device");
              throw new Error("Failed to find an audio device");
            }

            return [4
            /*yield*/
            , this.getAudioStream(audioDeviceInfo)];

          case 3:
            mediaStream = _a.sent();

            if (!this.connection) {
              throw new Error("Connection is null");
            }

            if (this.connection.addStream) {
              this.connection.addStream(mediaStream);
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  PartyRtcClient.prototype.getAudioStream = function (audioDeviceInfo) {
    var audioTrackConstraints = {
      advanced: [{
        deviceId: audioDeviceInfo.deviceId,
        channelCount: 1
      }]
    };
    var constraints = {
      audio: audioTrackConstraints
    };
    return navigator.mediaDevices.getUserMedia(constraints);
  };

  PartyRtcClient.prototype.getAudioDeviceInfo = function () {
    return __awaiter(this, void 0, void 0, function () {
      var devices, audioDeviceInfo;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , navigator.mediaDevices.enumerateDevices()];

          case 1:
            devices = _a.sent();
            audioDeviceInfo = devices.find(function (device) {
              return device.kind === "audioinput";
            });
            return [2
            /*return*/
            , audioDeviceInfo];
        }
      });
    });
  };

  PartyRtcClient.prototype.createDataChannel = function (connection) {
    var _this = this;

    var dataChannel = connection.createDataChannel("relay", {
      negotiated: false,
      ordered: true
    });

    dataChannel.onopen = function () {
      _this.dataChannelOpened = true;
      console.log("RTC data channel opened"); // TODO: Announce seen mpsd members

      var clientInfo = _this.sClientInfo.getValue();

      if (clientInfo) {
        var message = {
          type: "announceClientParameters",
          ssrc: clientInfo.ssrc,
          payload_type: clientInfo.payloadType
        };

        _this.sendDataChannelMessage(message);

        _this.sDataChannelOpened.next();
      }
    };

    dataChannel.onclose = function () {
      return console.log("RTC data channel closed");
    };

    dataChannel.onerror = function (err) {
      return console.log("RTC data channel error: " + err);
    };

    var thisClient = this;

    dataChannel.onmessage = function (message) {
      return thisClient.onDataChannelMessage(message);
    };

    return dataChannel;
  };

  PartyRtcClient.prototype.createPeerConnection = function (config) {
    var _this = this;

    var connection = Object.assign({}, new RTCPeerConnection(config), {
      onaddstream: function onaddstream(event) {
        return undefined;
      }
    });

    connection.onicegatheringstatechange = function (event) {
      if (_this.connection) {
        console.log("IceGatheringState change: " + _this.connection.iceGatheringState);
      }
    };

    connection.oniceconnectionstatechange = function (event) {
      if (_this.connection) {
        console.log("IceConnectionState change: " + _this.connection.iceConnectionState);
      }
    };

    connection.onsignalingstatechange = function (event) {
      if (_this.connection) {
        console.log("SignalingState change: " + _this.connection.signalingState);
      }
    };

    connection.onaddstream = function (event) {
      if (event.stream) {
        console.log("OnAddStream: " + event.stream.id);

        _this.sRemoteStream.next(event.stream);
      }
    };

    return connection;
  };

  PartyRtcClient.prototype.generateCertificate = function () {
    return RTCPeerConnection.generateCertificate({
      name: "RSASSA-PKCS1-v1_5",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256"
    });
  };

  Object.defineProperty(PartyRtcClient.prototype, "parsedLocalSdp", {
    get: function get() {
      if (!this._parsedLocalSdp && this.connection && this.connection.localDescription && this.connection.localDescription.sdp) {
        this._parsedLocalSdp = this.parseSDP(this.connection.localDescription.sdp);
      }

      return this._parsedLocalSdp;
    },
    enumerable: false,
    configurable: true
  });

  PartyRtcClient.prototype.updateClientInfo = function () {
    if (this.connection && this.connection.localDescription && this.connection.localDescription.sdp) {
      this.connection.localDescription.sdp.match(/a=fingerprint:(.*) (.*)\r/);
      var dtlsCertificateAlgorithm = RegExp.$1;
      var dtlsCertificateThumbprint = RegExp.$2;
      this.connection.localDescription.sdp.match(/a=ice-ufrag:(.*)\r/);
      var iceUfrag = RegExp.$1;
      this.connection.localDescription.sdp.match(/a=ice-pwd:(.*)\r/);
      var icePwd = RegExp.$1;
      var audioSection = null;
      var parsedLocalSDP = this.parsedLocalSdp;

      for (var i = 0; audioSection == null && i < parsedLocalSDP.sections.length; i++) {
        var section = parsedLocalSDP.sections[i];

        if (section.type === "audio") {
          audioSection = section;
        }
      }

      var ssrc = null;
      var payloadType = null;

      if (audioSection) {
        for (var i = 0; (ssrc == null || payloadType == null) && i < audioSection.lines.length; i++) {
          var line = audioSection.lines[i];

          if (line.match(/a=ssrc:(\d+)/)) {
            ssrc = parseInt(RegExp.$1, 10);
          } else if (line.match(/a=rtpmap:(\d+) opus\//)) {
            payloadType = parseInt(RegExp.$1, 10);
          }
        }
      }

      if (!ssrc || !payloadType) {
        throw new Error("Malformed audio section! ssrc=" + ssrc + ", payloadType=" + payloadType);
      }

      this.sClientInfo.next({
        dtlsCertificateAlgorithm: dtlsCertificateAlgorithm,
        dtlsCertificateThumbprint: dtlsCertificateThumbprint,
        iceUfrag: iceUfrag,
        icePwd: icePwd,
        ssrc: ssrc,
        payloadType: payloadType
      });
    }
  };

  PartyRtcClient.prototype.onDataChannelMessage = function (messageEvent) {
    console.log("Received data channel message: " + messageEvent.data);
    var messageObj = JSON.parse(messageEvent.data);
    console.log("Message type: " + messageObj.type);

    switch (messageObj.type) {
      case "join":
        if (messageObj.ssrc) {
          if (this.isLocalMemberIndex && !this.isLocalMemberIndex(messageObj.mpsdMemberIndex) && messageObj.ssrc) {
            this.remoteClients.set(messageObj.mpsdMemberIndex, {
              ssrc: messageObj.ssrc,
              mpsdMemberIndex: messageObj.mpsdMemberIndex
            });
            this.updateRemoteDescription().catch(function (err) {
              return console.log("Error occurred while updating remote description after member joined: " + err);
            });
          }
        }

        this.sMemberJoined.next(messageObj.mpsdMemberIndex);
        break;

      case "text":
        break;

      case "privacyResult":
        break;

      default:
        break;
    }
  };

  PartyRtcClient.prototype.parseSDP = function (sdp) {
    var sections = [];
    var hasBundles = false;
    var currentSection = null;
    var lines = sdp.split(/\n/);

    for (var i = 0; i < lines.length; i++) {
      var line = lines[i];

      if (line.match(/^a=group:BUNDLE\s/)) {
        hasBundles = true;
      } else if (line.match(/^m=(\w+)/)) {
        if (currentSection) {
          sections.push(currentSection);
        }

        currentSection = {
          type: RegExp.$1,
          lines: [line]
        };
      }

      if (currentSection) {
        currentSection.lines.push(line);

        if (line.match(/^a=mid:(\w+)/)) {
          currentSection.bundleId = RegExp.$1;
        }
      }
    }

    if (currentSection) {
      sections.push(currentSection);
    }

    return {
      sections: sections,
      hasBundles: hasBundles
    };
  };

  PartyRtcClient.prototype.updateLocalDescription = function () {
    var _this = this;

    this.localDescriptionPromise = this.localDescriptionPromise.then(function () {
      if (!_this.connection) {
        return _this.createConnection();
      } else {
        return Promise.resolve();
      }
    }).then(function () {
      if (_this.connection) {
        return _this.connection.createOffer();
      } else {
        throw new Error("Connection is null");
      }
    }).then(function (sessionDescriptionInit) {
      if (_this.connection) {
        var sessionDescription = PartyRtcClient_1.modifyDescription(sessionDescriptionInit);
        console.log("Set local description: " + sessionDescription.sdp);
        return _this.connection.setLocalDescription(sessionDescription);
      } else {
        throw new Error("Connection is null");
      }
    }).then(function () {
      _this.updateClientInfo();
    });
    return this.localDescriptionPromise;
  };

  PartyRtcClient.prototype.updateRemoteDescription = function () {
    var _this = this;

    this.remoteDescriptionPromise = this.remoteDescriptionPromise.catch(function (err) {
      return "Prior remote description update failed " + err;
    }).then(function () {
      if (!_this.connection || _this.connection.signalingState === "stable") {
        console.log("Resetting offer and local description");
        return _this.updateLocalDescription();
      } else {
        return Promise.resolve();
      }
    }).then(function () {
      var clientInfo = _this.sClientInfo.getValue();

      if (_this.serverInfo && _this.connection && _this.connection.localDescription && _this.connection.localDescription.sdp && clientInfo) {
        var hasRemoteClients = _this.remoteClients.size > 0;
        var firstRemoteUpdate = _this.connection && _this.connection.remoteDescription == null;
        var parsedLocalSDP = _this.parsedLocalSdp;
        console.log("Updating remote description: hasRemoteClients=" + hasRemoteClients + ", firstRemoteUpdate=" + firstRemoteUpdate);
        _this.serverInfo.candidateLine = "a=candidate:1 1 udp 1 ___SERVERINFO_IP___ ___SERVERINFO_PORT___ typ host generation 0 network-id 1".replace(/___SERVERINFO_IP___/g, "" + _this.serverInfo.candidateIpv4).replace(/___SERVERINFO_PORT___/g, "" + _this.serverInfo.candidatePort);
        var remoteSdp = "" + "v=0\n" + "o=- 1616430012261898126 2 IN IP4 127.0.0.1\n" + "s=-\n" + "t=0 0\n" + (parsedLocalSDP.hasBundles ? "a=group:BUNDLE ___BUNDLES___\n" : "") + "a=msid-semantic: WMS *\n" + "a=ice-options:trickle\n" + "";
        var sectionSdp_1 = "";

        for (var sectionIndex = 0; sectionIndex < parsedLocalSDP.sections.length; sectionIndex++) {
          var section = parsedLocalSDP.sections[sectionIndex];

          switch (section.type.toString()) {
            case "application":
              {
                sectionSdp_1 = "m=application ___SERVERINFO_PORT___ DTLS/SCTP 5000\n" + "c=IN IP4 ___SERVERINFO_IP___\n" + _this.serverInfo.candidateLine + "\n" + "a=ice-ufrag:___SERVERINFO_ICE_UFRAG___\n" + "a=ice-pwd:___SERVERINFO_ICE_PWD___\n" + "a=fingerprint:___SERVERINFO_FINGERPRINT_ALGO___ ___SERVERINFO_FINGERPRINT_VALUE___\n" + "a=setup:___SERVERINFO_SETUP___\n" + "a=mid:___BUNDLEID___\n" + "a=sctpmap:5000 webrtc-datachannel 1024\n" + // 'a=sctp-port:5000\n' +
                "";
                break;
              }

            case "audio":
              {
                sectionSdp_1 = ("" + "m=audio ___SERVERINFO_PORT___ UDP/TLS/RTP/SAVPF ___CLIENTINFO_PAYLOAD_TYPE___\n" + "c=IN IP4 ___SERVERINFO_IP___\n" + "a=rtcp:___SERVERINFO_PORT___ IN IP4 ___SERVERINFO_IP___\n" + (parsedLocalSDP.sections.length > 1 ? "" : _this.serverInfo.candidateLine + "\n") + "a=ice-ufrag:___SERVERINFO_ICE_UFRAG___\n" + "a=ice-pwd:___SERVERINFO_ICE_PWD___\n" + "a=fingerprint:___SERVERINFO_FINGERPRINT_ALGO___ ___SERVERINFO_FINGERPRINT_VALUE___\n" + "a=setup:___SERVERINFO_SETUP___\n" + "a=mid:___BUNDLEID___\n" +
                /*"a=extmap:1 urn:ietf:params:rtp-hdrext:ssrc-audio-level\n" +*/
                "a=sendrecv\n" + "a=rtcp-mux\n" + "a=rtpmap:___CLIENTINFO_PAYLOAD_TYPE___ opus/48000/2\n" + "a=rtcp-fb:___CLIENTINFO_PAYLOAD_TYPE___ transport-cc\n" + "a=fmtp:___CLIENTINFO_PAYLOAD_TYPE___ filled-in-by-fixupSDP\n" + "").replace(/___CLIENTINFO_PAYLOAD_TYPE___/g, "" + clientInfo.payloadType);

                if (hasRemoteClients) {
                  _this.remoteClients.forEach(function (remoteClient) {
                    if (remoteClient) {
                      var remoteClientSdp = ("" + "a=ssrc:___SERVERINFO_SSRC___ cname:___SERVERINFO_CNAME___\n" + "a=ssrc:___SERVERINFO_SSRC___ msid:___SERVERINFO_MSLABEL___ ___SERVERINFO_MSID___\n" + "a=ssrc:___SERVERINFO_SSRC___ mslabel:___SERVERINFO_MSLABEL___\n" + "a=ssrc:___SERVERINFO_SSRC___ label:___SERVERINFO_MSID___\n" + "").replace(/___SERVERINFO_SSRC___/g, "" + remoteClient.ssrc).replace(/___SERVERINFO_CNAME___/g, "" + "cname_" + remoteClient.mpsdMemberIndex).replace(/___SERVERINFO_MSID___/g, "" + "msid_" + remoteClient.mpsdMemberIndex).replace(/___SERVERINFO_MSLABEL___/g, "" + "mslabel_" + remoteClient.mpsdMemberIndex);
                      sectionSdp_1 += remoteClientSdp;
                    }
                  });
                }

                break;
              }

            default:
              {
                throw new Error("Unsupported section type " + section.type);
              }
          }

          sectionSdp_1 = sectionSdp_1.replace(/___SERVERINFO_IP___/g, "" + _this.serverInfo.candidateIpv4).replace(/___SERVERINFO_PORT___/g, "" + _this.serverInfo.candidatePort).replace(/___SERVERINFO_SETUP___/g, "" + _this.serverInfo.setup).replace(/___SERVERINFO_ICE_UFRAG___/g, "" + _this.serverInfo.iceUfrag).replace(/___SERVERINFO_ICE_PWD___/g, "" + _this.serverInfo.icePwd).replace(/___SERVERINFO_FINGERPRINT_ALGO___/g, "" + _this.serverInfo.dtlsAlgorithm).replace(/___SERVERINFO_FINGERPRINT_VALUE___/g, "" + _this.serverInfo.dtlsThumbprint).replace(/___BUNDLEID___/g, "" + section.bundleId);
          remoteSdp += sectionSdp_1;
        }

        remoteSdp = remoteSdp.replace(/___BUNDLES___/g, "" + parsedLocalSDP.sections.map(function (e) {
          return e.bundleId;
        }).join(" "));
        var sessionDescriptionInit = {
          sdp: remoteSdp,
          type: "answer"
        };
        var remoteDescription = PartyRtcClient_1.modifyDescription(sessionDescriptionInit);
        console.log("setting remote description:\n" + remoteDescription.sdp);
        return _this.connection.setRemoteDescription(remoteDescription);
      } else {
        throw new Error("connection, serverInfo or clientInfo is missing");
      }
    }).then(function () {
      if (_this.connection && _this.connection.remoteDescription) {
        console.log("set remote description: [" + _this.connection.remoteDescription.sdp + "]");
      }

      var parsedLocalSdp = _this.parsedLocalSdp;

      if (parsedLocalSdp && _this.serverInfo && _this.connection) {
        for (var sectionIndex = 0; sectionIndex < parsedLocalSdp.sections.length; sectionIndex++) {
          var section = parsedLocalSdp.sections[sectionIndex];
          var iceCandidateInit = {
            candidate: _this.serverInfo.candidateLine,
            sdpMid: section.bundleId
          };
          var iceCandidate = new RTCIceCandidate(iceCandidateInit);

          _this.connection.addIceCandidate(iceCandidate);
        }
      } else {
        throw new Error("Invalid parsed local SDP, serverInfo or connection");
      }
    });
    return this.remoteDescriptionPromise;
  };

  PartyRtcClient.prototype.sendDataChannelMessage = function (message) {
    var jsonObj = JSON.stringify(message, null, 4);

    if (this.dataChannel) {
      console.log("Sending data channel message: " + jsonObj);
      this.dataChannel.send(jsonObj);
    }
  };

  var PartyRtcClient_1;
  PartyRtcClient = PartyRtcClient_1 = __decorate([inversify_1.injectable(), __metadata("design:paramtypes", [])], PartyRtcClient);
  return PartyRtcClient;
}();

exports.PartyRtcClient = PartyRtcClient;