"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MpsdPartySession = void 0;

var rxjs_1 = require("rxjs");

var MpsdPartySession =
/** @class */
function () {
  function MpsdPartySession(sessionId, localXuid, sessionDocument, mpsdPartyService) {
    this.sessionId = sessionId;
    this.localXuid = localXuid;
    this.sessionDocument = sessionDocument;
    this.mpsdPartyService = mpsdPartyService;
    this.sSessionDocumentUpdate = new rxjs_1.Subject();
    this.sMembersAdded = new rxjs_1.Subject();
    this.sMembersRemoved = new rxjs_1.Subject();
    this.membersMap = new Map();
  }

  MpsdPartySession.prototype.setPartyRtaConnectionId = function (rtaConnectionId) {
    throw new Error("Method not implemented.");
  };

  MpsdPartySession.prototype.refreshSessionDocument = function () {
    var _this = this;

    return this.mpsdPartyService.getSessionDocumentById(this.sessionId).then(function (sessionDocument) {
      return _this.handleSessionDocumentUpdate(sessionDocument);
    });
  };

  MpsdPartySession.prototype.setWebRtcClientInfo = function (dtlsCertificateAlgorithm, dtlsCertificateThumbprint, iceUfrag, icePwd) {
    var _this = this;

    return this.mpsdPartyService.setWebRtcClientInfo(this.sessionId, this.localXuid, this.localMemberIndex, dtlsCertificateAlgorithm, dtlsCertificateThumbprint, iceUfrag, icePwd).then(function (sessionDocument) {
      return _this.handleSessionDocumentUpdate(sessionDocument);
    });
  };

  MpsdPartySession.prototype.setConnectionState = function (connectionState) {
    var _this = this;

    return this.mpsdPartyService.setConnectionState(this.sessionId, this.localXuid, this.localMemberIndex, connectionState).then(function (sessionDocument) {
      return _this.handleSessionDocumentUpdate(sessionDocument);
    });
  };

  MpsdPartySession.prototype.inviteUser = function (xuid) {
    return this.mpsdPartyService.inviteUser(this.sessionId, xuid);
  };

  MpsdPartySession.prototype.leaveParty = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.mpsdPartyService.removeLocalMember(this.sessionId, this.localXuid)];

          case 1:
            _a.sent();

            this.sSessionDocumentUpdate.complete();
            this.sMembersAdded.complete();
            this.sMembersRemoved.next(this.members);
            this.sMembersRemoved.complete();
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  Object.defineProperty(MpsdPartySession.prototype, "localMemberIndex", {
    get: function get() {
      for (var memberIndex in this.sessionDocument.members) {
        if (this.sessionDocument.members[memberIndex].constants.system.xuid === this.localXuid) {
          return +memberIndex;
        }
      }

      throw new Error("Unable to determine local member index");
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "rtaConnectionId", {
    get: function get() {
      return this.sessionDocument.members[this.localMemberIndex].properties.system.connection;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "cloudCompute", {
    get: function get() {
      return this.sessionDocument.servers.cloudCompute;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "allocateCloudCompute", {
    get: function get() {
      return this.sessionDocument.properties.system.allocateCloudCompute;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "changeNumber", {
    get: function get() {
      return this.sessionDocument.changeNumber;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "members", {
    get: function get() {
      return Array.from(this.membersMap.values());
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "oSessionDocumentUpdate", {
    get: function get() {
      return this.sSessionDocumentUpdate;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "oMembersAdded", {
    get: function get() {
      return this.sMembersAdded;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(MpsdPartySession.prototype, "oMembersRemoved", {
    get: function get() {
      return this.sMembersRemoved;
    },
    enumerable: false,
    configurable: true
  });

  MpsdPartySession.prototype.handleSessionDocumentUpdate = function (sessionDocument) {
    var _this = this;

    if (!this.changeNumber || sessionDocument.changeNumber > this.changeNumber) {
      this.sessionDocument = sessionDocument;
      var addedMembers_1 = [];
      Object.keys(this.sessionDocument.members).forEach(function (memberIndex) {
        var member = _this.sessionDocument.members[memberIndex];
        var mpsdMember = {
          xuid: member.constants.system.xuid,
          index: member.constants.system.index
        };

        if (!_this.membersMap.has(mpsdMember.xuid)) {
          console.log("Member added " + mpsdMember.xuid);
          addedMembers_1.push(mpsdMember);
        }

        _this.membersMap.set(mpsdMember.xuid, mpsdMember);
      });
      var removedMembers_1 = [];
      Array.from(this.membersMap.values()).forEach(function (member) {
        var docMember = _this.sessionDocument.members[member.index];

        if (!docMember) {
          removedMembers_1.push(member);
        }
      });
      removedMembers_1.forEach(function (member) {
        console.log("Member removed " + member.xuid);

        if (_this.membersMap.has(member.xuid)) {
          _this.membersMap.delete(member.xuid);
        }
      });

      if (addedMembers_1.length > 0) {
        this.sMembersAdded.next(addedMembers_1);
      }

      if (removedMembers_1.length > 0) {
        this.sMembersRemoved.next(removedMembers_1);
      }

      console.log("Session document updated to change: " + sessionDocument.changeNumber);
      this.sSessionDocumentUpdate.next(sessionDocument.changeNumber);
    } else {
      console.log("Session document was unchanged");
    }
  };

  return MpsdPartySession;
}();

exports.MpsdPartySession = MpsdPartySession;