"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitlesForPfn = exports.TitlesForTitleIds = exports.TitlesForXuid = void 0;

var graphql_tag_1 = require("graphql-tag");

var SharedFragments_1 = require("./SharedFragments");

exports.TitlesForXuid = graphql_tag_1.default(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query TitlesForXuid($xuid: String!, $first: Int) {\n        titlesForXuid(xuid: $xuid, first: $first) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"], ["\n    query TitlesForXuid($xuid: String!, $first: Int) {\n        titlesForXuid(xuid: $xuid, first: $first) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"])), SharedFragments_1.TitleProfileFragment);
exports.TitlesForTitleIds = graphql_tag_1.default(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query Titles($titleIds: [TitleId]!) {\n        titles(titleIds: $titleIds) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"], ["\n    query Titles($titleIds: [TitleId]!) {\n        titles(titleIds: $titleIds) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"])), SharedFragments_1.TitleProfileFragment);
exports.TitlesForPfn = graphql_tag_1.default(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query TitlesFromPfn($pfn: String!, $first: Int) {\n        titlesFromPfn(pfn: $pfn, first: $first) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"], ["\n    query TitlesFromPfn($pfn: String!, $first: Int) {\n        titlesFromPfn(pfn: $pfn, first: $first) {\n            ...TitleProfileFragment\n        }\n    }\n    ", "\n"])), SharedFragments_1.TitleProfileFragment);
var templateObject_1, templateObject_2, templateObject_3;