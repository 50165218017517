"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peopleRedux = exports.getFriendContainerViewState = exports.getPeopleState = void 0;

var PeopleActions_1 = require("../../actions/PeopleActions");

var PeopleReducer_1 = require("./PeopleReducer");

var PeopleNormalizer_1 = require("./PeopleNormalizer"); // selectors


function getPeopleState(state) {
  if (state.people) {
    return PeopleNormalizer_1.peopleDenormalizer(state.people.result, state.people.entities);
  } else {
    return [];
  }
}

exports.getPeopleState = getPeopleState; // keep the state as any to allow grabing properties everywhere.

function getFriendContainerViewState(state) {
  return {
    currentUser: state.authentication.currentUser,
    isLoading: state.people.fetchFriendsInProgress,
    friends: getPeopleState(state.people),
    inParty: state.party.inParty,
    inviteSent: state.party.inviteSent
  };
}

exports.getFriendContainerViewState = getFriendContainerViewState; // redux selector

exports.peopleRedux = {
  reducer: PeopleReducer_1.peopleReducer,
  actions: PeopleActions_1.PeopleActions,
  selectors: {
    getPeopleState: getPeopleState,
    getFriendContainerViewState: getFriendContainerViewState
  }
};